import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../constants/images";
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import axios from "axios";
import moment from "moment/moment";

const Fail = () => {
  const [loading, setloading] = useState(false);
  const [getdata, setData] = useState({});
  const navigate = useNavigate();

  const allRes = JSON.parse(localStorage.getItem("allres"));

  useEffect(() => {
    failApi();
  }, []);

  const failApi = () => {
    setloading(true);
    const param = {
      transaction_id: allRes.transaction_id,
    };
    console.log("assacacacasavsedav", param);
    axios
      .post("https://www.ravi.host/api/stripe_payment_fail", param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          console.log(Response.data);
          setData(Response.data);
          //  sessionStorage.clear();
        } else {
          console.log(Response.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="page_main_wrapp">
      <div
        className="page_base_wrapp"
        style={{ overflowY: "hidden", margin: "0px auto" }}
      >
        {/* fail modal start */}
        <div
          className="actionneed_2main"
          style={{ minHeight: "95vh", overflow: "hidden" }}
        >
          <div
            style={{
              background: "linear-gradient(180deg, #ff004c, #caacffc2",
              display: "flex",
              alignItems: "center",
              padding: "2px",
              minHeight: "200px",
              borderRadius: "18px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "var(--color-black)",
                height: "100%",
                borderRadius: "18px",
                padding: "10px",
                // margin: "10px auto",
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-start",
              }}
            >
              <IoIosCloseCircle color="#fff" size="30" />

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-700)",
                  fontSize: "24px",
                  marginTop: "12px",
                }}
              >
                Payment failed!
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-400)",
                  fontSize: "12px",
                  // marginTop: "4px",
                }}
              >
                {/* 18 Des,2023 | 03:46 PM */}
                {moment(getdata.date_time).format("MMM D, YYYY")} |{" "}
                {moment(getdata.date_time).format("h:mm a")}
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-300)",
                  fontSize: "12px",
                  marginTop: "12px",
                }}
              >
                {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque
              sapiente ipsam nostrum neque. */}
                {getdata.message}
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-600)",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Transction number
              </p>
              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-300)",
                  fontSize: "12px",
                  // marginTop: "8px",
                }}
              >
                {/* SDFS44548SDF5485DFG */}
                {allRes.tra_id}
              </p>
            </div>
          </div>
          {/* <Link to={"/homescreen2"} className="actionneed_2btn">
            Home
          </Link> */}
          <button
            onClick={() => {
              // localStorage.removeItem("allres");
              navigate("/homescreen2");
            }}
            className="new_btn_wrapp mt-3"
          >
            <p>Home</p>
          </button>
        </div>
        {/* fail modal start */}
      </div>
    </div>
  );
};

export default Fail;
