import React, { useEffect } from "react";
import "./RoyaltyNfbs.css";
import images from "../../constants/images";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useHomeContext } from "../../context/home_context";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

import NewFrame1 from "../../frames/feedframes/FeedNewFrame1";
import NewFrame2 from "../../frames/feedframes/FeedNewFrame2";
import NewFrame3 from "../../frames/feedframes/FeedNewFrame3";
import NewFrame4 from "../../frames/feedframes/FeedNewFrame4";
import NewFrame5 from "../../frames/feedframes/FeedNewFrame5";
import NewFrame6 from "../../frames/feedframes/FeedNewFrame6";
import NewFrame7 from "../../frames/feedframes/FeedNewFrame7";
import NewFrame8 from "../../frames/feedframes/FeedNewFrame8";
import NewFrame9 from "../../frames/feedframes/FeedNewFrame9";
import NewFrame10 from "../../frames/feedframes/FeedNewFrame10";
import V2Modal from "../../common/v2modal/V2Modal";

const RoyaltyNfbs = () => {
  const { getDashboardlatest5, is_loading, home_latest5_data } =
    useHomeContext();
  const [getsearch, setSearch] = useState(false);
  const [getsearchData, setSearchData] = useState([]);
  const [getroyaltyFirst, setRoyaltyFirst] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [isloading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Latest5Api = async () => {
    setIsLoading(true);
    const params = {
      user_id: JSON.parse(userId),
    };
    console.log("login param", params);

    const data = await getDashboardlatest5(params);
    setIsLoading(false);
    if (data) {
      // setAllIntrest(data.user_interest);
      setRoyaltyFirst(data.result.latest_five_not_accepted);
      setSearchData(data.result.latest_five_not_accepted);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    console.log("user_interest", getroyaltyFirst);
  };

  useEffect(() => {
    Latest5Api();
    console.log("getroyaltyFirst", getroyaltyFirst);
  }, []);

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setRoyaltyFirst(newData);
    console.log("newData", newData);
  };

  const renderSelectedCard = (item) => {
    if (item.theme_index === 16) {
      return (
        <NewFrame1
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 17) {
      return (
        <NewFrame2
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 18) {
      return (
        <NewFrame3
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 19) {
      return (
        <NewFrame4
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 20) {
      return (
        <NewFrame5
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 21) {
      return (
        <NewFrame6
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 22) {
      return (
        <NewFrame7
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 23) {
      return (
        <NewFrame8
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 24) {
      return (
        <NewFrame9
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (item.theme_index === 25) {
      return (
        <NewFrame10
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else {
      return (
        <NewFrame1
          item={item}
          like_btn={true}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    }
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* navbar start */}
        <div className="rnfb_nav_wrapp">
          {/* <img
            src={images.back}
            onClick={() => navigate(-1)}
            alt=""
            className="rnfb_nav_back_btn"
          /> */}
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p className="rnfb_nav_screen_name">Royalty Rarerez (1st buyer)</p>
          <FiSearch
            color="var(--color-red)"
            size={24}
            // className="rnfb_nav_search_btn"
            style={{ cursor: "pointer" }}
            onClick={() => setSearch(!getsearch)}
          />
        </div>
        {/* navbar end */}

        {/* search start */}
        {getsearch && (
          <div style={{}} className="bid13_search">
            <div
              className="bids13_user_bid_main"
              style={{ paddingTop: "10px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="bids13_user_bid_search"
                // value={searchTerm}
                onChange={(e) => searchFilter(e.target.value)}
              />
              {/* <img src={images.search} alt="" className="search_ic" /> */}
            </div>
          </div>
        )}
        {/* search end */}

        {isloading ? (
          <NewLoader />
        ) : (
          <div
            className=""
            style={{
              width: "100%",
              margin: "1rem auto",
            }}
          >
            {getroyaltyFirst && getroyaltyFirst.length == 0 ? (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "60px",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                No item found.
              </p>
            ) : null}
            {getroyaltyFirst && getroyaltyFirst.length > 0
              ? getroyaltyFirst.map((item, index) => {
                  console.log("item", item);
                  return (
                    <>
                      <div
                        className="feedmain"
                        style={{ marginTop: "10px", marginBottom: "50px" }}
                      >
                        <Link
                          to={
                            item.is_club_id == ""
                              ? "/UserProfile"
                              : "/Clubprofile"
                          }
                          state={
                            item.is_club_id == ""
                              ? { user_id: item.user_id }
                              : { club_id: item.is_club_id }
                          }
                          className="feed_details"
                        >
                          <div className="feed_user_icon_Wrapp">
                            <img src={item.profile} className="" alt="" />
                          </div>
                          <p className="user_feed_nm">{item.username}</p>
                        </Link>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {renderSelectedCard(item)}
                        </div>
                        <br />
                      </div>
                      {/* <Link
                        to={"/productview"}
                        state={{
                          release_bond_id: item.release_bond_id,
                          product_id: item.product_id,
                          is_nfb: item.is_nfb,
                          uniq_code: item.uniq_code,
                          theme_index: item.theme_index,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                        }}
                        className="rngb_single_product"
                        key={item.product_id}
                      >
                        {item.product_image_video &&
                        item.product_image_video.length > 0 ? (
                          <img
                            src={item.product_image_video[0].img_video}
                            alt=""
                          />
                        ) : (
                          <img
                            src={
                              item.product_image_video
                                ? item.product_image_video
                                    .filter((item) => item.image_type == "new")
                                    .map((item) => item.img_video)[0] || null
                                : (item.bond_media_upload &&
                                    item.bond_media_upload
                                      .filter(
                                        (item) => item.image_type == "new"
                                      )
                                      .map((item) => item.img_video)[0]) ||
                                  null
                            }
                            alt=""
                          />
                        )}
                        <div className="rnfb_blur_view">
                          <p className="rnfb_product_name">
                            {item.product_name?.slice(0, 30)}
                          </p>
                          <div className="rnfb_stock_wrapp">
                            <p>Stock : {item.total_available_bond_bid}</p>
                            <span>₹{item.product_value}</span>
                          </div>
                        </div>
                      </Link> */}
                    </>
                  );
                })
              : null}
          </div>
        )}
      </div>
      {ismodalopen && (
        <>
          {/* <NewModal
              title={getmodalName}
              msg={getmsg}
              setShowModal={openmodal}
              closemodal={closemodal}
              
            /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default RoyaltyNfbs;
