import React from "react";
import "./AttachVideos.css";
import NavbarSec from "../../common/NavbarSec";
import { useState } from "react";

const AttachVideos = () => {
  const [getvideoId, setVide0Id] = useState(0);
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Attach Video" />

        <div className="av_videos_wrapp">
          <div className="av_single_video">
            <input
              type="checkbox"
              className="av_of_checkbox"
              // checked={getvideoId === 1}
            />
            <button className="av_prev_btn">Preview</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachVideos;
