import React, { useEffect, useRef, useState } from "react";
import "./NewCreateClub.css";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import NewLoader from "../../common/newloder/NewLoader";
import images from "../../constants/images";
import { ToastContainer, toast } from "react-toastify";
import {
  create_club,
  get_club_join_fees,
  getall_user,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";

const NewCreateClub = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [creatormodal, setCreatormodal] = useState(false);
  const [getselectedItem, setSelectedItem] = useState([]);
  const [getAllData, setAllData] = useState([]);
  const [getselectedId, setSelectedId] = useState([]);
  const [gettask_arrayholder, settask_arrayholder] = useState([]);

  const [clubdisplayname, setclubdisplayname] = useState([]);
  const [getclubName, setClubName] = useState("");
  const [getcontactEmail, setContactEmail] = useState("");
  const [getcontactPhone, setContactPhone] = useState("");
  const [getaboutClub, setAboutClub] = useState("");
  const [getclubHistory, setClubHistory] = useState("");
  const [getclubStory, setClubStory] = useState("");
  const [getpanNo, setPanNo] = useState("");
  const [getgstNo, setGstNo] = useState("");

  const [getfacebook, setFacebook] = useState("");
  const [getinstagram, setInstagram] = useState("");
  const [gettwitter, setTwitter] = useState("");
  const [getyoutube, setyoutube] = useState("");
  const [getlinkedin, setlinkedin] = useState("");

  const [getpaid_amount, setpaid_amount] = useState(0);
  const [getclubFee, setClubFee] = useState(0);
  const [showFree, setShowFree] = useState(0);
  const [getmonth_1, setmonth_1] = useState(0);
  const [getmonth_3, setmonth_3] = useState(0);
  const [getmonth_6, setmonth_6] = useState("");
  const [getmonth_12, setmonth_12] = useState(0);

  const [profileImage, setProfileImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setcoverImage] = useState(null);
  const [multiImages, setMultiImages] = useState([]);

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);

  //   profile image

  const [inputValues, setInputValues] = useState([""]);

  const handleProfileChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setProfileImage((e) => [...files]);
  };

  const handleProfileDelete = () => {
    setProfileImage(null);
  };

  //   logo image

  const handleLogoChange = (e) => {
    const files = Array.from(e.target.files);
    // if (file) {
    //   setLogoImage(URL.createObjectURL(file));
    // }
    setLogoImage((e) => [...files]);
  };

  const handleLogoDelete = () => {
    setLogoImage(null);
  };

  //   cover image

  const handlecoverChange = (e) => {
    const file = e.target.files;
    // if (file) {
    //   setcoverImage(URL.createObjectURL(file));
    // }
    setcoverImage((e) => [...file]);
  };

  const handleCoverDelete = () => {
    setcoverImage(null);
  };

  //   media images start

  const handlemultiChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setMultiImages([...multiImages, ...selectedImages]);
  };

  const handleImageDelete = (index) => {
    const newImages = [...multiImages];
    newImages.splice(index, 1);
    setMultiImages(newImages);
  };
  //   media images end

  //   media links start

  const validateSocialMediaLink = (link) => {
    if (
      link.startsWith("https://www.facebook.com/") ||
      link.startsWith("https://facebook.com/")
    ) {
      return true;
    }
    if (
      link.startsWith("https://www.instagram.com/") ||
      link.startsWith("https://instagram.com/")
    ) {
      return true;
    }

    // Validation logic for YouTube
    if (
      link.startsWith("https://www.youtube.com/") ||
      link.startsWith("https://youtube.com/")
    ) {
      return true;
    }

    // Validation logic for LinkedIn
    if (
      link.startsWith("https://www.linkedin.com/") ||
      link.startsWith("https://linkedin.com/")
    ) {
      return true;
    }

    // Validation logic for Twitter
    if (
      link.startsWith("https://www.twitter.com/") ||
      link.startsWith("https://twitter.com/")
    ) {
      return true;
    }

    return false;
  };

  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);

    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      toast.error("Please enter valid social media links", {
        theme: "colored",
      });
    }
  };

  //   media links end

  //   phone number validation start

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setContactPhone(numericValue);
      // setErrorMessage("");
    } else {
      const trimmedValue = numericValue.slice(0, 10);
      setContactPhone(trimmedValue);
      console.log("trimmedValue", trimmedValue);
    }
  };

  //   phone number validation end

  //   subsction mountly prices functions start

  const handleonemonthprice = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (numericValue) {
      setpaid_amount(numericValue);
      setmonth_1(numericValue);
    } else {
      const trimmedValue = numericValue;
      setpaid_amount(trimmedValue);
      setmonth_1(trimmedValue);
      console.log("trimmedValue", trimmedValue);
    }
  };
  const handlethreemonthprice = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (numericValue) {
      setmonth_3(numericValue);
    } else {
      const trimmedValue = numericValue;
      setmonth_3(trimmedValue);
      console.log("trimmedValue", trimmedValue);
    }
  };
  const handlesixmonthprice = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (numericValue) {
      setmonth_6(numericValue);
    } else {
      const trimmedValue = numericValue;
      setmonth_6(trimmedValue);
      console.log("trimmedValue", trimmedValue);
    }
  };
  const handle12monthprice = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (numericValue) {
      setmonth_12(numericValue);
    } else {
      const trimmedValue = numericValue;
      setmonth_12(trimmedValue);
      console.log("trimmedValue", trimmedValue);
    }
  };

  //   subsction mountly prices functions end

  const searchFilter_branch = (text) => {
    const newData = getAllData.filter((item) => {
      const employee = item.label ? item.label.toUpperCase() : "";
      const price = String(item.mobile_number).toUpperCase();
      const status = String(item.status).toUpperCase();
      const textData = text.toUpperCase();
      return (
        employee.includes(textData) ||
        price.includes(textData) ||
        status.includes(textData)
      );
    });
    settask_arrayholder(newData);
  };

  const onItemPress = (itemId) => {
    let updatedSelectedItems;

    if (getselectedItem.includes(itemId)) {
      updatedSelectedItems = getselectedItem.filter(
        (label) => label !== itemId
      );
    } else {
      updatedSelectedItems = [...getselectedItem, itemId];
    }

    // this.setState({ selectedItems: updatedSelectedItems });
    setSelectedItem(updatedSelectedItems);
    console.log("selectedItems: updatedSelectedItems", updatedSelectedItems);
  };

  const onItemid = (itemId) => {
    let updatedSelectedItems1;

    if (getselectedId.includes(itemId)) {
      updatedSelectedItems1 = getselectedId.filter((id) => id !== itemId);
    } else {
      updatedSelectedItems1 = [...getselectedId, itemId];
    }

    // this.setState({ selectedId: updatedSelectedItems1 });
    setSelectedId(updatedSelectedItems1);
    console.log("selectedItems: 1", updatedSelectedItems1);
  };

  const GetAllUser = async () => {
    setLoading(true);
    const param = {
      user_id: userId,
    };
    await axios
      .post(getall_user, qs.stringify(param))
      .then((Response) => {
        setLoading(false);
        if (Response.data.status == 1) {
          settask_arrayholder(Response.data.result);
          setAllData(Response.data.result);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("get_club_join_fees err", err);
      });
  };

  useEffect(() => {
    axios
      .get(get_club_join_fees)
      .then((Response) => {
        // setIsLoading(true)
        if (Response.data.status == 1) {
          setClubFee(Response.data.result.platform_fee);
        }
      })
      .catch((err) => {
        console.log("get_club_join_fees err", err);
        // setIsLoading(true);
      });
    GetAllUser();
  }, []);

  const removeOwner = (index) => {
    console.log("index", index);
    let result = getselectedItem.filter((item, key) => key != index);
    let resul1 = getselectedItem.filter((item, key) => key != index);
    setSelectedItem(result);
    setSelectedId(resul1);
  };

  // video functionality start

  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(60);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [getuserVid2, setUserVideo2] = useState("");

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 180; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...selectedVideos, file]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  const handleVideoDelete = (index) => {
    const newVideos = [...selectedVideos];
    newVideos.splice(index, 1);
    setSelectedVideos(newVideos);
  };

  // stop recording function
  const StopRecording = () => {
    // setthumbImg("");
    setRecording(false);
    setRecordingstatus(false);
    const minDuration = 30;
    const maxDuration = 60;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (60 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          toast.error(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
            { theme: "colored" }
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(60);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };

  // stop recording function2
  // const stopRecording1 = () => {
  //   setRecording(false);
  //   const minDuration = 30;
  //   const maxDuration = 180;

  //   if (recorderRef.current) {
  //     recorderRef.current.stopRecording(() => {
  //       const blob = recorderRef.current.getBlob();
  //       const stream = videoRef.current.srcObject;
  //       if (stream) {
  //         const tracks = stream.getTracks();
  //         tracks.forEach((track) => track.stop());
  //       }
  //       setRecordedChunks([blob]);
  //       setSelectedVideos([blob]);
  //       setRecordingTime(180);
  //       setIsModalVisiblevideo(false);
  //       // }
  //     });
  //   }
  // };

  // start recording
  const startRecording = () => {
    // setthumbImg("");
    setRecordingstatus(true);
    setRecordingTime(60);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };

  useEffect(() => {
    let timerInterval;
    const minDuration = 30;

    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;

          // Check if the recording time is less than the minimum duration
          if (newTime < minDuration) {
            // Display an error or take other actions
            console.error(
              `Recording time should be at least ${minDuration} seconds.`
            );
          }

          // Check if the recording time reaches 1 second
          if (newTime <= 2) {
            StopRecording(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(60);
  };

  const showimage = () => {
    StopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  // video functionality end

  //   club create api

  const ClubCreate = async () => {
    if (getclubName == "") {
      toast.error("Enter club name", {
        theme: "colored",
      });
    } else if (clubdisplayname == "") {
      toast.error("Enter club display name", {
        theme: "colored",
      });
    } else if (getcontactEmail == "") {
      toast.error("Enter contact email", {
        theme: "colored",
      });
    } else if (getcontactPhone == "") {
      toast.error("Enter contact phone number", {
        theme: "colored",
      });
    } else if (getcontactPhone.length != 10) {
      toast.error("Enter valid phone number", {
        theme: "colored",
      });
    } else if (getaboutClub == "") {
      toast.error("Enter bio", {
        theme: "colored",
      });
    } else if (getselectedId == 0) {
      toast.error("Please Select Owners", {
        theme: "colored",
      });
    } else if (profileImage == null) {
      toast.error("Please Select Profile photo", {
        theme: "colored",
      });
    } else if (coverImage == null) {
      toast.error("Please Select cover photo", {
        theme: "colored",
      });
    } else if (selectedVideos.length <= 0) {
      toast.error("Upload video", {
        theme: "colored",
      });
    } else {
      setLoading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("club_name", getclubName);
      formData.append("email", getcontactEmail);
      formData.append("phone_number", getcontactPhone);
      formData.append("gender", "0");
      formData.append("free_paid", showFree);
      formData.append("amount", getpaid_amount);
      formData.append("bio", getaboutClub);
      // formData.append('logo', this.state.logo);
      // formData.append("location", this.state.location);
      // formData.append(
      //   'location',
      //   this.props.route.params ? this.props.route.params.address : null,
      // );
      formData.append("manager_id", getselectedId.join(", "));
      formData.append("package_time", "1,3,6,12");
      formData.append("facebook_link", getfacebook);
      formData.append("twitter_link", gettwitter);
      formData.append("insta_link", getinstagram);
      formData.append("youtube_link", getyoutube);
      formData.append("linkdin_link", getlinkedin);
      formData.append("club_story", getclubStory);
      formData.append("club_history", getclubHistory);
      formData.append("club_d_name", clubdisplayname);
      formData.append("club_pan_no", getpanNo);
      formData.append("gst_no", getgstNo);

      formData.append(
        "package_price",
        `${getmonth_1},${getmonth_3},${getmonth_6},${getmonth_12}`
      );

      profileImage.forEach((image, index) => {
        formData.append("profile", image);
      });

      // profile image start
      // for (let index = 0; index < profileImage.length; index++) {
      //   const imagePath = profileImage[index];
      //   formData.append(`profile`, imagePath);
      // }
      // profile image end

      coverImage.forEach((image, index) => {
        formData.append("cover_photo", image);
      });

      if (logoImage == null || logoImage == "") {
        formData.append("logo", "");
      } else {
        logoImage.forEach((image, index) => {
          formData.append("logo", image);
        });
        // formData.append("logo", logoImage);
      }

      multiImages.forEach((image, index) => {
        formData.append("img_video[]", image);
      });

      let linktag = "";
      var demolinktag = 0;
      for (let index = 0; index < inputValues.length; index++) {
        if (inputValues[index]) {
          demolinktag = demolinktag + 1;

          if (index > 0) {
            linktag += ",";
          }
          linktag += inputValues[index];
        }
      }
      formData.append("club_social_media_link_data", linktag);
      selectedVideos.forEach((image, index) => {
        formData.append("club_videos", image);
      });
      console.log("formData", formData);
      await axios
        .post(create_club, formData)
        .then((Response) => {
          console.log("Response", Response);
          setLoading(false);
          {
            Response.data.status == 0 &&
              toast.error(Response.data.message, {
                theme: "colored",
              });
          }
          if (Response.data.status == 1) {
            navigate("/ClubOtp", {
              state: {
                email: getcontactEmail,
                phone: getcontactPhone,
                user_id: Response.data.user_id,
                club_id: Response.data.club_id,
              },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const handleGesture = (event) => {
      // Check if the modal is open and the user swipes right (for closing the modal)
      if (
        creatormodal &&
        event.type === "touchmove" &&
        event.touches[0].clientX < 50
      ) {
        setCreatormodal(false);
      }
    };

    // Add event listeners when the modal is open
    if (creatormodal) {
      document.addEventListener("touchmove", handleGesture);
    }

    // Remove event listeners when the modal is closed
    return () => {
      document.removeEventListener("touchmove", handleGesture);
    };
  }, [creatormodal]);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="back_arrow_wrapp">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
        </div>

        <div className="npasset_main_wrapp">
          {/* heading start */}
          <h2 className="npasset_heading">Create Club</h2>
          <div className="npasset_heading_border"></div>
          {/* heading start */}

          {isLoading ? (
            <NewLoader />
          ) : (
            <div className="npasset_inner_page_wrapp">
              <div className="create_club_inputs_wrapp">
                {/* input boxes start */}
                <>
                  {/* club display name */}
                  <div className="creator_name_wrapp mb-2">
                    <p>Club Display Name</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter club display name"
                        value={clubdisplayname}
                        onChange={(e) => setclubdisplayname(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* club name */}
                  <div className="creator_name_wrapp mb-2">
                    <p>Club Name</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter club name"
                        value={getclubName}
                        onChange={(e) => setClubName(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Contact Email */}
                  <div className="creator_name_wrapp mb-2">
                    <p>Contact Email</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter contact email"
                        value={getcontactEmail}
                        onChange={(e) => {
                          setContactEmail(e.target.value);
                        }}
                        onBlur={() => {
                          if (getcontactEmail != "") {
                            if (
                              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                getcontactEmail
                              )
                            ) {
                              toast.error("Enter valid email address", {
                                theme: "colored",
                              });
                            } else {
                            }
                          } else {
                            toast.error("Enter email address", {
                              theme: "colored",
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* Contact Phone Number */}
                  <div className="creator_name_wrapp mb-2">
                    <p>Contact Phone Number</p>
                    <div>
                      <input
                        type="text"
                        inputMode="tel"
                        placeholder="Enter contact phone number"
                        value={getcontactPhone}
                        onChange={(e) => handlePhoneNumberChange(e)}
                        onBlur={() => {
                          console.log("getcontactPhone", getcontactPhone);

                          if (getcontactPhone == "") {
                            toast.error("Enter contact phone number", {
                              theme: "colored",
                            });
                          } else if (getcontactPhone.length < 10) {
                            toast.error(
                              "The contact phone number you entered is invalid. Please enter a valid phone number any try again.",
                              {
                                theme: "colored",
                              }
                            );
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* PAN Number */}
                  <div className="creator_name_wrapp mb-2">
                    <p>PAN Number</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter PAN number"
                        value={getpanNo}
                        onChange={(e) => {
                          // setPanNo(e);
                          const inputValue = e.target.value.toUpperCase();
                          setPanNo(inputValue);
                          // setIsMobile(null);
                        }}
                      />
                    </div>
                  </div>

                  {/* GST Number */}
                  <div className="creator_name_wrapp mb-2">
                    <p>GST Number</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter GST number"
                        value={getgstNo}
                        onChange={(e) => {
                          const inputValue = e.target.value.toUpperCase();
                          setGstNo(inputValue);
                        }}
                      />
                    </div>
                  </div>
                </>
                {/* input boxes end */}

                {/* user can join */}
                <div className="create_club_userjoin_wrapp mb-2">
                  <p>User can join</p>

                  <button
                    onClick={() => {
                      setShowFree(0);
                      setmonth_1("");
                      setmonth_3("");
                      setmonth_6("");
                      setmonth_12("");
                    }}
                  >
                    <img
                      src={
                        showFree == 0
                          ? images.new_accepted_terms
                          : images.new_notaccepted_terms
                      }
                      alt=""
                      // onClick={handleTermChange}
                    />
                    <p>Free</p>
                  </button>

                  <button
                    onClick={() => {
                      setShowFree(1);
                      setmonth_1("");
                      setmonth_3("");
                      setmonth_6("");
                      setmonth_12("");
                    }}
                  >
                    <img
                      src={
                        showFree == 1
                          ? images.new_accepted_terms
                          : images.new_notaccepted_terms
                      }
                      alt=""
                      // onClick={handleTermChange}
                    />
                    <p>Paid</p>
                  </button>
                </div>

                {/* paid club prices inputs satrt */}
                {showFree == 1 && (
                  <div className="creator_name_wrapp mb-2">
                    <p
                      style={{
                        color: "var(--color-main)",
                        lineHeight: "120%",
                        marginBottom: "10px",
                      }}
                    >
                      Platform fees will be calculated as {getclubFee}% of the
                      price you enter
                    </p>
                    <div className="mb-1">
                      <input
                        type="number"
                        placeholder="1 Month price"
                        value={getmonth_1}
                        onChange={(e) => {
                          handleonemonthprice(e);
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <input
                        type="number"
                        placeholder="3 Month price"
                        value={getmonth_3}
                        onChange={(e) => {
                          handlethreemonthprice(e);
                          // setmonth_3(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <input
                        type="number"
                        placeholder="6 Month price"
                        value={getmonth_6}
                        onChange={(e) => {
                          handlesixmonthprice(e);
                          // setmonth_6(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <input
                        type="number"
                        placeholder="1 Year price"
                        value={getmonth_12}
                        onChange={(e) => {
                          handle12monthprice(e);
                        }}
                      />
                    </div>
                  </div>
                )}
                {/* paid club prices inputs end */}

                {/* about your club */}
                <div className="npasset_yes_sec_wrapp mb-2">
                  <p
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "12px",
                      marginTop: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    About Your Club
                  </p>
                  <div
                    className="des_textarea_wrapp"
                    style={{ marginTop: "10px" }}
                  >
                    <textarea
                      name="product_des"
                      rows="6"
                      className="form_input_box"
                      placeholder="Type Here"
                      value={getaboutClub}
                      onChange={(e) => {
                        setAboutClub(e.target.value);
                      }}
                      // value={getdescription}
                      // onChange={(e) => setdescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                {/* club history */}
                <div className="npasset_yes_sec_wrapp mb-2">
                  <p
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "12px",
                      marginTop: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    Club History
                  </p>
                  <div
                    className="des_textarea_wrapp"
                    style={{ marginTop: "10px" }}
                  >
                    <textarea
                      name="product_des"
                      rows="6"
                      className="form_input_box"
                      placeholder="Type Here"
                      value={getclubHistory}
                      onChange={(e) => {
                        setClubHistory(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>

                {/* club story */}
                <div className="npasset_yes_sec_wrapp mb-2">
                  <p
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "12px",
                      marginTop: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    Club Story
                  </p>
                  <div
                    className="des_textarea_wrapp"
                    style={{ marginTop: "10px" }}
                  >
                    <textarea
                      name="product_des"
                      rows="6"
                      className="form_input_box"
                      placeholder="Type Here"
                      value={getclubStory}
                      onChange={(e) => {
                        setClubStory(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>

                {/* select owner */}
                <div className="creator_name_wrapp mb-2">
                  {getselectedItem.length <= 0 ? (
                    <div>
                      <input
                        type="text"
                        placeholder="Select Owners"
                        //   value={getownerName}
                        readOnly
                        onClick={() => {
                          setCreatormodal(true);
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        {getselectedItem.map((itm, index) => {
                          return (
                            <div className="new_create_clubs_selected_ownername_wrapp">
                              <p> {itm}</p>

                              <button
                                // className="delete-button"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                onClick={() => {
                                  removeOwner(index);
                                }}
                              >
                                <img
                                  src={images.side_new_close}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                {/* social media links */}
                <div className="creator_name_wrapp mb-2">
                  <p>Social Media Links</p>
                  <div className="mb-1">
                    <input
                      type="text"
                      placeholder="Facebook"
                      //   value={getownerName}
                      value={getfacebook}
                      onChange={(e) => {
                        setFacebook(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <input
                      type="text"
                      placeholder="Instagram"
                      //   value={getownerName}
                      value={getinstagram}
                      onChange={(e) => {
                        setInstagram(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <input
                      type="text"
                      placeholder="Twitter"
                      //   value={getownerName}
                      value={gettwitter}
                      onChange={(e) => {
                        setTwitter(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <input
                      type="text"
                      placeholder="Youtube"
                      //   value={getownerName}
                      value={getyoutube}
                      onChange={(e) => {
                        setyoutube(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-1">
                    <input
                      type="text"
                      placeholder="Linkdin"
                      //   value={getownerName}
                      value={getlinkedin}
                      onChange={(e) => {
                        setlinkedin(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* profile and logo section start */}
                <div className="create_club_profileandlogo_wrapp mb-2   ">
                  <div
                    className="npasset_yes_sec_wrapp"
                    style={{ alignItems: "flex-start" }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Upload Profile
                    </p>

                    {/* profile start */}
                    <div
                      className="new_creator_video_wrapp"
                      style={{ margin: "8px auto" }}
                    >
                      {profileImage && profileImage.length > 0 ? (
                        <>
                          {profileImage.map((image, index) => (
                            <div
                              className="new_min1_img_preview_single"
                              key={index}
                              style={{ height: "100%", borderRadius: "10px" }}
                            >
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                              />

                              <button
                                className="delete-button"
                                onClick={() => handleProfileDelete()}
                              >
                                <img src={images.close_red} alt="" />
                              </button>
                            </div>
                          ))}
                        </>
                      ) : (
                        <label
                          // onClick={() => {
                          //   setIsModalVisiblevideo(true);
                          //   setRecordedChunks([]);
                          //   setrecordedvideo(false);
                          // }}
                          htmlFor="profile_img"
                          // className="upload-button"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                      )}
                      <input
                        id="profile_img"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={handleProfileChange}
                      />
                    </div>
                    {/* profile end */}
                  </div>

                  <div
                    className="npasset_yes_sec_wrapp"
                    style={{ alignItems: "flex-start" }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Upload Logo
                    </p>

                    {/* logo start */}
                    <div
                      className="new_creator_video_wrapp"
                      style={{ margin: "8px auto" }}
                    >
                      {logoImage && logoImage.length > 0 ? (
                        <>
                          {logoImage.map((image, index) => (
                            <div
                              className="new_min1_img_preview_single"
                              key={index}
                              style={{ height: "100%", borderRadius: "10px" }}
                            >
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                              />

                              <button
                                className="delete-button"
                                onClick={() => handleLogoDelete()}
                              >
                                <img src={images.close_red} alt="" />
                              </button>
                            </div>
                          ))}
                        </>
                      ) : (
                        <label
                          // onClick={() => {
                          //   setIsModalVisiblevideo(true);
                          //   setRecordedChunks([]);
                          //   setrecordedvideo(false);
                          // }}
                          htmlFor="logo_img"
                          // className="upload-button"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                      )}
                      <input
                        id="logo_img"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={handleLogoChange}
                      />
                    </div>
                    {/* logo end */}
                  </div>
                </div>
                {/* profile and logo section end */}

                {/* cover start */}
                <div
                  className="npasset_yes_sec_wrapp mb-2"
                  style={{ alignItems: "flex-start" }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Upload Cover Image
                  </p>

                  <div
                    className="new_creator_video_wrapp"
                    style={{ margin: "8px auto" }}
                  >
                    {coverImage && coverImage.length > 0 ? (
                      <>
                        {coverImage.map((image, index) => (
                          <div
                            className="new_min1_img_preview_single"
                            key={index}
                            style={{ height: "100%", borderRadius: "10px" }}
                          >
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                            />

                            <button
                              className="delete-button"
                              onClick={() => handleCoverDelete()}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </>
                    ) : (
                      <label
                        // onClick={() => {
                        //   setIsModalVisiblevideo(true);
                        //   setRecordedChunks([]);
                        //   setrecordedvideo(false);
                        // }}
                        htmlFor="cover_img"
                        // className="upload-button"
                        className="new_creator_video_upload_button"
                      >
                        <img src={images.new_upload_logo} alt="" />
                      </label>
                    )}
                    <input
                      id="cover_img"
                      type="file"
                      accept="image/*"
                      // capture="camera"
                      onChange={handlecoverChange}
                    />
                  </div>
                </div>
                {/* cover end */}

                {/* video upload start */}
                <div className="npasset_yes_sec_wrapp">
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Upload Authentication Video.
                  </p>

                  {/* creator video start */}
                  <div
                    className="new_creator_video_wrapp"
                    style={{ margin: "8px auto" }}
                  >
                    {selectedVideos && selectedVideos.length <= 0 ? (
                      <>
                        <label
                          onClick={() => {
                            setIsModalVisiblevideo(true);
                            setRecordedChunks([]);
                            setrecordedvideo(false);
                          }}
                          htmlFor="creator_vid"
                          // className="upload-button"
                          className="new_creator_video_upload_button"
                        >
                          <img src={images.new_upload_logo} alt="" />
                        </label>
                        <input
                          disabled
                          id="creator_vid"
                          type="file"
                          accept="video/*"
                          // multiple
                          capture="camera"
                          onChange={(e) => {
                            handleVideoChange(e);
                            // setIsModalVisiblevideo(true);
                          }}
                        />
                      </>
                    ) : (
                      <div
                        className="image-previews"
                        style={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        {selectedVideos.map((image, index) => (
                          <div
                            className="image-preview"
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "10px",
                              width: "100%",
                              height: "100%",
                              background: "#000",
                            }}
                          >
                            {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                            <button
                              onClick={() => {
                                setPreviewVideoModal2(true);
                                setUserVideo2(URL.createObjectURL(image));
                              }}
                              // disabled={gettab3Completed}
                              style={{
                                color: "var(--color-main)",
                                fontSize: "12px",
                                fontFamily: "var(--font-600)",
                                cursor: "default",
                                marginBottom: "25px",
                              }}
                            >
                              {/* Preview */}
                              {/* {getthumbImg != "" ? ( */}
                              <div
                                onClick={() => {
                                  setPreviewVideoModal2(true);
                                  setUserVideo2(URL.createObjectURL(image));
                                }}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "relative",
                                }}
                              >
                                {/* <img
                                      src={images.play_white}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "contain",
                                        margin: "0px",
                                        position: "absolute",
                                        transform: "translate(-50%, -50%)",
                                        left: "50%",
                                        top: "50%",
                                        zIndex: 11,
                                        cursor: "pointer",
                                      }}
                                      alt=""
                                     
                                    /> */}
                              </div>
                              {/* ) : ( */}
                              Preview
                              {/* )} */}
                            </button>
                            <button
                              className="delete-button"
                              onClick={() => handleVideoDelete(index)}
                              style={{ right: "14px" }}
                            >
                              <img src={images.close_red} alt="" />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* creator video end */}
                </div>
                {/* video upload end */}

                {/* upload media images start */}
                <div
                  className="npasset_yes_sec_wrapp"
                  style={{ alignItems: "flex-start" }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Upload Media Images
                  </p>

                  {/* select at least one image start */}

                  <div
                    className="new_creator_video_wrapp"
                    style={{ margin: "10px auto" }}
                  >
                    <label
                      // onClick={() => {
                      //   setIsModalVisiblevideo(true);
                      //   setRecordedChunks([]);
                      //   setrecordedvideo(false);
                      // }}
                      htmlFor="min_1_img"
                      // className="upload-button"
                      className="new_creator_video_upload_button"
                    >
                      <img src={images.new_upload_logo} alt="" />
                    </label>
                    <input
                      id="min_1_img"
                      type="file"
                      accept="image/*"
                      // capture="camera"
                      multiple
                      maxLength={2}
                      onChange={handlemultiChange}
                    />
                  </div>
                  {multiImages && multiImages.length > 0 && (
                    <div className="new_min1_img_preview_wrapp">
                      {multiImages.map((image, index) => (
                        <div
                          className="new_min1_img_preview_single"
                          key={index}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                          />

                          <button
                            className="delete-button"
                            onClick={() => handleImageDelete(index)}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* select at least one image end */}
                </div>
                {/* upload media images end */}

                {/* media link start */}
                <div className="creator_name_wrapp">
                  <p>Add Media Links</p>
                  {inputValues.map((value, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "0px",
                        background: "#000",
                        border: "2px dashed #ff004c",
                        paddingRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Paste here"
                        onChange={(e) =>
                          handleInputChange(e.target.value, index)
                        }
                        //   value={getownerName}
                        className="dashed_input_box"
                      />
                      {!value ? (
                        <img
                          src={images.copy_link_icon}
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      ) : (
                        <div onClick={handleRemoveValue}>
                          <img
                            src={images.side_new_close}
                            style={{
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={() => handleAddValue()}
                    className="new_btn_wrapp"
                    style={{
                      height: "30px",
                      width: "100px",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ fontSize: "10px" }}>Add More</p>
                  </button>
                </div>
                {/* media link end */}

                {/* btn */}
                <button
                  onClick={() => {
                    ClubCreate();
                  }}
                  className="new_btn_wrapp mx-1 mt-3"
                >
                  <p>Submit</p>
                </button>
                {/* btn */}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />

      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  />
                </div>
                <p className="nav_screen_name">Preview</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      {creatormodal && (
        <div
          className="creator_modal_main_warp"
          style={{
            background: "black",
            zIndex: 111,
            position: "fixed",
            height: "100vh",
            overflow: "scroll",
          }}
        >
          <div className="creator_modal_base_warp">
            <div
              className="cp_navbar_wrapp"
              style={{
                justifyContent:
                  getselectedItem.length <= 0 ? "flex-start" : "space-between",
                gap: getselectedItem.length <= 0 ? "30%" : "0px",
              }}
            >
              <img
                onClick={() => {
                  setCreatormodal(false);
                  setAllData([]);
                  setSelectedItem([]);
                }}
                src={images.back}
                className="nav_back_btn"
                alt=""
              />

              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "var(--font-600)",
                }}
              >
                Add Owners
              </p>

              {getselectedItem.length <= 0 ? null : (
                <button
                  onClick={() => {
                    setCreatormodal(false);
                  }}
                  style={{
                    padding: "4px 12px",
                    color: "var(--color-white)",
                    backgroundColor: "var(--color-main)",
                    borderRadius: "10px",
                    fontFamily: "var(--font-600)",
                    fontSize: "14px",
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
          <div className="search mt-2">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                searchFilter_branch(e.target.value);
              }}
            />
            <img className="search-img" src={images.search} alt="" />
          </div>
          {gettask_arrayholder.map((item, index) => {
            const isSelected = getselectedItem.includes(item.label);
            return (
              <button
                onClick={() => {
                  onItemPress(item.label);
                  onItemid(item.id);
                }}
                key={index}
                className="item-container"
              >
                <img
                  src={item.profile}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "30px",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    color: isSelected ? "green" : "white",
                    fontFamily: "var(--font-600)",
                  }}
                >
                  {item.label}
                </p>
              </button>
            );
          })}
        </div>
      )}

      {/* video modal start */}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={StopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  showimage();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* video modal end */}
    </div>
  );
};

export default NewCreateClub;
