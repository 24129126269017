import React, { useEffect, useState } from "react";
import "../../src/pages/viewmanagers/viewmanagers.css";
import images from "../../src/constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../src/common/NavbarSec";
import Cardthree from "../../src/components/feedcard/cardthree";
import {
  edit_profile,
  delete_user_manager,
  show_request,
  get_indian_user,
} from "../../src/utils/Constant";
import axios from "axios";

const Internationaluserrequest = () => {
  const location = useLocation();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [request_co, setrequest_co] = useState(0);
  const maxSelectableUsers =
    request_co == 0
      ? 2
      : request_co == 1
      ? 1
      : request_co == 2
      ? 0
      : request_co == 5
      ? 0
      : 2;

  useEffect(() => {
    _getdata();
  }, []);
  const userId = localStorage.getItem("user_id");
  const [managerdata, setManagerData] = useState([]);

  const _getdata = () => {
    setloading(true);

    const param = {
      user_id: location.state.user_id,
    };

    axios.post(get_indian_user, param).then((Response) => {
      setloading(false);
      console.log("----", JSON.stringify(Response.data, null, 2));
      if (Response.data.status === 1) {
        const result = Response.data;
        setManagerData(result.result);
        setFilteredProducts(result.result);
        setrequest_co(result.request_co);
      } else {
        // setIsModalVisible(true);
        // setCusMsg(Response.data.message);
      }
    });
  };
  const handleUserSelection = (item) => {
    // Check if the user is already selected
    const isAlreadySelected = selectedUsers.some(
      (user) => user.user_id === item.user_id
    );

    // If the user is not already selected and the maximum limit is not reached, allow selection
    if (!isAlreadySelected && selectedUsers.length < maxSelectableUsers) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, item]);
    } else if (isAlreadySelected) {
      // If the user is already selected, remove from the selection
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((user) => user.user_id !== item.user_id)
      );
    }
  };
  const searchFilterBranch = (text) => {
    const newData = filteredProducts.filter((item) => {
      console.log("item", item.firstname);
      const employee = item.firstname
        ? item.firstname.toUpperCase()
        : "".toLowerCase();
      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1;
    });
    setManagerData(newData);
    console.log("asvsvsds", newData);
  };

  const sendUserRequest = async () => {
    setloading(true);

    try {
      const senderId = location.state.user_id; // Replace with the actual sender ID
      const receiverIds = selectedUsers.map((user) => user.user_id).join(",");

      const param = {
        sender_id: senderId,
        receiver_id: receiverIds,
      };
      console.log("param", param);
      const response = await axios.post(
        "https://www.ravi.host/api/sent_user_request",
        param
      );

      // Handle the response as needed
      console.log("API response:", response.data);
      setloading(false);
      if (response.data.status === 1) {
        // addUserId(props.route.params.user_id);
        // addEmail(Response.data.email);
        // addPhoneNumber(props.route.params.user_id);
        // addUserName(Response.data.username);
        // addProfile(Response.data.profile);
        // console.log(
        //   'user_id: props.route.params.user_id',
        //   props.route.params.user_id,
        // );
        navigate(-1);
        setSelectedUsers([]);
      }
    } catch (error) {
      setloading(false);
      console.error("Error sending user request:", error);
    }
  };
  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <NavbarSec name="International user request" />

            <input
              type="text"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                searchFilterBranch(e.target.value);
              }}
              placeholder="Search"
              className="viewmanagers_search"
            />

            {managerdata &&
              managerdata.map((item, index) => {
                return (
                  <div
                    aria-disabled={
                      selectedUsers.length === maxSelectableUsers &&
                      !selectedUsers.includes(item)
                    }
                    onClick={() => {
                      {
                        handleUserSelection(item);
                      }
                    }}
                    className="viewmanagers_details"
                    key={item.id}
                  >
                    <img
                      // className="viewmanagers_details_img"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "30px",
                        objectFit: "cover",
                      }}
                      src={item.profile}
                      alt=""
                    />
                    <b className="name_of_viewmanagers">{item.firstname}</b>
                    <img
                      className="viewmanagers_details_img"
                      src={
                        selectedUsers.some(
                          (user) => user.user_id === item.user_id
                        )
                          ? images.Mybidslist
                          : images.circle
                      }
                      alt=""
                      // onClick={() => deletemanager(item.manager_id)}

                      onClick={() => {
                        // setSelectedManagerId(item.manager_id);
                        // setdeletemodal(true);
                      }}
                    />
                    {/* modal */}
                    {/* modal end */}
                  </div>
                );
              })}
          </div>
        </>
      )}
      {maxSelectableUsers.length == 2 || request_co == 1 || request_co == 0 ? (
        <button
          style={{
            position: "fixed",
            bottom: 30,
            zIndex: 1,
            marginLeft: 10,
            backgroundColor: "#ee1651",
            padding: "10px",
            borderRadius: 5,
          }}
          onClick={() => {
            sendUserRequest();
          }}
        >
          <p style={{ color: "white", fontFamily: "var(--font-600)" }}>
            Submit
          </p>
        </button>
      ) : null}
    </div>
  );
};

export default Internationaluserrequest;
