import React, { useState } from "react";
import "../FeedNewFrame1.css";
import { useSwipeable } from "react-swipeable";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { release_bond_like } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";

const NewResellNfbFrame9 = ({
  msg,
  setShowModal,
  showModal,
  data,
  icon,
  setIsModalOpen,
  setMsg,
  setModalName,
}) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="blue-card-main-wrapp">
      <div
        className={`flip-container ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        <div className="flipper">
          <div className="front">
            <img src={images.new_frame_9_f} className="frame-img" alt="" />
            <img
              src={
                data.product_img_video && data.product_img_video[0].product_icon
              }
              className="inner-img"
              alt="product img"
            />
            <div className="front-card-del-qr">
              <div className="front-card-del-div">
                <div className="front-card-del">
                  <p>
                    {data.product_name?.slice(0, 24)}
                    {data.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {data.creator_name?.slice(0, 20)}{" "}
                    {data.creator_name?.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{data.product_value_data}</p>
                </div>
              </div>
              <div className="front-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="back">
            <img src={images.new_frame_9_b} className="frame-img" alt="" />
            <img
              src={
                data.product_img_video && data.product_img_video[0].product_icon
              }
              className="inner-img"
              alt=""
            />
            <div className="back-card-del-div">
              {data.description && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product Description</p>
                  <p className="des-content">
                    {data.description?.slice(0, 78)}{" "}
                    {data.description?.length > 78 && "..."}
                  </p>
                  {data.description?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.description);
                        setModalName("Product Description");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_history && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product story</p>
                  <p className="des-content">
                    {data.product_history?.slice(0, 78)}{" "}
                    {data.product_history?.length > 78 && "..."}
                  </p>
                  {data.product_history?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_history);
                        setModalName("Product Story");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_highlights && (
                <>
                  <p className="des-name">Product Highlight</p>
                  <p className="des-content">
                    {data.product_highlights?.slice(0, 78)}{" "}
                    {data.product_highlights?.length > 78 && "..."}
                  </p>
                  {data.product_highlights?.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_highlights);
                        setModalName("Product Highlight");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="owner-name-div">
              <p className="owned-by">Owned by</p>
              <p className="owner-name">{data.owner_name}</p>
            </div>
            <div className="back-card-del-qr">
              <div className="back-card-del-div2">
                <div className="back-card-del">
                  <p>
                    {data.product_name?.slice(0, 24)}
                    {data.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {data.creator_name?.slice(0, 20)}{" "}
                    {data.creator_name?.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{data.product_value_data}</p>
                </div>
              </div>
              <div className="back-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewResellNfbFrame9;
