import React from "react";
import "./NewPlayScreen.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import NewLoader from "../../common/newloder/NewLoader";
import V2Modal from "../../common/v2modal/V2Modal";
import { useState } from "react";

const NewPlayScreen = () => {
  const [runTour, setRunTour] = useState(true);
  const [tourOpen, setTourOpen] = useState(false);

  const handleTourEnd = () => {
    setRunTour(false);
    setTourOpen(false);
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      <div className="new_container">
        <div className="play_screen_main_wrapp">
          <div className="play_screen_banner_container">
            <img
              src={images.new_play_screen_img}
              className="play_screen_banner"
              style={{ width: "230px", height: "230px" }}
              alt=""
            />
          </div>

          <div className="play_screen_text_container">
            <h4>Unlock Your</h4>
            <h2>Creative Potential</h2>
            <p>Simple 5-Step Registration</p>
            <p>Secure Data Protection</p>
            <br />
            {/* <span>Join the community of creators</span> */}

            {/* btn */}
            <Link to="/NewOtpScreen" className="new_btn_wrapp">
              <p>Join or Login</p>
            </Link>
            {/* btn */}

            {/* <span>
              Already have an account?
              <Link to={"/login"}>
                <b className="sign_login">LOGIN</b>
              </Link>
            </span> */}
          </div>
        </div>
      </div>

      {/* loader strat */}

      {/* <NewLoader /> */}
      {/* <V2Modal /> */}

      {/* loader end */}
    </div>
  );
};

export default NewPlayScreen;
