// import React from "react";
// import images from "../constants/images";
// import { useLocation } from "react-router-dom";
// import NavbarSec from "../common/NavbarSec";

// const FeedVideo = () => {
//   const location = useLocation();
//   return (
//     <div>
//       <div className="page_main_wrapp">
//         <div className="page_base_wrapp_2">
//           {/* <div className="page_navbar_wrapp">
//             <div className="nav_back_wrapp">
//               <img
//                 src={images.back}
//                 className="nav_back_btn"
//                 alt=""
//                 // onClick={() => setPreviewVideoModal(false)}
//               />
//             </div>
//             <p className="nav_screen_name">Video</p>
//           </div> */}
//           <NavbarSec name="Video" />
//           <video
//             controls
//             autoPlay
//             style={{
//               width: "100%",
//               //   maxHeight: "80vh",
//               minHeight: "80vh",
//               height: "100%",
//               //   border: "1px solid red",
//               marginTop: "10px",
//             }}
//           >
//             <source src={location.state.videoplay} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeedVideo;

import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import images from "../constants/images";

const FeedVideo = () => {
  const videoRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);

  const rotateVideo = () => {
    setRotation((prevRotation) => (prevRotation === 0 ? 90 : 0));
  };

  const restartVideo = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };
  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const video = videoRef.current;
    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);

    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, []);
  return (
    <div>
      <div className="page_main_wrapp">
        <div className="page_base_wrapp_2">
          <NavbarSec name="Video" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "80vh", // Adjust the height as needed
              backgroundColor: "black",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            <video
              ref={videoRef}
              // controls
              autoPlay
              style={{
                transform: `rotate(${rotation}deg)`,
                transformOrigin: "center center",
                width: "auto",
                height: "100%",
              }}
            >
              <source src={location.state?.videoplay} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button onClick={rotateVideo}>
              <img
                src={images.rotate_video}
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "contain",
                }}
                alt=""
              />
            </button>
            <button onClick={togglePlayPause} style={{}}>
              {/* <p>Play</p> */}
              {isPlaying ? (
                <img
                  src={images.pause_white}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={images.play_white}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              )}
            </button>
            <button onClick={restartVideo} style={{}}>
              <img
                src={images.reset_video}
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "contain",
                }}
                alt=""
              />{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedVideo;
