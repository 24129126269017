import React, { useEffect, useRef, useState } from "react";
import "./resellcreateproduct.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AssetLink,
  get_all_Resale_interest_product,
  resale_user_get_product,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Resellcreateproduct = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [getcustomerarray, setcustomerarray] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getimgUrl, setImgUrl] = useState("");
  const [getblob, setBlob] = useState([]);

  useEffect(() => {
    GetResell();
  }, []);

  useEffect(() => {
    console.log("getimgUrl", getimgUrl);

    fetch(getimgUrl)
      .then((response) => response.blob())
      .then((data) => {
        console.log("data -->", data);
        // Use the data blob as needed
      })
      .catch((error) => console.error("Error fetching image:", error));
  }, [getimgUrl]);

  // convert into blob start

  const uploadImage = async (imageUrl) => {
    try {
      // const response = await fetch(imageUrl);
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Add the new blob to the existing blobs array
      setBlob((prevBlobs) => [...prevBlobs, blob]);
      console.log("getblob", blob);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // const uploadImage = async (imageUrl) => {
  //   try {
  //     // Step 1: Fetch the image as a Blob
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();

  //     console.log("blob", blob);
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //   }
  // };

  // convert into blob end

  const GetResell = () => {
    const param = {
      accept_bond_bid_id: location.state.accept_bond_bid_id,
      user_id: userId,
    };
    console.log(param);
    axios
      .post(get_all_Resale_interest_product, qs.stringify(param))
      .then((Response) => {
        console.log("Success", Response.data.result);
        setcustomerarray(Response.data.result);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const [product_name, setProductName] = useState("");
  const [owner_name, setOwnerName] = useState("");
  const [description, setDescription] = useState("");
  const [product_value, setProductValue] = useState("");
  const [is_owner, setIsOwner] = useState("");
  const [product_id, setProductId] = useState("");
  const [yy, setYY] = useState("");
  const [mm, setMM] = useState("");
  const [dd, setDD] = useState("");
  const [product_price, setProductPrice] = useState(0);
  const [images_array, setImagesArray] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [getthumbnailindex, setthumbnailindex] = useState();
  const [min_bid_amount, setMinBidAmount] = useState("");
  const [purchasedvalue, setPurchasedValue] = useState("");
  useEffect(() => {
    getData();
    console.log("product_value 22", product_value);
  }, []);

  useEffect(() => {
    console.log("product_value", product_value);
  }, [product_value]);

  const getData = async () => {
    setIsLoading(true);

    const param = {
      accept_bond_bid_id: location.state.accept_bond_bid_id,
      is_nfb: location.state.is_nfb,
      user_id: userId,
    };

    await axios
      .post(resale_user_get_product, qs.stringify(param))
      .then((Response) => {
        console.log("resale_user_get_product res", Response.data.product_value);
        if (Response.data.status == 1) {
          const result = Response.data.result;
          console.log(JSON.stringify(result, null, 2));
          const imagesArray = result.bond_img_video.map(
            (item) => item.img_video
          );

          const raviIndex = imagesArray.indexOf("uri");
          if (raviIndex !== -1) {
            imagesArray.splice(raviIndex, 1);
          }
          setProductName(result.product_name);
          setOwnerName(result.owner_name);
          setDescription(result.description);
          setProductValue(result.product_value);
          setPurchasedValue(result.bid_amount);
          setIsOwner(result.is_owner);
          setProductId(result.product_id);
          setYY(result.date_of_creation.substr(0, 4));
          setMM(result.date_of_creation.substr(5, 2));
          setDD(result.date_of_creation.substr(8, 10));
          setProductPrice(parseInt(result.product_value) / 100);
          setImagesArray([...imagesArray]);
        } else {
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }
        setIsLoading(false);
      });
  };

  const createProduct = () => {
    const numericProductValue = parseFloat(product_value.replace(/,/g, ""));

    console.log("min_bid_amount >= product_value", numericProductValue / 100);
    if (min_bid_amount == "") {
      setErrorMessage("Enter minimum bid amount");
      setShowModal(true);
    }
    //  else if (getblob.length == 0) {
    //   setErrorMessage("Please Select image");
    //   setShowModal(true);
    // }
    else {
      if (min_bid_amount >= numericProductValue / 100) {
        setIsLoading(true);

        let idString = "";
        var demo = 0;
        for (let index = 0; index < getcustomerarray.length; index++) {
          if (getcustomerarray[index].status == "2") {
            demo = demo + 1;

            if (index > 0) {
              idString += ",";
            }
            idString += getcustomerarray[index].interest_id;
          }
        }

        console.log("Final idString: ", idString);

        let formData = new FormData();

        formData.append(
          "accept_bond_bid_id",
          location.state.accept_bond_bid_id
        );
        formData.append("resale_product_id", location.state.resale_product_id);
        formData.append("product_name", product_name);
        formData.append("owner_name", owner_name);
        formData.append("product_id", product_id);
        formData.append("bond_value", "1");
        let date = yy + "-" + mm + "-" + dd;
        formData.append("date_of_creation", date.substr(0, 10));
        formData.append("description", description);
        formData.append("product_value", product_value);
        formData.append("is_owner", is_owner);
        formData.append("bond_interest_id", idString);
        formData.append("owner_user_id", userId);
        formData.append("minimum_bid_amount", min_bid_amount);
        // images_array.forEach(async (i) => {
        // if (i !== "uri") {
        // formData.append("img_video[]", getblob);
        // getblob.forEach((blob, index) => {
        //   formData.append("img_video[]", blob);
        // });
        // }
        // });

        formData.append("is_nfb", location.state.is_nfb);

        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });
        console.log("FormData: add_resale_user_product ->", formDataObject);

        axios
          .post("https://www.ravi.host/api/add_resale_user_product", formData)
          .then((Response) => {
            setIsLoading(false);
            console.log("add_resale_user_product res", Response.data);
            if (Response.data.status === 1) {
              // alert(Response.data.message);
              // setErrorMessage(Response.data.message);
              // setShowModal(true);
              navigate("/Resellnewattach", {
                state: {
                  product_id: Response.data.resale_product_id,
                },
              });
              // navigation.replace('ResellProduct');
              // navigation.replace('NewAttechResellbond', {
              //   product_id: Response.data.resale_product_id,
              // });
            } else {
              setErrorMessage(Response.data.message);
              setShowModal(true);
              // setIsModalVisible(true);
              // setCusmsg(Response.data.message);
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        setErrorMessage(
          "Please enter minimum bid value " + numericProductValue / 100
        );
        setShowModal(true);
        // setIsModalVisible(true);
        // setCusmsg('Please enter minimum bid value ' + product_value / 100);
      }
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  const handleKeyPress = (e) => {
    // Check if the pressed key is a dot
    if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
      e.preventDefault();
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        {/* <NavbarSec name="Sell Rarerez" /> */}
        {/* navbar start */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Sell rarerez</p>
          <div></div>
        </div>
        {/* navbar end */}

        <div className="npasset_main_wrapp mb-3">
          <div
            className="npasset_inner_page_wrapp"
            style={{ marginBottom: "0px", padding: "1rem 10px" }}
          >
            {/* input boxes start */}
            <div className="">
              {/* Purchased Amount start */}
              <div className="creator_name_wrapp mb-2">
                <p>Purchased Amount</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter value"
                    value={purchasedvalue}
                    readOnly
                    disabled
                    // onChange={(e) =>}
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Purchased Amount end */}

              {/* notify me start */}
              <div className="creator_name_wrapp mb-2">
                <p>Notify me when the bid crosses</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter ammount"
                    value={min_bid_amount}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => {
                      setMinBidAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* notify me end */}

              {/* Asset name start */}
              <div className="creator_name_wrapp mb-2">
                <p>Asset Name/Title</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={product_name}
                    // onChange={(e) =>}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Asset name end */}

              {/* Category start */}
              <div className="mb-2">
                <p className="re_title">Category</p>

                <div style={{ position: "relative", width: "100%" }}>
                  <button
                    className="nav-button left"
                    style={{ padding: "10px 0px", width: "24px" }}
                    onClick={scrollLeft}
                  >
                    <FaAngleLeft color="var(--color-main)" size={22} />
                  </button>
                  <div className="select_resel_cato" ref={sliderRef}>
                    {getcustomerarray &&
                      getcustomerarray.map((item, index) => {
                        return (
                          <div
                            className="select_resel_cato_sub"
                            style={{ alignItems: "center" }}
                          >
                            <div
                              className={
                                item.status == 2
                                  ? "img_resel_new_border"
                                  : "img_resel_new"
                              }
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "8px",
                              }}
                            >
                              <img
                                src={
                                  item.status == 2
                                    ? AssetLink + item.new_color_icon
                                    : AssetLink + item.new_icon
                                }
                                className="img_of_resell"
                                alt=""
                              />
                            </div>
                            <p className="resellcreateproduct_nm">
                              {item.name && item.name.length > 10
                                ? `${item.name.slice(0, 10)}...`
                                : item.name}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  <button
                    className="nav-button right"
                    onClick={scrollRight}
                    style={{
                      justifyContent: "flex-end",
                      padding: "10px 0px",
                      width: "24px",
                    }}
                  >
                    <FaAngleRight color="var(--color-main)" size={22} />
                  </button>
                </div>
              </div>
              {/* Category end */}

              {/* Date of creation start */}
              <div className="mb-2">
                <p className="re_title">Date of creation</p>
                <div
                  className="datepicker_inputs_main_wrapp"
                  style={{ margin: "0px" }}
                >
                  <div className="datepicker_single_input">
                    <select value={dd} style={{ background: "#565656" }}>
                      <option value={dd} disabled>
                        {dd}
                      </option>
                    </select>
                  </div>
                  -
                  <div className="datepicker_single_input">
                    <select value={mm} style={{ background: "#565656" }}>
                      <option value={mm} disabled>
                        {mm}
                      </option>
                    </select>
                  </div>
                  -
                  <div className="datepicker_single_input">
                    <select value={yy} style={{ background: "#565656" }}>
                      <option value={yy} disabled selected>
                        {yy}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {/* Date of creation end */}

              {/* description start */}

              <div className="npasset_yes_sec_wrapp mb-2">
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "14px",
                  }}
                >
                  Description
                </p>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    value={description}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  ></textarea>
                </div>
              </div>

              {/* description end */}

              {/* curunt owner start */}
              <div className="create_club_userjoin_wrapp mb-2">
                <p style={{ lineHeight: "125%" }}>
                  Are you the current owner ?
                </p>

                <button>
                  <img
                    src={
                      is_owner !== 0
                        ? images.new_accepted_terms
                        : images.new_notaccepted_terms
                    }
                    alt=""
                    // onClick={handleTermChange}
                  />
                  <p>Yes</p>
                </button>

                <button>
                  <img
                    src={
                      is_owner === 0
                        ? images.new_accepted_terms
                        : images.new_notaccepted_terms
                    }
                    alt=""
                    // onClick={handleTermChange}
                  />
                  <p>No</p>
                </button>
              </div>
              {/* curunt owner end */}

              {/* Owner name start */}
              <div className="creator_name_wrapp mb-2">
                <p>Enter owner's name</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={owner_name}
                    // onChange={(e) =>}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Owner name end */}

              {/* uploaded images start */}
              <div className="mb-2" style={{ alignItems: "flex-start" }}>
                <p className="re_title">Upload Media Images</p>

                <div
                  className="new_min1_img_preview_wrapp"
                  style={{ marginBottom: "1rem" }}
                >
                  {images_array &&
                    images_array.map((item, index) => {
                      return item == "uri" ? null : (
                        <div
                          className="new_min1_img_preview_single"
                          key={index}
                        >
                          <img src={item} alt={`Preview ${index}`} />
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* uploaded images end */}

              {/* Asset value start */}
              <div className="creator_name_wrapp mb-2">
                <p>Enter asset value</p>

                <div>
                  <input
                    type="text"
                    placeholder="Enter value"
                    value={product_value}
                    // onChange={(e) =>}
                    readOnly
                    disabled
                    style={{ background: "#565656" }}
                  />
                </div>
              </div>
              {/* Asset value end */}
            </div>
            {/* input boxes end */}
          </div>
          <button
            onClick={() => {
              createProduct();
            }}
            className="new_btn_wrapp"
          >
            <p>Resell</p>
          </button>
        </div>
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Resellcreateproduct;
