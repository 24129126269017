import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import Modal from "react-modal";
import "./Wallet.css";
import BackButton from "../../components/BackButton";
import { useWalletContext } from "../../context/user_wallet_context";
import AlertModal from "../../components/AlertModal";
import NavbarSec from "../../common/NavbarSec";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";
import Urls from "../../utils/Urls";
import { loadStripe } from "@stripe/stripe-js";

const Wallet = () => {
  const [selectedFilter, setSelectedFilter] = useState(0); // Set initial state to "all"
  const [isAddCashModalOpen, setIsAddCashModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [getrecharge, setrecharge] = useState(null);
  const [getsuccessrecharge, setgetsuccessrecharge] = useState("");
  const [getcusmodal, setcusmodal] = useState(false);
  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg, setcusmsg] = useState("");
  const [getcusmsg1, setcusmsg1] = useState("");
  const [getwithdrew, setwithdrew] = useState(null);
  const [getaddLoading, setAddLoading] = useState(false);
  const [getwidLoading, setWidLoading] = useState(false);
  const [new_total_amount, setNewTotalAmount] = useState(null);
  const [getloading, setloading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    get_user_wallet,
    get_user_wallet_loading,
    get_userwallet_data,
    get_user_wallet_recharge,
    getUserWalletRecharge,
    get_user_wallet_recharge_loading,
    get_userwallet_recharge_data,
    total_amount,
    getUserWalletwithdrew,
    get_user_wallet_withdrew_loading,
    get_userwallet_withdrew_data,
  } = useWalletContext();

  function convertToInteger(input) {
    // Ensure the input is a string
    const numberString = input.toString();

    // Remove commas from the string
    const numberWithoutCommas = numberString.replace(/,/g, "");

    // Convert the string to an integer
    return parseInt(numberWithoutCommas, 10);
  }

  // useEffect(() => {
  //   setNewTotalAmount(convertToInteger(total_amount))
  // }, []);

  const new_total_amount2 = convertToInteger(total_amount);

  console.log("integerNumber", new_total_amount2);

  const handleFilterChange = (filter) => {
    get_data(filter);
    setSelectedFilter(filter);
  };
  const openAddCashModal = () => {
    setrecharge(null);
    setIsAddCashModalOpen(true);
  };

  const closeAddCashModal = () => {
    setIsAddCashModalOpen(false);
  };

  const openWithdrawModal = () => {
    setwithdrew(null);
    setIsWithdrawModalOpen(true);
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
  };

  useEffect(() => {
    get_data(0);
  }, []);

  const userId = localStorage.getItem("user_id");

  const get_data = async (filter) => {
    const params = {
      user_id: userId,
      status: filter,
    };
    const data = await get_user_wallet(params, setcusmodal1, setcusmsg1);
    console.log("get_user_wallet", data);
  };

  useEffect(() => {
    console.log("get_userwallet_recharge_data", get_userwallet_recharge_data);
    setgetsuccessrecharge(get_userwallet_recharge_data);
    if (get_userwallet_recharge_data.status == 1) {
      // setcusmsg(get_userwallet_recharge_data.message);
      setIsAddCashModalOpen(false);
      get_data(0);
      // setcusmodal(true);
    }
  }, [get_userwallet_recharge_data]);

  useEffect(() => {
    if (get_userwallet_withdrew_data.status == 1) {
      // setcusmsg1(get_userwallet_withdrew_data.message);
      setIsWithdrawModalOpen(false);
      get_data(0);
      // setcusmodal1(true);
    }
  }, [get_userwallet_withdrew_data]);

  const recharge_user = async () => {
    if (getrecharge == null) {
      setcusmsg("Please enter amount");
      setcusmodal(true);
    } else {
      makePayment();
      // const param = {
      //   amount: getrecharge,
      //   user_id: userId,
      // };
      // const data = await getUserWalletRecharge(
      //   param,
      //   setcusmodal1,
      //   setcusmsg1,
      //   setAddLoading
      // );
      // console.log("getUserWalletRecharge", data);
    }
  };
  const Withdraw_user = async () => {
    if (getwithdrew == null) {
      setcusmsg1("Please enter amount");
      setcusmodal1(true);
    } else if (
      parseInt(getwithdrew) >= new_total_amount2
      // parseInt(getwithdrew) >= parseInt(total_amount.replace(/,/g, ""))
    ) {
      setcusmsg1("Withdrawal amount cannot exceed your balance.");
      setcusmodal1(true);
      console.log("new_total_amount", new_total_amount2);
    } else {
      const param = {
        amount: getwithdrew,
        user_id: userId,
      };
      await getUserWalletwithdrew(
        param,
        setcusmodal1,
        setcusmsg1,
        setWidLoading
      );
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (
      isAddCashModalOpen ||
      isWithdrawModalOpen ||
      getcusmodal ||
      getcusmodal1
    ) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [
    isAddCashModalOpen,
    isWithdrawModalOpen,
    getcusmodal1,
    getcusmodal,
    getcusmodal1,
    bodyScrollEnabled,
  ]);

  const closemodal = () => {
    setcusmodal(false);
  };
  const openmodal = () => {
    setcusmodal(true);
  };

  const closemodal1 = () => {
    setcusmodal1(false);
  };
  const openmodal1 = () => {
    setcusmodal1(true);
  };

  const stripePromise = loadStripe(
    "pk_live_51ODMf4SIZS16AN382v1WLRYA0m5T0xqKIjkWwJCYo2eLmEVR6JlSxQM1MIgvQNl6NJewKCQBR2F6JqMqhKrhm5O7000ONaUVem"
  );

  // Storing data in sessionStorage
  // const saveDataToSessionStorage = (key, data) => {
  //   const storedData = JSON.parse(sessionStorage.getItem(key)) || "";
  //   storedData.push(data);
  //   sessionStorage.setItem(key, JSON.stringify(storedData));
  // };

  const makePayment = async () => {
    setloading(true);
    try {
      const apiUrl = "https://www.ravi.host/api/stripe_payment";
      const datas = {
        user_id: userId,
        amount: 1, // Replace with the desired amount
        // amount: getplatform_fees_data.stripe_total, // Replace with the desired amount
        success_url: ` ${Urls.main_url}NewAddBalanceSucess`, // Replace with your success URL
        fail_url: `${Urls.main_url}Fail`, // Replace with your fail URL
      };
      console.log("dddd", datas);
      const response = await axios.post(apiUrl, datas);
      console.log("Response: " + JSON.stringify(response.data));
      // Handle the response data as needed
      // setResponsesession(response.data.session_id);
      if (response.data.success === true) {
        setloading(false);
        // saveDataToSessionStorage("myItems", getplatform_fees_data);
        // saveDataToSessionStorage("location_data", location.state);
        localStorage.setItem("allres", JSON.stringify(response.data));

        // saveDataToSessionStorage("add_amount", JSON.stringify(getrecharge));

        sessionStorage.setItem("add_amount", JSON.stringify(getrecharge));

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      } else {
        alert("something went wrong ");
        sessionStorage.clear();
        setloading(false);
      }
    } catch (error) {
      // Handle errors
      console.error("Error making payment:", error);
      setloading(false);
    }
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* <div className="page_navbar_wrapp">
          <div className="nav_back_wrapp">
            <img src={images.back} className="nav_back_btn" alt="" />
          </div>
          <p className="nav_screen_name">Wallet</p>
        </div> */}
        {/* <NavbarSec name="Transactions" /> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate("/homescreen2");
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Transactions</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        {get_user_wallet_loading ||
        get_user_wallet_recharge_loading ||
        get_user_wallet_withdrew_loading ? (
          <div className="loader"></div>
        ) : (
          <>
            <div className="wallet_details">
              <p className="total_wallet_rupee">₹ {total_amount}</p>
              <p className="wl_titel">My Balance</p>
            </div>
            <div className="container_bank">
              <Link to="/ListOfBankAccounts" className="manage_account_btn">
                Manage Bank Account
              </Link>
              {/* <Link to="/userbankaccount" className="manage_account_btn">
                Manage Bank Account
              </Link> */}
            </div>
            {/* <br /> */}
            <div className="add_with">
              <div className="add_with">
                <button className="add_with_1" onClick={openAddCashModal}>
                  <img src={images.addcash} className="wallet_tran" alt="" />
                  <h1 className="add_name">Recharge Wallet</h1>
                </button>
                <div className="add_with_c"></div>
                <div className="add_with_2" onClick={openWithdrawModal}>
                  <img src={images.withcash} className="wallet_tran" alt="" />
                  <h1 className="add_name">Withdraw</h1>
                </div>
              </div>
            </div>
          </>
        )}
        <br />
        <div className="Transactions_container">
          <p className="Transactions_text">Transactions</p>
          <a
            href={get_userwallet_data[0]?.generate_statement}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="download_text">Download</p>
          </a>
        </div>
        <div className="wallet_tab_btns_wrapp">
          <button
            className={`wallet_tab_btn ${selectedFilter === 0 ? "active" : ""}`}
            onClick={() => handleFilterChange(0)}
          >
            All
          </button>
          <button
            className={`wallet_tab_btn ${selectedFilter === 1 ? "active" : ""}`}
            onClick={() => handleFilterChange(1)}
          >
            Credit
          </button>
          <button
            className={`wallet_tab_btn ${selectedFilter === 2 ? "active" : ""}`}
            onClick={() => handleFilterChange(2)}
          >
            Debit
          </button>
        </div>

        {selectedFilter === 0 || selectedFilter == 1 || selectedFilter == 2 ? (
          <>
            {get_userwallet_data.map((item, index) => {
              return (
                <div style={{ width: "100%" }}>
                  <div className="wallet_entry_wrapp">
                    <div className="all_list1">
                      {item.is_accept == "2" ||
                      item.is_accept == "4" ||
                      item.is_accept == "3" ||
                      item.is_accept == "5" ? (
                        <img src={images.UpArrow} className="" alt="" />
                      ) : (
                        <img src={images.DownArrow} className="" alt="" />
                      )}
                    </div>
                    <div className="all_list2">
                      <p
                        className={
                          item.is_accept == 2 ||
                          item.is_accept == 4 ||
                          item.is_accept == 3 ||
                          item.is_accept == 5
                            ? "Account-name"
                            : "credit_active"
                        }
                      >
                        {item.is_accept == "1"
                          ? "Account debited ₹ " + item.is_debit
                          : item.is_accept == "2"
                          ? "Account credited  ₹ " + item.is_refund
                          : item.is_accept == "3"
                          ? "Account credited ₹ " + item.is_royalty
                          : item.is_accept == "4"
                          ? "Account credited ₹ " + item.owner_amount
                          : item.is_accept == "5"
                          ? "Account credited ₹ " + item.owner_amount
                          : null}
                      </p>
                      <p className="by-text"> For : {item.main_product}</p>
                      <p className="wallet-recharged">
                        {item.is_accept == "1"
                          ? item.main_product == "Withdrawal"
                            ? "Withdrawal"
                            : "Bidding amount Deducted " + item.main_product
                          : item.is_accept == "2"
                          ? "Refund " + item.main_product
                          : item.is_accept == "3"
                          ? "Royalty Received -  " +
                            item.main_product +
                            " Rarerez (" +
                            item.bidder_user_names +
                            ")"
                          : item.is_accept == "4"
                          ? "" +
                            "Payment  Received  For Rarerez " +
                            item.main_product +
                            " " +
                            item.bidder_user_names
                          : item.is_accept == "5"
                          ? "Wallet Recharged "
                          : null}
                      </p>
                    </div>
                    {/* <div className="all_list3">
                    <p
                      className={
                        item.is_accept == "4" ||
                        item.is_accept == "3" ||
                        item.is_accept == "5"
                          ? "credit_p"
                          : "credit_active"
                      }
                    >
                      +25.k
                    </p>
                  </div> */}
                  </div>
                  <div className="wallet_entry_line" />
                </div>
              );
            })}
          </>
        ) : null}
        {/* {selectedFilter === 1 && (
        <div className="credit">
          <div className="wallet_entry_wrapp">
            <div className="all_list1">
              <img src={images.UpArrow} className="wallet_tran" alt="" />
            </div>
            <div className="all_list2">
              <p>user credit</p>
              <p>user details</p>
            </div>
            <div className="all_list3">
              <p className="credit_p">+25.k</p>
            </div>
          </div>
          <hr />
          <div className="wallet_entry_wrapp">
            <div className="all_list1">
              <img src={images.UpArrow} className="wallet_tran" alt="" />
            </div>
            <div className="all_list2">
              <p>user credit</p>
              <p>user details</p>
            </div>
            <div className="all_list3">
              <p className="credit_p">+25.k</p>
            </div>
          </div>
          <hr />
        </div>
      )}
      {selectedFilter === 2 && (
        <div className="debit">
          <div className="wallet_entry_wrapp">
            <div className="all_list1">
              <img src={images.DownArrow} className="wallet_tran" alt="" />
            </div>
            <div className="all_list2">
              <p>user debit</p>
              <p>user details</p>
            </div>
            <div className="all_list3">
              <p className="debit_p">+25.k</p>
            </div>
          </div>
          <hr />
          <div className="wallet_entry_wrapp">
            <div className="all_list1">
              <img src={images.DownArrow} className="wallet_tran" alt="" />
            </div>
            <div className="all_list2">
              <p>user debit</p>
              <p>user details</p>
            </div>
            <div className="all_list3">
              <p className="debit_p">+25.k</p>
            </div>
          </div>

          <hr />
        </div>
      )} */}

        {/* Add Cash Modal */}
        <Modal
          isOpen={isAddCashModalOpen}
          onRequestClose={closeAddCashModal}
          className="modal_w"
          overlayClassName="modal-overlay"
          style={{ zIndex: 111, position: "absolute" }}
        >
          <img src={images.logo} className="modal_img_wallet" alt="" />
          <h3>Recharge Your Wallet</h3>
          <input
            value={getrecharge}
            onChange={(e) => {
              setrecharge(e.target.value);
            }}
            type="number"
            className="w_input"
            placeholder="Enter amount"
          />
          <div className="footer_center">
            <div className="can_1">
              <button onClick={closeAddCashModal}>Cancel</button>
            </div>
            <div className="can_2">
              {getaddLoading ? (
                <div className="loader"></div>
              ) : (
                <button onClick={() => recharge_user()}>Okay</button>
              )}
            </div>
          </div>
        </Modal>

        {/* Withdraw Modal */}
        <Modal
          isOpen={isWithdrawModalOpen}
          onRequestClose={closeWithdrawModal}
          className="modal_w"
          overlayClassName="modal-overlay"
        >
          <img src={images.logo} className="modal_img_wallet" alt="" />
          <h3>Withdraw Your Balance</h3>
          <input
            value={getwithdrew}
            onChange={(e) => {
              setwithdrew(e.target.value);
            }}
            type="number"
            className="w_input"
            placeholder="Enter amount"
          />
          <div className="footer_center">
            <div className="can_1">
              <button onClick={closeWithdrawModal}>Cancel</button>
            </div>
            <div className="can_2">
              {getwidLoading ? (
                <div className="loader"></div>
              ) : (
                <button
                  onClick={() => {
                    // Withdraw_user();
                    setcusmsg(
                      "Your withdrawal request has been submitted. You will be informed within 3 to 5 working days."
                    );
                    setcusmodal(true);
                    setIsWithdrawModalOpen(false);
                  }}
                >
                  Okay
                </button>
              )}
            </div>
          </div>
          {/* Withdraw form or input */}
        </Modal>
        {getcusmodal && (
          // <AlertModal
          //   setShowModal={setcusmodal}
          //   // title={"Success"}
          //   msg={getcusmsg}
          // />

          // <NewModal
          //   title=""
          //   msg={getcusmsg}
          //   setShowModal={openmodal}
          //   closemodal={closemodal}
          // />
          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setcusmodal}
            okfunction={closemodal}
          />
        )}
        {getcusmodal1 && (
          // <AlertModal
          //   setShowModal={setcusmodal1}
          //   // title={"Success"}
          //   msg={getcusmsg1}
          // />

          // <NewModal
          //   title=""
          //   msg={getcusmsg1}
          //   setShowModal={openmodal1}
          //   closemodal={closemodal1}
          // />

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg1}
            setShowModal={setcusmodal1}
            okfunction={closemodal1}
          />
        )}
      </div>
    </div>
  );
};

export default Wallet;
