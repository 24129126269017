import React, { useState, useEffect } from "react";
import images from "../../constants/images";
// import "./cardsecond.css";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";

const Productcardeight = ({ item, setIsModalOpen, setMsg, setModalName }) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };

  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="centered">
      <div
        className={`flip-card ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        <div className="flip-card-inner_card">
          <div
            className="card_flip-card-front"
            onClick={() => {
              navigate("/ProductViewImg", {
                state: {
                  img: item.product_image_video ? item.product_image_video : "",
                },
              });
            }}
          >
            <div className="crad_8_main_img_front">
              <img
                src={
                  item.product_image_video
                    ? item.product_image_video[
                        item.product_image_video.length - 1
                      ].img_video
                    : item.bond_media_upload &&
                      item.bond_media_upload[item.bond_media_upload.length - 1]
                        .img_video
                }
                className="crad_8_img_front"
                alt=""
              />
              <div className="sc_f_blur_8">
                <div className="sc_qr_div">
                  <img src={item.qr_code} className="crad_8_qr_front" alt="" />
                </div>
                <div className="nm_of_user_8_div">
                  <b className="nm_of_user_8"> {item.product_name}</b>
                </div>
              </div>
              <div className="sc_f_details_8">
                <div className="sc_f_details1_8">
                  <p className="nm_of_cre_titel">Creator</p>
                  <b className="nm_of_cre_nmr">{item.owner_name}</b>
                </div>
                <div className="sc_f_details2_8">
                  <p className="sc_p_nm_8">Asset value</p>
                  {/* <p className="sc_p_nm">value</p> */}
                  <b className="sc_price_8">₹{item.product_value}</b>
                </div>
                <div className="sc_f_details3_8">
                  <img src={images.logo_white} className="sc_img_f_8" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="card_flip-card-back">
            <div className="crad_8_main_img_back">
              <div className="ditails_of_back_cs_8_nfb" style={{}}>
                {item.description && (
                  <>
                    <b>Asset Description</b>
                    <p className="details_bg_card">
                      {item.description.slice(0, 100)}{" "}
                      {item.description.length > 100 && "..."}
                    </p>
                    {item.description.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.description);
                          setModalName("Asset Description");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {item.user_product_history && (
                  <>
                    <b>Asset story</b>
                    <p className="details_bg_card">
                      {item.user_product_history.slice(0, 100)}{" "}
                      {item.user_product_history.length > 100 && "..."}
                    </p>
                    {item.user_product_history.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.user_product_history);
                          setModalName("Asset story");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {item.product_highlights && (
                  <>
                    <b>Asset Highlight</b>
                    <p className="details_bg_card">
                      {item.product_highlights.slice(0, 100)}{" "}
                      {item.product_highlights.length > 100 && "..."}
                    </p>
                    {item.product_highlights.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.product_highlights);
                          setModalName("Asset Highlight");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
              </div>
              <img
                src={
                  item.product_image_video
                    ? item.product_image_video[
                        item.product_image_video.length - 1
                      ].img_video
                    : item.bond_media_upload &&
                      item.bond_media_upload[item.bond_media_upload.length - 1]
                        .img_video
                }
                className="crad_8_img_back"
                alt=""
                onClick={() => {
                  navigate("/ProductViewImg", {
                    state: {
                      img: item.product_image_video
                        ? item.product_image_video
                        : "",
                    },
                  });
                }}
              />
            </div>
            <div className="sc_f_details_back_8">
              <p className="nm_of_cre_titel_back">Owned by</p>
              <b className="nm_of_cre_nmr_back">{item.username}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productcardeight;
