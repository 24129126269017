import React, { useState } from "react";
import images from "../../constants/images";
import "./signup.css";
import axios from "axios";
import qs from "qs";
import {
  check_is_user,
  getPincodes,
  get_all_country,
  get_all_interest,
  get_user_emailcheck,
  get_user_number,
  getcitybystate,
  getstatebycountry,
  user_register,
} from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Termsandcondition from "../termsandcondition/termsandcondition";
import BackButton from "../../components/BackButton";
import NewModal from "../../common/newmodal/NewModal";

const SignUp = () => {
  const location = useLocation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [is_email, setIsEmail] = useState(null);
  const [getuserName, setUserName] = useState("");
  const [is_username, setIsUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(101);
  const [countryCode, setCountryCode] = useState("91");
  const [countryName, setCountryName] = useState("India");
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [statesName, setStatesName] = useState("");
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [cityName, setCityName] = useState("");
  const [getpincode, setPincode] = useState([]);
  const [getpincodeName, setPincodeName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [is_mobile, setIs_mobile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [getAddress1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [password, setPassword] = useState("");
  const [getIp, setIp] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isInputValid, setInputValid] = useState(false);
  const [isSpecialCharacterMissing, setSpecialCharacterMissing] =
    useState(false);
  const [isMinLengthInvalid, setMinLengthInvalid] = useState(false);
  const [isNumericCharacterMissing, setNumericCharacterMissing] =
    useState(false);
  const [isUppercaseMissing, setUppercaseMissing] = useState(false);
  const [isLowercaseMissing, setLowercaseMissing] = useState(false);
  const [getcusmsg, setcusmsg] = useState("");
  const [phonedisable, setphonedisable] = useState(false);
  const [gettermsmodal, settermsmodal] = useState(false);
  const [fullnamecon, setfullnamecon] = useState(false);
  const [getmobileErrMsg, setMobileErrMsg] = useState("");
  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };
  useEffect(() => {
    console.log("getpincode", getpincode);
  }, [getpincode]);

  const navigate = useNavigate();

  const regEx =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    Countryget();
    FetchIpAddress();
  }, []);

  useEffect(() => {
    console.log("isInputValid", isInputValid);
  }, [isInputValid]);

  useEffect(() => {
    console.log("sign up states -->", location.state);
  }, []);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    console.log("event", event.target.value);
    // event.target.value == 0 ?
    if (inputValue.length === 0) {
      setIsEmail(null);
    }
    setEmail(event.target.value);
  };

  const handleUserNameChange = (event) => {
    const inputValue = event.target.value;
    console.log("event", inputValue);

    if (inputValue.length === 0) {
      setIsUserName(null); // Clear the validation status
    }

    setUserName(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent entering space
    }
  };

  // const handlePhoneNumberChange = (event) => {
  //   const value = event.target.value;
  //   if (/^\d*$/.test(value) && value.length <= 10) {
  //     setPhoneNumber(value);
  //     //  setErrorMessage("");
  //   } else {
  //     // setphonedisable()
  //     alert("Please enter up to 10 digits.");
  //   }
  //   // console.log("event", event.target.value.replace(/[^0-9]/g, ""));
  //   // event.target.value.length === 0
  //   //   ? setIs_mobile(null)
  //   //   : setPhoneNumber(event.target.value.replace(/[^0-9]/g, ""));
  //   // setPhoneNumber(event.target.value);
  // };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setPhoneNumber(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setPhoneNumber(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleAddress1Change = (event) => {
    const filteredValue = event.target.value.replace(
      /[\uD800-\uDBFF][\uDC00-\uDFFF]/g,
      ""
    );

    setAddress1(filteredValue);
  };

  const handleAddress2Change = (event) => {
    const filteredValue2 = event.target.value.replace(
      /[\uD800-\uDBFF][\uDC00-\uDFFF]/g,
      ""
    );
    setAddress2(filteredValue2);
  };

  // const handlePasswordChange = (event) => {
  //   setPassword(event.target.value);
  // };

  // const handleConfirmPasswordChange = (event) => {
  //   setConfirmPassword(event.target.value);
  // };

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlesignup = () => {
    AddUser();
  };

  const handleSignup = () => {
    console.log("Navigate to sign-up page");
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    settermsmodal(false);
    setIsChecked(!isChecked);
  };
  const handleCheckboxChangealert = () => {
    settermsmodal(false);
    setIsChecked(true);
  };
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);

  // Function to handle password change
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validateInput(newPassword);
  };

  // Function to handle confirm password change
  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);

    // Check if confirm password matches the password
    setConfirmPasswordValid(newConfirmPassword === password);
  };

  // confirm password start

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const [password1, setPassword1] = useState("");
  const [isInputFocused1, setIsInputFocused] = useState(false);
  const [isInputValid1, setIsInputValid] = useState(false);
  const [isSpecialCharacterMissing1, setIsSpecialCharacterMissing] =
    useState(false);
  const [isMinLengthInvalid1, setIsMinLengthInvalid] = useState("");
  const [isNumericCharacterMissing1, setIsNumericCharacterMissing] =
    useState(false);
  const [isUppercaseMissing1, setIsUppercaseMissing] = useState(false);
  const [isLowercaseMissing1, setIsLowercaseMissing] = useState(false);

  // State for the confirm_password field
  const [confirmPassword1, setConfirmPassword1] = useState("");
  const [isInputFocusedConfirmPassword, setIsInputFocusedConfirmPassword] =
    useState(false);
  const [isInputValidConfirmPassword, setIsInputValidConfirmPassword] =
    useState(false);
  const [
    isSpecialCharacterMissingConfirmPassword,
    setIsSpecialCharacterMissingConfirmPassword,
  ] = useState(false);
  const [
    isMinLengthInvalidConfirmPassword,
    setIsMinLengthInvalidConfirmPassword,
  ] = useState(false);
  const [
    isNumericCharacterMissingConfirmPassword,
    setIsNumericCharacterMissingConfirmPassword,
  ] = useState(false);
  const [
    isUppercaseMissingConfirmPassword,
    setIsUppercaseMissingConfirmPassword,
  ] = useState(false);
  const [
    isLowercaseMissingConfirmPassword,
    setIsLowercaseMissingConfirmPassword,
  ] = useState(false);

  // Function to validate the password field
  const [valid, setvalid] = useState(false);
  const [valid1, setvalid1] = useState(false);
  const validateInput1 = (e) => {
    const ee = e;
    console.log("ww", ee);
    const isSpecialCharacterMissing =
      !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(ee);
    const isMinLengthInvalid = ee.length < 8;
    const isNumericCharacterMissing = !/\d/.test(ee);
    const isUppercaseMissing = !/[A-Z]/.test(ee);
    const isLowercaseMissing = !/[a-z]/.test(ee);

    const isInputValid =
      isInputFocused1 ||
      (!isLowercaseMissing &&
        !isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing);

    const isInputValid23 =
      !isLowercaseMissing &&
      !isSpecialCharacterMissing &&
      !isMinLengthInvalid &&
      !isNumericCharacterMissing &&
      !isUppercaseMissing;

    console.log("isInputValid23", isInputValid23);
    setvalid(isInputValid23);
    setIsInputValid(isInputValid);
    setIsSpecialCharacterMissing(isSpecialCharacterMissing);
    setIsMinLengthInvalid(isMinLengthInvalid);
    setIsNumericCharacterMissing(isNumericCharacterMissing);
    setIsUppercaseMissing(isUppercaseMissing);
    setIsLowercaseMissing(isLowercaseMissing);
  };

  // Function to validate the confirm_password field
  const validateInputConfirmPassword = (e) => {
    const ee = e;
    const isSpecialCharacterMissing = !/[!@#$%^&]/.test(ee);
    const isMinLengthInvalid = ee.length < 8;
    const isNumericCharacterMissing = !/\d/.test(ee);
    const isUppercaseMissing = !/[A-Z]/.test(ee);
    const isLowercaseMissing = !/[a-z]/.test(ee);

    const isInputValid =
      isInputFocusedConfirmPassword ||
      (!isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing &&
        !isLowercaseMissing);
    const isInputValid1 =
      !isSpecialCharacterMissing &&
      !isMinLengthInvalid &&
      !isNumericCharacterMissing &&
      !isUppercaseMissing &&
      !isLowercaseMissing;
    setvalid1(isInputValid1);

    setIsInputValidConfirmPassword(isInputValid);
    setIsSpecialCharacterMissingConfirmPassword(isSpecialCharacterMissing);
    setIsMinLengthInvalidConfirmPassword(isMinLengthInvalid);
    setIsNumericCharacterMissingConfirmPassword(isNumericCharacterMissing);
    setIsUppercaseMissingConfirmPassword(isUppercaseMissing);
    setIsLowercaseMissingConfirmPassword(isLowercaseMissing);
  };

  // confirm password end

  const handlename = () => {
    setfullnamecon(false);
    const full_name = fullName;
    const nameParts = full_name.split(" ");

    const filteredArray = nameParts.filter((item) => item.trim() !== "");
    if (filteredArray.length >= 2) {
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");

      // Perform further actions with the first name and last name
    } else {
      setfullnamecon(true);
      // alert("Please enter full name");
      setcusmsg("Please enter full name");
      setShowModal(true);
    }
  };

  const Countryget = () => {
    axios.get(get_all_country).then((Response) => {
      if (Response.data.status === 1) {
        setCountry(Response.data.data);
        console.log("get_all_country Response", Response.data);
      } else {
        console.log("get_all_country err", Response.data);
      }
      console.log("param", Response.data.data);
      console.log("====================================");
    });
  };

  useEffect(() => {
    console.log("isMinLengthInvalid111", isMinLengthInvalid);
    StatesGet(101);
  }, []);

  const StatesGet = (Id) => {
    const params = {
      country_id: Id,
    };
    axios
      .post(getstatebycountry, qs.stringify(params))
      .then((Response) => {
        if (Response.data.status === 1) {
          setStates(Response.data.data);
        }
        console.log("getstatebycountry Response", Response.data.data);
      })
      .catch((err) => {
        console.log("getstatebycountry err", err);
      });
  };

  const CitysGet = (cId, sId) => {
    const params = {
      country_id: cId,
      state_id: sId,
    };
    axios
      .post(getcitybystate, qs.stringify(params))
      .then((Response) => {
        if (Response.data.status === 1) {
          setCity(Response.data.data);
        }
        console.log("getcitybystate Response", Response.data.data);
      })
      .catch((err) => {
        console.log("getcitybystate err", err);
      });
  };

  const PincodeGet = (city) => {
    const params = {
      city: city,
    };
    axios
      .post(getPincodes, qs.stringify(params))
      .then((Response) => {
        console.log("ascsac", Response.data);
        // setPincodeName(Response.data.pincodes);
        if (Response.data.pincodes !== "") {
          setPincode(Response.data.pincodes);
        }
        console.log("getPincodes_Response", Response.data);
      })
      .catch((err) => {
        console.log("getPincodes err", err);
      });
  };

  // const handlecontry = (Id) => {
  //   console.log("eee", Id);
  //   setCountryId(Id);
  //   // setCountryCode(e.target.value.phonecode);
  //   // setCountryName(e.target.value.name);
  //   StatesGet(Id);
  // };

  // const handlestate = (Id) => {
  //   console.log("eee", Id);
  //   setStateId(Id);
  //   setStatesName(e.target.value.name);
  //   CitysGet(countryId, e.target.value.id);
  // };

  // const handlecites = (e) => {
  //   console.log("eee", e.target.value);
  //   setCityId(e.target.value.Id);
  //   setCityName(e.target.value.Name);
  // };

  // password conditions start
  const validateInput = (newPassword) => {
    const specialCharacterRegex = /[!@#$%^&]/;
    const numericCharacterRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;

    const isSpecialCharacterMissing = !specialCharacterRegex.test(newPassword);
    const isMinLengthInvalid = newPassword.length < 8;
    const isNumericCharacterMissing = !numericCharacterRegex.test(newPassword);
    const isUppercaseMissing = !uppercaseRegex.test(newPassword);
    const isLowercaseMissing = !lowercaseRegex.test(newPassword);

    const isInputValid =
      // isInputFocused &&
      isSpecialCharacterMissing &&
      isMinLengthInvalid &&
      isNumericCharacterMissing &&
      isUppercaseMissing &&
      isLowercaseMissing;

    console.log("isInputValid", isInputValid);

    setInputValid(isInputValid);
    setSpecialCharacterMissing(isSpecialCharacterMissing);
    setMinLengthInvalid(isMinLengthInvalid);
    setNumericCharacterMissing(isNumericCharacterMissing);
    setUppercaseMissing(isUppercaseMissing);
    setLowercaseMissing(isLowercaseMissing);

    setConfirmPasswordValid(isInputValid);
  };

  // password conditions end

  // fetch ip

  const FetchIpAddress = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    console.log("data", data);
    setIp(data.ip);
    console.log("ipAddress", getIp);
  };

  // fetch ip

  // register api start

  /// validation for signup // vishal

  const Validation = () => {
    // console.log("getpincodeName", getpincodeName);
    const emailcheck = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    if (fullName == "" || fullName == null) {
      setcusmsg("Please enter full name");
      setShowModal(true);
    } else if (fullnamecon == true) {
      setcusmsg("Please enter valid full name");
      setShowModal(true);
    } else if (email == "" || email == null) {
      setcusmsg("Please enter email address");
      setShowModal(true);
    } else if (emailcheck) {
      setcusmsg("Please enter valid email address");
      setShowModal(true);
    } else if (getuserName == "" || getuserName == null) {
      setcusmsg("Please enter profile name");
      setShowModal(true);
    } else if (gender == "" || gender == null) {
      setcusmsg("Please select gender");
      setShowModal(true);
    } else if (countryName == "" || countryName == null) {
      setcusmsg("Please select country");
      setShowModal(true);
    } else if (statesName == "" || statesName == null) {
      setcusmsg("Please select state");
      setShowModal(true);
    } else if (cityName == "" || cityName == null) {
      setcusmsg("Please select city");
      setShowModal(true);
    }
    //  else if (getpincodeName == "" || getpincodeName == null) {
    //   setcusmsg("Please select pincode");
    //   setShowModal(true);
    // }
    else if (getAddress1 == "" || getAddress1 == null) {
      setcusmsg("Provide the first line of address");
      setShowModal(true);
    } else if (phoneNumber == "" || phoneNumber == null) {
      setcusmsg("Please enter phone number");
      setShowModal(true);
    } else if (phoneNumber.length != 10) {
      setcusmsg("Please enter valid phone number");
      setShowModal(true);
    } else if (password1 == "" || password1 == null) {
      setcusmsg("Please enter password");
      setShowModal(true);
    } else if (password1.length < 8) {
      setcusmsg("Password is must be at least 8 characters");
      setShowModal(true);
    } else if (confirmPassword == "" || confirmPassword == null) {
      setcusmsg("Please enter confirm password");
      setShowModal(true);
    } else if (password1 !== confirmPassword) {
      setcusmsg("Password and confirm password do not match");
      setShowModal(true);
    } else if (isChecked == false) {
      setcusmsg("Please accept our terms and conditions.");
      setShowModal(true);
    } else {
      AddUser();
    }
  };

  const AddUser = async () => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("firstname", fullName);
    formdata.append("email", email);
    formdata.append("username", getuserName);
    formdata.append("phone", phoneNumber);
    formdata.append(
      "gender",
      gender == "male" ? 0 : gender == "female" ? 1 : gender == "other" ? 2 : 0
    );
    formdata.append("pincode", getpincodeName);
    formdata.append("city", cityName);
    formdata.append("state", statesName);
    formdata.append("country", countryName);
    formdata.append("password", password1);
    formdata.append("device_id", "123");
    formdata.append("user_type", 1);
    formdata.append("user_ip_address", getIp);
    formdata.append("player_id", "123");
    formdata.append("country_code", countryCode);
    formdata.append("address_line_1", getAddress1);
    formdata.append("address_line_2", Address2);
    // formdata.append("user_id", location.state.user_id);
    console.log("====================================");
    for (let pair of formdata.entries()) {
      console.log(pair[0], pair[1]);
    }
    console.log("====================================");
    await axios
      .post(user_register, formdata)
      .then((Response) => {
        setIsLoading(false);
        console.log("user_register Response", Response.data);
        if (Response.data.status == 1) {
          navigate("/verifyscreen", {
            state: {
              user_id: Response.data.user_id,
              user_otp: Response.data.user_otp,
              phone: Response.data.phone,
            },
          });
          // navigate("/aadharcard", {
          //   state: {
          //     user_id: Response.data.user_id,
          //   },
          // });
          console.log("user_register Response", Response.data);
        } else {
          // setIsLoading(false);
          setShowModal(true);
          setcusmsg(Response.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_register err", err);
      });
  };

  // register api end

  // contry select box start

  const [opencontryModal, setOpencontryModal] = useState(false);
  const [inputText, setInputText] = useState("");

  // Filter city options based on the input text
  const filteredContrys = country.filter((data) =>
    data.Name.toLowerCase().includes(inputText.toLowerCase())
  );

  // Function to handle city selection
  const handleContrySelect = (contry) => {
    setCountryId(contry.Id);
    StatesGet(contry.Id);
    setCountryName(contry.Name);
    setCountryCode(contry.phonecode);
    setOpencontryModal(false);
    console.log("contry", contry);

    setStateId(null);
    setStatesName("");

    setCityId(null);
    setCityName("");

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle input text change
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  // contry select box end

  // state state box start

  const [openstateModal, setOpenstateModal] = useState(false);
  const [stateInputText, setStateInputText] = useState("");

  // Filter city options based on the input text
  const filteredStates = states.filter((data) =>
    data.Name.toLowerCase().includes(stateInputText.toLowerCase())
  );

  // Function to handle state selection
  const handleStateSelect = (state) => {
    setStateId(state.Id);
    setStatesName(state.Name);
    CitysGet(countryId, state.Id);
    setOpenstateModal(false);

    setCityId(null);
    setCityName("");

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle input text change
  const handleStateInputChange = (e) => {
    setStateInputText(e.target.value);
  };

  // state select box end

  // city select box start

  const [opencityModal, setOpencityModal] = useState(false);
  const [openpincodeModal, setOpenpincodeModal] = useState(false);
  const [cityInputText, setCityInputText] = useState("");

  // Filter city options based on the input text
  const filteredcity = city.filter((data) =>
    data.Name.toLowerCase().includes(cityInputText.toLowerCase())
  );

  // Filter pincode options based on the input text

  const filteredPincode = getpincode.filter((pin) =>
    pin.includes(getpincodeName.toLowerCase())
  );
  // Function to handle city selection
  const handleCitysSelect = (city) => {
    setCityId(city.Id);
    setCityName(city.Name);
    PincodeGet(city.Name);

    setOpencityModal(false);
    setOpenpincodeModal(false);

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle pincode selection
  const handlePincodeSelect = (city) => {
    setPincodeName(city);

    setOpenpincodeModal(false);
  };

  // Function to handle input text change
  const handlecitysInputChange = (e) => {
    setCityInputText(e.target.value);
  };

  const handlePincodeInputChange = (e) => {
    setPincodeName(e.target.value);
  };

  // city select box end

  return (
    <>
      {gettermsmodal ? (
        <>
          <div className="page_main_wrapp">
            {/* <div className="Termsandcondition_1"></div> */}
            <div className="Termsandcondition_2">
              <div className="web_user_menu">
                <div className="Termsandcondition_header1">
                  {/* <div className="main_header_notifi"> */}
                  <p
                    style={{
                      color: "var(--color-white)",
                      fontSize: "16px",
                      fontFamily: "var(--font-700)",
                    }}
                  >
                    Terms & conditions
                  </p>
                  {/* </div> */}
                </div>
                <div
                  className="Termsandcondition_details"
                  style={{ marginTop: "30px" }}
                >
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    These Terms and Conditions ("Terms") govern your
                    participation in the beta release of the rarerez.club App
                    ("App"). By accessing or using the App, you agree to be
                    bound by these Terms. If you do not agree with any part of
                    these Terms, please refrain from using the App.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Beta Testing: The App is currently in the beta testing
                    phase, and you understand and acknowledge that it may
                    contain bugs, errors, and other issues. Your participation
                    in the beta release is voluntary and at your own risk.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Feedback and Suggestions: As a beta tester, you are
                    encouraged to provide feedback, suggestions, and bug reports
                    regarding the App. You agree that any feedback or
                    suggestions you provide may be used by rarerez.club for the
                    improvement and development of the App without any
                    obligation to compensate you.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Confidentiality: You agree to keep all information related
                    to the App, including its features, designs, and
                    functionality, confidential. You will not disclose or share
                    this information with any third party without prior written
                    consent from rarerez.club.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Limited License: rarerez.club grants you a limited,
                    non-exclusive, non-transferable, revocable license to use
                    the App solely for the purpose of beta testing and providing
                    feedback during the beta release period.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Disclaimer of Warranty: The App is provided on an "as is"
                    basis, and rarerez.club makes no warranties or
                    representations regarding its functionality, performance, or
                    suitability for any purpose. rarerez.club shall not be
                    liable for any damages arising out of or in connection with
                    the use of the App.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Termination: rarerez.club reserves the right to terminate
                    your access to the App at any time, for any reason, without
                    prior notice.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    Modification of Terms: rarerez.club may modify these Terms
                    at any time without prior notice. It is your responsibility
                    to review the most current version of the Terms
                    periodically.
                  </p>
                  <p
                    className="paragraph"
                    style={{ fontFamily: "var(--font-400)", fontSize: "14px" }}
                  >
                    By accessing and using the App, you acknowledge that you
                    have read, understood, and agreed to these Terms and
                    Conditions. If you do not agree to these Terms, please
                    refrain from using the App.
                  </p>
                </div>
              </div>
              <button
                onClick={handleCheckboxChangealert}
                className="accept-buttont"
              >
                Accept
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="signup-container">
          {/* <div className="signup1">
            <img src={images.loginbanner} className="signup_img_1" alt="" />
          </div> */}
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div className="signup2">
              <div className="web_signup">
                <div className="signup-header">
                  <button
                    onClick={() => navigate("/")}
                    style={{
                      width: "30px",
                      // objectFit: "contain",
                      marginBottom: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        alignItems: "flex-start",
                      }}
                      src={images.back}
                      alt=""
                    />
                  </button>
                  <img className="signup-logo" src={images.new_logo} alt="" />
                  <h1 className="signup-name">Create Account</h1>
                </div>
                <div className="signup-input">
                  <input
                    type="text"
                    value={fullName}
                    onChange={handleFullNameChange}
                    onBlur={() => {
                      handlename();
                    }}
                    placeholder="Full Name"
                  />
                  <div
                    style={{
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="email"
                      style={{
                        border:
                          email !== ""
                            ? is_email === 1
                              ? "1px solid green"
                              : is_email === 0
                              ? "1px solid red"
                              : "1px solid #fff"
                            : "1px solid #fff",
                      }}
                      placeholder="Email Address"
                      value={email}
                      // value={location.state && location.state.email}
                      onBlur={() => {
                        if (email != "") {
                          if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              email
                            )
                          ) {
                            setIsEmail(null);
                            // alert("Enter valid email address");
                            setcusmsg("Enter valid email address");
                            setShowModal(true);
                          } else {
                            setIsLoading(true);
                            const param = {
                              email: email,
                            };
                            console.log("param", param);
                            axios
                              .post(get_user_emailcheck, qs.stringify(param))
                              .then((Response) => {
                                setIsLoading(false);
                                console.log("param", Response.data);
                                if (Response.data.status === 0) {
                                  setIsEmail(0);
                                } else {
                                  setIsEmail(1);
                                }
                              })
                              .catch((err) => {
                                console.log("get_user_emailcheck err", err);
                              });
                          }
                        }
                      }}
                      onChange={(e) => {
                        handleEmailChange(e);
                      }}
                    />
                    {is_email == null ? null : (
                      <img
                        src={
                          is_email === 0 ? images.close_red : images.Mybidslist
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          right: "10px",
                          top: "8px",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {is_email === 0 ? (
                      <p
                        style={{
                          color: "red",
                          marginHorizontal: "5%",
                          fontSize: 13,
                          fontFamily: "var(--font-300)",
                          marginTop: "-10px",
                          marginBottom: "10px",
                          marginLeft: "3%",
                        }}
                      >
                        This email already exists.
                      </p>
                    ) : null}
                  </div>
                  {/* user name section start */}
                  <div
                    style={{
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        border:
                          getuserName !== ""
                            ? is_username === 1
                              ? "1px solid green"
                              : is_username === 0
                              ? "1px solid red"
                              : "1px solid #fff"
                            : "1px solid #fff",
                      }}
                      placeholder="Profile Name"
                      value={getuserName}
                      onBlur={() => {
                        if (getuserName != "") {
                          setIsLoading(true);
                          const param = {
                            username: getuserName,
                          };
                          console.log("param", param);
                          axios
                            .post(check_is_user, qs.stringify(param))
                            .then((Response) => {
                              setIsLoading(false);
                              console.log("param", Response.data);
                              if (Response.data.status === 0) {
                                setIsUserName(0);
                              } else {
                                setIsUserName(1);
                              }
                            })
                            .catch((err) => {
                              console.log("check_is_user err", err);
                            });
                        }
                      }}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        handleUserNameChange(e);
                      }}
                    />
                    {is_username == null ? null : (
                      <img
                        src={
                          is_username === 0
                            ? images.close_red
                            : images.Mybidslist
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          right: "10px",
                          top: "8px",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {is_username === 0 ? (
                      <p
                        style={{
                          color: "red",
                          marginHorizontal: "5%",
                          fontSize: 13,
                          fontFamily: "var(--font-300)",
                          marginTop: "-10px",
                          marginBottom: "10px",
                          marginLeft: "3%",
                        }}
                      >
                        The username is already in use.
                      </p>
                    ) : null}
                  </div>
                  {/* user name section end */}

                  {/* gender start */}
                  <div
                    className="gender-input"
                    style={{
                      marginBottom: "10px",
                      justifyContent: "space-between",
                      // padding: "auto 2px",
                    }}
                  >
                    <p className="gender-nm">Gender</p>
                    <div
                      // style={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "space-between",
                      //   width: "70%",
                      // }}
                      className="all_gender_radio_wrapp"
                    >
                      <div className="gender_single_wrapp">
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          checked={gender === "male"}
                          onChange={handleGenderChange}
                          className="radio_btn1"
                          style={{ margin: "0px" }}
                        />
                        <p className="radio_btn_name">Male</p>
                      </div>
                      <div className="gender_single_wrapp">
                        <input
                          className="radio_btn2"
                          type="radio"
                          name="gender"
                          value="female"
                          checked={gender === "female"}
                          onChange={handleGenderChange}
                          style={{ margin: "0px" }}
                        />
                        <p className="radio_btn_name">Female</p>
                      </div>
                      <div className="gender_single_wrapp">
                        <input
                          type="radio"
                          name="gender"
                          value="other"
                          checked={gender === "other"}
                          onChange={handleGenderChange}
                          className="radio_btn3"
                          style={{ margin: "0px" }}
                        />
                        <p className="radio_btn_name">Other</p>
                      </div>
                    </div>
                  </div>
                  {/* gender end */}

                  {/* select contry start */}
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="modal_city_select"
                      onClick={() => {
                        setOpencontryModal(!opencontryModal);
                        setOpenstateModal(false);
                        setOpencityModal(false);
                        setOpenpincodeModal(false);
                      }} // Open the modal when clicked
                      value={countryName}
                      readOnly
                      placeholder={"Select Country"}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <img
                      // onClick={toggleShowPassword}
                      src={images.down}
                      alt=""
                      className="select_down_arrow"
                      style={{
                        transform: opencontryModal
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                    {opencontryModal && (
                      <div className="contry_select_modal">
                        <input
                          type="text"
                          className="city_nm_select"
                          placeholder={"Search Country"}
                          onChange={(e) => handleInputChange(e)}
                          // onBlur={() => setOpencontryModal(false)}
                        />
                        <div
                          className="contry_modal_option_wrapp"
                          style={{
                            zIndex: 111,
                            width: "100%",
                            height: "200px",
                          }}
                        >
                          {filteredContrys.map((contry) => {
                            return (
                              <>
                                <button
                                  key={contry}
                                  // className="city_option_nm_set"
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    fontFamily: "var(--font-300)",
                                  }}
                                  onClick={() => {
                                    handleContrySelect(contry);
                                    // console.log("first", contry);
                                    // setCountryName(contry.Name);
                                  }}
                                >
                                  {contry.Name}
                                </button>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* select contry end */}
                  {/* select state start */}
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="modal_city_select"
                      style={{ color: "#fff" }}
                      onClick={() => {
                        setOpenstateModal(!openstateModal);
                        setOpencontryModal(false);
                        setOpencityModal(false);
                        setOpenpincodeModal(false);
                      }} // Open the modal when clicked
                      value={statesName}
                      placeholder={
                        statesName == "" ? "Select State" : statesName
                      }
                      // onBlur={() => setOpencontryModal(false)}
                      readOnly
                    />
                    <img
                      // onClick={toggleShowPassword}
                      src={images.down}
                      alt=""
                      className="select_down_arrow"
                      style={{
                        transform: openstateModal
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                    {openstateModal && (
                      <div
                        className="state_select_modal"
                        // style={{ border: "1px solid red" }}
                      >
                        <input
                          type="text"
                          className="city_nm_select"
                          placeholder="Search State"
                          // onClick={toggleCityOptions}
                          onChange={(e) => handleStateInputChange(e)}
                          // value={countryName} // Set the input value to the selected city or filtered text
                        />
                        {/* {isCityOptionsVisible && ( */}
                        <div
                          className="contry_modal_option_wrapp"
                          style={{
                            zIndex: 111,
                            width: "100%",
                            height: "200px",
                          }}
                        >
                          {filteredStates.map((state) => {
                            console.log("state", state);
                            return (
                              <>
                                <button
                                  key={state.Id}
                                  // className="city_option_nm_set"
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    fontFamily: "var(--font-300)",
                                  }}
                                  onClick={() => handleStateSelect(state)}
                                >
                                  {state.Name}
                                </button>
                              </>
                            );
                          })}
                        </div>
                        {/* )} */}
                      </div>
                    )}
                  </div>
                  {/* select state end */}
                  {/* select city start */}
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="modal_city_select"
                      onClick={() => {
                        setOpencityModal(!opencityModal);
                        setOpenstateModal(false);
                        setOpencontryModal(false);
                        setOpenpincodeModal(false);
                      }} // Open the modal when clicked
                      value={cityName}
                      readOnly
                      placeholder={cityName == "" ? "Select City" : cityName}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <img
                      // onClick={toggleShowPassword}
                      src={images.down}
                      alt=""
                      className="select_down_arrow"
                      style={{
                        transform: opencityModal
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                    {opencityModal && (
                      <div
                        className="state_select_modal"
                        // style={{ border: "1px solid red" }}
                      >
                        <input
                          type="text"
                          className="city_nm_select"
                          placeholder="Search City"
                          // onClick={toggleCityOptions}
                          onChange={(e) => handlecitysInputChange(e)}
                          // value={countryName} // Set the input value to the selected city or filtered text
                        />
                        {/* {isCityOptionsVisible && ( */}
                        <div
                          className="contry_modal_option_wrapp"
                          style={{
                            zIndex: 111,
                            width: "100%",
                            height: "200px",
                          }}
                        >
                          {filteredcity.map((city) => {
                            console.log("city", city);
                            return (
                              <>
                                <button
                                  key={city.Id}
                                  // className="city_option_nm_set"
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    fontFamily: "var(--font-300)",
                                  }}
                                  onClick={() => handleCitysSelect(city)}
                                >
                                  {city.Name}
                                </button>
                              </>
                            );
                          })}
                        </div>
                        {/* )} */}
                      </div>
                    )}
                  </div>
                  {/* select city end */}

                  {/* select Pincode start */}
                  {/*<input
                  disabled={true}
                    type="text"
                    value={getpincodeName}
                    placeholder="pincode"
                  /> */}
                  {/* select Pincode end */}

                  {/* select Pincode start */}
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="modal_city_select"
                      onClick={() => {
                        setOpenpincodeModal(!openpincodeModal);
                        setOpencityModal(false);
                        setOpenstateModal(false);
                        setOpencontryModal(false);
                      }} // Open the modal when clicked
                      value={getpincodeName}
                      readOnly
                      placeholder={
                        getpincodeName == "" ? "Select Pincode" : getpincodeName
                      }
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <img
                      // onClick={toggleShowPassword}
                      src={images.down}
                      alt=""
                      className="select_down_arrow"
                      style={{
                        transform: openpincodeModal
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                    {openpincodeModal && (
                      <div
                        className="state_select_modal"
                        // style={{ border: "1px solid red" }}
                      >
                        <input
                          type="text"
                          className="city_nm_select"
                          placeholder="Search Pincode"
                          // onClick={toggleCityOptions}
                          onChange={(e) => handlePincodeInputChange(e)}
                          // value={countryName} // Set the input value to the selected city or filtered text
                        />
                        {/* {isCityOptionsVisible && ( */}
                        <div
                          className="contry_modal_option_wrapp"
                          style={{
                            zIndex: 111,
                            width: "100%",
                            height: "200px",
                          }}
                        >
                          {filteredPincode.map((pin) => {
                            console.log("pin", pin);
                            return (
                              <>
                                <button
                                  key={pin}
                                  // className="city_option_nm_set"
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                    fontFamily: "var(--font-300)",
                                  }}
                                  onClick={() => handlePincodeSelect(pin)}
                                >
                                  {pin}
                                </button>
                              </>
                            );
                          })}
                        </div>
                        {/* )} */}
                      </div>
                    )}
                  </div>
                  {/* select Pincode end */}

                  <input
                    type="text"
                    value={getAddress1}
                    onChange={handleAddress1Change}
                    placeholder="Address Line 1"
                  />
                  <input
                    type="text"
                    value={Address2}
                    onChange={(e) => handleAddress2Change(e)}
                    placeholder="Address Line 2"
                  />
                  <div style={{ position: "relative" }}>
                    {countryCode && (
                      <p
                        style={{
                          position: "absolute",
                          // top: "8px",
                          left: "11px",
                          fontSize: "14px",
                          fontFamily: "var(--font-400)",
                          color: "#fff",
                          zIndex: 11,
                        }}
                        className="phone-country"
                      >
                        +{countryCode && countryCode}
                      </p>
                    )}
                    <div
                      style={{
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      <input
                        // type="number"
                        title="Phone number"
                        inputmode="text"
                        type="text"
                        // placeholder=""
                        autocomplete="off"
                        // id="7"
                        inputMode="numeric"
                        value={phoneNumber}
                        style={{
                          fontSize: "14px",
                          border:
                            phoneNumber !== ""
                              ? is_mobile === 1
                                ? "1px solid green"
                                : is_mobile === 0
                                ? "1px solid red"
                                : "1px solid #fff"
                              : "1px solid #fff",
                          paddingLeft: countryCode ? "50px" : "10px",
                        }}
                        // value={phoneNumber}
                        onChange={(e) => {
                          setIs_mobile(null);
                          handlePhoneNumberChange(e);
                        }}
                        placeholder="Phone Number"
                        onBlur={() => {
                          if (phoneNumber.length < 10) {
                            setcusmsg(
                              "The phone number you entered is invalid. Please enter a valid phone number any try again."
                            );
                            setShowModal(true);
                          } else if (phoneNumber !== "") {
                            setIsLoading(true);
                            const param = {
                              phone_no: phoneNumber,
                            };
                            console.log("param", param);
                            axios
                              .post(get_user_number, qs.stringify(param))
                              .then((Response) => {
                                setIsLoading(false);
                                console.log(
                                  "get_user_number res",
                                  Response.data
                                );
                                if (Response.data.status === 0) {
                                  setIs_mobile(0);
                                  setMobileErrMsg(Response.data.message);
                                } else {
                                  setIs_mobile(1);
                                }
                              })
                              .catch((err) => {
                                console.log("get_user_number err", err);
                              });
                          }
                        }}
                      />
                      {is_mobile == null ? null : (
                        <img
                          src={
                            is_mobile === 0
                              ? images.close_red
                              : images.Mybidslist
                          }
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                            right: "10px",
                            top: "8px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    {is_mobile === 0 ? (
                      <p
                        style={{
                          color: "red",
                          marginHorizontal: "5%",
                          fontSize: 12,
                          fontFamily: "var(--font-300)",
                          marginTop: "-10px",
                          marginBottom: "10px",
                          marginLeft: "3%",
                        }}
                      >
                        {getmobileErrMsg}
                        {/* This mobile no is already exists. */}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <input
                      type={showPassword1 ? "text" : "password"}
                      value={password1}
                      // onChange={handlePasswordChange}
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword1(e.target.value);
                        // handlePasswordChange(e);
                        validateInput1(e.target.value);
                      }}
                      onFocus={handleInputFocus}
                      // onFocus={() => {
                      //   setInputFocused(true);
                      // }}
                      // onBlur={() => {
                      //   // setIsInputValid(false);
                      //   setIsInputFocused(false);
                      //   validateInput1();
                      // }}
                    />
                    <img
                      onClick={toggleShowPassword1}
                      // src={images.password_seq}
                      src={showPassword1 ? images.eyeopen : images.eyeoff}
                      alt=""
                      className="hied_signup"
                    />
                    {valid == false ? (
                      <>
                        {isInputValid1 == true && (
                          <div style={{ marginLeft: 10 }}>
                            {/* {isMinLengthInvalid && ( */}
                            <p
                              style={{
                                color: isMinLengthInvalid1 ? "gray" : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must be at least 8 characters long
                            </p>
                            {/* )} */}
                            {/* {isSpecialCharacterMissing && ( */}
                            <p
                              style={{
                                color: isSpecialCharacterMissing1
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one special
                              character
                            </p>
                            {/* )} */}
                            {/* {isNumericCharacterMissing && ( */}
                            <p
                              style={{
                                color: isNumericCharacterMissing1
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one numeric
                              character
                            </p>
                            {/* )} */}
                            {/* {isUppercaseMissing && ( */}
                            <p
                              style={{
                                color: isUppercaseMissing1 ? "gray" : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one uppercase
                              character
                            </p>
                            {/* )} */}
                            {/* {isLowercaseMissing && ( */}
                            <p
                              style={{
                                color: isLowercaseMissing1 ? "gray" : "green",
                                fontSize: 10,
                                marginBottom: 28,
                              }}
                            >
                              Password must include at least one lowercase
                              character
                            </p>
                            {/* )} */}
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div style={{ position: "relative", bottom: "20px" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      // onChange={handleConfirmPasswordChange}
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        validateInputConfirmPassword(e.target.value);
                      }}
                      onFocus={() => setIsInputFocusedConfirmPassword(true)}
                    />
                    <img
                      onClick={toggleShowPassword}
                      // src={images.password_seq}
                      src={showPassword ? images.eyeopen : images.eyeoff}
                      alt=""
                      className="hied_signup"
                    />
                    {valid1 == false ? (
                      <>
                        {isInputValidConfirmPassword == true && (
                          <div style={{ marginLeft: 10 }}>
                            {/* {isMinLengthInvalid && ( */}
                            <p
                              style={{
                                color: isMinLengthInvalidConfirmPassword
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must be at least 8 characters long
                            </p>
                            {/* )} */}
                            {/* {isSpecialCharacterMissing && ( */}
                            <p
                              style={{
                                color: isSpecialCharacterMissingConfirmPassword
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one special
                              character
                            </p>
                            {/* )} */}
                            {/* {isNumericCharacterMissing && ( */}
                            <p
                              style={{
                                color: isNumericCharacterMissingConfirmPassword
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one numeric
                              character
                            </p>
                            {/* )} */}
                            {/* {isUppercaseMissing && ( */}
                            <p
                              style={{
                                color: isUppercaseMissingConfirmPassword
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 3,
                              }}
                            >
                              Password must include at least one uppercase
                              character
                            </p>
                            {/* )} */}
                            {/* {isLowercaseMissing && ( */}
                            <p
                              style={{
                                color: isLowercaseMissingConfirmPassword
                                  ? "gray"
                                  : "green",
                                fontSize: 10,
                                marginBottom: 28,
                              }}
                            >
                              Password must include at least one lowercase
                              character
                            </p>
                            {/* )} */}
                          </div>
                        )}
                      </>
                    ) : null}
                    {/* {!confirmPasswordValid && (
                      <p
                        className="error-message"
                        style={{
                          color: confirmPasswordValid ? "green" : "red",
                          fontSize: 10,
                          marginBottom: 3,
                          marginTop: "-6px",
                        }}
                      >
                        Passwords do not match.
                      </p>
                    )} */}
                  </div>
                  {/* <div className="checkbox-container">
              <label
                htmlFor="myCheckbox"
                className={`checkbox-label ${isChecked ? "checked" : ""}`}
              >
                <input
                  type="checkbox"
                  id="myCheckbox"
                  name="myCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="checkbox-inline"
                />
                <span className="checkmark"></span>
                <label for="myCheckbox" className="mb_term">
                  I have read and agreed to the{" "}
                  <span className="mv_terms_new"> terms and conditions </span>
                </label>
              </label>
            </div> */}
                  <div className="checkbox-container">
                    {/* <label
                htmlFor="myCheckbox"
                className={`checkbox-label ${isChecked ? "checked" : ""}`}
              >
                <input
                  type="checkbox"
                  id="myCheckbox"
                  name="myCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="checkbox-inline"
                />
              </label>
              <span className="checkmark"></span>
              <span
                className="mb_term"
                onClick={() => {
                  settermsmodal(true);
                  // navigate("termsandcondition");
                }} // Show alert when clicking on terms and conditions text
              >
                I have read and agreed to the{" "}
                <span className="mv_terms_new"> terms and conditions </span>
              </span> */}
                    {/* <input
                type="radio"
                name="gender"
                value="male"
                id="myCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="radio_btn1"
                style={{
                  margin: "0px 10px",
                  padding: "0px",
                  width: "25px",
                  height: "20px",
                  borderRadius: "50px",
                }}
              />
              <span
                className="radio_btn_name"
                onClick={() => {
                  settermsmodal(true);
                  // navigate("termsandcondition");
                }}
              >
                I have read and agreed to the{" "}
                <span className="mv_terms_new"> terms and conditions </span>
              </span> */}

                    <div
                      className="bondbox_checkbox bondbox_checkbox1"
                      style={{ width: "100%", marginTop: "-24px" }}
                    >
                      <input
                        type="checkbox"
                        className="check_box_new"
                        style={{ margin: "0px" }}
                        // value={agree}
                        // onChange={(e) => {
                        //   console.log(e.target.value);
                        //   setAgree(!agree);
                        // }}
                        id="myCheckbox"
                        checked={isChecked}
                        // onChange={handleCheckboxChange}
                        onClick={() => {
                          settermsmodal(true);
                        }}
                      />
                      <span
                        className="radio_btn_name"
                        onClick={() => {
                          settermsmodal(true);
                          // navigate("termsandcondition");
                        }}
                      >
                        I have read and agreed to the{" "}
                        <span className="mv_terms_new">
                          {" "}
                          terms and conditions.{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="signup-footer">
                  <button
                    onClick={() => Validation()}
                    className="login-buttons mx-1"
                  >
                    Sign up
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="signup-text" style={{ opacity: "0.8" }}>
                      Already have an account?{" "}
                    </p>
                    <Link to={"/"}>
                      <p
                        style={{
                          color: "var(--color-main)",
                          opacity: 1,
                          fontFamily: "var(--font-600)",
                          fontSize: "14px",
                          marginLeft: "4px",
                          marginTop: "2px",
                        }}
                      >
                        Login
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Modal */}
              {showModal && (
                <>
                  {/* <AlertModal
                    title=""
                    msg={getcusmsg}
                    setShowModal={setShowModal}
                  /> */}
                  <NewModal
                    title=""
                    msg={getcusmsg}
                    setShowModal={openmodal}
                    closemodal={closemodal}
                  />
                  {/* <div className="modal_login">
              <div className="modal-content_login">
                <div className="modal_div1">
                  <img className="forgot_main" src={images.logo} alt="" />
                </div>
                <div className="modal_div">
                  <b>Alert</b>
                  <br />
                  <p className="modal-message"> Please enter full name</p>
                  <br />
                </div>
                <hr className="hr_forgot" />
                <br />
                <div className="modal_div">
                  <button
                    className="close_forgot"
                    onClick={() => setShowModal(false)}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div> */}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SignUp;
