import React from "react";
import "./NewModal.css";
import ReactModal from "react-modal";
import images from "../../constants/images";

const NewModal = ({ title, msg, setShowModal, closemodal }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <ReactModal
      isOpen={setShowModal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closemodal}
      style={customStyles}
    >
      <div className="NewModal_main_wrapp">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img className="newmodal_logo" src={images.logo} alt="" />
          <p className="newmodal_titel">{title ? title : ""}</p>
          {/* <p className="newmodal_titel">Alert</p> */}
        </div>

        {/* <div className="newmodal_des_wrapp"> */}
        <p className="newmodal_des">{msg ? msg : ""}</p>
        {/* </div> */}

        <button className="newmodal_btn" onClick={closemodal}>
          Okay
        </button>
      </div>
    </ReactModal>
  );
};

export default NewModal;
