import React, { useState, useEffect } from "react";
import images from "../../constants/images";
// import "./cardsecond.css";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";

const Productcardfive = ({ item, setIsModalOpen, setMsg, setModalName }) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };

  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="centered">
      <div
        className={`flip-card ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        <div className="flip-card-inner_card">
          <div className="card_flip-card-front">
            <div
              className="crad_5_main_img_front"
              onClick={() => {
                navigate("/ProductViewImg", {
                  state: {
                    img: item.product_image_video
                      ? item.product_image_video
                      : "",
                  },
                });
              }}
            >
              <img
                src={
                  item.product_image_video
                    ? item.product_image_video[
                        item.product_image_video.length - 1
                      ].img_video
                    : item.bond_media_upload &&
                      item.bond_media_upload[item.bond_media_upload.length - 1]
                        .img_video
                }
                className="crad_5_img_front"
                alt=""
              />
              <div className="card_five_title_wrapp">
                <b className="card_five_title">{item.product_name}</b>
              </div>

              <div className="card_five_detail_wrapp">
                <div className="card_five_detail_sec_1">
                  {/* <b className="nm_of_user"> {item.product_name}</b> */}
                  <p className="nm_of_cre_titel">Creator</p>
                  <b className="nm_of_cre_nmr">{item.owner_name}</b>
                </div>
                <div className="card_five_detail_sec_2">
                  <p className="nm_of_cre_titel">Asset value</p>
                  {/* <p className="sc_p_nm">value</p> */}
                  <b className="nm_of_cre_nmr">₹{item.product_value}</b>
                </div>
              </div>

              <div className="crad_5_qr_front_div">
                <img src={item.qr_code} className="crad_5_qr_front" alt="" />
              </div>

              <img
                src={images.logo_white}
                className="card_five_logo_front"
                alt=""
              />
            </div>
          </div>
          <div className="card_flip-card-back">
            <div className="crad_5_main_img_back">
              <div className="cardfive_back_det_wrapp" style={{}}>
                {item.description && (
                  <>
                    <p
                      style={{
                        fontsize: "16px",
                        fontFamily: "var(--font-600)",
                        color: "var(--color-white)",
                      }}
                    >
                      Asset Description
                    </p>
                    <p className="cardfive_det_text">
                      {item.description.slice(0, 100)}{" "}
                      {item.description.length > 100 && "..."}
                    </p>
                    {item.description.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.description);
                          setModalName("Asset Description");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {item.user_product_history && (
                  <>
                    <b>Asset story</b>
                    <p className="details_bg_card">
                      {item.user_product_history.slice(0, 100)}{" "}
                      {item.user_product_history.length > 100 && "..."}
                    </p>
                    {item.user_product_history.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.user_product_history);
                          setModalName("Asset story");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {item.product_highlights && (
                  <>
                    <b>Asset Highlight</b>
                    <p className="details_bg_card">
                      {item.product_highlights.slice(0, 100)}{" "}
                      {item.product_highlights.length > 100 && "..."}
                    </p>
                    {item.product_highlights.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(item.product_highlights);
                          setModalName("Asset Highlight");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
              </div>
              <img
                src={
                  item.product_image_video
                    ? item.product_image_video[
                        item.product_image_video.length - 1
                      ].img_video
                    : item.bond_media_upload &&
                      item.bond_media_upload[item.bond_media_upload.length - 1]
                        .img_video
                }
                className="crad_5_img_back"
                alt=""
                onClick={() => {
                  navigate("/ProductViewImg", {
                    state: {
                      img: item.product_image_video
                        ? item.product_image_video
                        : "",
                    },
                  });
                }}
              />
            </div>
            <div className="sc_f_details_back">
              {/* <div className="sc_f_details1_back"> */}
              <div className="sc_b_details_5" style={{ left: "14%" }}>
                <p className="nm_of_cre_titel_back">Owned by</p>
                <b className="nm_of_cre_nmr_back">{item.username}</b>
              </div>
              {/* </div> */}
            </div>
            <img
              src={images.logo_white}
              className="card_five_logo_back"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productcardfive;
