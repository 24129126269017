import {
  GET_USER_WALLET_BEGIN,
  GET_USER_WALLET_FAIL,
  GET_USER_WALLET_SUCCESS,
  GET_USER_WALLET_RECHARGE_BEGIN,
  GET_USER_WALLET_RECHARGE_FAIL,
  GET_USER_WALLET_RECHARGE_SUCCESS,
  GET_USER_WALLET_WITHDREW_BEGIN,
  GET_USER_WALLET_WITHDREW_FAIL,
  GET_USER_WALLET_WITHDREW_SUCCESS,
} from "../Action";

const user_wallet_reducer = (state, action) => {
  //get user wallet
  if (action.type === GET_USER_WALLET_BEGIN) {
    return { ...state, get_user_wallet_loading: true };
  }

  if (action.type === GET_USER_WALLET_SUCCESS) {
    return {
      ...state,
      get_user_wallet_loading: false,
      get_userwallet_data: action.payload.result,
      total_amount: action.payload.total_amount,
    };
  }

  if (action.type === GET_USER_WALLET_FAIL) {
    return { ...state, get_user_wallet_loading: false };
  }

  // get user wallet recharge
  if (action.type === GET_USER_WALLET_RECHARGE_BEGIN) {
    return { ...state, get_user_wallet_recharge_loading: true };
  }

  if (action.type === GET_USER_WALLET_RECHARGE_SUCCESS) {
    return {
      ...state,
      get_user_wallet_recharge_loading: false,
      get_userwallet_recharge_data: action.payload,
    };
  }

  if (action.type === GET_USER_WALLET_RECHARGE_FAIL) {
    return { ...state, get_user_wallet_recharge_loading: false };
  }

  // get user wallet withdrw

  if (action.type === GET_USER_WALLET_WITHDREW_BEGIN) {
    return { ...state, get_user_wallet_withdrew_loading: true };
  }

  if (action.type === GET_USER_WALLET_WITHDREW_SUCCESS) {
    return {
      ...state,
      get_user_wallet_withdrew_loading: false,
      get_userwallet_withdrew_data: action.payload,
    };
  }

  if (action.type === GET_USER_WALLET_WITHDREW_FAIL) {
    return { ...state, get_user_wallet_withdrew_loading: false };
  }
};

export default user_wallet_reducer;
