import React from "react";
import "./NewGstScreen.css";
import { useRef } from "react";
import { useState } from "react";
import images from "../../constants/images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { add_user_gst_v2, user_gst_skip_v2 } from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";

const NewGstScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [gstNumber, setGSTNumber] = useState(Array(12).fill(""));
  const [gstNumberValue, setGSTNumberValue] = useState("");
  const inputRefs = useRef([]);

  // gst inputs function start
  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-numeric characters
    setGSTNumberValue(inputValue);
    console.log("updatedPANNumber.length", inputValue);
  };
  // gst inputs function end

  // submit gst api start
  const SubmitGSTApi = () => {
    if (gstNumberValue == "") {
      toast.error("Enter GST number", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        gst_number: gstNumberValue,
        user_id: location.state.user_id,
      };
      // Make the API request
      axios
        .post(add_user_gst_v2, qs.stringify(param))
        .then((response) => {
          if (response.data.status === 1) {
            toast.success(response.data.message, { theme: "colored" });
            setIsLoading(false);
            console.log("response.data", response.data);
            navigate("/NewFounderVideoScreen", {
              state: {
                user_id: location.state.user_id,
              },
            });
          } else {
            // setIsModalVisible(true);
            // setCusmsg("Please enter valid GST Number");
            toast.error(response.data.message, { theme: "colored" });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }
  };
  // submit gst api end

  // skip GST api start

  const SkipGST = () => {
    setIsLoading(true);
    const param1 = {
      user_id: location.state.user_id,
    };
    axios
      .post(user_gst_skip_v2, qs.stringify(param1))
      .then((response) => {
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setIsLoading(false);
          navigate("/NewFounderVideoScreen", {
            state: {
              user_id: location.state.user_id,
            },
          });
          toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  // skip GST api end

  return (
    <div className="new_wrapp_container relative_position">
      {/* <img src={images.new_bg_circule} alt="" className="back_circule_shadow" /> */}
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="new_gst_main_wrapp">
            <div>
              <div
                className="play_screen_banner_container"
                style={{ height: "24vh", minHeight: "200px" }}
              >
                <img
                  src={images.new_logo}
                  className="play_screen_banner"
                  style={{ height: "64px", maxWidth: "200px" }}
                  alt=""
                />
              </div>
              <div
                className="otp_screen_text_container"
                style={{ margin: "0px" }}
              >
                <h4 style={{ margin: "0px" }}>
                  Secure Your Access: Rarerez GST Verification
                </h4>
                <p>
                  Your card details remain confidential. This helps us to
                  process further.{" "}
                </p>
              </div>
              {/* <div className="new_aadhaar_input_wrapp">
                <p>Enter GSTIN number</p>
                <div
                  className="aadhaar-input-container"
                  style={{ width: "100%" }}
                >
                  {gstNumber.map((digit, index) => (
                    <>
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="aadhaar-input"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength={1}
                        style={{ width: "inherit" }}
                      />
                    </>
                  ))}
                </div>
              </div> */}

              {/* single input */}
              <div
                className="new_set_password_single_input_wrapp"
                style={{ padding: "5%" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  {/* <img src={images.new_lock_logo} alt="" /> */}
                  <p>Enter GSTIN number :</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Type here"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="new_gst_btns_wrapp">
              <button className="new_skip_btn" onClick={SkipGST}>
                Skip
              </button>

              {/* btn */}
              <button
                onClick={SubmitGSTApi}
                className="new_btn_wrapp mx-1"
                style={{ width: "48%" }}
              >
                <p>Next</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewGstScreen;
