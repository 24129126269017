// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyALxo2F13nHj8JExIiQqfDXrlsvWY4-hIA",
  authDomain: "test123-4a9b3.firebaseapp.com",
  projectId: "test123-4a9b3",
  storageBucket: "test123-4a9b3.appspot.com",
  messagingSenderId: "705852723811",
  appId: "1:705852723811:web:c8adca859f06d8312f1e56",
  measurementId: "G-H5R70MWD4M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const genarateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log("permission: ", permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BBdGVzSqMPf6YfxWuseOf8VGNF8FWNlprpy83-CULQ-16VycGDh3bUuM98N0hmwZTmSZvHuy1uP4FADIXm9XaIk",
    });
    // console.log("Token :", token);

    // let ttt = token;

    return token;
  }
};
