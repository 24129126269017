import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import images from "../constants/images";
import axios from "axios";
import NewLoader from "../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";
import { Button } from "@mui/material";
const AllTopClub = () => {
  const [getsearch, setSearch] = useState(false);
  const [getsearchData, setSearchData] = useState([]);
  const [getroyaltyFirst, setRoyaltyFirst] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.club_name
        ? item.club_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setTopClubData(newData);
    console.log("newData", newData);
  };

  const [topclubdata, setTopClubData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(
          "https://www.ravi.host/api/get_all_club_for_home_screen"
        );

        setIsLoading(false);

        console.log("====================================");
        console.log("Response", response.data);
        console.log("====================================");

        if (response.data.status === 1) {
          setTopClubData(response.data.result);
          setSearchData(response.data.result);
          // You can also set searchClubData if needed
        } else {
          // Handle the case when status is not 1
        }
      } catch (error) {
        setIsLoading(false);
        // Handle the error
      }
    };

    fetchData();
  }, []);

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          {/* navbar start */}
          <div className="rnfb_nav_wrapp">
            {/* <img
              src={images.back}
              onClick={() => navigate(-1)}
              alt=""
              className="rnfb_nav_back_btn"
            /> */}
            <div
              onClick={() => {
                navigate("/homescreen2");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </div>
            <p className="rnfb_nav_screen_name">Top Clubs</p>
            <FiSearch
              color="var(--color-red)"
              size={24}
              // className="rnfb_nav_search_btn"
              style={{ cursor: "pointer" }}
              onClick={() => setSearch(!getsearch)}
            />
          </div>
          {/* navbar end */}

          {/* search start */}
          {getsearch && (
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>
          )}
          {/* search end */}
          {topclubdata && topclubdata.length == 0 ? (
            <p
              style={{
                textAlign: "center",
                marginTop: "60px",
                color: "#fff",
                fontSize: "16px",
                fontFamily: "var(--font-600)",
              }}
            >
              No item found.
            </p>
          ) : null}
          <div className="rnfb_products_wrapp">
            {topclubdata && topclubdata.length > 0
              ? topclubdata.map((item, index) => {
                  console.log("item", item);
                  return (
                    <>
                      <Button
                        onClick={() => {
                          console.log("item", item);
                          item.phone_is_status == 0 && item.email_is_status == 0
                            ? navigate("/ClubOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.phone_is_status == 0
                            ? navigate("/ClubOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.email_is_status == 0
                            ? navigate("/ClubEmailOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.is_bank_status == 0
                            ? navigate("/clubBankaccount", {
                                state: {
                                  club_id: item.club_id,
                                  is_alltopclub: 1,
                                },
                              })
                            : navigate("/Clubprofile", {
                                state: {
                                  club_id: item.club_id,
                                  is_alltopclub: 1,
                                },
                              });
                        }}
                        // to={"/Clubprofile"}
                        // state={{
                        //   club_id: item.club_id,
                        // }}
                        className="home_sec_slide_card"
                        key={item.id}
                      >
                        <img src={item.profile} alt="" />
                        <div className="home_sec_card_blur_view">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                fontFamily: "var(--font-800)",
                                marginLeft: "2%",
                              }}
                            >
                              {item.club_name}
                              {/* User Name */}
                            </p>
                          </div>
                        </div>
                      </Button>
                    </>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllTopClub;
