import React, { useCallback, useEffect, useRef } from "react";
import "./editprofile.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import { useState } from "react";
import { edit_profile, user_update } from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import AlertModal from "../../components/AlertModal";
import qs from "qs";
// import Cropper from "react-easy-crop";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";

const Editprofile = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setloading] = useState(false);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState("");
  const [bio, setBio] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [managerdata, setManagerData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusMsg] = useState("");
  const [user_story, setUserStory] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updateimage, setupdateimage] = useState("");
  const [selectedCover, setSelectedCover] = useState([]);
  const [selectedCoverpreview, setSelectedCoverpreview] = useState(null);
  const [getvideoArray, setVideoArray] = useState([]);
  const [getattechVideoModal, setAttechVideoModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [user_social_media_link, setuser_social_media_link] = useState([]);
  const [getDeleteSocialId, setDeleteSocialId] = useState("");
  const [getconformationmodel, setconformationmodel] = useState(false);
  const [user_video_upload, setuser_video_upload] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [getcropmodal, setCropmodal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  // const defaultSrc =
  //   "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const cropperRef = useRef(null);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  // const getCropData = () => {
  //   if (cropperRef.current && cropperRef.current.cropper) {
  //     setCropData(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
  //   }
  // };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedDataUrl = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedDataUrl);

      // Convert the data URL to a Blob object
      const blob = dataURItoBlob(croppedDataUrl);

      // Create a FormData object and append the blob along with other necessary data
      const formData = new FormData();
      formData.append("id", userId);
      formData.append("profile", blob);

      // Send the cropped image data to your API
      axios
        .post("https://www.ravi.host/api/change_profile", formData)
        .then((response) => {
          setImage("");

          console.log("Success", response.data.status);
          if (response.data.status === 1) {
            setErrorMessage(response.data.message);
            setShowModal(true);
            setCropmodal(false);
            _getdata();
          } else {
            setErrorMessage(response.data.message);
            setShowModal(true);
            setCropmodal(false);
          }
        })
        .catch((error) => {
          console.error("Error uploading cropped image:", error);
          setCropmodal(false);
        });
    }
  };

  // Helper function to convert data URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  useEffect(() => {
    console.log("croppedImage", croppedImage);
  }, [croppedImage]);
  // const cropperRef = useRef(null);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedCover(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCoverDelete = (index) => {
    const newImages = [...selectedCover];
    newImages.splice(index, 1);
    setSelectedCover(newImages);
  };

  useEffect(() => {
    // selectedCover.length > 0 &&
    //   selectedCover.map((image, index) =>
    console.log("selectedCover", selectedCover);
    //   );
  }, [selectedCover]);

  useEffect(() => {
    // selectedCover.length > 0 &&
    //   selectedCover.map((image, index) =>
    console.log("selectedCoverpreview", selectedCoverpreview);
    //   );
  }, [selectedCoverpreview]);

  useEffect(() => {
    _getdata();
  }, []);
  const userId = localStorage.getItem("user_id");

  const _getdata = () => {
    setloading(true);

    const param = {
      user_id: userId,
    };

    axios.post(edit_profile, param).then((Response) => {
      setloading(false);
      console.log("----", Response.data.result);
      if (Response.data.status === 1) {
        const result = Response.data.result;
        setName(result.firstname);
        setUsername(result.username);
        setProfile(result.profile);
        setBio(result.bio);
        setNumber(result.phone);
        setEmail(result.email);
        setManagerData(result.manager_data);
        setUserStory(result.user_story);
        setSelectedCoverpreview(result.user_cover_photo);
        setVideoArray(result.user_videos);
        setuser_social_media_link(result.user_social_media_link);
        setuser_video_upload(result.user_video_upload);
      } else {
        setIsModalVisible(true);
        setCusMsg(Response.data.message);
      }
    });
  };

  const updateprofile = () => {
    if (name == "") {
      setErrorMessage("Enter name");
      setShowModal(true);
    } else if (username == "") {
      setErrorMessage("Enter username");
      setShowModal(true);
    }
    // else if (bio == "") {
    //   setErrorMessage("Enter bio");
    //   setShowModal(true);
    // }
    else {
      setloading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("username", username);
      formData.append("firstname", name);
      formData.append("bio", bio);
      formData.append("user_story", user_story);
      formData.append("user_cover_photo", selectedCover);
      selectedVideos.forEach((image, index) => {
        formData.append("user_videos", image);
      });
      let linktag = "";
      var demolinktag = 0;
      for (let index = 0; index < inputValues.length; index++) {
        if (inputValues[index]) {
          demolinktag = demolinktag + 1;

          if (index > 0) {
            linktag += ",";
          }
          linktag += inputValues[index];
        }
      }
      formData.append("user_social_media_link_data", linktag);
      axios.post(user_update, formData).then((Response) => {
        setloading(false);
        console.log("----", JSON.stringify(Response.data.result, null, 2));
        if (Response.data.status === 1) {
          // setErrorMessage(Response.data.message);
          // setShowModal(true);
          _getdata();
          navigate(-1);
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
      });
    }
  };

  const handleImagelogo = (e) => {
    const files = e.target.files;
    setupdateimage(files[0]);
    setProfile(URL.createObjectURL(files[0]));
    let formData = new FormData();
    formData.append("id", userId);
    formData.append("profile", files[0]);
    console.log("param", formData);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    console.log("FormData:", formDataObject);
    axios
      .post("https://www.ravi.host/api/change_profile", formData)
      .then((Response) => {
        console.log("Success", Response.data.status);
        if (Response.data.status === 1) {
          setErrorMessage(Response.data.message);
          setShowModal(true);
          _getdata();
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
      });

    // console.log("files 11", URL.createObjectURL(files[0]));
  };

  // video section start
  const [selectedVideos, setSelectedVideos] = useState([]);

  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedVideos([...selectedVideos, ...files]);
  // };

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 180; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...selectedVideos, file]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  // video functionality start

  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  // const [selectedVideos, setSelectedVideos] = useState([]);

  useEffect(() => {
    let timerInterval;
    const minDuration = 20;
    const maxDuration = 60;
    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;

          // Check if the recording time is less than the minimum duration
          // if (newTime < minDuration) {
          //   // Display an error or take other actions
          //   console.error(
          //     `Recording time should be at least ${minDuration} seconds.`
          //   );
          // }

          // Check if the recording time exceeds the maximum duration
          if (newTime >= maxDuration) {
            stopRecording1(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(0);
  };
  const showimage = () => {
    stopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
  };

  const recorderRef = useRef(null);

  const stopRecording = () => {
    setRecording(false);
    const minDuration = 20;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        if (recordingTime < minDuration) {
          // console.error(
          //   `Recording time should be at least ${minDuration} seconds.`
          // );
          alert(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(0);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };
  const stopRecording1 = () => {
    setRecording(false);
    const minDuration = 20;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        // if (recordingTime < minDuration) {
        //   // console.error(
        //   //   `Recording time should be at least ${minDuration} seconds.`
        //   // );
        //   alert(
        //     `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        //   );
        //   setRecordedChunks([]);
        //   setSelectedVideos([]);
        // } else {
        setRecordedChunks([blob]);
        setSelectedVideos([blob]);
        setRecordingTime(0);
        setIsModalVisiblevideo(false);
        // }
      });
    }
  };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(0);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };
  // video functionality end

  //  video section end

  // const changeprofile = (e) => {
  //   handleImagelogo(e);

  // };

  const handleImagepreview = (e) => {
    const files = e.target.files;
    setSelectedCover(files[0]);
    setSelectedCoverpreview(URL.createObjectURL(files[0]));
    console.log("files 11", files);
  };

  const handleVideoDelete = (index) => {
    const newVideos = [...selectedVideos];
    newVideos.splice(index, 1);
    setSelectedVideos(newVideos);
  };
  const [getuserVid2, setUserVideo2] = useState("");
  const [getuserVid3, setUserVideo3] = useState("");
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  const [inputValues, setInputValues] = useState([""]);

  const validateSocialMediaLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-])\/?$/;

    // Test if the inputValue matches the URL pattern
    return urlPattern.test(link);
  };
  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);
    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      setAlertModal(true);
      setMsg("Please enter valid social media links");
    }
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  const [getalertodal, setAlertModal] = useState(false);
  const [getmsg, setMsg] = useState("");

  const deletedata = () => {
    const param = {
      user_id: userId,
      user_social_media_link_id: getDeleteSocialId,
    };
    console.log(param);
    axios
      .post(
        "https://www.ravi.host/api/delete_user_social_media_link",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.status == 1) {
          console.log("====================================");
          console.log("res", Response.data);
          console.log("====================================");
          setconformationmodel(false);
          setErrorMessage1(Response.data.message);
          setShowModal1(true);
          _getdata();
        } else {
          setErrorMessage1(Response.data.message);
          setShowModal1(true);
        }
      })
      .catch((err) => {
        console.log("dzv sd", err);
      });
  };

  const closemodal3 = () => {
    setconformationmodel(false);
  };

  const closemodal2 = () => {
    setAlertModal(false);
  };
  const openmodal2 = () => {
    setAlertModal(true);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };
  const openmodal1 = () => {
    setShowModal1(true);
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp" style={{ position: "relative" }}>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <NavbarSec name="Profile" />
            <div className="user_profile_img">
              <div className="up_img">
                {profile ? (
                  <img
                    src={profile}
                    className="update_profie_img"
                    alt="Preview"
                    onClick={() => {
                      // setCropmodal(true);
                      navigate("/ProductViewImg2", {
                        state: {
                          img: profile ? profile : "",
                        },
                      });
                    }}
                  />
                ) : (
                  <img
                    src={images.logo}
                    className="update_profie_img"
                    alt="Profile"
                  />
                )}

                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  // onChange={(e) => {
                  //   // handleImagelogo(e);
                  //   setCropmodal(true);
                  // }}
                  onClick={() => {
                    setCropmodal(true);
                  }}
                  style={{ display: "none" }}
                />
              </div>
              <label style={{ textAlign: "center" }}>
                <img
                  src={images.camera2}
                  className="update_profie_img2"
                  alt="Upload"
                  onClick={() => {
                    setCropmodal(true);
                  }}
                />
              </label>
              <p>Share a Little bit about Yourself</p>
            </div>
            <br />
            <div className="edit_user_main">
              <div className="edit_user_nm">
                <div className="edit_user_nm1">
                  <img
                    src={images.user_white}
                    className="user_icon_edit"
                    alt=""
                  />
                </div>
                <div className="edit_user_nm2">
                  <p className="edit_nm_tag">Full Name</p>
                  <input
                    disabled
                    type="text"
                    value={name}
                    className="input_edit"
                    placeholder="Enter name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="edit_user_nm">
                <div className="edit_user_nm1">
                  <img
                    src={images.profile_white}
                    className="user_icon_edit"
                    alt=""
                  />
                </div>
                <div className="edit_user_nm2">
                  <p className="edit_nm_tag">User Name</p>
                  <input
                    type="text"
                    className="input_edit"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="edit_user_nm_text">
                <div className="edit_user_nm1_text">
                  <img
                    src={images.resume_white}
                    className="user_icon_edit"
                    alt=""
                  />
                </div>
                <div className="edit_user_nm2_text">
                  <p className="edit_nm_tag">My Bio</p>
                  <textarea
                    name=""
                    className="input_edit"
                    id=""
                    cols="30"
                    rows="2"
                    placeholder="Enter bio"
                    value={bio}
                    onChange={(e) => {
                      setBio(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="edit_user_nm_text">
                <div className="edit_user_nm1_text">
                  <img
                    src={images.resume_white}
                    className="user_icon_edit"
                    alt=""
                  />
                </div>
                <div className="edit_user_nm2_text">
                  <p className="edit_nm_tag">About me</p>
                  <textarea
                    name=""
                    className="input_edit"
                    id=""
                    cols="30"
                    rows="2"
                    placeholder="Enter story"
                    value={user_story == "null" ? "" : user_story}
                    onChange={(e) => {
                      setUserStory(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <Link
                to={"/editmobile"}
                state={{ number: number }}
                className="edit_user_nm"
              >
                <div className="edit_user_nm1">
                  <img
                    src={images.telephone_white}
                    className="user_icon_edit"
                    alt=""
                  />
                </div>
                <div className="edit_user_nm2">
                  <p className="edit_nm_tag">Mobile Number</p>
                  <input
                    type="number"
                    className="input_edit"
                    value={number}
                    readOnly
                  />
                </div>
              </Link>
              <Link
                to={"/editemail"}
                state={{ email: email }}
                className="edit_user_nm"
              >
                <div className="edit_user_nm1">
                  <img src={images.mail} className="user_icon_edit" alt="" />
                </div>
                <div className="edit_user_nm2">
                  <p className="edit_nm_tag">Email id</p>
                  <input
                    type="text"
                    className="input_edit"
                    value={email}
                    readOnly
                  />
                </div>
              </Link>

              <div style={{ margin: "5% 0" }}>
                {user_social_media_link &&
                user_social_media_link.length === 0 ? null : (
                  <>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "var(--font-600) !important",
                        color: "#fff",
                        paddingTop: 20,
                        marginLeft: 4,
                      }}
                    >
                      Uploaded Website & Media Links
                    </p>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {user_social_media_link &&
                        user_social_media_link.map((item, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: 20,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontFamily: "var(--font-600) !important",
                                color: "#fff",
                                marginLeft: 4,
                                width: "87%",
                              }}
                            >
                              {item.user_social_media_link_data}
                            </p>
                            <button
                              onClick={() => {
                                setconformationmodel(true);
                                setDeleteSocialId(
                                  item.user_social_media_link_id
                                );
                              }}
                            >
                              <img
                                style={{ width: 25, height: 25 }}
                                src={images.delete2}
                                alt="Delete"
                              />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </div>

              {/* UPLOAD COVER FILE START */}
              {/* <label htmlFor="auth_file" className="form_input_label mt-2">
                Cover
              </label>
              <div className="image-upload">
                <label htmlFor="file-input" className="upload-button">
                  <img src={images.gallery_icon} alt="" />
                </label>

                {selectedCover && selectedCover.length <= 0 ? (
                  <input
                    id="file-input"
                    type="file"
                    onChange={(e) => handleCoverChange(e)}
                  />
                ) : (
                  <button
                    onClick={() => setSelectedCover([])}
                    className="image-previews"
                    style={{
                      border: "2px dashed gray",
                      borderRadius: "4px",
                      // padding: "4px",
                    }}
                  >
                    <div className="image-preview">
                      <img
                        src={selectedCover}
                        alt={`Preview`}
                        style={{ margin: "0px" }}
                      />
                    </div>
                  </button>
                )}
              </div> */}
              <div className="edit_club_profile2">
                <b>Cover</b>
                <div className="edit_club_profile_sub3">
                  {selectedCoverpreview ==
                    "https://www.ravi.host/public/assets/user_cover_photo/" ||
                  selectedCoverpreview == null ||
                  selectedCoverpreview == "" ? (
                    <div className="edit_club_profile_file">
                      <input
                        type="file"
                        accept="image/*"
                        id="coverInput"
                        onChange={(e) => {
                          handleImagepreview(e);
                          // handleCoverChange(e);
                        }}
                        className="cover_file_set"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="coverInput" className="cover_upload_nm">
                        + Add Cover
                      </label>
                    </div>
                  ) : (
                    <>
                      <img
                        onClick={() => {
                          setSelectedCover("");
                          setSelectedCoverpreview("");
                        }}
                        src={images.delete2}
                        style={{
                          // backgroundColor: "red",
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                          zIndex: 11,
                          top: "10px",
                          right: "10px",
                        }}
                      />
                      <img
                        src={selectedCoverpreview}
                        alt="Cover Preview"
                        className="edit_club_profile_img"
                      />
                    </>
                  )}
                </div>
              </div>
              {/* UPLOAD COVER FILE END */}

              {/* AUTHENTICATION VIDEO START */}

              <div className="edit_club_profile2">
                <b>
                  Record Authentication video{" "}
                  <span
                    className="details_home_icon"
                    onClick={() => {
                      setShowModal1(true);
                      setErrorMessage1(
                        "Bring your Rare to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 30 seconds to 1 minute for the best impact."
                      );
                    }}
                  >
                    {String.fromCharCode(9432)}
                  </span>
                </b>
                {user_video_upload !== 1 && selectedVideos.length == 0 ? (
                  <div className="">
                    <div className="">
                      {/* <input
                        type="file"
                        id="videoInput"
                        onChange={handleVideoChange}
                        className="cover_file_set"
                        style={{ display: "none" }}
                        accept="video/*"
                        capture="camera"
                      />
                      <label htmlFor="videoInput" className="cover_upload_nm">
                        + Add Video
                      </label> */}
                      <label
                        onClick={() => {
                          setIsModalVisiblevideo(true);
                          setRecordedChunks([]);
                          setrecordedvideo(false);
                        }}
                        htmlFor="creator_vid"
                        className="upload-button"
                        style={{
                          width: "50%",
                          height: "100px",
                          textAlign: "center",
                        }}
                      >
                        {/* <img src={images.gallery_icon} alt="" /> */}
                        <label htmlFor="videoInput" className="cover_upload_nm">
                          + Add Video
                        </label>
                      </label>
                      <input
                        disabled
                        id="creator_vid"
                        type="file"
                        accept="video/*"
                        // multiple
                        capture="camera"
                        onChange={(e) => {
                          handleVideoChange(e);
                          // setIsModalVisiblevideo(true);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedVideos.length > 0 && (
                  <b>Uploaded Authentication Video</b>
                )}
                {selectedVideos.length > 0 &&
                  selectedVideos.map((image, ind) => {
                    return (
                      <>
                        <div
                          className="image-preview"
                          key={ind}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px dashed gray",
                            borderRadius: "8px",
                            width: "170px",
                            height: "100px",
                          }}
                        >
                          {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                          <button
                            onClick={() => {
                              setPreviewVideoModal2(true);
                              setUserVideo2(URL.createObjectURL(image));
                            }}
                            style={{
                              color: "var(--color-main)",
                              fontSize: "12px",
                              fontFamily: "var(--font-600)",
                            }}
                          >
                            Preview
                          </button>
                          <button
                            style={{ marginRight: "10px" }}
                            className="delete-button"
                            onClick={() => handleVideoDelete(ind)}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      </>
                    );
                  })}
              </div>

              {/* AUTHENTICATION VIDEO END */}
              <div className="av_videos_wrapp_2">
                {getvideoArray && getvideoArray.length > 0 ? (
                  getvideoArray.map((item, index) => {
                    console.log("item", item);
                    return (
                      <div
                        style={{ height: "100px" }}
                        className="av_single_video"
                      >
                        {/* <input
                          onClick={() => {
                            // setVideoId(item.user_video_id);
                            // setUserVideo(item.user_videos);
                            setAttechVideoModal(false);
                            console.log("item", item);
                          }}
                          type="checkbox"
                          className="av_of_checkbox"
                          checked={getvideoId === item.user_video_id}
                        /> */}
                        <button
                          onClick={() => {
                            setUserVideo3(item.user_videos);
                            setAttechVideoModal(true);
                            // setPreviewVideoModal(true);
                            // console.log("getuserVid", getuserVid);
                          }}
                          className="av_prev_btn"
                          style={{ width: "110%" }}
                        >
                          {item.user_thumbnail != "" ? (
                            <div
                              style={{
                                width: "100%",
                                height: "95px",
                                position: "relative",
                              }}
                            >
                              <img
                                src={item.user_thumbnail}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "95px",
                                  objectFit: "cover",
                                  margin: "0px",
                                  objectPosition: "top",
                                  borderRadius: "9px",
                                }}
                              />
                              <img
                                src={images.play_white}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "contain",
                                  margin: "0px",
                                  position: "absolute",
                                  transform: "translate(-50%, -50%)",
                                  left: "50%",
                                  top: "50%",
                                  zIndex: 11,
                                }}
                                alt=""
                              />
                            </div>
                          ) : (
                            "Preview"
                          )}
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <Link
                    className="novideo_btn"
                    // to={"/UserProfile"}
                    // state={{
                    //   user_id: userId,
                    // }}
                    to={"/editprofile"}
                  >
                    Upload Videos From Profile
                  </Link>
                )}
              </div>
              <label htmlFor="product_name" className="form_input_label mt-2">
                Website & Media Links
              </label>

              <>
                {inputValues.map((value, index) => (
                  <div
                    style={{ marginBottom: "20px" }}
                    key={index}
                    className="cp_medialink_signle_wrapp"
                  >
                    <input
                      style={{
                        width: "80%",
                        marginBottom: "0px",
                      }}
                      className="form_input_box"
                      placeholder="Enter a Link"
                      placeholderTextColor="gray"
                      value={value}
                      onChange={(e) => handleInputChange(e.target.value, index)}
                    />
                    {index === inputValues.length - 1 ? (
                      <div className="cp_ml_btns_wrapp">
                        <button
                          className="cp_ml_plus_btn"
                          onClick={handleAddValue}
                        >
                          +
                        </button>
                        {inputValues.length > 1 && (
                          <button
                            className="cp_ml_mius_btn"
                            onClick={handleRemoveValue}
                          >
                            -
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </>
              <center>
                <button
                  onClick={() => {
                    updateprofile();
                  }}
                  className="myupdate_profile"
                >
                  {" "}
                  Update Profile
                </button>
              </center>
              <br />
              <div className="view_managers_main">
                <Link to={"/viewmanagers"} className="view_managers">
                  <b>View managers</b>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        // <AlertModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   title="Information"
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 12,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp_2">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  {/* <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  /> */}
                  <div
                    onClick={() => {
                      setPreviewVideoModal2(false);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </div>
                </div>
                <p className="nav_screen_name">Preview</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {getattechVideoModal && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 12,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp_2">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setAttechVideoModal(false)}
                  />
                </div>
                <p className="nav_screen_name">Preview</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {showModal1 && (
        // <AlertModal
        //   msg={errorMessage1}
        //   setShowModal={setShowModal1}
        //   title="Information"
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage1}
        //   setShowModal={openmodal1}
        //   closemodal={closemodal1}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage1}
          setShowModal={setShowModal1}
          okfunction={closemodal1}
        />
      )}
      {getalertodal && (
        // <div className="cusModal_main_wrapp" style={{ bottom: "0px" }}>
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{getmsg}</p>
        //       <div className="cm_hr"></div>
        //       <Link className="cm_btn" onClick={() => setAlertModal(false)}>
        //         Okay
        //       </Link>
        //     </div>
        //   </div>
        // </div>
        // <NewModal
        //   title=""
        //   msg={getmsg}
        //   setShowModal={openmodal2}
        //   closemodal={closemodal2}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={getmsg}
          setShowModal={setAlertModal}
          okfunction={closemodal2}
        />
      )}
      {getconformationmodel && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       {/* <b>Information</b> */}
        //       <br />
        //       <p className="modal-message">
        //         {" "}
        //         Are you sure you want to delete link ?
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_two_buton">
        //       <button className="close_forgot" onClick={deletedata}>
        //         Okay
        //       </button>
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setconformationmodel(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </div>
        // <ReactModal
        //   isOpen={setconformationmodel}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal3}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Are you sure you want to delete link ?
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={deletedata}>
        //         Okay
        //       </button>
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setconformationmodel(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Are you sure you want to delete link ?"
          setShowModal={setconformationmodel}
          okfunction={deletedata}
        />
      )}
      {/* new record video modal start */}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{
            zIndex: 111,
            width: "100%",
            height: "95vh",
            bottom: "-5px",
          }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div style={{ height: "60vh" }}>
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    min
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={stopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  showimage();
                  setBodyScrollEnabled(true);
                }}
              >
                Cancel
              </button>
              {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      )}{" "}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{
            zIndex: 111,
            width: "100%",
            height: "95vh",
            bottom: "-5px",
          }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div style={{ height: "60vh" }}>
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={stopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  showimage();
                  setBodyScrollEnabled(true);
                  // Stop the media stream to release the camera
                  const stream = videoRef.current.srcObject;
                  if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => track.stop());
                  }
                }}
              >
                Cancel
              </button>
              {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      )}
      {/* new record video modal end */}
      {/* crop modal start */}
      {getcropmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              maxHeight: "75vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                maxWidth: "90%",
                margin: "10px auto",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                {image == "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="imageInput"
                      style={{ marginBottom: "10px" }}
                    >
                      Upload Image:
                    </label>
                    <div
                      className="image-upload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="file-input" className="upload-button">
                        <img src={images.gallery_icon} alt="" />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <br />
                    <br />
                    <Cropper
                      ref={cropperRef}
                      style={{ height: 250, width: "100%" }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={200}
                      minCropBoxWidth={200}
                      maxWidth={200}
                      maxHeight={200}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      guides={true}
                      aspectRatio={4 / 4}
                    />
                  </>
                )}
              </div>
              {image !== "" && (
                <div>
                  <div
                    className="box"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <h1>Preview</h1>
                    <div
                      className="img-preview"
                      style={{
                        width: "100%",
                        height: "150px",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  {/* <div
                  className="box"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <h1>
                    <span>Crop</span>
                    <button style={{ float: "right" }} onClick={getCropData}>
                      Crop Image
                    </button>
                  </h1>
                  <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                </div> */}
                </div>
              )}
            </div>
          </div>
          <div
            className="crop_img_yes_no_btn_wrapp"
            style={{ justifyContent: image == "" ? "center" : "space-between" }}
          >
            <button
              onClick={() => {
                setCropmodal(false);
                setImage("");
                console.log("getcropmodal", getcropmodal);
              }}
            >
              <IoCloseSharp color="#fff" size={28} />
            </button>
            {image !== "" && (
              <button onClick={getCropData}>
                <IoCheckmarkSharp color="#fff" size={28} />
              </button>
            )}
          </div>
        </div>
      )}
      {/* Display the cropped image */}
      {/* {croppedImage && (
        <div className="crop_modal_base_wrapp">
          <p>Cropped Image:</p>
          <img src={croppedImage} alt="Cropped Preview" />
        </div>
      )} */}
      {/* crop modal end */}
      <ToastContainer />
    </div>
  );
};

export default Editprofile;
