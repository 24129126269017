import React, { useEffect, useState } from "react";
import "./editmobile.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import { update_user_mobile_number } from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";

const Editmobile = () => {
  const location = useLocation();
  const [new_phone, setNewphone] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("user_id");
  const [getoldmail, setoldmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.email) {
      console.log(" location.state", location.state.number);
    }
  }, []);

  const handlemobileChange = () => {
    if (new_phone == "") {
      toast.error("Enter New Mobile number", {
        theme: "colored",
      });
    } else if (location.state.number === new_phone) {
      toast.error(
        "The new Phone number must be different from the old phone Number.",
        {
          theme: "colored",
        }
      );
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("old_phone_number", location.state.number);
      formData.append("new_phone_number", new_phone);
      console.log(formData);
      axios
        .post(update_user_mobile_number, formData)
        .then((response) => {
          console.log(response.data.status);
          setIsLoading(false);
          if (response.data.status == 1) {
            // props.navigation.navigate('UpdateEmailOtp', {
            //   new_email: new_email,
            //   user_id: props.user_id,
            // });
            navigate("/editmobileotp", {
              state: {
                number: new_phone,
              },
            });
          } else {
            toast.error(response.data.message, {
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          // Handle error
        });
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setNewphone(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setNewphone(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        {isLoading ? (
          <NewLoader />
        ) : (
          <>
            <div
              className="play_screen_main_wrapp"
              style={{ padding: "10px 5%" }}
            >
              <div className="legacy_dashbord_nav_sec">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Update Mobile no.</p>
                <div></div>
              </div>
              <div style={{ height: "70vh", width: "100%" }}>
                <div
                  style={{ marginTop: "30px" }}
                  className="ep_main_wrapp_txt_username"
                >
                  <input
                    readOnly
                    value={location.state.number}
                    placeholder="Current Mobile No"
                    className="ep_text_input_username"
                  />
                  <div className="ep_absolue_txt_username">
                    <p className="ep_position_two_p">Current Mobile No</p>
                  </div>
                </div>
                <div
                  style={{ marginTop: "30px" }}
                  className="ep_main_wrapp_txt_username"
                >
                  <input
                    type="phone"
                    value={new_phone}
                    onChange={(e) => {
                      handlePhoneNumberChange(e);
                    }}
                    placeholder="New Mobile No."
                    className="ep_text_input_username"
                  />
                  <div className="ep_absolue_txt_username">
                    <p className="ep_position_two_p">New Mobile No</p>
                  </div>
                </div>
              </div>
              <button
                onClick={() => handlemobileChange()}
                className="new_btn_wrapp"
                style={{
                  bottom: "10%",
                  width: "100%",
                  //   left: "10%",
                  transform: "translate(0%, 50%)",
                  background: "#000",
                  maxWidth: "var(--max-width-sm)",
                  marginTop: "20px",
                  marginBottom: "50px",
                }}
              >
                <p className="ep_p_tag_preview">Submit</p>
              </button>
            </div>
          </>
        )}
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Editmobile;
