import React from "react";
import "./productviewlist.css";
import images from "../../constants/images";
import NavbarSec from "../../common/NavbarSec";

const Productviewlist = () => {
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name=""  />
        <div className="Productview_list">
            <div className="Productview_list_view">
                <p className="product_number_list">1</p>   
                <img src={images.media1} className="Productview_list_img" alt="" />
                <p className="Productview_list_nm">Rohit</p>
                <p className="product_price_list">₹100/-</p>
            </div>
            <div className="Productview_list_view">
                <p className="product_number_list">1</p>   
                <img src={images.media1} className="Productview_list_img" alt="" />
                <p className="Productview_list_nm">Rohit</p>
                <p className="product_price_list">₹100/-</p>
            </div>
            <div className="Productview_list_view">
                <p className="product_number_list">1</p>   
                <img src={images.media1} className="Productview_list_img" alt="" />
                <p className="Productview_list_nm">Rohit</p>
                <p className="product_price_list">₹100/-</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Productviewlist;
