import React, { useEffect, useState } from "react";
import "./placebid.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import {
  add_joyride,
  check_user_club_is_member,
  get_release_bond_highest_bid,
  get_user_joyride,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import CustomJoyride from "../../utils/CustomJoyride";
import ReactJoyride from "react-joyride";

const steps = [
  {
    target: ".pb_joy_1",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
];

const Placebid = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const showmodel = localStorage.getItem("showorwhat");
  const [isLoading, setIsLoading] = useState(false);
  const [is_club_id, setis_club_id] = useState(location.state.is_club_id);
  const [club_id, setClub_id] = useState(location.state.club_id);
  const [is_club_member, setis_club_member] = useState(
    location.state.is_club_member
  );
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [is_club, setis_club] = useState(location.state.is_club);
  const [data, setData] = useState([]);
  const [getamount, setamount] = useState("");
  const [bidmsg, setbidmsg] = useState("");
  const [getclubcon, setclubcon] = useState(false);
  const [getconformationmodel, setconformationmodel] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   GetProductDetail();
  // }, []);

  const [run, setRun] = useState(false);

  useEffect(() => {
    if (location.state.join_club_product == 1) {
      setis_club_id(location.state.is_club_id);
    } else {
      setis_club_id(location.state.is_club);
    }

    console.log(
      "====================================" +
        location.state.is_club_id +
        " 1- " +
        location.state.is_club_member +
        " 2- " +
        location.state.is_club +
        " 3- "
    );
    GetProductDetail();
    if (club_id != "" || club_id != 0) {
      checkMEmber();
    }
    setamount("");
  }, []);

  const GetProductDetail = async () => {
    setIsLoading(true);

    const param = {
      release_bond_id: location.state.release_bond_id,
      uniq_code: location.state.uniq_code,
      is_nfb: location.state.is_nfb,
    };

    await axios
      .post(get_release_bond_highest_bid, param)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          const data = res.data.result;
          setData(data);

          console.log("asset view response ->", res.data);
        } else {
          console.log("asset view err ->", res.data);
          // setIsModalVisible("true");
          // setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const checkMEmber = () => {
    if (club_id == "") {
    } else {
      const param = {
        club_id: is_club_id,
        user_id: userId,
      };
      console.log("check_user_club_is_member == param", param);
      axios
        .post(check_user_club_is_member, qs.stringify(param))
        .then((Response) => {
          console.log("Res", Response.data);
          console.log("====================================");
          if (Response.data.result == 1) {
            setis_club_member(1);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const Placebiddone = () => {
    console.log(location.state);
    // if (location.state.is_auction == 1) {
    //   if (location.state.is_auction == 1 && location.state.is_place_bid == 1) {
    //     if (getamount == 0) {
    //       setErrorMessage("Please enter bid amount");
    //       setShowModal(true);
    //     } else {
    //       if (location.state.is_club_id == "") {
    //         if (location.state.partner_bond_benifit_data == 0) {
    //           navigate("/bidscreate", {
    //             state: {
    //               accept_bond_bid_id: location.state.accept_bond_bid_id,
    //               is_globle: location.state.is_globle,
    //               release_bond_id: location.state.release_bond_id,
    //               amount: getamount,
    //               uniq_code: location.state.uniq_code,
    //               is_nfb: location.state.is_nfb,
    //               product_id: location.state.product_id,
    //               partned_id: 0,
    //               partner_benifit_id: 0,
    //               benifit_price: 0,
    //               is_club: is_club,
    //             },
    //           });
    //         } else {
    //           navigate("/bidscreate", {
    //             state: {
    //               accept_bond_bid_id:
    //               location.state
    //                 .accept_bond_bid_id,
    //             is_globle:
    //               location.state.is_globle,
    //             is_club: is_club,
    //             release_bond_id:
    //               location.state.release_bond_id,
    //             uniq_code:
    //               location.state.uniq_code,
    //             is_nfb: location.state.is_nfb,
    //             minimum_bid_amount:
    //               location.state
    //                 .minimum_bid_amount,
    //             amount: getamount,
    //             product_id:
    //               location.state.product_id,
    //             },
    //           });
    //         }
    //       } else {
    //         if (is_club_member ==1) {
    //           if (parseInt(location.state.minimum_bid_amount) <= parseInt(getamount)) {
    //             if (parseInt(location.state.partner_bond_benifit_data) == 0) {
    //               navigate("/bidscreate", {
    //                 state: {
    //                   is_club: is_club,
    //                   is_globle:
    //                     location.state.is_globle,
    //                   accept_bond_bid_id:
    //                     location.state
    //                       .accept_bond_bid_id,
    //                   release_bond_id:
    //                     location.state
    //                       .release_bond_id,
    //                   amount: getamount,
    //                   uniq_code:
    //                     location.state.uniq_code,
    //                   is_nfb:
    //                     location.state.is_nfb,
    //                   product_id:
    //                     location.state.product_id,
    //                   partned_id: 0,
    //                   partner_benifit_id: 0,
    //                   benifit_price: 0,
    //                 },
    //               });
    //             }
    //           } else {
    //             navigate("/bidscreate", {
    //               state: {
    //                 accept_bond_bid_id:
    //                 location.state
    //                   .accept_bond_bid_id,
    //               is_globle:
    //                 location.state.is_globle,
    //               is_club:is_club,
    //               release_bond_id:
    //                 location.state
    //                   .release_bond_id,
    //               uniq_code:
    //                 location.state.uniq_code,
    //               is_nfb:
    //                 location.state.is_nfb,
    //               minimum_bid_amount:
    //                 location.state
    //                   .minimum_bid_amount,
    //               amount: getamount,
    //               product_id:
    //                 location.state.product_id,
    //               },
    //             });
    //           }
    //         } else {
    //           setErrorMessage('Please enter bid grater then minimum bid value');
    //           setShowModal(true);
    //         }
    //       } else {

    //       }
    //     }
    //   }
    // }

    if (location.state.is_auction == 1) {
      if (location.state.is_auction == 1 && location.state.is_place_bid == 1) {
        if (getamount == 0) {
          setErrorMessage("Please enter bid amount");
          setShowModal(true);
        } else {
          if (is_club_id == "") {
            if (parseInt(location.state.partner_bond_benifit_data) == 0) {
              navigate("/bidscreate", {
                state: {
                  share_by_user: location.state.share_by_user,
                  accept_bond_bid_id: location.state.accept_bond_bid_id,
                  is_globle: location.state.is_globle,
                  release_bond_id: location.state.release_bond_id,
                  amount: getamount,
                  uniq_code: location.state.uniq_code,
                  is_nfb: location.state.is_nfb,
                  product_id: location.state.product_id,
                  partned_id: 0,
                  partner_benifit_id: 0,
                  benifit_price: 0,
                  is_club: is_club_id,
                  user_id: userId,
                  product_name: location.state.product_name,
                },
              });
            } else {
              navigate("/benefitshow", {
                state: {
                  share_by_user: location.state.share_by_user,
                  accept_bond_bid_id: location.state.accept_bond_bid_id,
                  is_globle: location.state.is_globle,
                  is_club: is_club_id,
                  release_bond_id: location.state.release_bond_id,
                  uniq_code: location.state.uniq_code,
                  is_nfb: location.state.is_nfb,
                  minimum_bid_amount: location.state.minimum_bid_amount,
                  amount: getamount,
                  product_id: location.state.product_id,
                  product_name: location.state.product_name,
                },
                //location.state.partner_bond_benifit_data
              });
            }
          } else {
            if (is_club_member == 1) {
              if (
                parseInt(location.state.minimum_bid_amount) <=
                parseInt(getamount)
              ) {
                if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                  navigate("/bidscreate", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      is_club: is_club_id,
                      is_globle: location.state.is_globle,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      release_bond_id: location.state.release_bond_id,
                      amount: getamount,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      product_id: location.state.product_id,
                      partned_id: 0,
                      partner_benifit_id: 0,
                      benifit_price: 0,
                      product_name: location.state.product_name,
                    },
                  });
                } else {
                  navigate("/benefitshow", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      is_globle: location.state.is_globle,
                      is_club: is_club_id,
                      release_bond_id: location.state.release_bond_id,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      minimum_bid_amount: location.state.minimum_bid_amount,
                      amount: getamount,
                      product_id: location.state.product_id,
                      product_name: location.state.product_name,
                    },
                    //location.state.partner_bond_benifit_data
                  });
                }
              } else {
                setErrorMessage(
                  "Please enter bid grater then minimum bid value"
                );
                setShowModal(true);
              }
            } else {
              // Alert.alert(
              //   'Alert',
              //   'You are not member of club, join club for place a bid',
              //   [
              //     {
              //       text: 'Cancel',
              //       onPress: () =>
              //         console.log('Cancel Pressed'),
              //       style: 'cancel',
              //     },
              //     {
              //       text: 'OK',
              //       onPress: () => {
              //         this.props.navigation.navigate(
              //           'ClubProfile',
              //           {
              //             club_id: is_club_id,
              //           },
              //         );
              //       },
              //     },
              //   ],
              // );
              setclubcon(true);
              setErrorMessage(
                "You are not a member of this club, please join this club to place a bid."
              );
              setShowModal(true);
            }
          }
        }
      } else {
        console.log("Please select msg else");
        if (getamount == 0) {
          setErrorMessage("Please enter bid amount");
          setShowModal(true);
        } else {
          if (is_club_id == "") {
            if (
              parseInt(location.state.minimum_bid_amount) < parseInt(getamount)
            ) {
              if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                navigate("/bidscreate", {
                  state: {
                    share_by_user: location.state.share_by_user,
                    accept_bond_bid_id: location.state.accept_bond_bid_id,
                    is_globle: location.state.is_globle,
                    release_bond_id: location.state.release_bond_id,
                    amount: getamount,
                    uniq_code: location.state.uniq_code,
                    is_nfb: location.state.is_nfb,
                    product_id: location.state.product_id,
                    partned_id: 0,
                    partner_benifit_id: 0,
                    benifit_price: 0,
                    is_club: is_club_id,
                    product_name: location.state.product_name,
                  },
                });
              } else {
                navigate("/benefitshow", {
                  state: {
                    share_by_user: location.state.share_by_user,
                    accept_bond_bid_id: location.state.accept_bond_bid_id,
                    is_globle: location.state.is_globle,
                    is_club: is_club_id,
                    release_bond_id: location.state.release_bond_id,
                    uniq_code: location.state.uniq_code,
                    is_nfb: location.state.is_nfb,
                    minimum_bid_amount: location.state.minimum_bid_amount,
                    amount: getamount,
                    product_id: location.state.product_id,
                    product_name: location.state.product_name,
                  },
                  //location.state.partner_bond_benifit_data
                });
              }
            } else {
              setErrorMessage(
                "Oops! It seems like the bid amount you entered is lower than the minimum bid value set for this Rarerez. To participate in the bidding process, please enter a bid amount that is higher than the minimum bid value. This ensures fair competition among bidders and increases your chances of winning this exclusive Rarerez. Thank you for your understanding and happy bidding on Rarerez Club!."
              );
              setShowModal(true);
            }
          } else {
            if (is_club_member == 1) {
              if (
                parseInt(location.state.minimum_bid_amount) <=
                parseInt(getamount)
              ) {
                if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                  navigate("/bidscreate", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      is_club: is_club_id,
                      is_globle: location.state.is_globle,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      release_bond_id: location.state.release_bond_id,
                      amount: getamount,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      product_id: location.state.product_id,
                      partned_id: 0,
                      partner_benifit_id: 0,
                      benifit_price: 0,
                      product_name: location.state.product_name,
                    },
                  });
                } else {
                  navigate("/benefitshow", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      is_globle: location.state.is_globle,
                      is_club: is_club_id,
                      release_bond_id: location.state.release_bond_id,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      minimum_bid_amount: location.state.minimum_bid_amount,
                      amount: getamount,
                      product_id: location.state.product_id,
                      product_name: location.state.product_name,
                    },
                    //location.state.partner_bond_benifit_data
                  });
                }
              } else {
                setErrorMessage(
                  "Please enter bid grater then minimum bid value"
                );
                setShowModal(true);
              }
            } else {
              // Alert.alert(
              //   'Alert',
              //   'You are not member of club, join club for place a bid',
              //   [
              //     {
              //       text: 'Cancel',
              //       onPress: () =>
              //         console.log('Cancel Pressed'),
              //       style: 'cancel',
              //     },
              //     {
              //       text: 'OK',
              //       onPress: () => {
              //         this.props.navigation.navigate(
              //           'ClubProfile',
              //           {
              //             club_id: is_club_id,
              //           },
              //         );
              //       },
              //     },
              //   ],
              // );
              setclubcon(true);
              setErrorMessage(
                "You are not a member of this club, please join this club to place a bid."
              );
              setShowModal(true);
            }
          }
        }
      }
    } else {
      console.log("bidmsg 11", bidmsg);
      console.log("is_place_bid", location.state.is_place_bid);
      // if (bidmsg === "") {
      //   // this.setState({
      //   //   modalvisibleauthetication: true,
      //   // });
      // } else {
      if (location.state.is_auction == 1 && location.state.is_place_bid == 1) {
        if (getamount == 0) {
          setErrorMessage("Please enter bid amount");
          setShowModal(true);
        } else {
          if (is_club_id == "") {
            if (parseInt(location.state.partner_bond_benifit_data) == 0) {
              navigate("/bidscreate", {
                state: {
                  share_by_user: location.state.share_by_user,
                  accept_bond_bid_id: location.state.accept_bond_bid_id,
                  is_globle: location.state.is_globle,
                  release_bond_id: location.state.release_bond_id,
                  amount: getamount,
                  uniq_code: location.state.uniq_code,
                  is_nfb: location.state.is_nfb,
                  product_id: location.state.product_id,
                  partned_id: 0,
                  partner_benifit_id: 0,
                  benifit_price: 0,
                  is_club: is_club_id,
                  product_name: location.state.product_name,
                },
              });
            } else {
              navigate("/benefitshow", {
                state: {
                  share_by_user: location.state.share_by_user,
                  accept_bond_bid_id: location.state.accept_bond_bid_id,
                  is_globle: location.state.is_globle,
                  is_club: is_club_id,
                  release_bond_id: location.state.release_bond_id,
                  uniq_code: location.state.uniq_code,
                  is_nfb: location.state.is_nfb,
                  minimum_bid_amount: location.state.minimum_bid_amount,
                  amount: getamount,
                  product_id: location.state.product_id,
                  product_name: location.state.product_name,
                },
                //location.state.partner_bond_benifit_data
              });
            }
          } else {
            if (is_club_member == 1) {
              if (
                parseInt(location.state.minimum_bid_amount) <=
                parseInt(getamount)
              ) {
                if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                  navigate("/bidscreate", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      is_club: is_club_id,
                      is_globle: location.state.is_globle,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      release_bond_id: location.state.release_bond_id,
                      amount: getamount,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      product_id: location.state.product_id,
                      partned_id: 0,
                      partner_benifit_id: 0,
                      benifit_price: 0,
                      product_name: location.state.product_name,
                    },
                  });
                } else {
                  navigate("/benefitshow", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      is_globle: location.state.is_globle,
                      is_club: is_club_id,
                      release_bond_id: location.state.release_bond_id,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      minimum_bid_amount: location.state.minimum_bid_amount,
                      amount: getamount,
                      product_id: location.state.product_id,
                      product_name: location.state.product_name,
                    },
                    //location.state.partner_bond_benifit_data
                  });
                }
              } else {
                setErrorMessage(
                  "Please enter bid grater then minimum bid value"
                );
                setShowModal(true);
              }
            } else {
              // Alert.alert(
              //   "Alert",
              //   "You are not member of club, join club for place a bid",
              //   [
              //     {
              //       text: "Cancel",
              //       onPress: () => console.log("Cancel Pressed"),
              //       style: "cancel",
              //     },
              //     {
              //       text: "OK",
              //       onPress: () => {
              //         this.props.navigation.navigate("ClubProfile", {
              //           club_id: is_club_id,
              //         });
              //       },
              //     },
              //   ]
              // );
              setclubcon(true);
              setErrorMessage(
                "You are not a member of this club, please join this club to place a bid."
              );
              setShowModal(true);
            }
          }
        }
      } else {
        console.log("aa");
        if (getamount == 0) {
          setErrorMessage("Please enter bid amount");
          setShowModal(true);
        } else {
          if (is_club_id == "" || is_club_id == undefined) {
            if (
              parseInt(location.state.minimum_bid_amount) < parseInt(getamount)
            ) {
              if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                navigate("/bidscreate", {
                  state: {
                    share_by_user: location.state.share_by_user,
                    accept_bond_bid_id: location.state.accept_bond_bid_id,
                    is_globle: location.state.is_globle,
                    release_bond_id: location.state.release_bond_id,
                    amount: getamount,
                    uniq_code: location.state.uniq_code,
                    is_nfb: location.state.is_nfb,
                    product_id: location.state.product_id,
                    partned_id: 0,
                    partner_benifit_id: 0,
                    benifit_price: 0,
                    is_club: is_club_id,
                    product_name: location.state.product_name,
                  },
                });
              } else {
                navigate("/benefitshow", {
                  state: {
                    share_by_user: location.state.share_by_user,
                    accept_bond_bid_id: location.state.accept_bond_bid_id,
                    is_globle: location.state.is_globle,
                    is_club: is_club_id,
                    release_bond_id: location.state.release_bond_id,
                    uniq_code: location.state.uniq_code,
                    is_nfb: location.state.is_nfb,
                    minimum_bid_amount: location.state.minimum_bid_amount,
                    amount: getamount,
                    product_id: location.state.product_id,
                    product_name: location.state.product_name,
                  },
                  //location.state.partner_bond_benifit_data
                });
              }
            } else {
              setErrorMessage(
                "Oops! It seems like the bid amount you entered is lower than the minimum bid value set for this Rarerez. To participate in the bidding process, please enter a bid amount that is higher than the minimum bid value. This ensures fair competition among bidders and increases your chances of winning this exclusive Rarerez. Thank you for your understanding and happy bidding on Rarerez Club!."
              );
              setShowModal(true);
            }
          } else {
            if (is_club_member == 1) {
              if (
                parseInt(location.state.minimum_bid_amount) <=
                parseInt(getamount)
              ) {
                if (parseInt(location.state.partner_bond_benifit_data) == 0) {
                  navigate("/bidscreate", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      is_club: is_club_id,
                      is_globle: location.state.is_globle,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      release_bond_id: location.state.release_bond_id,
                      amount: getamount,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      product_id: location.state.product_id,
                      partned_id: 0,
                      partner_benifit_id: 0,
                      benifit_price: 0,
                      product_name: location.state.product_name,
                    },
                  });
                } else {
                  navigate("/benefitshow", {
                    state: {
                      share_by_user: location.state.share_by_user,
                      accept_bond_bid_id: location.state.accept_bond_bid_id,
                      is_globle: location.state.is_globle,
                      is_club: is_club_id,
                      release_bond_id: location.state.release_bond_id,
                      uniq_code: location.state.uniq_code,
                      is_nfb: location.state.is_nfb,
                      minimum_bid_amount: location.state.minimum_bid_amount,
                      amount: getamount,
                      product_id: location.state.product_id,
                      product_name: location.state.product_name,
                    },
                    //location.state.partner_bond_benifit_data
                  });
                }
              } else {
                setErrorMessage(
                  "Please enter bid grater then minimum bid value"
                );
                setShowModal(true);
              }
            } else {
              setErrorMessage(
                "You are not a member of this club, please join this club to place a bid."
              );
              setShowModal(true);
              setclubcon(true);
              // Alert.alert(
              //   "Alert",
              //   "You are not member of club, join club for place a bid",
              //   [
              //     {
              //       text: "Cancel",
              //       onPress: () => console.log("Cancel Pressed"),
              //       style: "cancel",
              //     },
              //     {
              //       text: "OK",
              //       onPress: () => {
              //         this.props.navigation.navigate("ClubProfile", {
              //           club_id: is_club_id,
              //         });
              //       },
              //     },
              //   ]
              // );
            }
          }
        }
      }
      // }
    }
  };

  const handleKeyDown = (e) => {
    // Check if the pressed key is a digit, backspace, or minus sign at the beginning
    if (
      !(
        (e.key >= "0" && e.key <= "9") ||
        e.key === "Backspace" ||
        (e.key === "-" &&
          e.target.selectionStart === 0 &&
          e.target.value.indexOf("-") === -1)
      )
    ) {
      e.preventDefault();
    }
  };

  // ...

  <input type="text" onKeyDown={handleKeyDown} />;

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  useEffect(() => {
    localStorage.getItem("modalbidconfirm");
  }, []);

  const handleKeyPress = (e) => {
    // Check if the pressed key is a dot
    if (!(e.charCode >= 48 && e.charCode <= 57) && e.charCode !== 8) {
      e.preventDefault();
    }
  };

  const closemodal1 = () => {
    setconformationmodel(false);
  };
  const openmodal1 = () => {
    setconformationmodel(true);
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["placebid_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            setRun(false);
          } else {
            setRun(true);
          }
        } else {
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "placebid_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end
  return (
    <>
      {/* <CustomJoyride
        steps={steps}
        run={run}
        showProgress={false}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 100000,
            // primaryColor: "var(--color-main)",
          },

          spotlight: {
            backgroundColor: "transparent",
          },
        }}
      /> */}
      {/* <ReactJoyride steps={steps} /> */}
      <div className="page_main_wrapp">
        <div className="page_base_wrapp_2">
          <div className="page_navbar_wrapp">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="nav_back_wrappplsbis"
            >
              {/* <img
              src={images.back}
              className="nav_back_btn"
              alt=""
              onClick={() => navigate(-1)}
            /> */}
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p className="nav_screen_nameplsbis" style={{ paddingTop: 0 }}>
              Bids
            </p>
          </div>
          {location.state.is_auction == 1 ? (
            <div className="pb_content">
              <p className="pb_content_p">
                Welcome to the auction process for this exclusive Rarerez!
                Here's how it works: The creator has released 10 Rarerez of the
                asset for Auction. To secure an Rarerez of the asset, you need
                to place a bid before the final auction date. The top 10 bidders
                with the highest bids by the end of the auction will each win an
                Rarerez of this asset.
              </p>
              <p className="pb_content_p_one">
                Remember, the competition will be fierce, so make sure to place
                your bid strategically and stay engaged until the final auction
                date. This is your chance to own a unique digital asset and be
                part of an exclusive community. Good luck and happy Auction on
                rarerez.club!
              </p>
              <p className="pb_content_two">
                You can not withdraw the Auction bid. If your bid get rejected
                only then you will get back you refund amount after the auction
                gets closed.
              </p>
            </div>
          ) : null}

          <br />
          <div className="pb_price">
            <p className="pb_price_p">Starting bid</p>
            <div
              style={
                {
                  // backgroundColor: "red",
                  // width: "80%",
                  // textOverflow: "inherit",
                }
              }
            >
              <h1
                // style={{ width: "100%", margin: "0px auto" }}
                className="pb_price_h1"
              >
                <p>₹</p>
                {location.state.minimum_bid_amount}
              </h1>
            </div>

            {location.state.is_auction == 1 &&
            location.state.is_place_bid == 1 ? (
              <p className="pb_price_h1">
                Your placed bid ₹ {location.state.place_bid_amount}
              </p>
            ) : null}

            <p
              style={{
                color: "white",
                fontFamily: "var(--font-400)",
                fontSize: "12px",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              The seller will notify you when the value of your bid reaches{" "}
              {location.state.notify_value}&nbsp;or more.
            </p>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <div className="pb_input pb_joy_1">
              <input
                type="text" // Change type to text
                placeholder={
                  location.state.is_auction == 1 &&
                  location.state.is_place_bid == 1 &&
                  parseInt(location.state.minimum_bid_amount) !==
                    parseInt(location.state.place_bid_amount)
                    ? `Enter your bid ${
                        parseInt(location.state.minimum_bid_amount) -
                        parseInt(location.state.place_bid_amount)
                      } or more`
                    : "Enter your bid"
                }
                className="pb_amount"
                onKeyDown={handleKeyDown}
                value={getamount}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^\d*\.?\d*$/.test(inputValue); // Regular expression for decimal number
                  if (!isValid) {
                    return; // Don't update state if input is not a valid decimal number
                  }
                  setamount(inputValue); // Update state with the input value
                }}
                inputMode="numeric"
              />

              {location.state.currenbt_stock == 0 ? null : (
                <button
                  // onClick={() => Placebiddone()}
                  onClick={() => {
                    const modalbidconfirm =
                      localStorage.getItem("modalbidconfirm");
                    console.log("modalbidconfirm", modalbidconfirm);
                    if (
                      showmodel == null ||
                      showmodel == "null" ||
                      showmodel == false ||
                      showmodel == "false"
                    ) {
                      setconformationmodel(true);
                    } else {
                      Placebiddone();
                      // console.log("location.state", location.state);
                    }
                  }}
                  type="submit"
                  className="next_2_new"
                >
                  <img
                    type="submit"
                    src={images.next_2}
                    className="next_2_new"
                    alt=""
                  />
                </button>
              )}
            </div>
          </div>
          <h1 className="pb_titel">
            {data.length == 0 ? "No Activities" : "Bids"}
          </h1>
          {data &&
            data.map((item, index) => {
              return (
                <div className="pb_details">
                  <div className="pb_details2">
                    <h1 className="pb_details_h1">
                      {location.state.product_name}
                    </h1>
                    <div className="pb_details_sub">
                      <div className="pb_sub_1">
                        <img
                          src={item.user_profile}
                          className="pb_details_img"
                          alt=""
                        />
                      </div>
                      <div className="pb_sub_2">
                        <p className="pb_details_titel">
                          bid placed by {item.username}
                        </p>
                        <p className="pb_details_date">{item.bidding_date}</p>
                      </div>
                      <div className="pb_sub_3">
                        <p className="pb_details_price">₹{item.bid_amount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {showModal && (
          // <div className="cusModal_main_wrapp">
          //   <div className="cusmodal_inner_wrapp">
          //     <img src={images.logo} alt="" className="cm_logo" />
          //     <p className="cm_heading">Alert</p>
          //     <div className="cm_des_wrapp">
          //       <p className="cm_des">{errorMessage}</p>
          //       <div className="cm_hr"></div>
          //       <button
          //         className="cm_btn"
          //         onClick={() => {
          //           getclubcon == true
          //             ? navigate("/Clubprofile", {
          //                 state: {
          //                   club_id: is_club_id,
          //                 },
          //               })
          //             : setShowModal(false);
          //         }}
          //       >
          //         Okay
          //       </button>
          //     </div>
          //   </div>
          // </div>

          // <ReactModal
          //   isOpen={setShowModal}
          //   // onAfterOpen={afterOpenModal}
          //   onRequestClose={closemodal}
          //   style={customStyles}
          // >
          //   <div className="NewModal_main_wrapp">
          //     <div
          //       style={{
          //         display: "flex",
          //         flexDirection: "column",
          //         alignItems: "center",
          //         justifyContent: "center",
          //       }}
          //     >
          //       <img className="newmodal_logo" src={images.logo} alt="" />
          //       <p className="newmodal_titel"></p>
          //       {/* <p className="newmodal_titel">Alert</p> */}
          //     </div>

          //     {/* <div className="newmodal_des_wrapp"> */}
          //     <p className="newmodal_des">{errorMessage}</p>
          //     {/* </div> */}

          //     <button
          //       className="newmodal_btn"
          //       onClick={() => {
          //         getclubcon == true
          //           ? navigate("/Clubprofile", {
          //               state: {
          //                 club_id: is_club_id,
          //               },
          //             })
          //           : setShowModal(false);
          //       }}
          //     >
          //       Okay
          //     </button>
          //   </div>
          // </ReactModal>

          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage}
            setShowModal={setShowModal}
            okfunction={() => {
              getclubcon == true
                ? navigate("/Clubprofile", {
                    state: {
                      club_id: is_club_id,
                    },
                  })
                : setShowModal(false);
            }}
          />
        )}

        {getconformationmodel && (
          <div className="v2modal_main_wrapp">
            <div className="v2modal_inner_wrapp">
              <div className="v2modal_fixed_logo_wrapp">
                <img src={images.logo} alt="" />
              </div>
              <button
                className="v2modal_close_img"
                onClick={() => {
                  setconformationmodel(false);
                }}
              >
                <img
                  src={images.new_close_logo}
                  className="v2modal_close_img"
                  alt=""
                />
              </button>
              <div className="v2modal_fixed_text_wrapp">
                <p>
                  {" "}
                  After placing a bid, you won't be able to withdraw or revert
                  it back for the next 10 days.
                </p>
              </div>

              <div className="v2modal_fixed_btns_wrapp">
                <button
                  className="v2modal_btn1"
                  onClick={() => {
                    localStorage.setItem("showorwhat", "false");
                    setconformationmodel(false);
                    // {
                    if (location.state.currenbt_stock !== 0) {
                      Placebiddone();
                    }
                    // }
                  }}
                >
                  Continue
                </button>

                <button
                  className="v2modal_btn2"
                  onClick={() => {
                    localStorage.setItem("showorwhat", "true");
                    setconformationmodel(false);
                    // {
                    if (location.state.currenbt_stock !== 0) {
                      Placebiddone();
                    }
                    // }
                  }}
                >
                  Don't show again
                </button>
              </div>
            </div>
          </div>

          // <ReactModal
          //   isOpen={setconformationmodel}
          //   // onAfterOpen={afterOpenModal}
          //   onRequestClose={closemodal1}
          //   style={customStyles}
          // >
          //   <div className="NewModal_main_wrapp">
          //     <div
          //       style={{
          //         display: "flex",
          //         flexDirection: "column",
          //         alignItems: "center",
          //         justifyContent: "center",
          //       }}
          //     >
          //       <img className="newmodal_logo" src={images.logo} alt="" />
          //       <p className="newmodal_titel"></p>
          //       {/* <p className="newmodal_titel">Alert</p> */}
          //     </div>

          //     {/* <div className="newmodal_des_wrapp"> */}
          //     <p className="newmodal_des">
          //       After placing a bid, you won't be able to withdraw or revert it
          //       back for the next 10 days.
          //     </p>

          //     <div className="modal_two_buton" style={{ width: "100%" }}>
          //       <button
          //         className="close_forgot"
          //         onClick={() => {
          //           setconformationmodel(false);
          //           // {
          //           if (location.state.currenbt_stock !== 0) {
          //             Placebiddone();
          //           }
          //           // }
          //         }}
          //       >
          //         Continue
          //       </button>
          //       <button
          //         className="close_forgot"
          //         onClick={() => {
          //           localStorage.setItem("modalbidconfirm", "true");
          //           setconformationmodel(false);
          //           // {
          //           // if (location.state.currenbt_stock !== 0) {
          //           // Placebiddone();
          //           // }
          //         }}
          //       >
          //         Don't show again
          //       </button>
          //     </div>
          //   </div>
          // </ReactModal>
        )}
      </div>
    </>
  );
};

export default Placebid;
