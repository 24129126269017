import React from "react";
import "./ProductViewImg.css";
import { IoClose } from "react-icons/io5";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ProductViewImg2 = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product_main_wrapp">
      <div className="page_base_wrapp_2">
        <div className="pv_img_close_wrapp">
          <IoClose
            size={30}
            color="#fff"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>

        <div className="pv_imgs_wrapp">
          <>
            <Slider {...settings}>
              {/* {location && location.state.img
                ? location.state.img.map((item, index) => {
                    return (
                      <img src={item.img_video} className="pv_img_single_img" />
                    );
                  })
                : null} */}
              {location.state.img && location.state.img && (
                <TransformWrapper
                // initialScale={1}
                // initialPositionX={200}
                // initialPositionY={100}\
                >
                  <TransformComponent>
                    <img
                      src={location.state.img}
                      className="pv_img_single_img"
                    />
                  </TransformComponent>
                </TransformWrapper>
              )}
            </Slider>
          </>
        </div>
      </div>
    </div>
  );
};

export default ProductViewImg2;
