const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {String} backgroundImageSrc - Background Image url
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  backgroundImageSrc
) {
  const image = await createImage(imageSrc);
  const backgroundImage = await createImage(backgroundImageSrc);

  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  // Draw the background image on the canvas
  ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

  // Draw the cropped image on top of the background
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      // resolve(URL.createObjectURL(file));
      resolve(file);
    }, "image/jpeg");
  });
}
