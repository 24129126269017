import React, { useEffect, useState } from "react";
import "./AssetImgRequest.css";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import { get_original_file_asset } from "../../utils/Constant";
import qs from "qs";
import images from "../../constants/images";

const AssetImgRequest = () => {
  const userId = localStorage.getItem("user_id");
  const [getdata, setData] = useState([]);

  const GetImagesApi = async () => {
    const param = {
      user_id: 207,
    };

    await axios
      .post(get_original_file_asset, qs.stringify(param))
      .then((Response) => {
        console.log("response.data", Response);
        if (Response.data.status == 1) {
          setData(Response.data.result);
        } else {
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  useEffect(() => {
    GetImagesApi();
    console.log("getdata", getdata);
  }, []);

  return (
    <>
      <div className="page_main_wrapp">
        <div className="page_base_wrapp_2">
          <NavbarSec name="Image Requests" />
          <div className="asset_images_main_Wrapp">
            {/* {getdata && getdata.length > 0
              ? getdata.map((item, index) => {
                  return ( */}
            <div className="asset_img_req_single_Card">
              <div className="asset_img_req_img_back">
                <div className="asset_img_req_img_gradient-border">
                  <img src={images.media2} alt="" />
                </div>
              </div>
              <div className="asset_img_req_sec_section">
                <div className="asset_img_req_sec_section_part1">
                  <img src={images.mask_profile} alt="" />
                  <div>
                    <p>Salman khan</p>
                    <div>
                      <button>Accept</button>
                      <button
                        style={{
                          color: "var(--color-main)",
                          backgroundColor: "#fff",
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
                <div className="asset_img_req_sec_section_part2">
                  <p>Upload Image</p>
                </div>
              </div>
            </div>
            {/* );
                })
              : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetImgRequest;
