import React, { useEffect, useState } from "react";
import "../actionneed/actionneed.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import { club_product_history, product_history } from "../../utils/Constant";
import axios from "axios";
import moment from "moment";

const ClubActionNeed = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const [is_step, setis_step] = useState(0);
  const [offer_price, setoffer_price] = useState("");
  const [club_name, setClub_name] = useState("");
  const [getproductPrice, setProductPrice] = useState("");
  const [full_msg, setfull_msg] = useState("");
  const [getstatuscode, setstatuscode] = useState("");
  const [loading, setloading] = useState(false);
  const [getextra_msg, setextra_msg] = useState("");
  const gettodayDate = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    History();
    console.log("location.state", location.state);
  }, []);

  const History = async () => {
    const param = {
      club_product_id: location.state.club_product_id,
      club_id: location.state.club_id,
      share_by_user: location.state.share_by_user,
      user_id: userId,
      // user_id: userId,
    };
    console.log("parammpin", param);
    await axios
      .post(club_product_history, param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        if (data.status == 2) {
          // if (data.message != "" || data.message != null) {
          //   setoffer_price(data.offer_price);
          //   setis_step(data.is_step);
          //   setfull_msg(data.message);
          // } else {
          //   setoffer_price(data.offer_price);
          setis_step(data.is_step);
          setfull_msg(
            data.is_user && data.is_user == 1
              ? `Exciting news! You now have the opportunity to create Rarerez and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n Please note that a nominal fee of ${
                  location.state && location.state.is_globle ? "10" : "20"
                } Rarerez will be charged for the creation of Rarerez. This enables us to provide you with exceptional resources and support. Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing rarerez.club as your partner. We can't wait to see your asset thrive in our community.`
              : `Exciting news! You now have the opportunity to create Rarerez and tap into our vibrant community. We believe in the value of your creation and are committed to helping you succeed.\n Get ready to showcase your asset, build your brand, and unlock new revenue streams. Thank you for choosing rarerez.club as your partner. We can't wait to see your asset thrive in our community.`
          );
          setProductPrice(Response.data.result.product_price);
          setClub_name(Response.data.result.club_name);

          //   // alert(Response.data.message);
          // }
        } else if (data.status == 3) {
          setfull_msg(data.message);
          setextra_msg(data.extra_message);
          setstatuscode(3);
        }
        setloading(false);
        // if (Response.data.status == 1) {
        //   setis_step(data.is_step);
        //   setoffer_price(data.offer_price)
        // } else {
        //   setErrorMessage(Response.data.message);
        //   setShowModal(true);
        // }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="" />
        <div className="actionneed_main">
          <b>Dear {location.state.owner_name}</b>
          <p
            style={{
              color: "#fff",
              fontFamily: "var(--font-400)",
              fontSize: "12px",
            }}
          >
            ({club_name})
          </p>
          <br />
          {/* <p className="actionneed_p">
            {full_msg}
            <br />
            {getextra_msg !== "" ? getextra_msg : null}
          </p> */}

          {location.state.commitment_date == "" ? (
            <p className="actionneed_p">
              {full_msg}
              <br />
              {getextra_msg !== "" ? getextra_msg : null}
            </p>
          ) : location.state.commitment_date <= gettodayDate ? (
            <p className="actionneed_p">
              Thank you for choosing rarerez.club as your partner. We can't wait
              to see your rarerez thrive in our community.
            </p>
          ) : (
            <p className="actionneed_p">
              {full_msg}
              <br />
              {getextra_msg !== "" ? getextra_msg : null}
            </p>
          )}
          <br />
          <img className="actionneed_img" src={images.new_logo} alt="" />
          {getstatuscode == 3 ? null : (
            <>
              {is_step == 0 ? (
                <Link
                  to={"/ClubBondissue"}
                  state={{
                    is_globle: location.state.is_globle,
                    club_product_id: location.state.club_product_id,
                    remaining_bond: location.state.remaining_bond,
                    // product_price: getproductPrice,
                    product_image: location.state.product_image,
                    product_name: location.state.product_name,
                    is_owner: location.state.is_owner,
                    club_user_id: location.state.club_user_id,
                    club_id: location.state.club_id,
                    is_club_assign: location.state.is_club_assign,
                    share_by_user: location.state.share_by_user,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  Create Rarerez
                </Link>
              ) : is_step == 1 ? (
                <>
                  <Link
                    to={"/ClubCreatenfbs"}
                    state={{
                      club_product_id: location.state.club_product_id,
                      product_image: location.state.product_image,
                      product_name: location.state.product_name,
                      club_user_id: location.state.club_user_id,
                      is_club_assign: location.state.is_club_assign,
                      user_id: location.state.club_user_id,
                      club_id: location.state.club_id,
                      share_by_user: location.state.share_by_user,
                      commitment_date_new: location.state.commitment_date,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    Create Rarerez
                  </Link>
                </>
              ) : (
                <Link
                  to={"/ClubCreatenfbs"}
                  state={{
                    club_product_id: location.state.club_product_id,
                    product_image: location.state.product_image,
                    product_name: location.state.product_name,
                    club_user_id: location.state.club_user_id,
                    is_club_assign: location.state.is_club_assign,
                    user_id: location.state.club_user_id,
                    club_id: location.state.club_id,
                    share_by_user: location.state.share_by_user,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  Create Rarerez
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubActionNeed;
