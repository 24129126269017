import React, { useState, useEffect } from "react";
//import images from "../../constants/images";
// import Modal from "react-modal";
import "./ClubRequest.css";
//import BackButton from "../../components/BackButton";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa";

const ClubRequest = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const url = "https://www.ravi.host/api/club_owner_send_approval_for_join";
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  console.log("nitin13", data);

  const [statuss, setstatus] = useState(null);
  const fetchuserData = async () => {
    // FormData object
    const formData = new FormData();

    formData.append("club_id", location.state.club_id);
    formData.append("user_id", userId);

    // Send the POST request with the FormData
    return await axios
      .post(url, formData)
      .then((res) => {
        console.log("Success", res.data);
        setstatus(res.data.status);
        setData(res.data.data);
        setSearchdata(res.data.data);
      })
      .catch((error) => {
        // Handle errors
      });
  };

  useEffect(() => {
    fetchuserData();
  }, []);

  // status accepts
  const statusurl = "https://www.ravi.host/api/accept_reject_club_join_member";
  const [acceptstatus, setAcceptstatus] = useState([]);

  console.log("nitin38", acceptstatus);

  const fetchstatusData = async (status, id) => {
    // FormData object
    const formData = new FormData();

    formData.append("status", status);
    formData.append("club_join_id", id);

    // Send the POST request with the FormData
    return await axios
      .post(statusurl, formData)
      .then((res) => {
        setAcceptstatus(res.data);
        fetchuserData();
      })
      .catch((error) => {
        // Handle errors
      });
  };
  // useEffect(() => {
  //   fetchstatusData();
  // }, []);

  // status rejects

  const [showButtons, setShowButtons] = useState(true);
  const [status, setStatus] = useState("");
  const [getsearchData, setSearchdata] = useState([]);

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.firstname
        ? item.firstname.toUpperCase()
        : "".toLowerCase();
      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setData(newData);
    console.log("newData", newData);
  };

  const handleBidAction = (action) => {
    setShowButtons(false);
    setStatus(action === "accept" ? "Accepted" : "Rejected");
  };
  return (
    <div className="page_base_wrapp_2">
      {/* start header */}
      {/* <NavbarSec name="" /> */}
      <div className="legacy_dashbord_nav_sec" style={{ margin: "20px auto" }}>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaAngleLeft color="var(--color-white)" size={20} />
        </button>
        <p>Club Requests</p>
        <div></div>
      </div>

      {statuss === 0 ? (
        <p
          style={{
            fontFamily: "var(--main-400)",
            textAlign: "center",
            marginTop: "40%",
          }}
        >
          No Requests Found...
        </p>
      ) : (
        <>
          {/* <div
              className="req_search"
              style={{ width: "100%", margin: "40px auto 30px auto" }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => searchFilter(e.target.value)}
                // value={search}
              />
              <img className="req_search-img" src={images.search} alt="" />
            </div> */}
          <div style={{}} className="bid13_search">
            <div
              className="bids13_user_bid_main"
              style={{ paddingTop: "10px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="bids13_user_bid_search"
                // value={searchTerm}
                onChange={(e) => searchFilter(e.target.value)}
              />
              {/* <img src={images.search} alt="" className="search_ic" /> */}
            </div>
          </div>
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map(({ profile, firstname, email, club_join_id }) => {
              return (
                <div className="alllist">
                  <div className="alllist_part1">
                    <img
                      src={profile}
                      className="alllist_part1_img"
                      alt=""
                      style={{}}
                    />
                  </div>

                  <div className="alllist_part2">
                    <p>{firstname}</p>
                    <p>{email}</p>
                  </div>

                  <div className="alllist_part3">
                    {showButtons && (
                      <div className="bidsbtn_all">
                        <div className="bidsbtn_all1">
                          <button
                            className="button_all1"
                            onClick={() => {
                              // handleBidAction("accept")
                              fetchstatusData(1, club_join_id);
                            }}
                          >
                            <p className="button_img" alt=""></p>
                          </button>
                          <h1 className="button_allp_new">Accept</h1>
                        </div>
                        <div className="bidsbtn_all2">
                          <button
                            className="button_all2_new"
                            onClick={() => {
                              // handleBidAction("reject")
                              fetchstatusData(0, club_join_id);
                            }}
                          >
                            <p className="button_img2_new" alt="">
                              x
                            </p>
                          </button>
                          <h1 className="button_allp_new_r">Reject</h1>
                        </div>
                      </div>
                    )}
                    {status && <div className="msg_bids">{status}</div>}
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default ClubRequest;
