import React from "react";
import "./NewMobileOtpSuccess.css";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";

const NewinternationalInviteSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="mobile_success_main_wrapp">
          <p className="success_heading">Success!!!</p>
          <p className="success_des_text">
            Your invitation have been sent successfully to a new international
            member
          </p>
          <img src={images.new_success_img} className="success_img" alt="" />

          {/* btn */}
          <button
            onClick={() => {
              navigate("/NewInternationalInviteForm");
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Send More</p>
          </button>
          {/* btn */}

          {/* btn */}
          <button
            onClick={() => {
              navigate("/homescreen2");
            }}
            className="new_black_btn_wrapp mx-1"
          >
            <p>Exit</p>
          </button>
          {/* btn */}
        </div>
      </div>
    </div>
  );
};

export default NewinternationalInviteSuccess;
