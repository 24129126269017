import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const CirtificateViewImg = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="product_main_wrapp">
      <div className="page_base_wrapp_2">
        <div className="pv_img_close_wrapp">
          <IoClose
            size={30}
            color="#fff"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>

        <div className="pv_imgs_wrapp">
          <>
            <Slider {...settings}>
              <div>
                {/* <InnerImageZoom
                  src={location.state.img}
                  zoomSrc={location.state.img}
                  zoomScale={1.5}
                  hasSpacer={true}
                  className="pv_img_single_img pv_img_single_img_22"
                  style={{ objectFit: "contain" }}
                /> */}

                <TransformWrapper>
                  <TransformComponent>
                    <img
                      src={location.state.img}
                      className="pv_img_single_img pv_img_single_img_22"
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
                {/* <InnerImageZoom
                  src={location.state.img}
                  zoomSrc={location.state.img}
                  className="pv_img_single_img pv_img_single_img_22"
                  // style={{ objectFit: "contain" }}
                /> */}
              </div>
            </Slider>
          </>
        </div>
      </div>
    </div>
  );
};

export default CirtificateViewImg;
