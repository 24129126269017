import React from "react";
import "./NewTandC.css";
import { FaAngleLeft } from "react-icons/fa6";

const NewTandC = ({ title, msg, accept, setIsOpen }) => {
  return (
    <div className="newtnc_main_wrapp">
      <div className="new_container">
        <div className="tandc_backbtn_wrapp">
          <button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
        </div>
        <h2 className="tandc_heading">{title}</h2>

        <div className="tandc_deswrapp">
          <p>
            These Terms and Conditions ("Terms") govern your participation in
            the beta release of the rarerez.club App ("App"). By accessing or
            using the App, you agree to be bound by these Terms. If you do not
            agree with any part of these Terms, please refrain from using the
            App.
          </p>

          <p>
            Beta Testing: The App is currently in the beta testing phase, and
            you understand and acknowledge that it may contain bugs, errors, and
            other issues. Your participation in the beta release is voluntary
            and at your own risk.
          </p>

          <p>
            Feedback and Suggestions: As a beta tester, you are encouraged to
            provide feedback, suggestions, and bug reports regarding the App.
            You agree that any feedback or suggestions you provide may be used
            by rarerez.club for the improvement and development of the App
            without any obligation to compensate you.
          </p>

          <p>
            Confidentiality: You agree to keep all information related to the
            App, including its features, designs, and functionality,
            confidential. You will not disclose or share this information with
            any third party without prior written consent from rarerez.club.
          </p>

          <p>
            Limited License: rarerez.club grants you a limited, non-exclusive,
            non-transferable, revocable license to use the App solely for the
            purpose of beta testing and providing feedback during the beta
            release period.
          </p>

          <p>
            Disclaimer of Warranty: The App is provided on an "as is" basis, and
            rarerez.club makes no warranties or representations regarding its
            functionality, performance, or suitability for any purpose.
            rarerez.club shall not be liable for any damages arising out of or
            in connection with the use of the App.
          </p>

          <p>
            Termination: rarerez.club reserves the right to terminate your
            access to the App at any time, for any reason, without prior notice.
          </p>

          <p>
            Modification of Terms: rarerez.club may modify these Terms at any
            time without prior notice. It is your responsibility to review the
            most current version of the Terms periodically.
          </p>

          <p>
            By accessing and using the App, you acknowledge that you have read,
            understood, and agreed to these Terms and Conditions. If you do not
            agree to these Terms, please refrain from using the App.
          </p>
        </div>

        {/* btn */}
        <button
          onClick={accept}
          className="new_btn_wrapp mx-1"
          style={{ marginBottom: "2rem", display: "inline-block" }}
        >
          <p>Accept</p>
        </button>
        {/* btn */}
      </div>
    </div>
  );
};

export default NewTandC;
