import React, { useEffect, useState } from "react";
import NavbarSec from "../common/NavbarSec";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import images from "../constants/images";

const AllFollowing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [getdata, setData] = useState([]);
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    GetAllFollowing();
  }, []);

  const GetAllFollowing = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.target_user_id,
    };
    await axios
      .post("https://www.ravi.host/api/get_user_followers", qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log("user_follow res", Response.data);
        console.log("====================================");
        if (Response.data.status == 1) {
          setData(Response.data.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_follow errr", err);
      });
  };

  //   delet member api start

  const deletemember = async (club_join_id) => {
    const param = {
      club_join_id: club_join_id,
    };
    console.log("param", param);
    setIsLoading(true);
    console.log("====================================", param);
    await axios
      .post("https://www.ravi.host/api/delete_club_member", qs.stringify(param))
      .then((Response) => {
        console.log("====================================");
        setIsLoading(false);
        if (Response.data.status == 1) {
          navigate("/UserProfile");
        } else {
          console.log("Response.data", Response.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("delete_club_member err", err);
      });
  };

  //   delet member api end

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Followers" />

        <div style={{ marginTop: "20px" }}>
          {getdata && getdata.length > 0
            ? getdata.map((item, index) => {
                return (
                  <div className="club_manager_gradinat_wrapp">
                    <div className="main_manager_club_set" key={index}>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "40px",
                        }}
                        src={item.profile}
                        alt=""
                      />
                      <b
                        style={{
                          fontSize: "14px",
                          fontFamily: "var(--font-300)",
                          color: "#fff",
                        }}
                      >
                        {item.firstname}
                      </b>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default AllFollowing;
