import React, { useEffect } from "react";
import "./collaboratenfb/collaboratenfb.css";

import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import Modal from "react-modal";
import NavbarSec from "../common/NavbarSec";

Modal.setAppElement("#root");

const ClubCollaboratenfb = () => {
  const userId = localStorage.getItem("user_id");

  const location = useLocation();
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState({});
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState([]);
  const [getmemberData, setMemberData] = useState([]);
  const [getsearchData, setSearchData] = useState([]);
  const [getisClub, setIsClub] = useState("");
  const [getclubId, setClubId] = useState(0);
  const [getisGlobal, setIsGlobal] = useState(0);
  const [getclubName, setClubName] = useState("");

  // modal start
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleGlobalChange = (event) => {
    setIsGlobal(event.target.value);
  };

  // modal end

  useEffect(() => {
    getProductData();
  }, []);

  // --apis start

  const getProductData = () => {
    setIsLoading(true);

    const param = {
      club_product_id: location.state.club_product_id,
    };

    axios
      .post(
        "https://www.ravi.host/api/club_preview_product",
        qs.stringify(param)
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setproduct_name(response.data.result.product_name);
          setdescription(response.data.result.description);
          setcreated_at(response.data.result.created_at);

          const demo = [];
          for (
            let index = 0;
            index < response.data.result.product_img_video.length;
            index++
          ) {
            demo.push(
              response.data.result.product_img_video[index].product_icon
            );
          }

          setProductData({ product_img_video: demo });
        } else {
          // alert(response.data.message);
          console.log("preview_product err ->", response.data.message);
        }
      });
  };

  const getAllUser = async () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    const param = {
      user_id: userId,
    };

    await axios
      .post("https://www.ravi.host/api/get_all_club", qs.stringify(param))
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          setMemberData(response.data.result);
          setSearchData(response.data.result);
        } else {
          // alert(response.data.message);
          console.log(
            "get_user_club_member_detail err ->",
            response.data.message
          );
        }
      });
  };

  const getAllClub = () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_user_club_member_detail",
        qs.stringify(param)
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          setData(response.data.result);
        } else {
          // alert(response.data.message);
          console.log(
            "get_user_club_member_detail err ->",
            response.data.message
          );
        }
      });
  };

  const getAllPartner = () => {
    setSelectedName("");
    setSelectedId("");
    setIsLoading(true);

    axios.get("https://www.ravi.host/api/get_all_partner").then((response) => {
      setIsLoading(false);
      if (response.data.status === 1) {
        setData(response.data.result);
      } else {
        // alert(response.data.message);
        console.log("get_all_partner err ->", response.data.message);
      }
    });
  };

  useEffect(() => {
    getAllUser();
    console.log("getmemberData", getmemberData);
  }, [getisClub]);

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.club_name
        ? item.club_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setMemberData(newData);
    console.log("newData", newData);
  };

  const handleSubmit = () => {
    if (getisClub == "") {
      alert("Please Select collaborator or none and continue");
    } else {
      const param = {
        club_product_id: location.state.club_product_id,
        colla_partner_club_none_id: getclubId,
        colla_partner_club_none:
          getisClub == "Partner"
            ? 1
            : getisClub == "Collaborator"
            ? 2
            : getisClub == "club"
            ? 3
            : 4,
        user_id: userId,
        // is_global: getisClub == "club" ? getisGlobal : "None",
      };

      axios
        .post(
          "https://www.ravi.host/api/club_draft_bond_api_part_2",
          qs.stringify(param)
        )
        .then((Response) => {
          console.log(
            "club_draft_bond_api_part_2 -->Response.data",
            Response.data
          );
          if (Response.data.status === 1) {
            // props.navigation.replace("NewAttech", {
            //   product_id: props.route.params.product_id,
            //   user_id: props.user_id,
            // });
            Navigate("/ClubCreatenfbs", {
              state: {
                club_product_id: location.state.club_product_id,
                user_id: userId,
                club_user_id: location.state.club_user_id,
                is_club_assign: location.state.is_club_assign,
              },
            });
          } else {
            alert(Response.data.message);
          }
        });
    }
  };

  // --apis end

  const sliderSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per interaction
  };
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name="Collaborate Rarerez" />
        <Slider {...sliderSettings}>
          {" "}
          {/* Use the Slider component with your settings */}
          {productData.product_img_video &&
          productData.product_img_video.length > 0
            ? productData.product_img_video.slice(0, 1).map((item, index) => {
                console.log("item", item);
                console.log("productData", productData);
                return (
                  <div>
                    <img
                      src={item}
                      className="Collaboratenfb_img"
                      alt=""
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    />
                  </div>
                );
              })
            : null}
          {/* Add additional slides here */}
        </Slider>
        {/* <p className="collaborate_titel">{product_name}</p> */}
        <p
          style={{
            fontFamily: "var(--font-600)",
            fontSize: "18px",
            color: "var(--color-white)",
          }}
        >
          Asset Name
        </p>
        <p
          style={{
            fontFamily: "var(--font-400)",
            fontSize: "16px",
            color: "var(--color-white)",
            opacity: "0.6",
          }}
        >
          {product_name}
        </p>
        <br />
        <p
          style={{
            fontFamily: "var(--font-600)",
            fontSize: "18px",
            color: "var(--color-white)",
          }}
        >
          do You have any Collaboratenfb / Partners ?{" "}
        </p>
        <br />
        <div>
          <select
            name=""
            id=""
            onChange={(e) => {
              setIsClub(e.target.value);
            }}
          >
            <option className="collaborate_option" value="" selected disabled>
              Select
            </option>
            <option className="collaborate_option" value="none">
              None
            </option>
            <option className="collaborate_option" value="club">
              Club
            </option>
            {/* <option className="collaborate_option" value="">
              none
            </option> */}
          </select>
        </div>
        {/* <br /> */}
        {/* SELECTED CLUB START */}
        {getisClub == "club" && (
          <div>
            <select
              name=""
              id=""
              // onChange={(e) => {
              //   setIsClub(e.target.value);
              // }}
              onClick={openModal}
            >
              <option className="collaborate_option" value={0} selected>
                {getclubName != "" ? getclubName : "Search"}
              </option>
            </select>
          </div>
        )}
        {/* SELECTED CLUB END */}

        {/* global exclusive start */}

        {/* {getisClub == "club" && (
          <div className="cbond_form_radio_wrapp" style={{ marginTop: "10px" }}>
            <div id="custom_radio" style={{ justifyContent: "flex-start" }}>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="checkbox"
                  value={0}
                  checked={getisGlobal == 0}
                  onChange={handleGlobalChange}
                />
                <span class="checkmark"></span>
                Exclusive
              </label>
              <label class="custom_radio">
                <input
                  type="radio"
                  name="checkbox"
                  value={1}
                  checked={getisGlobal == 1}
                  onChange={handleGlobalChange}
                />
                <span class="checkmark"></span>
                Super Exclusive
              </label>
            </div>
          </div>
        )} */}

        {/* global exclusive end */}

        <br />

        <center>
          <button className="collaborate_btn" onClick={handleSubmit}>
            Save and Promote
          </button>
        </center>
      </div>

      {/* modal start */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Search Modal"
        overlayClassName="custom_overlay"
        className="serch_modal"
      >
        <div className="collbrator_inner_modal">
          <input
            type="text"
            placeholder="Search"
            // value={getclubName}
            onChange={(e) => searchFilter(e.target.value)}
            className="search_box_in_modal"
          />

          {/* Add your search results or content here */}
          <ul>
            {getmemberData && getmemberData.length > 0
              ? getmemberData.map((result) => (
                  <button
                    className="list_collab_btn"
                    key={result.club_id}
                    onClick={() => {
                      setClubName(result.club_name);
                      setClubId(result.club_id);
                      // setIsModalOpen(false);
                      closeModal();
                      console.log("getclubName", getclubName);
                    }}
                  >
                    {result.club_name}
                  </button>
                ))
              : null}
          </ul>
        </div>
        <button className="collbrator_inner_btn" onClick={closeModal}>
          Close
        </button>
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default ClubCollaboratenfb;
