import React, { useEffect, useState } from "react";
import "./changepassword.css";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { reset_password } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import NewLoader from "../../common/newloder/NewLoader";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa6";

const Changepassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [getsuccess, setSuccess] = useState(0);
  const [isInputValid, setInputValid] = useState(false);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputFocused_old, setIsInputFocused_old] = useState(false);
  const [isInputFocused_confirm, setIsInputFocused_confirm] = useState(false);

  const [isSpecialCharacterMissing, setIsSpecialCharacterMissing] =
    useState(false);
  const [isMinLengthInvalid, setIsMinLengthInvalid] = useState(false);
  const [isNumericCharacterMissing, setIsNumericCharacterMissing] =
    useState(false);
  const [isUppercaseMissing, setIsUppercaseMissing] = useState(false);
  const [isLowercaseMissing, setIsLowercaseMissing] = useState(false);
  const [isPasswordDiffrent, setIsPasswordDiffrent] = useState(false);

  const [isInputValid_old, setIsInputValid_old] = useState(false);
  const [isSpecialCharacterMissing_old, setIsSpecialCharacterMissing_old] =
    useState(false);
  const [isMinLengthInvalid_old, setIsMinLengthInvalid_old] = useState(false);
  const [isNumericCharacterMissing_old, setIsNumericCharacterMissing_old] =
    useState(false);
  const [isUppercaseMissing_old, setIsUppercaseMissing_old] = useState(false);
  const [isLowercaseMissing_old, setIsLowercaseMissing_old] = useState(false);

  const [isInputValid_confirm, setIsInputValid_confirm] = useState(false);
  const [
    isSpecialCharacterMissing_confirm,
    setIsSpecialCharacterMissing_confirm,
  ] = useState(false);
  const [isMinLengthInvalid_confirm, setIsMinLengthInvalid_confirm] =
    useState(false);
  const [
    isNumericCharacterMissing_confirm,
    setIsNumericCharacterMissing_confirm,
  ] = useState(false);
  const [isUppercaseMissing_confirm, setIsUppercaseMissing_confirm] =
    useState(false);
  const [isLowercaseMissing_confirm, setIsLowercaseMissing_confirm] =
    useState(false);

  useEffect(() => {
    validateInput();
  }, [newPassword, isInputFocused]);

  const validateInput_confirm = () => {
    const isSpecialCharacterMissing_confirm = !/[!@#$%^&]/.test(
      confirmPassword
    );
    const isMinLengthInvalid_confirm = confirmPassword.length < 8;
    const isNumericCharacterMissing_confirm = !/\d/.test(confirmPassword);
    const isUppercaseMissing_confirm = !/[A-Z]/.test(confirmPassword);
    const isLowercaseMissing_confirm = !/[a-z]/.test(confirmPassword);

    const isInputValid_confirm =
      isInputFocused_confirm ||
      (!isSpecialCharacterMissing_confirm &&
        !isMinLengthInvalid_confirm &&
        !isNumericCharacterMissing_confirm &&
        !isUppercaseMissing_confirm &&
        !isLowercaseMissing_confirm);

    setIsInputValid_confirm(isInputValid_confirm);
    setIsSpecialCharacterMissing_confirm(isSpecialCharacterMissing_confirm);
    setIsMinLengthInvalid_confirm(isMinLengthInvalid_confirm);
    setIsNumericCharacterMissing_confirm(isNumericCharacterMissing_confirm);
    setIsUppercaseMissing_confirm(isUppercaseMissing_confirm);
    setIsLowercaseMissing_confirm(isLowercaseMissing_confirm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any field is empty
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill in all fields.", { theme: "colored" });

      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.", {
        theme: "colored",
      });

      return;
    }

    // Check if the new password meets the required criteria (e.g., 8 characters, at least one uppercase, one lowercase, one number, and one special character)
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "New password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (@ $ ! % * ? &).",
        { theme: "colored" }
      );

      return;
    }

    // Password validation passed, you can now proceed with your API call for changing the password
    console.log(
      "API call to change password:",
      oldPassword,
      newPassword,
      confirmPassword
    );
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  // new password start

  const handlePasswordChange = (event) => {
    // if (oldPassword == event.target.value) {
    //   toast.error("New password must be diffrent from old password", {
    //     theme: "colored",
    //   });
    // }
    if (event.length == 0) {
      setInputValid(false);
    } else {
      validateInput();
    }
    // setInputValid(false);
    const newPassword = event.target.value;
    setNewPassword(newPassword);
    // const passwordPattern =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // setPasswordValid(passwordPattern.test(newPassword));
  };

  // validations

  const validateInput = () => {
    setInputValid(true);
    const isSpecialCharacterMissing = !/[!@#$%^&]/.test(newPassword);
    const isMinLengthInvalid = newPassword.length < 8;
    const isNumericCharacterMissing = !/\d/.test(newPassword);
    const isUppercaseMissing = !/[A-Z]/.test(newPassword);
    const isLowercaseMissing = !/[a-z]/.test(newPassword);

    const isInputValid =
      isInputFocused ||
      (!isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing &&
        !isLowercaseMissing);

    const isInputValid1 =
      !isSpecialCharacterMissing &&
      !isMinLengthInvalid &&
      !isNumericCharacterMissing &&
      !isUppercaseMissing &&
      !isLowercaseMissing;
    setvalid(isInputValid1);

    setInputValid(isInputValid);
    setIsSpecialCharacterMissing(isSpecialCharacterMissing);
    setIsMinLengthInvalid(isMinLengthInvalid);
    setIsNumericCharacterMissing(isNumericCharacterMissing);
    setIsUppercaseMissing(isUppercaseMissing);
    setIsLowercaseMissing(isLowercaseMissing);
  };

  // new password end

  // confirm password start

  const handleConfirmPasswordChange = (event) => {
    if (event.length == 0) {
      setIsInputValid_confirm(false);
    } else {
      validateInput_confirm();
    }
    // setInputValid(false);
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
    // const passwordPattern =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // setPasswordValid(passwordPattern.test(newPassword));
  };

  // confirm password end

  // show password logics start
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  // show password logics end

  const userId = localStorage.getItem("user_id");

  const Resetpaassword = () => {
    // if (!oldPassword) {
    //   toast.error("Enter Old Password", { theme: "colored" });
    //   return;
    // } else
    if (!newPassword) {
      toast.error("Enter New Password", { theme: "colored" });
    } else if (!confirmPassword) {
      toast.error("Enter Confirm Password", { theme: "colored" });
    } else if (newPassword !== confirmPassword) {
      toast.error("New Passsword and Confirm Password not match", {
        theme: "colored",
      });
    } else if (oldPassword == newPassword) {
      toast.error("New password must be diffrent from old password", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("password", newPassword);
      formData.append("password2", confirmPassword);
      // formData.append("oldpassword", oldPassword);

      console.log("====================================");
      console.log("Password change Res -->", formData);

      axios
        .post("https://www.ravi.host/api/reset_password", formData)
        .then((Response) => {
          console.log("====================================", Response.data);
          setIsLoading(false);
          if (Response.data.status == 1) {
            toast.success(Response.data.message, { theme: "colored" });
            setNewPassword("");
            setConfirmPassword("");
            // localStorage.clear();
            // sessionStorage.clear();
            // window.location.reload();
            //this.props.navigation.repo('Dashboard');
            // setSuccess(1);
          } else {
            toast.error(Response.data.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("Error", err);
        });
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  //
  const [valid1, setvalid1] = useState(false);
  const [valid, setvalid] = useState(false);

  const validateInputConfirmPassword = (e) => {
    const ee = e;
    const isSpecialCharacterMissing = !/[!@#$%^&]/.test(ee);
    const isMinLengthInvalid = ee.length < 8;
    const isNumericCharacterMissing = !/\d/.test(ee);
    const isUppercaseMissing = !/[A-Z]/.test(ee);
    const isLowercaseMissing = !/[a-z]/.test(ee);

    const isInputValid =
      isInputFocusedConfirmPassword ||
      (!isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing &&
        !isLowercaseMissing);
    const isInputValid1 =
      !isSpecialCharacterMissing &&
      !isMinLengthInvalid &&
      !isNumericCharacterMissing &&
      !isUppercaseMissing &&
      !isLowercaseMissing;
    setvalid1(isInputValid1);

    setIsInputValidConfirmPassword(isInputValid);
    setIsSpecialCharacterMissingConfirmPassword(isSpecialCharacterMissing);
    setIsMinLengthInvalidConfirmPassword(isMinLengthInvalid);
    setIsNumericCharacterMissingConfirmPassword(isNumericCharacterMissing);
    setIsUppercaseMissingConfirmPassword(isUppercaseMissing);
    setIsLowercaseMissingConfirmPassword(isLowercaseMissing);
  };
  const [isInputFocusedConfirmPassword, setIsInputFocusedConfirmPassword] =
    useState(false);
  const [isInputValidConfirmPassword, setIsInputValidConfirmPassword] =
    useState(false);
  const [
    isSpecialCharacterMissingConfirmPassword,
    setIsSpecialCharacterMissingConfirmPassword,
  ] = useState(false);
  const [
    isMinLengthInvalidConfirmPassword,
    setIsMinLengthInvalidConfirmPassword,
  ] = useState(false);
  const [
    isNumericCharacterMissingConfirmPassword,
    setIsNumericCharacterMissingConfirmPassword,
  ] = useState(false);
  const [
    isUppercaseMissingConfirmPassword,
    setIsUppercaseMissingConfirmPassword,
  ] = useState(false);
  const [
    isLowercaseMissingConfirmPassword,
    setIsLowercaseMissingConfirmPassword,
  ] = useState(false);
  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />

      {/* <NavbarSec name="Change Password" /> */}

      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="back_arrow_wrapp">
            <button
              onClick={() => {
                navigate("/NewUserMenu");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div>
          <div className="set_otp_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "18vh", minHeight: "150px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4 style={{ margin: "0px" }}>Change Password</h4>
              <p>Enhance Your Shield: Change Password Now!</p>
            </div>

            {/* ----------------------- */}

            <div className="new_set_password_inputs_wrapp">
              {/* single input box start */}
              <div className="new_set_password_single_input_wrapp">
                {/* <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_lock_logo} alt="" />
                  <p>Old Password </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Old Password"
                    required
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <img
                    // src={images.password_seq}
                    src={
                      showPassword1 ? images.new_eye_close : images.new_eye_icon
                    }
                    // className="change_pass_show_new"
                    alt=""
                    onClick={toggleShowPassword1}
                  />
                </div> */}
              </div>
              {/* single input box end */}

              {/* single input box start */}
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_lock_logo} alt="" />
                  <p>New Password </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Type here"
                    value={newPassword}
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                    onFocus={() => {
                      setIsInputFocused(true);
                    }}
                  />
                  <img
                    onClick={toggleShowPassword2}
                    src={
                      showPassword2 ? images.new_eye_close : images.new_eye_icon
                    }
                    alt=""
                  />
                </div>
              </div>
              {valid == false ? (
                <>
                  {isInputValid && (
                    <div className="password_validation_main_wrapp">
                      <p
                        style={{
                          color: isMinLengthInvalid
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must be at least 8 characters long
                      </p>
                      <p
                        style={{
                          color: isSpecialCharacterMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one special character
                      </p>
                      <p
                        style={{
                          color: isNumericCharacterMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one numeric character
                      </p>
                      <p
                        style={{
                          color: isUppercaseMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one uppercase character
                      </p>
                      <p
                        style={{
                          color: isLowercaseMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one lowercase character
                      </p>
                    </div>
                  )}
                </>
              ) : null}
              {/* single input box end */}

              {/* single input box start */}
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_lock_logo} alt="" />
                  <p>Confirm Password </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type={showPassword3 ? "text" : "password"}
                    placeholder="Type here"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      validateInputConfirmPassword(e.target.value);
                    }}
                    onFocus={() => setIsInputFocusedConfirmPassword(true)}
                  />
                  <img
                    src={
                      showPassword3 ? images.new_eye_close : images.new_eye_icon
                    }
                    alt=""
                    onClick={toggleShowPassword3}
                  />
                </div>
              </div>
              {/* single input box end */}

              {/* {valid1 == false ? (
                <>
                  {isInputValidConfirmPassword == true && (
                    <div style={{ marginLeft: 10 }}>
                      <p
                        style={{
                          color: isMinLengthInvalidConfirmPassword
                            ? "gray"
                            : "green",
                          fontSize: 10,
                          marginBottom: 3,
                        }}
                      >
                        Password must be at least 8 characters long
                      </p>
                      <p
                        style={{
                          color: isSpecialCharacterMissingConfirmPassword
                            ? "gray"
                            : "green",
                          fontSize: 10,
                          marginBottom: 3,
                        }}
                      >
                        Password must include at least one special character
                      </p>
                      <p
                        style={{
                          color: isNumericCharacterMissingConfirmPassword
                            ? "gray"
                            : "green",
                          fontSize: 10,
                          marginBottom: 3,
                        }}
                      >
                        Password must include at least one numeric character
                      </p>

                      <p
                        style={{
                          color: isUppercaseMissingConfirmPassword
                            ? "gray"
                            : "green",
                          fontSize: 10,
                          marginBottom: 3,
                        }}
                      >
                        Password must include at least one uppercase character
                      </p>
                      <p
                        style={{
                          color: isLowercaseMissingConfirmPassword
                            ? "gray"
                            : "green",
                          fontSize: 10,
                          marginBottom: 28,
                        }}
                      >
                        Password must include at least one lowercase character
                      </p>
                    </div>
                  )}
                </>
              ) : null} */}

              {/* btn */}
              <button
                disabled={
                  newPassword == "" || confirmPassword == "" ? true : false
                }
                onClick={Resetpaassword}
                className="new_btn_wrapp mt-3"
              >
                <p>Submit</p>
              </button>
              {/* btn */}
            </div>

            {/* ----------------------- */}
          </div>
        </div>
      )}
      {showModal && (
        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Changepassword;
