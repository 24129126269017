import React, { useEffect, useRef, useState } from "react";
import "./createnfbs.css";
import images from "../../constants/images";
import NavbarSec from "../../common/NavbarSec";
import Cardnfb from "./Cardnfb";
import Cardnfbsecond from "./Cardnfbsecond";
import Cardnfbthree from "./Cardnfbthree";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  preview_product,
  release_bond,
  update_bond_img,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import Cardnfbfour from "./Cardnfbfour";
import Cardnfbfive from "./Cardnfbfive";
import Cardnfbsix from "./Cardnfbsix";
import Cardnfbseven from "./Cardnfbseven";
import Cardnfbeight from "./Cardnfbeight";
import AlertModal from "../../components/AlertModal";
import Cardnfb13 from "./Cardnfb13";
import Cropper from "react-easy-crop";
import getCroppedImg from "./createImage";
import Card14 from "./Card14";
import Card15 from "./Card15";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import NewNfbFrame1 from "../../frames/nfbframes/NewNfbFrame1";
import NewLoader from "../../common/newloder/NewLoader";
import NewNfbFrame2 from "../../frames/nfbframes/NewNfbFrame2";
import NewNfbFrame3 from "../../frames/nfbframes/NewNfbFrame3";
import NewNfbFrame4 from "../../frames/nfbframes/NewNfbFrame4";
import NewNfbFrame5 from "../../frames/nfbframes/NewNfbFrame5";
import NewNfbFrame6 from "../../frames/nfbframes/NewNfbFrame6";
import NewNfbFrame7 from "../../frames/nfbframes/NewNfbFrame7";
import NewNfbFrame9 from "../../frames/nfbframes/NewNfbFrame9";
import NewNfbFrame8 from "../../frames/nfbframes/NewNfbFrame8";
import NewNfbFrame10 from "../../frames/nfbframes/NewNfbFrame10";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { NewEditNfbFrame1 } from "../../frames/neweditnfbframes";
import { IoClose } from "react-icons/io5";
import NewEditNfbFrame10 from "../../frames/neweditnfbframes/NewEditNfbFrame10";
import NewEditNfbFrame9 from "../../frames/neweditnfbframes/NewEditNfbFrame9";
import NewEditNfbFrame8 from "../../frames/neweditnfbframes/NewEditNfbFrame8";
import NewEditNfbFrame7 from "../../frames/neweditnfbframes/NewEditNfbFrame7";
import NewEditNfbFrame6 from "../../frames/neweditnfbframes/NewEditNfbFrame6";
import NewEditNfbFrame5 from "../../frames/neweditnfbframes/NewEditNfbFrame5";
import NewEditNfbFrame4 from "../../frames/neweditnfbframes/NewEditNfbFrame4";
import NewEditNfbFrame3 from "../../frames/neweditnfbframes/NewEditNfbFrame3";
import NewEditNfbFrame2 from "../../frames/neweditnfbframes/NewEditNfbFrame2";

const CreatenfbsNew2 = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();

  const ttt = localStorage.getItem("theme_index");
  const fffff = localStorage.getItem("frames_index");

  // const [selectedFrame, setSelectedFrame] = useState("frames1");
  const [selectedFrame, setSelectedFrame] = useState(
    fffff == "" ||
      fffff == null ||
      fffff == "null" ||
      fffff == "undefined" ||
      fffff == undefined
      ? "frames12"
      : fffff
  );
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [getok, setok] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [ismodalopen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getmodalName, setModalName] = useState("");
  const [getmsg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [no_of_bond, setno_of_bond] = useState("");
  const [getall_uniqcode, setAll_uniqcode] = useState("");
  const handleFrameClick = (frame) => {
    setSelectedFrame(frame);
  };

  // const carddata = () => {

  // }

  const [getdata, setdata] = useState([]);

  const location = useLocation();

  useEffect(() => {
    Previewproduct();
    ReleaseProduct();
    GetAllData();
  }, []);

  const [qr_code, setqr_code] = useState("");
  const [owner_name, setowner_name] = useState("");
  const [creator_name, setcreator_name] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [no_of_release_bond, setno_of_release_bond] = useState("");
  const [max_bond, setmax_bond] = useState(0);
  // const [theme_index, settheme_index] = useState(6);
  const [theme_index, settheme_index] = useState(
    ttt == "" ||
      ttt == null ||
      ttt == "null" ||
      ttt == "undefined" ||
      ttt == undefined
      ? 16
      : ttt
  );
  const [assign_no_of_bond, setassign_no_of_bond] = useState(0);
  const [remaining_bond_release_date, setremaining_bond_release_date] =
    useState("");
  const [product_value, setproduct_value] = useState("");
  const [product_img_video_carosel, setproduct_img_video_carosel] =
    useState("");
  const [product_img_video_carosel3, setproduct_img_video_carosel3] =
    useState("");

  const [available_no_of_bond, setavailable_no_of_bond] = useState(0);
  const [attched_benifit_data, setattched_benifit_data] = useState([]);
  const [bond_img_id, setbond_img_id] = useState("");
  const [img_index, set_img_index] = useState(0);

  // crop section start

  const minZoom = 0.2;
  const CROP_AREA_ASPECT = 14 / 16;

  const [getcropmodal, setCropmodal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [imageSrc2, setImageSrc2] = useState(
    "https://ae01.alicdn.com/kf/HTB1AboSJFXXXXXZXpXXq6xXFXXX8/New-6-5x10ft-Studio-Photo-Backdrop-Screen-Hot-Selling-Green-Nature-Landscape-Photography-Wedding-Portrait-Background.jpg_640x640.jpg"
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [getsubmitmodal, setSubmitModal] = useState(false);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const [fixedImageSrc, setFixedImageSrc] = useState(images.logos_back);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const selectedImageRef = useRef(null);
  const [getorignal, setOrignal] = useState("");
  // const [getbond_img_id, setbond_img_id] = useState("");

  const [getsetImgsModal, setSetImgsModal] = useState(false);

  const openSetImgsModal = () => {
    setSetImgsModal(!getsetImgsModal);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const showCroppedImage = async () => {
    setloading(true);
    // const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
    const croppedImage = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      "https://rarerez.club/logos_back2.png"
    );
    console.log(croppedImage);
    setCroppedImageBlob(croppedImage);
    console.log("blobii", URL.createObjectURL(croppedImage));
    setCroppedImage(URL.createObjectURL(croppedImage));

    setCropData(URL.createObjectURL(croppedImage));

    setSelectedImageSrc(URL.createObjectURL(croppedImage));
    setCropmodal(false);
    setloading(false);
    setSubmitModal(true);
    // setImage("");
  };

  // const showOriginalimage = async () => {
  //   const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";

  //   try {
  //     // Fetch the image as a binary blob
  //     const response = await fetch(`${corsProxyUrl}${getorignal}`);
  //     const blob = await response.blob();
  //     console.log("bolb", JSON.stringify(blob, null, 2));
  //     setloading(true);

  //     let formData = new FormData();
  //     // formData.append("product_id", location.state.product_id);
  //     formData.append("bond_img_id",bond_img_id);
  //     // formData.append("user_id", userId);
  //     // formData.append("img_video[]", blob);

  //     console.log("param------>", formData);
  //     axios
  //       .post(update_bond_img, formData)
  //       .then((Response) => {
  //         console.log("acdascs", Response.data.result);
  //         const data = Response.data.result;
  //         setloading(false);
  //         if (Response.data.status == 1) {
  //           Previewproduct();
  //           ReleaseProduct();
  //           GetAllData();
  //           console.log("Response.data", Response.data);
  //           setSubmitModal(false);
  //         } else {
  //           setSubmitModal(false);
  //           // setok(1);
  //         }
  //         // this.setState({
  //         //   // data: Response.data.result,
  //         //   task_arrayholder: Response.data.result,
  //         // });
  //       })
  //       .catch((err) => {
  //         setloading(false);
  //         console.log(err);
  //         setSubmitModal(false);
  //       });
  //   } catch (error) {
  //     console.error("Error fetching image:", error);
  //   }
  // };

  const deleteimage = async (bond_img_id) => {
    console.log("vishal", bond_img_id);

    try {
      // Fetch the image as a binary blob
      setloading(true);

      let formData = new FormData();
      formData.append("bond_img_id", bond_img_id);

      console.log("param------>", formData);
      axios
        .post("https://www.ravi.host/api/delete_crop_image", formData)
        .then((Response) => {
          console.log("acdascs", Response.data.result);
          const data = Response.data.result;
          setloading(false);
          if (Response.data.status == 1) {
            Previewproduct();
            ReleaseProduct();
            GetAllData();
            console.log("Response.data", Response.data);
            setSubmitModal(false);
          } else {
            setSubmitModal(false);
            // setok(1);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          setSubmitModal(false);
        });
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  const handleImageSubmit = () => {
    // dataURItoBlob(croppedImage);
    setCropImage(croppedImageBlob);
  };

  // const handleImageSubmit = () => {
  //   // Combine the two images and save or perform other actions
  //   const canvas = document.createElement("canvas");
  //   canvas.width = 300; // Set the canvas size as needed
  //   canvas.height = 500;
  //   const context = canvas.getContext("2d");

  //   // Draw the fixed image
  //   const fixedImage = new Image();
  //   fixedImage.src = fixedImageSrc;
  //   context.drawImage(fixedImage, 0, 0, canvas.width, canvas.height);

  //   // Draw the selected image at the specified position
  //   if (selectedImageSrc) {
  //     const selectedImage = selectedImageRef.current;
  //     context.drawImage(
  //       selectedImage,
  //       position.x,
  //       position.y,
  //       selectedImage.width,
  //       selectedImage.height
  //     );
  //   }

  //   // Save or perform other actions with the combined image
  //   const combinedImageSrc = canvas.toDataURL();
  //   // dataURItoBlob(combinedImageSrc);
  //   const combinedImageBlob = dataURItoBlob(combinedImageSrc);
  //   const blobUrl = URL.createObjectURL(combinedImageBlob);
  //   console.log("Blob URL:", blobUrl);
  //   // setCropImage(combinedImageBlob);
  //   base64ToBlob(combinedImageSrc);
  //   setCroppedImage(blobUrl);
  //   setImage("");
  //   setSubmitModal(false);

  //   axios.get();

  //   console.log("Combined Image:", combinedImageSrc);
  // };

  function base64ToBlob(base64, mimeType) {
    // Split the base64 string to get the data and mime type
    const base64Data = base64.split(",")[1];
    const type = mimeType || base64.split(",")[0].split(":")[1].split(";")[0];

    // Convert base64 to binary
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create Blob from binary data
    const bb = new Blob([bytes], { type });
    console.log("Blob11", bb);
  }

  const setCropImage = (image) => {
    setloading(true);

    let formData = new FormData();
    formData.append("product_id", location.state.product_id);
    // formData.append("bond_img_id", bond_img_id);
    // formData.append("product_id", location.state.product_id);
    formData.append("user_id", userId);
    formData.append("img_video[]", image);

    console.log("param------>", formData);
    axios
      .post(update_bond_img, formData)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          // Previewproduct();
          Previewproduct2();
          ReleaseProduct();
          GetAllData();
          console.log("Response.data", Response.data);
          setSubmitModal(false);
        } else {
          setSubmitModal(false);
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        setSubmitModal(false);
      });
  };

  const dataURItoBlob = (dataURI) => {
    // Split the data URI into the metadata and the actual data
    const [metadata, data] = dataURI.split(",");

    // Extract the MIME type from the metadata
    const mime = metadata.match(/data:(.*);base64/)[1];

    // Convert the Base64 data to a byte array
    const byteCharacters = atob(data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create a Blob with the byte array and the MIME type
    const blob = new Blob(byteArrays, { type: mime });
    console.log("111 blob -->", blob);
    return blob;
  };

  const handleFixedImageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setPosition({ x, y });
  };

  const handleClose = () => {
    setCroppedImage(null);
  };

  // crop section end

  const Previewproduct = () => {
    setloading(true);
    const param = {
      product_id: location.state.product_id,
      user_id: userId,
    };
    console.log("param------>", param);
    axios
      .post(preview_product, param)
      .then((Response) => {
        console.log("acdascs--->", Response.data.result);
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          setdata(data);
          setqr_code(data.qr_code);
          setowner_name(data.owner_name);
          setcreator_name(data.creator_name);
          setproduct_name(data.product_name);
          setdescription(data.description);
          setcreated_at(data.created_at);
          setno_of_release_bond(data.no_of_release_bond);
          setremaining_bond_release_date(data.remaining_bond_release_date);
          setproduct_value(data.product_value);
          setproduct_img_video_carosel(data.product_img_video);
          // setproduct_img_video_carosel3(data.product_img_video[0].product_icon);

          // setproduct_img_video_carosel3(
          //   data.product_img_video[data.product_img_video.length - 1]
          //     .product_icon
          // );

          set_img_index(data.product_img_video.length - 1);
          setbond_img_id(data.product_img_video[0].bond_img_id);
        } else {
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const Previewproduct2 = () => {
    setloading(true);
    const param = {
      product_id: location.state.product_id,
      user_id: userId,
    };
    console.log("param------>", param);
    axios
      .post(preview_product, param)
      .then((Response) => {
        console.log("acdascs--->", Response.data.result);
        const data = Response.data.result;
        setloading(false);
        if (Response.data.status == 1) {
          setdata(data);
          setqr_code(data.qr_code);
          setowner_name(data.owner_name);
          setcreator_name(data.creator_name);
          setproduct_name(data.product_name);
          setdescription(data.description);
          setcreated_at(data.created_at);
          setno_of_release_bond(data.no_of_release_bond);
          setremaining_bond_release_date(data.remaining_bond_release_date);
          setproduct_value(data.product_value);
          setproduct_img_video_carosel(data.product_img_video);
          // setproduct_img_video_carosel3(data.product_img_video[0].product_icon);
          // setbond_img_id(data.product_img_video[0].bond_img_id);
        } else {
          // setok(1);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const closemodal1 = () => {
    setShowInfoModal(false);
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };

  let selectedCardComponent;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (getdata) {
    if (selectedFrame === "frames12") {
      selectedCardComponent = (
        <NewEditNfbFrame1
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames13") {
      selectedCardComponent = (
        <NewEditNfbFrame2
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames14") {
      selectedCardComponent = (
        <NewEditNfbFrame3
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames15") {
      selectedCardComponent = (
        <NewEditNfbFrame4
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames16") {
      selectedCardComponent = (
        <NewEditNfbFrame5
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames17") {
      selectedCardComponent = (
        <NewEditNfbFrame6
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames18") {
      selectedCardComponent = (
        <NewEditNfbFrame7
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames19") {
      selectedCardComponent = (
        <NewEditNfbFrame8
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames20") {
      selectedCardComponent = (
        <NewEditNfbFrame9
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else if (selectedFrame === "frames21") {
      selectedCardComponent = (
        <NewEditNfbFrame10
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          openSetImgsModal={openSetImgsModal}
        />
      );
    } else {
      selectedCardComponent = (
        <NewEditNfbFrame1
          item={getdata}
          icon={product_img_video_carosel3}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
          setImgModal={setSetImgsModal}
        />
      );
    }
  }

  // get all data start

  const GetAllData = () => {
    setattched_benifit_data([]);
    const param = {
      product_id: location.state.product_id,
      user_id: userId,
      commitment_date: location.state.commitment_date_new,
    };

    axios
      .post(
        "https://www.ravi.host/api/list_of_product_no_of_bond_assign_benifit",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          setattched_benifit_data(Response.data.result);
          // Extracting uniq_code values into a separate array
          const uniqCodesArray = Response.data.result.map(
            (item) => item.uniq_code
          );
          const commaSeparatedCodes = uniqCodesArray.join(",");

          setAll_uniqcode(commaSeparatedCodes);
          console.log("uniqCodesArray:", commaSeparatedCodes);
        } else {
        }
      });
  };

  // get all data end

  // release product start

  const ReleaseProduct = () => {
    const param = {
      product_id: location.state.product_id,
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_no_of_release_bond_product",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(
          "====================================release product,",
          JSON.stringify(Response.data, null, 2)
        );

        if (Response.data.status == 1) {
          setavailable_no_of_bond(Response.data.result.available_no_of_bond);
          setmax_bond(Response.data.result.no_of_release_bond);
          setassign_no_of_bond(Response.data.result.assign_no_of_bond);
          // this.setState({
          //   max_bond: Response.data.result.no_of_release_bond,
          //   available_no_of_bond: Response.data.result.available_no_of_bond,
          //   assign_no_of_bond: Response.data.result.assign_no_of_bond,
          //   // description:
          // });
        } else {
          // this.setState({
          //   isModalVisible: true,
          //   cusmsg: Response.data.message,
          // });
        }
      });
  };

  // release product end

  // enter nfbs modal logic start
  const BenefitNFBs = () => {
    const isValid = /^\d+$/.test(no_of_bond);

    if (no_of_bond == 0 || no_of_bond == null) {
      // alert("Please enter number of bond");
      setIsModalOpen(true);
      setModalName("Please enter number of Rarerez");
    } else if (no_of_bond < 0) {
      // alert("Enter bond value below release value of bond");
      setIsModalOpen(true);
      setModalName("Value is invalid");
    } else if (!isValid) {
      // alert("Enter bond value below release value of bond");
      setno_of_bond("");
      setIsModalOpen(true);
      setModalName("Please enter a valid number of Rarerez");
    } else if (no_of_bond > available_no_of_bond) {
      // alert("Enter bond value below release value of bond");
      setIsModalOpen(true);
      setModalName("Please enter a number below the available rarerez count");
    } else {
      navigate("/Attachbenefit", {
        state: {
          product_id: location.state.product_id,
          number_of_bond: no_of_bond,
        },
      });
    }
  };

  // enter nfbs modal logic end

  // place bid start

  const releaseBond = () => {
    setShowInfoModal(false);
    // setIsLoading(true);

    if (available_no_of_bond == 0) {
      const param1 = {
        uniq_code: getall_uniqcode,
      };

      console.log("param1", param1);
      axios
        .post(
          "https://www.ravi.host/api/user_product_uniq_code",
          qs.stringify(param1)
        )
        .then((Response) => {
          // setIsLoading(false);
          if (Response.data.status == 1) {
            // release bond api start

            // Extract product_assign_id values
            const productAssignIds = Response.data.result.map(
              (item) => item.product_assign_id
            );

            // Convert the array to a comma-separated string
            const commaSeparatedIds = productAssignIds.join(",");
            console.log("productAssignIds", commaSeparatedIds);

            const param = {
              product_id: location.state.product_id,
              user_id: userId,
              no_of_release_bond: max_bond,
              theme_index: theme_index,
              bond_img_id: bond_img_id,
              product_assign_id: commaSeparatedIds,
            };

            axios
              .post(
                "https://www.ravi.host/api/release_bond",
                qs.stringify(param)
              )
              .then((Response) => {
                setIsLoading(false);
                if (Response.data.status === 1) {
                  // props.navigation.replace("BottomTabNavigator", {
                  //   user_id: userId,
                  // });
                  navigate("/homescreen2");
                } else {
                  getmsg(Response.data.message);
                  setIsModalOpen(true);
                }
              });
            // release bond api end
          } else {
            getmsg(Response.data.message);
            setIsModalOpen(true);
          }
        });
    } else {
      const chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let randomString = "";
      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        randomString += chars.charAt(randomIndex);
      }

      const param = {
        product_id: location.state.product_id,
        no_of_bond: available_no_of_bond,
        user_id: userId,
        uniq_code: randomString,
        theme_index: theme_index,
        bond_img_id: bond_img_id,
      };

      axios
        .post(
          "https://www.ravi.host/api/release_bond_without_benifit",
          qs.stringify(param)
        )
        .then((Response) => {
          // setIsModalVisible(false);
          if (Response.data.status === 1) {
            localStorage.removeItem("theme_index");
            localStorage.removeItem("frames_index");

            const finalString = `${getall_uniqcode},${randomString}`;

            console.log("finalString", finalString);
            const param11 = {
              uniq_code: getall_uniqcode == "" ? randomString : finalString,
            };
            console.log("param11", param11);

            axios
              .post(
                "https://www.ravi.host/api/user_product_uniq_code",
                qs.stringify(param11)
              )
              .then((Response) => {
                // setIsLoading(false);
                if (Response.data.status == 1) {
                  // release bond api start

                  // Extract product_assign_id values
                  const productAssignIds = Response.data.result.map(
                    (item) => item.product_assign_id
                  );

                  // Convert the array to a comma-separated string
                  const commaSeparatedIds = productAssignIds.join(",");
                  console.log("productAssignIds", commaSeparatedIds);

                  const param = {
                    product_id: location.state.product_id,
                    user_id: userId,
                    no_of_release_bond: max_bond,
                    theme_index: theme_index,
                    bond_img_id: bond_img_id,
                    product_assign_id: commaSeparatedIds,
                  };

                  axios
                    .post(
                      "https://www.ravi.host/api/release_bond",
                      qs.stringify(param)
                    )
                    .then((Response) => {
                      setIsLoading(false);
                      if (Response.data.status === 1) {
                        // props.navigation.replace("BottomTabNavigator", {
                        //   user_id: userId,
                        // });
                        navigate("/homescreen2");
                      } else {
                        getmsg(Response.data.message);
                        setIsModalOpen(true);
                      }
                    });
                  // release bond api end
                } else {
                  getmsg(Response.data.message);
                  setIsModalOpen(true);
                }
              });
          } else {
            // setCusmsg(Response.data.message);
            // setIsModalVisible(true);
          }
        });
    }
  };

  // const releaseBond = () => {
  //   setIsLoading(true);

  //   if (available_no_of_bond == 0) {
  //     const param = {
  //       product_id: location.state.product_id,
  //       user_id: userId,
  //       no_of_release_bond: max_bond,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };

  //     console.log("params1---->", param);

  //     axios
  //       .post(
  //         release_bond
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         setIsLoading(false);
  //         console.log("Response.data", Response.data);
  //         if (Response.data.status === 1) {
  //           // props.navigation.replace("BottomTabNavigator", {
  //           //   user_id: props.user_id,
  //           // });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   } else {
  //     const chars =
  //       "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+~`|}{[]:;?><,.=";
  //     let randomString = "";
  //     for (let i = 0; i < 4; i++) {
  //       const randomIndex = Math.floor(Math.random() * chars.length);
  //       randomString += chars.charAt(randomIndex);
  //     }

  //     const param = {
  //       product_id: location.state.product_id,
  //       no_of_bond: available_no_of_bond,
  //       user_id: userId,
  //       uniq_code: randomString,
  //       theme_index: theme_index,
  //       bond_img_id: bond_img_id,
  //     };
  //     console.log("params2---->", param);

  //     axios
  //       .post(
  //         "https://www.ravi.host/api/release_bond_without_benifit"
  //         // qs.stringify(param)
  //       )
  //       .then((Response) => {
  //         // setIsModalVisible(false);
  //         if (Response.data.status === 1) {
  //           const param = {
  //             product_id: location.state.product_id,
  //             user_id: userId,
  //             no_of_release_bond: max_bond,
  //             theme_index: theme_index,
  //             bond_img_id: bond_img_id,
  //           };
  //           console.log("params3---->", param);

  //           setIsLoading(true);

  //           axios
  //             .post(
  //               "https://www.ravi.host/api/release_bond"
  //               // qs.stringify(param)
  //             )
  //             .then((Response) => {
  //               setIsLoading(false);
  //               if (Response.data.status === 1) {
  //                 // props.navigation.replace("BottomTabNavigator", {
  //                 //   user_id: user,
  //                 // });
  //                 navigate("/homescreen2");
  //               } else {
  //                 // setCusmsg(Response.data.message);
  //                 // setIsModalVisible(true);
  //               }
  //             });
  //         } else {
  //           // setCusmsg(Response.data.message);
  //           // setIsModalVisible(true);
  //         }
  //       });
  //   }
  // };

  // place bid end

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="page_main_wrapp">
      {loading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          {/* <NavbarSec name="Create Rarerez" /> */}

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "30px",
              background: "black",
              zIndex: 11,
            }}
          >
            <div className="navsec_back_arrow">
              {/* <img
                src={images.back}
                // className="nav_back_btn"
                alt=""
                onClick={() => {
                  localStorage.removeItem("theme_index");
                  localStorage.removeItem("frames_index");
                  navigate(-1);
                }}
              /> */}
              <button
                onClick={() => {
                  localStorage.removeItem("theme_index");
                  localStorage.removeItem("frames_index");
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
            </div>

            <b
              style={{
                color: "#fff",
                fontfamily: "var(--font-600)",
                fontsize: "16px",
              }}
            >
              Create Rarerez
            </b>
          </div>

          {isLoading == true ? (
            <div className="loader"></div>
          ) : (
            <div>
              <div
                className="view_card_nfb"
                style={{ margin: "1rem", minWidth: "90%" }}
              >
                {selectedCardComponent}
              </div>

              <p>Set frames</p>
              <div style={{ position: "relative", width: "100%" }}>
                <button
                  className="nav-button left"
                  onClick={scrollLeft}
                  style={{ padding: "10px 0px", width: "24px" }}
                >
                  <FaAngleLeft color="var(--color-main)" size={22} />
                </button>
                <div
                  ref={sliderRef}
                  style={{ padding: "6px 1.5rem" }}
                  className="view_card_nfb2"
                >
                  <br />
                  {/* Frame Selection */}
                  {/* <img
              src={images.firstcard}
              className={
                selectedFrame === "frames3"
                  ? `frames_img ${
                      selectedFrame === "frames3" ? "selected" : ""
                    }`
                  : "frames_img_border"
              }
              alt=""
              onClick={() => {
                settheme_index(0);
                handleFrameClick("frames3");
              }}
            /> */}
                  {/* <img
              src={images.firstcard}
              className={
                selectedFrame === "frames3"
                  ? `frames_img ${
                      selectedFrame === "frames3" ? "selected" : ""
                    }`
                  : "frames_img_border"
              }
              alt=""
              onClick={() => {
                settheme_index(0);
                handleFrameClick("frames3");
              }}
            /> */}
                  {/* <img
                  src={images.yellowcard}
                  className={
                    selectedFrame === "frames1"
                      ? `frames_img ${
                          selectedFrame === "frames1" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(6);
                    localStorage.setItem("theme_index", 6);
                    handleFrameClick("frames1");
                    localStorage.setItem("frames_index", "frames1");
                  }}
                /> */}
                  {/* <img
                  src={images.redcard}
                  className={
                    selectedFrame === "frames2"
                      ? `frames_img ${
                          selectedFrame === "frames2" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(7);
                    localStorage.setItem("theme_index", 7);
                    handleFrameClick("frames2");
                    localStorage.setItem("frames_index", "frames2");
                  }}
                />
                <img
                  src={images.Card8Frame}
                  className={
                    selectedFrame === "frames4"
                      ? `frames_img ${
                          selectedFrame === "frames4" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(8);
                    localStorage.setItem("theme_index", 8);
                    handleFrameClick("frames4");
                    localStorage.setItem("frames_index", "frames4");
                  }}
                />
                <img
                  src={images.Card9Frame}
                  className={
                    selectedFrame === "frames5"
                      ? `frames_img ${
                          selectedFrame === "frames5" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(9);
                    localStorage.setItem("theme_index", 9);
                    handleFrameClick("frames5");
                    localStorage.setItem("frames_index", "frames5");
                  }}
                />
                <img
                  src={images.Card10Frame}
                  className={
                    selectedFrame === "frames6"
                      ? `frames_img ${
                          selectedFrame === "frames6" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(10);
                    localStorage.setItem("theme_index", 10);
                    handleFrameClick("frames6");
                    localStorage.setItem("frames_index", "frames6");
                  }}
                />
                <img
                  src={images.Card11Frame}
                  className={
                    selectedFrame === "frames7"
                      ? `frames_img ${
                          selectedFrame === "frames7" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(11);
                    localStorage.setItem("theme_index", 11);
                    handleFrameClick("frames7");
                    localStorage.setItem("frames_index", "frames7");
                  }}
                />
                <img
                  src={images.Card12Frame}
                  className={
                    selectedFrame === "frames8"
                      ? `frames_img ${
                          selectedFrame === "frames8" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(12);
                    localStorage.setItem("theme_index", 12);
                    handleFrameClick("frames8");
                    localStorage.setItem("frames_index", "frames8");
                  }}
                />
                <img
                  src={images.card7_hr_thub}
                  className={
                    selectedFrame === "frames9"
                      ? `frames_img ${
                          selectedFrame === "frames9" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(13);
                    localStorage.setItem("theme_index", 13);
                    handleFrameClick("frames9");
                    localStorage.setItem("frames_index", "frames9");
                  }}
                />
                <img
                  src={images.frame14}
                  className={
                    selectedFrame === "frames10"
                      ? `frames_img ${
                          selectedFrame === "frames10" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(14);
                    localStorage.setItem("theme_index", 14);
                    handleFrameClick("frames10");
                    localStorage.setItem("frames_index", "frames10");
                  }}
                />
                <img
                  src={images.frame15}
                  className={
                    selectedFrame === "frames11"
                      ? `frames_img ${
                          selectedFrame === "frames11" ? "selected" : ""
                        }`
                      : "frames_img_border"
                  }
                  alt=""
                  onClick={() => {
                    settheme_index(15);
                    localStorage.setItem("theme_index", 15);
                    handleFrameClick("frames11");
                    localStorage.setItem("frames_index", "frames11");
                  }}
                /> */}
                  <img
                    src={images.new_frame_1_nfb}
                    className={
                      selectedFrame === "frames12"
                        ? `frames_img ${
                            selectedFrame === "frames12" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(16);
                      localStorage.setItem("theme_index", 16);
                      handleFrameClick("frames12");
                      localStorage.setItem("frames_index", "frames12");
                    }}
                  />
                  <img
                    src={images.new_frame_2_nfb}
                    className={
                      selectedFrame === "frames13"
                        ? `frames_img ${
                            selectedFrame === "frames13" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(17);
                      localStorage.setItem("theme_index", 17);
                      handleFrameClick("frames13");
                      localStorage.setItem("frames_index", "frames13");
                    }}
                  />
                  <img
                    src={images.new_frame_3_nfb}
                    className={
                      selectedFrame === "frames14"
                        ? `frames_img ${
                            selectedFrame === "frames14" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(18);
                      localStorage.setItem("theme_index", 18);
                      handleFrameClick("frames14");
                      localStorage.setItem("frames_index", "frames14");
                    }}
                  />
                  <img
                    src={images.new_frame_4_nfb}
                    className={
                      selectedFrame === "frames15"
                        ? `frames_img ${
                            selectedFrame === "frames15" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(19);
                      localStorage.setItem("theme_index", 19);
                      handleFrameClick("frames15");
                      localStorage.setItem("frames_index", "frames15");
                    }}
                  />
                  <img
                    src={images.new_frame_5_nfb}
                    className={
                      selectedFrame === "frames16"
                        ? `frames_img ${
                            selectedFrame === "frames16" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(20);
                      localStorage.setItem("theme_index", 20);
                      handleFrameClick("frames16");
                      localStorage.setItem("frames_index", "frames16");
                    }}
                  />
                  <img
                    src={images.new_frame_6_nfb}
                    className={
                      selectedFrame === "frames17"
                        ? `frames_img ${
                            selectedFrame === "frames17" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(21);
                      localStorage.setItem("theme_index", 21);
                      handleFrameClick("frames17");
                      localStorage.setItem("frames_index", "frames17");
                    }}
                  />
                  <img
                    src={images.new_frame_7_nfb}
                    className={
                      selectedFrame === "frames18"
                        ? `frames_img ${
                            selectedFrame === "frames18" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(22);
                      localStorage.setItem("theme_index", 22);
                      handleFrameClick("frames18");
                      localStorage.setItem("frames_index", "frames18");
                    }}
                  />
                  <img
                    src={images.new_frame_8_nfb}
                    className={
                      selectedFrame === "frames19"
                        ? `frames_img ${
                            selectedFrame === "frames19" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(23);
                      localStorage.setItem("theme_index", 23);
                      handleFrameClick("frames19");
                      localStorage.setItem("frames_index", "frames19");
                    }}
                  />
                  <img
                    src={images.new_frame_9_nfb}
                    className={
                      selectedFrame === "frames20"
                        ? `frames_img ${
                            selectedFrame === "frames20" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(24);
                      localStorage.setItem("theme_index", 24);
                      handleFrameClick("frames20");
                      localStorage.setItem("frames_index", "frames20");
                    }}
                  />
                  <img
                    src={images.new_frame_10_nfb}
                    className={
                      selectedFrame === "frames21"
                        ? `frames_img ${
                            selectedFrame === "frames21" ? "selected" : ""
                          }`
                        : "frames_img_border"
                    }
                    alt=""
                    onClick={() => {
                      settheme_index(25);
                      localStorage.setItem("theme_index", 25);
                      handleFrameClick("frames21");
                      localStorage.setItem("frames_index", "frames21");
                    }}
                  />
                </div>
                <button
                  className="nav-button right"
                  onClick={scrollRight}
                  style={{
                    justifyContent: "flex-end",
                    padding: "10px 0px",
                    width: "24px",
                  }}
                >
                  <FaAngleRight color="var(--color-main)" size={22} />
                </button>
              </div>
              {/* {location.state.is_user == 1 && (
                <div className="view_card_nfb">
                  <p>Set Image</p>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    {product_img_video_carosel &&
                      product_img_video_carosel.map((i, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "150px",
                              // paddingLeft: "20px",
                            }}
                          >
                            <img
                              src={i.product_icon}
                              className={
                                index != img_index
                                  ? "set_images_nfb_border"
                                  : "set_images_nfb"
                              }
                              alt=""
                              onClick={() => {
                                if (i.image_type == "new") {
                                  set_img_index(index);
                                  setbond_img_id(i.bond_img_id);
                                  setproduct_img_video_carosel3(i.product_icon);
                                  setImageSrc(i.product_icon);
                                  setOrignal(i.product_icon);
                                  // setCropmodal(true);
                                  console.log("getcropmodal", getcropmodal);
                                } else {
                                  set_img_index(index);
                                  setbond_img_id(i.bond_img_id);
                                  setproduct_img_video_carosel3(i.product_icon);
                                  setImageSrc(i.product_icon);
                                  setOrignal(i.product_icon);
                                  setCropmodal(true);
                                  console.log("getcropmodal", getcropmodal);
                                }
                                // console.log("dscv");
                              }}
                            />
                            {i.image_type == "new" ? (
                              <button
                                onClick={() => {
                                  deleteimage(i.bond_img_id);
                                }}
                                // variant="contained"
                                // color="primary"
                                // classes={{ root: classes.cropButton }}
                                style={{
                                  color: "#fff",
                                  // display: "block",
                                  // zIndex: 1,
                                  background: "var(--color-main)",
                                  borderRadius: "4px",
                                  padding: "10px 16px",
                                  fontFamily: "var(--font-600)",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  // marginLeft: "30px",
                                  // width: "50%",
                                }}
                              >
                                Delete
                              </button>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )} */}
              {available_no_of_bond == 0 ? null : (
                <button
                  className="attach_nfbs_nm"
                  onClick={() => setShowModal(true)}
                >
                  <p>
                    Attach Benefit to remaining {available_no_of_bond} Rarerez
                  </p>
                </button>
              )}
              <br />
              <div className="attach_nfbs_nm2">
                <p style={{ fontSize: "12px", fontFamily: "var(--font-600)" }}>
                  You can attach benefits on {available_no_of_bond} Rarerez.
                </p>
                <p className="nm_attach_f">
                  Attach benefits to your Rarerez and make them irresistible.
                  Your added benefits enhance the value of your Rarerez. Get
                  creative and watch them become highly sought after!
                </p>
                <Link
                  className="createbenefit_btn"
                  to={"/createbenefit"}
                  state={{ product_id: location.state.product_id }}
                >
                  Create benefits
                </Link>
              </div>
              <div className="view_benefit_main">
                {attched_benifit_data && attched_benifit_data.length > 0 && (
                  <b>Benefits Attached</b>
                )}
                {attched_benifit_data && attched_benifit_data.length > 0
                  ? attched_benifit_data.map((item, index) => {
                      console.log("item", item);
                      return (
                        <div className="list_benefit_view">
                          <div className="part_benefit" style={{ gap: "10px" }}>
                            <div className="part_benefit1">
                              <div className="sub_list_benefits">
                                <div>
                                  <b className="nm_nfbs_list">Rarerez</b>
                                  <br />
                                  <b className="nm_nfbs_list1">
                                    {item.no_of_bond}
                                  </b>
                                </div>
                                <Link
                                  className="manage_link"
                                  to="/Attachbenefit"
                                  state={{
                                    product_id: location.state.product_id,
                                    number_of_bond: item.no_of_bond,
                                    uniq_code: item.uniq_code,
                                  }}
                                >
                                  Manage
                                </Link>
                              </div>
                            </div>
                            <div className="part_benefit2">
                              {/* partner data start */}
                              {item.partner_bond_benifit_data &&
                              item.partner_bond_benifit_data.length > 0
                                ? item.partner_bond_benifit_data.map(
                                    (itm, ind) => {
                                      return (
                                        <div className="view_sub_benefit">
                                          <img
                                            src={itm.benifit_icon}
                                            className="img_view_1"
                                            alt=""
                                          />
                                          <p className="benefit_nm_sub">
                                            {itm.benifit_name}
                                          </p>
                                          <p className="benefit_nm_sub">
                                            {itm.partner_name}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )
                                : null}

                              {/* partner data end */}

                              {/* own benefit data start */}

                              {item.product_bond_benifit_detail &&
                              item.product_bond_benifit_detail.length > 0
                                ? item.product_bond_benifit_detail.map(
                                    (data, ind) => {
                                      return (
                                        <div className="view_sub_benefit">
                                          <img
                                            src={
                                              data.reward_img_video[0].img_video
                                            }
                                            className="img_view_1"
                                            alt=""
                                          />
                                          <p className="benefit_nm_sub">
                                            {data.reward_name}
                                          </p>
                                        </div>
                                      );
                                    }
                                  )
                                : null}

                              {/* own benefit data end */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <center>
                <button
                  className="new_btn_wrapp mt-3"
                  onClick={() => setShowInfoModal(true)}
                >
                  <p style={{ width: "90%" }}>
                    {" "}
                    {attched_benifit_data && attched_benifit_data.length > 0
                      ? "Launch Rarerez"
                      : "Launch Rarerez Without Benefit"}
                  </p>
                </button>
              </center>
            </div>
          )}
        </div>
      )}
      {showModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>

            <h4 className="v2modal_fixed_heading">
              Enter Rarerez (Max {available_no_of_bond})
            </h4>

            <div>
              {/* {mpin.map((digit, index) => { */}
              {/* return ( */}
              <input
                // key={index}
                type="tel"
                value={no_of_bond}
                className="newattach_modal_input"
                // onChange={(e) => setno_of_bond(e.target.value)}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^\d+$/.test(inputValue);

                  if (no_of_bond > available_no_of_bond) {
                    setno_of_bond("");
                    setModalName(
                      "Enter Rarerez value below release value of Rarerez"
                    );
                    setIsModalOpen(true);
                  } else if (!isValid) {
                    setno_of_bond("");
                    setModalName("Please enter a valid number of Rarerez");
                    setIsModalOpen(true);
                  } else {
                    setno_of_bond(parseInt(inputValue));
                    console.log("isValid", inputValue);
                  }
                }}
                // maxLength={1}
                placeholder="Enter number of rarerez"
                // ref={(input) => (mpinRefs.current[index] = input)}
              />
              {/* ); */}
              {/* })} */}
            </div>
            <div className="newattech_hr"></div>
            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px auto",
              }}
            >
              <button
                className="v2modal_btn1"
                onClick={() => {
                  setno_of_bond(null);
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button className="v2modal_btn2" onClick={BenefitNFBs}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}

      {/* crop modal start */}

      {getcropmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              zIndex: "11",
            }}
          >
            <div
              style={{
                width: "100%",
                margin: "10px auto",
                height: "13vh",
                display: "flex",
                // flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",

                // backgroundColor: "red",
                // position: "absolute",
                // top: "10px",
                // alignSelf: "center",
              }}
            >
              <button
                onClick={showCroppedImage}
                // variant="contained"
                // color="primary"
                // classes={{ root: classes.cropButton }}
                style={{
                  // color: "#fff",
                  width: "30%",
                }}
                className="new_btn_wrapp"
              >
                {/* Show Img */}
                <p>Preview</p>
              </button>
              <button
                onClick={() => {
                  setCropmodal(false);
                  setloading(false);
                }}
                className="new_skip_btn"
                style={{
                  // color: "#fff",
                  width: "30%",
                  zIndex: 11,
                }}
              >
                {/* Show Img */}
                Cancel
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "70vh",
                maxWidth: "300px",
                alignSelf: "center",
                overflow: "hidden",
              }}
            >
              <Cropper
                minZoom={minZoom}
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={3 / 4}
                restrictPosition={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                style={{ width: "300px", height: 500 }}
              />
            </div>
          </div>
        </div>
      )}

      {/* crop modal end */}

      {/* information modal start */}

      {showinfoModal && (
        // <div className="newattach_modal">
        //   <div className="modal-content1">
        //     <center>
        //       <img src={images.logo} className="newattach_modal_img2" alt="" />
        //     </center>
        //     <p
        //       style={{
        //         fontSize: "16px",
        //         fontFamily: "var(--font-600)",
        //         color: "#fff",
        //         marginTop: "-30px",
        //       }}
        //     >
        //       {/* Information */}
        //     </p>
        //     <p
        //       style={{
        //         width: "90%",
        //         margin: "10px auto",
        //         fontFamily: "var(--font-400)",
        //         fontSize: "12px",
        //       }}
        //     >
        //       Before releasing the Rarerez, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>
        //     <hr className="hr_forgot" />

        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //         width: "90%",
        //         margin: "10px auto",
        //         marginTop: "20px",
        //         gap: "50px",
        //       }}
        //     >
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => releaseBond()}
        //       >
        //         Okay
        //       </button>
        //       <button
        //         className="new_attech_modal_btn"
        //         onClick={() => setShowInfoModal(false)}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowInfoModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Before releasing the Rarerez, Please take a moment to carefully
        //       verify the details and the benefits attached
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={() => releaseBond()}>
        //         Okay
        //       </button>
        //       <button className="close_forgot" onClick={closemodal1}>
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Before releasing the Rarerez, Please take a moment to carefully verify the details and the benefits attached"
          setShowModal={setShowInfoModal}
          okfunction={releaseBond}
        />
      )}
      {ismodalopen && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
          /> */}
          {/* <NewModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}

      {/* submit img modal start */}

      {getsubmitmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="image-editor-container">
              <div
                className="image-container"
                // style={{ width: "252px", height: "405px", overflow: "hidden" }}
              >
                {/* <img
                  src={fixedImageSrc}
                  alt="Fixed Image"
                  className="fixed-image"
                  onClick={handleFixedImageClick}
                /> */}
                {selectedImageSrc && (
                  <img
                    src={selectedImageSrc}
                    alt="Selected Image"
                    className="selected-image"
                    ref={selectedImageRef}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
              {/* <input
                type="file"
                onChange={handleImageSelect}
                accept="image/*"
              /> */}
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <button
                  onClick={() => {
                    setSubmitModal(false);
                  }}
                  style={{
                    color: "white",
                    fontFamily: "var(--font-600)",
                    backgroundColor: "var(--color-main)",
                    padding: "10px 18px",
                    borderRadius: "8px",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleImageSubmit();
                    setproduct_img_video_carosel3(selectedImageSrc);
                  }}
                  style={{
                    color: "white",
                    fontFamily: "var(--font-600)",
                    backgroundColor: "var(--color-main)",
                    padding: "10px 18px",
                    borderRadius: "8px",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* submit img modal end */}

      {/* information modal end */}

      {/* set image modal start */}

      {getsetImgsModal && (
        <>
          <div
            className="crop_modal_main_wrapp"
            style={{
              background: "#00000060",
              zIndex: 222,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <div
              // className="crop_modal_base_wrapp"
              style={{
                background: "#000",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                borderTopRightRadius: "28px",
                borderTopLeftRadius: "28px",
                boxShadow: "0px 0px 10px rgba(90, 89, 89, 0.5)",
                maxWidth: "var(--max-width-sm)",
                margin: "0px auto",
                paddingTop: "20px",
                minHeight: "60vh",
              }}
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  margin: "10px",
                }}
              >
                <button
                  onClick={() => {
                    setSetImgsModal(false);
                  }}
                >
                  <IoClose size={20} color="#fff" />
                </button>
              </div>
              {location.state.is_user == 1 && (
                <div className="view_card_nfb" style={{ margin: "1rem" }}>
                  <p>Set Image</p>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    {product_img_video_carosel &&
                      product_img_video_carosel.map((i, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "150px",
                              // paddingLeft: "20px",
                            }}
                          >
                            <img
                              src={i.product_icon}
                              className={"set_images_nfb"}
                              alt=""
                              onClick={() => {
                                if (i.image_type == "new") {
                                  set_img_index(index);
                                  setbond_img_id(i.bond_img_id);
                                  setproduct_img_video_carosel3(i.product_icon);
                                  setImageSrc(i.product_icon);
                                  setOrignal(i.product_icon);
                                  setSetImgsModal(false);
                                  // setCropmodal(true);
                                  console.log("getcropmodal", getcropmodal);
                                } else {
                                  set_img_index(index);
                                  setbond_img_id(i.bond_img_id);
                                  setproduct_img_video_carosel3(i.product_icon);
                                  setImageSrc(i.product_icon);
                                  setOrignal(i.product_icon);
                                  setCropmodal(true);
                                  setSetImgsModal(false);
                                  console.log("getcropmodal", getcropmodal);
                                }
                                // console.log("dscv");
                              }}
                            />
                            {i.image_type == "new" ? (
                              <button
                                onClick={() => {
                                  deleteimage(i.bond_img_id);
                                }}
                                // variant="contained"
                                // color="primary"
                                // classes={{ root: classes.cropButton }}
                                style={{
                                  color: "#fff",
                                  // display: "block",
                                  // zIndex: 1,
                                  background: "var(--color-main)",
                                  borderRadius: "4px",
                                  padding: "10px 16px",
                                  fontFamily: "var(--font-600)",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  // marginLeft: "30px",
                                  // width: "50%",
                                }}
                              >
                                Delete
                              </button>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* set image modal end */}
    </div>
  );
};

export default CreatenfbsNew2;

// ---------- new croper added file -------------

//16-3-24
