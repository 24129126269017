import React, { useState } from "react";
import "./Recievedinvitaion_2.css";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { inter_user_email_verify_v2 } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";

const Recievedinvitaion2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [user_id, setuser_id] = useState(8);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirm = () => {
    if (email == "") {
      toast.error("please enter email address", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        email: email,
        user_id: location.state.user_id,
      };
      axios
        .post(inter_user_email_verify_v2, qs.stringify(param))
        .then((response) => {
          setIsLoading(false);
          if (response.data.status == 1) {
            console.log("Email verification successful!");
            toast.success(`${response.data.message}`, { theme: "colored" });
            navigate("/NewInternationalEmailOtp", {
              state: {
                user_id: location.state.user_id,
                email: location.state.email,
              },
            });
          } else {
            console.log("Email verification failed!");
            toast.error(response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          console.error(
            "There was an error with the Axios POST request:",
            error
          );
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="recr_new_wrapp_container recr_reletive_position">
          <img
            src={images.new_bg_circule}
            alt=""
            className="recr_back_circule_shadow"
          />
          <div className="recr_new_container">
            <div className="recr_back_arrow_wrapp">
              <button>
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
            </div>
            <div className="recr_new_intanationa_invite_main_wrapp">
              <img src={images.logo} alt="" />
              <h2>INVITATION</h2>
              <p>
                To verify an invitation from <b>{location.state.username}</b>,
                please provide the email ending with{" "}
                <b>
                  ****
                  {location.state.email && location.state.email.length > 4
                    ? location.state.email.slice(4)
                    : location.state.email}
                </b>
                .
              </p>
              {/* btn */}
              <div className="recr_new_set_password_single_input_wrapp">
                <div className="recr_new_set_password_single_labal_wrapp">
                  <img src={images.new_email_logo} alt="" />
                  <p className="recr_email">Enter Email Address</p>
                </div>
                <div className="recr_new_set_password_single_border_wrapp">
                  <input
                    type="email"
                    placeholder="Type here"
                    value={email}
                    onChange={(e) => {
                      handleChangeEmail(e);
                    }}
                  />
                  {/* <img src={images.new_eye_icon} alt="" /> */}
                </div>
              </div>
              <button className="new_btn_wrapp mt-3" onClick={handleConfirm}>
                <p>Confirm</p>
              </button>
              {/* btn */}
              {/* {successMessage && <p>{successMessage}</p>}
                {errorMessage && <p>{errorMessage}</p>} */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Recievedinvitaion2;
