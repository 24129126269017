import React from "react";
import NavbarSec from "../common/NavbarSec";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ShippingPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="Termsandcondition-container">
      <div className="Termsandcondition_2">
        <div className="web_user_menu">
          {/* <NavbarSec name="Shipping & Delivery Policy" /> */}
          <div
            style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
          >
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Shipping & Delivery Policy</p>
              <div></div>
            </div>
          </div>

          <div
            style={{
              color: "var(--color-white)",
              fontFamily: "var(--font-400)",
              fontSize: "14px",
              // fontWeight: 200,
            }}
            className="Termsandcondition_details"
          >
            <p
              style={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "var(--font-600)",
                margin: "10px auto",
              }}
            >
              Last updated on Des 22nd 2023
            </p>
            <p
              style={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "var(--font-400)",
                margin: "10px auto",
              }}
            >
              Shipping is not applicable for business
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
