import {
  REGISTER_BEGIN,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOGIN_BEGIN,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_OTP_FAIL,
  USER_OTP_BEGIN,
  USER_OTP_SUCCESS,
  FORGATE_PASSWORD_BEGIN,
  FORGATE_PASSWORD_SUCCESS,
  FORGATE_PASSWORD_FAIL,
} from "../Action";

const user_register = (state, action) => {
  // register

  if (action.type === REGISTER_BEGIN) {
    return { ...state, user_register_loading: true };
  }

  if (action.type === REGISTER_SUCCESS) {
    return {
      ...state,
      user_register_loading: false,
      user_register_data: action.payload,
      user_otp: action.payload.user_otp,
    };
  }

  if (action.type === REGISTER_FAIL) {
    return { ...state, user_register_loading: false };
  }

  // login

  if (action.type === USER_LOGIN_BEGIN) {
    return { ...state, user_login_loading: true };
  }

  if (action.type === USER_LOGIN_SUCCESS) {
    return {
      ...state,
      user_login_loading: false,
      user_login_data: action.payload,
    };
  }

  if (action.type === USER_LOGIN_FAIL) {
    return { ...state, user_login_loading: false };
  }

  // USER OTP
  if (action.type === USER_OTP_BEGIN) {
    return { ...state, user_otp_loading: true };
  }

  if (action.type === USER_OTP_SUCCESS) {
    return {
      ...state,
      user_otp_loading: false,
      user_otp_data: action.payload,
    };
  }

  if (action.type === USER_OTP_FAIL) {
    return { ...state, user_otp_loading: false };
  }

  // FORGATE PASSWORD
  if (action.type === FORGATE_PASSWORD_BEGIN) {
    return { ...state, is_loading: true };
  }

  if (action.type === FORGATE_PASSWORD_SUCCESS) {
    return {
      ...state,
      is_loading: false,
      forgate_data: action.payload,
    };
  }

  if (action.type === FORGATE_PASSWORD_FAIL) {
    return { ...state, is_loading: false };
  }
};

export default user_register;
