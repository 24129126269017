import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import SignUp from "./pages/signup/signup";
import HomeScreen from "./pages/homescreen/HomeScreen";
import Usermenu from "./pages/usermenu/Usermenu";
import Forgot from "./pages/forgotpass/Forgot";
import Feed from "./pages/feed/feed";
import Wallet from "./pages/Wallet/wallet";
import Clubwallet from "./pages/Clubwallet/Clubwallet";
import Clubs from "./pages/clubs/Clubs";

import Myproduct from "./pages/myproduct/Myproduct";
import Creatednfbs from "./pages/creatednfbs/Creatednfbs";
import Bids from "./pages/bid/Bids";
import Launchednfb from "./pages/launchednfb/Launchednfb";
import Bidsall from "./pages/bidsall/Bidsall";
import Nfbview from "./pages/Nfbview/Nfbview";
import Partnerbenefits from "./pages/partnerbenefits/Partnerbenefits";
import Notification from "./pages/notification/notification";
import Feed2 from "./pages/feed2/Feed2";
import Editprofile from "./pages/editprofile/Editprofile";
import Userqr from "./pages/userqr/Userqr";
import Changepassword from "./pages/chnagepassword/Changepassword";
import Termsandcondition from "./pages/termsandcondition/termsandcondition";
import Mpin from "./pages/mpin/Mpin";
import Setmpin from "./pages/setmpin/Setmpin";

import Showcase from "./pages/showcase/Showcase";
import Verifyscreen from "./pages/verify/Verifyscreen";

import Mynfb from "./pages/mynfb/Mynfb";
import Intrests from "./pages/intrests/Intrests";
import HomeNew from "./pages/homenew/HomeNew";
import Createclub from "./pages/createclub/CreateClub";
import CreateProduct from "./pages/createproduct/CreateProduct";
import Auction from "./pages/auction/Auction";
import ReceivedNfbs from "./pages/receivednfbs/ReceivedNfbs";
import Sponsors from "./pages/sponsors/Sponsors";
import SpecialBenefits from "./pages/specialbenefits/SpecialBenefits";
import FlipCard from "./pages/flipcard/FlipCard";
import Benefits from "./pages/benefits/Benefits";
import ProductView from "./pages/productview/Productview";
import Mymemberships from "./pages/mymemberships/Mymemberships";
import Benefitsoffered from "./pages/benefitsoffered/Benefitsoffered";
import Assetsapproval from "./pages/assetsapproval/Assetsapproval";
import Resellnfb from "./pages/resellnfb/Resellnfb";
import Releasednfb from "./pages/releasednfb/Releasednfb";
import Wishlist from "./pages/wishlist/Wishlist";
import UserProfile from "./pages/userprofile/UserProfile";
import Sidemenu from "./common/sidemenu/Sidemenu";
import Clubprofile from "./pages/clubprofile/Clubprofile";
import PrivateRoutes from "./utils/PrivateRoutes";
import RoyaltyNfbs from "./pages/royltynfbs/RoyaltyNfbs";
import RoyaltyNfbs2 from "./pages/royltynfbs2/RoyaltyNfbs2";
import TopCreators from "./pages/TopCreators";

// keyur 28-08-2023
import Placebid from "./pages/placebid/Placebid";
import Homesearch from "./pages/homesearch/Homesearch";
import Bondissue from "./pages/bondissues/Bondissue";
import Actionneed from "./pages/actionneed/Actionneed";
import Actionneed2 from "./pages/actionneed2/Actionneed2";
import UpdateIntrests from "./pages/updateintrests/UpdateIntrests";
// import Launchednfb from "./pages/launchednfb/Launchednfb";

// 31-08 -vishal
import Selectmanagers from "./pages/selectmanagers/Selectmanagers";
import Viewmanagers from "./pages/viewmanagers/Viewmanagers";
import Editemail from "./pages/editemail/Editemail";
import Editmobile from "./pages/editmobile/Editmobile";
import Editmobileotp from "./pages/editmobileotp/Editmobileotp";
import Editmailotp from "./pages/editemailotp/Editemailotp";

// 01-09 -dk

import Createbenefit from "./pages/createbenefit/Createbenefit";
import Addcontact from "./pages/addcontact/Addcontact";
import Createnfbs from "./pages/createnfb2/Createnfbs";
// 02-09 -dk
import Bidlist from "./pages/bidlist/Bidlist";
import Attachbenefit from "./pages/attachbenefit/Attachbenefit";
// 04-09 -dk
import Clubmenbers from "./pages/clubmenbers/Clubmenbers";
import Clubmembership from "./pages/clubmembership/Clubmembership";
import Collaboratenfb from "./pages/collaboratenfb/Collaboratenfb";
import Productviewlist from "./pages/productviewlist/Productviewlist";
// 05-09 -2023 vishal
import Bidscreate from "./pages/bidcreate/Bidscreate";
import Bondbox from "./pages/bondbox/Bondbox";
import Benefitshow from "./pages/benefitshow/Benefitshow";
import Approvalcreate from "./pages/approvalcreate/Approvalcreate";
import Keyur from "./pages/keyur/Keyur";
// 12-09 -2023 vishal
import Resellcreateproduct from "./pages/resellcreateproduct/Resellcreateproduct";
import ClubOtp from "./pages/clubotp/ClubOtp";
// 13-09-2023

import Report from "./pages/report/report";
import Clubsetting from "./pages/clubsetting/Clubsetting";
import Clubbankaccount from "./pages/clubbankaccount/Clubbankaccount";
import Userbankaccount from "./pages/userbankaccount/Userbankaccount";
import Editclub from "./pages/editclub/Editclub";
import NewClubRecordedVideo from "./pages/editclub/NewClubRecordedVideo";
import Clubmanager from "./pages/clubmanager/Clubmanager";
import Addowners from "./pages/addowners/Addowners";

import ClubCreateProduct from "./pages/clubcreateproduct/ClubCreateProduct";
import Mybidslist from "./pages/mybidslist/Mybidslist";
import Tradehistory from "./pages/tradehistory/Tradehistory";
import Editmyproduct from "./pages/editmyproduct/Editmyproduct";
import Aadharcard from "./pages/aadharcard/Aadharcard";
import Purchasednfbs from "./pages/purchasednfbs/Purchasednfbs";
import Aadharcardotp from "./pages/aadharcardotp/aadharcardotp";

// new app
import Qrhuntlogin from "./pages/qrhuntlogin/Qrhuntlogin";
import ClubRequest from "./pages/clubrequest/ClubRequest";
import Cluballnfb from "./pages/cluballnfb/Cluballnfb";
import Exclusiveclubnfb from "./pages/exclusiveclub/Exclusiveclubnfb";
import Royltyclubnfb from "./pages/royltyclubnfb/Royltyclubnfb";
import Auction2 from "./pages/auction2/Auction2";
import ClubActionNeed from "./pages/clubactionneed/ClubActionNeed";
import Clubmyproduct from "./pages/clubmyproduct/Clubmyproduct";
import Royltyclubsecound from "./pages/royaltyclubsecound/Royaltyclubsecound";
import Createclubbenefit from "./pages/createclubbenifit/createclubbenifit";
import ClubCollaboratenfb from "./pages/ClubCollaboratenfb";
import ClubCreatenfbs from "./pages/ClubCreatenfbs";
import ClubBondissue from "./pages/ClubBondissue";
import ClubBondbox from "./pages/ClubBondbox";
import ClubAttachbenefit from "./pages/ClubAttachbenefit";
import Resellnewattach from "./pages/Resellnewattach/Resellnewattach";
import ResellAttachbenefit from "./pages/ResellAttachbenefit";
import ResellCreatebenefit from "./pages/ResellCreateBenefit";
import ResellActionneed from "./pages/ResellActionneed";
import Mynfbcollection from "./pages/mynfbcollection/Mynfbcollection";
import Resellbidslist from "./pages/Resellbidslist";
import Clubreleasednfb from "./pages/clubreleasednfb/Clubreleasenfb";
import Splashscreen from "./pages/splashscreen/Splashscreen";
import AllFollower from "./pages/AllFollower";
import AllFollowing from "./pages/AllFollowing";
import ClubEmailOtp from "./pages/ClubEmailOtp";
import AttachVideos from "./pages/attachvideos/AttachVideos";
import FeedVideo from "./pages/FeedVideo";
import ClubReceivedNfb from "./pages/ClubReceivedNfb";
import ForgatePassOtp from "./pages/ForgatePassOtp";
import ForgateNewPass from "./pages/ForgateNewPass";
import AllTopClub from "./pages/AllTopClub";
import Releasedattachbenefit from "./pages/releasedattachbenefit/Releasedattachbenefit";
import ReportPage from "./pages/ReportPage";
import ViewBenefits from "./pages/benefits/Viewbenifit";
import Legal from "./pages/mynfb/Legal";
import PrivacyPolicy from "./pages/termsandcondition/PrivacyPolicy";
import Refund from "./pages/termsandcondition/Refund";
import ContactUs from "./pages/termsandcondition/ContectUs";
import ProductViewImg from "./pages/productViewImg/ProductViewImg";
import ProductView2 from "./pages/productview2/ProductView2";
import Clubmembers from "./pages/clubmenbers/Clubmenbers";
import { useEffect } from "react";
import SocialSignUp from "./pages/SocialSignUp";
import AutherVideo from "./pages/authervideo/AutherVideo";
import ListOfBankAccounts from "./pages/listofbankaccounts/ListOfBankAccounts";
import ClubListOfBankAccounts from "./pages/clublistofbankaccount/ClubListOfBankAccounts";
import Cluballfollowers from "./pages/Cluballfollowers";
import ProductViewImg2 from "./pages/productViewImg/productViewImg2";
import ClubActionneed2 from "./pages/ClubActionneed2";
import ClubMybidslist from "./pages/ClubMybidslist";
import ProductView3 from "./pages/productview3/ProductView3";
import ProductView4 from "./pages/productview3/ProductView4";
import CirtificateViewImg from "./pages/CirtificateViewImg";
import QrUserProfile from "./pages/QrUserProfile";
import EditShareAuction from "./pages/editshareauction/EditShareAuction";
import PanCard from "./pages/aadharcard/PanCard";
import Gst from "./pages/aadharcard/Gst";
import Succcess from "./pages/Success";
import Fail from "./pages/Fail";
import MyOrders from "./pages/myorders/MyOrders";
import SelectMerchent from "./pages/selectmerchent/SelectMerchent";
import BuyProduct from "./pages/buyproduct/BuyProduct";
import OrderConfirmation from "./pages/orderconfirmation/OrderConfirmation";
import AddressForProductBuy from "./pages/addressforProductBuy/AddressForProductBuy";
import ShippingPolicy from "./pages/shippingPolicy";
import ClubMamberRequsts from "./pages/clubmamberrequsts/ClubMamberRequsts";
import HistoryRarerez from "./pages/historyRarerez/HistoryRarerez";
import ProductviewAuction from "./pages/productview/ProductviewAuction";
import AssetImgRequest from "./pages/assetimagerequest/AssetImgRequest";
import ClubSuccess from "./pages/ClubSuccess";
import ClubFail from "./pages/ClubFail";
import Newbankaccountadd from "./pages/Newbankaccountadd";
import Internationaluserlist from "./pages/Internationaluserlist";
import Internationaluserrequest from "./pages/Internationaluserrequest";
import Acceptinternationaluser from "./pages/Acceptinternationaluser";
import FreeBenefit from "./pages/freebenefit/FreeBenefit";

// import QrUserProfile from "./pages/QrUserProfile";
// import Clubreleasednfb from "./pages/Clubreleasenfb";

// -------------------------------- new files start ------------------------------------------------------ //

import NewPlayScreen from "./pages/newplayscreen/NewPlayScreen";
import NewOtpScreen from "./pages/newotpscreen/NewOtpScreen";
import NewVerifyOtp from "./pages/newverifyotp/NewVerifyOtp";
import NewMobileOtpSuccess from "./pages/newmobileotpsuccess/NewMobileOtpSuccess";
import NewEmailVerification from "./pages/newemailverification/NewEmailVerification";
import NewEmailOtpVerify from "./pages/newemailotpverify/NewEmailOtpVerify";
import NewEmailOtpSuccess from "./pages/newmobileotpsuccess/NewEmailOtpSuccess";
import NewSetPassword from "./pages/newsetpassword/NewSetPassword";
import NewAadhaarCard from "./pages/newaadhaarcard/NewAadhaarCard";
import NewShowUserDetail from "./pages/newshowuserdetail/NewShowUserDetail";
import NewPancardScreen from "./pages/newpancardscreen/NewPancardScreen";
import NewGstScreen from "./pages/newgstscreen/NewGstScreen";
import NewFounderVideoScreen from "./pages/newfoundervideoscreen/NewFounderVideoScreen";
import NewUploadAutherVideoScreen from "./pages/newuploadauthorvideoscreen/NewUploadAutherVideoScreen";
import NewLoginScreen from "./pages/newloginscreen/NewLoginScreen";
import NewForgotPassword from "./pages/newforgotpassword/NewForgotPassword";
import NewForgotOtpScreen from "./pages/newforgototpscreen/NewForgotOtpScreen";
import NewChangePasswordScreen from "./pages/newchangepasswordscreen/NewChangePasswordScreen";
import NewInternationalInvite from "./pages/newinternationalinvite/NewInternationalInvite";
import NewInternationalInviteForm from "./pages/newinternationalinviteform/NewInternationalInviteForm";
import NewinternationalInviteSuccess from "./pages/newmobileotpsuccess/NewinternationalInviteSuccess";
import NewAddharCardOtpScreen from "./pages/newAddharCardOtpScreen/NewAddharCardOtpScreen";
import NewAddhharSuccess from "./pages/newmobileotpsuccess/NewAddhharSuccess";
import NewInterests from "./pages/newinterests/NewInterests";
import NewProtectAsset from "./pages/newprotectasset/NewProtectAsset";
import Feed3 from "./pages/feed2/Feed3";
import CreatenfbsNew2 from "./pages/createnfb2/CreatenfbsNew2";
import NewUserMenu from "./pages/newusermenu/NewUserMenu";
import NewSideMenu from "./pages/newsidemenu/NewSideMenu";
import NewHomePage from "./pages/newhomepage/NewHomePage";
import NewMyProduct from "./pages/newmyproduct/NewMyProduct";
import Bids_13 from "./pages/newbidsscreen/Bids_13";
import Recievedinvitaion_1 from "./pages/NewRecievedinvitaion/Recievedinvitaion1";
import Recievedinvitaion1 from "./pages/NewRecievedinvitaion/Recievedinvitaion1";
import Recievedinvitaion2 from "./pages/NewRecievedinvitaion/Recievedinvitaion2";
import NewInternationalStripePage from "./pages/newinternationalstipepage/NewInternationalStripePage";
import NewEditProtectAsset from "./pages/newprotectasset/NewEditProtectAsset";
import NewInternationalEmailOtp from "./pages/NewRecievedinvitaion/NewInternationalEmailOtp";
import NewInternationalPassword from "./pages/newinternationalpassword/NewInternationalPassword";
import NewInterNationalUserDetails from "./pages/newinternationuserdetails/NewInterNationalUserDetails";
import NewInternationalStripeSuccess from "./pages/newmobileotpsuccess/NewInternationalStripeSuccess";
import NewClubProtectAsset from "./pages/newprotectasset/NewClubProtectAsset";
import NewUserCreateBenefit from "./pages/newusercreatebenefit/NewUserCreateBenefit";
import NewClubMyProduct from "./pages/clubmyproduct/NewClubMyProduct";
import NewEditClubProduct from "./pages/newprotectasset/NewEditClubProduct";
import LegacyDashbord from "./pages/lagecydashbord/LagecyDashbord";
import CreateLegacy from "./pages/createlegacy/CreateLegacy";
import CreateMilestone from "./pages/createmilestone/CreateMilestone";
import NewLegacyProtectAsset from "./pages/newprotectasset/NewLegacyProtectAsset";

import MyCommunity from "./pages/newmycommunity/MyCommunity";
import MyFamily from "./pages/newmycommunity/MyFamily";
import NewCommunityRequest from "./pages/newcommunityrequest/NewCommunityRequest";
import NewFamilyRequest from "./pages/newcommunityrequest/NewFamilyRequest";
import MilestoneDashboard from "./pages/milestonedashboard/MilestoneDashboard";
import ShowAllFamilyMember from "./pages/showallfamilymember/ShowAllFamilyMember";
import ShowAllCommunity from "./pages/showallfamilymember/ShowAllCommunity";
import ShowAllMilestones from "./pages/showallfamilymember/ShowAllMilestones";
import MilestoneProductView from "./pages/productview/MilestoneProductView";
import NewlegacyActionneed2 from "./pages/actionneed2/NewlegacyActionneed2";
import NewProductView from "./pages/newproductview/NewProductView";
import NewApprovalClubCreate from "./pages/approvalcreate/NewApprovalClubCreate";
import NewApprovalCreate from "./pages/approvalcreate/NewApprovalCreate";
import CWLeaderBord from "./pages/cwleaderbord/CWLeaderBord";
import NewCreateClub from "./pages/newcreateclub/NewCreateClub";
import MyCAndW from "./pages/mycandw/MyCAndW";
import CreateCandW from "./pages/createcandw/CreateCandW";
import SelectCandWBond from "./pages/selectcandwbond/SelectCandWBond";
import NewEditProfile from "./pages/editprofile/NewEditProfile";
import NewUserRecordedVideo from "./pages/editprofile/NewUserRecordedVideo";
import ProofIdentity from "./pages/proofIdentity/ProofIdentity";
import NewOpeningScreen from "./pages/newopeningscreen/NewOpeningScreen";
import { genarateToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoginVerifyOTP from "./pages/newverifyotp/NewLoginVerifyOTP";
import Urls from "./utils/Urls";
import SuccessUpdatebid from "./pages/SuccessUpdatebid";
import ProductViewImg3 from "./pages/productViewImg/ProductViewImg3";
import ActionViewImg from "./pages/ActionViewImg";
// -------------------------------- new files end ------------------------------------------------------ //
import { ParallaxProvider } from "react-scroll-parallax";
import UserChargeScreen from "./pages/userchargescreen/UserChargeScreen";
import NewIndianStipeSuccess from "./pages/newmobileotpsuccess/NewIndianStipeSuccess";
import NewAddBalanceSucess from "./pages/newmobileotpsuccess/NewAddBalanceSucess";

function App() {
  // useEffect(() => {
  //   genarateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log("acdsdc", payload.notification.body);
  //     toast.info(payload.notification.body, { theme: "colored" });
  //   });
  // }, []);

  // useEffect(() => {
  //   genarateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log("Notification-->", payload.notification.body);
  //     toast.info(payload.notification.body, {
  //       theme: "colored",
  //       onClick: () => {
  //         window.location.href = Urls.main_url + "notification";
  //       },
  //     });
  //   });
  // });

  useEffect(() => {
    genarateToken();

    // Set up onMessage listener once here
    onMessage(messaging, (payload) => {
      console.log("Notification-->", payload.notification.body);
      toast.info(payload.notification.body, {
        theme: "colored",
        onClick: () => {
          window.location.href = Urls.main_url + "notification";
        },
      });
    });
  });

  return (
    // <BrowserRouter basename="/web">
    <ParallaxProvider>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          {/* <Route path="/" element={<Splashscreen />} /> */}
          <Route
            path="/:accept_bond_bid_id3/:release_bond_id3/:user_id3/:is_nfb3/:is_club3/:is_share3/:uniq_code3"
            element={<ProductView3 />}
          />
          <Route path="/:accept_bond_bid_id3" element={<ProductView4 />} />
          <Route path="/user/:userid3" element={<QrUserProfile />} />

          {/* ----------------- */}
          {/* <Route path="/NewPlayScreen" element={<NewPlayScreen />} /> */}
          <Route path="/NewOtpScreen" element={<NewOtpScreen />} />
          <Route path="/NewVerifyOtp" element={<NewVerifyOtp />} />
          <Route path="/NewLoginVerifyOTP" element={<NewLoginVerifyOTP />} />
          <Route
            path="/NewMobileOtpSuccess"
            element={<NewMobileOtpSuccess />}
          />
          <Route path="/NewEmailOtpSuccess" element={<NewEmailOtpSuccess />} />
          <Route path="/NewEmailOtpVerify" element={<NewEmailOtpVerify />} />
          <Route path="/NewSetPassword" element={<NewSetPassword />} />
          <Route path="/NewAadhaarCard" element={<NewAadhaarCard />} />
          <Route path="/NewShowUserDetail" element={<NewShowUserDetail />} />
          <Route path="/NewPancardScreen" element={<NewPancardScreen />} />
          <Route path="/NewGstScreen" element={<NewGstScreen />} />
          {/* <Route path="/NewLoginScreen" element={<NewLoginScreen />} /> */}
          <Route path="/NewForgotPassword" element={<NewForgotPassword />} />
          <Route path="/NewForgotOtpScreen" element={<NewForgotOtpScreen />} />
          <Route path="/NewInterests" element={<NewInterests />} />

          <Route
            path="/NewChangePasswordScreen"
            element={<NewChangePasswordScreen />}
          />
          <Route
            path="/NewEmailVerification"
            element={<NewEmailVerification />}
          />
          <Route
            path="/NewUploadAutherVideoScreen"
            element={<NewUploadAutherVideoScreen />}
          />
          <Route
            path="/NewFounderVideoScreen"
            element={<NewFounderVideoScreen />}
          />
          <Route
            path="/NewAddharCardOtpScreen"
            element={<NewAddharCardOtpScreen />}
          />
          <Route path="/NewAddhharSuccess" element={<NewAddhharSuccess />} />
          <Route path="/ProofIdentity" element={<ProofIdentity />} />
          <Route path="/NewOpeningScreen" element={<NewOpeningScreen />} />
          {/* recived international user invite strat */}

          <Route
            path="/international/:user_id"
            element={<Recievedinvitaion1 />}
          />
          <Route path="/Recievedinvitaion2" element={<Recievedinvitaion2 />} />
          <Route
            path="/NewInternationalStripePage"
            element={<NewInternationalStripePage />}
          />

          <Route
            path="/NewInternationalStripeSuccess"
            element={<NewInternationalStripeSuccess />}
          />

          <Route
            path="/NewInternationalEmailOtp"
            element={<NewInternationalEmailOtp />}
          />

          <Route
            path="/NewInternationalPassword"
            element={<NewInternationalPassword />}
          />

          <Route
            path="/NewInterNationalUserDetails"
            element={<NewInterNationalUserDetails />}
          />

          {/* recived international user invite end */}

          {/* ----------------- */}

          {/* new paths start */}
          <Route path="/" element={<NewOpeningScreen />} />
          <Route path="/NewPlayScreen" element={<NewPlayScreen />} />
          <Route path="/login" element={<NewLoginScreen />} />

          {/* new paths end */}

          <Route path="/ProductViewImg" element={<ProductViewImg />} />
          <Route path="/ProductViewImg3" element={<ProductViewImg3 />} />
          <Route path="/CirtificateViewImg" element={<CirtificateViewImg />} />
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/Newbankaccountadd" element={<Newbankaccountadd />} />
          <Route
            path="/Internationaluserlist"
            element={<Internationaluserlist />}
          />
          <Route
            path="/Internationaluserrequest"
            element={<Internationaluserrequest />}
          />
          <Route
            path="/Acceptinternationaluser"
            element={<Acceptinternationaluser />}
          />
          {/* <Route
          path="/:accept_bond_bid_id3/:is_nfb3/:is_club3/:is_share3"
          element={<ProductView3 />}
        /> */}
          <Route path="/forgot_pass" element={<Forgot />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verifyscreen" element={<Verifyscreen />} />
          <Route path="/forgateotp" element={<ForgatePassOtp />} />
          <Route path="/ForgateNewPass" element={<ForgateNewPass />} />
          <Route path="/SocialSignUp" element={<SocialSignUp />} />
          <Route path="/AutherVideo" element={<AutherVideo />} />
          <Route path="/Intrests" element={<Intrests />} />
          <Route path="/aadharcard" element={<Aadharcard />} />
          <Route path="/aadharcardotp" element={<Aadharcardotp />} />
          <Route path="/PanCard" element={<PanCard />} />
          <Route path="/Gst" element={<Gst />} />
          <Route path="/Success" element={<Succcess />} />
          <Route path="/Fail" element={<Fail />} />
          <Route path="/MyOrders" element={<MyOrders />} />
          <Route path="/SelectMerchent" element={<SelectMerchent />} />
          <Route path="/BuyProduct" element={<BuyProduct />} />
          <Route path="/OrderConfirmation" element={<OrderConfirmation />} />
          <Route
            path="/AddressForProductBuy"
            element={<AddressForProductBuy />}
          />
          <Route path="/UserChargeScreen" element={<UserChargeScreen />} />
          <Route
            path="/NewIndianStipeSuccess"
            element={<NewIndianStipeSuccess />}
          />
          <Route element={<PrivateRoutes />}>
            {/* ------------- new files start ---------------- */}
            {/* <Route path="/NewProtectAsset" element={<NewProtectAsset />} /> */}
            <Route path="/NewUserMenu" element={<NewUserMenu />} />
            <Route path="/NewSideMenu" element={<NewSideMenu />} />
            <Route path="/NewHomePage" element={<NewHomePage />} />

            <Route
              path="/NewAddBalanceSucess"
              element={<NewAddBalanceSucess />}
            />

            <Route
              path="/NewinternationalInviteSuccess"
              element={<NewinternationalInviteSuccess />}
            />
            <Route
              path="/NewInternationalInviteForm"
              element={<NewInternationalInviteForm />}
            />
            <Route
              path="/NewInternationalInvite"
              element={<NewInternationalInvite />}
            />
            <Route
              path="/NewUserCreateBenefit"
              element={<NewUserCreateBenefit />}
            />
            <Route
              path="/NewApprovalClubCreate"
              element={<NewApprovalClubCreate />}
            />
            <Route path="/NewApprovalCreate" element={<NewApprovalCreate />} />

            {/* lagecy start */}

            <Route path="/LegacyDashbord" element={<LegacyDashbord />} />
            <Route path="/CreateLegacy" element={<CreateLegacy />} />
            <Route path="/CreateMilestone" element={<CreateMilestone />} />
            <Route path="/MyCommunity" element={<MyCommunity />} />
            <Route path="/MyFamily" element={<MyFamily />} />
            <Route
              path="/NewCommunityRequest"
              element={<NewCommunityRequest />}
            />
            <Route path="/NewFamilyRequest" element={<NewFamilyRequest />} />
            <Route
              path="/NewLegacyProtectAsset"
              element={<NewLegacyProtectAsset />}
            />
            <Route
              path="/ShowAllFamilyMember"
              element={<ShowAllFamilyMember />}
            />
            <Route path="/ShowAllCommunity" element={<ShowAllCommunity />} />
            <Route path="/ShowAllMilestones" element={<ShowAllMilestones />} />
            <Route
              path="/NewlegacyActionneed2"
              element={<NewlegacyActionneed2 />}
            />
            <Route path="/CWLeaderBord" element={<CWLeaderBord />} />
            {/* lagecy end */}

            {/* c and w start */}
            <Route path="/MyCAndW" element={<MyCAndW />} />
            <Route path="/CreateCandW" element={<CreateCandW />} />
            <Route path="/SelectCandWBond" element={<SelectCandWBond />} />
            {/* c and w end */}

            {/* ------------- new files end ---------------- */}

            <Route path="/ClubSuccess" element={<ClubSuccess />} />
            <Route path="/ClubFail" element={<ClubFail />} />
            {/* <Route path="/usermenu" element={<Usermenu />} /> */}
            <Route path="/usermenu" element={<NewUserMenu />} />
            {/* <Route path="/homescreen" element={<HomeScreen />} /> */}
            {/* <Route path="/homescreen2" element={<HomeNew />} /> */}
            <Route path="/homescreen2" element={<NewHomePage />} />
            {/* <Route path="/creatednfbs" element={<Creatednfbs />} /> */}
            {/* <Route path="/feed" element={<Feed />} /> */}
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/club_wallet" element={<Clubwallet />} />
            <Route path="/myproduct" element={<NewMyProduct />} />
            {/* <Route path="/myproduct" element={<Myproduct />} /> */}
            <Route path="/bids" element={<Bids_13 />} />
            {/* <Route path="/bids" element={<Bids />} /> */}
            <Route path="/launchednfb" element={<Launchednfb />} />
            <Route path="/bidsall" element={<Bidsall />} />
            <Route path="/placebid" element={<Placebid />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/partnerbenefits" element={<Partnerbenefits />} />
            {/* user menu */}
            <Route path="/notification" element={<Notification />} />
            <Route path="/feed" element={<Feed3 />} />
            {/* <Route path="/feed" element={<Feed2 />} /> */}
            <Route path="/editprofile" element={<NewEditProfile />} />
            {/* <Route path="/NewEditProfile" element={<NewEditProfile />} /> */}
            <Route
              path="/NewUserRecordedVideo"
              element={<NewUserRecordedVideo />}
            />
            <Route path="/userqr" element={<Userqr />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/mpin" element={<Mpin />} />
            <Route path="/setmpin" element={<Setmpin />} />
            <Route path="/termsandcondition" element={<Termsandcondition />} />

            {/* jalpesh */}
            {/* <Route path="/CreateProduct" element={<CreateProduct />} /> */}

            <Route path="/CreateProduct" element={<NewProtectAsset />} />
            <Route path="/EditProduct" element={<NewEditProtectAsset />} />

            {/* collection */}
            <Route path="/showcase" element={<Showcase />} />

            <Route path="/mynfb" element={<Mynfb />} />
            <Route path="/Createclub" element={<NewCreateClub />} />
            {/* <Route path="/NewCreateClub" element={<NewCreateClub />} /> */}
            <Route path="/Auction" element={<Auction />} />
            <Route path="/ReceivedNfbs" element={<ReceivedNfbs />} />
            <Route path="/Sponsors" element={<Sponsors />} />
            <Route path="/SpecialBenefits" element={<SpecialBenefits />} />
            <Route path="/FlipCard" element={<FlipCard />} />
            {/* <Route path="/launchednfb" element={<Launchednfb />} /> */}
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/productview" element={<ProductView />} />
            <Route path="/NewProductView" element={<NewProductView />} />
            <Route
              path="/MilestoneProductView"
              element={<MilestoneProductView />}
            />
            <Route path="/mymemberships" element={<Mymemberships />} />
            <Route path="/benefitsoffered" element={<Benefitsoffered />} />
            <Route path="/assetsapproval" element={<Assetsapproval />} />
            <Route path="/resellnfb" element={<Resellnfb />} />
            <Route path="/releasednfb" element={<Releasednfb />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/Sidemenu" element={<NewSideMenu />} />
            {/* 27-08-2023 */}
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/Clubprofile" element={<Clubprofile />} />
            <Route path="/RoyaltyNfbs" element={<RoyaltyNfbs />} />
            <Route path="/RoyaltyNfbs2" element={<RoyaltyNfbs2 />} />
            <Route path="/TopCreators" element={<TopCreators />} />
            <Route path="/homesearch" element={<Homesearch />} />
            <Route path="/bondissue" element={<Bondissue />} />

            <Route path="/actionneed" element={<Actionneed />} />
            <Route path="/actionneed2" element={<Actionneed2 />} />
            <Route path="/UpdateIntrests" element={<UpdateIntrests />} />

            <Route path="/selectmanagers" element={<Selectmanagers />} />
            <Route path="/viewmanagers" element={<Viewmanagers />} />
            <Route path="/editemail" element={<Editemail />} />
            <Route path="/editmobile" element={<Editmobile />} />
            <Route path="/editmobileotp" element={<Editmobileotp />} />
            <Route path="/editmailotp" element={<Editmailotp />} />

            {/* 31-08 - dk*/}
            <Route path="/createbenefit" element={<Createbenefit />} />
            <Route path="/addcontact" element={<Addcontact />} />
            <Route path="/createnfbs" element={<CreatenfbsNew2 />} />
            {/* 02-09-2023 */}
            <Route path="/bidlist" element={<Bidlist />} />
            <Route path="/attachbenefit" element={<Attachbenefit />} />
            {/* 04-09-2023 */}
            <Route path="/clubmenbers" element={<Clubmenbers />} />
            <Route path="/clubmembership" element={<Clubmembership />} />
            <Route path="/clubmembers" element={<Clubmembers />} />
            <Route path="/collaboratenfb" element={<Collaboratenfb />} />
            <Route path="/productviewlist" element={<Productviewlist />} />
            {/* 05-09-2023 */}
            <Route path="/bidscreate" element={<Bidscreate />} />
            <Route path="/bondbox" element={<Bondbox />} />
            <Route path="/benefitshow" element={<Benefitshow />} />
            {/* 09-09-2023 */}
            <Route path="/approvalcreate" element={<Approvalcreate />} />
            <Route path="/keyur" element={<Keyur />} />
            {/* 09-09-2023 */}

            <Route
              path="/resellcreateproduct"
              element={<Resellcreateproduct />}
            />
            <Route path="/ClubOtp" element={<ClubOtp />} />
            {/* 13-09-2023 */}
            <Route path="/report" element={<Report />} />
            <Route path="/clubsetting" element={<Clubsetting />} />
            <Route path="/clubbankaccount" element={<Clubbankaccount />} />
            <Route path="/userbankaccount" element={<Userbankaccount />} />
            <Route path="/editclub" element={<Editclub />} />
            <Route path="/clubmanager" element={<Clubmanager />} />
            <Route path="/addowners" element={<Addowners />} />
            <Route path="/mybidslist" element={<Mybidslist />} />
            <Route path="/tradehistory" element={<Tradehistory />} />

            {/* jalpesh */}

            {/* <Route path="/ClubCreateProduct" element={<ClubCreateProduct />} /> */}
            <Route
              path="/NewEditClubProduct"
              element={<NewEditClubProduct />}
            />
            <Route
              path="/ClubCreateProduct"
              element={<NewClubProtectAsset />}
            />
            <Route path="/editmyproduct" element={<Editmyproduct />} />
            {/* 15-09-2023 */}

            <Route path="/purchasednfbs" element={<Purchasednfbs />} />

            <Route path="/clubrequest" element={<ClubRequest />} />
            {/* 18-09-2023 */}
            <Route path="/cluballnfb" element={<Cluballnfb />} />
            <Route path="/exclusiveclubnfb" element={<Exclusiveclubnfb />} />
            <Route path="/royltyclubnfb" element={<Royltyclubnfb />} />
            <Route path="/auction2" element={<Auction2 />} />
            <Route path="/ClubActionNeed" element={<ClubActionNeed />} />
            <Route path="/clubmyproduct" element={<NewClubMyProduct />} />
            {/* <Route path="/clubmyproduct" element={<Clubmyproduct />} /> */}
            <Route path="/royaltyclubsecound" element={<Royltyclubsecound />} />
            {/* <Route path="/createclubbenifit" element={<Createclubbenefit />} /> */}
            {/* -20-09-2023 */}
            <Route
              path="/ClubCollaboratenfb"
              element={<ClubCollaboratenfb />}
            />
            <Route path="/ClubCreatenfbs" element={<ClubCreatenfbs />} />
            <Route path="/ClubBondissue" element={<ClubBondissue />} />
            <Route path="/ClubBondbox" element={<ClubBondbox />} />
            <Route path="/Createclubbenefit" element={<Createclubbenefit />} />
            <Route path="/ClubAttachbenefit" element={<ClubAttachbenefit />} />

            <Route path="/Resellnewattach" element={<Resellnewattach />} />
            <Route
              path="/ResellAttachbenefit"
              element={<ResellAttachbenefit />}
            />
            <Route
              path="/ResellCreatebenefit"
              element={<ResellCreatebenefit />}
            />
            <Route path="/ResellActionneed" element={<ResellActionneed />} />
            <Route path="/mynfbcollection" element={<Mynfbcollection />} />
            {/* 25-09-2023 */}
            <Route path="/Resellbidslist" element={<Resellbidslist />} />
            {/* 26-09-2023 */}
            <Route path="/clubreleasednfb" element={<Clubreleasednfb />} />
            {/* 29-09-2023 */}
            <Route path="/AllFollower" element={<AllFollower />} />
            <Route path="/AllFollowing" element={<AllFollowing />} />
            <Route path="/ClubEmailOtp" element={<ClubEmailOtp />} />
            {/* 29-09-2023 */}
            {/* <Route path="/Clubreleasednfb" element={<Clubreleasednfb />}/> */}
            {/* new app */}
            <Route path="/qrhuntlogin" element={<Qrhuntlogin />} />
            <Route path="/AttachVideos" element={<AttachVideos />} />
            <Route path="/FeedVideo" element={<FeedVideo />} />
            <Route path="/ClubReceivedNfb" element={<ClubReceivedNfb />} />
            <Route path="/AllTopClub" element={<AllTopClub />} />
            <Route
              path="/Releasedattachbenefit"
              element={<Releasedattachbenefit />}
            />
            <Route path="/ReportPage" element={<ReportPage />} />
            <Route path="/ViewBenefits" element={<ViewBenefits />} />
            <Route path="/Legal" element={<Legal />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Refund" element={<Refund />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/ProductViewImg" element={<ProductViewImg />} />
            <Route path="/ProductView2" element={<ProductView2 />} />
            <Route
              path="/ListOfBankAccounts"
              element={<ListOfBankAccounts />}
            />
            <Route
              path="/ClubListOfBankAccounts"
              element={<ClubListOfBankAccounts />}
            />
            <Route path="/Cluballfollowers" element={<Cluballfollowers />} />
            <Route path="/ProductViewImg2" element={<ProductViewImg2 />} />
            <Route path="/ClubActionneed2" element={<ClubActionneed2 />} />
            <Route path="/ClubMybidslist" element={<ClubMybidslist />} />
            <Route path="/EditShareAuction" element={<EditShareAuction />} />
            <Route path="/shippingPolicy" element={<ShippingPolicy />} />
            <Route path="/ClubMamberRequsts" element={<ClubMamberRequsts />} />
            <Route path="/HistoryRarerez" element={<HistoryRarerez />} />
            <Route
              path="/ProductviewAuction"
              element={<ProductviewAuction />}
            />
            <Route path="/AssetImgRequest" element={<AssetImgRequest />} />
            <Route path="/FreeBenefit" element={<FreeBenefit />} />
            <Route
              path="/MilestoneDashboard"
              element={<MilestoneDashboard />}
            />
            <Route path="/SuccessUpdatebid" element={<SuccessUpdatebid />} />
            <Route path="/ActionViewImg" element={<ActionViewImg />} />
            <Route
              path="/NewClubRecordedVideo"
              element={<NewClubRecordedVideo />}
            />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
