import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "./assets/fonts/Nexa_bold.otf";
// import "./assets/fonts/Nexa_light.otf";
import { UserProvider } from "./context/user_context";
import { UserInterestProvider } from "./context/nfb_interests_context";
import { HomeProvider } from "./context/home_context";
import { ProfileProvider } from "./context/profile_context";
import { UserBlockclubProvider } from "./context/nfb_blockclub_context";
import { UserBlockbidProvider } from "./context/nfb_blockbid_context";
import { UserWalletProvider } from "./context/user_wallet_context";
import "swiper/swiper-bundle.css";
import { useEffect } from "react";

import { genarateToken, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

// function Appqq() {
//   useEffect(() => {
//     genarateToken();
//     onMessage(messaging, (payload) => {
//       console.log("acdsdc", payload.notification.body);
//       toast.info(payload.notification.body, { theme: "dark" });
//     });
//   });
// }
// export default Appqq;

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered successfully:", registration);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

root.render(
  <React.StrictMode>
    <UserProvider>
      <UserInterestProvider>
        <HomeProvider>
          <ProfileProvider>
            <UserBlockclubProvider>
              <UserBlockbidProvider>
                <UserWalletProvider>
                  <>
                    <App />
                    <ToastContainer />
                  </>
                </UserWalletProvider>
              </UserBlockbidProvider>
            </UserBlockclubProvider>
          </ProfileProvider>
        </HomeProvider>
      </UserInterestProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
