import React, { useEffect, useState } from "react";
import "./cluballnfb.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";

const Cluballnfb = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [clubId, setClubId] = useState("");
  const location = useLocation();

  const getData = () => {
    setIsLoading(true);
    const param12 = {
      club_id: location.state.club_id,
    };

    console.log("====================================");
    console.log("par", param12);
    console.log("====================================");
    axios
      .post(
        "https://www.ravi.host/api/club_get_all_release_bond",
        qs.stringify(param12)
      )
      .then((response) => {
        setIsLoading(false);

        console.log("====================================");
        console.log(
          "Response.data.result--333->",
          JSON.stringify(response.data.result, null, 2)
        );
        console.log("====================================");

        if (response.data.status === 1) {
          setProducts(response.data.result);
          setClubId(response.data.club_id);
        } else {
          // alert(response.data.message);
        }

        console.log("Response============.", response.data.club_id);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();
  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2">
          <NavbarSec name="" />
          <div className="cluballnfb_main_details">
            {products &&
              products.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      navigate("/ProductView", {
                        state: {
                          release_bond_id: item.release_bond_id,
                          product_id: item.product_id,
                          is_nfb: item.is_nfb,
                          uniq_code: item.uniq_code,
                          theme_index: item.theme_index,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                        },
                      });
                    }}
                    className="cluballnfb_main"
                  >
                    <p className="cluballnfb_nm">{item.product_name}</p>
                    <img
                      src={item.bond_img_video[0].img_video}
                      className="cluballnfb_img"
                      alt=""
                    />
                    <p className="cluballnfb_number">{item.total_bond}</p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cluballnfb;
