import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import images from "../constants/images";
import axios from "axios";
import { create_bid } from "../utils/Constant";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";

const ClubSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const userId = localStorage.getItem("user_id");

  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || {};
  };

  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };

  const clubPackageId = getDataFromSessionStorage("clubPackageId");
  const club_id = getDataFromSessionStorage("club_id");
  const allRes = JSON.parse(localStorage.getItem("allres"));

  useEffect(() => {
    console.log("store data", clubPackageId);
    console.log("club_id", club_id);
    console.log("allRes", allRes);
    // console.log("location data", locatioData[0]);

    if (
      clubPackageId !== null ||
      clubPackageId !== undefined ||
      clubPackageId.length > 0
    ) {
      JoinClub();
    }
  }, []);

  const JoinClub = () => {
    setloading(true);
    const param = {
      club_id: club_id,
      user_id: userId,
      club_package_id: clubPackageId,
    };
    console.log("assacacacasavsedav", param);
    axios
      .post("https://www.ravi.host/api/club_join", param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          console.log(Response.data);
          sessionStorage.clear();
        } else {
          console.log(Response.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="page_main_wrapp">
      <div
        className="page_base_wrapp"
        style={{ overflowY: "hidden", margin: "0px auto" }}
      >
        {/* success modal start */}
        <div
          className="actionneed_2main"
          style={{ minHeight: "95vh", overflow: "hidden" }}
        >
          <div
            style={{
              width: "100%",
              padding: "12px",
              backgroundColor: "var(--color-main)",
              minHeight: "200px",
              borderRadius: "18px",
              margin: "10px auto",

              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <IoIosCheckmarkCircle color="#fff" size="30" />

            <p
              style={{
                color: "var(--color-white)",
                fontFamily: "var(--font-700)",
                fontSize: "24px",
                marginTop: "12px",
                lineHeight: "28px",
              }}
            >
              Payment of ₹{allRes.ammount} is successful!
            </p>

            <p
              style={{
                color: "var(--color-white)",
                fontFamily: "var(--font-400)",
                fontSize: "12px",
                marginTop: "4px",
              }}
            >
              {/* 18 Des,2023 | 03:46 PM */}
              {moment(allRes.date_time).format("MMM D, YYYY")} |{" "}
              {moment(allRes.date_time).format("h:mm a")}
            </p>

            <p
              style={{
                color: "var(--color-white)",
                fontFamily: "var(--font-300)",
                fontSize: "12px",
                marginTop: "12px",
              }}
            >
              {allRes.message}
            </p>

            <p
              style={{
                color: "var(--color-white)",
                fontFamily: "var(--font-600)",
                fontSize: "14px",
                marginTop: "12px",
              }}
            >
              Transction number
            </p>
            <p
              style={{
                color: "var(--color-white)",
                fontFamily: "var(--font-300)",
                fontSize: "12px",
                // marginTop: "8px",
              }}
            >
              {allRes.tra_id}
            </p>
          </div>
          <Link
            onClick={() => {
              localStorage.removeItem("allres");
            }}
            to={"/homescreen2"}
            className="actionneed_2btn"
          >
            Home
          </Link>
        </div>
        {/* success modal end */}
      </div>
    </div>
  );
};

export default ClubSuccess;
