import logo from "../assets/images/logo/logo.png";
import new_logo from "../assets/images/rarerez.png";
import menulist from "../assets/images/list.png";
import password_seq from "../assets/images/password_seq.png";

import like from "../assets/images/like.png";
import Bids from "../assets/images/Bids.png";

import addcash from "../assets/images/AdCash.png";
import withcash from "../assets/images/Withdraw.png";

import back from "../assets/images/back_png.png";
import DownArrow from "../assets/images/DownArrow.png";
import down from "../assets/images/down.png";
import UpArrow from "../assets/images/UpArrow.png";

// feed
import slider from "../assets/images/slider_club4.jpeg";
import view_1 from "../assets/images/view_1.png";
// feed end

import bg_product from "../assets/images/bg_product.png";
import bidsimg from "../assets/images/bidsimg.png";

import vk_img from "../assets/images/vk_img.jpg";
import img5 from "../assets/images/5.png";
import hr_img from "../assets/images/hr_img.jpg";

import next from "../assets/images/next.png";
import sharenfb from "../assets/images/34.png";

import video_bg from "../assets/images/video_bg.png";
import video from "../assets/images/video.png";
import thumbnail from "../assets/images/video_bg.png";
import need_help from "../assets/images/need_help.png";
import rightarrow_white from "../assets/images/right-arrow_white.png";
import clipart from "../assets/images/clipart.png";

import MaskGroup54 from "../assets/images/MaskGroup54.png";
import insta from "../assets/images/insta.png";

// media1
import media1 from "../assets/images/media1.jpg";
import media2 from "../assets/images/media2.jpg";
import media3 from "../assets/images/media3.jpg";
import media4 from "../assets/images/media4.jpg";
import media5 from "../assets/images/media5.jpg";
import media6 from "../assets/images/media6.jpg";
import loginbanner from "../assets/images/loginbanner.png";
import mpin from "../assets/images/mpin.png";

//partner benefits
import object from "../assets/images/object.png";
import logout from "../assets/images/footer/logout.png";
import interests_logo from "../assets/images/interests_logo.png";
import editProfile from "../assets/images/editProfile.png";
import notifications from "../assets/images/notifications.png";
import terms from "../assets/images/terms.png";
import change_password from "../assets/images/change_password.png";

// catogory.
import entertainment from "../assets/images/object.png";
import entertainment2 from "../assets/images/entertainment.png";
import history from "../assets/images/history.png";
import qrcode from "../assets/images/qrcode.png";
import camera2 from "../assets/images/camera2.png";
import copylink from "../assets/images/copylink.png";
import share from "../assets/images/share.png";

// feed images
import demo1 from "../assets/images/demo_1.png";
import card1 from "../assets/images/card23.png";
import logo_white from "../assets/images/logo_white.png";
import editing_verify from "../assets/images/editing_verify_1.png";
import clubs_icon from "../assets/images/clubs_icon.png";
import search_2 from "../assets/images/search_2.png";
import club from "../assets/images/club.png";
import home from "../assets/images/home.png";
import home_main from "../assets/images/home_main.png";
import marked from "../assets/images/marked.png";
import club_white from "../assets/images/club_white.png";
import bids_white from "../assets/images/bids_white.png";
import Partner_Reward_Request from "../assets/images/Partner_Reward_Request.png";
import new_launch_nfb_new from "../assets/images/new_launch_nfb_new.png";
import product_auction from "../assets/images/product_auction.png";
import gallery_icon from "../assets/images/gallery_icon.png";
import close_red from "../assets/images/close_red.png";
import mask_profile from "../assets/images/mask_profile.png";
import bg_au from "../assets/images/bg_au.png";
import search from "../assets/images/search.png";
import promote_new_be from "../assets/images/promote_new_be.png";
import terms_white from "../assets/images/terms_white.png";
import need from "../assets/images/need.png";

import pinkcard from "../assets/images/newBlueCardFront.png";
import goldencard from "../assets/images/newGoldenCardFront.png";
import purplecard from "../assets/images/fram10.png";
import redcard7 from "../assets/images/frame11.png";
import redlinecard from "../assets/images/newredlineCardFront.png";

// sidemenu
import new_my_products_new from "../assets/images/new_my_products_new.png";
import new_create_product_new from "../assets/images/new_create_product_new.png";
import new_creator_request_new from "../assets/images/new_creator_request_new.png";
import new_my_benefits_new from "../assets/images/new_my_benefits_new.png";
import Reward_Request from "../assets/images/Reward_Request.png";
import menu_my_club from "../assets/images/menu_my_club.png";
import menu_membership_new from "../assets/images/menu_membership_new.png";
import menu_create_club_new from "../assets/images/menu_create_club_new.png";
import cwaty_logo from "../assets/images/cwaty_logo.png";

// club profile
import createnfb1 from "../assets/images/createnfb1.png";
import myproducts1 from "../assets/images/myproducts1.png";
import mywallets1 from "../assets/images/mywallets1.png";
import cp_sponser from "../assets/images/26.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";
import callclub from "../assets/images/callclub.png";
import facebook from "../assets/images/facebook.png";

// user Profile
import coverimg from "../assets/images/coverimg.png";
import menu_clue_new from "../assets/images/menu_clue_new.png";
import home_white from "../assets/images/home_white.png";
import fav_new_white from "../assets/images/fav_new_white.png";
import fav_new from "../assets/images/fav_new.png";
import Bids_left from "../assets/images/Bids_left.png";
import frames1 from "../assets/images/yellow_card_bg.png";
import frames2 from "../assets/images/red_card_bg.png";
import frames3 from "../assets/images/bluecard_back.png";
// 04-09-2023
import community from "../assets/images/community.png";
import discussion from "../assets/images/discussion.png";
import star_new from "../assets/images/star_new.png";
import cube_animation2 from "../assets/images/cube_animation2.gif";
import star from "../assets/images/star.png";
import delete2 from "../assets/images/delete.png";
import next_2 from "../assets/images/next_2_new.png";

// 09-09-2023
import redcard from "../assets/images/redcard.png";
import yellowcard from "../assets/images/yellowcard.png";
import firstcard from "../assets/images/card_number_0.png";
import usernew from "../assets/images/user_new.png";
import plus from "../assets/images/plus_new.png";
import favorite from "../assets/images/favorite.png";
import favorite_gray from "../assets/images/favorite_gray.png";
import Mybidslist from "../assets/images/check.png";
import profilestar from "../assets/images/profile_star.png";
import user_white from "../assets/images/user_white.png";
import telephone_white from "../assets/images/telephone_white.png";
import mail from "../assets/images/mail.png";
import resume_white from "../assets/images/resume_white.png";
import profile_white from "../assets/images/profile_white.png";
import location from "../assets/images/location.png";
import aadharcard from "../assets/images/aadharcard.png";
import whitecollection from "../assets/images/whitecollection.png";
import collection from "../assets/images/collection.png";
import alertimg from "../assets/images/alert.png";
import right_green from "../assets/images/right_green.png";
import salmanKhan from "../assets/images/salmanKhan.png";
import login8 from "../assets/images/login8.png";
import ar_code_11 from "../assets/images/ar_code_11.png";
import new_partner_benefit_new from "../assets/images/new_partner_benefit_new.png";
import marked_main from "../assets/images/marked_main.png";

// 28-09-2023
import Splashscreen1 from "../assets/images/6.png";
import Splashscreen2 from "../assets/images/S2.png";
import Splashscreen3 from "../assets/images/S4.png";
import Splashscreen4 from "../assets/images/S3.png";
import Splashscreen5 from "../assets/images/6.png";
import new_logo_black from "../assets/images/logo/new_logo_black.png";
import nfb_video from "../assets/images/nfb_video.png";
import Users_tab from "../assets/images/Users_tab.png";
import Clubs_tab from "../assets/images/Clubs_tab.png";
import Bonds_tab from "../assets/images/Bonds_tab.png";
import order_done from "../assets/images/order_done.png";
import wallet_new from "../assets/images/wallet_new.png";
import feed_filter from "../assets/images/feed_filter.png";
import explore from "../assets/images/explore.png";
import legal from "../assets/images/legal.png";
import Privacy from "../assets/images/privacy-policy.png";
import cancelation from "../assets/images/cancelation.png";
import support from "../assets/images/support.png";
import bg_amount from "../assets/images/bg_amount.png";
import like_true from "../assets/images/like_true.png";
import call from "../assets/images/call.png";
import new_launch_nfb_new_white from "../assets/images/new_launch_nfb_new_white.png";
import eyeopen from "../assets/images/eyeopen.png";
import eyeoff from "../assets/images/eyeoff.png";
import Card8Frame from "../assets/images/Card8Frame.png";
import Card9Frame from "../assets/images/Card9Frame.png";
import Card10Frame from "../assets/images/Card10Frame.png";
import Card11Frame from "../assets/images/Card11Frame.png";
import Card12Frame from "../assets/images/Card12Frame.png";
import rares from "../assets/images/rares.png";
import giphy from "../assets/images/giphy.gif";
import select from "../assets/images/select.png";
import selected from "../assets/images/selected.png";
import new_plus_icon from "../assets/images/new_plus_icon.png";
import re_capcha_icon from "../assets/images/re_capcha_icon.png";
import smaple_video from "../assets/images/smaple_video.mp4";
import play_white from "../assets/images/play_white.png";
import pause_white from "../assets/images/pause_white.png";
import rotate_camera from "../assets/i/rotate_camera.png";
import camera_new from "../assets/i/camera_new.png";
import deleteicon from "../assets/images/delete.png";
import video_product from "../assets/images/video_product.png";
import video_user from "../assets/images/video_user.png";
import card13 from "../assets/images/card13.png";
import start_circle from "../assets/images/start_circle.png";
import stop_circle from "../assets/images/stop_circle.png";
import back_black from "../assets/images/back_black.png";
import shopping_cart from "../assets/images/shopping-cart.png";
import shopping_bag from "../assets/images/shopping_bag.png";
import payment_correct from "../assets/images/payment_correct.png";
import payment_cancel from "../assets/images/payment_cancel.png";
import card7_hr from "../assets/images/card7_hr.png";
import card7_hr_thub from "../assets/images/card7_hr_thub.png";
import shipping_box from "../assets/images/shipping_box.png";
import logos_back from "../assets/images/logos_back2.png";
import club_req from "../assets/images/club_req.png";
import rhistory from "../assets/images/rhistory.png";
import imgreq from "../assets/images/imgreq.png";
import asset_req_card_img from "../assets/images/asset_req_card_img.png";
import frame14 from "../assets/images/frame14.png";
import frame15 from "../assets/images/frame15.png";
import card14front from "../assets/images/card14front.png";
import card14back from "../assets/images/card14back.png";
import card15front from "../assets/images/card15front.png";
import card15back from "../assets/images/card15back.png";
import circle from "../assets/images/circle.png";
import new_sa_left from "../assets/images/new_sa_left.png";
import new_sa_right from "../assets/images/new_sa_right.png";
import pdf_icon from "../assets/images/pdf_icon.png";

// ----------------------------------------------------------------

import new_location_logo from "../assets/new_images/new_location_logo.png";
import new_down_logo from "../assets/new_images/new_down_logo.png";
import new_bg_circule from "../assets/new_images/new_bg_circule.png";
import new_bg_circule_2 from "../assets/new_images/new_bg_circule_2.png";
import new_play_screen_img from "../assets/new_images/new_play_screen_img.png";
import new_btn_img from "../assets/new_images/new_btn_img.png";
import new_down_arrow from "../assets/new_images/new_down_arrow.png";
import new_success_img from "../assets/new_images/new_success_img.png";
import new_email_logo from "../assets/new_images/new_email_logo.png";
import new_lock_logo from "../assets/new_images/new_lock_logo.png";
import new_eye_icon from "../assets/new_images/new_eye_icon.png";
import new_eye_close from "../assets/new_images/new_eye_close.png";
import capcha_img from "../assets/new_images/capcha_img.png";
import rotate_video from "../assets/new_images/rotate_video.png";
import reset_video from "../assets/new_images/reset_video.png";
import new_capcha_rephrase from "../assets/new_images/new_capcha_rephrase.png";
import new_accepted_terms from "../assets/new_images/new_accept_terms.png";
import new_notaccepted_terms from "../assets/new_images/new_notaccept_terms.png";
import new_user_icon from "../assets/new_images/new_user_icon.png";
import new_ifsc_logo from "../assets/new_images/new_ifsc_logo.png";
import new_upload_logo from "../assets/new_images/new_upload_logo.png";
import new_close_logo from "../assets/new_images/new_close_logo.png";
import new_pa_left from "../assets/new_images/new_pa_left.png";
import new_pa_right from "../assets/new_images/new_pa_right.png";
import new_link_icon from "../assets/new_images/new_link_icon.png";
import new_fill_close from "../assets/new_images/new_fill_close.png";
import asset_name_logo from "../assets/new_images/asset_name_logo.png";
import new_phone_icon from "../assets/new_images/new_phone_icon.png";
import club_white_h from "../assets/new_images/club_white_h.png";
import product_auction_white from "../assets/new_images/product_auction.png";
import round_plus from "../assets/new_images/round_plus.png";
import copy_link_icon from "../assets/new_images/copy_link_icon.png";
import gold_stars from "../assets/new_images/gold_stars.png";
import pink_stars from "../assets/new_images/pink_stars.png";
import pink_stars2 from "../assets/new_images/pink_stars2.png";
import leaderbord_boxes from "../assets/new_images/leaderbord_boxes.png";

// new frames
import new_frame_1_f from "../assets/frame_images/new_frame_1_f.png";
import new_frame_1_b from "../assets/frame_images/new_frame_1_b.png";
import new_frame_2_b from "../assets/frame_images/new_frame_2_b.png";
import new_frame_2_f from "../assets/frame_images/new_frame_2_f.png";
import new_frame_3_b from "../assets/frame_images/new_frame_3_b.png";
import new_frame_3_f from "../assets/frame_images/new_frame_3_f.png";
import new_frame_4_b from "../assets/frame_images/new_frame_4_b.png";
import new_frame_4_f from "../assets/frame_images/new_frame_4_f.png";
import new_frame_5_b from "../assets/frame_images/new_frame_5_b.png";
import new_frame_5_f from "../assets/frame_images/new_frame_5_f.png";
import new_frame_6_b from "../assets/frame_images/new_frame_6_b.png";
import new_frame_6_f from "../assets/frame_images/new_frame_6_f.png";
import new_frame_7_b from "../assets/frame_images/new_frame_7_b.png";
import new_frame_7_f from "../assets/frame_images/new_frame_7_f.png";
import new_frame_9_b from "../assets/frame_images/new_frame_9_b.png";
import new_frame_9_f from "../assets/frame_images/new_frame_9_f.png";
import new_frame_8_b from "../assets/frame_images/new_frame_8_b.png";
import new_frame_8_f from "../assets/frame_images/new_frame_8_f.png";
import new_frame_10_b from "../assets/frame_images/new_frame_10_b.png";
import new_frame_10_f from "../assets/frame_images/new_frame_10_f.png";

// new nfb frames
import new_frame_1_nfb from "../assets/frame_images/new_frame_1_nfb.png";
import new_frame_2_nfb from "../assets/frame_images/new_frame_2_nfb.png";
import new_frame_3_nfb from "../assets/frame_images/new_frame_3_nfb.png";
import new_frame_4_nfb from "../assets/frame_images/new_frame_4_nfb.png";
import new_frame_5_nfb from "../assets/frame_images/new_frame_5_nfb.png";
import new_frame_6_nfb from "../assets/frame_images/new_frame_6_nfb.png";
import new_frame_7_nfb from "../assets/frame_images/new_frame_7_nfb.png";
import new_frame_9_nfb from "../assets/frame_images/new_frame_9_nfb.png";
import new_frame_8_nfb from "../assets/frame_images/new_frame_8_nfb.png";
import new_frame_10_nfb from "../assets/frame_images/new_frame_10_nfb.png";

// side menu 09-03-24
import side_new_close from "../assets/new_images/side_new_close.png";
import sidemenu_logout from "../assets/new_images/sidemenu-logout.png";
import my_asset from "../assets/new_images/my_asset.png";
import protect_asset from "../assets/new_images/protect_asset.png";
import asset_approval from "../assets/new_images/asset_approval.png";
import my_benefits from "../assets/new_images/my_benefits.png";
import benefits_offered from "../assets/new_images/benefits_offered.png";
import club_request from "../assets/new_images/club_request.png";
import my_club_new from "../assets/new_images/my_club_new.png";
import my_membership from "../assets/new_images/my_membership.png";
import create_club_new from "../assets/new_images/create_club_new.png";
import international_request from "../assets/new_images/international_request.png";
import bucket_benefit from "../assets/sidemenu_imgs/bucket_benefit.png";

// usermenu 11-03-24
import notification_user from "../assets/sidemenu_imgs/um_notification.png";
import interest_user from "../assets/sidemenu_imgs/um_interest.png";
import editprofile_user from "../assets/sidemenu_imgs/um_editprofile.png";
import myorder_user from "../assets/sidemenu_imgs/um_orders.png";
import setmpin_user from "../assets/sidemenu_imgs/um_resetmpin.png";
import changepwd_user from "../assets/sidemenu_imgs/um_changepassword.png";
import legal_user from "../assets/sidemenu_imgs/um_legal.png";
import contactus_user from "../assets/sidemenu_imgs/um_contactus.png";
import logout_user from "../assets/sidemenu_imgs/um_logout.png";
import legacy_logo from "../assets/sidemenu_imgs/legacy_logo.png";

// home images

import club_white_hh from "../assets/home_images/club_white_h.png";
import home_blank_card from "../assets/home_images/home_blank_card.png";
import product_auction13 from "../assets/home_images/product_auction.png";
import search_icon from "../assets/home_images/search_icon.png";
import transaction_icon from "../assets/home_images/transaction_icon.png";
import dot3 from "../assets/home_images/dot3.png";

import footerlogo1_r from "../assets/home_images/footerlogo1_r.png";
import footerlogo1_w from "../assets/home_images/footerlogo1_w.png";
import footerlogo2_r from "../assets/home_images/footerlogo2_r.png";
import footerlogo2_w from "../assets/home_images/footerlogo2_w.png";
import footerlogo3_r from "../assets/home_images/footerlogo3_r.png";
import footerlogo3_w from "../assets/home_images/footerlogo3_w.png";
import footerlogo4_r from "../assets/home_images/footerlogo4_r.png";
import footerlogo4_w from "../assets/home_images/footerlogo4_w.png";
import footerlogo5_r from "../assets/home_images/footerlogo5_r.png";
import footerlogo5_w from "../assets/home_images/footerlogo5_w.png";

import untickcommunity from "../assets/new_images/untickcommunity.png";
import tickcommunity from "../assets/new_images/tickcommunity.png";
import communityicon from "../assets/new_images/communityicon.png";
import familyicon from "../assets/new_images/familyicon.png";
import viewallbtn from "../assets/new_images/viewallbtn.png";
import squarep from "../assets/new_images/Rectangle94075.png";
import new_notification_fill from "../assets/new_images/new_notification_fill.png";

import Person_white from "../assets/new_images/Person_white.png";
import edit_profile_pen from "../assets/new_images/edit_profile_pen.png";
import edit_pen from "../assets/new_images/edit_pen.png";
import new_delete from "../assets/new_images/new_delete.png";
import d_play from "../assets/new_images/3d_play.png";
import right_user_check from "../assets/new_images/right_user_check.png";
import flip_icon from "../assets/new_images/flip_icon.png";
import new_loader_gif from "../assets/new_images/new_loader_gif.gif";
import new_delete_icon from "../assets/new_images/new_delete_icon.png";

import new_profile_icon from "../assets/new_images/new_profile_icon.png";
import new_location_icon from "../assets/new_images/new_location_icon.png";
import mapicon from "../assets/new_images/location.png";

import dobble_back from "../assets/new_images/dobble_back.png";
import triple_back from "../assets/new_images/triple_back.png";

export default {
  // feed images
  // 28-09-2023
  new_logo_black,
  Splashscreen1,
  Splashscreen2,
  Splashscreen3,
  Splashscreen4,
  Splashscreen5,
  // 28-09-2023
  marked_main,
  salmanKhan,
  ar_code_11,
  new_partner_benefit_new,
  login8,
  right_green,
  favorite,
  alertimg,
  aadharcard,
  whitecollection,
  collection,
  new_launch_nfb_new,
  Partner_Reward_Request,
  home,
  bids_white,
  club_white,
  marked,
  home_main,
  search_2,
  club,
  clubs_icon,
  editing_verify,
  demo1,
  card1,
  logo_white,
  // catogory.
  loginbanner,
  entertainment,
  history,
  qrcode,
  mpin,
  camera2,
  copylink,
  share,

  logo,
  new_logo,
  logout,
  interests_logo,
  editProfile,
  notifications,
  terms,
  change_password,

  addcash,
  withcash,

  back,
  DownArrow,
  down,
  UpArrow,

  menulist,
  password_seq,

  slider,
  view_1,
  like,
  Bids,
  insta,

  bg_product,
  bidsimg,
  vk_img,
  img5,
  hr_img,
  sharenfb,
  next,
  video_bg,
  need_help,
  rightarrow_white,

  clipart,
  MaskGroup54,
  thumbnail,
  video,

  media1,
  media2,
  media3,
  media4,
  media5,
  media6,

  product_auction,

  //partner benefits
  object,
  gallery_icon,
  close_red,
  mask_profile,
  bg_au,
  search,
  promote_new_be,
  terms_white,
  need,

  // side menu
  new_my_products_new,
  new_create_product_new,
  new_creator_request_new,
  new_my_benefits_new,
  Reward_Request,
  menu_my_club,
  menu_membership_new,
  menu_create_club_new,
  cwaty_logo,

  // club profile

  createnfb1,
  myproducts1,
  mywallets1,
  cp_sponser,
  twitter,
  instagram,
  linkedin,
  facebook,
  callclub,

  // UserProfile

  coverimg,
  // 31-028-2023
  menu_clue_new,
  home_white,
  fav_new_white,
  fav_new,
  Bids_left,
  frames1,
  frames2,
  frames3,
  // 31-028-2023
  community,
  discussion,
  star_new,
  cube_animation2,
  star,
  delete2,
  next_2,
  //  09-09-023
  redcard,
  yellowcard,
  firstcard,
  entertainment2,
  usernew,
  plus,
  favorite_gray,
  Mybidslist,
  profilestar,
  user_white,
  telephone_white,
  mail,
  resume_white,
  profile_white,
  location,
  nfb_video,
  Users_tab,
  Clubs_tab,
  Bonds_tab,
  order_done,
  wallet_new,
  feed_filter,
  explore,
  legal,
  Privacy,
  cancelation,
  support,
  bg_amount,
  like_true,
  call,
  new_launch_nfb_new_white,
  eyeopen,
  eyeoff,

  pinkcard,
  goldencard,
  purplecard,
  redcard7,
  redlinecard,
  Card8Frame,
  Card9Frame,
  Card10Frame,
  Card11Frame,
  Card12Frame,
  rares,
  giphy,
  selected,
  select,
  new_plus_icon,
  re_capcha_icon,
  smaple_video,
  pause_white,
  play_white,
  rotate_camera,
  camera_new,
  deleteicon,
  video_product,
  video_user,
  card13,
  start_circle,
  stop_circle,
  back_black,
  shopping_cart,
  shopping_bag,
  payment_cancel,
  payment_correct,
  card7_hr,
  card7_hr_thub,
  shipping_box,
  logos_back,
  club_req,
  rhistory,
  imgreq,
  asset_req_card_img,
  frame14,
  frame15,
  card14front,
  card14back,
  card15front,
  card15back,
  circle,
  new_sa_left,
  new_sa_right,
  pdf_icon,
  new_location_logo,
  new_down_logo,

  // ----------------------------------------------------------------

  new_bg_circule,
  new_bg_circule_2,
  new_play_screen_img,
  new_btn_img,
  new_down_arrow,
  new_success_img,
  new_email_logo,
  new_lock_logo,
  new_eye_icon,
  new_eye_close,
  capcha_img,
  new_capcha_rephrase,
  new_accepted_terms,
  new_notaccepted_terms,
  new_user_icon,
  new_ifsc_logo,
  new_upload_logo,
  new_close_logo,
  new_pa_left,
  new_pa_right,
  new_link_icon,
  new_fill_close,
  asset_name_logo,
  new_phone_icon,
  club_white_h,
  product_auction_white,
  round_plus,
  copy_link_icon,
  gold_stars,
  pink_stars,
  pink_stars2,
  leaderbord_boxes,

  // side menu
  side_new_close,
  sidemenu_logout,
  my_asset,
  protect_asset,
  asset_approval,
  my_benefits,
  benefits_offered,
  club_request,
  my_club_new,
  my_membership,
  create_club_new,
  international_request,
  bucket_benefit,

  // user menu
  notification_user,
  interest_user,
  editprofile_user,
  myorder_user,
  setmpin_user,
  changepwd_user,
  legal_user,
  contactus_user,
  logout_user,
  legacy_logo,

  // new frames start
  new_frame_1_f,
  new_frame_1_b,
  new_frame_2_f,
  new_frame_2_b,
  new_frame_3_f,
  new_frame_3_b,
  new_frame_4_f,
  new_frame_4_b,
  new_frame_5_f,
  new_frame_5_b,
  new_frame_6_f,
  new_frame_6_b,
  new_frame_7_f,
  new_frame_7_b,
  new_frame_8_f,
  new_frame_8_b,
  new_frame_9_f,
  new_frame_9_b,
  new_frame_10_f,
  new_frame_10_b,

  // new frames end

  // new nfb frames
  new_frame_1_nfb,
  new_frame_2_nfb,
  new_frame_3_nfb,
  new_frame_4_nfb,
  new_frame_5_nfb,
  new_frame_6_nfb,
  new_frame_7_nfb,
  new_frame_8_nfb,
  new_frame_9_nfb,
  new_frame_10_nfb,
  // new nfb frames end

  // home images start
  club_white_hh,
  product_auction13,
  search_icon,
  transaction_icon,
  dot3,
  home_blank_card,

  footerlogo1_r,
  footerlogo1_w,
  footerlogo2_r,
  footerlogo2_w,
  footerlogo3_r,
  footerlogo3_w,
  footerlogo4_r,
  footerlogo4_w,
  footerlogo5_r,
  footerlogo5_w,
  // home images end

  untickcommunity,
  tickcommunity,
  communityicon,
  familyicon,
  viewallbtn,
  squarep,
  new_notification_fill,

  Person_white,
  edit_profile_pen,
  edit_pen,
  new_delete,
  d_play,
  right_user_check,
  flip_icon,
  new_loader_gif,
  rotate_video,
  reset_video,
  new_delete_icon,
  new_profile_icon,
  new_location_icon,

  mapicon,
  dobble_back,
  triple_back,
};
