import React from "react";
import "./NewProductView.css";
import { FaAngleLeft, FaCheck, FaRegHeart } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import images from "../../constants/images";

const NewProductView = () => {
  const navigate = useNavigate();
  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="back_arrow_wrapp">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
        </div>

        {/* cards start */}
        <div className="new_pv_card_wrapp"></div>
        {/* cards start */}

        {/* asset value start */}
        <div className="new_pv_asset_value_wrapp">
          <img
            src={images.gold_stars}
            alt=""
            className="new_pv_asset_value_stars1"
          />

          <div className="new_pv_asset_value_div">
            <p>Asset Value</p>
            <h4>₹5.00L</h4>
          </div>

          <img
            src={images.gold_stars}
            alt=""
            className="new_pv_asset_value_stars2"
          />
        </div>
        {/* asset value end */}

        {/* asset name start */}
        <div className="new_pv_asset_name_wrapp">
          <div>
            <h6>Asset Name</h6>
            <p>March 11, 2024</p>
          </div>
          <p>
            With each brushstroke, the canvas breathes life into colors, weaving
            stories untold.
          </p>
        </div>
        {/* asset name end */}

        {/* like and share buttons wrapp start */}

        <div className="new_pv_likeshare_wrapp">
          <div className="new_pv_likeshare_btns_wrapp">
            <button>
              <FaCheck size={28} color="#fff" />
              <p>Like</p>
            </button>
            <button>
              <FiShare2 size={28} color="#fff" />
              <p>Share</p>
            </button>
            <button>
              <FaRegHeart size={28} color="#fff" />
              <p>Whishlist</p>
            </button>
          </div>

          <Link
            to={"/placebid"}
            style={{ width: "48%", height: "50px" }}
            className="new_btn_wrapp"
          >
            <p className="bid_nm">Bid</p>
            <img
              src={images.footerlogo4_w}
              className="bid_d_img"
              alt=""
              style={{
                // transform: "rotate(90deg)",
                left: "25%",
                position: "absolute",
                objectFit: "contain",
                width: "18px",
                height: "18px",
              }}
            />
          </Link>
        </div>

        {/* like and share buttons wrapp end */}

        {/* owner profile start */}

        <div className="new_pv_ownerprofile_wrapp">
          <div className="new_pv_ownerprofile_img_wrapp">
            <img src={images.hr_img} alt="" />
          </div>
          <p>Hritik Roshan</p>
        </div>

        {/* owner profile end */}

        {/* other available rarerez section start */}

        <div className="new_pv_other_avail_sec_wrapp">
          <p className="new_pv_sec_heading">Available Rarerez</p>

          <div className="new_pv_other_avail_rarerez_cards_wrapp">
            <button className="new_pv_avail_rarerez_card"></button>
          </div>
        </div>

        {/* other available rarerez section end */}
      </div>
    </div>
  );
};

export default NewProductView;
