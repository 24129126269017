import React, { useState, useRef, useEffect } from "react";
import images from "../constants/images";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { user_resend_otp_in_new_mobile } from "../utils/Constant";
import AlertModal from "../components/AlertModal";
import Verifyscreen from "./verify/Verifyscreen";

const ForgatePassOtp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
  const otpInputs = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [con, setCon] = useState(false);
  const [getuserOtp, setUserOtp] = useState(
    location.state.user_otp ? location.state.user_otp : ""
  );

  const [resendTimer, setResendTimer] = useState(30);
  const [isResendTimerRunning, setIsResendTimerRunning] = useState(true);

  useEffect(() => {
    const otpValue = otp.join("");
    console.log("location.state", location.state.user_otp);
    console.log("otpValue", otpValue);
    console.log("otp", otp);
  }, [otp]);

  const startResendTimer = () => {
    setIsResendTimerRunning(true);
    setResendTimer(30);
  };

  useEffect(() => {
    if (isResendTimerRunning && resendTimer > 0) {
      const countdown = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isResendTimerRunning && resendTimer === 0) {
      setIsResendTimerRunning(false);
    }
  }, [isResendTimerRunning, resendTimer]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Check if the entered value is a digit and not empty
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field automatically if the current field is not empty
      if (value !== "" && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && otp[index] === "") {
      // Move back to the previous input field when backspace is pressed on an empty field
      otpInputs.current[index - 1].focus();
    }
  };

  const ResendOtp = () => {
    setIsLoading(true);

    const param = {
      user_id: location.state.user_id,
      phone: location.state.mobile_no,
    };
    console.log("Transaction", param);

    axios
      .post(user_resend_otp_in_new_mobile, qs.stringify(param))
      .then((response) => {
        setIsLoading(false);
        console.log("API Response:", response.data);

        if (response.data.status === 1) {
          setUserOtp(response.data.user_otp);
          startResendTimer();
          // setCon(true);
          setIsModalVisible(true);
          setCusmsg(response.data.message);
        } else {
          // setCon(true);
          setIsModalVisible(true);
          setCusmsg(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    // }
  };

  const VerifyOtp = () => {
    const otpValue = otp.join("");
    if (otpValue == "") {
      setIsModalVisible(true);
      setCusmsg("Enter otp");
    } else if (Number(getuserOtp) !== Number(otpValue)) {
      setIsModalVisible(true);
      setCusmsg("Wrong OTP");
    } else {
      navigate("/ForgateNewPass", {
        state: {
          user_id: location.state.user_id,
        },
      });
    }
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp">
          {/* <img src={images.new_logo} className="otp_demo_img" alt="" /> */}
          <div className="otp_demo">
            <h1 className="otp_demo_title">Enter OTP</h1>
            <br />
            <p
              style={{
                fontFamily: "var(--font-300)",
                fontSize: "12px",
                textAlign: "center",
                marginTop: "20px",
                opacity: 0.8,
              }}
            >
              OTP has been sent on +91{location.state.mobile_no}
              <br />
              Please Enter OTP to change your Password.
            </p>
          </div>
          <br />
          <div className="input_otp_new">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="number"
                className="in_de_otp"
                value={digit}
                onChange={(event) => handleInputChange(index, event)}
                onKeyDown={(event) => handleBackspace(index, event)}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <br />

          {/* <p className="demo_resend_otp">RESEND OTP</p> */}
          <div className="forgot_otp_btns_wrapp">
            <button
              onClick={ResendOtp}
              className="resend_mobile_otp"
              style={{
                color: isResendTimerRunning ? "#fff" : "var(--color-main)",
                fontFamily: isResendTimerRunning
                  ? "var(--font-400)"
                  : "font(--font-600)",
              }}
              disabled={isResendTimerRunning}
            >
              {isResendTimerRunning
                ? `Resend in ${resendTimer}s`
                : "RESEND OTP"}
            </button>
            <button className="forgot_submit_btn" onClick={VerifyOtp}>
              Submit
            </button>
          </div>
          <br />
          {/* <center>
            <button
              onClick={() => {
                updateOtp();
              }}
              className="demo_otp_btn_new_set"
            >
              Submit
            </button>
          </center> */}
        </div>
      )}
      {/* {isModalVisible && (
        <div className="modal_login">
          <div className="modal-content_login">
            <div className="modal_div1">
              <img className="forgot_main" src={images.logo} alt="" />
            </div>
            <div className="modal_div">
              <b>{"Alert"}</b>
              <br />
              <p className="modal-message"> {cusmsg}</p>
              <br />
            </div>
            <hr className="hr_forgot" />
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                // onClick={() => {
                //   con == true
                //     ? navigate("/homescreen2")
                //     : setIsModalVisible(false);
                // }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )} */}
      {isModalVisible && (
        <>
          <AlertModal title="" msg={cusmsg} setShowModal={setIsModalVisible} />
        </>
      )}
    </div>
  );
};

export default ForgatePassOtp;
