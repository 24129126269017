import React, { useState } from "react";
import { FaLock, FaLongArrowAltUp, FaReact } from "react-icons/fa";
import { MdNotifications } from "react-icons/md";
import { FaBeer } from "react-icons/fa";
import images from "../../constants/images";
import "./usermenunew.css";
import BackButton from "../../components/BackButton";
import { Link, useNavigate } from "react-router-dom";
import { useProfileContext } from "../../context/profile_context";
import { useEffect } from "react";
import { user_Send_otp_for_bond } from "../../utils/Constant";

import CusModal from "../../common/cusmodal/CusModal";
import axios from "axios";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const NewUserMenu = () => {
  const navigate = useNavigate();
  const MpinModal = ({ onClose }) => {
    const handleNoClick = () => {
      onClose();
    };

    return (
      <div className="mpin-modal-overlay">
        <div className="mpin-modal">
          <h1 className="modal_h1_mpin"></h1>
          <h1 className="modal_h1_mpin">
            Are you sure want to change/update your mPin?
          </h1>
          <div className="mpin_modal-buttons">
            <button className="modal_mpin_btn" onClick={handleNoClick}>
              No
            </button>
            <button className="modal_mpin_btn" onClick={handleYesClick}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  };
  const [loading, setloading] = useState(false);
  const [getuserProfileImg, setUserProfileImg] = useState("");
  const [getuserName, setUserName] = useState("");

  const handleYesClick = () => {
    mpingetotp();
    closeMpinModal();
  };

  const mpingetotp = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    console.log(param);
    axios
      .post(user_Send_otp_for_bond, param)
      .then((Response) => {
        console.log("acdascs", Response.data.user_otp);
        setloading(false);
        if (Response.data.status == 1) {
          navigate("/mpin", {
            state: {
              otp: Response.data.user_otp,
            },
          });
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const userId = localStorage.getItem("user_id");
  const { profile_loading, edit_profile_data, EditProfile } =
    useProfileContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isMpinModalOpen, setIsMpinModalOpen] = useState(false);

  const handleMpinSubmit = (mpin) => {
    // Handle the MPin submission here (e.g., send the MPin to the server)
    console.log("Submitted MPin:", mpin);
  };

  const openMpinModal = () => {
    setIsMpinModalOpen(true);
  };

  const closeMpinModal = () => {
    setIsMpinModalOpen(false);
  };

  const GetProfile = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    const data = await EditProfile(param);
    setUserProfileImg(data.profile);
    setUserName(data.username);
    console.log("data 111", data.result);
  };

  const GetQrCode = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    await axios
      .post("https://www.ravi.host/api/get_user_qr_code", param)
      .then((Response) => {
        setIsLoading(false);
        console.log("responce", Response.data);
        if (Response.data.status == 1) {
          navigate("/userqr", {
            state: {
              qr_code: Response.data.result.qr_code,
              link: Response.data.result.link,
              username: edit_profile_data.username,
              profile:
                edit_profile_data.profile === ""
                  ? images.logo
                  : edit_profile_data.profile,
            },
          });
          console.log("Response.data", Response.data);
        } else {
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    GetProfile();
  }, []);

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (isMpinModalOpen || showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isMpinModalOpen, showModal, bodyScrollEnabled]);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };
  return (
    <div className="page_main_wrapp_nw reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2">
          {/* navbar start */}
          <div className="new_user_menu_nav_wrapp">
            <Link to={"/editprofile"} className="new_user_img_menu">
              <div className="new_side_menu_sec1_profile_wrapp">
                <div className="new_user_sideimgbox">
                  <img
                    src={
                      edit_profile_data.profile === ""
                        ? images.logo
                        : edit_profile_data.profile
                    }
                    className="new_user_img_1"
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <img
              src={images.side_new_close}
              onClick={() => navigate("/homescreen2")}
              alt=""
              className="new_nav_back_btn"
            />
          </div>
          {/* navbar end */}

          {/* username Qr section start*/}
          <div className="new_user_menu_nav_wrapp_qr">
            <div className="new_user_left_name">
              <p className="new_user_menu_nm_1">{edit_profile_data.username}</p>
              <p className="new_user_menu_nm_2">
                {edit_profile_data.firstname}
              </p>
            </div>
            <div className="new_user_right_qr">
              <button
                className="new_user_qr_btn"
                // to={"/userqr"}
                onClick={() => {
                  GetQrCode();
                }}
              >
                <img
                  src={edit_profile_data?.qr_code}
                  alt=""
                  className="new_user_qr_img"
                />
              </button>
            </div>
          </div>
          <div className="new_usermenu_hr"></div>
          {/* username Qr section end*/}

          {/* usermenu list start */}
          <div className="new_menu_list">
            <img
              src={images.notification_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/notification"}>
              <p className="new_menu_list_p">Notification</p>
            </Link>
          </div>
          <div className="new_menu_list">
            <img
              src={images.interest_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/UpdateIntrests"}>
              <p className="new_menu_list_p">Interests</p>
            </Link>
          </div>

          <div className="new_menu_list">
            <img
              src={images.legacy_logo}
              className="images_icon_menu_new"
              alt=""
            />
            <Link
              to={"/LegacyDashbord"}
              state={{ first_time_popup: 0, first_time_home: 0 }}
            >
              <p className="new_menu_list_p">Legacy</p>
            </Link>
          </div>

          <div className="new_menu_list">
            <img
              src={images.editprofile_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/editprofile"}>
              <p className="new_menu_list_p">Edit Profile</p>
            </Link>
          </div>
          <div className="new_menu_list">
            <img
              src={images.myorder_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to="/MyOrders">
              <p className="new_menu_list_p">My Orders</p>
            </Link>
          </div>
          {/* <div className="new_menu_list" onClick={openMpinModal}>
            <img
              src={images.setmpin_user}
              className="images_icon_menu_new"
              alt=""
            />
            <p className="new_menu_list_p">Set/Reset MPin</p>
          </div> */}
          <div className="new_menu_list">
            <img
              src={images.changepwd_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/changepassword"}>
              <p className="new_menu_list_p">Change Password</p>
            </Link>
          </div>
          {/* <div className="new_menu_list">
                <img src={images.terms} className="images_icon_menu_new" alt="" />
                <Link to={"/termsandcondition"}>
                  <p className="new_menu_list_p">Terms & Conditions</p>
                </Link>
              </div> */}
          <div className="new_menu_list">
            <img
              src={images.legal_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/Legal"}>
              <p className="new_menu_list_p">Legal</p>
            </Link>
          </div>
          <div className="new_menu_list">
            <img
              src={images.contactus_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link to={"/ContactUs"}>
              <p className="new_menu_list_p">Contact Us</p>
            </Link>
          </div>
          <div className="new_menu_list">
            <img
              src={images.logout_user}
              className="images_icon_menu_new"
              alt=""
            />
            <Link
              to={"/"}
              onClick={() => {
                window.location.reload();
                localStorage.clear();
              }}
            >
              <p className="new_menu_list_p">Logout</p>
            </Link>
          </div>
          {isMpinModalOpen && (
            // <div className="cusModal_main_wrapp">
            //   <div className="cusmodal_inner_wrapp">
            //     <img src={images.logo} alt="" className="cm_logo" />
            //     <p className="cm_heading">Information</p>
            //     <div className="cm_des_wrapp">
            //       <p className="cm_des mb-1">
            //         Are you sure want to change/update your MPin?
            //       </p>

            //       <hr className="cm_hr" />

            //       <div className="mpin_btns_wrapp">
            //         <button className="mpin_yn_btn" onClick={handleYesClick}>
            //           Yes
            //         </button>
            //         <button className="mpin_yn_btn" onClick={closeMpinModal}>
            //           No
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            // </div>

            <V2Modal
              canclebtn={true}
              title=""
              msg="Are you sure want to change/update your MPin?"
              setShowModal={setIsMpinModalOpen}
              okfunction={handleYesClick}
            />
          )}
          {showModal && (
            // <NewModal
            //   title=""
            //   msg={errorMessage}
            //   setShowModal={openmodal}
            //   closemodal={closemodal}
            // />

            <V2Modal
              canclebtn={false}
              title=""
              msg={errorMessage}
              setShowModal={setShowModal}
              okfunction={closemodal}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NewUserMenu;
