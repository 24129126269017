import React, { useRef } from "react";
import "./editclub.css";
// import images from "../../constants/images";
// import { useNavigate } from "react-router-dom";
// import NavbarSec from "../../common/NavbarSec";
import { useState, useEffect } from "react";
import axios from "axios";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";

const Editclub = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePrew, setSelectedImagePrew] = useState("");
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedLogopreview, setSelectedLogopreview] = useState(null);
  const locations = useLocation();
  const handleImageprofile = (e) => {
    const files = e.target.files;
    if (files) {
      setSelectedImage(files[0]);
      setSelectedImagePrew(URL.createObjectURL(files[0]));
    }

    // console.log("files 11", URL.createObjectURL(files[0]));
  };
  const handleImagelogo = (e) => {
    const files = e.target.files;
    setSelectedLogo(files[0]);
    setSelectedLogopreview(URL.createObjectURL(files[0]));
    // console.log("files 11", URL.createObjectURL(files[0]));
  };

  // crop image start

  const [getcropmodal, setCropmodal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const cropperRef = useRef(null);

  const onChangeCrop = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedDataUrl = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedDataUrl);

      // Convert the data URL to a Blob object
      const blob = dataURItoBlob(croppedDataUrl);

      setSelectedImage(blob);
      setSelectedImagePrew(image);

      setCropmodal(false);
    }
  };

  // Helper function to convert data URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // crop image end

  // crop cover start

  const [getcropmodal2, setCropmodal2] = useState(false);
  const [croppedImage2, setCroppedImage2] = useState(null);
  const [image2, setImage2] = useState("");
  const [cropData2, setCropData2] = useState("#");
  const cropperRef2 = useRef(null);

  const onChangeCrop2 = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage2(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData2 = () => {
    if (typeof cropperRef2.current?.cropper !== "undefined") {
      const croppedDataUrl = cropperRef2.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData2(croppedDataUrl);

      // Convert the data URL to a Blob object
      const blob = dataURItoBlob2(croppedDataUrl);

      setSelectedCover(blob);
      setSelectedCoverpreview(image2);

      setCropmodal2(false);
    }
  };

  // Helper function to convert data URI to Blob
  const dataURItoBlob2 = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // crop cover end

  //   logo start
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  //   logo end
  const handelFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // cover start
  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedCoverpreview, setSelectedCoverpreview] = useState(null);
  const handleImagepreview = (e) => {
    const files = e.target.files;
    setSelectedCover(files[0]);
    setSelectedCoverpreview(URL.createObjectURL(files[0]));
    console.log("files 11", files);
  };

  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedCover(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  // cover end

  // video start

  const [selectedVideos, setSelectedVideos] = useState([]);

  useEffect(() => {
    console.log("setSelectedVideos", selectedVideos);
  }, [selectedVideos]);
  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedVideos([...selectedVideos, ...files]);
  // };
  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 30; // Minimum duration in seconds
    const maxDuration = 180; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...selectedVideos, file]);
      }

      // Clean up the video element
      video.remove();
    }
  };
  const handleVideoDelete = (index) => {
    const newVideos = [...selectedVideos];
    newVideos.splice(index, 1);
    setSelectedVideos(newVideos);
  };

  // video functionality start

  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  // const [selectedVideos, setSelectedVideos] = useState([]);

  useEffect(() => {
    let timerInterval;
    const minDuration = 20;
    const maxDuration = 60;
    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;

          // Check if the recording time is less than the minimum duration
          // if (newTime < minDuration) {
          //   // Display an error or take other actions
          //   console.error(
          //     `Recording time should be at least ${minDuration} seconds.`
          //   );
          // }

          // Check if the recording time exceeds the maximum duration
          if (newTime >= maxDuration) {
            stopRecording1(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(0);
  };
  const showimage = () => {
    stopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
  };

  const recorderRef = useRef(null);

  const stopRecording = () => {
    setRecording(false);
    const minDuration = 20;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        if (recordingTime < minDuration) {
          // console.error(
          //   `Recording time should be at least ${minDuration} seconds.`
          // );
          alert(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(0);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };
  const stopRecording1 = () => {
    setRecording(false);
    const minDuration = 20;
    const maxDuration = 60;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        // if (recordingTime < minDuration) {
        //   // console.error(
        //   //   `Recording time should be at least ${minDuration} seconds.`
        //   // );
        //   alert(
        //     `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        //   );
        //   setRecordedChunks([]);
        //   setSelectedVideos([]);
        // } else {
        setRecordedChunks([blob]);
        setSelectedVideos([blob]);
        setRecordingTime(0);
        setIsModalVisiblevideo(false);
        // }
      });
    }
  };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(0);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };
  // video functionality end

  // video end

  //   multipel images start
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);

  const handleImagesChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const imageUrls = [];
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          imageUrls.push(reader.result);
          if (imageUrls.length === files.length) {
            setSelectedImages([...selectedImages, ...imageUrls]);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const [club, setClub] = useState([]);
  const [fullName, setFullName] = useState("");
  const [bio, setBio] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [logo, setlogo] = useState("");
  const [club_video_upload, setclub_video_upload] = useState("");

  const url = "https://www.ravi.host/api/get_all_club_detail";
  console.log("nitin 85", club);
  const [getvideoArray, setVideoArray] = useState([]);

  const editclubData = async () => {
    // FormData object
    const formData = new FormData();

    formData.append("club_id", locations.state.club_id);
    formData.append("user_id", userId);

    // Send the POST request with the FormData
    return await axios
      .post(url, formData)
      // .then((res) => setClub(res.data.result))
      .then((res) => {
        console.log("Success--->", JSON.stringify(res.data.result, null, 2));
        // Set the state variables with the data from the API
        setClub(res.data.result);
        setFullName(res.data.result.club_name);
        setBio(res.data.result.club_bio);
        setMobileNumber(res.data.result.phone_number);
        setEmail(res.data.result.club_email);
        setLocation(res.data.result.location);
        setVideoArray(res.data.result.club_videos);
        setuser_social_media_link(res.data.result.club_social_media_link);
        setclub_video_upload(res.data.result.club_video_upload);
        // setSelectedLogo(
        //   res.data.result.logo ==
        //     "https://www.ravi.host/public/assets/logo/"
        //     ? ""
        //     : res.data.result.logo
        // );
        // setSelectedCover(
        //   res.data.result.cover_photo ==
        //     "https://www.ravi.host/public/assets/logo/"
        //     ? ""
        //     : res.data.result.cover_photo
        // );
        setSelectedImagePrew(res.data.result.profile);
        setSelectedLogopreview(
          res.data.result.logo == "https://www.ravi.host/public/assets/logo/"
            ? ""
            : res.data.result.logo
        );
        setSelectedCoverpreview(
          res.data.result.cover_photo ==
            "https://www.ravi.host/public/assets/logo/"
            ? ""
            : res.data.result.cover_photo
        );
        setSelectedImages1(res.data.result.club_img_video);
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  };
  useEffect(() => {
    editclubData();
  }, []);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getuserVid2, setUserVideo2] = useState("");
  const [getpreviewVideoModal3, setPreviewVideoModal3] = useState(false);
  const [getuserVid3, setUserVideo3] = useState("");

  const removeImage1 = (club_img_id) => {
    const formData = new FormData();
    formData.append("club_img_id", club_img_id);

    console.log("formDataupdate------->", JSON.stringify(formData, null, 2));
    axios
      .post("https://www.ravi.host/api/Remove_club_img_video", formData)
      .then((Response) => {
        // Handle the response data here
        if (Response.data.status === 1) {
          editclubData();
          console.log(Response.data);
        } else {
          // Handle error
          // console.error("Error: ", Response.data.message);
        }
      })
      .catch((err) => {
        // Handle any errors that occur during the request
        console.error(err);
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const userId = localStorage.getItem("user_id");

  const updateClub = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("club_id", locations.state.club_id);
    formData.append("user_id", userId);
    formData.append("club_name", fullName);
    formData.append("email", email);
    formData.append("phone_number", mobileNumber);
    formData.append("bio", bio);
    formData.append("location", location);
    formData.append("profile", selectedImage);
    formData.append("logo", selectedLogo);
    formData.append("cover_photo", selectedCover);
    selectedImages.forEach((image) => {
      if (image !== "") {
        // formData.append("img_video[]", {
        //   uri: Platform.OS === "android" ? image : image.replace("file://", ""),
        //   name: "name",
        //   type: "image/jpeg",
        // });
        selectedImages.forEach((image, index) => {
          formData.append("img_video[]", image);
        });
      }
    });
    selectedVideos.forEach((image, index) => {
      formData.append("club_videos", image);
    });
    let linktag = "";
    var demolinktag = 0;
    for (let index = 0; index < inputValues.length; index++) {
      if (inputValues[index]) {
        demolinktag = demolinktag + 1;

        if (index > 0) {
          linktag += ",";
        }
        linktag += inputValues[index];
      }
    }
    formData.append("club_social_media_link_data", linktag);
    console.log("formdataupdate------->", JSON.stringify(formData, null, 2));

    axios
      .post("https://www.ravi.host/api/update_Club", formData)
      .then((Response) => {
        setIsLoading(false);

        if (Response.data.status === 1) {
          navigate("/clubsetting", {
            state: {
              club_id: locations.state.club_id,
            },
          });
          // props.navigation.goBack();
          setIsLoading(false);
          console.log(Response.data);
        } else {
          setIsLoading(false);
          toast.error(Response.data.message, { theme: "colored" });

          // alert(Response.data.message);
          // Assuming you have a way to display the modal or message
          // You can set the message and visibility state here
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };
  const handleImageChangeMin1Img = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };

  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [user_social_media_link, setuser_social_media_link] = useState([]);
  const [getDeleteSocialId, setDeleteSocialId] = useState("");
  const [getconformationmodel, setconformationmodel] = useState(false);

  const [inputValues, setInputValues] = useState([""]);

  const validateSocialMediaLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    // Test if the inputValue matches the URL pattern
    return urlPattern.test(link);
  };

  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);
    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      setAlertModal(true);
      setMsg("Please enter valid social media links");
    }
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  const [getalertodal, setAlertModal] = useState(false);
  const [getmsg, setMsg] = useState("");

  const deletedata = () => {
    const param = {
      club_id: locations.state.club_id,
      club_social_media_link_id: getDeleteSocialId,
    };
    console.log(param);
    axios
      .post(
        "https://www.ravi.host/api/delete_club_social_media_link",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.status == 1) {
          console.log("====================================");
          console.log("res", Response.data);
          console.log("====================================");
          setconformationmodel(false);
          setErrorMessage1(Response.data.message);
          setShowModal1(false);
          editclubData();
        } else {
          setErrorMessage1(Response.data.message);
          setShowModal1(true);
        }
      })
      .catch((err) => {
        console.log("dzv sd", err);
      });
  };

  const closemodal = () => {
    setconformationmodel(false);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };
  const openmodal1 = () => {
    setShowModal1(true);
  };

  const closemodal2 = () => {
    setAlertModal(false);
  };
  const openmodal2 = () => {
    setAlertModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <div style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}>
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Club Settings</p>
            <div></div>
          </div>
        </div>
        <div
          style={{ marginTop: "20px", marginBottom: "40px" }}
          className="ep_image_main_wrapp"
        >
          {selectedImagePrew ? (
            <img
              onClick={() => {
                navigate("/ProductViewImg2", {
                  state: {
                    img: selectedImagePrew ? selectedImagePrew : "",
                  },
                });
              }}
              src={selectedImagePrew}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
              }}
            />
          ) : (
            <img src={images.Person_white} className="ep_image_logo" alt="" />
          )}
          <button
            onClick={() => {
              setCropmodal(true);
              setImage("");
            }}
            className="ep_image_edit_btn"
          >
            <img src={images.edit_profile_pen} alt="" />
          </button>
        </div>
        <div className="ep_main_wrapp_txt_username">
          <input
            readOnly
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter name"
            className="ep_text_input_username"
          />
          <div className="ep_absolue_txt_username">
            <p className="ep_position_two_p">Club Name</p>
          </div>
        </div>

        {/* bio start */}
        <div style={{ height: "150px" }} className="ep_main_wrapp_txt_bio">
          <button>
            <img className="ep_mobile_edit_img" src={images.edit_pen} alt="" />
          </button>
          <textarea
            value={bio == "null" || bio == null ? "" : bio}
            onChange={(e) => {
              setBio(e.target.value);
            }}
            placeholder="My Bio"
            className="ep_text_input_bio"
          />
          <div className="ep_absolue_txt_username">
            <p className="ep_position_two_p">My Bio</p>
          </div>
        </div>
        {/* bio end */}
        {/* mobile number strat */}

        <div className="ep_main_wrapp_txt_username">
          <div className="ep_mobile_flex_wrapp">
            <p
              style={{
                color: "#ffff",
                fontSize: "14px",
                fontWeight: "350",
                fontFamily: "var(--font-600)",
                marginLeft: "18px",
              }}
            >
              +91
            </p>
            <div
              style={{
                width: "2px",
                background: "rgba(255, 255, 255, 1)",
                height: "20px",
                borderRadius: "1px",
                marginLeft: "10px",
              }}
            ></div>
            <input
              value={
                mobileNumber == "null" || mobileNumber == null
                  ? ""
                  : mobileNumber
              }
              readOnly
              placeholder="Mobile No"
              className="ep_text_input_username"
            />
          </div>

          <div className="ep_absolue_txt_username">
            <p className="ep_position_two_p">Mobile No</p>
          </div>
        </div>

        {/* mobile number end */}
        {/* <div className="edit_club_nm">
          <div className="edit_club_nm1">
            <img src={images.mail} className="user_icon_edit" alt="" />
          </div>
          <div className="edit_club_nm2">
            <p className="edit_nm_tag">Email id</p>
            <input
              type="email"
              className="input_edit"
              placeholder="Enter name"
              value={email}
              readOnly
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div> */}
        {/* email id start */}
        <div className="ep_main_wrapp_txt_username">
          <input
            value={email == null || email == "null" ? "" : email}
            readOnly
            placeholder="Email Id"
            className="ep_text_input_username"
          />
          <div className="ep_absolue_txt_username">
            <p className="ep_position_two_p">Email Id</p>
          </div>
        </div>
        {/* email id end */}
        <div className="ep_main_wrapp_txt_username">
          <input
            type="text"
            value={location == "null" ? "" : location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="Enter Location"
            className="ep_text_input_username"
          />
          <div className="ep_absolue_txt_username">
            <p className="ep_position_two_p">My Location</p>
          </div>
        </div>

        {/* <div style={{ margin: "5% 0" }}>
          {user_social_media_link &&
          user_social_media_link.length === 0 ? null : (
            <>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "var(--font-600) !important",
                  color: "#fff",
                  paddingTop: 20,
                  marginLeft: 4,
                }}
              >
                Uploaded Website & Media Links
              </p>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {user_social_media_link &&
                  user_social_media_link.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 20,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 14,
                          fontFamily: "var(--font-600) !important",
                          color: "#fff",
                          marginLeft: 4,
                          width: "87%",
                        }}
                      >
                        {item.club_social_media_link_data}
                      </p>
                      <button
                        onClick={() => {
                          setconformationmodel(true);
                          setDeleteSocialId(item.club_social_media_link_id);
                        }}
                      >
                        <img
                          style={{ width: 25, height: 25 }}
                          src={images.delete2}
                          alt="Delete"
                        />
                      </button>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div> */}
        {/* media link start */}
        <p className="ep_genral_txt mb-2">Add Website & Media Link</p>
        {inputValues.map((value, index) => (
          <div
            key={index}
            style={{ marginTop: "5px" }}
            className="ep_main_wrapp_txt_username"
          >
            <input
              placeholder="Enter here"
              className="ep_text_input_username"
              placeholderTextColor="gray"
              value={value}
              onChange={(e) => handleInputChange(e.target.value, index)}
            />
            {!value ? (
              <button>
                <img
                  className="ep_mobile_edit_img"
                  src={images.new_link_icon}
                  alt=""
                />
              </button>
            ) : (
              <div onClick={handleRemoveValue}>
                <img
                  src={images.side_new_close}
                  style={{
                    width: "20px",
                    height: "20px",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                  alt=""
                />
              </div>
            )}
          </div>
        ))}
        <button
          onClick={() => handleAddValue()}
          className="new_btn_wrapp"
          style={{
            height: "30px",
            width: "100px",
            marginTop: "10px",
            alignSelf: "flex-start",
          }}
        >
          <p style={{ fontSize: "10px" }}>Add More</p>
        </button>
        {/* media link end */}
        {/* uploded media link start */}
        {user_social_media_link &&
        user_social_media_link.length === 0 ? null : (
          <p className="ep_genral_txt mb-2">Uploaded Website & Media Links</p>
        )}
        {user_social_media_link &&
          user_social_media_link.map((item, index) => {
            return (
              <div className="ep_uploded_link_wrapp">
                <p className="ep_uploded_link_div_one">
                  {index + 1}. {item.club_social_media_link_data}
                </p>
                <button
                  onClick={() => {
                    setDeleteSocialId(item.club_social_media_link_id);
                    setShowModal1(true);
                  }}
                  className="ep_uploded_link_div_two"
                >
                  <img
                    className="uploaded_image_delete_btn"
                    src={images.new_delete}
                    alt=""
                  />
                </button>
              </div>
            );
          })}

        {/* uploded media link end */}
        {/* <div className="edit_club_profile">
          <div className="edit_club_profile_sub">
            <p style={{ fontSize: "14px", fontFamily: "var(--font-600)" }}>
              Update Logo
            </p>
            <div className="edit_logo_profile_file">
              <input
                type="file"
                accept="image/*"
                id="logoInput"
                onChange={(e) => handleImagelogo(e)}
                className="logo_add_new"
                style={{ display: "none" }}
              />
              <label htmlFor="logoInput" className="add_logo_set_club">
                + Add Logo
              </label>
              {selectedLogopreview && (
                <>
                  <img
                    onClick={() => {
                      setSelectedLogo("");
                      setSelectedLogopreview("");
                    }}
                    src={images.delete2}
                    style={{
                      // backgroundColor: "red",
                      width: "20px",
                      height: "20px",
                      position: "absolute",
                      zIndex: 11,
                      top: "10px",
                      right: "10px",
                    }}
                  />
                  <img
                    src={selectedLogopreview}
                    alt="Logo Preview"
                    className="edit_logo_profile_img"
                  />
                </>
              )}
            </div>
          </div>
        </div> */}
        <p className="ep_genral_txt mb-2">Update Logo</p>
        <div style={{ marginTop: "0px" }} className="new_creator_video_wrapp">
          {selectedLogopreview == null || selectedLogopreview == "" ? (
            <>
              <label
                htmlFor="file-input"
                className="new_creator_video_upload_button"
              >
                <img src={images.new_upload_logo} alt="" />
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*,.pdf"
                onChange={(e) => handleImagelogo(e)}
              />
            </>
          ) : (
            <>
              <div
                className="new_min1_img_preview_single"
                style={{
                  gridColumn: "span 3",
                  height: "160px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "94%",
                    marginTop: "5px",
                  }}
                  src={selectedLogopreview}
                  alt={`Preview`}
                />

                <button
                  style={{ marginTop: "10px", marginRight: "5px" }}
                  className="delete-button"
                  onClick={() => {
                    setSelectedLogo("");
                    setSelectedLogopreview("");
                  }}
                >
                  <img src={images.close_red} alt="" />
                </button>
              </div>
            </>
          )}
        </div>

        {/* Add Cover start */}
        {/* <div className="edit_club_profile2">
          <p style={{ fontSize: "14px", fontFamily: "var(--font-600)" }}>
            Update Cover
          </p>
          <div className="edit_club_profile_sub3">
            <div className="edit_club_profile_file">
              <button
                // className="add_edit_club"
                style={{
                  color: "#fff",
                  fontFamily: "var(--font-600)",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setCropmodal2(true);
                  setImage2("");
                }}
              >
                + Add Cover
              </button>
            </div>
            {selectedCoverpreview && (
              <>
                <img
                  onClick={() => {
                    setSelectedCover("");
                    setSelectedCoverpreview("");
                  }}
                  src={images.delete2}
                  style={{
                    // backgroundColor: "red",
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: 11,
                    top: "10px",
                    right: "10px",
                  }}
                />
                <img
                  src={selectedCoverpreview}
                  alt="Cover Preview"
                  className="edit_club_profile_img"
                />
              </>
            )}
          </div>
        </div> */}
        <p className="ep_genral_txt mb-2">Cover</p>
        <div style={{ marginTop: "0px" }} className="new_creator_video_wrapp">
          {selectedCoverpreview ==
            "https://www.ravi.host/public/assets/club_cover_photo/" ||
          selectedCoverpreview == null ||
          selectedCoverpreview == "" ? (
            <>
              <label
                htmlFor="file-input"
                className="new_creator_video_upload_button"
              >
                <img src={images.new_upload_logo} alt="" />
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*,.pdf"
                onChange={handleImagepreview}
              />
            </>
          ) : (
            <>
              <div
                className="new_min1_img_preview_single"
                style={{
                  gridColumn: "span 3",
                  height: "160px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "94%",
                    marginTop: "5px",
                  }}
                  src={selectedCoverpreview}
                  alt={`Preview`}
                />

                <button
                  style={{ marginTop: "10px", marginRight: "5px" }}
                  className="delete-button"
                  onClick={() => {
                    setSelectedCover("");
                    setSelectedCoverpreview("");
                  }}
                >
                  <img src={images.close_red} alt="" />
                </button>
              </div>
            </>
          )}
        </div>
        {/* Add Cover end */}

        {/* Add Authentication video start */}
        {/* {club_video_upload !== 1 ? 
        <div className="edit_club_profile2">
          <>
            {selectedVideos.length !== 0 ? null : (
              <p style={{ fontSize: "14px", fontFamily: "var(--font-600)" }}>
                {" "}
                Record Authentication Video
                <span
                  className="details_home_icon"
                  onClick={() => {
                    setShowModal1(true);
                    setErrorMessage1(
                      "Bring your Rare to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 30 seconds to 1 minute for the best impact."
                    );
                  }}
                >
                  {String.fromCharCode(9432)}
                </span>
              </p>
            )}
            {club_video_upload !== 1 && selectedVideos.length == 0 ? (
              <div className="">
                <div className="">
                  <label
                    onClick={() => {
                      setIsModalVisiblevideo(true);
                      setRecordedChunks([]);
                      setrecordedvideo(false);
                    }}
                    htmlFor="creator_vid"
                    className="upload-button"
                    style={{
                      width: "50%",
                      height: "100px",
                      textAlign: "center",
                    }}
                  >
                    <label htmlFor="videoInput" className="cover_upload_nm">
                      + Add Video
                    </label>
                  </label>
                  <input
                    disabled
                    id="creator_vid"
                    type="file"
                    accept="video/*"
                    // multiple
                    capture="camera"
                    onChange={(e) => {
                      handleVideoChange(e);
                      // setIsModalVisiblevideo(true);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </>
          {selectedVideos.length === 0 ? null : (
            <b>Uploaded Authentication Video</b>
          )}
          {selectedVideos.length > 0 &&
            selectedVideos.map((image, ind) => {
              return (
                <>
                  <div
                    className="image-preview"
                    key={ind}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px dashed gray",
                      borderRadius: "8px",
                      width: "170px",
                      height: "100px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setPreviewVideoModal3(true);
                        setUserVideo3(URL.createObjectURL(image));
                      }}
                      style={{
                        color: "var(--color-main)",
                        fontSize: "12px",
                        fontFamily: "var(--font-600)",
                      }}
                    >
                      Preview
                    </button>
                    <button
                      style={{ marginRight: "10px" }}
                      className="delete-button"
                      onClick={() => handleVideoDelete(ind)}
                    >
                      <img src={images.close_red} alt="" />
                    </button>
                  </div>
                </>
              );
            })}
        </div>
        : null}  */}

        {club_video_upload !== 1 && selectedVideos.length == 0 ? (
          <>
            <p style={{ marginTop: "0px" }} className="ep_genral_txt mb-2">
              Record Authentication video
            </p>
            <div
              style={{ marginTop: "0px" }}
              className="new_creator_video_wrapp"
            >
              {selectedVideos && selectedVideos.length <= 0 ? (
                <>
                  <label
                    onClick={() => {
                      setIsModalVisiblevideo(true);
                      setRecordedChunks([]);
                      setrecordedvideo(false);
                    }}
                    htmlFor="creator_vid"
                    // className="upload-button"
                    className="new_creator_video_upload_button"
                  >
                    <img src={images.new_upload_logo} alt="" />
                  </label>
                  <input
                    disabled
                    id="creator_vid"
                    type="file"
                    accept="video/*"
                    // multiple
                    capture="camera"
                    onChange={(e) => {
                      handleVideoChange(e);
                      // setIsModalVisiblevideo(true);
                    }}
                  />
                </>
              ) : (
                <div
                  className="image-previews"
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  {selectedVideos.map((image, index) => (
                    <div
                      className="image-preview"
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        width: "100%",
                        height: "100%",
                        background: "#000",
                      }}
                    >
                      {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                      <button
                        onClick={() => {
                          setPreviewVideoModal2(true);
                          setUserVideo2(URL.createObjectURL(image));
                        }}
                        // disabled={gettab3Completed}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          cursor: "default",
                          marginBottom: "25px",
                        }}
                      >
                        {/* Preview */}
                        {/* {getthumbImg != "" ? ( */}
                        <div
                          onClick={() => {
                            setPreviewVideoModal2(true);
                            setUserVideo2(URL.createObjectURL(image));
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          {/* <img
                                      src={images.play_white}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "contain",
                                        margin: "0px",
                                        position: "absolute",
                                        transform: "translate(-50%, -50%)",
                                        left: "50%",
                                        top: "50%",
                                        zIndex: 11,
                                        cursor: "pointer",
                                      }}
                                      alt=""
                                     
                                    /> */}
                        </div>
                        {/* ) : ( */}
                        Preview
                        {/* )} */}
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleVideoDelete(index)}
                        style={{ right: "14px" }}
                      >
                        <img src={images.close_red} alt="" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        ) : null}

        {/* {getvideoArray === 0 ? null : <b> Uploaded Authentication Video</b>}
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "20px",
            width: "100%",
            overflowY: "scroll",
            maxHeight: "90vh",
          }}
          // className="av_videos_wrapp"
        >
          {getvideoArray && getvideoArray.length > 0
            ? getvideoArray.map((item, index) => {
                console.log("item", item);
                return (
                  <div style={{ height: "100px" }} className="av_single_video">
                    <button
                      onClick={() => {
                        setUserVideo2(item.club_video);
                        setPreviewVideoModal2(true);
                      }}
                      className="av_prev_btn"
                      style={{ width: "110%" }}
                    >
                      {item.club_thumbnail != "" ? (
                        <div
                          style={{
                            width: "100%",
                            height: "95px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={item.club_thumbnail}
                            alt=""
                            style={{
                              width: "100%",
                              height: "95px",
                              objectFit: "cover",
                              margin: "0px",
                              objectPosition: "top",
                              borderRadius: "9px",
                            }}
                          />
                          <img
                            src={images.play_white}
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "contain",
                              margin: "0px",
                              position: "absolute",
                              transform: "translate(-50%, -50%)",
                              left: "50%",
                              top: "50%",
                              zIndex: 11,
                            }}
                            alt=""
                          />
                        </div>
                      ) : (
                        "Preview"
                      )}
                    </button>
                  </div>
                );
              })
            : null}
        </div> */}

        {/* record authentication video start */}
        <div style={{ width: "100%" }} className="mynfb_viewall_btn_wrapp">
          <p>Uploaded Authentication Video</p>
          <button
            onClick={() => {
              navigate("/NewClubRecordedVideo", {
                state: {
                  getvideoArray: getvideoArray,
                },
              });
            }}
            className="new_btn_wrapp"
            style={{
              color: "var(--color-white)",
              opacity: 0.6,
              height: "30px",
              width: "90px",
            }}
          >
            <p
              style={{
                fontFamily: "var(--font-400)",
                fontSize: "10px",
                width: "100%",
              }}
            >
              View all
            </p>
          </button>
        </div>
        <div className="ep_record_authentication_video_wrapp_2 mb-2">
          {getvideoArray && getvideoArray.length > 0
            ? getvideoArray.slice(0, 2).map((item, index) => {
                return (
                  <button
                    className="ep_record_authentication_video_div"
                    style={{ height: "150px", width: "48%" }}
                  >
                    {item.club_thumbnail != "" ? (
                      <div
                        className="ep_record_authentication_thumb_div"
                        style={{ height: "150px" }}
                      >
                        <img
                          className="ep_record_authentication_thumb"
                          src={item.club_thumbnail}
                          style={{ height: "150px" }}
                        />
                        <div
                          onClick={() => {
                            setPreviewVideoModal2(true);
                            setUserVideo2(item.club_video);
                          }}
                          className="ep_play_thumb_div"
                        >
                          <img
                            className="ep_video_play_btn"
                            src={images.d_play}
                          />
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setPreviewVideoModal2(true);
                          setUserVideo2(item.user_videos);
                        }}
                        className="ep_preview_btn_main_wrapp"
                      >
                        <p className="ep_p_tag_preview">Preview</p>
                      </button>
                      // "Preview"
                    )}
                  </button>
                );
              })
            : null}
        </div>
        {/* record authentication video end */}

        {/* <label htmlFor="product_name" className="form_input_label mt-2">
          Website & Media Links
        </label>

        <>
          {inputValues.map((value, index) => (
            <div
              style={{ marginBottom: "20px" }}
              key={index}
              className="cp_medialink_signle_wrapp"
            >
              <input
                style={{
                  width: "80%",
                  marginBottom: "0px",
                }}
                className="form_input_box"
                placeholder="Enter a Link"
                placeholderTextColor="gray"
                value={value}
                onChange={(e) => handleInputChange(e.target.value, index)}
              />
              {index === inputValues.length - 1 ? (
                <div className="cp_ml_btns_wrapp">
                  <button className="cp_ml_plus_btn" onClick={handleAddValue}>
                    +
                  </button>
                  {inputValues.length > 1 && (
                    <button
                      className="cp_ml_mius_btn"
                      onClick={handleRemoveValue}
                    >
                      -
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ))}
        </> */}
        {/* Add Authentication video end */}

        <div className="update_all_photos">
          <p className="ep_genral_txt mb-2">Update images/videos</p>
          <div style={{ marginTop: "0px" }} className="new_creator_video_wrapp">
            <div className="new_creator_video_upload_button">
              <input
                type="file"
                id="imagesInput"
                onChange={handleImageChangeMin1Img}
                className="multi_club_img"
                multiple
                accept="image/*"
                style={{ display: "none", zIndex: 11 }}
              />
              <label
                htmlFor="imagesInput"
                className="new_creator_video_upload_button"
              >
                <img src={images.new_upload_logo} alt="" />
              </label>
            </div>
          </div>
          <div className="update_all_photos_show">
            {selectedImages.map((imageUrl, index) => (
              <div key={index} className="preview_image_wrapper">
                <img
                  src={URL.createObjectURL(imageUrl)}
                  alt={`Image ${index + 1}`}
                  className="preview_image_edit_club"
                  onClick={() => removeImage(index)}
                />
                <img
                  src={images.new_close_logo}
                  onClick={() => removeImage(index)}
                  className="delete_img_club"
                  style={{ marginTop: "10px", marginRight: "10px" }}
                  alt="Delete"
                />
              </div>
            ))}
          </div>
          <div className="update_all_photos_show">
            {selectedImages1.map((imageUrl, index) => (
              <div key={index} className="preview_image_wrapper">
                <img
                  src={imageUrl.img_video}
                  alt={`Image ${index + 1}`}
                  className="preview_image_edit_club"
                  // onClick={() => removeImage(index)}
                />
                <img
                  src={images.delete2}
                  onClick={() => removeImage1(imageUrl.club_img_id)}
                  className="delete_img_club"
                  style={{ marginTop: "8px", marginRight: "8px" }}
                  alt="Delete"
                />
              </div>
            ))}
          </div>
        </div>

        <center>
          <button
            onClick={() => {
              updateClub();
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Update</p>
          </button>
        </center>
      </div>
      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 12,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp_2">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  />
                </div>
                <p className="nav_screen_name">Preview</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {getpreviewVideoModal3 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 12,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp_2">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal3(false)}
                  />
                </div>
                <p className="nav_screen_name">Preview</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {getalertodal && (
        // <div className="cusModal_main_wrapp" style={{ bottom: "0px" }}>
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{getmsg}</p>
        //       <div className="cm_hr"></div>
        //       <Link className="cm_btn" onClick={() => setAlertModal(false)}>
        //         Okay
        //       </Link>
        //     </div>
        //   </div>
        // </div>

        // <NewModal
        //   title=""
        //   msg={getmsg}
        //   setShowModal={openmodal2}
        //   closemodal={closemodal2}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={getmsg}
          setShowModal={setAlertModal}
          okfunction={closemodal2}
        />
      )}
      {getconformationmodel && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       {/* <b>Information</b> */}
        //       <br />
        //       <p className="modal-message">
        //         {" "}
        //         Are you sure you want to delete link ?
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_two_buton">
        //       <button className="close_forgot" onClick={deletedata}>
        //         Okay
        //       </button>
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setconformationmodel(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </div>
        // <ReactModal
        //   isOpen={setconformationmodel}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       Are you sure you want to delete link ?
        //     </p>

        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={deletedata}>
        //         Okay
        //       </button>
        //       <button className="close_forgot" onClick={closemodal}>
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={true}
          title=""
          msg="Are you sure you want to delete link ?"
          setShowModal={setShowModal1}
          okfunction={deletedata}
        />
      )}
      {/* {showModal1 && (
        // <AlertModal
        //   msg={errorMessage1}
        //   setShowModal={setShowModal1}
        //   title="Information"
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage1}
        //   setShowModal={openmodal1}
        //   closemodal={closemodal1}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage1}
          setShowModal={setconformationmodel}
          okfunction={closemodal1}
        />
      )} */}
      {showModal1 && (
        <>
          <div className="v2modal_main_wrapp">
            <div className="v2modal_inner_wrapp">
              <div className="v2modal_fixed_logo_wrapp">
                <img src={images.logo} alt="" />
              </div>
              <button
                className="v2modal_close_img"
                onClick={() => {
                  setShowModal1(false);
                }}
              >
                <img
                  src={images.new_close_logo}
                  className="v2modal_close_img"
                  alt=""
                />
              </button>
              <div className="v2modal_fixed_text_wrapp">
                <p>{"Are you sure went to remove ?"}</p>
              </div>

              <div className="v2modal_fixed_btns_wrapp">
                {/* {canclebtn && ( */}
                <button
                  className="v2modal_btn1"
                  onClick={() => {
                    setShowModal1(false);
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                <button
                  className="v2modal_btn2"
                  onClick={() => {
                    deletedata();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* new record video modal start */}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{
            zIndex: 111,
            width: "100%",
            height: "95vh",
            bottom: "-5px",
          }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "20px",
                          fontFamily: "var(--font-600)",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        {recordingTime} seconds
                      </p>
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                      <img
                        onClick={stopRecording}
                        src={images.stop_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  showimage();
                  // setBodyScrollEnabled(true);
                }}
              >
                Cancel
              </button>
              {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      )}{" "}
      {/* new record video modal end */}
      {/* crop modal start */}
      {getcropmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                maxWidth: "90%",
                margin: "10px auto",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                {image == "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="imageInput"
                      style={{ marginBottom: "10px" }}
                    >
                      Upload Image:
                    </label>
                    <div
                      className="image-upload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="file-input" className="upload-button">
                        <img src={images.gallery_icon} alt="" />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={onChangeCrop}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <br />
                    <br />
                    <Cropper
                      ref={cropperRef}
                      style={{ height: 250, width: "100%" }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      guides={true}
                      aspectRatio={4 / 4}
                    />
                  </>
                )}
              </div>
              {image !== "" && (
                <div>
                  <div
                    className="box"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <h1>Preview</h1>
                    <div
                      className="img-preview"
                      style={{
                        width: "100%",
                        height: "150px",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  {/* <div
                  className="box"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <h1>
                    <span>Crop</span>
                    <button style={{ float: "right" }} onClick={getCropData}>
                      Crop Image
                    </button>
                  </h1>
                  <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                </div> */}
                </div>
              )}
            </div>
          </div>
          <div
            className="crop_img_yes_no_btn_wrapp"
            style={{ justifyContent: image == "" ? "center" : "space-between" }}
          >
            <button
              onClick={() => {
                setCropmodal(false);
                console.log("getcropmodal", getcropmodal);
              }}
            >
              <IoCloseSharp color="#fff" size={28} />
            </button>
            {image !== "" && (
              <button onClick={getCropData}>
                <IoCheckmarkSharp color="#fff" size={28} />
              </button>
            )}
          </div>
        </div>
      )}
      {/* crop modal end */}
      {/* crop modal 2 start */}
      {getcropmodal2 && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                maxWidth: "90%",
                margin: "10px auto",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                {image2 == "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="imageInput"
                      style={{ marginBottom: "10px" }}
                    >
                      Upload Image:
                    </label>
                    <div
                      className="image-upload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="file-input" className="upload-button">
                        <img src={images.gallery_icon} alt="" />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={onChangeCrop2}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <br />
                    <br />
                    <Cropper
                      ref={cropperRef2}
                      style={{ height: 250, width: "100%" }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".img-preview2"
                      src={image2}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      guides={true}
                      aspectRatio={16 / 9}
                    />
                  </>
                )}
              </div>
              {image2 !== "" && (
                <div style={{ width: "100%" }}>
                  <div
                    className="box"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <h1>Preview</h1>
                    <div
                      className="img-preview2"
                      style={{
                        width: "100%",
                        height: "150px",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                  {/* <div
                  className="box"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <h1>
                    <span>Crop</span>
                    <button style={{ float: "right" }} onClick={getCropData}>
                      Crop Image
                    </button>
                  </h1>
                  <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                </div> */}
                </div>
              )}
            </div>
          </div>
          <div
            className="crop_img_yes_no_btn_wrapp"
            style={{
              justifyContent: image2 == "" ? "center" : "space-between",
            }}
          >
            <button
              onClick={() => {
                setCropmodal2(false);
                console.log("getcropmodal", getcropmodal2);
              }}
            >
              <IoCloseSharp color="#fff" size={28} />
            </button>
            {image2 !== "" && (
              <button onClick={getCropData2}>
                <IoCheckmarkSharp color="#fff" size={28} />
              </button>
            )}
          </div>
        </div>
      )}
      {/* crop modal 2 end */}
      <ToastContainer />
    </div>
  );
};

export default Editclub;
