import React from "react";
import "./CreateMilestone.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import images from "../../constants/images";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa6";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

const CreateMilestone = () => {
  const userId = localStorage.getItem("user_id");

  const navigate = useNavigate();
  const location = useLocation();
  const [getloading, setLoading] = useState(false);
  const [selectedImagesMin1Img, setSelectedImagesMin1Img] = useState([]);
  const [getconditionMin1Img, setconditionMin1Img] = useState(false);

  // datepicker functionality start
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  useEffect(() => {
    console.log("location.state.legacy_year", location.state.legacy_year);
  }, []);

  const daysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    if (selectedYear <= currentYear) {
      setYear(selectedYear);
      if (selectedYear === currentYear) {
        setMonth(currentMonth);
        setDay(currentDate);
      }
    }
    // Reset month and day if the year changes
    setMonth("");
    setDay("");
  };

  const handleMonthChange = (e) => {
    setMonth(parseInt(e.target.value));
    // Reset day if the month changes
    setDay("");
  };

  const handleDayChange = (e) => {
    setDay(parseInt(e.target.value));
  };

  const formatValue = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const days = Array.from(
    { length: daysInMonth(year, month) },
    (_, i) => i + 1
  );

  // datepicker functionality end

  // upload images functionality start

  const handleImageChangeMin1Img = (e) => {
    const files = Array.from(e.target.files);
    console.log("files:", files.length);
    if (files.length == 11) {
    } else {
      setSelectedImagesMin1Img((prevSelectedImages) => [
        ...prevSelectedImages,
        ...files,
      ]);
    }
    // setImageFiles(e.target.files[0]);
  };

  const handleImageDelete2 = (index) => {
    const newImages = [...selectedImagesMin1Img];
    newImages.splice(index, 1);
    setSelectedImagesMin1Img(newImages);
    setconditionMin1Img(true);
  };

  // upload images functionality end

  /// vishal ahir code start

  const [getmilestonename, setmilestonename] = useState("");

  // addusermileston api function start

  const Addusermilestone = async () => {
    if (getmilestonename == "") {
      toast.error("Please enter Milestone name", {
        theme: "colored",
      });
    } else if (selectedImagesMin1Img.length <= 0) {
      toast.error("Please upload at least one image.", {
        theme: "colored",
      });
    } else if (getdescription == "") {
      toast.error("Please enter description", {
        theme: "colored",
      });
    } else if (year == "") {
      toast.error("Please Select Date of creation", { theme: "colored" });
    } else {
      let linktag = "";
      var demolinktag = 0;
      for (let index = 0; index < inputValues.length; index++) {
        if (inputValues[index]) {
          demolinktag = demolinktag + 1;

          if (index > 0) {
            linktag += ",";
          }
          linktag += inputValues[index];
        }
      }

      setLoading(true);
      const formdata = new FormData();
      formdata.append("user_id", userId);
      formdata.append("m_name", getmilestonename);
      // if (inputValues == [""]) {
      //   formdata.append("m_image_link", "");
      // } else {
      //   formdata.append("m_image_link", linktag);
      // }

      selectedImagesMin1Img.forEach((image, index) => {
        formdata.append("m_image_link[]", image);
      });
      formdata.append("description", getdescription);
      if (month == "" && day == "") {
        formdata.append("date_of_creation", year);
      } else if (day == "") {
        formdata.append("date_of_creation", `${year}-${month}`);
      } else {
        formdata.append("date_of_creation", `${year}-${month}-${day}`);
      }
      let linktag1 = "";
      var demolinktag1 = 0;
      for (let index = 0; index < inputValues1.length; index++) {
        if (inputValues1[index]) {
          demolinktag1 = demolinktag1 + 1;

          if (index > 0) {
            linktag1 += ",";
          }
          linktag1 += inputValues1[index];
        }
      }
      if (inputValues1 == [""]) {
        formdata.append("media_link", "");
      } else {
        formdata.append("media_link", linktag1);
      }
      formdata.append("legacy_year", location.state.legacy_year);
      await axios
        .post("https://www.ravi.host/api/add_user_milestone", formdata, {})
        .then((res) => {
          setLoading(false);
          console.log("res", res.data);
          if (res.data.status === 1) {
            if (location.state && location.state.edit_param_protect == 1) {
              // navigate(-1);
              navigate("/EditProduct", {
                state: {
                  item: location.state && location.state.item,
                  legacy_year: location.state.legacy_year,
                },
              });
            } else if (location.state && location.state.createpro_param == 1) {
              navigate("/CreateProduct", {
                state: {
                  legacy_year: location.state.legacy_year,
                  getpendingBondId_data: location.state.getpendingBondId_data,
                  milestonestep: location.state.milestonestep,
                },
              });
              // navigate(-1);
            } else if (
              location.state &&
              location.state.createpro_Newapproval == 1
            ) {
              navigate("/NewApprovalCreate", {
                state: {
                  legacy_year: location.state.legacy_year,
                  getpendingBondId_data: location.state.getpendingBondId_data,
                  milestonestep: location.state.milestonestep,
                  pending_bond_id: location.state.pending_bond_id,
                },
              });
              // navigate(-1);
            } else {
              navigate("/LegacyDashbord", {
                // state: {
                //   user_id: userId,
                // },
                state: {
                  first_time_popup: 0,
                  first_time_home: 0,
                },
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  // add usermilestone function end

  // milestone image link start

  // media links start
  const [inputValues, setInputValues] = useState([""]);
  const [linkvalid, setvalid] = useState(false);
  const [getdescription, setdescription] = useState("");

  const validateSocialMediaLink = async (link) => {
    try {
      const formdata = new FormData();
      formdata.append("imageUrl", link);

      const response = await axios.post(
        "https://www.ravi.host/api/chack_image",
        formdata,
        {}
      );

      console.log(response.data);
      return response.data.status === 1;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleAddValue = async () => {
    console.log("inputValues", inputValues);
    const validationResults = await Promise.all(
      inputValues.map(validateSocialMediaLink)
    );

    if (validationResults.every((isValid) => isValid)) {
      setvalid(true);
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      toast.error("Please enter valid social media links", {
        theme: "colored",
      });
    }
  };

  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    console.log("text", text);
    setInputValues(updatedInputValues);
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };
  // milestone image link end

  // media link start
  const [inputValues1, setInputValues1] = useState([""]);

  const validateSocialMediaLink1 = (link) => {
    // Validation logic for Instagram
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }
    if (link.startsWith("Https://") || link.startsWith("Http://")) {
      return true;
    }
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for YouTube
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for LinkedIn
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for Twitter
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    return false; // If none of the above conditions match, consider it an invalid link
  };
  const handleAddValue1 = () => {
    const isValid = inputValues1.every(validateSocialMediaLink1);

    if (isValid) {
      // setvalid(true);
      setInputValues1([...inputValues1, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      toast.error("Please enter valid social media links", {
        theme: "colored",
      });
    }
  };
  const handleInputChange1 = (text, index) => {
    const updatedInputValues = [...inputValues1];
    updatedInputValues[index] = text;
    console.log("text", text);
    setInputValues1(updatedInputValues);
  };

  const handleRemoveValue1 = () => {
    if (inputValues1.length > 1) {
      const updatedInputValues = [...inputValues1];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues1(updatedInputValues);
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  // navigate(-1);
                  if (
                    location.state &&
                    location.state.edit_param_protect == 1
                  ) {
                    // navigate(-1);
                    navigate("/EditProduct", {
                      state: {
                        item: location.state && location.state.item,
                        // legacy_year: location.state.legacy_year,
                      },
                    });
                  } else if (
                    location.state &&
                    location.state.createpro_param == 1
                  ) {
                    navigate("/CreateProduct", {
                      state: {
                        item: location.state && location.state.item,
                        getpendingBondId_data:
                          location.state.getpendingBondId_data,
                      },
                    });
                    // navigate(-1);
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Create Milestone</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>

            {/* form start */}

            <div
              className="enter_detail_main_wrapp"
              style={{ marginTop: "1rem" }}
            >
              {/* milestone name start */}
              <div className="creator_name_wrapp">
                <p>Add Milestone Name</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter milestone name"
                    onChange={(e) => {
                      setmilestonename(e.target.value);
                    }}
                    style={{ marginBottom: "10px" }}
                    //   value={getownerName}
                  />
                </div>
              </div>
              {/* milestone name end */}

              {/* image link start */}
              {/* <div className="creator_name_wrapp">
                <p>Paste Milestone Image Link</p>
                {inputValues.map((value, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px",
                      background: "#000",
                      border: "2px dashed #ff004c",
                      paddingRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      // readOnly={value}
                      type="text"
                      placeholder="Paste here"
                      onChange={(e) => handleInputChange(e.target.value, index)}
                      //   value={getownerName}
                      className="dashed_input_box"
                    />
                    {!value ? (
                      <img
                        src={images.copy_link_icon}
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    ) : (
                      <div onClick={handleRemoveValue}>
                        <img
                          src={images.side_new_close}
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                ))}
                <button
                  onClick={() => handleAddValue()}
                  className="new_btn_wrapp"
                  style={{ height: "30px", width: "100px", marginTop: "10px" }}
                >
                  <p style={{ fontSize: "10px" }}>Add More</p>
                </button>
              </div> */}
              {/* image link end */}

              {/* upload images section start */}

              <div
                className="npasset_yes_sec_wrapp"
                style={{ alignItems: "flex-start" }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    width: "100%",
                    textAlign: "left",
                    lineHeight: "30px",
                  }}
                >
                  Please upload at least one image.
                </p>

                {/* select at least one image start */}

                <div
                  className="new_creator_video_wrapp"
                  style={{ margin: "0px auto" }}
                >
                  <label
                    // onClick={() => {
                    //   setIsModalVisiblevideo(true);
                    //   setRecordedChunks([]);
                    //   setrecordedvideo(false);
                    // }}
                    htmlFor="min_1_img"
                    // className="upload-button"
                    className="new_creator_video_upload_button"
                  >
                    <img src={images.new_upload_logo} alt="" />
                  </label>
                  <input
                    id="min_1_img"
                    type="file"
                    accept="image/*"
                    // capture="camera"
                    multiple
                    maxLength={2}
                    onChange={handleImageChangeMin1Img}
                  />
                </div>
                {selectedImagesMin1Img && selectedImagesMin1Img.length > 0 && (
                  <div className="new_min1_img_preview_wrapp">
                    {selectedImagesMin1Img.map((image, index) => (
                      <div className="new_min1_img_preview_single" key={index}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Preview ${index}`}
                        />

                        <button
                          className="delete-button"
                          onClick={() => handleImageDelete2(index)}
                        >
                          <img src={images.close_red} alt="" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* select at least one image end */}
              </div>

              {/* upload images section end */}

              {/* description box start */}
              <div className="npasset_yes_sec_wrapp">
                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    marginTop: "10px",
                    lineHeight: "14px",
                  }}
                >
                  Provide a brief description or summary of your milestone
                </p>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    value={getdescription}
                    onChange={(e) => setdescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              {/* description box end */}

              {/* date of creation start */}
              <div
                className="npasset_yes_sec_wrapp"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    // width: "100%",
                    fontSize: "12px",
                    lineHeight: "14px",
                  }}
                >
                  {/* Date of Creation */}
                  Selected Year
                </p>
                {/* datepicker inputs start */}

                {/* <div
                  className="datepicker_inputs_main_wrapp"
                  style={{ marginTop: "10px", marginBottom: "0px" }}
                >
                  <div
                    className="datepicker_single_input"
                    style={{ height: "40px" }}
                  >
                    <select value={day} onChange={handleDayChange}>
                      <option value="" disabled>
                        DD
                      </option>
                      {days.map((d) => (
                        <option
                          key={d}
                          value={d}
                          disabled={
                            year === currentYear &&
                            month === currentMonth &&
                            d > currentDate
                          }
                        >
                          {formatValue(d)}
                        </option>
                      ))}
                    </select>
                  </div>
                  -
                  <div
                    className="datepicker_single_input"
                    style={{ height: "40px" }}
                  >
                    <select value={month} onChange={handleMonthChange}>
                      <option value="" disabled>
                        MM
                      </option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                        <option
                          key={m}
                          value={m}
                          disabled={year === currentYear && m > currentMonth}
                        >
                          {formatValue(m)}
                        </option>
                      ))}
                    </select>
                  </div>
                  -
                  <div
                    className="datepicker_single_input"
                    style={{ height: "40px" }}
                  >
                    <select value={year} onChange={handleYearChange}>
                      <option value="" disabled>
                        YYYY
                      </option>
                      {Array.from(
                        {
                          length: currentYear - 1300 + 1,
                        },
                        (_, i) => currentYear - i
                      ).map((y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                {/* datepicker inputs end */}

                <p
                  style={{
                    color: "var(--color-main)",
                    fontsize: "1rem",
                    fontFamily: "var(--font-600)",
                    width: "auto",
                  }}
                >
                  {location.state.legacy_year}
                </p>
              </div>
              {/* date of creation end */}

              {/* media link start */}
              <div className="creator_name_wrapp">
                <p>Add Media Links</p>
                {inputValues1.map((value, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px",
                      background: "#000",
                      border: "2px dashed #ff004c",
                      paddingRight: "10px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Paste here"
                      onChange={(e) =>
                        handleInputChange1(e.target.value, index)
                      }
                      //   value={getownerName}
                      className="dashed_input_box"
                    />
                    {!value ? (
                      <img
                        src={images.copy_link_icon}
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    ) : (
                      <div onClick={handleRemoveValue1}>
                        <img
                          src={images.side_new_close}
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                ))}
                <button
                  onClick={() => handleAddValue1()}
                  className="new_btn_wrapp"
                  style={{ height: "30px", width: "100px", marginTop: "10px" }}
                >
                  <p style={{ fontSize: "10px" }}>Add More</p>
                </button>
              </div>
              {/* media link end */}

              {/* btn */}
              <button
                onClick={Addusermilestone}
                className="new_btn_wrapp mx-1 mt-2"
              >
                <p>Submit & Continue</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default CreateMilestone;
