import React from "react";
import "./CreateLegacy.css";
import images from "../../constants/images";
import { useState } from "react";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { add_user_legacy } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";

const CreateLegacy = () => {
  const userId = localStorage.getItem("user_id");
  const [getloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const [getlegacyName, setLegacyName] = useState("");

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    if (selectedYear <= currentYear) {
      setYear(selectedYear);
    }
  };

  const AddLegacy = async () => {
    if (getlegacyName == "") {
      toast.error("Please enter legacy name", {
        theme: "colored",
      });
    } else if (year == "" || year == null || year == undefined) {
      toast.error("Please select legacy year", {
        theme: "colored",
      });
    } else {
      setLoading(true);
      const parama = {
        user_id: userId,
        legacy_name: getlegacyName,
        legacy_year: year,
      };
      await axios
        .post(add_user_legacy, parama)
        .then((res) => {
          setLoading(false);
          if (res.data.status == 1) {
            navigate("/LegacyDashbord", {
              state: {
                first_time_popup: 0,
                first_time_home: 0,
              },
            });
          } else {
            toast.error(res.data.message, {
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate("/LegacyDashbord", {
                    state: {
                      first_time_popup: 0,
                      first_time_home: 0,
                    },
                  });
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Add Legacy</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>
            <div className="enter_detail_main_wrapp" style={{ height: "60vh" }}>
              <div className="creator_name_wrapp">
                <p>Enter Name</p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={getlegacyName}
                    onChange={(e) => {
                      setLegacyName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="creator_name_wrapp">
                <p>select Year</p>
                <div className="datepicker_single_input">
                  <select
                    value={year}
                    onChange={handleYearChange}
                    style={{ padding: "10px 40%" }}
                  >
                    <option value="" disabled>
                      Year
                    </option>
                    {Array.from(
                      {
                        length: currentYear - 1300 + 1,
                      },
                      (_, i) => currentYear - i
                    ).map((y) => (
                      <option
                        key={y}
                        value={y}
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          left: "5%",
                          width: "90%",
                        }}
                      >
                        {y}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* btn */}
            <button onClick={AddLegacy} className="new_btn_wrapp mx-1 mt-3">
              <p>Create</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default CreateLegacy;
