import React, { useState, useEffect } from "react";
import "./clubmanager.css";
// import NavbarSec from "../../common/NavbarSec";
// import images from "../../constants/images";
import axios from "axios";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Clubmanager = () => {
  const [userNames, setUserNames] = useState([]);
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const [isloading, setIsLoading] = useState(false);

  const [user, setUser] = useState([]);

  const url = "https://www.ravi.host/api/get_all_club_detail";
  console.log("nitin 122", user);

  const fetchUserdata = async () => {
    // FormData object
    setIsLoading(true);
    const formData = new FormData();

    formData.append("club_id", location.state.club_id);
    formData.append("user_id", userId);

    // Send the POST request with the FormData
    await axios
      .post(url, formData)
      .then((res) => {
        setIsLoading(false);
        setUser(res.data.result.manager_detail);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle errors
      });
  };

  useEffect(() => {
    fetchUserdata();
  }, []);

  // delete manager

  // status accepts
  const managersurl = "https://www.ravi.host/api/delete_club_manager";
  const [deletemanager, setDeletemanager] = useState([]);

  console.log("nitin 41", deletemanager);

  const fetchmanagerData = async (id) => {
    // FormData object
    const formData = new FormData();

    formData.append("manager_id", id);
    formData.append("club_id", location.state.club_id);

    // Send the POST request with the FormData
    return await axios
      .post(managersurl, formData)
      .then((res) => {
        setDeletemanager(res.data);
        fetchUserdata();
      })
      .catch((error) => {
        // Handle errors
      });
  };

  const handleDeleteUser = (indexToRemove) => {
    // Create a new array without the user to be removed
    const updatedUserNames = userNames.filter(
      (_, index) => index !== indexToRemove
    );
    setUserNames(updatedUserNames);
  };

  const navigate = useNavigate();

  return (
    <div className="page_main_wrapp">
      {isloading ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <div className="page_base_wrapp_2">
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Club Manager</p>
            <div></div>
          </div>

          {/* <NavbarSec name=" " /> */}
          <div className="club_manager_new">
            {user && user.length > 0 ? (
              user.map((item, index) => (
                <div className="club_manager_gradinat_wrapp">
                  <div className="main_manager_club_set" key={index}>
                    {/* You can replace this with your user image */}

                    <div className="nav13_profile_wrapp">
                      <div>
                        <img
                          src={item.profile}
                          className="nav13_img_of_usermenu"
                          alt=""
                        />
                      </div>
                    </div>
                    <p className="name_of_manager">{item.firstname}</p>
                    <img
                      src={images.new_delete_icon}
                      className="delete_manager_club"
                      alt=""
                      onClick={() => fetchmanagerData(item.club_manager_id)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "var(--font-600)",
                  color: "var(--color-white)",
                  margin: "40px auto",
                  textAlign: "center",
                }}
              >
                No data found <br />
                Please add manager
              </p>
            )}
          </div>
          <Link to={"/addowners"} state={{ club_id: location.state.club_id }}>
            <img src={images.round_plus} className="plus_new" alt="" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default Clubmanager;
