import { Outlet, Navigate } from "react-router-dom";
import { useUserContext } from "../context/user_context";

const PrivateRoutes = () => {
  // const { is_login } = useUserContext();
  let is_login = localStorage.getItem("is_login");

  //   let auth = { isLogin: false };
  console.log("test is_login", is_login);

  return is_login !== null ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
