import React, { useState } from "react";
import "./exclusiveclubnfb.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";

const Exclusiveclubnfb = () => {
  const location = useLocation();
  const [getdata, setdata] = useState(location.state.item);
  const navigate = useNavigate();

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name="" />
        <div className="exclusiveclubnfb_main_details">
          {getdata &&
            getdata.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate("/ProductView", {
                      state: {
                        release_bond_id: item.release_bond_id,
                        product_id: item.product_id,
                        is_nfb: item.is_nfb,
                        uniq_code: item.uniq_code,
                        theme_index: item.theme_index,
                        share_by_user: item.share_by_user,
                        club_id: item.club_id,
                      },
                    });
                  }}
                  className="exclusiveclubnfb_main"
                >
                  <img
                    src={item.product_image_video[0].img_video}
                    className="exclusiveclubnfb_img"
                    alt=""
                  />
                  <div className="exclusiveclubnfb_nm">
                    <p className="exclusiveclubnfb_number">
                      {item.product_name}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Exclusiveclubnfb;
