import React, { useEffect, useState } from "react";
import "./Bids_13.css";
import images from "../../constants/images";
import { Link, useLocation } from "react-router-dom";
import BackButton from "../../components/BackButton";
import Modal from "react-modal";
import {
  get_user_pending_bid,
  bid_withdraw_amount_status,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import { useNavigate } from "react-router-dom/dist";
import { IoClose } from "react-icons/io5";
import NewModal from "../../common/newmodal/NewModal";
import NewFooter from "../../common/newfooter/NewFooter";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const Bids_13 = () => {
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawConfirmationOpen, setIsWithdrawConfirmationOpen] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [isValidAmount, setValidAmount] = useState(true);
  const [past_bid_id, setpast_bid_id] = useState("");
  const [past_price, setpast_price] = useState("");
  const [past_total_price, setpast_total_price] = useState("");
  const [share_by_user, setShare_by_user] = useState("");
  const [bidbtn, setbidbtn] = useState(2);
  useEffect(() => {
    setbidbtn(2);
  }, []);

  const handleOpenModal = (item) => {
    setpast_bid_id(item.bid_id);
    setpast_price(item.bid_amount);
    setpast_total_price(item.total);
    setShare_by_user(item.share_by_user);
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleOpenWithdrawConfirmation = () => {
    setIsWithdrawConfirmationOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseWithdrawConfirmation = () => {
    setIsWithdrawConfirmationOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    setValidAmount(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (amount && !isNaN(amount) && parseFloat(amount) > 0) {
      console.log("Amount is valid. Perform action.");
      setAmount("");
    } else {
      console.log("Invalid amount. Please enter a valid number.");
      setValidAmount(false);
    }
  };

  const handleWithdrawBid = () => {
    console.log("Withdrawal action performed.");
    setloading(true);
    const param = {
      bid_id: past_bid_id,
    };
    console.log("param", param);
    axios
      .post(bid_withdraw_amount_status, param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          handleCloseWithdrawConfirmation();
          // this.setState({
          //   // isok: 1,
          //   msg: Response.data.message,
          //   isModalVisible: true,
          //   doublemodal: false,
          // });
          setErrorMessage(Response.data.message);
          setShowModal(true);
          getReleasebond();
        } else {
          handleCloseWithdrawConfirmation();
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const bidsnm = [
    { id: 1, name: "My clubs name 1" },
    { id: 2, name: "My clubs name 2" },
    { id: 3, name: "My clubs name 3" },
    { id: 4, name: "My clubs name 4" },
    { id: 5, name: "My clubs name 5" },
    { id: 6, name: "My clubs name 6" },
  ];
  const filteredClubs = bidsnm.filter((bidsnm) =>
    bidsnm.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const userId = localStorage.getItem("user_id");
  const [getreleasedata, setreleasedata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    getReleasebond();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.bid_amount)
        ? String(item.bid_amount).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setreleasedata(newData);
    console.log("asvsvsds", newData);
  };

  const getReleasebond = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    axios
      .post(get_user_pending_bid, param)
      .then((Response) => {
        setloading(false);

        if (Response.data.status == 1) {
          setreleasedata(Response.data.result);
          setsearchdata(Response.data.result);
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }

        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const navigate = useNavigate();

  const Updatebid = () => {
    if (amount == "") {
      setErrorMessage("Please enter amount");
      setShowModal(true);
    } else {
      navigate("/bidlist", {
        state: {
          bid_id: past_bid_id,
          share_by_user: share_by_user,

          bid_amount_back:
            // parseInt(this.state.past_price) +
            parseInt(amount),
          amount: parseInt(amount),
          // my_balance: Response.data.total,
          back_total:
            parseInt(past_total_price) +
            parseInt(past_price) +
            parseInt(amount),
        },
      });
    }
  };

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal || isModalOpen || isWithdrawConfirmationOpen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, isModalOpen, isWithdrawConfirmationOpen, bodyScrollEnabled]);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="bids13_page_main_wrapp">
      <div className="bids13_page_base_wrapp_2">
        <div className="title_section">
          <button
            onClick={() => {
              navigate("/homescreen2");
            }}
            className="myproduct_13_back_btn"
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <div className="nav_title">Bids</div>
        </div>

        <div className="bid13_search">
          <div className="bids13_user_bid_main" style={{ paddingTop: "40px" }}>
            <input
              type="text"
              placeholder="Type Keyboard..."
              className="bids13_user_bid_search"
              // value={searchTerm}
              onChange={(e) => searchFilter_branch(e.target.value)}
            />
            {/* <img src={images.search} alt="" className="search_ic" /> */}
          </div>
        </div>
        {getreleasedata == 0 ? (
          <>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  fontFamily: "var(--font-700)",
                }}
              >
                No data found
              </p>
            </div>
          </>
        ) : (
          <div className="bids13_club_user">
            {getreleasedata &&
              getreleasedata.map((item, index) => (
                <div key={index} className="bids13_club_user_details">
                  <img
                    src={item.bond_img_video[0].img_video}
                    className="bid13_img_details"
                    alt=""
                    onClick={() => {
                      navigate("/productView", {
                        state: {
                          release_bond_id: item.release_bond_id,
                          product_id: item.product_id,
                          is_nfb: item.is_nfb,
                          uniq_code: item.uniq_code,
                          theme_index: item.theme_index,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                        },
                      });
                    }}
                  />
                  <div className="bid13_details_sub1">
                    <div className="bids13_h1">
                      <img
                        src={item.user_profile}
                        alt=""
                        className="bids13_profile"
                      />
                      <h1 className="bids13_h11">{item.username}</h1>
                    </div>
                    <div className="bids13_h1_rs">
                      <h2 className="bids13_rs">₹ {item.bid_amount}</h2>
                    </div>
                  </div>
                  {/* <div className="bid_details_sub2"> */}
                  <div className="bids13_btn_new">
                    <button
                      className={`bids13_sub2_button_a ${
                        bidbtn === 1 ? "btn_hover" : ""
                      }`}
                      onClick={() => {
                        handleOpenModal(item);
                        setbidbtn(1);
                      }}
                      // className={`myproduct_13_sub2_button_a ${cat_assets===1 ? "myproduct_13_sub2_button_a_hover": ""}`}
                    >
                      Update Bid
                    </button>
                    {item.is_days == 1 ? (
                      <button
                        className={`bids13_sub2_button_a ${
                          bidbtn === 2 ? "btn_hover" : ""
                        }`}
                        onClick={() => {
                          setpast_bid_id(item.bid_id);
                          handleOpenWithdrawConfirmation();
                          setbidbtn(2);
                        }}
                      >
                        Withdraw Bid
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        )}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Update Bid Modal"
          className="modal_bids"
          overlayClassName="modal_overlay2"
        >
          <p className="close_tag" onClick={handleCloseModal}>
            <IoClose color="var(--color-main)" size={30} />
          </p>
          <br />
          <p
            className="nm_bid_modal"
            style={{
              fontFamily: "var(--font-300)",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            Update your bid
          </p>
          <p
            className="nm_bid_modal"
            style={{
              fontFamily: "var(--font-300)",
              marginBottom: "10px",
              fontSize: "12px",
            }}
          >
            Past bid : {past_price} +
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              placeholder="Enter amount"
              id="bids_inpput_tag"
              value={amount}
              onChange={handleAmountChange}
              className={!isValidAmount ? "invalid" : ""}
            />
            {!isValidAmount && (
              <p className="error-message_bids">
                Invalid amount. Please enter a valid number.
              </p>
            )}
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <button
                onClick={() => {
                  Updatebid();
                }}
                type="submit"
                className="bids_close_modal"
              >
                Done
              </button>
            </div>
          </form>
        </Modal>

        {isWithdrawConfirmationOpen && (
          <>
            <V2Modal
              canclebtn={true}
              title=""
              msg="Are you sure you want to withdraw this bid?"
              setShowModal={setIsWithdrawConfirmationOpen}
              okfunction={handleWithdrawBid}
            />
          </>
        )}
        <br />
        <br />
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      <NewFooter getlocation={location.pathname} />
    </div>
  );
};

export default Bids_13;
