import React from "react";
import "./actionneed2.css";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";

const Actionneed2 = () => {
  const navigate = useNavigate();
  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="" /> */}
        <div className="mobile_success_main_wrapp">
          <img src={images.new_success_img} className="success_img" alt="" />
          <p className="product_success_heading" style={{ fontsize: "18px" }}>
            Your Asset has been <br /> sent for approval !
          </p>
          <p className="product_des_text">
            {/* Our team of experts will review the asset and get back to you. */}
            Once the asset is approved, you can start creating rarerez.
          </p>

          {/* btn */}
          <button
            onClick={() => {
              navigate("/myproduct");
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>My Assets</p>
          </button>
          {/* btn */}
        </div>
      </div>
    </div>
  );
};

export default Actionneed2;
