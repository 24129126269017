import React, { useState, useRef, useEffect } from "react";
import images from "../constants/images";
import "./clubotp/ClubOtp.css";
import "./clubmailotp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  club_email_verify_and_active_user,
  club_resend_email_otp,
  club_resend_otp,
  club_reset_email_phone_number,
  club_reset_phone_number,
  email_verify_and_active_user,
  user_otp,
  user_resend_email_otp,
  user_resend_otp,
  user_reset_email_phone_number,
  user_reset_phone_number,
  verify_and_active_club,
} from "../utils/Constant";
import AlertModal from "../components/AlertModal";
import ReactModal from "react-modal";
import NewModal from "../common/newmodal/NewModal";
import V2Modal from "../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const ClubEmailOtp = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmail, setIsEmail] = useState(
    location.state && location.state.email
  );
  const [getphone, setPhone] = useState(location.state.phone);
  const [getoldOtp, setIsOldOtp] = useState(
    location.state && location.state.user_otp
  );
  const [isLoading, setIsLoading] = useState(false);
  const emailmpin = [useRef(), useRef(), useRef(), useRef()];
  const [getcusmsg, setcusmsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [getnumbervalidation, setNumberValidation] = useState(
    location.state && location.state.status
  );
  const navigate = useNavigate();
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(60);
  };
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendTimerRunning, setIsResendTimerRunning] = useState(false);

  const [showsuccessModal, showSuccessModal] = useState(false);

  const startResendTimer = () => {
    setIsResendTimerRunning(true);
    setResendTimer(60);
  };

  const ResendMobileOtp1 = () => {
    // Place your logic here to initiate OTP resend
    startTimer();
  };

  useEffect(() => {
    if (isResendTimerRunning && resendTimer > 0) {
      const countdown = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isResendTimerRunning && resendTimer === 0) {
      setIsResendTimerRunning(false);
    }
  }, [isResendTimerRunning, resendTimer]);

  useEffect(() => {
    // console.log("location", location.state);

    if (isTimerRunning && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [isTimerRunning, timer]);

  // mobileotp value start
  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  useEffect(() => {
    console.log(
      "storeitem",
      localStorage.getItem("is_login"),
      localStorage.getItem("user_id"),
      localStorage.getItem("email"),
      localStorage.getItem("username"),
      localStorage.getItem("profile")
    );
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const otpValue = mobileotp.join("");

  // mobileotp value end

  // mobileotp api start
  const VerifyOtp = async () => {
    console.log("dcss", otpValue);
    if (!otpValue) {
      setcusmsg("Please enter your otp");
      setShowModal(true);
    } else if (otpValue.length < 4) {
      setcusmsg("Please enter 4 digit otp");
      setShowModal(true);
    } else {
      setIsLoading(true);
      const param = {
        mobile_no: getphone,
        user_otp: otpValue,
      };
      await axios
        .post(verify_and_active_club, qs.stringify(param))
        .then((Response) => {
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response);
            setIsLoading(false);
            setIsMobileVerified(true);
            setIsEmail(Response.data.email);
          } else {
            // alert(Response.data.message);
            setcusmsg(Response.data.message);
            setShowModal(true);
          }
          // setIsModalOpen2(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          // alert("OTP is incrrect please try again");
        });
    }
  };
  // mobileotp api end

  // resend mobileotp api start
  const ResendMobileOtp = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
      club_id: location.state.club_id,
    };
    console.log("success");
    await axios
      .post(club_resend_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        } else {
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
      });
  };
  // resend mobileotp api end

  // resend mobileotp api start
  const EditMobileNo = async () => {
    setIsLoading(true);
    const param = {
      club_id: location.state.club_id,
      phone_number: getphone,
    };
    console.log("user_otp param", param);

    await axios
      .post(club_reset_phone_number, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          handleModalClose();
        } else {
          console.log("user_otp err", Response.data);

          // alert(Response.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
      });
  };
  // resend mobileotp api end

  // ==================== email section ===================== //

  // emailotp api start
  const VerifyEmailOtp = async () => {
    if (!otpValueEmail) {
      toast.error("Please enter your otp", { theme: "colored" });
    } else if (otpValueEmail.length < 4) {
      toast.error("Please enter 4 digit otp", { theme: "colored" });
    } else {
      console.log("ascsac", "adcasfasevsf");
      setIsLoading(true);
      const param = {
        mobile_no: getemail,
        email_otp: otpValueEmail,
        club_id: location.state.club_id,
      };
      console.log("success", param);
      await axios
        .post(club_email_verify_and_active_user, qs.stringify(param))
        .then((Response) => {
          if (Response.data.status == 1) {
            //   localStorage.setItem("is_login", JSON.stringify(true));
            //   localStorage.setItem("user_id", Response.data.user_id);
            //   localStorage.setItem("email", Response.data.email);
            //   localStorage.setItem("username", Response.data.username);
            //   localStorage.setItem("profile", Response.data.profile);

            setcusmsg(Response.data.message);
            showSuccessModal(true);
            console.log(
              "club_email_verify_and_active_user Response",
              Response.data
            );
            //   navigate("/Intrests", {
            //     state: {
            //       user_id: Response.data.user_id,
            //     },
            //   });
            setIsLoading(false);
            // setIsMobileVerified(true);
          } else {
            console.log("email_verify_and_active_user err", Response.data);
            // alert(Response.data.message);
            setIsLoading(false);

            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
          // setIsModalOpen2(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("email_verify_and_active_user err", err);
        });
    }
  };
  // emailotp api end

  // resend Emailotp api start
  const ResendEmailOtp = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
      club_id: location.state.club_id,
    };
    console.log("success");
    await axios
      .post(club_resend_email_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startResendTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.success(`${Response.data.message}`, { theme: "colored" });
        } else {
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
      });
  };
  // resend emailotp api end

  // Edit email api start
  // const EditEmail = async () => {
  //   setIsLoading(true);
  //   const param = {
  //     email: getemailedit,
  //     club_id: location.state.club_id,
  //   };
  //   await axios
  //     .post(club_reset_email_phone_number, qs.stringify(param))
  //     .then((Response) => {
  //       setIsLoading(false);
  //       if (Response.data.status == 1) {
  //         console.log("user_otp Response", Response.data);
  //         // alert(Response.data.message);
  //         setcusmsg(Response.data.message);
  //         setShowModal(true);
  //         handleModalClose2();
  //       } else {
  //         // alert(Response.data.message);
  //         setcusmsg(Response.data.message);
  //         setShowModal(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log("user_otp err", err);
  //     });
  // };
  // edit email api end
  const [getemail, setEmail] = useState(location.state.email);

  const EditEmail = async () => {
    console.log("asdas");
    if (getemailedit === "") {
      toast.error("Please enter a email address", { theme: "colored" });
    } else if (!getcon) {
      toast.error("Please enter a valid email address", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        club_id: location.state.club_id,
        email: getemailedit,
      };
      console.log("user_otp param", param);

      await axios
        .post(club_reset_email_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status === 1) {
            console.log("user_otp Response", Response.data);
            setShowEditModal(false);
            setEmail(Response.data.results);
            toast.success(`${Response.data.message}`, { theme: "colored" });
          } else {
            console.log("user_otp err", Response.data);
            // setError(Response.data.message);
            toast.error(`${Response.data.message}`, { theme: "colored" });

            // if (!toastDisplayed) {
            //   setToastDisplayed(true);
            // }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };

  // Emailotp value start
  const [emailotp, setEmailOtp] = React.useState(["", "", "", ""]);
  const refsEmail = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeEmail = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...emailotp];
      newOtp[index] = value;
      setEmailOtp(newOtp);

      if (index < refsEmail.length - 1 && value !== "") {
        refsEmail[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownEmail = (index, event) => {
    if (event.key === "Backspace" && index > 0 && emailotp[index] === "") {
      const newOtp = [...emailotp];
      newOtp[index - 1] = "";
      setEmailOtp(newOtp);
      refsEmail[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (refsEmail[0].current) {
      refsEmail[0].current.focus();
    }
  }, []);
  const otpValueEmail = emailotp.join("");
  // Emailotp value end

  const handleMobileVerification = () => {
    setIsMobileVerified(true);
  };

  // modal mobile
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  // email modal
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleModalOpen2 = () => {
    setIsModalOpen2(true);
  };

  const handleModalClose2 = () => {
    setIsModalOpen2(false);
  };

  const closemodal = () => {
    showSuccessModal(false);
  };
  const closemodal1 = () => {
    setShowModal(false);
  };
  const openmodal1 = () => {
    setShowModal(false);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const [getcon, setcon] = useState(false);
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [getemailedit, setEmailEdit] = useState(location.state.email);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="Verifyscreen_1"></div>

      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          {isResendTimerRunning ? null : (
            <button
              style={{ marginTop: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          )}
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "45vh" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            {/* {isMobileVerified == true || getnumbervalidation == 3 ? ( */}
            <div className="monile_div">
              {/* <div className="mobile_verify">
              <p className="mobile_verify_p">
                WE have sent on OTP to your email address please check your spam
                folder
              </p>
              <p className="mobile_verify_number" onClick={handleModalOpen2}>
                {isEmail}{" "}
                <img
                  className="verify_edit"
                  src={images.editing_verify}
                  alt=""
                />
              </p>
              {isModalOpen2 && (
                <div className="modal_overlay">
                  <div className="modal_content_mobile">
                    <input
                      type="email"
                      // readOnly={isModalOpen2}
                      placeholder="Enter Your email address"
                      className="email_input_modal"
                      value={isEmail}
                      onChange={(e) => setIsEmail(e.target.value)}
                    />
                    <div className="mobile_modal_btn">
                      <button
                        className="mobile_verify_btn"
                        onClick={handleModalClose2}
                      >
                        Cancel
                      </button>
                      <button
                        className="mobile_verify_btn"
                        onClick={() => EditEmail()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div> */}
              <div
                className="otp_screen_text_container"
                style={{ margin: "0px" }}
              >
                <h4>Verify OTP</h4>
                <p>
                  Please enter the verification code sent to{" "}
                  <span
                    style={{
                      color: "var(--color-main)",
                      fontFamily: "var(--font-600)",
                    }}
                  >
                    {getemail}{" "}
                  </span>
                  email address
                </p>
              </div>
              <div className="input_otp_new_clubmail">
                {emailotp.map((digit, index) => (
                  <input
                    key={index}
                    type="number"
                    maxLength={1}
                    className="new_verify_otp_inputs"
                    ref={refsEmail[index]}
                    value={digit}
                    onChange={(event) => handleInputChangeEmail(index, event)}
                    onKeyDown={(event) => handleKeyDownEmail(index, event)}
                  />
                ))}
              </div>
              {/* <p>{otpValueEmail}</p> */}
              <div className="new_verify_otp_down_sec">
                <div>
                  <p>Request another OTP in</p>
                  {/* <span>00 : 30</span> */}
                  <button
                    disabled={isResendTimerRunning}
                    onClick={ResendEmailOtp}
                  >
                    {isResendTimerRunning
                      ? `00 : ${resendTimer}`
                      : "RESEND OTP"}
                  </button>
                </div>
                <button className="new_btn_wrapp mx-1" onClick={VerifyEmailOtp}>
                  <p>Verify</p>
                </button>
                <div>
                  <p>
                    Want to{" "}
                    <button
                      onClick={() => {
                        setShowEditModal(true);
                      }}
                    >
                      {" "}
                      edit email
                    </button>{" "}
                    ?
                  </p>
                </div>
              </div>
            </div>
            {/* ) : null} */}
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            {/* {title && <h4 className="v2modal_fixed_heading">{title}</h4>} */}
            <div className="v2modal_fixed_text_wrapp">
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_email_logo} alt="" />
                  <p>Enter Email Address</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="email"
                    placeholder="Type here"
                    value={getemailedit}
                    // onChange={(e) => formatPhoneNumber(e.target.value)}
                    onChange={(e) => {
                      const text = e.target.value;
                      if (email_validation.test(text) === false) {
                        setcon(false);
                      } else if (text.length == 0) {
                        setcon(false);
                      } else {
                        setcon(true);
                      }
                      setEmailEdit(text);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn2"
                onClick={EditEmail}
                disabled={!getcon}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {/* custom alert modal start vishal */}
      {showModal && (
        <>
          {/* <AlertModal title="" msg={getcusmsg} setShowModal={setShowModal} /> */}
          {/* <NewModal
            title=""
            msg={getcusmsg}
            setShowModal={openmodal1}
            closemodal={closemodal1}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setShowModal}
            okfunction={closemodal1}
          />
        </>
      )}

      {showsuccessModal && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>Success</b>
        //       <br />
        //       <p className="modal-message"> {getcusmsg ? getcusmsg : ""}</p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           showSuccessModal(false);
        //           navigate("/homescreen2");
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>
        <>
          {/* <div className="modal_login">
            <div className="modal-content_login">
              <div className="modal_div1">
                <img className="forgot_main" src={images.logo} alt="" />
              </div>
              <div className="modal_div">
                <b>Success</b>
                <br />
                <p className="modal-message"> {getcusmsg ? getcusmsg : ""}</p>
                <br />
              </div>
              <hr className="hr_forgot" />
              <br />
              <div className="modal_div">
                <button
                  className="close_forgot"
                  onClick={() => {
                    showSuccessModal(false);
                    navigate("/Sponsors");
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div> */}

          {/* <ReactModal
            isOpen={setShowModal}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closemodal}
            style={customStyles}
          >
            <div className="NewModal_main_wrapp">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img className="newmodal_logo" src={images.logo} alt="" />
                <p className="newmodal_titel">Success</p>

              </div>

              <p className="newmodal_des">{getcusmsg ? getcusmsg : ""}</p>


              <button
                className="newmodal_btn"
                onClick={() => {
                  showSuccessModal(false);
                  navigate("/Sponsors");
                }}
              >
                Okay
              </button>
            </div>
          </ReactModal> */}

          <V2Modal
            canclebtn={false}
            title="Success"
            msg={getcusmsg ? getcusmsg : ""}
            setShowModal={showSuccessModal}
            okfunction={() => {
              showSuccessModal(false);
              navigate("/Sponsors");
            }}
          />
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default ClubEmailOtp;
