import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  get_all_benifit_category,
  get_all_user_manager,
  getall_user,
  product_bond_benifit,
  resale_product_benifit,
} from "../utils/Constant";
import axios from "axios";
import images from "../constants/images";
import NavbarSec from "../common/NavbarSec";
import qs from "qs";
import ReactModal from "react-modal";
import V2Modal from "../common/v2modal/V2Modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const ResellCreatebenefit = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showOtherSelect, setShowOtherSelect] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [catname, setcatname] = useState("");
  const [catid, setcatid] = useState("");
  const [getmanagerdata, setmanagerdata] = useState([]);
  const [getmanagersearch, setmanagersearch] = useState([]);
  const [contectname, setcontectname] = useState("");
  const [contectid, setcontectid] = useState("");
  const [getcontect, setgetcontect] = useState("me");
  const [getcon, setgetcon] = useState(false);
  const [getme, setMe] = useState("me");

  const handleRadioChange = (value) => {
    console.log("value", value);
    setcontectname("");
    // const value = event;
    setMe(value);
    setgetcontect(value);
    if (value === "other") {
      setShowOtherSelect(true);
    } else {
      setWhatsappTick(0);
      whatsappGetApi();
      setShowOtherSelect(false);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   // setImageFiles(e.target.files[0]);
  //   setSelectedFile((prevSelectedImages) => [...prevSelectedImages, ...files]);
  // };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedFiles = [];

    for (let i = 0; i < files.length; i++) {
      newSelectedFiles.push(files[i]);
    }

    // setSelectedFiles(newSelectedFiles);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newSelectedFiles,
    ]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };
  const handlePlayVideo = (index) => {
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      videoElement.play();
    }
  };

  // Function to open the modal

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closemodal2 = () => {
    setShowModal(false);
  };

  // modal
  const [searchValue, setSearchValue] = useState("");

  const contactNames = [
    "Vishal",
    "John",
    "Emily",
    "Michael",
    "Sarah",
    "David",
    "Emma",
    "Daniel",
    "Olivia",
    "Sophia",
  ];

  const filteredContactNames = contactNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const [loading, setloading] = useState(false);

  useEffect(() => {
    getReleasebond();
    Getallusermanager();
  }, []);

  const [getdata, setdata] = useState([]);

  const getReleasebond = () => {
    setloading(true);
    axios
      .get(get_all_benifit_category)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          setdata(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const userId = localStorage.getItem("user_id");

  const Getallusermanager = () => {
    const param = {
      user_id: userId,
    };
    setloading(true);
    axios
      .post(getall_user, param)
      .then((Response) => {
        console.log("acdascs--->", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          // setmanagerdata(Response.data.result);
          setmanagersearch(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const searchFilter_branch = (text) => {
    const newData = getmanagersearch.filter(function (item) {
      console.log("item", item.label);
      const employee = item.label ? item.label.toUpperCase() : "".toLowerCase();
      const number = String(item.mobile_number)
        ? String(item.mobile_number).toUpperCase()
        : "".toUpperCase();
      const status = String(item.status)
        ? String(item.status).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        status.indexOf(textData) > -1
      );
    });
    setmanagerdata(newData);
    // setmanagersearch(newData);
    console.log("asvsvsds", newData);
  };

  const [getbanifit, setbenifit] = useState("");
  const [getdes, setdes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // const ccc = () => {
  //   alert(getcontect !== "other" ? "0" : getcontect);
  // };

  const createnfb = () => {
    if (getbanifit == "") {
      setShowModal(true);
      setErrorMessage("Enter benefit name");
    } else if (catid == "") {
      setShowModal(true);
      setErrorMessage("Select category");
    } else if (getdes == "") {
      setShowModal(true);
      setErrorMessage("Enter description");
    } else if (getcontect == "") {
      setShowModal(true);
      setErrorMessage("Please Select contact");
    } else if (getcontect === "other" && contectid === "") {
      // if (contectid === "") {
      setShowModal(true);
      setErrorMessage("Please Select contact person name");
      // }
    } else if (selectedFiles == 0) {
      setShowModal(true);
      setErrorMessage("Upload atlist one image");
    } else {
      let formData = new FormData();
      formData.append("resale_product_id", location.state.product_id);
      formData.append("user_id", userId);
      formData.append("reward_name", getbanifit);
      formData.append("free_paid", 0);
      formData.append("is_contact", getcontect == "other" ? 1 : 0);
      formData.append("contact_user_id", contectid);
      formData.append("for_other_user_paid", "0");
      formData.append("ctegory_id", catid);
      formData.append("free_paid_user_option", "0");
      formData.append("valid_time", "0");
      formData.append("description", getdes);
      formData.append(
        "for_other_user_price",
        getcontect !== "other" ? "0" : getcontect
      );

      // this.state.images_array.map((i) =>
      //   i == "uri"
      //     ? null
      //     : formData.append("img_video[]", {
      //         uri: Platform.OS === "android" ? i : i.replace("file://", ""),
      //         name: "name",
      //         type: "image/jpeg",
      //       })
      // );
      selectedFiles.forEach((image, index) => {
        formData.append("img_video[]", image);
      });
      formData.append("whatsapp_number", whatsappvalue);
      console.log("param", formData);

      setloading(true);
      axios
        .post(resale_product_benifit, formData)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            setShowModal(true);
            setErrorMessage(Response.data.message);
            setgetcon(true);
          } else {
            setShowModal(true);
            setErrorMessage(Response.data.message);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (isModalOpen || showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isModalOpen, showModal, bodyScrollEnabled]);

  const [whatsapptick, setWhatsappTick] = useState(0);
  const [whatsappvalue, setWhatsappValue] = useState("");

  useEffect(() => {
    whatsappGetApi();
  }, []);

  const whatsappGetApi = (userIds) => {
    setloading(true);
    const param = {
      user_id: userIds ? userIds : userId,
    };
    console.log("setting", param);

    axios
      .post("https://www.ravi.host/api/get_user_whatsapp_number", param)
      .then((response) => {
        console.log("whatsappGetApi --->", response.data);
        setloading(false);
        setWhatsappValue(response.data.whatsapp_number);
      })
      .catch((error) => {
        setloading(false);
        console.log("Error in whatsappGetApi:", error);
      });
  };

  const handleWhatsappToggle = () => {
    if (whatsapptick === 1) {
      // Perform action if ticked
      // For example, call this._whatsappgetapi()
      whatsappGetApi();
    }

    // Toggle the tick state
    setWhatsappTick((prevTick) => (prevTick === 0 ? 1 : 0));
    setWhatsappValue("");
  };

  const handleWhatsappChange = (data) => {
    setWhatsappValue(data);
  };
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <div className="new_wrapp_container reletive_position">
        <div className="new_container">
          {/* navbar start */}
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Create Benefit</p>
            <div></div>
          </div>
          {/* navbar end */}

          <div className="npasset_main_wrapp mb-3">
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div
                className="npasset_inner_page_wrapp"
                style={{ marginBottom: "0px", padding: "1rem 10px" }}
              >
                {/* Benefit name start */}
                <div className="creator_name_wrapp mb-2">
                  <p>Benefit name</p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter name"
                      value={getbanifit}
                      onChange={(e) => {
                        setbenifit(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* Benefit name end */}

                {/* category start */}
                <div className="resell_div2 mb-2" style={{ paddingTop: "0px" }}>
                  <p className="re_title">Category</p>
                  <div style={{ position: "relative", width: "100%" }}>
                    <button
                      className="nav-button left"
                      onClick={scrollLeft}
                      style={{
                        padding: "8px",
                        top: "30%",
                        left: "0px",
                        width: "36px",
                      }}
                    >
                      <FaAngleLeft color="var(--color-main)" size={22} />
                    </button>
                    <div
                      ref={sliderRef}
                      style={
                        {
                          // padding: "0px 2rem",
                        }
                      }
                      className="select_resel_cato"
                    >
                      {getdata &&
                        getdata.map((item, index) => {
                          console.log("item", item);
                          return (
                            <button
                              onClick={() => setcatid(item.cat_id)}
                              className="select_resel_cato_sub"
                            >
                              <div
                                className={"img_resel_new"}
                                style={{
                                  borderColor:
                                    catid == item.cat_id ? "red" : "gray",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    "https://www.ravi.host/public/assets/benifit_category/" +
                                    item.cat_icon
                                  }
                                  className="img_of_resell"
                                  alt=""
                                />
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  // width: "%",
                                  fontSize: "10px",
                                  fontFamily: "var(--font-600)",
                                  alignSelf: "center",
                                  // marginTop: "5px",
                                }}
                              >
                                {item.cat_name && item.cat_name.length > 8
                                  ? item.cat_name.substring(0, 8) + "..."
                                  : item.cat_name}
                              </p>
                            </button>
                          );
                        })}
                    </div>
                    <button
                      className="nav-button right"
                      onClick={scrollRight}
                      style={{
                        justifyContent: "flex-end",
                        padding: "8px",
                        top: "30%",
                        width: "36px",
                        right: "0px",
                      }}
                    >
                      <FaAngleRight color="var(--color-main)" size={22} />
                    </button>
                  </div>
                </div>
                {/* category end */}

                {/* description start */}
                <div className="npasset_yes_sec_wrapp mb-2">
                  <p
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "12px",
                      marginTop: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    Description
                  </p>
                  <div
                    className="des_textarea_wrapp"
                    style={{ marginTop: "10px" }}
                  >
                    <textarea
                      name="product_des"
                      rows="6"
                      className="form_input_box"
                      placeholder="Type Here"
                      value={getdes}
                      onChange={(e) => {
                        setdes(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
                {/* description end */}

                {/* contect me start */}
                <div className="create_club_userjoin_wrapp mb-2">
                  <p className="re_title">Contact</p>
                  <button
                    onClick={() => {
                      handleRadioChange("me");
                    }}
                  >
                    <img
                      src={
                        getme === "me"
                          ? images.new_accepted_terms
                          : images.new_notaccepted_terms
                      }
                      alt=""
                      // onClick={handleTermChange}
                    />
                    <p>Me</p>
                  </button>
                  {getmanagerdata && getmanagerdata.length > 0 ? (
                    <button
                      onClick={() => {
                        handleRadioChange("other");
                      }}
                    >
                      <img
                        src={
                          getme === "other"
                            ? images.new_accepted_terms
                            : images.new_notaccepted_terms
                        }
                        alt=""
                        // onClick={handleTermChange}
                      />
                      <p>Other</p>
                    </button>
                  ) : null}
                </div>
                {showOtherSelect && (
                  <div
                    onClick={openModal}
                    className="benefit_select_person_input"
                  >
                    <input
                      type="text"
                      readOnly
                      placeholder="Select Contact Person"
                      value={contectname}
                    />
                  </div>
                )}
                {/* contect me end */}

                {/* whatsapp number start */}

                <div
                  className="create_club_userjoin_wrapp mb-2"
                  style={{ justifyContent: "flex-start", gap: "1rem" }}
                >
                  <button onClick={handleWhatsappToggle}>
                    <img
                      src={
                        whatsapptick === 0
                          ? images.new_accepted_terms
                          : images.new_notaccepted_terms
                      }
                      alt=""
                      // onClick={handleTermChange}
                    />
                  </button>
                  <p className="re_title" style={{ width: "80%" }}>
                    is this your WhatsApp number ?
                  </p>
                </div>
                <div className="creator_name_wrapp mb-2">
                  <div>
                    <input
                      readOnly={whatsapptick === 1 ? false : true}
                      onChange={(e) => handleWhatsappChange(e.target.value)}
                      placeholder="Enter WhatsApp number"
                      maxLength={10}
                      value={whatsappvalue}
                      placeholderTextColor={"gray"}
                      type="number"
                      // style={{
                      //   borderRadius: 45,
                      //   height: 45,
                      //   marginTop: 10,
                      //   paddingLeft: 15,
                      //   fontSize: 13,
                      //   fontFamily: "var(--font-600)",
                      //   paddingTop: 10,
                      //   color: "#fff",
                      //   borderColor: "#929396",
                      //   borderWidth: 0.5,
                      // }}
                    />
                  </div>
                </div>

                {/* whatsapp number end */}

                {/* upload media images start */}
                <div
                  className="npasset_yes_sec_wrapp"
                  style={{ alignItems: "flex-start" }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Upload Image
                  </p>

                  <div
                    className="new_creator_video_wrapp"
                    style={{ margin: "10px auto" }}
                  >
                    <label
                      htmlFor="min_1_img"
                      // className="upload-button"
                      className="new_creator_video_upload_button"
                    >
                      <img src={images.new_upload_logo} alt="" />
                    </label>
                    <input
                      id="min_1_img"
                      type="file"
                      accept="image/*"
                      // capture="camera"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                  {selectedFiles && selectedFiles.length > 0 && (
                    <div
                      className="new_min1_img_preview_wrapp"
                      style={{ marginBottom: "1rem" }}
                    >
                      {selectedFiles.map((image, index) => (
                        <div
                          className="new_min1_img_preview_single"
                          key={index}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                          />

                          <button
                            className="delete-button"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* upload media images end */}

                <div>
                  <center>
                    <button
                      onClick={() => {
                        // ccc();
                        createnfb();
                      }}
                      className="new_btn_wrapp mt-3"
                    >
                      <p>Submit</p>
                    </button>
                  </center>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* modal start */}

        {isModalOpen && (
          <div className="modal_p-overlay">
            <div className="modal_p-content">
              <div
                className="legacy_dashbord_nav_sec"
                style={{ margin: "10px auto" }}
              >
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Add contact person</p>
                <div></div>
              </div>
              <div className="modal_p-body">
                {/* <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="viewmanagers_search"
                    onChange={(e) => {
                      e.target.value.length == 0
                        ? setmanagerdata([])
                        : searchFilter_branch(e.target.value);
                    }}
                  />
                </div> */}

                <div style={{}} className="bid13_search">
                  <div
                    className="bids13_user_bid_main"
                    style={{ paddingTop: "10px" }}
                  >
                    <input
                      type="text"
                      placeholder="Search"
                      className="bids13_user_bid_search"
                      // value={searchTerm}
                      onChange={(e) => {
                        e.target.value.length == 0
                          ? setmanagerdata([])
                          : searchFilter_branch(e.target.value);
                      }}
                    />
                    {/* <img src={images.search} alt="" className="search_ic" /> */}
                  </div>
                </div>
                <div className="list_of_contact_nm">
                  {getmanagerdata.map((item, index) => (
                    <p
                      onClick={() => {
                        setIsModalOpen(false);
                        setcontectid(item.id);
                        setcontectname(item.label);
                        whatsappGetApi(item.id);
                      }}
                      className="add_contact_name"
                      key={index}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* modal end */}
        {showModal && (
          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage}
            setShowModal={setShowModal}
            okfunction={() => {
              getcon == true ? navigate(-1) : setShowModal(false);
              setBodyScrollEnabled(true);
            }}
          />
        )}
      </div>
    </>
    // <div className="page_main_wrapp">
    //   {loading ? (
    //     <div className="loader"></div>
    //   ) : (
    //     <div className="page_base_wrapp_2">
    //       <NavbarSec name="Create Benefit" />
    //       <div className="createbenefit">
    //         <div>
    //           <b>Name</b>
    //           <input
    //             type="text"
    //             className="benefit_nm_new"
    //             placeholder="Enter Benefit Name"
    //             onChange={(e) => {
    //               setbenifit(e.target.value);
    //             }}
    //           />
    //         </div>
    //         <br />
    //         <b>Category</b>
    //         <div style={{ position: "relative", width: "100%" }}>
    //           <button
    //             className="nav-button left"
    //             onClick={scrollLeft}
    //             style={{ padding: "10px 0px", width: "24px" }}
    //           >
    //             <FaAngleLeft color="var(--color-main)" size={22} />
    //           </button>
    //           <div
    //             ref={sliderRef}
    //             style={{ padding: "6px 1.5rem" }}
    //             className="benefit_category"
    //           >
    //             {getdata &&
    //               getdata.map((item, index) => {
    //                 return (
    //                   <div
    //                     onClick={() => {
    //                       setcatid(item.cat_id);
    //                     }}
    //                     className={
    //                       catid == item.cat_id
    //                         ? "benefit_category_sub_validation"
    //                         : "benefit_category_sub"
    //                     }
    //                   >
    //                     <img
    //                       src={
    //                         "https://www.ravi.host/public/assets/benifit_category/" +
    //                         item.cat_icon
    //                       }
    //                       className="benefit_category_sub_img"
    //                       alt=""
    //                     />
    //                     <p className="nm_category_new">
    //                       {item.cat_name && item.cat_name.length > 5
    //                         ? item.cat_name.substring(0, 5) + "..."
    //                         : item.cat_name}
    //                     </p>
    //                   </div>
    //                 );
    //               })}
    //           </div>
    //           <button
    //             className="nav-button right"
    //             onClick={scrollRight}
    //             style={{
    //               justifyContent: "flex-end",
    //               padding: "10px 0px",
    //               width: "24px",
    //             }}
    //           >
    //             <FaAngleRight color="var(--color-main)" size={22} />
    //           </button>
    //         </div>
    //         <br />
    //         <div>
    //           <b>Description</b>
    //           <textarea
    //             name=""
    //             className="benefit_nm_new"
    //             id=""
    //             cols="30"
    //             rows="7"
    //             onChange={(e) => {
    //               setdes(e.target.value);
    //             }}
    //           ></textarea>
    //         </div>
    //         <b>Contact</b>
    //         <div>
    //           <div className="benefit_contact">
    //             <div className="benefit_contact_sub">
    //               <input
    //                 type="radio"
    //                 name="contactType"
    //                 value="me"
    //                 onChange={handleRadioChange}
    //                 checked={getme === "me"}
    //               />
    //               <p>me</p>
    //             </div>
    //             {getmanagerdata && getmanagerdata.length > 0 ? (
    //               <div className="benefit_contact_sub">
    //                 <input
    //                   type="radio"
    //                   name="contactType"
    //                   value="other"
    //                   onChange={handleRadioChange}
    //                 />
    //                 <p>Other</p>
    //               </div>
    //             ) : null}
    //           </div>
    //           {showOtherSelect && (
    //             <div className="other_select">
    //               <br />
    //               {/* <div to={"/addcontact"}> */}
    //               <div onClick={openModal}>
    //                 <input
    //                   type="text"
    //                   readOnly
    //                   placeholder="Select Contact Person"
    //                   value={contectname}
    //                 />
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //         <div
    //           style={{
    //             // display: "flex",
    //             // flexDirection: "row",
    //             marginTop: 20,
    //             alignItems: "center",
    //           }}
    //         >
    //           <button
    //             style={{
    //               display: "flex",
    //               flexDirection: "row",
    //               alignItems: "center",
    //               border: "none",
    //               background: "none",
    //             }}
    //             onClick={handleWhatsappToggle}
    //           >
    //             <img
    //               src={whatsapptick === 0 ? images.selected : images.select}
    //               alt="WhatsApp Icon"
    //               style={{
    //                 height: 20,
    //                 width: 20,
    //                 marginLeft: 10,
    //                 tintColor: "#ff2e4b",
    //               }}
    //             />
    //             <p
    //               style={{
    //                 fontSize: 16,
    //                 fontFamily: "var(--font-600)",
    //                 color: "#fff",
    //                 marginTop: 2,
    //                 marginLeft: 10,
    //               }}
    //             >
    //               is this your WhatsApp number ?
    //             </p>
    //           </button>

    //           <input
    //             readOnly={whatsapptick === 1 ? false : true}
    //             onChange={(e) => handleWhatsappChange(e.target.value)}
    //             placeholder="Enter WhatsApp number"
    //             maxLength={10}
    //             value={whatsappvalue}
    //             placeholderTextColor={"gray"}
    //             type="number"
    //             style={{
    //               borderRadius: 45,
    //               height: 45,
    //               marginTop: 10,
    //               paddingLeft: 15,
    //               fontSize: 13,
    //               fontFamily: "var(--font-600)",
    //               paddingTop: 10,
    //               color: "#fff",
    //               borderColor: "#929396",
    //               borderWidth: 0.5,
    //             }}
    //           />
    //         </div>
    //         <p className="file_benefit_details_titel">Upload Images/Video</p>
    //         <div className="file_benefit_details">
    //           <label className="custom-file-input">
    //             <input
    //               type="file"
    //               accept="image/*"
    //               onChange={handleFileChange}
    //               multiple
    //               className="benefit_input_2"
    //             />
    //             <div className="upload_tag_benefit">
    //               <span className="add_img_nm">+ add images</span>
    //             </div>
    //           </label>
    //           {selectedFiles.length > 0 && (
    //             <div className="file-preview">
    //               {selectedFiles.map((file, index) => (
    //                 <div key={index} className="preview-item">
    //                   <span
    //                     className="close-icon1"
    //                     onClick={() => handleRemoveFile(index)}
    //                   >
    //                     ✖
    //                   </span>
    //                   {file.type.startsWith("image/") ? (
    //                     <img
    //                       src={URL.createObjectURL(file)}
    //                       className="benefit_preview"
    //                       alt={`Preview ${index}`}
    //                     />
    //                   ) : (
    //                     <video
    //                       controls
    //                       className="benefit_preview2"
    //                       onClick={() => handlePlayVideo(index)}
    //                     >
    //                       <source
    //                         src={URL.createObjectURL(file)}
    //                         type={file.type}
    //                       />
    //                       Your browser does not support the video tag.
    //                     </video>
    //                   )}
    //                 </div>
    //               ))}
    //             </div>
    //           )}
    //         </div>
    //         <div>
    //           <center>
    //             <button
    //               onClick={() => {
    //                 // ccc();
    //                 createnfb();
    //               }}
    //               className="new_btn_wrapp mt-3"
    //             >
    //               <p>Proceed</p>
    //             </button>
    //           </center>
    //         </div>
    //         {isModalOpen && (
    //           <div className="modal_p-overlay">
    //             <div className="modal_p-content">
    //               <div className="modal_p-header">
    //                 <img
    //                   src={images.back}
    //                   alt=""
    //                   className="modal-close_p"
    //                   onClick={closeModal}
    //                 />
    //                 <h2>Add contact Person</h2>
    //                 <button className="modal-close">&times;</button>
    //               </div>
    //               <div className="modal_p-body">
    //                 <div>
    //                   <input
    //                     type="text"
    //                     placeholder="Search"
    //                     className="viewmanagers_search"
    //                     onChange={(e) => {
    //                       e.target.value.length == 0
    //                         ? setmanagerdata([])
    //                         : searchFilter_branch(e.target.value);
    //                     }}
    //                   />
    //                 </div>
    //                 <div className="list_of_contact_nm">
    //                   {getmanagerdata.map((item, index) => (
    //                     <p
    //                       onClick={() => {
    //                         setIsModalOpen(false);
    //                         setcontectid(item.id);
    //                         setcontectname(item.label);
    //                         whatsappGetApi(item.id);
    //                       }}
    //                       className="add_contact_name"
    //                       key={index}
    //                     >
    //                       {item.label}
    //                     </p>
    //                   ))}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   )}
    //   {showModal && (
    //     // <CusModal
    //     //   msg={errorMessage}
    //     //   setShowModal={setShowModal}
    //     //   showModal={showModal}
    //     // />

    //     // <ReactModal
    //     //   isOpen={setShowModal}
    //     //   // onAfterOpen={afterOpenModal}
    //     //   onRequestClose={closemodal2}
    //     //   style={customStyles}
    //     // >
    //     //   <div className="NewModal_main_wrapp">
    //     //     <div
    //     //       style={{
    //     //         display: "flex",
    //     //         flexDirection: "column",
    //     //         alignItems: "center",
    //     //         justifyContent: "center",
    //     //       }}
    //     //     >
    //     //       <img className="newmodal_logo" src={images.logo} alt="" />
    //     //       <p className="newmodal_titel"></p>

    //     //     </div>

    //     //     <p className="newmodal_des">{errorMessage}</p>

    //     //     <button
    //     //       className="newmodal_btn"
    //     //       onClick={() => {
    //     //         getcon == true ? navigate(-1) : setShowModal(false);
    //     //         setBodyScrollEnabled(true);
    //     //       }}
    //     //     >
    //     //       Okay
    //     //     </button>
    //     //   </div>
    //     // </ReactModal>

    //     <V2Modal
    //       canclebtn={false}
    //       title=""
    //       msg={errorMessage}
    //       setShowModal={setShowModal}
    //       okfunction={() => {
    //         getcon == true ? navigate(-1) : setShowModal(false);
    //         setBodyScrollEnabled(true);
    //       }}
    //     />
    //     // <div className="cusModal_main_wrapp">
    //     //   <div className="cusmodal_inner_wrapp">
    //     //     <img src={images.logo} alt="" className="cm_logo" />
    //     //     <p className="cm_heading"></p>
    //     //     <div className="cm_des_wrapp">
    //     //       <p className="cm_des">{errorMessage}</p>
    //     //       <div className="cm_hr"></div>
    //     //       <button
    //     //         className="cm_btn"
    //     //         onClick={() => {
    //     //           getcon == true ? navigate(-1) : setShowModal(false);
    //     //           setBodyScrollEnabled(true);
    //     //         }}
    //     //       >
    //     //         Okay
    //     //       </button>
    //     //     </div>
    //     //   </div>
    //     // </div>
    //   )}
    // </div>
  );
};

export default ResellCreatebenefit;
