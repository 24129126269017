import React, { useState } from "react";
import "./qrhuntlogin.css";
import images from "../../constants/images";

const Qrhuntlogin = () => {
  const [showPasswordqr, setShowPasswordqr] = useState(false);
  const toggleShowPasswordqr = () => {
    setShowPasswordqr(!showPasswordqr);
  };

  return (
    <div className="page_qr_main_wrapp">
      <div className="qrhuntlogin_wrapp">
        <img src={images.logo} className="qr_logo_set" alt="" />
        <br />
        <br />
        <div className="qr_login_main">
          <b className="qr_login">Login</b>
          <br />
          <br />
          <input
            type="text"
            className="qr_login_input"
            placeholder="Phone or Email"
          />
          <input
            type="password"
            className="qr_login_input"
            placeholder="Password"
          />
          <img
            // src={images.password_seq}
            src={showPasswordqr ? images.eyeopen : images.eyeoff}
            className="change_pass_show_qr"
            alt=""
            onClick={toggleShowPasswordqr}
          />
          <p className="for_qr_code">Forgot Password ?</p>
        </div>
        <center>
          <button className="qr_or_btn_set">Login</button>
          <p>
            Don't have an account?<b>SIGN UP</b>
          </p>
        </center>
      </div>
    </div>
  );
};

export default Qrhuntlogin;
