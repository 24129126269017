import React from "react";
import "./NewForgotPassword.css";
import images from "../../constants/images";
import axios from "axios";
import {
  user_forgot_password,
  user_forgot_password_v2,
} from "../../utils/Constant";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import NewLoader from "../../common/newloder/NewLoader";

const NewForgotPassword = () => {
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const [isLoading, setIsLoading] = useState(false);
  const [getemail, setEmail] = useState("");
  const [getcon, setcon] = useState(false);
  const navigate = useNavigate();

  const SubmitForgateEmail = async () => {
    console.log("getemail", getemail);
    if (getemail == "") {
      toast.error("Please enter email address or phone number", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      const params = {
        email_phone: getemail,
      };
      await axios
        .post(user_forgot_password, params)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status == 1) {
            toast.success(`${res.data.message}`, { theme: "colored" });
            navigate("/NewForgotOtpScreen", {
              state: {
                mobile_no: res.data.phone,
                user_id: res.data.user_id,
                user_otp: res.data.user_otp,
              },
            });
          } else {
            toast.error(`${res.data.message}`, { theme: "colored" });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(`${error}`, { theme: "colored" });
        });
    }
  };

  return (
    <div className="new_wrapp_container relative_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="new_login_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "34vh" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className="new_login_screen_text_container">
                <h4 style={{ margin: "0px" }}>Forgot Password</h4>
                <p>Add your phone or email to get OTP</p>
              </div>
              <div className="new_login_inputs_wrapp">
                {/* single input */}
                <div className="new_set_password_single_input_wrapp">
                  <div className="new_set_password_single_labal_wrapp">
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={images.new_lock_logo}
                      alt=""
                    />
                    <p style={{ fontSize: "12px" }}>Add your phone or email</p>
                  </div>
                  <div className="new_set_password_single_border_wrapp">
                    <input
                      type="text"
                      placeholder="Type here"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {/* <img src={images.new_eye_icon} alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* btn */}
            <button onClick={SubmitForgateEmail} className="new_btn_wrapp mt-3">
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewForgotPassword;
