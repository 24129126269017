import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "./clubreleasenfb.css";
import BackButton from "../../components/BackButton";
import {
  get_all_release_bond,
  add_showcase_my_collection,
  club_get_all_release_bond,
} from "../../utils/Constant";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Clubreleasednfb = () => {
  const userId = localStorage.getItem("user_id");
  const [getreleasedata, setreleasedata] = useState([]);
  const [getsearchdata, setsearchdata] = useState([]);
  const [loading, setloading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getReleasebond();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = getsearchdata.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.created_at)
        ? String(item.created_at).toUpperCase()
        : "".toUpperCase();
      const stock = String(item.total_available_bond_bid)
        ? String(item.total_available_bond_bid).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        stock.indexOf(textData) > -1
      );
    });
    setreleasedata(newData);
    console.log("asvsvsds", newData);
  };

  const getReleasebond = () => {
    setloading(true);
    const param = {
      club_id: location.state.club_id,
    };
    axios
      .post("https://www.ravi.host/api/club_get_all_release_bond", param)
      .then((Response) => {
        setloading(false);

        setreleasedata(Response.data.result);
        setsearchdata(Response.data.result);
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const showcasemycollection = (item) => {
    setloading(true);
    const param = {
      user_id: userId,
      bond_id: item.bond_id,
      is_nfb: item.is_nfb,
      is_club: item.is_club,
      uniq_code: item.uniq_code,
      release_bond_id: item.release_bond_id,
      is_nfb_type: 2,
    };
    console.log(param);
    axios
      .post(add_showcase_my_collection, param)
      .then((Response) => {
        setloading(false);
        console.log(
          "Response.data.result",
          JSON.stringify(Response.data.message, null, 2)
        );
        console.log(
          "Response.data.+++",
          JSON.stringify(Response.data.status, null, 2)
        );
        if (Response.data.status == 1) {
          setloading(false);
          getReleasebond();
        } else {
          getReleasebond();
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="releasednfb-container">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="releasednfb_2">
            <div className="web_released_menu">
              <div className="releasednfb_header">
                <div className="releasednfb_main_header_notifi">
                  <div className="released_main1">
                    <BackButton />
                  </div>
                </div>
              </div>
              <div style={{}} className="bid13_search">
                <div
                  className="bids13_user_bid_main"
                  style={{ paddingTop: "5px" }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    className="bids13_user_bid_search"
                    // value={searchTerm}
                    onChange={(e) => {
                      searchFilter_branch(e.target.value);
                    }}
                  />
                  {/* <img src={images.search} alt="" className="search_ic" /> */}
                </div>
              </div>
              {getreleasedata == 0 ? (
                <>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ alignSelf: "center", textAlign: "center" }}>
                      No data found
                    </p>
                  </div>
                </>
              ) : (
                <div className="nfb_releas">
                  {getreleasedata && getreleasedata.length > 0
                    ? getreleasedata.map((item, index) => {
                        return item.bond_img_video == 0 ? null : (
                          <div className="releasednfb_main">
                            <img
                              src={item.bond_img_video[0].img_video}
                              alt=""
                              className="releasednfb_img"
                            />
                            <div className="reles_details">
                              <div className="reles_details_sub">
                                <h1 className="reles_details_h1">
                                  {item.product_name} -{" "}
                                  {item.total_available_bond_bid} Stock
                                </h1>
                                {/* <h1 className="reles_details_h2">
                                  Released on - {item.created_at}
                                </h1> */}
                              </div>
                              <h1 className="reles_details_h1">
                                Released- {item.total_available_bond_bid}
                              </h1>
                              <h1 className="reles_details_club">
                                Released on - {item.date_of_creation}
                              </h1>

                              <div className="releas_btnclub">
                                <button
                                  onClick={() => {
                                    navigate("/ClubMybidslist", {
                                      state: {
                                        club_product_id: item.product_id,
                                        release_bond_id: item.release_bond_id,
                                        uniq_code: item.uniq_code,
                                        owner_id: item.owner_id,
                                        share_by_user: item.share_by_user,
                                        club_id: item.club_id,
                                        user_id: userId,
                                        product_name: item.product_name,
                                      },
                                    });
                                  }}
                                  className="view_bids"
                                >
                                  View Bids ({item.bid_count})
                                </button>
                                {/* <img
                                  onClick={() => {
                                    showcasemycollection(item);
                                  }}
                                  src={
                                    item.check_is_showcase == 1
                                      ? images.logo
                                      : images.logo_white
                                  }
                                  className="releas_btn2_img"
                                  alt=""
                                /> */}
                                <div className="releas_btn">
                                  {item.is_bid == 1 ? null : (
                                    <button className="edit_benefit">
                                      Edit Benefit
                                    </button>
                                  )}
                                  {/* <button className="stock_main">
                                  Stock {item.total_available_bond_bid}
                                </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Clubreleasednfb;
