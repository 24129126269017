import {
  GET_USER_PROFILE_BEGIN,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_FOLLOW_BEGIN,
  GET_FOLLOW_SUCCESS,
  GET_FOLLOW_FAIL,
  GET_CHANGE_PROFILE_BEGIN,
  GET_CHANGE_PROFILE_SUCCESS,
  GET_CHANGE_PROFILE_FAIL,
  GET_EDIT_PROFILE_BEGIN,
  GET_EDIT_PROFILE_SUCCESS,
  GET_EDIT_PROFILE_FAIL,
} from "../Action";

const profile_reducer = (state, action) => {
  // profile

  if (action.type === GET_USER_PROFILE_BEGIN) {
    return { ...state, profile_loading: true };
  }

  if (action.type === GET_USER_PROFILE_SUCCESS) {
    console.log("first", action.payload.result);
    return {
      ...state,
      profile_loading: false,
      profile_data: action.payload.result,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_USER_PROFILE_FAIL) {
    return { ...state, profile_loading: false };
  }

  // add_followers_following

  if (action.type === GET_FOLLOW_BEGIN) {
    return { ...state, follow_loading: true };
  }

  if (action.type === GET_FOLLOW_SUCCESS) {
    console.log("first", action.payload.result);
    return {
      ...state,
      follow_loading: false,
      follow_data: action.payload.result,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_FOLLOW_FAIL) {
    return { ...state, follow_loading: false };
  }

  // change_profile

  if (action.type === GET_CHANGE_PROFILE_BEGIN) {
    return { ...state, change_profile_loading: true };
  }

  if (action.type === GET_CHANGE_PROFILE_SUCCESS) {
    console.log("first", action.payload.result);
    return {
      ...state,
      change_profile_loading: false,
      change_profile_data: action.payload.result,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_CHANGE_PROFILE_FAIL) {
    return { ...state, change_profile_loading: false };
  }

  // edit_profile

  if (action.type === GET_EDIT_PROFILE_BEGIN) {
    return { ...state, profile_loading: true };
  }

  if (action.type === GET_EDIT_PROFILE_SUCCESS) {
    return {
      ...state,
      profile_loading: false,
      edit_profile_data: action.payload.result,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_EDIT_PROFILE_FAIL) {
    return {
      ...state,
      profile_loading: false,
    };
  }
};

export default profile_reducer;
