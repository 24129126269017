import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  CLUB_JOIN_BEGIN,
  CLUB_JOIN_FAIL,
  CLUB_JOIN_SUCCESS,
  CREATE_CLUB_BEGIN,
  CREATE_CLUB_FAIL,
  CREATE_CLUB_SUCCESS,
  GET_ALL_CLUB_BEGIN,
  GET_ALL_CLUB_DETAIL_BEGIN,
  GET_ALL_CLUB_DETAIL_FAIL,
  GET_ALL_CLUB_DETAIL_SUCCESS,
  GET_ALL_CLUB_FAIL,
  GET_ALL_CLUB_SUCCESS,
  GET_ALL_USER_CLUB_BEGIN,
  GET_ALL_USER_CLUB_FAIL,
  GET_ALL_USER_CLUB_SUCCESS,
} from "../Action";
import {
  get_all_club_detail,
  get_block_allclub,
  get_all_user_club,
  create_club,
  club_join,
} from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import user_allclub from "../reducer/nfb_blockclub_reducer";

const initialState = {
  get_allclub_loading: false,
  get_allclub_data: [],
  get_allclub_detail_loading: false,
  get_allclub_detail_data: [],
  get_all_user_club_loading: false,
  get_all_user_club_data: [],
  create_club_loading: false,
  create_club_data: [],
  club_join_loading: false,
};

const Userblockclubcontext = React.createContext();
export const UserBlockclubProvider = ({ children }) => {
  const [state, dispatch] = useReducer(user_allclub, initialState);

  // get all club

  const get_all_club = async () => {
    dispatch({ type: GET_ALL_CLUB_BEGIN });
    try {
      const response = await axios.get(get_block_allclub);
      const allclubdata = response.data;
      console.log("====iii", response.data);
      if (allclubdata.status == 1) {
        dispatch({ type: GET_ALL_CLUB_SUCCESS, payload: allclubdata });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_CLUB_FAIL });
    }
  };

  // get_all_club_detail

  const GetAllClubDetail = async (params, url) => {
    dispatch({ type: GET_ALL_CLUB_DETAIL_BEGIN });
    try {
      const response = await axios.post(get_all_club_detail, params);
      const registerdata = response.data;
      // console.log("====", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_ALL_CLUB_DETAIL_SUCCESS, payload: registerdata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_ALL_CLUB_DETAIL_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  // get_all_user_club

  const GetAllUserClub = async (params, url) => {
    dispatch({ type: GET_ALL_USER_CLUB_BEGIN });
    try {
      const response = await axios.post(get_all_user_club, params);
      const alluserclubdata = response.data;
      // console.log("====", response.data);
      if (alluserclubdata.status == 1) {
        dispatch({ type: GET_ALL_USER_CLUB_SUCCESS, payload: alluserclubdata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_ALL_USER_CLUB_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  // create_club
  const CreateClub = async (params, url) => {
    dispatch({ type: CREATE_CLUB_BEGIN });
    try {
      const response = await axios.post(create_club, params);
      const createclubdata = response.data;
      // console.log("====", response.data);
      if (createclubdata.status == 1) {
        dispatch({ type: CREATE_CLUB_SUCCESS, payload: createclubdata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: CREATE_CLUB_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  // club_join
  const ClubJoin = async (params, url) => {
    dispatch({ type: CLUB_JOIN_BEGIN });
    try {
      const response = await axios.post(club_join, params);
      const clubjoindata = response.data;
      // console.log("====", response.data);
      if (clubjoindata.status == 1) {
        dispatch({ type: CLUB_JOIN_SUCCESS, payload: clubjoindata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: CLUB_JOIN_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  return (
    <Userblockclubcontext.Provider
      value={{
        ...state,
        get_all_club,
        GetAllClubDetail,
        GetAllUserClub,
        CreateClub,
        ClubJoin,
      }}
    >
      {children}
    </Userblockclubcontext.Provider>
  );
};

export const useBlockContext = () => {
  return useContext(Userblockclubcontext);
};
