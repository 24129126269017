import React from "react";
import "./V2Modal.css";
import images from "../../constants/images";

const V2Modal = ({
  title,
  msg,
  setShowModal,
  closemodal,
  canclebtn,
  okfunction,
}) => {
  return (
    <div className="v2modal_main_wrapp">
      <div className="v2modal_inner_wrapp">
        <div className="v2modal_fixed_logo_wrapp">
          <img src={images.logo} alt="" />
        </div>
        <button
          className="v2modal_close_img"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <img
            src={images.new_close_logo}
            className="v2modal_close_img"
            alt=""
          />
        </button>
        {title && <h4 className="v2modal_fixed_heading">{title}</h4>}
        <div className="v2modal_fixed_text_wrapp">
          <p>{msg}</p>
        </div>

        <div className="v2modal_fixed_btns_wrapp">
          {canclebtn && (
            <button
              className="v2modal_btn1"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
          )}
          <button className="v2modal_btn2" onClick={okfunction}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default V2Modal;
