import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./Forgot.css";
import { useUserContext } from "../../context/user_context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { get_all_country, user_forgot_password } from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import NavbarSec from "../../common/NavbarSec";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const Forgot = () => {
  const navigate = useNavigate();
  const [getemail, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const { ForgatePassword, is_loding } = useUserContext();
  const [opencontryModal, setOpencontryModal] = useState(false);
  const [inputText, setInputText] = useState("");
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(101);
  const [countryCode, setCountryCode] = useState(91);
  const [countryName, setCountryName] = useState("India");

  const handleUsernameChange = (e) => {
    setEmail(e.target.value);
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  const handleForgot = () => {
    if (getemail == "") {
      setErrorMessage("Enter phone number or email");
      setShowModal(true);
    } else {
      UserLoginApi();
    }
  };

  const validateInput = (input) => {
    // Regular expression patterns for email, mobile number, and username validation.
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^[0-9]{10}$/;
    const usernamePattern = /^[a-zA-Z0-9_]+$/;

    return (
      emailPattern.test(input) ||
      mobilePattern.test(input) ||
      usernamePattern.test(input)
    );
  };

  // forgate api start

  const UserLoginApi = async () => {
    setIsLoading(true);
    const params = {
      email_phone: getemail,
    };
    console.log("login param", params);
    await axios
      .post(user_forgot_password, qs.stringify(params))
      .then((Response) => {
        setIsLoading(false);
        console.log("Response", Response.data.status);
        const status = Response.data.status;
        // if (condition) {
        // } else {
        // }
        if (status == 1) {
          navigate("/forgateotp", {
            state: {
              mobile_no: Response.data.phone,
              user_id: Response.data.user_id,
              user_otp: Response.data.user_otp,
            },
          });
          // navigate("/forgateotp");
        } else {
          setErrorMessage(Response.data.message);
          setShowModal(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // forgate api end

  const closeModal = () => {
    setShowModal(false);
  };

  // contry start

  useEffect(() => {
    Countryget();
  }, []);

  const Countryget = () => {
    axios.get(get_all_country).then((Response) => {
      if (Response.data.status === 1) {
        setCountry(Response.data.data);
        console.log("get_all_country Response", Response.data);
      } else {
        console.log("get_all_country err", Response.data);
      }
      console.log("param", Response.data.data);
      console.log("====================================");
    });
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  // Filter city options based on the input text
  const filteredContrys = country.filter((data) =>
    data.Name.toLowerCase().includes(inputText.toLowerCase())
  );

  // Function to handle city selection
  const handleContrySelect = (contry) => {
    console.log("contry", contry);

    setCountryId(contry.Id);

    setCountryName(contry.Name);
    setCountryCode(contry.phonecode);
    setOpencontryModal(false);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2" style={{ margin: "0px" }}>
        {/* <div className="for1">
        <img src={images.loginbanner} className="web_img_for" alt="" />
      </div> */}
        <NavbarSec name="" />
        <div className="for2">
          <div className="web_screen_forgot">
            <div className="forgot-header">
              <img className="signup-logo" src={images.new_logo} alt="" />
              <h1 className="forgot-name">Forgot password?</h1>
            </div>
            <p className="forgot_des">
              Enter your phone number we'll send you an OTP to regain access to
              your account.
            </p>
            <br />
            {/* select contry start */}
            <div style={{ position: "relative" }}>
              <input
                type="text"
                className="modal_city_select"
                onClick={() => {
                  setOpencontryModal(!opencontryModal);
                }} // Open the modal when clicked
                value={countryName}
                readOnly
                placeholder={"Select Country"}
                // onBlur={() => setOpencontryModal(false)}
              />
              <img
                // onClick={toggleShowPassword}
                src={images.down}
                alt=""
                className="select_down_arrow"
                style={{
                  transform: opencontryModal
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              />
              {opencontryModal && (
                <div className="contry_select_modal">
                  <input
                    type="text"
                    className="city_nm_select"
                    placeholder={"search Country"}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => setOpencontryModal(false)}
                  />
                  <div
                    className="contry_modal_option_wrapp"
                    style={{
                      zIndex: 111,
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    {filteredContrys.map((contry) => {
                      return (
                        <>
                          <button
                            key={contry}
                            // className="city_option_nm_set"
                            style={{
                              color: "white",
                              fontSize: "12px",
                              fontFamily: "var(--font-300)",
                            }}
                            onClick={() => {
                              handleContrySelect(contry);
                              // console.log("first", contry);
                              // setCountryName(contry.Name);
                            }}
                          >
                            {contry.Name}
                          </button>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* select contry end */}
            {/* <div className="contry_select_modal">
            <input
              type="text"
              className="city_nm_select"
              placeholder={"search Country"}
              onChange={(e) => handleInputChange(e)}
              // onBlur={() => setOpencontryModal(false)}
            />
            <div
              className="contry_modal_option_wrapp"
              style={{
                zIndex: 111,
                width: "100%",
                height: "200px",
              }}
            >
              {filteredContrys.map((contry) => {
                return (
                  <>
                    <button
                      key={contry}
                      // className="city_option_nm_set"
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "var(--font-300)",
                      }}
                      onClick={() => {
                        handleContrySelect(contry);
                        // console.log("first", contry);
                        // setCountryName(contry.Name);
                      }}
                    >
                      {contry.Name}
                    </button>
                  </>
                );
              })}
            </div>
          </div> */}

            <div className="forgot-input" style={{ position: "relative" }}>
              {countryCode && (
                <p
                  style={{
                    position: "absolute",
                    top: "9px",
                    left: "9px",
                    fontSize: "14px",
                    fontFamily: "var(--font-400)",
                    zIndex: 11,
                  }}
                >
                  +{countryCode && countryCode}
                </p>
              )}
              <input
                type="number"
                value={getemail}
                onChange={(e) => {
                  //  setEmail(e.target.value)

                  const inputValue = e.target.value;

                  // Check if the input value contains a decimal point or is negative
                  if (!/^-?\d*$/.test(inputValue)) {
                    // If the input value contains a decimal point or is negative, don't update the state
                    return;
                  }

                  // Check if the input value has more than 10 digits
                  if (inputValue.length > 10) {
                    // If more than 10 digits, trim to the first 10 digits
                    const trimmedValue = inputValue.slice(0, 10);
                    setEmail(trimmedValue);
                  } else {
                    // Update the state if the input value is valid
                    setEmail(inputValue);
                  }
                }}
                placeholder="Enter phone number"
                required
                style={{ paddingLeft: "45px", fontSize: "14px" }}

                // placeholderColor="gray"
              />
            </div>
            <button onClick={() => handleForgot()} className="forgot-button">
              Submit
            </button>
            {/* <div className="forgot-footer">
            <button onClick={() => handleForgot()} className="forgot-button">
              Proceed
            </button>
          </div> */}
            {showModal && (
              <>
                {/* <AlertModal
                  title=""
                  msg={errorMessage}
                  setShowModal={setShowModal}
                /> */}

                {/* <NewModal
                  title=""
                  msg={errorMessage}
                  setShowModal={openmodal}
                  closemodal={closemodal}
                /> */}

                <V2Modal
                  canclebtn={false}
                  title=""
                  msg={errorMessage}
                  setShowModal={setShowModal}
                  okfunction={closemodal}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
