import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "./termsandcondition.css";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import { contact_us_user, get_all_title } from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const ContactUs = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const [htmlContent, setHtmlContent] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getmsg, setMsg] = useState("");
  const [getallTitleData, setAllTitleData] = useState([]);
  const [getallTitleId, setAllTitleId] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   // Make the API call to get the terms and conditions HTML
  //   axios
  //     .get("https://www.ravi.host/api/get_contact_us")
  //     .then((response) => {
  //       console.log(response.data.result);
  //       // Assuming the API returns HTML content in the 'response.data' field
  //       setHtmlContent(response.data.result);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    GetAllTitle();
  }, []);

  const [getcon, setcon] = useState(false);

  const SendMsg = async () => {
    if (getallTitleId == "") {
      setErrorMessage("Please select topic");
      setShowModal(true);
    } else if (getmsg == "") {
      setErrorMessage("Enter message");
      setShowModal(true);
    } else {
      const param = {
        messages: getmsg,
        user_id: userId,
        title_id: getallTitleId,
      };
      await axios
        .post(contact_us_user, qs.stringify(param))
        .then((Response) => {
          if (Response.data.status == 1) {
            // console.log("Response", Response.data);
            setErrorMessage(Response.data.message);
            setShowModal(true);
            setcon(true);
            // setMsg("");
            // setAllTitleId("");
            // navigate(-1);
          } else {
            console.log("Response", Response.data);
          }
        });
    }
  };

  const GetAllTitle = () => {
    axios
      .get(get_all_title)
      .then((Response) => {
        // console.log("Response111a", Response.data);
        if (Response.data.status == 1) {
          setAllTitleData(Response.data.title_data);
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <div className="web_user_menu">
          <div
            style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
          >
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Contact Us</p>
              <div></div>
            </div>
          </div>
          {/*  CONTACT US START  */}
          <div className="mx-1" style={{ margin: "20px auto" }}>
            <label htmlFor="product_highlight"></label>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            {getallTitleData && getallTitleData.length > 0
              ? getallTitleData.map((item, index) => {
                  return (
                    <>
                      <button
                        key={item.title_id}
                        onClick={() => {
                          setAllTitleId(item.title_id);
                        }}
                        style={{
                          padding: "8px 12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "28px",
                          backgroundColor:
                            getallTitleId == item.title_id
                              ? "var(--color-main)"
                              : "#000",
                          marginRight: 10,
                          marginBottom: 10,
                          border: "1px solid var(--color-main)",
                        }}
                      >
                        <p
                          style={{
                            color: "var(--color-white)",
                            fontFamily: "var(--font-600)",
                            fontSize: "14px",
                          }}
                        >
                          {item.title_data}
                        </p>
                      </button>
                    </>
                  );
                })
              : null}
          </div>
          <textarea
            name="product_highlight"
            rows="6"
            className="form_input_box"
            placeholder="Please explain your situation, enabling us to take appropriate action. Our team will promptly reach out to you for further assistance"
            value={getmsg}
            onChange={(e) => setMsg(e.target.value)}
          ></textarea>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "8px",
              margin: "20px auto",
            }}
          >
            {/* <div> */}
            <p
              style={{
                color: "white",
                fontSize: "16px",
                fontFamily: "var(--font-400)",
              }}
            >
              Email address:{" "}
              <span
                style={{
                  color: "var(--color-main)",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                tech@rarerez.club
              </span>
            </p>
            <p
              style={{
                color: "white",
                fontSize: "16px",
                fontFamily: "var(--font-400)",
              }}
            >
              Mobile number:{" "}
              <span
                style={{
                  color: "var(--color-main)",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                +91 86918 00002
              </span>
            </p>
            <p
              style={{
                color: "white",
                fontSize: "16px",
                fontFamily: "var(--font-400)",
              }}
            >
              Operating address:{" "}
              <span
                style={{
                  color: "var(--color-main)",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                {/* 921-922, RK WORLD TOWER, 150 FT RING ROAD, RAJKOT */}
                505 Embassy, Shastri Nagar Lane 1, Near Lokhandwala Circle,
                Andheri West, Mumbai 400053
              </span>
            </p>
            {/* </div> */}
          </div>

          <button
            className="new_btn_wrapp mx-1"
            style={{ marginTop: "32px" }}
            onClick={SendMsg}
          >
            <p>Submit</p>
          </button>
          {/* CONTACT US END  */}
        </div>
        {showModal && (
          // <AlertModal
          //   msg={errorMessage}
          //   setShowModal={setShowModal}
          //   title="Information"
          // />

          // <div className="modal_login" style={{ zIndex: 111 }}>
          //   <div className="modal-content_login">
          //     <div className="modal_div1">
          //       <img className="forgot_main" src={images.logo} alt="" />
          //     </div>
          //     <div className="alert_modal_div">
          //       {/* <b>Information</b> */}
          //       <br />
          //       <p className="alert-modal-message">{errorMessage}</p>
          //       <br />
          //     </div>
          //     <hr className="hr_forgot" />
          //     <br />
          //     <div className="modal_div">
          //       <button
          //         className="close_forgot"
          //         onClick={() => {
          //           setShowModal(false);
          //           navigate(-1);
          //         }}
          //       >
          //         Okay
          //       </button>
          //     </div>
          //   </div>
          // </div>

          // <ReactModal
          //   isOpen={setShowModal}
          //   // onAfterOpen={afterOpenModal}
          //   onRequestClose={closemodal}
          //   style={customStyles}
          // >
          //   <div className="NewModal_main_wrapp">
          //     <div
          //       style={{
          //         display: "flex",
          //         flexDirection: "column",
          //         alignItems: "center",
          //         justifyContent: "center",
          //       }}
          //     >
          //       <img className="newmodal_logo" src={images.logo} alt="" />
          //       <p className="newmodal_titel"></p>
          //       {/* <p className="newmodal_titel">Alert</p> */}
          //     </div>

          //     {/* <div className="newmodal_des_wrapp"> */}
          //     <p className="newmodal_des">{errorMessage}</p>
          //     {/* </div> */}

          //     <button
          //       className="newmodal_btn"
          //       onClick={() => {
          //         setShowModal(false);
          //         navigate(-1);
          //       }}
          //     >
          //       Okay
          //     </button>
          //   </div>
          // </ReactModal>

          <V2Modal
            canclebtn={false}
            title=""
            msg={errorMessage}
            setShowModal={setShowModal}
            okfunction={() => {
              setShowModal(false);
              if (getcon == true) {
                navigate(-1);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ContactUs;
