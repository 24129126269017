import React from "react";
import images from "../constants/images";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

const NavbarSec = ({ name, backto }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "30px",
        background: "black",
        zIndex: 11,
        marginTop: "20px",
      }}
    >
      <div
        onClick={() => {
          (backto && backto != null) || backto != undefined
            ? navigate(backto)
            : navigate(-1);
        }}
        className="navsec_back_arrow"
      >
        {/* <img
          src={images.back}
          // className="nav_back_btn"
          alt=""
          onClick={() => {
            (backto && backto != null) || backto != undefined
              ? navigate(backto)
              : navigate(-1);
          }}
        /> */}
        <FaAngleLeft color="var(--color-white)" size={20} />
      </div>

      <b
        style={{
          color: "#fff",
          fontfamily: "var(--font-600)",
          fontsize: "16px",
        }}
      >
        {name && name}
      </b>
    </div>
  );
};

export default NavbarSec;
