import {
  GET_INTERESTS_FAIL,
  GET_INTERESTS_BEGIN,
  GET_INTERESTS_SUCCESS,
} from "../Action";

const user_interest = (state, action) => {
  // register

  if (action.type === GET_INTERESTS_BEGIN) {
    return { ...state, get_interest_loading: true };
  }

  if (action.type === GET_INTERESTS_SUCCESS) {
    return {
      ...state,
      get_interest_loading: false,
      get_interest_data: action.payload.result,
    };
  }

  if (action.type === GET_INTERESTS_FAIL) {
    return { ...state, get_interest_loading: false };
  }
};

export default user_interest;
