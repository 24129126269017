import React, { useState, useEffect } from "react";
import "./releasedattachbenefit.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Releasedattachbenefit = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [product_name, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [created_at, setCreatedAt] = useState("");
  const [no_of_release_bond, setNoOfReleaseBond] = useState("");
  const [remaining_bond_release_date, setRemainingBondReleaseDate] =
    useState("");
  const [product_img_video, setProductImgVideo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [max_bond, setMaxBond] = useState("");
  const [available_no_of_bond, setAvailableNoOfBond] = useState(0);
  const [assign_no_of_bond, setAssignNoOfBond] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [no_of_bond, setno_of_bond] = useState();
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    Previewproduct();
    Getmax();
    GetAttachedbanifit();
  }, []);

  const Previewproduct = async () => {
    try {
      setIsLoading(true);
      const param = {
        product_id: location.state.product_id,
        user_id: userId,
      };

      const response = await axios.post(
        "https://www.ravi.host/api/preview_product",
        param
      );

      setIsLoading(false);

      console.log("Response -------------------->", response.data.result);
      if (response.data.status === 1) {
        const productResult = response.data.result;
        setProductName(productResult.product_name);
        setDescription(productResult.description);
        setCreatedAt(productResult.created_at);
        setNoOfReleaseBond(productResult.no_of_release_bond);
        setRemainingBondReleaseDate(productResult.remaining_bond_release_date);
        setProductImgVideo(
          productResult.product_img_video.map((item) => item.product_icon)
        );
      } else {
        setIsModalVisible(true);
        setCusmsg(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle the error
    }
  };

  const Getmax = async () => {
    try {
      setIsLoading(true);

      const param = {
        product_id: location.state.product_id,
        user_id: userId,
      };

      console.log("====================================");
      console.log("param", param);

      const response = await axios.post(
        "https://www.ravi.host/api/get_no_of_release_bond_product",
        param
      );

      console.log(
        "====================================grlrdhtoghsrohdoghrdoh,",
        JSON.stringify(response.data, null, 2)
      );

      setIsLoading(false);

      if (response.data.status === 1) {
        setMaxBond(response.data.result.no_of_release_bond);
        setAvailableNoOfBond(response.data.result.available_no_of_bond);
        setAssignNoOfBond(response.data.result.assign_no_of_bond);
      } else {
        setIsModalVisible(true);
        setCusmsg(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle the error
    }
  };
  const [attachedBenefitData, setAttachedBenefitData] = useState([]);

  const GetAttachedbanifit = async () => {
    try {
      setIsLoading(true);

      const param = {
        product_id: location.state.product_id,
        user_id: userId,
      };

      const response = await axios.post(
        "https://www.ravi.host/api/list_of_product_no_of_bond_assign_benifit",
        param
      );

      setIsLoading(false);

      if (response.data.status === 1) {
        setAttachedBenefitData(response.data.result);

        console.log("Response.data.result,", response.data);
        console.log("attached benefit data", attachedBenefitData);
      } else {
        // Handle other cases or set state as needed
      }
    } catch (error) {
      setIsLoading(false);
      // Handle the error
    }
  };

  const BenefitNFBs = () => {
    if (no_of_bond == null || no_of_bond == 0) {
      // setErrorMessage("Please enter number of bond");
      // setShowInfoModal(true);
      toast.error("Please enter number of bond", { theme: "colored" });
    } else if (no_of_bond > available_no_of_bond) {
      // setErrorMessage("Enter bond value below release value of bond");
      // setShowInfoModal(true);
      toast.error("Enter bond value below release value of bond", {
        theme: "colored",
      });
    } else {
      navigate("/Attachbenefit", {
        state: {
          product_id: location.state.product_id,
          number_of_bond: no_of_bond,
        },
      });
    }
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal || showinfoModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, showinfoModal, bodyScrollEnabled]);

  const closemodal1 = () => {
    setShowModal(false);
  };

  const closemodal = () => {
    setShowInfoModal(false);
  };
  const openmodal = () => {
    setShowInfoModal(true);
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Create Rarerez" /> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Create Rarerez</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        <div className="relerfit">
          <div className="relerfit_1">
            <p>{product_name}</p>
            <b>{max_bond}</b>
            <b>Rarerez</b>
          </div>
          {product_img_video &&
            product_img_video.slice(0, 1).map((itm, ind) => {
              // console.log('itm', itm);
              return (
                <div className="relerfit_2">
                  <img src={itm} className="relerfit_img" alt="" />
                </div>
              );
            })}
        </div>
        <br />
        <div className="relerfit_details">
          <p className="relese_attach_titel">
            You can attach benefits on {available_no_of_bond} asset.
          </p>
          <p style={{ fontSize: "12px", fontFamily: "var(--font-400)" }}>
            Attach benefits to your Rarerez and make them irresistible. Your
            added benefits enhance the value of your Rarerez. Get creative and
            watch them become highly sought after!
          </p>
          <br />
          <button
            onClick={() => {
              navigate("/createbenefit");
            }}
            style={{ zIndex: "11" }}
            className="create_product_btn"
          >
            Create benefit
          </button>
        </div>
        <br />
        {/* {attachedBenefitData && attachedBenefitData.length > 0
          ? attachedBenefitData.map((item, index) => {
              return (
                <div className="relesattall">
                  <div className="relesattall_sub">
                    <div className="relesattall_sub1">
                      <b>{item.no_of_bond}</b>
                      <p>Rarerez</p>
                    </div>
                    <div className="relesattall_sub2">
                      <Link className="relesattall_sub2_link">
                        +Add Benefit
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          : null} */}
        <div className="view_benefit_main">
          {attachedBenefitData && attachedBenefitData.length > 0 && (
            <b>Attached Benefit</b>
          )}
          {attachedBenefitData && attachedBenefitData.length > 0
            ? attachedBenefitData.map((item, index) => {
                console.log("item", item);
                return (
                  <div className="list_benefit_view">
                    <div className="part_benefit">
                      <div className="part_benefit1">
                        <div className="sub_list_benefits">
                          <div>
                            <b className="nm_nfbs_list">Rarerez</b>
                            <br />
                            <b className="nm_nfbs_list1">{item.no_of_bond}</b>
                          </div>
                          <Link
                            className="manage_link"
                            to="/Attachbenefit"
                            state={{
                              product_id: location.state.product_id,
                              number_of_bond: item.no_of_bond,
                              uniq_code: item.uniq_code,
                            }}
                          >
                            Manage
                          </Link>
                        </div>
                      </div>
                      <div className="part_benefit2">
                        {/* partner data start */}
                        {item.partner_bond_benifit_data &&
                        item.partner_bond_benifit_data.length > 0
                          ? item.partner_bond_benifit_data.map((itm, ind) => {
                              return (
                                <div className="view_sub_benefit">
                                  <img
                                    src={itm.benifit_icon}
                                    className="img_view_1"
                                    alt=""
                                  />
                                  <p className="benefit_nm_sub">
                                    {itm.benifit_name}
                                  </p>
                                  <p className="benefit_nm_sub">
                                    {itm.partner_name}
                                  </p>
                                </div>
                              );
                            })
                          : null}

                        {/* partner data end */}

                        {/* own benefit data start */}

                        {item.product_bond_benifit_detail &&
                        item.product_bond_benifit_detail.length > 0
                          ? item.product_bond_benifit_detail.map(
                              (data, ind) => {
                                return (
                                  <div className="view_sub_benefit">
                                    <img
                                      src={data.reward_img_video[0].img_video}
                                      className="img_view_1"
                                      alt=""
                                    />
                                    <p className="benefit_nm_sub">
                                      {data.reward_name}
                                    </p>
                                  </div>
                                );
                              }
                            )
                          : null}

                        {/* own benefit data end */}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {/* add benefit btn start */}
        <button
          onClick={() => setShowModal(true)}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            background: "var(--color-main)",
            fontFamily: "var(--font-600)",
            color: "var(--color-white)",
            border: "1px solid #fff",
            borderRadius: "8px",
            fontSize: "10px",
            padding: "8px 16px",
          }}
        >
          +Add Benefits
        </button>

        {/* add benefit btn end */}
      </div>
      {/* {showModal && (
        <ReactModal
          isOpen={setShowModal}
          onRequestClose={closemodal1}
          style={customStyles}
        >
          <div className="NewModal_main_wrapp">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img className="newmodal_logo" src={images.logo} alt="" />
              <p className="newmodal_titel">
                Enter Rarerez (Max {available_no_of_bond})
              </p>
            </div>
            <div style={{ width: "90%" }}>
              <input
                type="tel"
                value={no_of_bond}
                className="newattach_modal_input"
                onChange={(e) => setno_of_bond(e.target.value)}
                placeholder="Enter number of bond"
              />
            </div>
            <div className="newattech_hr"></div>
            <div className="modal_two_buton" style={{ width: "100%" }}>
              <button className="close_forgot" onClick={BenefitNFBs}>
                Okay
              </button>
              <button className="close_forgot" onClick={closemodal1}>
                Cancel
              </button>
            </div>
          </div>
        </ReactModal>
      )} */}
      {showModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>

            <h4 className="v2modal_fixed_heading">
              Enter Rarerez (Max {available_no_of_bond})
            </h4>

            <div>
              {/* {mpin.map((digit, index) => { */}
              {/* return ( */}
              <input
                // key={index}
                type="tel"
                value={no_of_bond}
                className="newattach_modal_input"
                // onChange={(e) => setno_of_bond(e.target.value)}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValid = /^\d+$/.test(inputValue);

                  if (no_of_bond > available_no_of_bond) {
                    setno_of_bond("");
                    // setModalName(
                    //   "Enter Rarerez value below release value of Rarerez"
                    // );
                    // setIsModalOpen(true);
                    toast.error(
                      "Enter Rarerez value below release value of Rarerez",
                      { theme: "colored" }
                    );
                  } else if (!isValid) {
                    setno_of_bond("");
                    // setModalName("Please enter a valid number of Rarerez");
                    // setIsModalOpen(true);
                    toast.error("Please enter a valid number of Rarerez", {
                      theme: "colored",
                    });
                  } else {
                    setno_of_bond(parseInt(inputValue));
                    console.log("isValid", inputValue);
                  }
                }}
                // maxLength={1}
                placeholder="Enter number of rarerez"
                // ref={(input) => (mpinRefs.current[index] = input)}
              />
              {/* ); */}
              {/* })} */}
            </div>
            <div className="newattech_hr"></div>
            <div
              style={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px auto",
              }}
            >
              <button
                className="v2modal_btn1"
                onClick={() => {
                  setno_of_bond(null);
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button className="v2modal_btn2" onClick={BenefitNFBs}>
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
      {showinfoModal && (
        // <NewModal
        //   title="Information"
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowInfoModal}
          okfunction={closemodal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Releasedattachbenefit;
