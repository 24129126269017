import React, { useState } from "react";
import images from "../../constants/images";
import "./clubs.css";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { get_all_advertisement, get_all_club_2 } from "../../utils/Constant";
import { useEffect } from "react";
import qs from "qs";
import Slider from "react-slick";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const Clubs = () => {
  const [getads, setAds] = useState([]);
  const [getclubsData, setclubsData] = useState([]);
  const [getclubsSearchData, setclubsSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const GetAd = async () => {
    setIsLoading(true);
    await axios
      .get(get_all_advertisement)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          setAds(res.data.result);
        } else {
          console.log("ads err ->", res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetAllClub = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };
    await axios
      .post("https://www.ravi.host/api/get_all_club_2", qs.stringify(param))
      .then((res) => {
        setIsLoading(false);

        if (res.data.status === 1) {
          setclubsData(res.data.result);
          setclubsSearchData(res.data.result);
        } else {
          console.log("clubs err ->", res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchFilter = (text) => {
    const newData = getclubsSearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.club_name
        ? item.club_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setclubsData(newData);
    console.log("newData", newData);
  };

  useEffect(() => {
    GetAd();
    GetAllClub();
  }, []);

  //
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="page_main_wrapp_nw reletive_position">
        {isLoading ? (
          <NewLoader />
        ) : (
          <div className="page_base_wrapp_2">
            {/* <NavbarSec name="Clubs" /> */}
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate("/homescreen2");
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Clubs</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>

            {/* clubs sponsors start */}
            {getads && getads.length > 0 && (
              <div className="all_nfbs_main_wrapp" style={{ width: "100%" }}>
                <p>Sponsors</p>
                {/* <Link></Link> */}
              </div>
            )}
            <div className="" style={{ width: "100%" }}>
              <Slider {...settings}>
                {getads && getads.length > 0
                  ? getads.map((item, index) => {
                      return (
                        <img
                          src={item.banner}
                          alt=""
                          className="cp_gallery_single_img"
                        />
                      );
                    })
                  : null}
              </Slider>
            </div>
            {/* clubs sponsors end */}

            {/* search start */}
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>
            {/* search end */}

            {/* products start */}
            <div className="rnfb_products_wrapp">
              {getclubsData && getclubsData.length > 0
                ? getclubsData.map((item, index) => {
                    return (
                      <div className="club_single_product" key={item.club_id}>
                        <img src={item.cover_photo} alt="" />
                        <div className="club_product_name_btn_wrapp">
                          <p> {item.club_name}</p>
                          {/* <Link
                            className="new_btn_wrapp mx-1"
                            to="/Clubprofile"
                            state={{ club_id: item.club_id }}
                          > */}
                          <button
                            style={{
                              width: "100%",
                              height: "50px",
                              background: "#000",
                              boxShadow:
                                "21px 19px 45px -3px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset, 0px 39px 99px -49px #caacff73 inset",
                            }}
                            className="new_btn_wrapp mx-1"
                            onClick={() => {
                              console.log("item", item);
                              item.phone_is_status == 0 &&
                              item.email_is_status == 0
                                ? navigate("/ClubOtp", {
                                    state: {
                                      email: item.email,
                                      phone: item.phone_number,
                                      user_id: item.user_id,
                                      club_id: item.club_id,
                                    },
                                  })
                                : item.phone_is_status == 0
                                ? navigate("/ClubOtp", {
                                    state: {
                                      email: item.email,
                                      phone: item.phone_number,
                                      user_id: item.user_id,
                                      club_id: item.club_id,
                                    },
                                  })
                                : item.email_is_status == 0
                                ? navigate("/ClubEmailOtp", {
                                    state: {
                                      email: item.email,
                                      phone: item.phone_number,
                                      user_id: item.user_id,
                                      club_id: item.club_id,
                                    },
                                  })
                                : item.is_bank_status == 0
                                ? navigate("/clubBankaccount", {
                                    state: {
                                      club_id: item.club_id,
                                      is_alltopclub: 2,
                                    },
                                  })
                                : navigate("/Clubprofile", {
                                    state: {
                                      club_id: item.club_id,
                                      is_alltopclub: 2,
                                    },
                                  });
                            }}
                          >
                            <p>Explore</p>
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            {/* products end */}
          </div>
        )}
      </div>
      {/* <div className="clubs_2">
        <div className="web_user_menu">
          <div className="clubs_header">
            <div className="clubs_main_header_notifi">
              <div className="div_main1">
                <BackButton />
              </div>
            </div>
          </div>
          <div className="general_club_user">
            <input
              type="text"
              placeholder="Search"
              className="user_clubs_search"
            />
          </div>
          <div className="club_main">
            <div className="clun_header">
              <img src={images.media6} className="clubS_img" alt="" />
              <div className="club_details">
                <h1 className="h1_clubs_details">club Demo</h1>
                <button className="club_details_btn">Explore</button>
              </div>
            </div>
            <div className="clun_header">
              <img src={images.media6} className="clubS_img" alt="" />
              <div className="club_details">
                <h1 className="h1_clubs_details">club Demo</h1>
                <button className="club_details_btn">Explore</button>
              </div>
            </div>
            <div className="clun_header">
              <img src={images.media6} className="clubS_img" alt="" />
              <div className="club_details">
                <h1 className="h1_clubs_details">club Demo</h1>
                <button className="club_details_btn">Explore</button>
              </div>
            </div>
            <div className="clun_header">
              <img src={images.media6} className="clubS_img" alt="" />
              <div className="club_details">
                <h1 className="h1_clubs_details">club Demo</h1>
                <button className="club_details_btn">Explore</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Clubs;
