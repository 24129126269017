import React from "react";
import { useNavigate } from "react-router-dom";
import images from "../constants/images";
import "./back.css";

const BackButtonLogo = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return (
    <button onClick={handleClick} className="back_img_all">
      <img src={images.new_logo} alt="" className="signup-logo" />
    </button>
  );
};

export default BackButtonLogo;
