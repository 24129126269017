import React, { useRef, useState } from "react";
import "./NewPancardScreen.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import {
  add_user_pan_card_v2,
  user_add_bank_detail_v2,
} from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";
import NewTandC from "../../components/newtandc/NewTandC";

const NewPancardScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const recevedPanNumber = location.state.pan_no;
  const user_id = location.state.user_id;
  const [isLoading, setIsLoading] = useState(false);
  const [panNumber, setPanNumber] = useState(
    recevedPanNumber ? recevedPanNumber.split("") : Array(10).fill("")
  );
  const [panNumberValue, setPanNumberValue] = useState("");
  const [getpanCon, setPanCon] = useState(false);
  const inputRefs = useRef([]);

  // account state start

  const [getaccNo, setGetAccNo] = useState("");
  const [getconformAccNo, setGetConformAccNo] = useState("");
  const [ifscNo, setIfscNo] = useState("");

  // account state end

  // terms state start

  const [gettermAccept, setTermAccept] = useState(false);
  const [gettermModel, setTermModel] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);

  // terms state end

  // term accept function start

  const handleTermAccept = () => {
    console.log("asd");
    setTermModel(false);
    setTermAccept(true);
  };

  const handleTermChange = () => {
    console.log("asd");
    setTermModel(true);
  };

  // term accept function end

  // pancard input function start
  const handleChange = (e, index) => {
    let inputValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ""); // Remove any non-alphanumeric characters
    inputValue = inputValue.slice(0, 10).toUpperCase(); // Limit input to 10 characters and convert to uppercase
    const updatedPANNumber = [...panNumber];
    updatedPANNumber[index] = inputValue;
    setPanNumber(updatedPANNumber);
    const nextInput = inputRefs.current[index + 1];
    if (nextInput && inputValue !== "") {
      nextInput.focus();
    }

    // Join the array elements into a single string
    const updatedPANNumber2 = updatedPANNumber.join("");
    setPanNumberValue(updatedPANNumber2);
    console.log("updatedPANNumber.length", panNumberValue);
    console.log("index", index);

    // Check if the current index is the last digit of the PAN number
    if (updatedPANNumber2.length === 10) {
      // Call the API to submit the PAN card
      SubmitPanCard(updatedPANNumber2);
      console.log("11");
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && panNumber[index] === "") {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
      }
    }
  };
  // pancard input function end

  // account number handler start
  const handleAccountNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setGetAccNo(value);
  };
  const handleConfirmAccountNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setGetConformAccNo(value);
  };
  // account number handler end

  // pan number api start
  const SubmitPanCard = (pannumber) => {
    if (pannumber == "") {
      toast.error("Please Enter PAN card number", { theme: "colored" });
      return;
    } else {
      setIsLoading(true);
      const param = {
        pan_number: pannumber,
        user_id: location.state.user_id,
      };
      // Make the API request
      axios
        .post(add_user_pan_card_v2, qs.stringify(param))
        .then((response) => {
          console.log("response.data", response.data);
          if (response.data.status === 1) {
            console.log("response.data", response.data);
            setIsLoading(false);
            toast.success(`${response.data.message}`, { theme: "colored" });
            setPanCon(true);
          } else {
            toast.error(`${response.data.message}`, { theme: "colored" });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          toast.error(`${error}`, { theme: "colored" });
        });
    }
  };
  // pan number api end

  // account api start

  const SubmitBankDetail = () => {
    if (getaccNo === "") {
      toast.error("Enter account number", { theme: "colored" });
    } else if (getconformAccNo === "") {
      toast.error("Enter confirm account number", { theme: "colored" });
    } else if (ifscNo === "") {
      toast.error("Enter IFSC code", { theme: "colored" });
    } else if (getaccNo !== getconformAccNo) {
      toast.error("Account number and confirm account number is not match", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      const param = {
        bank_account_number: getaccNo,
        ifsc_code: ifscNo,
        user_id: user_id,
      };

      axios
        .post(user_add_bank_detail_v2, qs.stringify(param))
        .then((Response) => {
          if (Response.data.status === 1) {
            toast.success(Response.data.message, { theme: "colored" });
            navigate("/NewGstScreen", {
              state: {
                user_id: user_id,
              },
            });
          } else {
            toast.error(Response.data.message, { theme: "colored" });
          }

          setIsLoading(false);
          console.log("Response", Response.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  // account api end

  return (
    <div className="new_wrapp_container relative_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="set_otp_main_wrapp">
            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4 style={{ margin: "0px" }}>Confirm your Identity</h4>
              <p>
                Your card details remain confidential. This helps us to process
                further.{" "}
              </p>
            </div>

            {/* --------- */}

            <p className="new_pan_number_heading">Enter PAN CARD number</p>
            <div
              className="aadhaar-input-container"
              style={{ width: "100%", padding: "0px 5%", gap: "7px" }}
            >
              {panNumber.map((digit, index) => (
                <>
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className="aadhaar-input"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength={1}
                    disabled={getpanCon || recevedPanNumber}
                    style={{
                      width: "inherit",
                      backgroundImage:
                        getpanCon || recevedPanNumber
                          ? "linear-gradient(to right, #008000af, #00800036)"
                          : "linear-gradient(to right, #ff004c80, #cbacff37)",
                      color: getpanCon || recevedPanNumber ? "green" : "white",
                      borderRadius: "0px",
                      padding: "8px 2px",
                      fontSize: "14px",
                    }}
                  />
                </>
              ))}
            </div>

            {/* --------- */}

            <p className="new_pan_number_heading">Add Bank Details</p>

            <div
              className="new_set_password_single_input_wrapp"
              style={{ padding: "0px 5%", marginTop: "1rem" }}
            >
              <div className="new_set_password_single_labal_wrapp">
                <img src={images.new_user_icon} alt="" />
                <p>Account Number</p>
              </div>
              <div className="new_set_password_single_border_wrapp">
                <input
                  type="number"
                  value={getaccNo}
                  onChange={(e) => {
                    handleAccountNumber(e);
                  }}
                />
              </div>
            </div>

            <div
              className="new_set_password_single_input_wrapp"
              style={{ padding: "0px 5%", marginTop: "1rem" }}
            >
              <div className="new_set_password_single_labal_wrapp">
                <img src={images.new_user_icon} alt="" />
                <p>Confirm Account Number</p>
              </div>
              <div className="new_set_password_single_border_wrapp">
                <input
                  type="number"
                  value={getconformAccNo}
                  // onChange={(e) => {
                  //   setGetConformAccNo(e.target.value);
                  // }}
                  onChange={(e) => {
                    handleConfirmAccountNumber(e);
                  }}
                />
              </div>
            </div>

            <div
              className="new_set_password_single_input_wrapp"
              style={{ padding: "0px 5%", marginTop: "1rem" }}
            >
              <div className="new_set_password_single_labal_wrapp">
                <img src={images.new_ifsc_logo} alt="" />
                <p>IFSC Code</p>
              </div>
              <div className="new_set_password_single_border_wrapp">
                <input
                  type="text"
                  value={ifscNo}
                  onChange={(e) => {
                    setIfscNo(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="new_aadhaar_btn_wrapp">
              <div>
                {/* <img src={images.new_accepted_terms} alt="" /> */}
                {gettermAccept ? (
                  <img
                    src={images.new_accepted_terms}
                    onClick={() => setTermAccept(false)}
                    alt=""
                  />
                ) : (
                  <img
                    src={images.new_notaccepted_terms}
                    alt=""
                    onClick={handleTermChange}
                  />
                )}
                <p>I agree to the terms & conditions</p>
              </div>
              {/* btn */}

              <button
                className="new_btn_wrapp"
                onClick={() => {
                  if (getpanCon || recevedPanNumber) {
                    SubmitBankDetail();
                  } else {
                    if (panNumberValue === "") {
                      toast.error("Please Enter PAN card number", {
                        theme: "colored",
                      });
                    } else {
                      SubmitBankDetail();
                    }
                  }
                }}
                disabled={
                  getpanCon || recevedPanNumber
                    ? !gettermAccept ||
                      getaccNo == "" ||
                      getaccNo == null ||
                      getconformAccNo == "" ||
                      getconformAccNo == null ||
                      ifscNo == "" ||
                      ifscNo == null
                    : !gettermAccept ||
                      getaccNo == "" ||
                      getaccNo == null ||
                      getconformAccNo == "" ||
                      getconformAccNo == null ||
                      ifscNo == "" ||
                      ifscNo == null ||
                      panNumberValue === ""
                }
              >
                <p>Next</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      {gettermModel ? (
        <>
          <NewTandC
            title="Terms & Condition"
            msg={htmlContent}
            setIsOpen={setTermModel}
            accept={handleTermAccept}
          />
        </>
      ) : null}
    </div>
  );
};

export default NewPancardScreen;
