import React from "react";
import { useNavigate } from "react-router-dom";
import images from "../constants/images";
import "./back.css";
import { FaAngleLeft } from "react-icons/fa";

const BackButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <>
      {/* <img src={images.back} alt="" className="all_back" /> */}
      <button
        className="all_back"
        onClick={() => {
          navigate(-1);
        }}
      >
        <FaAngleLeft color="var(--color-white)" size={20} />
      </button>
    </>
  );
};

export default BackButton;
