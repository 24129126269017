import React, { useState } from "react";
import "./NewMobileOtpSuccess.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { inter_user_pay_con } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewInternationalStripeSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getuser_id, setUser_id] = useState("");

  // Retrieving data from sessionStorage
  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || [];
  };

  useEffect(() => {
    const user_id = getDataFromSessionStorage("inter_user_id");
    console.log("data", user_id);

    setIsLoading(true);
    const param = {
      user_id: user_id,
    };
    console.log("success");
    axios
      .post(inter_user_pay_con, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          console.log("user_otp Response", Response.data);
          setUser_id(Response.data.user_id);
        } else {
          toast.error(`${Response.data.message}`, { theme: "colored" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
        toast.error(`${err}`, { theme: "colored" });
      });
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="mobile_success_main_wrapp">
          <p className="success_heading">Success!!!</p>
          <p className="success_des_text">
            Congratulation! your Payment have been successfull.
          </p>
          <img src={images.new_success_img} className="success_img" alt="" />

          {/* btn */}
          <button
            onClick={() => {
              navigate("/NewInternationalPassword", {
                state: {
                  user_id: getuser_id,
                  //   phone: location.state.phone,
                },
              });
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Submit</p>
          </button>
          {/* btn */}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default NewInternationalStripeSuccess;
