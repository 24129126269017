import {
  GET_HOME_BEGIN,
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  GET_LIKE_BEGIN,
  GET_LIKE_SUCCESS,
  GET_LIKE_FAIL,
  GET_HOME_COMMENT_BEGIN,
  GET_HOME_COMMENT_SUCCESS,
  GET_HOME_COMMENT_FAIL,
  GET_LATEST5_BEGIN,
  GET_LATEST5_SUCCESS,
  GET_LATEST5_FAIL,
  GET_ALL_INTEREST_BEGIN,
  GET_ALL_INTEREST_SUCCESS,
  GET_ALL_INTEREST_FAIL,
  GET_ALL_NOTIFICATION_BEGIN,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  MY_COLLECTION_BEGIN,
  MY_COLLECTION_FAIL,
  MY_COLLECTION_SUCCESS,
} from "../Action";

const home_reducer = (state, action) => {
  // register

  if (action.type === GET_HOME_BEGIN) {
    return { ...state, home_loading: true };
  }

  if (action.type === GET_HOME_SUCCESS) {
    console.log("first", action.payload.result);
    return {
      ...state,
      home_loading: false,
      home_data: action.payload.result,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_HOME_FAIL) {
    return { ...state, home_loading: false };
  }

  if (action.type === GET_LIKE_BEGIN) {
    return { ...state, like_loading: true };
  }

  if (action.type === GET_LIKE_SUCCESS) {
    return {
      ...state,
      home_loading: false,
      like_message: action.payload.message,
    };
  }

  if (action.type === GET_LIKE_FAIL) {
    return { ...state, like_loading: false };
  }

  if (action.type === GET_HOME_COMMENT_BEGIN) {
    return { ...state, home_comment_loading: true };
  }

  if (action.type === GET_HOME_COMMENT_SUCCESS) {
    console.log("first", action.payload.result);
    return {
      ...state,
      home_comment_loading: false,
      home_comment_data: action.payload,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_HOME_COMMENT_FAIL) {
    return { ...state, home_comment_loading: false };
  }

  // get_latest_5_bond_3_club

  if (action.type === GET_LATEST5_BEGIN) {
    return { ...state, latest5_loading: true };
  }

  if (action.type === GET_LATEST5_SUCCESS) {
    return {
      ...state,
      latest5_loading: false,
      home_latest5_data: action.payload,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_LATEST5_FAIL) {
    return { ...state, latest5_loading: false };
  }

  // ALL INTEREST START
  if (action.type === GET_ALL_INTEREST_BEGIN) {
    return { ...state, is_loading: true };
  }

  if (action.type === GET_ALL_INTEREST_SUCCESS) {
    return {
      ...state,
      is_loading: false,
      interest_data: action.payload,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_ALL_INTEREST_FAIL) {
    return { ...state, is_loading: false };
  }
  // ALL INTEREST END

  // ALL NOTIFICATION START
  if (action.type === GET_ALL_NOTIFICATION_BEGIN) {
    return { ...state, is_loading: true };
  }

  if (action.type === GET_ALL_NOTIFICATION_SUCCESS) {
    return {
      ...state,
      is_loading: false,
      notification_data: action.payload,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === GET_ALL_NOTIFICATION_FAIL) {
    return { ...state, is_loading: false };
  }
  // ALL NOTIFICATION END

  // collection sTAERT
  if (action.type === MY_COLLECTION_BEGIN) {
    return { ...state, collection_loading: true };
  }

  if (action.type === MY_COLLECTION_SUCCESS) {
    return {
      ...state,
      collection_loading: false,
      collection_data: action.payload,
      //   user_otp: action.payload.user_otp,
    };
  }

  if (action.type === MY_COLLECTION_FAIL) {
    return { ...state, collection_loading: false };
  }
};

export default home_reducer;
