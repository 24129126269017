import React from "react";
import ContentLoader from "react-content-loader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import "./CardSkeletonLoaderhome.css";
const CardSkeletonLoaderhome = () => {
  return (
    <div className="main-card-screen">
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="skeletonnew-card">
          <Skeleton circle={true} height={40} width={40} />
          <Skeleton className="nameofskeletons" width={100} height={25} />
        </div>
        <Skeleton height={190} width="100%" />
        <br />
        <center>
          <h1 className="skeleton-img">
            <Skeleton width="50%" height={20} />
          </h1>
        </center>
        <div className="new_lo">
          <h1>
            <Skeleton width="100%" height={60} />
          </h1>
          <h1>
            <Skeleton width="100%" height={60} />
          </h1>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default CardSkeletonLoaderhome;
