import React, { useState, useRef } from "react";
import "./aadharcardotp.css";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import NewModal from "../../common/newmodal/NewModal";

const Aadharcardotp = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Array to store OTP digits
  const otpInputs = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [con, setCon] = useState(false);
  const [getUserId, setUserId] = useState("");

  const handleInputChange = (index, event) => {
    const value = event.target.value;

    // Check if the entered value is a digit and not empty
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field automatically if the current field is not empty
      if (value !== "" && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && otp[index] === "") {
      // Move back to the previous input field when backspace is pressed on an empty field
      otpInputs.current[index - 1].focus();
    }
  };

  const userId = localStorage.getItem("user_id");
  const location = useLocation();

  const navigate = useNavigate();

  const updateOtp = () => {
    const otpValue = otp.join("");

    if (otpValue == "") {
      setIsModalVisible(true);
      setCusmsg("Enter aadhaar otp");
    } else if (otpValue.length < 6) {
      setIsModalVisible(true);
      setCusmsg("Please enter valid aadhaar otp");
    } else {
      setIsLoading(true);

      const param = {
        adhar_no: location.state.adhar_no,
        transaction_id: location.state.transaction_id,
        otp: otpValue,
        user_id: location.state.user_id,
      };
      console.log("Transaction", param);

      axios
        .post("https://www.ravi.host/api/adhar_submit_otp", qs.stringify(param))
        .then((response) => {
          setIsLoading(false);
          console.log("API Response:", response.data);

          if (response.data.status === 1) {
            // setUserId(response.data.user_id);
            // setCon(true);
            setIsModalVisible(true);
            setCusmsg(response.data.message);
            navigate("/PanCard", {
              state: {
                user_id: location.state.user_id,
              },
            });
            // navigate("/AutherVideo", {
            //   state: {
            //     user_id: location.state.user_id,
            //   },
            // });
          } else {
            setCon(false);
            setIsModalVisible(true);
            setCusmsg(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp">
          <div className="login-header" style={{ marginTop: "20px" }}>
            <img
              className="login-logo"
              src={images.new_logo}
              alt=""
              style={{ width: "107px" }}
            />
          </div>
          <div
            className="otp_demo"
            style={{ maxWidth: "var(--max-width-md)", margin: "10px auto" }}
          >
            <h1 className="otp_demo_title">Enter OTP</h1>
            <br />
            <p className="v_a_c">Verify Aadhaar Card</p>
          </div>
          <br />
          <div className="input_otp_new" style={{ gap: "10px" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="number"
                className="in_de_otp"
                value={digit}
                onChange={(event) => handleInputChange(index, event)}
                onKeyDown={(event) => handleBackspace(index, event)}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <br />
          <p
            style={{
              fontFamily: "var(--font-400)",
              opacity: "0.6",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            An OTP has been shared with the mobile number linked to your Aadhaar
            Card. Please enter the same to verify your profile. Please note that
            we don't save your Aadhaar details.
          </p>
          {/* <p className="demo_resend_otp">RESEND OTP</p> */}
          <br />
          <center>
            <button
              onClick={() => {
                updateOtp();
              }}
              className="demo_otp_btn_new_set"
            >
              Submit
            </button>
          </center>
        </div>
      )}
      {isModalVisible && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <p style={{ fontSize: "13px" }}>
        //         {""} {cusmsg}
        //       </p>
        //       <br />
        //       <p className="modal-message"></p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsModalVisible(false);
        //           // setIsModalVisible(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>
        <NewModal
          title=""
          msg={cusmsg}
          setShowModal={openmodal}
          closemodal={closemodal}
        />
      )}
    </div>
  );
};

export default Aadharcardotp;
