import React, { useState } from "react";
import "./AddressForProductBuy.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { get_user_place_order_data } from "../../utils/Constant";
import { useEffect } from "react";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const AddData = [
  {
    id: 0,
    name: "Home",
  },
  {
    id: 1,
    name: "Work",
  },
  {
    id: 2,
    name: "Other",
  },
];

const AddressForProductBuy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [loading, setloading] = useState(false);
  const [getdata, setdata] = useState([]);
  const [getselctId, setSelctId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  const [getfirstname, setfirstname] = useState("");
  const [getlastname, setLastname] = useState("");
  const [getaddress, setAddress] = useState("");
  const [getcity, setCity] = useState("");
  const [getzipcode, setZipcode] = useState("");
  const [getcountry, setCountry] = useState("");
  const [getstates, setStates] = useState("");
  const [getotherMsg, setOtherMsg] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleButtonClick = (id) => {
    setSelctId(id);
    if (id === 2) {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };
  const saveadddata = AddData;

  useEffect(() => {
    // allOrders();
    console.log("AddData", AddData);
    console.log("location.state.totalamount", location.state.totalamount);
  }, []);

  const validation = () => {
    if (getselctId == null) {
      setIsModalVisible(true);
      setMsg("Select Address");
    } else if (getselctId == 2 && getotherMsg == "") {
      setIsModalVisible(true);
      setMsg("Enter other massage");
    } else if (getfirstname == "") {
      setIsModalVisible(true);
      setMsg("Enter Your First Name");
    } else if (getlastname == "") {
      setIsModalVisible(true);
      setMsg("Enter Your Last Name");
    } else if (getaddress == "") {
      setIsModalVisible(true);
      setMsg("Enter Your Address");
    } else if (getcity == "") {
      setIsModalVisible(true);
      setMsg("Enter Your City Name");
    } else if (getzipcode == "") {
      setIsModalVisible(true);
      setMsg("Enter Your ZIP Code");
    } else if (getcountry == "") {
      setIsModalVisible(true);
      setMsg("Enter Your Country Name");
    } else {
      addAddress();
    }
  };

  const addAddress = () => {
    setloading(true);

    console.log("bfkedkf,df====>", getselctId);
    let formData = new FormData();
    formData.append("first_name", getfirstname);
    formData.append("last_name", getlastname);
    formData.append("address", getaddress);
    formData.append("city", getcity);
    formData.append("zip_code", getzipcode);
    formData.append("country", getcountry);
    formData.append("user_id", userId);
    formData.append("user_address", getselctId);
    if (getselctId === 2) {
      formData.append("other_address", getotherMsg);
    }

    console.log("FormData", JSON.stringify(formData, null, 2));

    axios
      .post("https://www.ravi.host/api/add_address", formData)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          navigate("/OrderConfirmation", {
            state: {
              ecommerce_product_id: location.state.ecommerce_product_id,
            },
          });
          console.log("response------>", JSON.stringify(Response, null, 2));
        } else {
          setIsModalVisible(true);
          setMsg(Response.data.message);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="my_order_main_wrapp">
      <div className="my_order_base_wrapp">
        <div className="mo_nav_wrap">
          <img
            src={images.back_black}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <p>Shipping</p>
        </div>

        {/* save address are start */}

        <div className="save_add_main_wrapp">
          <p>Save Address as</p>
          <div className="save_add_btns_wrapp">
            {saveadddata.map(({ name, id }) => {
              console.log("name", name);
              return (
                <button
                  className="save_add_single_btn"
                  key={id}
                  style={{
                    color: getselctId === id ? "red" : "#000",
                    border:
                      getselctId === id ? "1px solid red" : "1px solid #000",
                    display: getselctId === 2 && id !== 2 ? "none" : "block",
                  }}
                  onClick={() => {
                    // setSelctId(id);
                    handleButtonClick(id);
                  }}
                >
                  {name}
                </button>
              );
            })}
            {showOtherInput && (
              <input
                type="text"
                className="afpb_other_input"
                onChange={(e) => {
                  setOtherMsg(e.target.value);
                }}
                value={getotherMsg}
                placeholder="Enter Other Option"
              />
            )}
          </div>
        </div>

        {/* save address are end */}

        {/* input boxes main wrapp start */}

        <div className="iboxes_main_wrapp">
          <div className="iboxes_first_wrapp">
            <div className="first_single_ibox">
              <p>First Name</p>
              <input
                type="text"
                value={getfirstname}
                onChange={(e) => {
                  setfirstname(e.target.value);
                }}
              />
            </div>
            <div className="first_single_ibox">
              <p>Last Name</p>
              <input
                type="text"
                value={getlastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="iboxes_first_wrapp">
            <div className="sec_single_ibox">
              <p>Address</p>
              <textarea
                rows={4}
                type="text"
                value={getaddress}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="iboxes_first_wrapp">
            <div className="first_single_ibox">
              <p>City</p>
              <input
                type="text"
                value={getcity}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
            <div className="first_single_ibox">
              <p>Zip Code</p>
              <input
                type="number"
                value={getzipcode}
                onChange={(e) => {
                  setZipcode(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="iboxes_first_wrapp">
            <div className="sec_single_ibox">
              <p>Country</p>
              <input
                type="text"
                value={getcountry}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* input boxes main wrapp end */}

        {/* last section start */}

        <div className="bp_total_wrapp" style={{ minHeight: "80px" }}>
          <div className="add_ship_total_wrapp_inner">
            <div className="add_ship_wrapp">
              <p>Additional Shipping Charge</p>
              <b>₹ {location.state.getshippingcharges}/-</b>
            </div>
            <div className="add_ship_hr"></div>
            <div className="add_ship_wrapp">
              <p>Total</p>
              <span>
                ₹{" "}
                {location.state.totalamount + location.state.getshippingcharges}
                /-
              </span>
            </div>
            <button
              className="add_ship_payment_btn"
              onClick={() => validation()}
            >
              Continue to Payment
            </button>
          </div>
        </div>

        {/* last section end */}

        {isModalVisible && (
          <>
            {/* <AlertModal
              title={getmodalName}
              msg={msg}
              setShowModal={setIsModalVisible}
            /> */}

            {/* <NewModal
              title={getmodalName}
              msg={msg}
              setShowModal={openmodal}
              closemodal={closemodal}
            /> */}
            <V2Modal
              canclebtn={false}
              title=""
              msg={msg}
              setShowModal={setIsModalVisible}
              okfunction={closemodal}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AddressForProductBuy;
