import React, { useEffect, useState } from "react";
import "./NewInterests.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  AssetLink,
  add_user_seleted_interest,
  edit_profile,
  get_all_interest,
  user_add_interest_v2,
} from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";

const NewInterests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [allIntrests, setAllInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    fetchInterests();

    if (location.state?.user_id) {
      console.log(" location.state?.user_id", location.state?.user_id);
    }
  }, []);

  useEffect(() => {
    setSelectedCount(selectedInterests.length);
  }, [selectedInterests]);

  const fetchInterests = () => {
    setLoading(true);
    axios
      .get(get_all_interest)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          setAllInterests(response.data.result);
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleInterestSelection = (item) => {
    const updatedSelectedInterests = [...selectedInterests];

    if (isInterestSelected(item)) {
      const index = updatedSelectedInterests.findIndex(
        (interest) => interest.id === item.id
      );
      updatedSelectedInterests.splice(index, 1);
    } else {
      if (updatedSelectedInterests.length >= 5) {
        toast.error("You can only select five interests", { theme: "colored" });

        return;
      }
      updatedSelectedInterests.push(item);
    }

    setSelectedInterests(updatedSelectedInterests);
    setSelectedIds(
      updatedSelectedInterests.map((interest) => interest.id).join(", ")
    );
  };

  const isInterestSelected = (item) => {
    return selectedInterests.some((interest) => interest.id === item.id);
  };

  const handleValidation = () => {
    if (selectedInterests.length === 5) {
      setLoading(true);
      const param = {
        user_id: location.state?.user_id,
        interest_id: selectedIds,
      };

      axios
        .post(user_add_interest_v2, qs.stringify(param))
        .then((response) => {
          setLoading(false);
          if (response.data.status === 1) {
            setLoading(true);
            const param1 = {
              user_id: location.state?.user_id,
            };

            axios
              .post(edit_profile, qs.stringify(param1))
              .then((Response) => {
                setLoading(false);

                if (Response.data.status == 1) {
                  localStorage.setItem("is_login", JSON.stringify(true));
                  localStorage.setItem("user_id", location.state?.user_id);
                  localStorage.setItem("email", Response.data.result.email);
                  localStorage.setItem(
                    "username",
                    Response.data.result.username
                  );
                  localStorage.setItem(
                    "profile",
                    Response.data.result.username
                  );
                  navigate("/LegacyDashbord", {
                    state: {
                      first_time_popup: 1,
                      first_time_home: 1,
                    },
                  });
                } else {
                  toast.error(Response.data.message, { theme: "colored" });
                }
              })
              .catch((err) => {
                toast.error(err, { theme: "colored" });
                setLoading(false);
              });
          } else {
            toast.error("Please select five interests", { theme: "colored" });
          }
        })
        .catch((err) => {});
    } else {
      toast.error("Please select five interests", { theme: "colored" });
    }
  };

  return (
    <div className="new_wrapp_container relative_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="set_otp_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ maxHeight: "140px", minHeight: "140px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <h2 className="newinterest_heading">Select Category</h2>
            <div className="newinterest_boxes_main_wrapp">
              <div className="newinterest_boxes_gradiant"></div>

              {/* single interest start */}
              {allIntrests && allIntrests.length > 0
                ? allIntrests.map((item, index) => {
                    return (
                      <button
                        key={item.id}
                        onClick={() => handleInterestSelection(item)}
                        className="newinterest_single_box"
                      >
                        <img
                          src={
                            isInterestSelected(item)
                              ? AssetLink + item.new_color_icon
                              : AssetLink + item.new_icon
                          }
                          alt=""
                        />
                        <p
                          style={{
                            color: isInterestSelected(item)
                              ? "var(--color-main)"
                              : "var(--color-white)",
                          }}
                        >
                          {" "}
                          {item.name}
                        </p>
                      </button>
                    );
                  })
                : null}
              {/* single interest end */}
            </div>

            <button
              onClick={() => handleValidation()}
              className="new_btn_wrapp mx-1"
              disabled={selectedCount !== 5}
            >
              {selectedCount == 5 ? (
                <p>Next</p>
              ) : (
                <p>{`(${selectedCount}/5) Next`}</p>
              )}
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewInterests;
