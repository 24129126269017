import React, { useState } from "react";
import "./NewInternationalInviteForm.css";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import qs from "qs";
import { add_new_inter_user_v2 } from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";

const NewInternationalInviteForm = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getemail, setEmail] = useState("");
  const [getname, setname] = useState("");
  const [getcon, setcon] = useState(false);
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleInvite = (invite) => {
    if (getname === "") {
      toast.error("Please enter user name", { theme: "colored" });
    } else if (getemail === "") {
      toast.error("Please enter email", { theme: "colored" });
    } else {
      console.log("getemailPhone:", getemail);
      console.log("name:", getname);
      InviteUser();
      // Perform the actual login logic here
    }
  };

  const InviteUser = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
      email: getemail,
      user_name: getname,
    };
    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    await axios
      .post(add_new_inter_user_v2, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);

        console.log("----", JSON.stringify(Response.data.result, null, 2));

        if (Response.data.status == 1) {
          console.log("Response.data", Response.data.result);
          navigate("/NewinternationalInviteSuccess");
        } else {
          toast.error(Response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="new_wrapp_container reletive_position">
      {/* <img src={images.new_bg_circule} alt="" className="back_circule_shadow" /> */}
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="back_arrow_wrapp">
            <button
              onClick={() => {
                navigate("/NewSideMenu");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div>
          <div className="new_intanationa_invite_form_main_wrapp">
            <div className="new_intanationa_invite_form_inner_wrapp">
              <div
                className="otp_screen_text_container"
                style={{ margin: "1rem 0px", padding: "0px" }}
              >
                <h4 style={{ margin: "0px" }}>Invite International users</h4>
                <p>
                  Provide a username and email address for an international user
                  to send an invitation.
                </p>
              </div>

              <div className="new_set_password_single_input_wrapp mt-3">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_user_icon} alt="" />
                  <p>Enter user</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Type here"
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                  {/* <img src={images.new_eye_icon} alt="" /> */}
                </div>
              </div>
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_email_logo} alt="" />
                  <p>Enter Email Address</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="email"
                    placeholder="Type here"
                    onChange={(e) => {
                      const text = e.target.value;
                      if (email_validation.test(text) === false) {
                        setcon(false);
                      } else if (text.length == 0) {
                        setcon(false);
                      } else {
                        setcon(true);
                      }
                      setEmail(text);
                    }}
                  />
                  {/* <img src={images.new_eye_icon} alt="" /> */}
                </div>
              </div>
            </div>
            <button
              disabled={!getcon}
              onClick={handleInvite}
              className="new_btn_wrapp mt-3"
            >
              <p>Send Invite</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewInternationalInviteForm;
