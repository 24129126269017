import React, { useEffect, useState } from "react";
import images from "../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import { reset_password } from "../utils/Constant";
import axios from "axios";
import qs from "qs";

const ForgateNewPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [getsuccess, setSuccess] = useState(0);
  const [isInputValid, setInputValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputFocused_old, setIsInputFocused_old] = useState(false);
  const [isInputFocused_confirm, setIsInputFocused_confirm] = useState(false);

  const [isSpecialCharacterMissing, setIsSpecialCharacterMissing] =
    useState(false);
  const [isMinLengthInvalid, setIsMinLengthInvalid] = useState(false);
  const [isNumericCharacterMissing, setIsNumericCharacterMissing] =
    useState(false);
  const [isUppercaseMissing, setIsUppercaseMissing] = useState(false);
  const [isLowercaseMissing, setIsLowercaseMissing] = useState(false);

  const [isInputValid_old, setIsInputValid_old] = useState(false);
  const [isSpecialCharacterMissing_old, setIsSpecialCharacterMissing_old] =
    useState(false);
  const [isMinLengthInvalid_old, setIsMinLengthInvalid_old] = useState(false);
  const [isNumericCharacterMissing_old, setIsNumericCharacterMissing_old] =
    useState(false);
  const [isUppercaseMissing_old, setIsUppercaseMissing_old] = useState(false);
  const [isLowercaseMissing_old, setIsLowercaseMissing_old] = useState(false);

  const [isInputValid_confirm, setIsInputValid_confirm] = useState(false);
  const [
    isSpecialCharacterMissing_confirm,
    setIsSpecialCharacterMissing_confirm,
  ] = useState(false);
  const [isMinLengthInvalid_confirm, setIsMinLengthInvalid_confirm] =
    useState(false);
  const [
    isNumericCharacterMissing_confirm,
    setIsNumericCharacterMissing_confirm,
  ] = useState(false);
  const [isUppercaseMissing_confirm, setIsUppercaseMissing_confirm] =
    useState(false);
  const [isLowercaseMissing_confirm, setIsLowercaseMissing_confirm] =
    useState(false);

  useEffect(() => {
    validateInput();
  }, [newPassword, isInputFocused]);

  const validateInput_confirm = () => {
    const isSpecialCharacterMissing_confirm = !/[!@#$%^&]/.test(
      confirmPassword
    );
    const isMinLengthInvalid_confirm = confirmPassword.length < 8;
    const isNumericCharacterMissing_confirm = !/\d/.test(confirmPassword);
    const isUppercaseMissing_confirm = !/[A-Z]/.test(confirmPassword);
    const isLowercaseMissing_confirm = !/[a-z]/.test(confirmPassword);

    const isInputValid_confirm =
      isInputFocused_confirm ||
      (!isSpecialCharacterMissing_confirm &&
        !isMinLengthInvalid_confirm &&
        !isNumericCharacterMissing_confirm &&
        !isUppercaseMissing_confirm &&
        !isLowercaseMissing_confirm);

    setIsInputValid_confirm(isInputValid_confirm);
    setIsSpecialCharacterMissing_confirm(isSpecialCharacterMissing_confirm);
    setIsMinLengthInvalid_confirm(isMinLengthInvalid_confirm);
    setIsNumericCharacterMissing_confirm(isNumericCharacterMissing_confirm);
    setIsUppercaseMissing_confirm(isUppercaseMissing_confirm);
    setIsLowercaseMissing_confirm(isLowercaseMissing_confirm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any field is empty
    if (!oldPassword || !newPassword || !confirmPassword) {
      setErrorMessage("Please fill in all fields.");
      setShowModal(true);
      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setErrorMessage("Both passwords do not match.");
      setShowModal(true);
      return;
    }

    // Check if the new password meets the required criteria (e.g., 8 characters, at least one uppercase, one lowercase, one number, and one special character)
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        "New password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character (@ $ ! % * ? &)."
      );
      setShowModal(true);
      return;
    }

    // Password validation passed, you can now proceed with your API call for changing the password
    console.log(
      "API call to change password:",
      oldPassword,
      newPassword,
      confirmPassword
    );
  };

  const closeModal = () => {
    setShowModal(false);
    // navigate("/homescreen2");
  };

  // new password start

  const handlePasswordChange = (event) => {
    if (event.length == 0) {
      setInputValid(false);
    } else {
      validateInput();
    }
    // setInputValid(false);
    const newPassword = event.target.value;
    setNewPassword(newPassword);
    // const passwordPattern =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // setPasswordValid(passwordPattern.test(newPassword));
  };

  // validations

  const validateInput = () => {
    setInputValid(true);
    const isSpecialCharacterMissing = !/[!@#$%^&]/.test(newPassword);
    const isMinLengthInvalid = newPassword.length < 8;
    const isNumericCharacterMissing = !/\d/.test(newPassword);
    const isUppercaseMissing = !/[A-Z]/.test(newPassword);
    const isLowercaseMissing = !/[a-z]/.test(newPassword);

    const isInputValid =
      isInputFocused ||
      (!isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing &&
        !isLowercaseMissing);

    setInputValid(isInputValid);
    setIsSpecialCharacterMissing(isSpecialCharacterMissing);
    setIsMinLengthInvalid(isMinLengthInvalid);
    setIsNumericCharacterMissing(isNumericCharacterMissing);
    setIsUppercaseMissing(isUppercaseMissing);
    setIsLowercaseMissing(isLowercaseMissing);
  };

  // new password end

  // confirm password start

  const handleConfirmPasswordChange = (event) => {
    if (event.length == 0) {
      setIsInputValid_confirm(false);
    } else {
      validateInput_confirm();
    }
    // setInputValid(false);
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
    // const passwordPattern =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // setPasswordValid(passwordPattern.test(newPassword));
  };

  // confirm password end

  // show password logics start
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  // show password logics end

  const Resetpaassword = () => {
    if (!newPassword) {
      setErrorMessage("Please enter a new password");
      setShowModal(true);
    } else if (!confirmPassword) {
      setErrorMessage("Please enter a confirm password");
      setShowModal(true);
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Both passwords do not match.");
      setShowModal(true);
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", location.state.user_id);
      formData.append("new_password", newPassword);
      formData.append("confirm_password", confirmPassword);
      //   formData.append("oldpassword", oldPassword);

      console.log("====================================");
      console.log("Password change Res -->", formData);

      axios
        .post("https://www.ravi.host/api/user_change_password", formData)
        .then((Response) => {
          setIsLoading(false);
          console.log("====================================", Response.data);

          if (Response.data.status == 1) {
            // alert(Response.data.status);
            setErrorMessage(Response.data.message);
            setShowModal(true);
            navigate("/");
            //this.props.navigation.repo('Dashboard');
            setSuccess(1);
          } else {
            // alert(Response.data.message);
            setErrorMessage(Response.data.message);
            setShowModal(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error", err);
        });
    }
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp">
          {/* <div className="page_navbar_wrapp">
            <div className="nav_back_wrapp">
              <img
                src={images.back}
                className="nav_back_btn"
                alt=""
                onClick={() => navigate("/")}
              />
            </div>
            <p className="new_nav_screen_name">Change Password</p>
          </div> */}
          <NavbarSec name="Change Password" />

          <div className="chnage_user_" style={{ margin: "30px auto" }}>
            <p
              style={{
                fontFamily: "var(--font-600)",
                color: "var(--color-white)",
                fontSize: "14px",
                marginBottom: "2px",
              }}
            >
              New Password
            </p>
            <input
              type={showPassword2 ? "text" : "password"}
              placeholder="New Password"
              className="chnages_input"
              required
              style={{ height: "35px", border: "1px solid #fff" }}
              value={newPassword}
              onChange={(e) => {
                handlePasswordChange(e);
                // setNewPassword(e.target.value);
              }}
              onBlur={() => {
                setInputValid(false);
                setIsInputFocused(false);
              }}
              onFocus={() => {
                setIsInputFocused(true);
              }}
            />
            <img
              // src={images.password_seq}
              src={showPassword2 ? images.eyeopen : images.eyeoff}
              className="change_pass_show_new"
              style={{ bottom: "43px" }}
              alt=""
              onClick={toggleShowPassword2}
            />
            {isInputValid && (
              <div style={{ marginLeft: 10 }}>
                {/* {isMinLengthInvalid && ( */}
                <p
                  style={{
                    color: isMinLengthInvalid ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must be at least 8 characters long
                </p>
                {/* )} */}
                {/* {isSpecialCharacterMissing && ( */}
                <p
                  style={{
                    color: isSpecialCharacterMissing ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one special character
                </p>
                {/* )} */}
                {/* {isNumericCharacterMissing && ( */}
                <p
                  style={{
                    color: isNumericCharacterMissing ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one numeric character
                </p>
                {/* )} */}
                {/* {isUppercaseMissing && ( */}
                <p
                  style={{
                    color: isUppercaseMissing ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one uppercase character
                </p>
                {/* )} */}
                {/* {isLowercaseMissing && ( */}
                <p
                  style={{
                    color: isLowercaseMissing ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one lowercase character
                </p>
                {/* )} */}
              </div>
            )}
            <br />
            <p
              style={{
                fontFamily: "var(--font-600)",
                color: "var(--color-white)",
                fontSize: "14px",
                marginBottom: "2px",
              }}
            >
              Confirm Password
            </p>
            <input
              // type="password"
              type={showPassword3 ? "text" : "password"}
              placeholder="Confirm Password"
              className="chnages_input"
              required
              style={{ height: "35px", border: "1px solid #fff" }}
              value={confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e)}
              onBlur={() => {
                setIsInputValid_confirm(false);
                setIsInputFocused_confirm(false);
              }}
              onFocus={() => {
                setIsInputFocused_confirm(true);
              }}
            />
            <img
              // src={images.password_seq}
              src={showPassword3 ? images.eyeopen : images.eyeoff}
              className="change_pass_show_new"
              alt=""
              style={{ bottom: "43px" }}
              onClick={toggleShowPassword3}
            />
            {isInputValid_confirm && (
              <div style={{ marginLeft: 10 }}>
                {/* {isMinLengthInvalid && ( */}
                <p
                  style={{
                    color: isMinLengthInvalid_confirm ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must be at least 8 characters long
                </p>
                {/* )} */}
                {/* {isSpecialCharacterMissing && ( */}
                <p
                  style={{
                    color: isSpecialCharacterMissing_confirm ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one special character
                </p>
                {/* )} */}
                {/* {isNumericCharacterMissing && ( */}
                <p
                  style={{
                    color: isNumericCharacterMissing_confirm ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one numeric character
                </p>
                {/* )} */}
                {/* {isUppercaseMissing && ( */}
                <p
                  style={{
                    color: isUppercaseMissing_confirm ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one uppercase character
                </p>
                {/* )} */}
                {/* {isLowercaseMissing && ( */}
                <p
                  style={{
                    color: isLowercaseMissing_confirm ? "gray" : "green",
                    fontSize: 10,
                    marginBottom: 3,
                  }}
                >
                  Password must include at least one lowercase character
                </p>
                {/* )} */}
              </div>
            )}
            <div className="footer_chnages_pass">
              <button
                className="submit_chnages_password"
                type="submit"
                onClick={() => Resetpaassword()}
              >
                Submit
              </button>
            </div>
          </div>
          {showModal && (
            <div className="modal_c">
              <div className="modal_c-content">
                <div className="modal_c_div1">
                  <img className="forgot_main" src={images.logo} alt="" />
                </div>
                <div className="modal_c_div">
                  <b></b>
                  <br />
                  <p className="modal_c-message">{errorMessage}</p>
                  <br />
                </div>
                <hr className="hr_forgot" />
                <br />
                <div className="modal_c_div">
                  <button
                    className="close_forgot"
                    onClick={() => {
                      closeModal();
                      if (getsuccess == 1) {
                        navigate(-1);
                      }
                      console.log("getsuccess", getsuccess);
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ForgateNewPass;
