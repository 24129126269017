import React, { useState, useRef, useEffect } from "react";
import "./setmpin.css";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import { user_Set_m_pin } from "../../utils/Constant";
import axios from "axios";

const SetMpin = () => {
  const [enterMpin, setEnterMpin] = useState(["", "", "", ""]);
  const [confirmMpin, setConfirmMpin] = useState(["", "", "", ""]);
  const enterMpinRefs = useRef([]); // Refs to store references to the Enter Mpin input fields
  const confirmMpinRefs = useRef([]); // Refs to store references to the Confirm Mpin input fields

  const handleMpinChange = (index, value, isEnterMpin) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      if (isEnterMpin) {
        const newEnterMpin = [...enterMpin];
        newEnterMpin[index] = value;
        setEnterMpin(newEnterMpin);
        if (value !== "" && index < enterMpin.length - 1) {
          enterMpinRefs.current[index + 1].focus();
        }
      } else {
        const newConfirmMpin = [...confirmMpin];
        newConfirmMpin[index] = value;
        setConfirmMpin(newConfirmMpin);
        if (value !== "" && index < confirmMpin.length - 1) {
          confirmMpinRefs.current[index + 1].focus();
        }
      }
    }
  };

  const isMpinMatch = enterMpin.join("") === confirmMpin.join("");

  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [getcon, setcon] = useState(false);

  const mpingetotp = () => {
    const otpValueEmail = otpValue;
    const otpValueEmail1 = otpValue1;
    console.log("otpValueEmail", otpValueEmail, otpValueEmail1);
    if (otpValueEmail == "") {
      setErrorMessage("Please enter a mpin");
      setShowModal(true);
    } else if (otpValueEmail1 == "") {
      setErrorMessage("Please enter a confirm mpin");
      setShowModal(true);
    } else if (otpValueEmail !== otpValueEmail1) {
      setErrorMessage("mpin and confirm mpin dose not match");
      setShowModal(true);
    } else if (otpValueEmail.length !=4) {
      setErrorMessage("Please enter valid mpin");
      setShowModal(true);
    } else {
      setloading(true);
      const param = {
        user_id: userId,
        m_pin: otpValueEmail1,
      };
      console.log(param);
      axios
        .post(user_Set_m_pin, param)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            setErrorMessage(Response.data.message);
            setShowModal(true);
            setcon(true);
            // navigate("/usermenu");
          } else {
            setErrorMessage(Response.data.message);
            setShowModal(true);
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };
  const otpValue = mobileotp.join("");

  ///
  useEffect(() => {
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const [mobileotp1, setMobileOtp1] = React.useState(["", "", "", ""]);
  const refsMobile1 = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile1 = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp1 = [...mobileotp1];
      newOtp1[index] = value;
      setMobileOtp1(newOtp1);

      if (index < refsMobile1.length - 1 && value !== "") {
        refsMobile1[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile1 = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp1[index] === "") {
      const newOtp = [...mobileotp1];
      newOtp[index - 1] = "";
      setMobileOtp1(newOtp);
      refsMobile1[index - 1].current.focus();
    }
  };
  const otpValue1 = mobileotp1.join("");

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <center>
          <b>Set Mpin</b>
        </center>
        <br />

        <div
          style={{
            marginTop: "20px",
            alignSelf: "center",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Enter Mpin</p>
          <div className="otp_input_container" style={{ marginTop: "20px",justifyContent:"space-between" }}>
            {mobileotp.map((digit, index) => (
              <input
                style={{
                  borderWidth: 0.5,
                }}
                key={index}
                type="number"
                maxLength={1}
                className="in_de_otp"
                ref={refsMobile[index]}
                value={digit}
                onChange={(event) => handleInputChangeMobile(index, event)}
                onKeyDown={(event) => handleKeyDownMobile(index, event)}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <p>Confirm Mpin</p>
          <div className="otp_input_container" style={{ marginTop: "20px",justifyContent:"space-between" }}>
            {mobileotp1.map((digit, index) => (
              <input
                style={{
                  borderWidth: 0.5,
                }}
                key={index}
                type="number"
                maxLength={1}
                className="in_de_otp"
                ref={refsMobile1[index]}
                value={digit}
                onChange={(event) => handleInputChangeMobile1(index, event)}
                onKeyDown={(event) => handleKeyDownMobile1(index, event)}
              />
            ))}
          </div>
        </div>
        <br/>
        <div className="mobile_new_details2">
          <button
            onClick={() => {
              mpingetotp();
            }}
            className="mobile_submit_new_btn"
            // disabled={!isMpinMatch}
          >
            Save
          </button>
        </div>
      </div>
      {showModal && (
        <div className="cusModal_main_wrapp">
          <div className="cusmodal_inner_wrapp">
            <img src={images.logo} alt="" className="cm_logo" />
            <p className="cm_heading"></p>
            <div className="cm_des_wrapp">
              <p className="cm_des">{errorMessage}</p>
              <div className="cm_hr"></div>
              <button
                className="cm_btn"
                onClick={() => {
                  getcon == true ? navigate("/usermenu") : setShowModal(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SetMpin;
