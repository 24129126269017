import React, { useState } from "react";
import images from "../../constants/images";
import "./assetsapproval.css";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import { get_user_pending_verify_product } from "../../utils/Constant";
import { useEffect } from "react";
import qs from "qs";
import { FaAngleLeft } from "react-icons/fa";

const Assetsapproval = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [activeSection, setActiveSection] = useState(0);
  const [getdata, setData] = useState([]);
  const [getrequest, setRequest] = useState(0);
  const [getaccept, setAccept] = useState(0);
  const [getreject, setReject] = useState(0);
  const [getsearchData, setSearchData] = useState([]);
  const [getisOk, setIsOk] = useState(null);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const GetAcceptApi = async () => {
    const param = {
      user_id: userId,
    };

    await axios
      .post(get_user_pending_verify_product, qs.stringify(param))
      .then((Response) => {
        console.log("response.data", Response);
        if (Response.data.status == 1) {
          setData(Response.data.result);
          setSearchData(Response.data.result);
          setRequest(Response.data.request_count);
          setAccept(Response.data.accept_count);
          setReject(Response.data.reject_count);
          setIsOk(0);
        } else {
          setIsOk(1);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const searchFilter_branch = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("item", item.product_name);
      const employee = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.username)
        ? String(item.username).toUpperCase()
        : "".toUpperCase();
      const stock = String(item.total_available_bond_bid)
        ? String(item.total_available_bond_bid).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        stock.indexOf(textData) > -1
      );
    });

    setData(newData);

    console.log("asvsvsds", newData);
  };

  useEffect(() => {
    GetAcceptApi();
    console.log("getdata", getdata);
  }, []);

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30px",
            background: "black",
            zIndex: 11,
            marginTop: "20px",
          }}
        >
          <div
            onClick={() => {
              navigate("/NewSideMenu");
            }}
            className="navsec_back_arrow"
          >
            {/* <img
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                navigate("/NewSideMenu");
              }}
            /> */}
            <FaAngleLeft color="var(--color-white)" size={20} />
          </div>
        </div>

        {getisOk === 1 && (
          <p
            style={{
              color: "var(--color-white)",
              fontSize: "16px",
              fontFamily: "var(--font-600)",
              width: "100%",
              margin: "10px auto",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            There are currently no creator requests from users
          </p>
        )}

        {/* <div className="approval_club_user">
          <input
            type="text"
            placeholder="Search"
            className="user_approval_search"
            onChange={(e) => searchFilter_branch(e.target.value)}
          />
        </div> */}
        <div style={{}} className="bid13_search">
          <div className="bids13_user_bid_main" style={{ paddingTop: "20px" }}>
            <input
              type="text"
              placeholder="Search"
              className="bids13_user_bid_search"
              // value={searchTerm}
              onChange={(e) => searchFilter_branch(e.target.value)}
            />
            {/* <img src={images.search} alt="" className="search_ic" /> */}
          </div>
        </div>

        <div className="main_approval_btn">
          <button
            className={`Accepted_btn ${activeSection === 1 ? "active" : ""}`}
            onClick={() => handleSectionChange(1)}
          >
            Accepted
          </button>
          <button
            className={`Accepted_btn ${activeSection === 0 ? "active" : ""}`}
            onClick={() => handleSectionChange(0)}
          >
            Requests
          </button>
          <button
            className={`Accepted_btn ${activeSection === 2 ? "active" : ""}`}
            onClick={() => handleSectionChange(2)}
          >
            Rejected
          </button>
        </div>
        {activeSection == 0 && getrequest == 0 && (
          <p className="assetapprov_nodata_text">No Request</p>
        )}
        {activeSection == 1 && getaccept == 0 && (
          <p className="assetapprov_nodata_text">No Request</p>
        )}
        {activeSection == 2 && getreject == 0 && (
          <p className="assetapprov_nodata_text">No Request</p>
        )}

        <div className="Accepted_btn_details">
          {getdata && getdata.length > 0
            ? getdata.map((item, index) => {
                return activeSection == 0 ? (
                  item.is_status == 0 ? (
                    <>
                      <div
                        // style={{ border: "1px solid red" }}
                        onClick={() => {
                          console.log("pending_bond_id", item.pending_bond_id);

                          {
                            item.is_nfb == 0
                              ? navigate("/NewApprovalCreate", {
                                  state: {
                                    product_id: item.product_id,
                                    username: item.username,
                                    club_id: item.club_id,
                                    user_id: item.user_id,
                                    owner_id: item.owner_id,
                                    owner_name: item.owner_name,
                                    is_nfb: item.is_nfb,
                                    product_name: item.product_name,
                                    img_video: item.bond_img_video,
                                    owner_proof: item.owner_proof,
                                    pending_bond_id: item.pending_bond_id,
                                  },
                                })
                              : navigate("/NewApprovalClubCreate", {
                                  state: {
                                    product_id: item.product_id,
                                    username: item.username,
                                    club_id: item.club_id,
                                    user_id: item.user_id,
                                    owner_id: item.owner_id,
                                    owner_name: item.owner_name,
                                    is_nfb: item.is_nfb,
                                    product_name: item.product_name,
                                    img_video: item.bond_img_video,
                                    owner_proof: item.owner_proof,
                                    pending_bond_id: item.pending_bond_id,
                                  },
                                });
                          }
                        }}
                        className="accepted_single_card"
                      >
                        <div className="accepted_details_sub_1">
                          <p className="accepted_details_pro">Asset Name</p>
                          <p className="accepted_details_nm">
                            {item.product_name}
                          </p>
                        </div>
                        <img
                          src={item.bond_img_video[0].img_video}
                          className="accepted_details_img"
                          alt=""
                        />
                        <div className="accepted_details_sub_2">
                          <h1 className="accepted_request">Request by</h1>
                          <div className="accepted_details_sub_3">
                            <img
                              src={
                                item.profile == ""
                                  ? images.mask_profile
                                  : item.profile
                              }
                              className="profile_accepted_details"
                              alt=""
                            />
                            <h1 className="accepted_nm">{item.username}</h1>
                          </div>
                          {item.is_status == 0 ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                // alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="new_btn_wrapp_for_assetaproval mt-3"
                                onClick={() => {
                                  // navigate("/NewApprovalCreate", {
                                  //   state: {
                                  //     product_id: item.product_id,
                                  //     username: item.username,
                                  //     club_id: item.club_id,
                                  //     user_id: item.user_id,
                                  //     owner_id: item.owner_id,
                                  //     owner_name: item.owner_name,
                                  //     is_nfb: item.is_nfb,
                                  //     product_name: item.product_name,
                                  //     img_video: item.bond_img_video,
                                  //     owner_proof: item.owner_proof,
                                  //   },
                                  // });
                                  console.log(
                                    "item.product_id",
                                    item.product_id
                                  );
                                }}
                              >
                                <p>Action Needed</p>
                              </button>
                            </div>
                          ) : (
                            <h1 className="accepted_text">
                              {item.action == 1
                                ? "Panding"
                                : item.action == 2
                                ? "Accepted"
                                : "Rejected"}
                            </h1>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null
                ) : activeSection == 1 ? (
                  item.is_status == 1 && item.action == 2 ? (
                    <div className="accepted_single_card">
                      <div className="accepted_details_sub_1">
                        <p className="accepted_details_pro">Asset Name</p>
                        <p className="accepted_details_nm">
                          {item.product_name}
                        </p>
                      </div>
                      <img
                        src={item.bond_img_video[0].img_video}
                        className="accepted_details_img"
                        alt=""
                      />
                      <div className="accepted_details_sub_2">
                        <h1 className="accepted_request">Request by</h1>
                        <div className="accepted_details_sub_3">
                          <img
                            src={
                              item.profile == ""
                                ? images.mask_profile
                                : item.profile
                            }
                            className="profile_accepted_details"
                            alt=""
                          />
                          <h1 className="accepted_nm">{item.username}</h1>
                        </div>
                        {item.is_status == 0 ? (
                          <button
                            style={{
                              color: "var(--color-back)",
                              fontSize: "12px",
                              fontFamily: "var(--font-400)",
                              border: "none",
                            }}
                            onClick={() => {
                              console.log(
                                "pending_bond_id",
                                item.pending_bond_id
                              );
                              {
                                item.is_nfb == "0" || item.is_nfb == 0
                                  ? navigate("/NewApprovalCreate", {
                                      state: {
                                        product_id: item.product_id,
                                        username: item.username,
                                        club_id: item.club_id,
                                        user_id: item.user_id,
                                        owner_id: item.owner_id,
                                        owner_name: item.owner_name,
                                        is_nfb: item.is_nfb,
                                        product_name: item.product_name,
                                        img_video: item.bond_img_video,
                                        owner_proof: item.owner_proof,
                                        pending_bond_id: item.pending_bond_id,
                                      },
                                    })
                                  : navigate("/NewApprovalClubCreate", {
                                      state: {
                                        product_id: item.product_id,
                                        username: item.username,
                                        club_id: item.club_id,
                                        user_id: item.user_id,
                                        owner_id: item.owner_id,
                                        owner_name: item.owner_name,
                                        is_nfb: item.is_nfb,
                                        product_name: item.product_name,
                                        img_video: item.bond_img_video,
                                        owner_proof: item.owner_proof,
                                        pending_bond_id: item.pending_bond_id,
                                      },
                                    });
                              }
                            }}
                          >
                            Action Needed
                          </button>
                        ) : (
                          <h1 className="accepted_text">
                            {item.action == 1
                              ? "Panding"
                              : item.action == 2
                              ? "Accepted"
                              : "Rejected"}
                          </h1>
                        )}
                      </div>
                    </div>
                  ) : null
                ) : item.is_status == 1 && item.action == 3 ? (
                  <div className="accepted_single_card">
                    <div className="accepted_details_sub_1">
                      <p className="accepted_details_pro">Asset Name</p>
                      <p className="accepted_details_nm">{item.product_name}</p>
                    </div>
                    <img
                      src={item.bond_img_video[0].img_video}
                      className="accepted_details_img"
                      alt=""
                    />
                    <div className="accepted_details_sub_2">
                      <h1 className="accepted_request">Request by</h1>
                      <div className="accepted_details_sub_3">
                        <img
                          src={
                            item.profile == ""
                              ? images.mask_profile
                              : item.profile
                          }
                          className="profile_accepted_details"
                          alt=""
                        />
                        <h1 className="accepted_nm">{item.username}</h1>
                      </div>
                      {item.is_status == 0 ? (
                        <button
                          style={{
                            color: "var(--color-back)",
                            fontSize: "12px",
                            fontFamily: "var(--font-400)",
                            border: "none",
                          }}
                          onClick={() => {
                            console.log(item.is_nfb);
                            {
                              item.is_nfb == "0" || item.is_nfb == 0
                                ? navigate("/NewApprovalCreate", {
                                    state: {
                                      product_id: item.product_id,
                                      username: item.username,
                                      club_id: item.club_id,
                                      user_id: item.user_id,
                                      owner_id: item.owner_id,
                                      owner_name: item.owner_name,
                                      is_nfb: item.is_nfb,
                                      product_name: item.product_name,
                                      img_video: item.bond_img_video,
                                      owner_proof: item.owner_proof,
                                      pending_bond_id: item.pending_bond_id,
                                    },
                                  })
                                : navigate("/NewApprovalClubCreate", {
                                    state: {
                                      product_id: item.product_id,
                                      username: item.username,
                                      club_id: item.club_id,
                                      user_id: item.user_id,
                                      owner_id: item.owner_id,
                                      owner_name: item.owner_name,
                                      is_nfb: item.is_nfb,
                                      product_name: item.product_name,
                                      img_video: item.bond_img_video,
                                      owner_proof: item.owner_proof,
                                      pending_bond_id: item.pending_bond_id,
                                    },
                                  });
                            }
                          }}
                        >
                          Action Needed
                        </button>
                      ) : (
                        <h1 className="accepted_text">
                          {item.action == 1
                            ? "Panding"
                            : item.action == 2
                            ? "Accepted"
                            : "Rejected"}
                        </h1>
                      )}
                    </div>
                  </div>
                ) : null;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Assetsapproval;
