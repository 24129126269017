import React, { useEffect, useState } from "react";
import "./UpdateIntrests.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  AssetLink,
  add_user_seleted_interest,
  edit_profile,
  get_all_interest,
  user_add_interest_v2,
} from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa6";

const UpdateIntrests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [allIntrests, setAllInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    fetchInterests();
    fetchSelectedInterests();
  }, []);

  const fetchInterests = () => {
    setLoading(true);
    axios
      .get(get_all_interest)
      .then((response) => {
        // setLoading(false);
        if (response.data.status === 1) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setAllInterests(response.data.result);
        } else {
          setLoading(false);

          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const handleInterestSelection = (item) => {
    const updatedSelectedInterests = [...selectedInterests];
    const updatedSelectedIds = selectedIds.split(", ");

    const index = updatedSelectedInterests.findIndex(
      (interest) => interest.id === item.id
    );

    if (index !== -1) {
      updatedSelectedInterests.splice(index, 1);
    } else {
      if (updatedSelectedInterests.length >= 5) {
        toast.error("You can only select five interests", { theme: "colored" });
        return;
      }
      updatedSelectedInterests.push({
        ...item,
        isHighlighted: true,
      });
    }

    const updatedIds = updatedSelectedInterests
      .map((interest) => interest.id)
      .join(", ");

    setSelectedInterests(updatedSelectedInterests);
    setSelectedIds(updatedIds);
  };

  const isInterestSelected = (item) => {
    return selectedInterests.some((interest) => interest.id === item.id);
  };

  const validateAndSubmit = () => {
    if (selectedInterests.length >= 5) {
      setLoading(true);

      const param = {
        user_id: userId, // Replace with your user ID logic
        interest_id: selectedIds,
      };

      axios
        .post(
          "https://www.ravi.host/api/edit_user_selected_interest",
          qs.stringify(param)
        )
        .then((response) => {
          setLoading(false);

          if (response.data.status === 1) {
            // Successful update logic
            toast.success("Interests Updated successfully", {
              theme: "colored",
            });

            // navigate("/homescreen2");
          } else {
            // alert(response.data.message);
            toast.error(response.data.message, { theme: "colored" });
          }
        })
        .catch((error) => {
          // alert(error);
          toast.error(error, { theme: "colored" });

          setLoading(false);
        });
    } else {
      toast.error("You can only select five interests", { theme: "colored" });

      setLoading(false);
    }
  };

  const fetchSelectedInterests = () => {
    setLoading(true);

    const param = {
      user_id: userId, // Replace with your user ID logic
    };

    axios
      .post(
        "https://www.ravi.host/api/get_all_user_selected_interest",
        qs.stringify(param)
      )
      .then((response) => {
        // setLoading(false);

        if (response.data.status === 1) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          const selectedInterests = response.data.result.map((item) => ({
            ...item,
            isHighlighted: true,
          }));

          const selectedIds = selectedInterests
            .map((interest) => interest.id)
            .join(", ");

          // setAllInterests(response.data.result);
          setSelectedInterests(selectedInterests);
          setSelectedIds(selectedIds);
        } else {
          setLoading(false);
          // alert(response.data.message);
          // setAlertName("Alert");
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        {isLoading ? (
          <NewLoader />
        ) : (
          <div className="new_container">
            <div className="set_otp_main_wrapp">
              <div className="legacy_dashbord_nav_sec">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Update Interest</p>
                <div></div>
              </div>
              {/* <h2 className="newinterest_heading">Update Category</h2> */}
              <div
                className="newinterest_boxes_main_wrapp"
                style={{ height: "604px" }}
              >
                <div className="newinterest_boxes_gradiant"></div>

                {/* single interest start */}
                {allIntrests && allIntrests.length > 0
                  ? allIntrests.map((item, index) => {
                      return (
                        <button
                          key={item.id}
                          onClick={() => handleInterestSelection(item)}
                          className="newinterest_single_box"
                        >
                          <img
                            src={
                              isInterestSelected(item)
                                ? AssetLink + item.new_color_icon
                                : AssetLink + item.new_icon
                            }
                            alt=""
                          />
                          <p
                            style={{
                              color: isInterestSelected(item)
                                ? "var(--color-main)"
                                : "var(--color-white)",
                            }}
                          >
                            {" "}
                            {item.name}
                          </p>
                        </button>
                      );
                    })
                  : null}
                {/* single interest end */}
              </div>

              <button
                onClick={() => validateAndSubmit()}
                className="new_btn_wrapp mx-1"
              >
                <p>Submit</p>
              </button>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default UpdateIntrests;
