import React, { useEffect, useState } from "react";
import "./NewCommunityRequest.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";

const NewFamilyRequest = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [getseletedTab, setSelectedTab] = useState(1);

  const _getData = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_user_family_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setData(response.data.result);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _setAcceptReject = (id, status) => {
    const param = {
      user_family_id: id,
      user_id: userId,
      status: status,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/accept_reject_family_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          //   setData(response.data.result);
          _getData();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    _getData();
  }, []); // This will run _getData when the component mounts
  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate("/LegacyDashbord", {
                    state: {
                      first_time_popup: 0,
                      first_time_home: 0,
                    },
                  });
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Family Requests</p>
              <div></div>
            </div>

            {/* request tabs section start */}

            <div className="family_req_tabs_sec">
              <div className="family_req_tabs_sec_details">
                <button
                  onClick={() => {
                    setSelectedTab(1);
                  }}
                  style={{ opacity: getseletedTab === 1 ? 1 : 0.5 }}
                >
                  Received
                </button>
                <div></div>
                <button
                  onClick={() => {
                    setSelectedTab(2);
                  }}
                  style={{ opacity: getseletedTab === 2 ? 1 : 0.5 }}
                >
                  Send
                </button>
              </div>
              <div className="family_req_tabs_sec_hr"></div>
            </div>

            {/* request tabs section end */}

            {/* recevied list start */}
            <div className="family_req_receved_list">
              {getseletedTab == 1 && (
                <div className="family_req_receved_single_card">
                  <img src={images.hr_img} alt="" />
                  <div className="family_req_receved_single_card_name">
                    <h4>Request Title</h4>
                    <p>Request Content</p>
                    <p>20h</p>
                  </div>
                  <div className="family_req_receved_single_card_btn_wrapp">
                    <button className="family_req_receved_single_card_btn">
                      <FiCheck size={20} color="var(--color-white)" />
                    </button>
                    <button className="family_req_receved_single_card_btn">
                      <IoClose size={20} color="var(--color-white)" />
                    </button>
                  </div>
                </div>
              )}
              {getseletedTab == 2 && (
                <div className="family_req_receved_single_card">
                  <img src={images.hr_img} alt="" />
                  <div className="family_req_receved_single_card_name">
                    <h4>Request Title</h4>
                    <p>Request Content</p>
                    <p>20h</p>
                  </div>
                  <button className="family_req_receved_single_card_lrg_btn">
                    <p>Accepted</p>
                  </button>
                </div>
              )}
            </div>
            {/* recevied list end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewFamilyRequest;
