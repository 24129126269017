import React from "react";
import "./MyCommunity.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import images from "../../constants/images";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";
import qs from "qs";
import V2Modal from "../../common/v2modal/V2Modal";

const MyCommunity = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [getallBonds, setAllBonds] = useState([]);
  const [getsearchBonds, setSearchBonds] = useState([]);
  const [getselectedProIds, setSelectedProIds] = useState([]);
  const [getselectedBondId, setSelectedBondId] = useState([]);
  const [getselectedBondIsNfb, setSelectedBondIsNfb] = useState([]);

  const [getselectedProIdsString, setSelectedProIdsString] = useState("");
  const [getselectedBondIdString, setSelectedBondIdString] = useState("");
  const [getselectedBondIsNfbString, setSelectedBondIsNfbString] = useState("");
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal1, setShowModal1] = useState(false);

  const handleRarerezSearch = (event) => {
    const query = event.target.value;
    setSearchBonds(query);
    filterData(query);
  };

  const filterData = (query) => {
    const filtered = getallBonds.filter(
      (item) =>
        item.username &&
        item.username.toLowerCase().includes(query.toLowerCase())
    );
    setSearchBonds(filtered);
  };

  const handleCheckboxChange = (proids, productId, isNfb) => {
    // Create a copy of the existing state arrays
    const selectedProIdsCopy = [...getselectedProIds];
    const selectedBondIdsCopy = [...getselectedBondId];
    const selectedBondIsNfbCopy = [...getselectedBondIsNfb];

    // Check if the product_id is already in the array
    const index = selectedBondIdsCopy.indexOf(productId);

    // If the product_id is not in the array, add it
    if (index === -1) {
      selectedProIdsCopy.push(proids);
      selectedBondIdsCopy.push(productId);
      selectedBondIsNfbCopy.push(isNfb);
    } else {
      // If the product_id is already in the array, remove it
      selectedProIdsCopy.splice(index, 1);
      selectedBondIdsCopy.splice(index, 1);
      selectedBondIsNfbCopy.splice(index, 1);
    }

    // Update the state with the modified arrays
    setSelectedProIds(selectedProIdsCopy);
    setSelectedBondId(selectedBondIdsCopy);
    setSelectedBondIsNfb(selectedBondIsNfbCopy);

    const selectedProIdsString = selectedProIdsCopy.join(",");
    const selectedBondIdsString = selectedBondIdsCopy.join(",");
    const selectedBondIsNfbString = selectedBondIsNfbCopy.join(",");
    setSelectedProIdsString(selectedProIdsString);
    setSelectedBondIdString(selectedBondIdsString);
    setSelectedBondIsNfbString(selectedBondIsNfbString);
  };

  const AllBonds = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };
    console.log("legacyparam-->", param);
    await axios
      .post("https://www.ravi.host/api/get_all_user_by_com", param, {})
      .then((res) => {
        setIsLoading(false);
        console.log("res", res.data.result);
        // if (res.data.status === 200) {
        setAllBonds(res.data.result);
        setSearchBonds(res.data.result);
        _Getlegacycommunity();
        // }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const Addusercommunity = async () => {
    if (getselectedBondIdString === "") {
      toast.error("Please select member", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      const param = {
        user_id: userId,
        community_id: getselectedBondIdString,
        legacy_id: location.state.legacy_id,
      };
      console.log("legacyparam-->", param);
      await axios
        .post("https://www.ravi.host/api/add_user_community", param, {})
        .then((res) => {
          setIsLoading(false);
          console.log("res", res.data);
          if (res.data.status === 1) {
            toast.success(res.data.message, {
              theme: "colored",
            });
            navigate("/LegacyDashbord", {
              state: {
                first_time_popup: 0,
                first_time_home: 0,
              },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    AllBonds();
    _Getlegacycommunity();
  }, []);

  const [getlegacy_family_data, setlegacy_family_data] = useState([]);
  const _Getlegacycommunity = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_legacy_community",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res--->", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setlegacy_family_data(response.data.legacy_community_data);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          toast.error(response.data.message, {
            theme: "colored",
          });
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const [getuser_community_id, setuser_community_id] = useState("");
  const _Deletecommunity = () => {
    const param = {
      user_community_id: getuser_community_id,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/delete_user_community",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res--->", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setShowModal1(false);
          AllBonds();
          _Getlegacycommunity();
          // setlegacy_family_data(response.data.legacy_community_data);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="user_benefit_allbonds_modal_wrapp_1">
      <div className="new_container">
        <div className="user_benefit_bond_modal_heding_1">
          <button
            onClick={() => {
              navigate("/LegacyDashbord", {
                state: {
                  first_time_popup: 0,
                  first_time_home: 0,
                },
              });
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>My Community</p>
        </div>
        {isLoading ? (
          <NewLoader />
        ) : (
          <>
            {/* search bar start */}
            <div className="myproduct_13_search">
              <div className="myproduct_13_user_bid_main">
                <input
                  type="text"
                  placeholder="Search Member"
                  className="myproduct_13_user_bid_search"
                  // value={searchTerm}
                  // onChange={(e) => handleSearch(e.target.value)}
                  onChange={handleRarerezSearch}
                />
                {/* <IoSearch
          size={24}
          color="#fff"
          className="myproduct_13_search_ic"
        /> */}
              </div>
            </div>

            {/* search bar end */}
            {getlegacy_family_data &&
            getlegacy_family_data.length == 0 ? null : (
              <>
                <p style={{ marginTop: "5px" }}>Your Community</p>
                <div
                  className="user_benefit_modal_allbonds_wrapp_mycommunity"
                  style={{ paddingBottom: "10px" }}
                >
                  <>
                    {getlegacy_family_data && getlegacy_family_data.length > 0
                      ? getlegacy_family_data.map((item, index) => {
                          return (
                            <div
                              className="user_benefit_modal_single_bond_1"
                              style={{
                                marginTop: "5px",
                                backgroundColor: "rgba(255, 255, 255, 0.08)",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                borderRadius: "5px",
                              }}
                              // key={item.product_id}
                            >
                              <div className="user_benefit_modal_single_bond_inner_wrapp">
                                <div
                                  onClick={() => {
                                    navigate("/userProfile", {
                                      state: { user_id: item.user_id },
                                    });
                                  }}
                                  style={{
                                    zIndex: "111",
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "45px",
                                    backgroundImage:
                                      "linear-gradient(to right, rgba(255, 0, 76, 1), rgba(202, 172, 255, 1))",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      borderRadius: "40px",
                                    }}
                                    src={item.profile}
                                    alt=""
                                  />
                                </div>
                                <p
                                  onClick={() => {
                                    navigate("/userProfile", {
                                      state: { user_id: item.user_id },
                                    });
                                  }}
                                >
                                  {item.firstname}
                                </p>
                                {item.status == 1 ? (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "var(--color-white)",
                                      opacity: 0.8,
                                      fontFamily: "var(--font-600)",
                                      margin: "0px",
                                      padding: "0px",
                                    }}
                                  >
                                    Accepted
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "var(--color-white)",
                                      opacity: 0.8,
                                      fontFamily: "var(--font-600)",
                                      margin: "0px",
                                      padding: "0px",
                                    }}
                                  >
                                    Panding
                                  </span>
                                )}
                              </div>
                              {/* <div
                                onClick={() => {
                                  setuser_community_id(item.user_community_id);
                                  setShowModal1(true);
                                  // _Deletecommunity(item.user_community_id);
                                }}
                                style={{
                                  width: "100%",
                                  alignItems: "flex-end",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  zIndex: "111",
                                }}
                              >
                                <p style={{ fontSize: "12px", color: "red" }}>
                                  Remove
                                </p>
                              </div> */}
                              <img
                                style={{
                                  alignItems: "flex-end",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  zIndex: "111",
                                  width: "25px",
                                  height: "25px",
                                  alignSelf: "flex-end",
                                }}
                                className="viewmanagers_details_img"
                                src={images.new_delete_icon}
                                alt=""
                                // onClick={() => deletemanager(item.manager_id)}

                                onClick={() => {
                                  setuser_community_id(item.user_community_id);
                                  setShowModal1(true);
                                  // _Deletecommunity(item.user_community_id);
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </>
                </div>
              </>
            )}
            {/* /// */}
            <p style={{ marginTop: "1rem" }}>Search Results</p>
            <div className="user_benefit_modal_allbonds_wrapp_1">
              <>
                {getsearchBonds && getsearchBonds.length > 0 ? (
                  getsearchBonds.map((item, index) => {
                    return (
                      <div
                        className="user_benefit_modal_single_bond_1"
                        style={{
                          marginTop: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.08)",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          borderRadius: "5px",
                        }}
                        // key={item.product_id}
                      >
                        <div className="user_benefit_modal_single_bond_inner_wrapp">
                          <div
                            onClick={() => {
                              navigate("/userProfile", {
                                state: { user_id: item.user_id },
                              });
                            }}
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "45px",
                              backgroundImage:
                                "linear-gradient(to right, rgba(255, 0, 76, 1), rgba(202, 172, 255, 1))",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              zIndex: "111",
                            }}
                          >
                            <img
                              style={{
                                width: "42px",
                                height: "42px",
                                borderRadius: "40px",
                              }}
                              src={item.profile}
                              alt=""
                            />
                          </div>
                          <p
                            onClick={() => {
                              navigate("/userProfile", {
                                state: { user_id: item.user_id },
                              });
                            }}
                          >
                            {item.username}
                          </p>
                          <img
                            onClick={() => {
                              handleCheckboxChange(
                                item.pro_as_id,
                                item.user_id,
                                item.is_nfb
                              );
                            }}
                            //   className="attach_partner_select"
                            style={{
                              width: "25px",
                              height: "25px",
                              zIndex: "111",
                              // borderRadius: "10px",
                            }}
                            src={
                              getselectedBondId.includes(item.user_id)
                                ? images.tickcommunity
                                : images.untickcommunity
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No items found.</p>
                )}
              </>
            </div>

            <button
              onClick={() => Addusercommunity()}
              className="new_btn_wrapp"
              //   style={{ backgroundColor: "#000" }}
              style={{
                position: "fixed",
                bottom: "10%",
                width: "90%",
                //   left: "10%",
                transform: "translate(0%, 50%)",
                maxWidth: "var(--max-width-sm)",
                background: "#000",
                zIndex: "113",
              }}
            >
              <p>Invite Community</p>
            </button>
          </>
        )}
      </div>
      <ToastContainer />

      {showModal1 && (
        <>
          <div className="v2modal_main_wrapp">
            <div className="v2modal_inner_wrapp">
              <div className="v2modal_fixed_logo_wrapp">
                <img src={images.logo} alt="" />
              </div>
              <button
                className="v2modal_close_img"
                onClick={() => {
                  setShowModal1(false);
                }}
              >
                <img
                  src={images.new_close_logo}
                  className="v2modal_close_img"
                  alt=""
                />
              </button>
              <div className="v2modal_fixed_text_wrapp">
                <p>{"Are you sure went to remove ?"}</p>
              </div>

              <div className="v2modal_fixed_btns_wrapp">
                {/* {canclebtn && ( */}
                <button
                  className="v2modal_btn1"
                  onClick={() => {
                    setShowModal1(false);
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                <button
                  className="v2modal_btn2"
                  onClick={() => {
                    _Deletecommunity();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MyCommunity;
