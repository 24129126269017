import React, { useEffect, useRef } from "react";
import "./NewVerifyOtp.css";
import images from "../../constants/images";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import {
  edit_profile,
  user_login_otp_atch,
  user_resend_email_otp_v2,
  user_resend_otp,
  user_resend_otp_v2,
  user_reset_phone_number,
  verify_mobile_otp_v2,
} from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleLeft } from "react-icons/fa";

const NewLoginVerifyOTP = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];
  const [getcusmsg, setcusmsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const location = useLocation();
  const [getphone, setPhone] = useState(location.state.phone);
  const [getphoneedit, setPhoneEdit] = useState(location.state.phone);
  // timer states
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(30);

  // timer functions start
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(30);
  };

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (startTimer && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [setIsTimerRunning, timer]);

  useEffect(() => {
    startTimer();
  }, []);
  // timer functions end

  const modalFunctions = () => {
    setShowModal(false);
  };

  // otp inputs functions start

  const handleInputChangeMobile = (index, event) => {
    // const value = event.target.value;
    let value = event.target.value.replace(/\D/g, "");
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  const otpValue = mobileotp.join("");

  // otp inputs functions end

  // resend mobileotp api start
  const ResendMobileOtp = async () => {
    setMobileOtp(["", "", "", ""]);
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
    };
    console.log("success");
    await axios
      .post(
        location.state.is_type == 1
          ? user_resend_otp_v2
          : user_resend_email_otp_v2,
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startTimer();
          console.log("user_otp Response", Response.data);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.success(`${Response.data.message}`, { theme: "colored" });
        } else {
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.error(`${Response.data.message}`, { theme: "colored" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
        toast.error(`${err}`, { theme: "colored" });
      });
  };
  // resend mobileotp api end

  // verify mobileotp api start
  const verifyMobileOtp = async () => {
    if (!otpValue) {
      toast.error("Please enter your otp", { theme: "colored" });
    } else if (otpValue.length < 4) {
      toast.error("Please enter 4 digit otp", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        mobile_no: getphone,
        user_otp: otpValue,
        is_type: location.state.is_type,
      };
      await axios
        .post(user_login_otp_atch, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            // toast.success(`${Response.data.message}`, { theme: "colored" });
            const param1 = {
              user_id: Response.data.user_id,
            };
            let demo = Response.data.user_id;
            console.log("====================================");
            console.log("param", param1);
            console.log("====================================");

            axios
              .post(edit_profile, qs.stringify(param1))
              .then((Response) => {
                setIsLoading(false);

                console.log(
                  "----",
                  JSON.stringify(Response.data.result, null, 2)
                );

                if (Response.data.status == 1) {
                  localStorage.setItem("is_login", JSON.stringify(true));
                  localStorage.setItem("user_id", demo);
                  localStorage.setItem("email", Response.data.result.email);
                  localStorage.setItem(
                    "username",
                    Response.data.result.username
                  );
                  localStorage.setItem(
                    "profile",
                    Response.data.result.username
                  );
                  console.log("Response.data", Response.data.result);
                  navigate("/homescreen2", {
                    state: {
                      user_id: demo,
                    },
                  });
                } else {
                  toast.error(Response.data.message, { theme: "colored" });
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          } else {
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };
  // verify mobileotp api end

  // add phone api end

  const formatPhoneNumber = (inputValue) => {
    // Remove any existing spaces
    let formattedText = inputValue.replace(/ /g, "");

    // console.log("formattedText", formattedText);

    if (formattedText.length > 10) {
      formattedText = formattedText.slice(0, 10); // Limit the input to 12 characters
    }

    formattedText = formattedText.replace(/\s+/g, " ");
    setPhoneEdit(formattedText);
    const tdata = formattedText.replace(/ /g, "");

    // console.log("formattedText", tdata);
    return formattedText;
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is a digit or a space
    if (!(e.key.match(/[0-9]/) || e.key === " " || e.key === "Backspace")) {
      e.preventDefault();
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          {isTimerRunning ? null : (
            <button
              style={{ marginTop: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          )}
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "45vh" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4>Verify OTP</h4>
              <p>
                We have sent an OTP to your <br />
                <span
                  style={{
                    color: "var(--color-main)",
                    fontFamily: "var(--font-600)",
                  }}
                >
                  {location.state.is_type == 1
                    ? `+${location.state?.countries_code}`
                    : null}{" "}
                  {getphone}
                </span>
                , please check.
              </p>
            </div>

            <div className="new_verify_otp_wrapp ">
              {mobileotp.map((digit, index) => (
                <input
                  style={{
                    borderWidth: 0.5,
                    // borderColor: isMobileVerified && "green",
                  }}
                  key={index}
                  // disabled={isMobileVerified}
                  type="number"
                  maxLength={1}
                  className="new_verify_otp_inputs"
                  ref={refsMobile[index]}
                  value={digit}
                  onChange={(event) => handleInputChangeMobile(index, event)}
                  onKeyDown={(event) => handleKeyDownMobile(index, event)}
                />
              ))}
            </div>

            <div className="new_verify_otp_down_sec">
              <div>
                <p>Request another OTP in</p>
                {/* <span>00 : 30</span> */}
                <button disabled={isTimerRunning} onClick={ResendMobileOtp}>
                  {isTimerRunning ? `00 : ${timer}` : "RESEND OTP"}
                </button>
              </div>
              {/* btn */}
              <button
                disabled={!otpValue}
                onClick={verifyMobileOtp}
                className="new_btn_wrapp mx-1"
              >
                <p>Next</p>
              </button>
              {/* btn */}
              {/* <div>
                <p>
                  Want to{" "}
                  <button
                    onClick={() => {
                      setShowEditModal(true);
                    }}
                  >
                    edit mobile
                  </button>{" "}
                  number?
                </p>
              </div> */}
            </div>
          </div>
        </div>
      )}
      {/* modal start */}
      {showModal ? (
        <V2Modal
          canclebtn={false}
          title=""
          msg={getcusmsg}
          setShowModal={setShowModal}
          okfunction={modalFunctions}
        />
      ) : null}
      {/* modal end */}
      {/* edit mobile number modal start */}

      <ToastContainer />
    </div>
  );
};

export default NewLoginVerifyOTP;
