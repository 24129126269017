import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./benefitsoffered.css";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import axios from "axios";
import {
  get_user_benifit_reward_claim,
  reward_claim_user_product_accept,
} from "../../utils/Constant";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import ReactModal from "react-modal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Benefitsoffered = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [getdata, setData] = useState([]);
  const userId = localStorage.getItem("user_id");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const [getreward_id, setreward_id] = useState("");
  const [getconformationmodel, setconformationmodel] = useState(false);
  const [getmodel, setmodal] = useState(false);

  const GetBenefits = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    await axios
      .post(get_user_benifit_reward_claim, qs.stringify(param))
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          setData(res.data.result);
        } else {
          console.log("ads err ->", res.data);
          // setIsModalVisible("true");
          // setMsg(res.data.message);
          if (res.data.message !== "Record Not Found.") {
            toast.error(res.data.message, {
              theme: "colored",
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const ClaimReward = async () => {
    setIsLoading(true);
    const param = {
      reward_id: getreward_id,
      status: 2,
    };

    await axios
      .post(reward_claim_user_product_accept, qs.stringify(param))
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          setconformationmodel(false);
          // setData(res.data.result);
          GetBenefits();
          setIsModalVisible("true");
          setMsg(res.data.message);
        } else {
          console.log("ads err ->", res.data);
          setIsModalVisible("true");
          setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const closemodal1 = () => {
    setIsModalVisible(false);
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setconformationmodel(true);
  };

  useEffect(() => {
    GetBenefits();
  }, []);

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* <div className="benefitsoffered_main_header_notifi">
          <div className="benefitsofferedmain1">
            <BackButton />
          </div>
          <div className="benefitsofferedmain2">
            <h1 className="benefitsoffered_h1">Requests</h1>
          </div>
        </div> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Requests</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        <br />
        {isLoading ? (
          <NewLoader />
        ) : (
          <div className="benefitsoffered_club_user">
            {getdata && getdata.length > 0 ? (
              getdata.map((item, index) => {
                return (
                  <div className="benefits_details_set">
                    <div style={{ zIndex: "111" }}>
                      <img
                        src={item.reward_img_video[0].img_video}
                        className="user_benefit_img"
                        alt=""
                      />
                      <h1 className="benefits_off">{item.bond_name}</h1>
                      {/* <p className="benefits_off_p">
                      Requested by -{" "}
                      {item.username}
                    </p> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: "5px",
                          margin: "5px auto",
                        }}
                        onClick={() => {
                          navigate("/UserProfile", {
                            state: {
                              user_id: item.user_id,
                            },
                          });
                        }}
                      >
                        <p className="benefits_off_p">Requested by - </p>
                        <img
                          src={item.user_profile}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "30px",
                          }}
                          alt=""
                        />
                        <p className="benefits_off_p">{item.username}</p>
                      </div>
                      <p className="benefits_off_p">
                        Requested on - {item.claim_time}
                      </p>

                      <p className="benefits_off_p">
                        Description - &nbsp;{item.description?.slice(0, 40)}{" "}
                        {item.description?.length > 60 && "..."}
                        {item.description?.length > 60 && (
                          <a
                            onClick={() => {
                              console.log("svsvsdav");
                              setmodal(true);
                              setMsg(item.description);
                              // setModalName("Asset Description");
                            }}
                            style={{
                              color: "var(--color-main)",
                              fontSize: "12px",
                              fontFamily: "var(--font-600)",
                              border: "none",
                              backgroundColor: "none",
                              marginBottom: "4px",
                              // zIndex: "111",
                            }}
                          >
                            Read More
                          </a>
                        )}
                      </p>
                    </div>

                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      {item.is_claim == 1 ? (
                        <button
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            alignSelf: "center",
                          }}
                          className="new_btn_wrapp_for_assetaproval mt-3"
                          // onClick={() => ClaimReward(item.reward_id)}
                          onClick={() => {
                            setreward_id(item.reward_id);
                            setconformationmodel(true);
                          }}
                        >
                          <p>Confirm</p>
                        </button>
                      ) : item.is_claim == 2 ? (
                        <button
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          className="new_btn_wrapp_for_assetaproval mt-3"
                        >
                          <p>Approved</p>
                        </button>
                      ) : item.is_claim == 3 ? (
                        <button
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          className="new_btn_wrapp_for_assetaproval mt-3"
                        >
                          <p>Given</p>
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="auction_nodata_text">
                There are currently no benefit requests from users.
              </p>
            )}
          </div>
        )}
      </div>
      {getmodel && (
        // <CusModal
        //   msg={msg}
        //   setShowModal={setIsModalVisible}
        //   showModal={isModalVisible}
        // />

        // <NewModal
        //   title=""
        //   msg={msg}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        // <V2Modal
        //   canclebtn={false}
        //   title=""
        //   msg={msg}
        //   setShowModal={setIsModalVisible}
        //   okfunction={closemodal}
        // />
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setmodal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            <div className="v2modal_fixed_text_wrapp">
              <p>{msg}</p>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  setmodal(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {getconformationmodel && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       {/* <b>Information</b> */}
        //       <br />
        //       <p className="modal-message">
        //         {" "}
        //         Are you sure you want to confirm benefit ?
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_two_buton">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setconformationmodel(false);
        //         }}
        //       >
        //         Cancel
        //       </button>
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           ClaimReward();
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setconformationmodel(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            <div className="v2modal_fixed_text_wrapp">
              <p> Are you sure you want to confirm benefit ?</p>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn1"
                onClick={() => {
                  setconformationmodel(false);
                }}
              >
                Cancel
              </button>
              <button className="v2modal_btn2" onClick={ClaimReward}>
                Ok
              </button>
            </div>
          </div>
        </div>

        // <ReactModal
        //   isOpen={setconformationmodel}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">
        //       {" "}
        //       Are you sure you want to confirm benefit ?
        //     </p>
        //     {/* </div> */}
        //     <div className="modal_two_buton" style={{ width: "100%" }}>
        //       <button className="close_forgot" onClick={() => ClaimReward()}>
        //         Okay
        //       </button>
        //       <button className="close_forgot" onClick={closemodal1}>
        //         Cancel
        //       </button>
        //     </div>
        //   </div>
        // </ReactModal>

        // <V2Modal
        //   canclebtn={true}
        //   title=""
        //   msg="Are you sure you want to confirm benefit ?"
        //   setShowModal={setIsModalVisible}
        //   okfunction={ClaimReward}
        // />
      )}
      <ToastContainer />
    </div>
  );
};

export default Benefitsoffered;
