import React from "react";
import "./NewLoader.css";
import images from "../../constants/images";

const NewLoader = () => {
  return (
    <div className="NewLoader_main_wrapp">
      <div className="NewLoader_glass_container">
        <img className="NewLoader_img" src={images.new_loader_gif} alt="" />
      </div>
    </div>
  );
};

export default NewLoader;
