import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  GET_USER_PROFILE_BEGIN,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_FOLLOW_BEGIN,
  GET_FOLLOW_SUCCESS,
  GET_FOLLOW_FAIL,
  GET_CHANGE_PROFILE_BEGIN,
  GET_CHANGE_PROFILE_SUCCESS,
  GET_CHANGE_PROFILE_FAIL,
  GET_EDIT_PROFILE_BEGIN,
  GET_EDIT_PROFILE_SUCCESS,
  GET_EDIT_PROFILE_FAIL,
} from "../Action";
import {
  get_user_profile,
  add_followers_following,
  change_profile,
  edit_profile,
} from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import profile_reducer from "../reducer/profile_reducer";

const initialState = {
  profile_loading: false,
  profile_data: {},
  edit_profile_data: {},
  follow_loading: false,
  follow_data: "",
  change_profile_loading: false,
  change_profile_data: "",
};

const ProfileContext = React.createContext();
export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(profile_reducer, initialState);

  const getProfile = async (params, url) => {
    dispatch({ type: GET_USER_PROFILE_BEGIN });
    try {
      const response = await axios.post(get_user_profile, params);
      const profiledata = response.data;
      // console.log("====", response.data);
      if (profiledata.status == 1) {
        dispatch({
          type: GET_USER_PROFILE_SUCCESS,
          payload: profiledata.result,
        });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_USER_PROFILE_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const changeProfile = async (params, url) => {
    dispatch({ type: GET_CHANGE_PROFILE_BEGIN });
    try {
      const response = await axios.post(change_profile, params);
      const profiledata = response.data;
      // console.log("====", response.data);
      if (profiledata.status == 1) {
        dispatch({ type: GET_CHANGE_PROFILE_SUCCESS, payload: profiledata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_CHANGE_PROFILE_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const setFollow = async (params, url) => {
    dispatch({ type: GET_FOLLOW_BEGIN });
    try {
      const response = await axios.post(add_followers_following, params);
      const followdata = response.data;
      // console.log("====", response.data);
      if (followdata.status == 1) {
        dispatch({ type: GET_FOLLOW_SUCCESS, payload: followdata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_FOLLOW_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const EditProfile = async (params, url) => {
    dispatch({ type: GET_EDIT_PROFILE_BEGIN });
    try {
      const response = await axios.post(edit_profile, params);
      const profiledata = response.data;
      // console.log("====", response.data);
      if (profiledata.status == 1) {
        dispatch({ type: GET_EDIT_PROFILE_SUCCESS, payload: profiledata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_EDIT_PROFILE_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        ...state,
        getProfile,
        setFollow,
        changeProfile,
        EditProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
