import React from "react";
import "./NewShowUserDetail.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const NewShowUserDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const res = location.state.all_data;
  const [fullname, setFullName] = useState(res.full_name);
  const [email, setEmail] = useState(res.email);
  const [dob, setDob] = useState(res.date_of_birth);
  const [address, setAddress] = useState(res.address);
  // const [gettermModel, setTermModel] = useState(false);
  const [gettermAccept, setTermAccept] = useState(false);

  useEffect(() => {
    console.log("location", location.state);
  }, []);

  return (
    <div className="new_wrapp_container relative_position">
      <div className="new_container">
        <div className="set_otp_main_wrapp">
          <div className="otp_screen_text_container" style={{ margin: "0px" }}>
            <h4 style={{ margin: "0px" }}>Confirm your Identity</h4>
            <p>
              Your card details remain confidential. This helps us to process
              further.{" "}
            </p>
          </div>

          <div className="new_show_user_single_input_wrapp">
            <div className="new_set_password_single_labal_wrapp">
              <p style={{ fontSize: "14px" }}>Full Name</p>
            </div>
            <div className="new_set_password_single_border_wrapp">
              <input
                type="text"
                placeholder="Enter Full Name"
                style={{ padding: "8px 0px", width: "100%" }}
                disabled
                value={fullname}
              />
            </div>
          </div>

          <div className="new_show_user_single_input_wrapp">
            <div className="new_set_password_single_labal_wrapp">
              <p style={{ fontSize: "14px" }}>Email Address</p>
            </div>
            <div className="new_set_password_single_border_wrapp">
              <input
                type="text"
                placeholder="Enter Email Address"
                style={{ padding: "8px 0px", width: "100%" }}
                disabled
                value={email}
              />
            </div>
          </div>

          <div className="new_show_user_single_input_wrapp">
            <div className="new_set_password_single_labal_wrapp">
              <p style={{ fontSize: "14px" }}>Date of Birth</p>
            </div>
            <div className="new_set_password_single_border_wrapp">
              <input
                type="text"
                placeholder="DD - MM - YYYY"
                style={{ padding: "8px 0px", width: "100%" }}
                disabled
                value={dob}
              />
            </div>
          </div>

          <div className="new_show_user_single_input_wrapp">
            <div className="new_set_password_single_labal_wrapp">
              <p style={{ fontSize: "14px" }}>Address</p>
            </div>
            <div className="new_set_password_single_border_wrapp">
              <input
                type="text"
                placeholder="Enter your address"
                style={{ padding: "8px 0px", width: "100%" }}
                disabled
                value={address}
              />
            </div>
          </div>

          <div className="new_aadhaar_btn_wrapp">
            <div>
              {/* <img src={images.new_accepted_terms} alt="" /> */}
              {gettermAccept ? (
                <img
                  src={images.new_accepted_terms}
                  onClick={() => setTermAccept(false)}
                  alt=""
                />
              ) : (
                <img
                  src={images.new_notaccepted_terms}
                  alt=""
                  onClick={() => setTermAccept(true)}
                />
              )}
              <p>I confirm the above details are correct.</p>
            </div>
            {/* btn */}
            <button
              onClick={() => {
                navigate("/NewPancardScreen", {
                  state: {
                    user_id: location.state.user_id,
                  },
                });
              }}
              className="new_btn_wrapp mx-1"
              disabled={!gettermAccept}
            >
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewShowUserDetail;
