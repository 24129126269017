import React, { useCallback, useEffect, useRef } from "react";
import "./neweditprofile.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { check_is_user, edit_profile, user_update } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FaAngleLeft } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";

const NewEditProfile = () => {
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [profile, setProfile] = useState("");
  const [getcropmodal, setCropmodal] = useState(false);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const cropperRef = useRef(null);
  const [username, setUsername] = useState("");
  const [usernamecheck, setUsernamecheck] = useState(null);
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [user_story, setUserStory] = useState("");
  const [user_social_media_link, setuser_social_media_link] = useState([]);
  const [getDeleteSocialId, setDeleteSocialId] = useState("");
  const [selectedCoverpreview, setSelectedCoverpreview] = useState(null);
  const videoRef = useRef(null);
  const [user_video_upload, setuser_video_upload] = useState(0);
  const [showModal1, setShowModal1] = useState(false);
  const [name, setName] = useState("");

  const recorderRef = useRef(null);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // setImageFiles(e.target.files[0]);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };
  const handleImageDelete = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
    // setconditionauthor(true);
  };
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedDataUrl = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(croppedDataUrl);

      // Convert the data URL to a Blob object
      const blob = dataURItoBlob(croppedDataUrl);

      // Create a FormData object and append the blob along with other necessary data
      const formData = new FormData();
      formData.append("id", userId);
      formData.append("profile", blob);

      // Send the cropped image data to your API
      axios
        .post("https://www.ravi.host/api/change_profile", formData)
        .then((response) => {
          setImage("");

          console.log("Success", response.data.status);
          if (response.data.status === 1) {
            toast.success(response.data.message, {
              theme: "colored",
            });
            // setErrorMessage(response.data.message);
            // setShowModal(true);
            setCropmodal(false);
            _getdata();
          } else {
            toast.error(response.data.message, {
              theme: "colored",
            });
            // setErrorMessage(response.data.message);
            // setShowModal(true);
            setCropmodal(false);
          }
        })
        .catch((error) => {
          console.log("Error uploading cropped image:", error);
          setCropmodal(false);
        });
    }
  };
  const [selectedCover, setSelectedCover] = useState([]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleImagepreview = (e) => {
    const files = e.target.files;
    setSelectedCover(files[0]);
    setSelectedCoverpreview(URL.createObjectURL(files[0]));
    console.log("files 11", files);
  };

  useEffect(() => {
    _getdata();
  }, []);
  const userId = localStorage.getItem("user_id");
  const [getvideoArray, setVideoArray] = useState([]);
  const [loading, setloading] = useState(false);

  const _getdata = () => {
    setloading(true);

    const param = {
      user_id: userId,
    };

    axios.post(edit_profile, param).then((Response) => {
      setloading(false);
      console.log("----", Response.data.result);
      if (Response.data.status === 1) {
        const result = Response.data.result;
        setName(result.firstname);
        setVideoArray(result.user_videos);
        setProfile(result.profile);
        setUsername(result.username);
        setNumber(result.phone);
        setEmail(result.email);
        setUserStory(result.user_story);
        setBio(result.bio);
        setuser_social_media_link(result.user_social_media_link);
        setSelectedImages([result.user_cover_photo]);
        setSelectedCoverpreview(result.user_cover_photo);
        setuser_video_upload(result.user_video_upload);
      } else {
        toast.error(Response.data.message, {
          theme: "colored",
        });
        // setIsModalVisible(true);
        // setCusMsg(Response.data.message);
      }
    });
  };
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const [inputValues, setInputValues] = useState([""]);

  const validateSocialMediaLink = (link) => {
    // Validation logic for Instagram
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }
    if (link.startsWith("Https://") || link.startsWith("Http://")) {
      return true;
    }
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for YouTube
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for LinkedIn
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    // Validation logic for Twitter
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return true;
    }

    return false; // If none of the above conditions match, consider it an invalid link
  };
  const handleInputChange = (text, index) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = text;
    setInputValues(updatedInputValues);
  };

  const handleAddValue = () => {
    const isValid = inputValues.every(validateSocialMediaLink);
    if (isValid) {
      setInputValues([...inputValues, ""]); // Add a new empty input field
    } else {
      // Handle invalid input
      toast.error("Please enter valid social media links", {
        theme: "colored",
      });
    }
  };

  const handleRemoveValue = () => {
    if (inputValues.length > 1) {
      const updatedInputValues = [...inputValues];
      updatedInputValues.pop(); // Remove the last input field
      setInputValues(updatedInputValues);
    }
  };

  // creator video start
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(60);
  const [getuserVid2, setUserVideo2] = useState("");
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideo, setVideo] = useState("");
  const [getthumbImg, setthumbImg] = useState("");
  const [getconditionvideo, setconditionvideo] = useState(false);

  // creator video end

  // stop recording function start
  const StopRecording = () => {
    setthumbImg("");
    setRecording(false);
    setRecordingstatus(false);
    const minDuration = 30;
    const maxDuration = 60;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (60 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          toast.error(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
            { theme: "colored" }
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(60);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };
  // stop recording function end

  // start recording started
  const startRecording = () => {
    setthumbImg("");
    setRecordingstatus(true);
    setRecordingTime(60);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };
  useEffect(() => {
    let timerInterval;
    const minDuration = 30;

    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;

          // Check if the recording time is less than the minimum duration
          if (newTime < minDuration) {
            // Display an error or take other actions
            console.error(
              `Recording time should be at least ${minDuration} seconds.`
            );
          }

          // Check if the recording time reaches 1 second
          if (newTime <= 2) {
            StopRecording(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(60);
  };
  // start recording end

  // creator video functionality start
  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 20; // Minimum duration in seconds
    const maxDuration = 60; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        toast.error(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`,
          { theme: "colored" }
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...files]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  const handleVideoDelete = () => {
    // const newVideos = [...selectedVideos];
    // newVideos.splice(index, 1);

    setSelectedVideos([]);
    // setSelectedVideos(newVideos);
    setRecordedChunks([]);
    setPreviewVideoModal2(false);
    setconditionvideo(true);
  };

  const showimage = () => {
    StopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const deletedata = () => {
    const param = {
      user_id: userId,
      user_social_media_link_id: getDeleteSocialId,
    };
    console.log(param);
    axios
      .post(
        "https://www.ravi.host/api/delete_user_social_media_link",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.status == 1) {
          console.log("====================================");
          console.log("res", Response.data);
          console.log("====================================");
          toast.success(Response.data.message, {
            theme: "colored",
          });
          setShowModal1(false);
          _getdata();
        } else {
          toast.error(Response.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("dzv sd", err);
      });
  };
  const updateprofile = () => {
    if (name == "") {
      toast.error("Enter name", {
        theme: "colored",
      });
    } else if (username == "" || username == null) {
      toast.error("Enter display name", {
        theme: "colored",
      });
    } else if (usernamecheck == 0) {
      toast.error("Please Enter valid Display Name", {
        theme: "colored",
      });
    }
    // else if (bio == "") {
    //   setErrorMessage("Enter bio");
    //   setShowModal(true);
    // }
    else {
      setloading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("username", username);
      formData.append("firstname", name);
      formData.append("bio", bio);
      formData.append("user_story", user_story);
      formData.append("user_cover_photo", selectedCover);
      selectedVideos.forEach((image, index) => {
        formData.append("user_videos", image);
      });
      let linktag = "";
      var demolinktag = 0;
      for (let index = 0; index < inputValues.length; index++) {
        if (inputValues[index]) {
          demolinktag = demolinktag + 1;

          if (index > 0) {
            linktag += ",";
          }
          linktag += inputValues[index];
        }
      }
      formData.append("user_social_media_link_data", linktag);
      axios.post(user_update, formData).then((Response) => {
        setloading(false);
        console.log("----", JSON.stringify(Response.data.result, null, 2));
        if (Response.data.status === 1) {
          // setErrorMessage(Response.data.message);
          // setShowModal(true);
          _getdata();
          navigate(-1);
        } else {
          toast.error(Response.data.message, {
            theme: "colored",
          });
        }
      });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent entering space
    }
  };
  return (
    <div className="new_wrapp_container reletive_position">
      {loading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Edit Profile</p>
              <div></div>
            </div>

            {/* image start */}

            <div className="ep_image_main_wrapp">
              {profile ? (
                <img
                  onClick={() => {
                    navigate("/ProductViewImg2", {
                      state: {
                        img: profile ? profile : "",
                      },
                    });
                  }}
                  src={profile}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                />
              ) : (
                <img
                  src={images.Person_white}
                  className="ep_image_logo"
                  alt=""
                />
              )}
              <button
                onClick={() => {
                  setCropmodal(true);
                }}
                className="ep_image_edit_btn"
              >
                <img src={images.edit_profile_pen} alt="" />
              </button>
            </div>

            {/* image end */}

            {/* first name start */}

            <p className="ep_genral_txt mb-2">General Information</p>
            {/* <div className="ep_main_wrapp_first_last">
              <div className="ep_main_wrapp_txt_first">
                <input
                  placeholder="First Name"
                  className="ep_text_input_first_last"
                />
                <div className="ep_absolue_txt">
                  <p className="ep_position_two_p">First Name</p>
                </div>
              </div>
              <div className="ep_main_wrapp_txt_first">
                <input
                  placeholder="Second Name"
                  className="ep_text_input_first_last"
                />
                <div className="ep_absolue_txt_secound">
                  <p className="ep_position_two_p">Last Name</p>
                </div>
              </div>
            </div> */}
            <div className="ep_main_wrapp_txt_username">
              <input
                readOnly
                value={name == null || name == "null" ? "" : name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Full Name"
                className="ep_text_input_username"
              />
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">Full Name</p>
              </div>
            </div>
            {/* first name end */}
            {/* username start */}
            <div className="ep_main_wrapp_txt_username">
              <input
                value={username == null || username == "null" ? "" : username}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onBlur={() => {
                  if (username == "" || username == null) {
                    setUsernamecheck(null);
                  }
                  if (username != "") {
                    setloading(true);
                    const param = {
                      username: username,
                    };
                    console.log("param", param);
                    axios
                      .post(check_is_user, qs.stringify(param))
                      .then((Response) => {
                        setloading(false);
                        console.log("param", Response.data);

                        if (Response.data.status === 0) {
                          setUsernamecheck(0);
                        } else {
                          setUsernamecheck(1);
                        }
                      })
                      .catch((err) => {
                        console.log("check_is_user err", err);
                      });
                  }
                }}
                placeholder="Display Name"
                className="ep_text_input_username"
              />
              {usernamecheck == null ? null : (
                <div>
                  <img
                    className="ep_mobile_edit_img"
                    src={
                      usernamecheck === 0
                        ? images.close_red
                        : images.right_user_check
                    }
                    alt=""
                  />
                </div>
              )}
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">Display Name</p>
              </div>
            </div>
            {/* username end */}

            {/* mobile number strat */}

            <div className="ep_main_wrapp_txt_username">
              <div className="ep_mobile_flex_wrapp">
                <p
                  style={{
                    color: "#ffff",
                    fontSize: "14px",
                    fontWeight: "350",
                    fontFamily: "var(--font-600)",
                    marginLeft: "18px",
                  }}
                >
                  +91
                </p>
                <div
                  style={{
                    width: "2px",
                    background: "rgba(255, 255, 255, 1)",
                    height: "20px",
                    borderRadius: "1px",
                    marginLeft: "10px",
                  }}
                ></div>
                <input
                  value={number == "null" || number == null ? "" : number}
                  readOnly
                  placeholder="Mobile No"
                  className="ep_text_input_username"
                />
              </div>
              <button
                onClick={() => {
                  navigate("/editmobile", {
                    state: {
                      number: number,
                    },
                  });
                }}
              >
                <img
                  className="ep_mobile_edit_img"
                  src={images.edit_pen}
                  alt=""
                />
              </button>
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">Mobile No</p>
              </div>
            </div>

            {/* mobile number end */}
            {/* email id start */}
            <div className="ep_main_wrapp_txt_username">
              <input
                value={email == null || email == "null" ? "" : email}
                readOnly
                placeholder="Email Id"
                className="ep_text_input_username"
              />

              <button
                onClick={() => {
                  navigate("/editemail", {
                    state: {
                      email: email,
                    },
                  });
                }}
              >
                <img
                  className="ep_mobile_edit_img"
                  src={images.edit_pen}
                  alt=""
                />
              </button>
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">Email Id</p>
              </div>
            </div>
            {/* email id end */}
            {/* bio start */}
            <div style={{ height: "150px" }} className="ep_main_wrapp_txt_bio">
              <button>
                <img
                  className="ep_mobile_edit_img"
                  src={images.edit_pen}
                  alt=""
                />
              </button>
              <textarea
                value={bio == "null" || bio == null ? "" : bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
                placeholder="My Bio"
                className="ep_text_input_bio"
              />
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">My Bio</p>
              </div>
            </div>
            {/* bio end */}
            {/* about start */}
            <div style={{ height: "150px" }} className="ep_main_wrapp_txt_bio">
              <button>
                <img
                  className="ep_mobile_edit_img"
                  src={images.edit_pen}
                  alt=""
                />
              </button>
              <textarea
                value={
                  user_story == "null" || user_story == null ? "" : user_story
                }
                onChange={(e) => {
                  setUserStory(e.target.value);
                }}
                placeholder="About Me"
                className="ep_text_input_bio"
              />
              <div className="ep_absolue_txt_username">
                <p className="ep_position_two_p">About Me</p>
              </div>
            </div>
            {/* about end */}
            {/* media link start */}
            <p className="ep_genral_txt mb-2">Add Website & Media Link</p>
            {inputValues.map((value, index) => (
              <div
                key={index}
                style={{ marginTop: "5px" }}
                className="ep_main_wrapp_txt_username"
              >
                <input
                  placeholder="Enter here"
                  className="ep_text_input_username"
                  placeholderTextColor="gray"
                  value={value}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                />
                {!value ? (
                  <button>
                    <img
                      className="ep_mobile_edit_img"
                      src={images.new_link_icon}
                      alt=""
                    />
                  </button>
                ) : (
                  <div onClick={handleRemoveValue}>
                    <img
                      src={images.side_new_close}
                      style={{
                        width: "20px",
                        height: "20px",
                        objectFit: "contain",
                        marginRight: "10px",
                      }}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
            <button
              onClick={() => handleAddValue()}
              className="new_btn_wrapp"
              style={{
                height: "30px",
                width: "100px",
                marginTop: "10px",
                alignSelf: "flex-start",
              }}
            >
              <p style={{ fontSize: "10px" }}>Add More</p>
            </button>
            {/* media link end */}
            {/* uploded media link start */}
            {user_social_media_link &&
            user_social_media_link.length === 0 ? null : (
              <p className="ep_genral_txt mb-2">
                Uploaded Website & Media Links
              </p>
            )}
            {user_social_media_link &&
              user_social_media_link.map((item, index) => {
                return (
                  <div className="ep_uploded_link_wrapp">
                    <p className="ep_uploded_link_div_one">
                      {index + 1}. {item.user_social_media_link_data}
                    </p>
                    <button
                      onClick={() => {
                        setDeleteSocialId(item.user_social_media_link_id);
                        setShowModal1(true);
                      }}
                      className="ep_uploded_link_div_two"
                    >
                      <img
                        className="uploaded_image_delete_btn"
                        src={images.new_delete}
                        alt=""
                      />
                    </button>
                  </div>
                );
              })}

            {/* uploded media link end */}
            {/* cover start */}
            <p className="ep_genral_txt mb-2">Cover</p>
            <div
              style={{ marginTop: "0px" }}
              className="new_creator_video_wrapp"
            >
              {selectedCoverpreview ==
                "https://www.ravi.host/public/assets/user_cover_photo/" ||
              selectedCoverpreview == null ||
              selectedCoverpreview == "" ? (
                <>
                  <label
                    htmlFor="file-input"
                    className="new_creator_video_upload_button"
                  >
                    <img src={images.new_upload_logo} alt="" />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={handleImagepreview}
                  />
                </>
              ) : (
                <>
                  <div
                    className="new_min1_img_preview_single"
                    style={{
                      gridColumn: "span 3",
                      height: "160px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "94%",
                        marginTop: "5px",
                      }}
                      src={selectedCoverpreview}
                      alt={`Preview`}
                    />

                    <button
                      style={{ marginTop: "10px", marginRight: "5px" }}
                      className="delete-button"
                      onClick={() => {
                        setSelectedCoverpreview("");
                      }}
                    >
                      <img src={images.close_red} alt="" />
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* cover end */}

            {/* creator video start */}
            {user_video_upload !== 1 && selectedVideos.length == 0 ? (
              <>
                <p style={{ marginTop: "0px" }} className="ep_genral_txt mb-2">
                  Record Authentication video
                </p>
                <div
                  style={{ marginTop: "0px" }}
                  className="new_creator_video_wrapp"
                >
                  {selectedVideos && selectedVideos.length <= 0 ? (
                    <>
                      <label
                        onClick={() => {
                          setIsModalVisiblevideo(true);
                          setRecordedChunks([]);
                          setrecordedvideo(false);
                        }}
                        htmlFor="creator_vid"
                        // className="upload-button"
                        className="new_creator_video_upload_button"
                      >
                        <img src={images.new_upload_logo} alt="" />
                      </label>
                      <input
                        disabled
                        id="creator_vid"
                        type="file"
                        accept="video/*"
                        // multiple
                        capture="camera"
                        onChange={(e) => {
                          handleVideoChange(e);
                          // setIsModalVisiblevideo(true);
                        }}
                      />
                    </>
                  ) : (
                    <div
                      className="image-previews"
                      style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {selectedVideos.map((image, index) => (
                        <div
                          className="image-preview"
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            width: "100%",
                            height: "100%",
                            background: "#000",
                          }}
                        >
                          {/* <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                /> */}
                          <button
                            onClick={() => {
                              setPreviewVideoModal2(true);
                              setUserVideo2(URL.createObjectURL(image));
                            }}
                            // disabled={gettab3Completed}
                            style={{
                              color: "var(--color-main)",
                              fontSize: "12px",
                              fontFamily: "var(--font-600)",
                              cursor: "default",
                              marginBottom: "25px",
                            }}
                          >
                            {/* Preview */}
                            {/* {getthumbImg != "" ? ( */}
                            <div
                              onClick={() => {
                                setPreviewVideoModal2(true);
                                setUserVideo2(URL.createObjectURL(image));
                              }}
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                              }}
                            >
                              {/* <img
                                      src={images.play_white}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        objectFit: "contain",
                                        margin: "0px",
                                        position: "absolute",
                                        transform: "translate(-50%, -50%)",
                                        left: "50%",
                                        top: "50%",
                                        zIndex: 11,
                                        cursor: "pointer",
                                      }}
                                      alt=""
                                     
                                    /> */}
                            </div>
                            {/* ) : ( */}
                            Preview
                            {/* )} */}
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => handleVideoDelete(index)}
                            style={{ right: "14px" }}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : null}
            {/* creator video end */}

            {/* record authentication video start */}
            <div style={{ width: "100%" }} className="mynfb_viewall_btn_wrapp">
              <p>Uploaded Authentication Video</p>
              <button
                onClick={() => {
                  navigate("/NewUserRecordedVideo", {
                    state: {
                      getvideoArray: getvideoArray,
                    },
                  });
                }}
                className="new_btn_wrapp"
                style={{
                  color: "var(--color-white)",
                  opacity: 0.6,
                  height: "30px",
                  width: "90px",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  View all
                </p>
              </button>
            </div>
            <div className="ep_record_authentication_video_wrapp_2 mb-2">
              {getvideoArray && getvideoArray.length > 0
                ? getvideoArray.slice(0, 2).map((item, index) => {
                    return (
                      <button
                        className="ep_record_authentication_video_div"
                        style={{ height: "150px", width: "48%" }}
                      >
                        {item.user_thumbnail != "" ? (
                          <div
                            className="ep_record_authentication_thumb_div"
                            style={{ height: "150px" }}
                          >
                            <img
                              className="ep_record_authentication_thumb"
                              src={item.user_thumbnail}
                              style={{ height: "150px" }}
                            />
                            <div
                              onClick={() => {
                                setPreviewVideoModal2(true);
                                setUserVideo2(item.user_videos);
                              }}
                              className="ep_play_thumb_div"
                            >
                              <img
                                className="ep_video_play_btn"
                                src={images.d_play}
                              />
                            </div>
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              setPreviewVideoModal2(true);
                              setUserVideo2(item.user_videos);
                            }}
                            className="ep_preview_btn_main_wrapp"
                          >
                            <p className="ep_p_tag_preview">Preview</p>
                          </button>
                          // "Preview"
                        )}
                      </button>
                    );
                  })
                : null}
            </div>
            {/* record authentication video end */}
            {/* view managar btn start */}
            <button
              onClick={() => navigate("/viewmanagers")}
              className="view_managar_btn_wrapp"
              //   style={{ backgroundColor: "#000" }}
              style={{
                // position: "fixed",
                bottom: "10%",
                width: "100%",
                //   left: "10%",
                transform: "translate(0%, 50%)",
                background: "#000",
                maxWidth: "var(--max-width-sm)",
                marginTop: "20px",
              }}
            >
              <p className="ep_p_tag_preview">View Managar</p>
            </button>
            {/* view managar btn end */}
            {/* update profile btn start */}
            <button
              onClick={() => updateprofile()}
              className="new_btn_wrapp"
              style={{
                bottom: "10%",
                width: "100%",
                //   left: "10%",
                transform: "translate(0%, 50%)",
                background: "#000",
                maxWidth: "var(--max-width-sm)",
                marginTop: "20px",
                marginBottom: "50px",
              }}
            >
              <p className="ep_p_tag_preview">Update Profile</p>
            </button>
            {/* update profile btn end */}
          </div>
        </div>
      )}
      <ToastContainer />
      {/* crop modal start */}
      {getcropmodal && (
        <div className="crop_modal_main_wrapp">
          <div
            // className="crop_modal_base_wrapp"
            style={{
              background: "#000",
              width: "100%",
              maxHeight: "75vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                maxWidth: "90%",
                margin: "10px auto",
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                {image == "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="imageInput"
                      style={{ marginBottom: "10px" }}
                    >
                      Upload Image:
                    </label>
                    <div
                      className="image-upload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="file-input" className="upload-button">
                        <img src={images.gallery_icon} alt="" />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        // capture="camera"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <br />
                    <br />
                    <Cropper
                      ref={cropperRef}
                      style={{ height: 250, width: "100%" }}
                      zoomTo={0.5}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={200}
                      minCropBoxWidth={200}
                      maxWidth={200}
                      maxHeight={200}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      guides={true}
                      aspectRatio={4 / 4}
                    />
                  </>
                )}
              </div>
              {image !== "" && (
                <div>
                  <div
                    className="box"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <h1>Preview</h1>
                    <div
                      className="img-preview"
                      style={{
                        width: "100%",
                        height: "150px",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="crop_img_yes_no_btn_wrapp"
            style={{ justifyContent: image == "" ? "center" : "space-between" }}
          >
            <button
              onClick={() => {
                setCropmodal(false);
                setImage("");
                console.log("getcropmodal", getcropmodal);
              }}
            >
              <IoCloseSharp color="#fff" size={28} />
            </button>
            {image !== "" && (
              <button onClick={getCropData}>
                <IoCheckmarkSharp color="#fff" size={28} />
              </button>
            )}
          </div>
        </div>
      )}
      {/* video modal start */}
      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={StopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  showimage();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* video modal end */}

      {/* preview video modal start */}
      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  {/* <img
                    src={images.back}
                    className="nav_back_btn"
                    alt=""
                    onClick={() => setPreviewVideoModal2(false)}
                  /> */}
                  <div
                    onClick={() => {
                      setPreviewVideoModal2(false);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </div>
                </div>
                <p className="nav_screen_name">Video</p>
              </div>
              <video
                controls
                style={{ width: "100%", height: "90%" }}
                poster={getthumbImg !== "" ? getthumbImg : ""}
              >
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
      {/* preview video modal end */}
      {showModal1 && (
        <>
          <div className="v2modal_main_wrapp">
            <div className="v2modal_inner_wrapp">
              <div className="v2modal_fixed_logo_wrapp">
                <img src={images.logo} alt="" />
              </div>
              <button
                className="v2modal_close_img"
                onClick={() => {
                  setShowModal1(false);
                }}
              >
                <img
                  src={images.new_close_logo}
                  className="v2modal_close_img"
                  alt=""
                />
              </button>
              <div className="v2modal_fixed_text_wrapp">
                <p>{"Are you sure went to remove ?"}</p>
              </div>

              <div className="v2modal_fixed_btns_wrapp">
                {/* {canclebtn && ( */}
                <button
                  className="v2modal_btn1"
                  onClick={() => {
                    setShowModal1(false);
                  }}
                >
                  Cancel
                </button>
                {/* )} */}
                <button
                  className="v2modal_btn2"
                  onClick={() => {
                    deletedata();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewEditProfile;
