import React from "react";
import "./NewInternationalInvite.css";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const NewInternationalInvite = () => {
  const navigate = useNavigate();
  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      <div className="new_container">
        <div className="back_arrow_wrapp">
          <button
            onClick={() => {
              navigate("/NewSideMenu");
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
        </div>
        <div className="new_intanationa_invite_main_wrapp">
          <img src={images.logo} alt="" />
          <h2>INVITE</h2>
          <p>Invite International users</p>
          {/* btn */}
          <Link to="/NewInternationalInviteForm" className="new_btn_wrapp mt-3">
            <p>Send Invite</p>
          </Link>
          {/* btn */}
        </div>
      </div>
    </div>
  );
};

export default NewInternationalInvite;
