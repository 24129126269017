import React, { useState, useEffect, useRef } from "react";
import "./cardsecond.css";
import "./showcardsecond.css";
import { useSwipeable } from "react-swipeable";
import { useHomeContext } from "../../context/home_context";
import axios from "axios";
import qs from "qs";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import { useScreenshot } from "use-react-screenshot";
import * as htmlToImage from "html-to-image";
import { useNavigate } from "react-router-dom";
import images from "../../constants/images";

const Showcardsecond = ({
  msg,
  setShowModal,
  showModal,
  data,
  setIsModalOpen,
  setMsg,
  setModalName,
  icon,
  setislikes,
  onBondLikeSuccess,
  location,
  // user_Id,
}) => {
  const userId = localStorage.getItem("user_id");
  const [isFlipped, setIsFlipped] = useState(false);
  const { Mycollectionlike, collection_data, collection_loading } =
    useHomeContext();
  const handleSwipe = (eventData) => {
    const numFlips = 155; // Number of times to flip the card
    let flipped = isFlipped;

    for (let i = 0; i < numFlips; i++) {
      flipped = !flipped;
      setIsFlipped(flipped);
    }
    if (eventData.dir === "Left") {
      setIsFlipped(false); // Flip to the front side
    } else if (eventData.dir === "Right") {
      setIsFlipped(true); // Flip to the back side
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  const daata = () => {
    const params = {
      user_id: userId,
    };
    Mycollectionlike(params);
  };

  const Bond_like = () => {
    const param = {
      user_id: userId,
      bond_id: data.product_id,
      is_nfb: data.is_nfb,
      is_club: data.is_club,
      uniq_code: data.uniq_code,
      time: data.time,
      release_bond_id: data.release_bond_id,
      is_nfb_type: data.is_nfb_type,
    };
    console.log("====================================");
    console.log("param---->", param);
    console.log("====================================");
    axios
      .post(
        "https://www.ravi.host/api/user_own_bond_like_unlike",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("Response", Response.data);
        if (Response.data.status == 1) {
          window.location.reload();
          // setislikes(1);
          onBondLikeSuccess();
          // daata();
          // setSetIndex(setindex);
        } else {
          window.location.reload();
          onBondLikeSuccess();
          // setislikes(0);
          // daata();
        }
      });
  };

  // const captureDivAsImage = () => {
  //   const divToCapture = document.getElementById("divToCapture"); // Replace with the ID or reference to your specific div
  //   if (!divToCapture) {
  //     console.error("Div not found");
  //     return;
  //   }

  //   html2canvas(divToCapture).then((canvas) => {
  //     // Convert the canvas to a data URL
  //     const imgData = canvas.toDataURL("image/png");

  //     // Create a link element to download the image
  //     const a = document.createElement("a");
  //     a.href = imgData;
  //     a.download = "captured_image.png"; // You can specify a custom filename
  //     a.click();
  //   });
  // };

  const captureDivAsImage = () => {
    const divToCapture = document.getElementById("divToCapture");
    if (!divToCapture) {
      console.error("Div not found");
      return;
    }

    domtoimage
      .toPng(divToCapture)
      .then(function (dataUrl) {
        // Create a link element to download the image
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = "captured_image.png"; // You can specify a custom filename
        a.click();
      })
      .catch(function (error) {
        console.error("Capture failed:", error);
      });
  };

  const captureDivAsImage1 = () => {
    const divToCapture = divToCaptureRef.current;
    if (!divToCapture) {
      console.error("Div not found");
      return;
    }

    // Preload images
    const imagesToPreload = [
      data.qr_code, // Add other image URLs you want to preload here
    ];

    const preloadImages = (urls) => {
      return Promise.all(
        urls.map((url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          });
        })
      );
    };

    // Capture the div as an image
    html2canvas(divToCapture).then((canvas) => {
      // Preload images before downloading
      preloadImages(imagesToPreload).then(() => {
        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL("image/png");

        // Create a link element to download the image
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "captured_image.png"; // You can specify a custom filename
        a.click();
      });
    });
  };

  const divToCaptureRef = useRef(null);
  const canvasRef = useRef(null);
  const { takeScreenshot } = useScreenshot();

  const captureImage = () => {
    const divToCapture = document.getElementById("divToCapture1");
    if (!divToCapture) {
      console.error("Div not found");
      return;
    }

    // Use html2canvas to capture the div as an image
    html2canvas(divToCapture).then((canvas) => {
      // Convert the canvas to a Blob
      canvas.toBlob((blob) => {
        // Create a link element to download the image
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "captured_image.png"; // You can specify a custom filename
        a.click();
      }, "image/png");
    });
  };

  // const captureImage = () => {
  //   const divToCapture = divToCaptureRef.current;
  //   if (!divToCapture) {
  //     console.error("Div not found");
  //     return;
  //   }

  //   // Use html2canvas to capture the div as an image
  //   html2canvas(divToCapture).then((canvas) => {
  //     // Convert the canvas to a data URL
  //     const dataURL = canvas.toDataURL("image/png");

  //     // Create a link element to download the image
  //     const a = document.createElement("a");
  //     a.href = dataURL;
  //     a.download = "captured_image.png"; // You can specify a custom filename
  //     a.click();
  //   });
  // };

  const navigate = useNavigate();

  const copyUrlToClipboard = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard.writeText(data.qr_string).then(
      () => {
        // The URL has been copied successfully
        console.log("URL copied to clipboard:", data.qr_string);
      },
      (err) => {
        // Unable to copy URL
        console.error("Failed to copy URL:", err);
      }
    );
  };

  const handleShare = () => {
    const urlToShare = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: "Share Profile",
          url: data.qr_string,
        })
        .then(
          () => {
            // The link has been shared successfully
            console.log("Link shared successfully:", data.qr_string);
          },
          (err) => {
            // Sharing failed
            console.error("Failed to share link:", err);
          }
        );
    } else {
      // Fallback to copy URL to clipboard if the Web Share API is not supported
      copyUrlToClipboard();
    }
  };

  return (
    <div className="centered">
      <div
        className={`flip-card ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        <div className="flip-card-inner_card">
          <div className="card_flip-card-front">
            <div ref={divToCaptureRef} className="crad__show2_main_img_front">
              <img src={icon} className="card_1_img_front" alt="" />
              <div
                className="show_1_details_front"
                onClick={() => {
                  navigate("/ProductView2", {
                    state: {
                      uniq_code: data.uniq_code,
                      is_nfb: data.is_nfb,
                      product_id: data.product_id,
                      release_bond_id: data.release_bond_id,
                      theme_index: data.theme_index,
                      accept_bond_bid_id: data.accept_bond_bid_id,
                      share_by_user: data.share_by_user,
                    },
                  });
                }}
              >
                <div className="front_card_sub_details">
                  <p className="front_nm">{data.product_name}</p>
                  <p className="front_nm_2">Creator</p>
                  <p className="front_nm_3">{data.owner_name}</p>
                </div>
                <div className="front_card_sub_details2">
                  <div className="front_card_sub_details_inner">
                    <div className="div_card_1">
                      <p className="front_nm_product">Asset</p>
                      <p className="front_nm_product">value</p>
                      <p className="front_nm_product1">₹{data.product_value}</p>
                    </div>
                    <div className="div_card_2">
                      <img
                        src={images.logo_white}
                        className="img_front_card_1_logo"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              {location && location.user_id == userId && (
                <div className="btn_showcardsecond">
                  <div className="btn_showcardsecond_sub1">
                    {data.is_nfb_type == 0 ? (
                      <button
                        onClick={() => {
                          navigate("/resellcreateproduct", {
                            state: {
                              accept_bond_bid_id: data.accept_bond_bid_id,
                              is_nfb: data.is_nfb,
                              resale_product_id: data.resale_product_id,
                              bond_id: data.bond_id,
                            },
                          });
                        }}
                        className="show_second_btn"
                      >
                        SELL
                      </button>
                    ) : null}
                    {/* <button onClick={captureImage} className="show_second_btn">
                      SAVE
                    </button> */}
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                    <a
                      // href={data.qr_string}
                      // target="_blank"
                      className="show_second_btn"
                      onClick={() => {
                        handleShare();
                      }}
                    >
                      SHARE
                    </a>
                  </div>
                  <div className="btn_showcardsecond_sub2">
                    <img
                      onClick={() => Bond_like()}
                      // src={
                      //   data.is_nfb_type == 0
                      //     ? images.new_launch_nfb_new
                      //     : data.is_nfb_type == 0
                      //     ? images.logo
                      //     : data.is_nfb_type == 2
                      //     ? images.logo
                      //     : data.is_nfb_type == 3
                      //     ? images.new_launch_nfb_new
                      //     : images.new_launch_nfb_new
                      // }
                      src={
                        data.is_nfb_type == 0
                          ? images.collection
                          : data.is_nfb_type == 0
                          ? images.collection
                          : data.is_nfb_type == 2
                          ? images.logo
                          : data.is_nfb_type == 3
                          ? images.new_launch_nfb_new
                          : images.new_launch_nfb_new
                      }
                      className="show_second_img"
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            id="divToCapture1"
            // style={{ transform: "scale(-1, 1)" }}
            ref={divToCaptureRef}
            className="card_flip-card-back"
          >
            <div className="crad__show2_main_img_back">
              <img src={icon} className="card_1_img_back" alt="" />
              <div className="ditails_of_back_cs_show2">
                {data.desc && (
                  <>
                    <p
                      style={{
                        fontsize: "16px",
                        fontFamily: "var(--font-600)",
                        color: "var(--color-white",
                      }}
                    >
                      Asset Description
                    </p>
                    <p className="details_bg_card">
                      {data.desc.slice(0, 100)}{" "}
                      {data.desc.length > 100 && "..."}
                    </p>
                    {data.desc.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(data.desc);
                          setModalName("Asset Description");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {data.product_history && (
                  <>
                    <p
                      style={{
                        fontsize: "16px",
                        fontFamily: "var(--font-600)",
                        color: "var(--color-white",
                      }}
                    >
                      Asset Story
                    </p>
                    <p className="details_bg_card">
                      {data.product_history.slice(0, 100)}
                      {data.product_history.length > 100 && "..."}
                    </p>
                    {data.product_history.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(data.product_history);
                          setModalName("Asset Story");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "12px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
                {data.product_highlights && (
                  <>
                    <p
                      style={{
                        fontsize: "16px",
                        fontFamily: "var(--font-600)",
                        color: "var(--color-white",
                      }}
                    >
                      Asset Highlight
                    </p>
                    <p className="details_bg_card">
                      {data.product_highlights.slice(0, 100)}
                      {data.product_highlights.length > 100 && "..."}
                    </p>
                    {data.product_highlights.length > 100 && (
                      <button
                        onClick={() => {
                          setIsModalOpen(true);
                          setMsg(data.product_highlights);
                          setModalName("Asset Highlight");
                        }}
                        style={{
                          color: "var(--color-main)",
                          fontSize: "11px",
                          fontFamily: "var(--font-600)",
                          border: "none",
                          backgroundColor: "none",
                          marginBottom: "4px",
                        }}
                      >
                        Read More
                      </button>
                    )}
                  </>
                )}
                <br />
              </div>
              <div className="crad1_details_back">
                {/* <p className="details_bg_card_show2_sub">
                {data.desc.length > 100 && (
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                      setMsg(data.desc);
                      setModalName("Asset Description");
                    }}
                    style={{
                      color: "var(--color-main)",
                      fontSize: "12px",
                      fontFamily: "var(--font-600)",
                      border: "none",
                      backgroundColor: "none",
                      marginBottom: "4px",
                    }}
                  >
                    Read More
                  </button>
                )}
                </p> */}
                <br />
                <h1 className="pro_nm_card">Demo Asset</h1>
                <p className="owned_by_nm">Owned by</p>
                <h1 className="back_owned_nm">{data.username}</h1>
              </div>
              <img src={data.qr_code} className="card_qr" alt="" />
              <div className="btn_showcardsecond">
                {location && location.user_id == userId && (
                  <>
                    <div className="btn_showcardsecond_sub1">
                      {data.is_nfb_type == 0 ? (
                        <button
                          onClick={() => {
                            navigate("/resellcreateproduct", {
                              state: {
                                accept_bond_bid_id: data.accept_bond_bid_id,
                                is_nfb: data.is_nfb,
                                resale_product_id: data.resale_product_id,
                                bond_id: data.bond_id,
                              },
                            });
                          }}
                          className="show_second_btn"
                        >
                          SELL
                        </button>
                      ) : null}
                      {/* <button
                        onClick={captureDivAsImage1}
                        className="show_second_btn"
                      >
                        SAVE
                      </button> */}
                      <a
                        // href={data.qr_string}
                        // target="_blank"
                        onClick={() => {
                          handleShare();
                        }}
                        className="show_second_btn"
                      >
                        SHARE
                      </a>
                    </div>

                    <div className="btn_showcardsecond_sub2">
                      <img
                        onClick={() => Bond_like()}
                        src={
                          // data.is_nfb_type == 0
                          //   ? images.new_launch_nfb_new
                          //   : data.is_nfb_type == 0
                          //   ? images.logo
                          //   : data.is_nfb_type == 2
                          //   ? images.logo
                          //   : data.is_nfb_type == 3
                          //   ? images.new_launch_nfb_new
                          //   : images.new_launch_nfb_new

                          data.is_nfb_type == 0
                            ? images.collection
                            : data.is_nfb_type == 0
                            ? images.collection
                            : data.is_nfb_type == 2
                            ? images.logo
                            : data.is_nfb_type == 3
                            ? images.new_launch_nfb_new
                            : images.new_launch_nfb_new
                        }
                        className="show_second_img"
                        alt=""
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcardsecond;
