import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  GET_HOME_BEGIN,
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  GET_LIKE_BEGIN,
  GET_LIKE_SUCCESS,
  GET_LIKE_FAIL,
  GET_HOME_COMMENT_BEGIN,
  GET_HOME_COMMENT_SUCCESS,
  GET_HOME_COMMENT_FAIL,
  GET_LATEST5_BEGIN,
  GET_LATEST5_SUCCESS,
  GET_LATEST5_FAIL,
  GET_ALL_INTEREST_BEGIN,
  GET_ALL_INTEREST_SUCCESS,
  GET_ALL_INTEREST_FAIL,
  GET_ALL_NOTIFICATION_BEGIN,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  GET_FEED_BEGIN,
  GET_FEED_SUCCESS,
  GET_FEED_FAIL,
  MY_COLLECTION_BEGIN,
  MY_COLLECTION_FAIL,
  MY_COLLECTION_SUCCESS,
} from "../Action";
import {
  add_release_bond_comment,
  get_all_interest,
  get_latest_5_bond_3_club,
  get_user_general_notification,
  list_of_release_bond,
  list_of_release_bond_2,
  release_bond_like,
  my_collection_get_user_accepted_bond,
} from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import home_reducer from "../reducer/home_reducer";

const initialState = {
  home_loading: false,
  latest5_loading: false,
  like_message: "",
  like_loading: false,
  home_comment_loading: false,
  home_comment_data: {},
  is_loading: false,
  home_latest5_data: [],
  interest_data: [],
  notification_data: [],
  collection_loading: false,
  collection_data: [],
};

const HomeContext = React.createContext();
export const HomeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(home_reducer, initialState);

  ////

  const getHome = async (uid) => {
    const formdata = new FormData();
    formdata.append("user_id", uid);
    dispatch({ type: GET_HOME_BEGIN });
    try {
      const response = await axios.post(list_of_release_bond, formdata);
      const homedata = response.data;
      //   console.log("====", response.data);
      if (homedata.status == 1) {
        dispatch({ type: GET_HOME_SUCCESS, payload: homedata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_HOME_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const setLike = async (params) => {
    dispatch({ type: GET_LIKE_BEGIN });
    try {
      const response = await axios.post(release_bond_like, params);
      const likedata = response.data;
      //   console.log("====", response.data);
      if (likedata.status == 1) {
        dispatch({ type: GET_LIKE_SUCCESS, payload: likedata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_LIKE_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const setComment = async (params) => {
    dispatch({ type: GET_HOME_COMMENT_BEGIN });
    try {
      const response = await axios.post(add_release_bond_comment, params);
      const commentdata = response.data;
      //   console.log("====", response.data);
      if (commentdata.status == 1) {
        dispatch({ type: GET_HOME_COMMENT_SUCCESS, payload: commentdata });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_HOME_COMMENT_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const getDashboardlatest5 = async (params) => {
    const formdata = new FormData();
    formdata.append("user_id", params);
    dispatch({ type: GET_LATEST5_BEGIN });
    try {
      const response = await axios.post(get_latest_5_bond_3_club, formdata);
      const homedata = response.data;
      //   console.log("====", response.data);
      if (homedata.status == 1) {
        dispatch({ type: GET_LATEST5_SUCCESS, payload: homedata.result });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_LATEST5_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const getAllInterest = async () => {
    dispatch({ type: GET_ALL_INTEREST_BEGIN });
    try {
      const response = await axios.get(get_all_interest);
      const intrestdata = response.data;
      if (intrestdata.status == 1) {
        dispatch({
          type: GET_ALL_INTEREST_SUCCESS,
          payload: intrestdata.result,
        });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_ALL_INTEREST_FAIL });
      // localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const getAllNotification = async (param) => {
    dispatch({ type: GET_ALL_NOTIFICATION_BEGIN });
    try {
      // const response = await axios.get(get_user_general_notification);
      const response = await axios.post(get_user_general_notification, param);
      const notificationdata = response.data;
      if (notificationdata.status == 1) {
        dispatch({
          type: GET_ALL_NOTIFICATION_SUCCESS,
          payload: notificationdata.result,
        });
      } else {
        dispatch({ type: GET_ALL_NOTIFICATION_FAIL });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_ALL_NOTIFICATION_FAIL });
      // localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  // feed api
  const getAllFeed = async (param) => {
    dispatch({ type: GET_FEED_BEGIN });
    try {
      // const response = await axios.get(get_user_general_notification);
      const response = await axios.post(list_of_release_bond_2, param);
      const feeddata = response.data;
      if (feeddata.status == 1) {
        dispatch({
          type: GET_FEED_SUCCESS,
          payload: feeddata.result,
        });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: GET_FEED_FAIL });
      // localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  const Mycollectionlike = async (params) => {
    dispatch({ type: MY_COLLECTION_BEGIN });
    try {
      const response = await axios.post(
        my_collection_get_user_accepted_bond,
        params
      );
      const colltiondata = response.data;
      console.log("====", response.data);
      if (colltiondata.status == 1) {
        dispatch({
          type: MY_COLLECTION_SUCCESS,
          payload: colltiondata.result,
        });
      } else {
        dispatch({ type: MY_COLLECTION_FAIL });
      }
      return response.data;
    } catch (error) {
      dispatch({ type: MY_COLLECTION_FAIL });
      localStorage.setItem("is_login", JSON.stringify(false));
    }
  };

  // useEffect(() => {
  //   getDashboardlatest5();
  // }, []);

  return (
    <HomeContext.Provider
      value={{
        ...state,
        getHome,
        setLike,
        setComment,
        getDashboardlatest5,
        getAllInterest,
        getAllNotification,
        getAllFeed,
        Mycollectionlike,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export const useHomeContext = () => {
  return useContext(HomeContext);
};
