import React, { useEffect, useState } from "react";
import "./royltyclubnfb.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import qs from "qs";
import axios from "axios";

const Royltyclubnfb = () => {
  const navigate = useNavigate();
  const [isInputVisible, setInputVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    searchFilterCreators(e.target.value);
  };

  const location = useLocation();
  const userId = localStorage.getItem("user_id");

  const [topcreatordata, setTopCreatorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCreatorData, setSearchCreatorData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
      club_id: location.state.club_id,
    };

    console.log(param);

    axios
      .post(
        "https://www.ravi.host/api/get_all_club_detail",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log(
          "Response============---------~~~~~~~~~~~```>",
          Response.data
        );
        console.log("====================================");
        if (Response.data.status === 1) {
          setTopCreatorData(Response.data.result.first_buyer_nfb);
          setSearchCreatorData(Response.data.result.first_buyer_nfb);
          setIsLoading(false);

          console.log("====================================");
          // console.log('topcreatordata', topcreatordata);
          console.log("====================================");
        } else {
          //  alert(Response.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchFilterCreators = (text) => {
    const newData = searchCreatorData.filter(function (item) {
      console.log("text", text);
      const creator = item.product_name
        ? item.product_name.toUpperCase()
        : "".toLowerCase();
      const textData = text.toUpperCase();
      return creator.indexOf(textData) > -1;
    });

    setTopCreatorData(newData);
    console.log("asvsvsds", topcreatordata);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <>
          <div className="roylty_nav_hd">
            <img
              src={images.back}
              className="roylty_nav_img1"
              alt=""
              onClick={() => navigate(-1)}
            />
            <p className="roylty_nav_nm">Roylty Rarerez (1st buyer)</p>
            <img
              src={images.search_2}
              className="roylty_nav_img"
              alt=""
              onClick={handleSearchClick}
            />
          </div>
          <br />
          {isInputVisible && (
            <div>
              <input
                type="text"
                value={searchValue}
                onChange={handleInputChange}
                placeholder="Search..."
              />
            </div>
          )}
        </>
        <div className="royltyclubnfb_main_details">
          {topcreatordata &&
            topcreatordata.map((item, index) => (
              <div
                onClick={() => {
                  navigate("/ProductView", {
                    state: {
                      release_bond_id: item.release_bond_id,
                      product_id: item.product_id,
                      is_nfb: item.is_nfb,
                      uniq_code: item.uniq_code,
                      theme_index: item.theme_index,
                      share_by_user: item.share_by_user,
                      club_id: item.club_id,
                    },
                  });
                }}
                key={index}
                className="royltyclubnfb_main"
              >
                <img
                  src={item.product_image_video[0].img_video}
                  className="royltyclubnfb_img"
                  alt=""
                />
                <div className="royltyclubnfb_nm">
                  <div className="royltyclubnfb_nm1">
                    <p className="royltyclubnfb_number">{item.product_name}</p>
                    <p className="royltyclubnfb_number">
                      Stock : {item.total_available_bond_bid}
                    </p>
                  </div>
                  <div className="royltyclubnfb_nm2">
                    <p className="royltyclubnfb_number1">
                      {item.product_value}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Royltyclubnfb;
