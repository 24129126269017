import React, { useState, useEffect } from "react";
import "./tradehistory.css";
import axios from "axios";
import NavbarSec from "../../common/NavbarSec";
import { useLocation } from "react-router-dom";
import { get_single_product_bond_detail_all_buyer } from "../../utils/Constant";
// import images from "../../constants/images";
// import NavbarSec from "../../common/NavbarSec";

const Tradehistory = () => {
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [tradehistory, setTradehistory] = useState([]);
  const [getdata, setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log("nitin 13", tradehistory);

  const tradeHistoryData = async () => {
    setIsLoading(true);
    // FormData object
    const formData = new FormData();

    formData.append("release_bond_id", location.state.release_bond_id);
    formData.append("product_id", location.state.product_id);
    formData.append("uniq_code", location.state.uniq_code);
    formData.append("user_id", userId);
    formData.append("is_nfb", location.state.is_nfb);

    // Send the POST request with the FormData
    await axios
      .post(get_single_product_bond_detail_all_buyer, formData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          setTradehistory(res.data.result);
          setdata(res.data.result);
        } else {
        }
      })
      .catch((error) => {
        // Handle errors
        setIsLoading(false);
      });
  };
  useEffect(() => {
    tradeHistoryData();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = tradehistory.filter(function (item) {
      console.log("item", item.buyer);
      const employee = item.buyer ? item.buyer.toUpperCase() : "".toLowerCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1;
    });
    setdata(newData);
    console.log("asvsvsds", newData);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Create Asset" /> */}
        <NavbarSec name="Trade history" />

        <input
          onChange={(e) => {
            searchFilter_branch(e.target.value);
          }}
          type="text"
          placeholder="Search"
          className="user_resellnfb_search"
        />
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <div className="tradehistory">
            {getdata.map((item, index) => (
              <>
                <div
                  className="trad_hist_siglecard"
                  key={index}
                  style={{
                    margin: "10px auto",
                    border: index == 0 ? "1px solid gold" : "",
                  }}
                >
                  <div className="th_img_wrapp">
                    <span className="list_number_pv">{index + 1}</span>

                    <img
                      src={item.buyer_profile}
                      className="list_img_of_resell"
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  </div>

                  {/* <h1 className="th_buyer_name"> {item.buyer}</h1> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="th_buyer_name">{item.buyer}</h1>
                    <p
                      style={{
                        fontSize: "12px",
                        fontFamily: "var(--font-300)",
                        textAlign: "center",
                      }}
                    >
                      {item.date_time}
                    </p>
                  </div>

                  <p className="th_price">
                    ₹ {item.get_amount}/-{"  "}
                  </p>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tradehistory;
