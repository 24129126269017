import React, { useEffect, useState } from "react";
import "./sidemenunew.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { useProfileContext } from "../../context/profile_context";

const NewSideMenu = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const { profile_loading, edit_profile_data, EditProfile } =
    useProfileContext();
  const [isLoading, setIsLoading] = useState(false);

  const GetProfile = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    const data = await EditProfile(param);
    console.log("data 111", data.result);
  };

  useEffect(() => {
    GetProfile();
  }, []);
  return (
    <div className="page_main_wrapp_nw reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />

      <div className="page_base_wrapp_2">
        {/* first section start */}
        <div className="new_side_menu_sec1">
          {/* <Link to={"/editprofile"} className="new_side_new_set1">
            <div className="new_side_menu_sec1_profile_wrapp">
              <div className="sideimgbox">
                <img
                  src={
                    edit_profile_data.profile === ""
                      ? images.logo
                      : edit_profile_data.profile
                  }
                  alt=""
                />
              </div>
            </div>
          </Link> */}

          <Link to={"/editprofile"} className="nav13_profile_wrapp">
            <div>
              <img
                src={
                  edit_profile_data.profile === ""
                    ? images.logo
                    : edit_profile_data.profile
                }
                className="nav13_img_of_usermenu"
                alt=""
              />
            </div>
          </Link>
          <div className="nav13_btns_wrapp">
            <Link
              to={"/"}
              // style={{}}
              onClick={() => {
                window.location.reload();
                localStorage.clear();
              }}
              className="nav13_btn"
              // style={{ width: "20px", height: "20px" }}
            >
              <img src={images.sidemenu_logout} alt="" />
            </Link>
            <Link className="nav13_btn" to={"/homescreen2"}>
              <img src={images.side_new_close} alt="" />
            </Link>
          </div>
        </div>
        {/* first section end */}

        {/* my Rares btn start */}
        <Link to={"/mynfb"} className="new_side_menu_mynfbs_btn">
          <img src={images.rares} alt="" className="" />
          <p className="sidelistmain_1">My Rarerez</p>
        </Link>
        {/* my Rares btn start */}

        {/* assets section start */}
        <div>
          <p className="new_sidemenu_assets_name">Assets</p>
          <div className="new_sidemenu_hr"></div>
          <div className="new_sidemenu_assets_main_wrapp">
            <Link
              to={"/myproduct"}
              className="new_sidemenu_assets_single_wrapp"
            >
              <img src={images.my_asset} alt="" />
              <p className="new_sidelistmain_sub">My Assets</p>
            </Link>
            <Link
              to={"/CreateProduct"}
              className="new_sidemenu_assets_single_wrapp"
            >
              <img src={images.protect_asset} alt="" />
              <p className="new_sidelistmain_sub">Protect Assets</p>
            </Link>
            <Link
              to={"/assetsapproval"}
              className="new_sidemenu_assets_single_wrapp"
            >
              <img src={images.asset_approval} alt="" />
              <p className="new_sidelistmain_sub">Assets Approval</p>
            </Link>
          </div>
        </div>
        {/* assets section end */}
        {/* benefit section start */}
        <p className="new_sidemenu_assets_name">Benefits</p>
        <div className="new_sidemenu_hr"></div>
        <div className="new_sidemenu_assets_main_wrapp">
          <Link
            to={"/SpecialBenefits"}
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.my_benefits} alt="" />
            <p className="new_sidelistmain_sub">My Benefits</p>
          </Link>
          {/* <Link
            to={"/NewUserCreateBenefit"}
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.bucket_benefit} alt="" />
            <p className="new_sidelistmain_sub">Collect & Win</p>
          </Link> */}
          <Link
            to={"/benefitsoffered"}
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.benefits_offered} alt="" />
            <p className="new_sidelistmain_sub">Benefits offered</p>
          </Link>
          {/* <Link
            to={"/FreeBenefit"}
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.benefits_offered} alt="" />
            <p className="new_sidelistmain_sub">Free Benefits</p>
          </Link> */}
          {edit_profile_data.is_partner_manager == 1 && (
            <Link
              to={"/partnerbenefits"}
              className="new_sidemenu_assets_single_wrapp"
            >
              <img src={images.benefits_offered} alt="" />
              <p className="new_sidelistmain_sub">Partner benefits</p>{" "}
            </Link>
          )}
        </div>
        {/* benefit section end */}

        {/* clubs section start */}
        <p className="new_sidemenu_assets_name">Clubs</p>
        <div className="new_sidemenu_hr"></div>
        <div className="new_sidemenu_assets_main_wrapp">
          <Link
            to="/ClubMamberRequsts"
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.club_request} alt="" />
            <p className="new_sidelistmain_sub">Club Requests</p>
          </Link>
          <Link to="/Sponsors" className="new_sidemenu_assets_single_wrapp">
            <img src={images.my_club_new} alt="" />
            <p className="new_sidelistmain_sub">My Club</p>
          </Link>
          <Link
            to={"/mymemberships"}
            className="new_sidemenu_assets_single_wrapp"
          >
            <img src={images.my_membership} alt="" />
            <p className="new_sidelistmain_sub">My Memberships</p>
          </Link>
          <Link to={"/Createclub"} className="new_sidemenu_assets_single_wrapp">
            <img src={images.create_club_new} alt="" />
            <p className="new_sidelistmain_sub">Create Club</p>
          </Link>
        </div>
        {/* clubs section end */}
        {/* other option section start */}
        <p className="new_sidemenu_assets_name">Other options</p>
        <div className="new_sidemenu_hr"></div>
        <Link
          to="/NewInternationalInvite"
          className="new_sidemenu_assets_single_wrapp"
        >
          <img src={images.international_request} alt="" />
          <p className="new_sidelistmain_sub"> International User requests</p>
        </Link>

        {/* <Link
          to="/NewCommunityRequest"
          state={{ con: true }}
          className="new_sidemenu_assets_single_wrapp"
        >
          <img src={images.communityicon} alt="" />
          <p className="new_sidelistmain_sub">Community Requests</p>
        </Link> */}
        {/* other option section end */}
      </div>
    </div>
  );
};

export default NewSideMenu;
