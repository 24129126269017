import React, { useEffect, useState } from "react";
import "./clubbankaccount.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import { useBlockContext } from "../../context/nfb_blockclub_context";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const ClubBankaccount = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userid, setUserid] = useState(localStorage.getItem("user_id")); // Initialize with your default user_id or set it using some other mechanism
  const [getacno, setGetacno] = useState("");
  const [getconformacno, setGetconformacno] = useState("");
  const [backholdername, setBackholdername] = useState("");
  const [bankname, setBankname] = useState("");
  const [ifscno, setIfscno] = useState("");
  const [con, setcon] = useState(false);

  useEffect(() => {
    // Fetch user's bank details when the component mounts
    // get_bankdetail();
  }, []);

  const get_bankdetail = () => {
    const formdata = new FormData();
    formdata.append("user_id", userid);
    formdata.append("club_id", location.state.club_id);
    setLoading(true);

    axios
      .post("https://www.ravi.host/api/get_club_bank_detail", formdata)
      .then((Response) => {
        if (Response.data.status === 1) {
          setGetacno(Response.data.result[0].bank_account_number);
          setGetconformacno(Response.data.result[0].bank_account_number);
          setBackholdername(Response.data.result[0].bank_holder_name);
          setBankname(Response.data.result[0].bank_name);
          setIfscno(Response.data.result[0].ifsc_code);
          setBodyScrollEnabled(true);
        }
        setLoading(false);
        console.log("Response", Response.data);
      });
  };
  const handleBankNameChange = (e) => {
    setBankname(e.target.value);
  };

  const handleBankHolderNameChange = (e) => {
    setBackholdername(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setGetacno(e.target.value);
  };

  const handleConfirmAccountNumberChange = (e) => {
    setGetconformacno(e.target.value);
  };

  const handleIFSCCodeChange = (e) => {
    setIfscno(e.target.value);
  };

  const [cusmsg, setCusmsg] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const create_bankdetail = () => {
    // if (bankname === "") {
    //   setIsModalVisible(true);
    //   setCusmsg("Please enter bank name");
    //   setcon(false);
    // } else if (backholdername === "") {
    //   setIsModalVisible(true);
    //   setCusmsg("Please enter bankholder name");
    //   setcon(false);
    // } else
    if (getacno === "") {
      setIsModalVisible(true);
      setCusmsg("Please enter Account Number");
      setcon(false);
    } else if (getacno !== getconformacno) {
      setIsModalVisible(true);
      setCusmsg(
        "The two account numbers you entered do not match each other. Please ensure that both account numbers are the same before proceeding"
      );
      setcon(false);
    } else if (ifscno === "") {
      setIsModalVisible(true);
      setCusmsg("Please enter IFSC code");
      setcon(false);
    } else {
      const formdata = new FormData();
      formdata.append("user_id", userid);
      formdata.append("club_id", location.state.club_id);
      // formdata.append("bank_name", bankname);
      // formdata.append("bank_holder_name", backholdername);
      formdata.append("bank_account_number", getacno);
      formdata.append("ifsc_code", ifscno);
      setLoading(true);
      console.log(formdata);
      axios
        .post("https://www.ravi.host/api/add_club_bank_detail", formdata)
        .then((Response) => {
          setLoading(false);
          if (Response.data.status === 1) {
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
            setcon(true);
            // SimpleToast.show(Response.data.message);
            // Do something after success, e.g., navigate to a different screen
            // this.props.navigation.goBack('');
            setBodyScrollEnabled(true);
          } else {
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
            setcon(false);
            setBodyScrollEnabled(true);
          }
          console.log("Response", Response.data);
        });
    }
  };

  const navigate = useNavigate();

  //disable scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isModalVisible, bodyScrollEnabled]);

  const [getclubName, setClubName] = useState("");
  const { GetAllClubDetail } = useBlockContext();

  const GetClubDetail = async () => {
    const userId = localStorage.getItem("user_id");
    const param = {
      club_id: location.state.club_id,
      user_id: userId,
    };
    console.log("param", param);
    const data = await GetAllClubDetail(param);
    console.log("cc data", JSON.stringify(data.result?.club_name, null, 2));
    setClubName(data.result?.club_name);
  };

  useEffect(() => {
    GetClubDetail();
    Checkclubgst();
  }, []);

  const [getgststatus, setgststatus] = useState(0);

  const Checkclubgst = () => {
    // const formdata = new FormData();
    // formdata.append("user_id", userid);
    const param = {
      user_id: userid,
    };
    console.log(param);
    axios
      .post("https://www.ravi.host/api/check_club_gst", param)
      .then((Response) => {
        setLoading(false);
        if (Response.data.status === 1) {
          setgststatus(1);
        } else {
          setgststatus(0);
        }
        console.log("Response", Response.data);
      });
  };

  const Updateclubname = () => {
    const param = {
      club_id: location.state.club_id,
      club_name: getclubName,
    };
    console.log(param);
    axios
      .post("https://www.ravi.host/api/edit_club_name", param)
      .then((Response) => {
        setLoading(false);
        if (Response.data.status === 1) {
          GetClubDetail();
          // setgststatus(1);
        } else {
          // setgststatus(0);
        }
        console.log("Response", Response.data);
      });
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <Link className="side_menu_close_btn" to={"/homescreen2"}>
            <IoClose className="side_menu_close_btn2" size={20} />
          </Link> */}
        {/* <NavbarSec name="" /> */}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30px",
            background: "black",
            zIndex: 11,
            marginTop: "20px",
          }}
        >
          <div
            onClick={() => {
              if (location.state && location.state.is_alltopclub == 1) {
                navigate("/AllTopClub");
              } else if (location.state && location.state.is_alltopclub == 2) {
                navigate("/clubs");
              } else {
                navigate("/Sponsors");
              }
            }}
            className="navsec_back_arrow"
          >
            {/* <img
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                navigate("/Sponsors");
              }}
            /> */}
            <FaAngleLeft color="var(--color-white)" size={20} />
          </div>
        </div>
        {/* <div className="club_bank_set">
          <b>Bank Name</b>
          <input type="text" value={bankname} onChange={handleBankNameChange} />
        </div>
        <div className="club_bank_set">
          <b>Bank Holder Name</b>
          <input
            type="text"
            value={backholdername}
            onChange={handleBankHolderNameChange}
          />
        </div> */}
        {/* <div style={{ marginTop: "5%" }}>
          <label htmlFor="" id="label_club">
            Club Name as per bank records
          </label>
          <input
            style={{ marginTop: "5px" }}
            disabled={getgststatus == 1 ? false : true}
            type="text"
            name="club_name"
            placeholder="Club Name as per bank records"
            value={getclubName}
            onChange={(e) => setClubName(e.target.value)}
          />
        </div> */}
        <div className="creator_name_wrapp mb-2">
          <p>Club Name as per bank records</p>
          <div>
            <input
              disabled={getgststatus == 1 ? false : true}
              type="text"
              name="club_name"
              placeholder="Club Name as per bank records"
              value={getclubName}
              onChange={(e) => setClubName(e.target.value)}
            />
          </div>
        </div>
        {getgststatus == 1 ? (
          <div
            style={{
              width: "100%",
              // backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                alignSelf: "flex-end",
                backgroundImage:
                  // "linear-gradient(180deg #ff004c 0%, rgba(202, 172, 255, 0.69) 100%)",
                  "linear-gradient(180deg, #ff004c 2.44%, rgba(202, 172, 255, 0.69) 100%)",
              }}
              className="close_forgot"
              onClick={() => {
                Updateclubname();
              }}
            >
              Update
            </button>
          </div>
        ) : null}
        {/* <div className="club_bank_set">
          <b>Account Number</b>
          <input
            type="number"
            value={getacno}
            onChange={handleAccountNumberChange}
          />
        </div> */}
        <div className="creator_name_wrapp mb-2">
          <p>Account Number</p>
          <div>
            <input
              type="number"
              value={getacno}
              onChange={handleAccountNumberChange}
            />
          </div>
        </div>
        <div className="creator_name_wrapp mb-2">
          <p>Confirm Account Number</p>
          <div>
            <input
              type="number"
              value={getconformacno}
              onChange={handleConfirmAccountNumberChange}
            />
          </div>
        </div>
        <div className="creator_name_wrapp mb-2">
          <p>IFSC Code</p>
          <div>
            <input type="text" value={ifscno} onChange={handleIFSCCodeChange} />
          </div>
        </div>

        <center>
          <button
            onClick={() => {
              create_bankdetail();
            }}
            style={{ marginTop: "40px" }}
            className="new_btn_wrapp"
          >
            <p>Submit</p>
          </button>
        </center>
      </div>
      {isModalVisible && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>{""}</b>
        //       <br />
        //       <p className="modal-message"> {cusmsg}</p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           con == true
        //             ? window.history.back()
        //             : setIsModalVisible(false);
        //           // console.log(con);
        //           // con == 2
        //           //   ? navigate("/homescreen2")
        //           //   : con == 1
        //           //   ? navigate("/wallet")
        //           //   : setcusmodal1(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setIsModalVisible}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{cusmsg ? cusmsg : ""}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         con == true ? window.history.back() : setIsModalVisible(false);
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={() => {
            con == true ? window.history.back() : setIsModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default ClubBankaccount;
