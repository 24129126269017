import React, { useEffect, useState } from "react";
import "./MyCAndW.css";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa6";
import axios from "axios";
import { get_user_pending_c_and_w } from "../../utils/Constant";

const MyCAndW = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [getpendingData, setPendingData] = useState([]);

  const GetPendingCandW = async () => {
    const formdata = new FormData();
    formdata.append("user_id", userId);

    await axios
      .post(get_user_pending_c_and_w, formdata, {})
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setPendingData(res.data.result);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetPendingCandW();
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="legacy_dashbord_nav_sec">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>My C & W</p>
          <div style={{ width: "10%" }}></div>
        </div>

        {/* tabs start */}
        <div className="c_and_w_tabs_wrapp"></div>
        {/* tabs end */}

        {/* pending bonds start */}
        <div className="c_and_w_pending_c_and_w_wrapp">
          {getpendingData && getpendingData.length > 0
            ? getpendingData.map((item, index) => {
                return (
                  <div className="c_and_w_pending_c_and_w_card">
                    <img src={item.c_and_w_image[0]?.img_video} alt="" />
                    <p>{item.reward_name}</p>
                    <button
                      onClick={() => {
                        navigate("/CreateCandW");
                      }}
                    >
                      Action Needed
                    </button>
                  </div>
                );
              })
            : null}
        </div>
        {/* pending bonds end */}
      </div>
    </div>
  );
};

export default MyCAndW;
