import React from "react";
import "./NewOpeningScreen.css";
import images from "../../constants/images";
import NewLoader from "../../common/newloder/NewLoader";
import { useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const NewOpeningScreen = () => {
  const navigate = useNavigate();
  const [getloading, setLoading] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  // Define the words array
  const wordsOrders = [
    ["Protect", "Promote", "Profit"],
    ["Promote", "Profit", "Protect"],
    ["Profit", "Protect", "Promote"],
  ];
  // const wordsOrders = [
  //   ["Protect", "Promote", "Profit"],
  //   ["Promote", "Profit", "Protect"],
  //   ["Profit", "Protect", "Promote"],
  // ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
          marginTop: "30px",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Function to render the words based on the current slide index
  const renderWords = () => {
    const currentOrder = wordsOrders[slideIndex % wordsOrders.length]; // Get the current order of words based on slide index
    return (
      <div className="words-display-container">
        {currentOrder.map((word, index) => (
          <div
            style={{
              // width: "94px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              key={index}
              className={`first_screen_word ${
                index === 1 ? "first_screen_highlighted" : ""
              }`}
            >
              {word}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />

      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="play_screen_main_wrapp" style={{ height: "95vh" }}>
            <div
              className="play_screen_banner_container"
              style={{ height: "30vh", minHeight: "100px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            {/* Include the WordsDisplay component here */}
            {renderWords()}

            <div
              className="pv_imgs_wrapp"
              style={{
                width: "100%",
                margin: "10px auto",
                minHeight: "40vh",
                marginBottom: "10vh",
              }}
            >
              <Slider {...settings}>
                <div
                  className="otp_screen_text_container"
                  style={{ margin: "6px auto" }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    Showcase your creations
                  </h4>
                  <p>
                    Showcase your most prized possessions, career milestones,
                    and anything that defines you. Let your unique assets
                    inspire others.{" "}
                  </p>
                </div>

                <div
                  className="otp_screen_text_container"
                  style={{ margin: "6px auto" }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    Unleash your Rarerez
                  </h4>
                  <p>
                    Transform what matters most to you into limited-edition
                    digital collectibles. The world craves a piece of your
                    legacy, and you earn with every sale.
                  </p>
                </div>

                <div
                  className="otp_screen_text_container"
                  style={{
                    margin: "6px auto",
                  }}
                >
                  <h4 style={{ margin: "0px", color: "var(--color-main)" }}>
                    Immortalize your journey
                  </h4>
                  <p>
                    Upload photos, videos, and stories to create a captivating
                    digital time capsule. Share your brilliance with the world
                    for generations to come.
                  </p>
                </div>
              </Slider>
            </div>

            {/* btn */}
            <button
              onClick={() => {
                navigate("/NewPlayScreen");
              }}
              className="new_btn_wrapp"
              style={{ width: "90%", marginBottom: "1rem" }}
            >
              <p>Let's Start</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewOpeningScreen;
