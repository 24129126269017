import React, { useState, useEffect } from "react";
import "./showcase.css"; // Make sure to import your CSS file
import images from "../../constants/images"; // Make sure to import your image paths
import { Link, useLocation } from "react-router-dom";
import Footer from "../../common/footer/Footer";
import Showcard from "./Showcard";
import Showcardsecond from "./Showcardsecond";
import Showcardthree from "./Showcardthree";
import qs from "qs";
import axios from "axios";
import { useHomeContext } from "../../context/home_context";
import Showcardfour from "./Showcardfour";
import Showcardfive from "./Showcardfive";
import Showcardsix from "./Showcardsix";
import Showcardeight from "./Showcardeight";
import Showcardseven from "./Showcardseven";
import AlertModal from "../../components/AlertModal";
import Showcard13 from "./Showcard13";
import ShowCard14 from "./ShowCard14";
import ShowCard15 from "./ShowCard15";
import NewModal from "../../common/newmodal/NewModal";
import ShowcaseNewFrame1 from "../../frames/showcaseframes/ShowcaseNewFrame1";

import ShowcaseNewFrame3 from "../../frames/showcaseframes/ShowcaseNewFrame3";
import ShowcaseNewFrame4 from "../../frames/showcaseframes/ShowcaseNewFrame4";
import ShowcaseNewFrame5 from "../../frames/showcaseframes/ShowcaseNewFrame5";
import ShowcaseNewFrame6 from "../../frames/showcaseframes/ShowcaseNewFrame6";
import ShowcaseNewFrame8 from "../../frames/showcaseframes/ShowcaseNewFrame8";
import ShowcaseNewFrame9 from "../../frames/showcaseframes/ShowcaseNewFrame9";
import ShowcaseNewFrame10 from "../../frames/showcaseframes/ShowcaseNewFrame10";
import ShowcaseNewFrame2 from "../../frames/showcaseframes/ShowcaseNewFrame2";
import ShowcaseNewFrame7 from "../../frames/showcaseframes/ShowcaseNewFrame7";
import NewFooter from "../../common/newfooter/NewFooter";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Showcase = () => {
  const location = useLocation();

  const { Mycollectionlike, collection_data, collection_loading } =
    useHomeContext();
  const [selectedCard, setSelectedCard] = useState(1); // Default to the first card

  const handleCardChange = (cardNumber) => {
    setSelectedCard(cardNumber);
  };

  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [setindex, setSetIndex] = useState(0);
  const [acceptBondBidId, setAcceptBondBidId] = useState("");
  const [setimage, setSetImage] = useState("");
  const [setname, setSetName] = useState("");
  const [setimage2, setSetImage2] = useState("");
  const [ownername, setOwnerName] = useState("");
  const [isNfb, setIsNfb] = useState("");
  const [uniqCode, setUniqCode] = useState("");
  const [isCollection, setIsCollection] = useState(1);
  const [bondId, setBondId] = useState("");
  const [isClub, setIsClub] = useState("");
  const [getdate, setGetDate] = useState("");
  const [releaseBondId, setReleaseBondId] = useState("");
  const [getqr, setGetQR] = useState("");
  const [productValue, setProductValue] = useState("");
  const [time, setTime] = useState("");
  const [themeIndex, setThemeIndex] = useState("");
  const [username, setUsername] = useState("");
  const [des, setDes] = useState("");
  const [getqrString, setGetQRString] = useState("");
  const [isNfbType, setIsNfbType] = useState("");
  const userId = localStorage.getItem("user_id");
  const [getamount, setamount] = useState("");
  const [getdata, setdata] = useState([]);
  const [islikes, setislikes] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const refreshData = () => {
    const params = {
      user_id: location.state?.user_id ? location.state.user_id : userId,
    };
    // Call your data retrieval function here, e.g., Mycollectionlike(params)
    Mycollectionlike(params);
  };

  useEffect(() => {
    fff();
  }, []);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fff = async () => {
    const params = {
      user_id: location.state?.user_id ? location.state.user_id : userId,
    };
    console.log("paramsww", params);
    console.log("userId", userId);

    const res = await Mycollectionlike(params);
    const status = res.status;
    console.log("sdcaccadcs", products);
    // const result = await collection_data[0];
    // console.log("result", res.result[0]);
    if (status == 1) {
      const rrr = res?.result[0];
      setdata(rrr);
      setProducts(res?.result);
      setSetIndex(0);
      setAcceptBondBidId(rrr.accept_bond_bid_id);
      setSetImage(rrr.bond_img_video[rrr.bond_img_video.length - 1].img_video);
      setSetName(rrr.product_name);
      setSetImage2(rrr.bond_img_video[rrr.bond_img_video.length - 1].img_video);
      setOwnerName(rrr.owner_name);
      setIsNfb(rrr.is_nfb);
      setUniqCode(rrr.uniq_code);
      setIsCollection(1);
      setBondId(rrr.product_id);
      setIsClub(rrr.is_club);
      setGetDate(rrr.created_at);
      setReleaseBondId(rrr.release_bond_id);
      setGetQR(rrr.qr_code);
      setProductValue(rrr.product_value);
      setTime(rrr.time);
      setThemeIndex(rrr.theme_index);
      setUsername(rrr.username);
      setDes(rrr.desc);
      setGetQRString(rrr.qr_string);
      setIsNfbType(rrr.is_nfb_type);
    }
  };

  // const renderSelectedCard = () => {
  //   console.log("adsas", themeIndex);

  //   if (themeIndex === 12) {
  //     return (
  //       <Showcardeight
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         userId={userId}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}

  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 11) {
  //     return (
  //       <Showcardsix
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // user_Id={userId}
  //       />
  //     );
  //   } else if (themeIndex === 10) {
  //     return (
  //       <Showcardfive
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // user_Id={userId}
  //       />
  //     );
  //   } else if (themeIndex === 9) {
  //     return (
  //       <Showcardfour
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // user_Id={userId}
  //       />
  //     );
  //   } else if (themeIndex === 8) {
  //     return (
  //       < Showcardthree
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // user_Id={userId}
  //       />
  //     );
  //   } else if (themeIndex === 7) {
  //     return (
  //       <Showcardsecond
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // user_Id={userId}
  //       />
  //     );
  //     } else if (themeIndex === 8) {
  //       return (
  //         <Showcardeight
  //           data={getdata}
  //           icon={setimage}
  //           onBondLikeSuccess={refreshData}
  //           location={location.state}
  //           setIsModalOpen={setIsModalOpen}
  //           setMsg={setMsg}
  //           setModalName={setModalName}
  //           // user_Id={userId}
  //         />
  //     );
  //   } else {
  //     return (
  //       <Showcard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         location={location.state}
  //         userId={userId}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //       />
  //     );
  //   }
  // };

  const renderSelectedCard = () => {
    // if (themeIndex === 6) {
    //   return (
    //     <Showcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       userId={userId}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 7) {
    //   return (
    //     <Showcardsecond
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 8) {
    //   return (
    //     <Showcardfour
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 9) {
    //   return (
    //     <Showcardfive
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 10) {
    //   return (
    //     <Showcardsix
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 11) {
    //   return (
    //     <Showcardseven
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 12) {
    //   return (
    //     <Showcardeight
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 13) {
    //   return (
    //     <Showcard13
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 14) {
    //   return (
    //     <ShowCard14
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else if (themeIndex === 15) {
    //   return (
    //     <ShowCard15
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       location={location.state}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // user_Id={userId}
    //     />
    //   );
    // } else
    if (themeIndex === 16) {
      return (
        <ShowcaseNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}

          // user_Id={userId}
        />
      );
    } else if (themeIndex === 17) {
      return (
        <ShowcaseNewFrame2
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 18) {
      return (
        <ShowcaseNewFrame3
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 19) {
      return (
        <ShowcaseNewFrame4
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 20) {
      return (
        <ShowcaseNewFrame5
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 21) {
      return (
        <ShowcaseNewFrame6
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 22) {
      return (
        <ShowcaseNewFrame7
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 23) {
      return (
        <ShowcaseNewFrame8
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 24) {
      return (
        <ShowcaseNewFrame9
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 25) {
      return (
        <ShowcaseNewFrame10
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 0) {
      return (
        <ShowcaseNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          location={location.state}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else {
      <ShowcaseNewFrame1
        data={getdata}
        icon={setimage}
        onBondLikeSuccess={refreshData}
        location={location.state}
        setIsModalOpen={setIsModalOpen}
        setMsg={setMsg}
        setModalName={setModalName}
        windowWidth={windowWidth}
      />;
    }
  };

  // const getData = () => {
  //   setIsLoading(true);
  //   setProducts([]);

  //   const param = {
  //     user_id: userId,
  //   };

  //   axios
  //     .post(
  //       "https://www.ravi.host/api/my_collection_get_user_accepted_bond",
  //       QueryString.stringify(param)
  //     )
  //     .then((Response) => {
  //       setIsLoading(false);
  //       if (Response.data.status === 1) {
  //         const result = Response.data.result[0];
  //         setdata(result);
  //         setProducts(Response.data.result);
  //         setSetIndex(0);
  //         setAcceptBondBidId(result.accept_bond_bid_id);
  //         setSetImage(result.bond_img_video[0].img_video);
  //         setSetName(result.product_name);
  //         setSetImage2(result.bond_img_video[0].img_video);
  //         setOwnerName(result.owner_name);
  //         setIsNfb(result.is_nfb);
  //         setUniqCode(result.uniq_code);
  //         setIsCollection(1);
  //         setBondId(result.product_id);
  //         setIsClub(result.is_club);
  //         setGetDate(result.created_at);
  //         setReleaseBondId(result.release_bond_id);
  //         setGetQR(result.qr_code);
  //         setProductValue(result.product_value);
  //         setTime(result.time);
  //         setThemeIndex(result.theme_index);
  //         setUsername(result.username);
  //         setDes(result.desc);
  //         setGetQRString(result.qr_string);
  //         setIsNfbType(result.is_nfb_type);
  //       } else {
  //         // Handle the case when the status is not 1
  //         // You can set a state variable or perform other actions as needed
  //         // For example, set a message state variable
  //         // setMessage(Response.data.message);
  //       }
  //       console.log("Response", Response.data);
  //     });
  // };

  const Bond_like = () => {
    const param = {
      user_id: userId,
      bond_id: bondId,
      is_nfb: isNfb,
      is_club: isClub,
      uniq_code: uniqCode,
      time: time,
      release_bond_id: acceptBondBidId,
      is_nfb_type: isNfbType,
    };
    console.log("====================================");
    console.log("param---->", param);
    console.log("====================================");
    axios
      .post(
        "https://www.ravi.host/api/user_own_bond_like_unlike",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          // getData();
          setSetIndex(setindex);
        } else {
          // getData();
        }
      });
  };

  //disable body scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  useEffect(() => {
    if (ismodalopen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalopen, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };
  return (
    <div className="showcase" style={{ minHeight: "900px" }}>
      {collection_loading == true ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <>
          <>
            <div className="page_showcase_wrapp">
              <div className="nav_back_wrapp">
                {/* <img
                  src={images.back}
                  className="nav_back_btn"
                  alt=""
                  onClick={() => window.history.back()} // Use window.history.back() for navigation
                /> */}
                <FaAngleLeft
                  onClick={() => window.history.back()}
                  color="var(--color-white)"
                  size={20}
                />
              </div>
              <p className="showcase_name">Showcase</p>
              <Link to={"/mynfb"}>
                <img src={images.logo} className="Showcase_link_img" alt="" />
              </Link>
            </div>
            {products == 0 ? (
              <p className="show_P" style={{ textAlign: "center" }}>
                You don't have any Rarerez in showcase, Please check in your
                collection.
              </p>
            ) : (
              <div className="showcase_details" style={{ minHeight: "120vh" }}>
                {renderSelectedCard()}
                <br />
                <div
                  className="chnages_btn"
                  style={{
                    //  marginTop: "2rem"
                    marginTop: windowWidth > 786 ? "60px" : "40px",
                    marginBottom: "10px",
                  }}
                >
                  {products && products.length > 0
                    ? products.map((item, index) => {
                        return (
                          <img
                            src={
                              item.bond_img_video[
                                item.bond_img_video.length - 1
                              ].img_video
                            }
                            className={
                              setindex == index
                                ? "chnages_img_btn"
                                : "chnages_img_btn_border"
                            }
                            alt=""
                            onClick={() => {
                              setdata(item);
                              // console.log(item.theme_index);
                              setIsCollection(1);
                              setSetIndex(index);
                              setSetImage(
                                item.bond_img_video[
                                  item.bond_img_video.length - 1
                                ].img_video
                              );
                              setSetImage2(
                                item.bond_img_video[
                                  item.bond_img_video.length - 1
                                ].img_video
                              );
                              setOwnerName(item.owner_name);
                              setIsNfb(item.is_nfb);
                              setUniqCode(item.uniq_code);
                              setBondId(item.product_id);
                              setIsClub(item.is_club);
                              setGetDate(item.created_at);
                              setReleaseBondId(item.release_bond_id);
                              setGetQR(item.qr_code);
                              setAcceptBondBidId(item.accept_bond_bid_id);
                              setamount(item.bid_amount);
                              setTime(item.time);
                              setThemeIndex(item.theme_index);
                              setIsNfbType(item.is_nfb_type);
                              setGetQRString(item.qr_string);
                              handleCardChange(1);
                            }}
                          />
                        );
                      })
                    : null}

                  {/* <img
          src={images.media2}
          className="chnages_img_btn"
          alt=""
          onClick={() => handleCardChange(2)}
        />
        <img
          src={images.media3}
          className="chnages_img_btn"
          alt=""
          onClick={() => handleCardChange(3)}
        /> */}
                </div>
              </div>
            )}
            <div className="page_showcase_wrapp">
              <NewFooter getlocation={location.pathname} />
            </div>
          </>
        </>
      )}
      {/* <Footer></Footer> */}
      {/* <Footer getlocation={location.pathname} /> */}

      {ismodalopen && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
          /> */}

          {/* <NewModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default Showcase;
