import React, { useState, useEffect } from "react";
import "./purchasednfbs.css"; // Make sure to import your CSS file
import images from "../../constants/images"; // Make sure to import your image paths
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../common/footer/Footer";

import qs from "qs";
import axios from "axios";
import { useHomeContext } from "../../context/home_context";
import Purchaseshowcard from "./Purchaseshowcard";
import Purchasesecound from "./Purchasesecoundcard";
import Purchasethreecard from "./Purchasethreecard";
import NavbarSec from "../../common/NavbarSec";
import Purchasefourcard from "./Purchasefourcard";
import Purchasefivecard from "./Purchasefivecard";
import Purchasesixcard from "./Purchasesixcard";
import Purchasesevencard from "./Purchasesevencard";
import Purchaseeightcard from "./Purchaseeightcard";
import AlertModal from "../../components/AlertModal";
import Purchasecard13 from "./Purchasecard13";
import Purchasecard14 from "./Purchasecard14";
import Purchasecard15 from "./Purchasecard15";
import NewModal from "../../common/newmodal/NewModal";
import PurchaseNewFrame1 from "../../frames/purchasedframes/PurchaseNewFrame1";
import PurchaseNewFrame2 from "../../frames/purchasedframes/PurchaseNewFrame2";
import PurchaseNewFrame3 from "../../frames/purchasedframes/PurchaseNewFrame3";
import PurchaseNewFrame4 from "../../frames/purchasedframes/PurchaseNewFrame4";
import PurchaseNewFrame5 from "../../frames/purchasedframes/PurchaseNewFrame5";
import PurchaseNewFrame6 from "../../frames/purchasedframes/PurchaseNewFrame6";
import PurchaseNewFrame7 from "../../frames/purchasedframes/PurchaseNewFrame7";
import PurchaseNewFrame8 from "../../frames/purchasedframes/PurchaseNewFrame8";
import PurchaseNewFrame9 from "../../frames/purchasedframes/PurchaseNewFrame9";
import PurchaseNewFrame10 from "../../frames/purchasedframes/PurchaseNewFrame10";
import V2Modal from "../../common/v2modal/V2Modal";
import NewFooter from "../../common/newfooter/NewFooter";
import { FaAngleLeft } from "react-icons/fa";

const Purchasednfbs = () => {
  const { Mycollectionlike, collection_data, collection_loading } =
    useHomeContext();
  const [selectedCard, setSelectedCard] = useState(1); // Default to the first card

  const handleCardChange = (cardNumber) => {
    setSelectedCard(cardNumber);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderSelectedCard = () => {
    // if (themeIndex === 6) {
    //   return (
    //     // <PurchaseNewFrame1
    //     <Purchaseshowcard
    //       // data={getdata}
    //       // icon={setimage}
    //       // handleData={handleData}
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //     // <Purchasesixcard
    //     //   data={getdata}
    //     //   icon={setimage}
    //     //   onBondLikeSuccess={refreshData}
    //     //   handleData={handleData}
    //     //   setIsModalOpen={setIsModalOpen}
    //     //   setMsg={setMsg}
    //     //   setModalName={setModalName}
    //     //   // setislikes={setislikes}
    //     // />
    //   );
    // } else if (themeIndex === 7) {
    //   return (
    //     // <Purchasefourcard
    //     <Purchasesecound
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // data={getdata}
    //       // icon={setimage}
    //       // onBondLikeSuccess={refreshData}
    //       // handleData={handleData}
    //       // setIsModalOpen={setIsModalOpen}
    //       // setMsg={setMsg}
    //       // setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 8) {
    //   return (
    //     // <Purchaseshowcard
    //     <Purchasefourcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 9) {
    //   return (
    //     // <Purchasesecound
    //     //   data={getdata}
    //     //   icon={setimage}
    //     //   onBondLikeSuccess={refreshData}
    //     //   handleData={handleData}
    //     //   setIsModalOpen={setIsModalOpen}
    //     //   setMsg={setMsg}
    //     //   setModalName={setModalName}
    //     //   // setislikes={setislikes}
    //     // />
    //     <Purchasefivecard
    //       // <Purchaseshowcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 10) {
    //   return (
    //     <Purchasesixcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 11) {
    //   return (
    //     <Purchasesevencard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //       // setislikes={setislikes}
    //     />
    //   );
    // } else if (themeIndex === 12) {
    //   return (
    //     <Purchaseeightcard
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 13) {
    //   return (
    //     <Purchasecard13
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 14) {
    //   return (
    //     <Purchasecard14
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else if (themeIndex === 15) {
    //   return (
    //     <Purchasecard15
    //       data={getdata}
    //       icon={setimage}
    //       onBondLikeSuccess={refreshData}
    //       handleData={handleData}
    //       setIsModalOpen={setIsModalOpen}
    //       setMsg={setMsg}
    //       setModalName={setModalName}
    //     />
    //   );
    // } else
    if (themeIndex === 16) {
      return (
        <PurchaseNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 17) {
      return (
        <PurchaseNewFrame2
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 18) {
      return (
        <PurchaseNewFrame3
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 19) {
      return (
        <PurchaseNewFrame4
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 20) {
      return (
        <PurchaseNewFrame5
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 21) {
      return (
        <PurchaseNewFrame6
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 22) {
      return (
        <PurchaseNewFrame7
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 23) {
      return (
        <PurchaseNewFrame8
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 24) {
      return (
        <PurchaseNewFrame9
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else if (themeIndex === 25) {
      return (
        <PurchaseNewFrame10
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}
        />
      );
    } else {
      return (
        // <Purchasesevencard
        //   data={getdata}
        //   icon={setimage}
        //   onBondLikeSuccess={refreshData}
        //   handleData={handleData}
        //   setIsModalOpen={setIsModalOpen}
        //   setMsg={setMsg}
        //   setModalName={setModalName}
        // />
        <PurchaseNewFrame1
          data={getdata}
          icon={setimage}
          onBondLikeSuccess={refreshData}
          handleData={handleData}
          setIsModalOpen={setIsModalOpen}
          setMsg={setMsg}
          setModalName={setModalName}
          windowWidth={windowWidth}

          // setislikes={setislikes}
        />
      );
    }
  };

  // const renderSelectedCard = () => {
  //   console.log("adsas", themeIndex);
  //   if (themeIndex === 11) {
  //     return (
  //       <Purchasesevencard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 12) {
  //     return (
  //       <Purchasesixcard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 10) {
  //     return (
  //       <Purchasefivecard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 9) {
  //     return (
  //       <Purchasefourcard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         setIsModalOpen={setIsModalOpen}
  //         setMsg={setMsg}
  //         setModalName={setModalName}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 8) {
  //     return (
  //       <Purchasethreecard
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 7) {
  //     return (
  //       <Purchasesecound
  //         data={getdata}
  //         icon={setimage}
  //         onBondLikeSuccess={refreshData}
  //         handleData={handleData}
  //         // setislikes={setislikes}
  //       />
  //     );
  //   } else if (themeIndex === 6) {
  //     return (
  //       <Purchaseshowcard
  //         data={getdata}
  //         icon={setimage}
  //         handleData={handleData}
  //       />
  //     );
  //   } else {
  //     return (
  //       <Purchaseeightcard
  //         data={getdata}
  //         icon={setimage}
  //         handleData={handleData}
  //       />
  //     );
  //   }
  // };

  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [setindex, setSetIndex] = useState(0);
  const [acceptBondBidId, setAcceptBondBidId] = useState("");
  const [setimage, setSetImage] = useState("");
  const [setname, setSetName] = useState("");
  const [setimage2, setSetImage2] = useState("");
  const [ownername, setOwnerName] = useState("");
  const [isNfb, setIsNfb] = useState("");
  const [uniqCode, setUniqCode] = useState("");
  const [isCollection, setIsCollection] = useState(1);
  const [bondId, setBondId] = useState("");
  const [isClub, setIsClub] = useState("");
  const [getdate, setGetDate] = useState("");
  const [releaseBondId, setReleaseBondId] = useState("");
  const [getqr, setGetQR] = useState("");
  const [productValue, setProductValue] = useState("");
  const [time, setTime] = useState("");
  const [themeIndex, setThemeIndex] = useState("");
  const [username, setUsername] = useState("");
  const [des, setDes] = useState("");
  const [getqrString, setGetQRString] = useState("");
  const [isNfbType, setIsNfbType] = useState("");
  const userId = localStorage.getItem("user_id");
  const [getamount, setamount] = useState("");
  const [getdata, setdata] = useState([]);
  const [islikes, setislikes] = useState(0);

  const refreshData = () => {
    const params = {
      user_id: userId,
    };
    // Call your data retrieval function here, e.g., Mycollectionlike(params)
    Mycollectionlike(params);
  };

  useEffect(() => {
    // fff();
    getData();
    // console.log("islikes", islikes);
  }, []);

  const handleData = () => {
    getData();
  };

  const fff = async () => {
    const params = {
      user_id: userId,
    };
    const res = await Mycollectionlike(params);
    const status = res.status;
    console.log("sdcaccadcs", products);
    // const result = await collection_data[0];
    // console.log("result", res.result[0]);
    if (status == 1) {
      const rrr = res?.result[0];
      setdata(rrr);
      setProducts(res?.result);
      setSetIndex(0);
      setAcceptBondBidId(rrr.accept_bond_bid_id);
      // setSetImage(rrr.bond_img_video[0].img_video);
      setSetImage(rrr.bond_img_video[rrr.bond_img_video.length - 1]?.img_video);
      setSetName(rrr.product_name);
      setSetImage2(
        rrr.bond_img_video[rrr.bond_img_video.length - 1]?.img_video
      );
      setOwnerName(rrr.owner_name);
      setIsNfb(rrr.is_nfb);
      setUniqCode(rrr.uniq_code);
      setIsCollection(1);
      setBondId(rrr.product_id);
      setIsClub(rrr.is_club);
      setGetDate(rrr.created_at);
      setReleaseBondId(rrr.release_bond_id);
      setGetQR(rrr.qr_code);
      setProductValue(rrr.product_value);
      setTime(rrr.time);
      setThemeIndex(rrr.theme_index);
      setUsername(rrr.username);
      setDes(rrr.desc);
      setGetQRString(rrr.qr_string);
      setIsNfbType(rrr.is_nfb_type);
    }
  };

  const getData = () => {
    setIsLoading(true);
    setProducts([]);

    const param = {
      user_id: userId,
    };

    axios
      .post(
        "https://www.ravi.host/api/get_user_accepted_bond",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status === 1) {
          const result = Response.data.result[0];
          setdata(result);
          setProducts(Response.data.result);
          setSetIndex(0);
          setAcceptBondBidId(result.accept_bond_bid_id);
          setSetImage(
            result.bond_img_video[result.bond_img_video.length - 1]?.img_video
          );
          setSetName(result.product_name);
          setSetImage2(result.bond_img_video[0].img_video);
          setOwnerName(result.owner_name);
          setIsNfb(result.is_nfb);
          setUniqCode(result.uniq_code);
          setIsCollection(1);
          setBondId(result.product_id);
          setIsClub(result.is_club);
          setGetDate(result.created_at);
          setReleaseBondId(result.release_bond_id);
          setGetQR(result.qr_code);
          setProductValue(result.product_value);
          setTime(result.time);
          setThemeIndex(result.theme_index);
          setUsername(result.username);
          setDes(result.desc);
          setGetQRString(result.qr_string);
          setIsNfbType(result.is_nfb_type);
        } else {
          // Handle the case when the status is not 1
          // You can set a state variable or perform other actions as needed
          // For example, set a message state variable
          // setMessage(Response.data.message);
        }
        console.log("Response", Response.data);
      });
  };

  const Bond_like = () => {
    const param = {
      user_id: userId,
      bond_id: bondId,
      is_nfb: isNfb,
      is_club: isClub,
      uniq_code: uniqCode,
      time: time,
      release_bond_id: acceptBondBidId,
      is_nfb_type: isNfbType,
    };
    console.log("====================================");
    console.log("param---->", param);
    console.log("====================================");
    axios
      .post(
        "https://www.ravi.host/api/user_own_bond_like_unlike",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          // getData();
          setSetIndex(setindex);
        } else {
          // getData();
        }
      });
  };

  //disable body scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  useEffect(() => {
    if (ismodalopen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalopen, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="showcase" style={{ minHeight: "900px" }}>
      {collection_loading == true ? (
        <div className="loader"></div>
      ) : (
        <>
          <>
            <div className="page_main_wrapp_nw reletive_position">
              <div
                className="page_base_wrapp_2"
                style={{ marginBottom: "10px", minHeight: "0vh" }}
              >
                {/* <NavbarSec name="Purchased Rarerez" /> */}
                <div
                  className="legacy_dashbord_nav_sec"
                  style={{ margin: "10px auto" }}
                >
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </button>
                  <p>Purchased Rarerez</p>
                  <div></div>
                  {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
                </div>
              </div>
            </div>

            {products == 0 ? (
              <p
                className="show_p"
                style={{
                  textAlign: "center",
                  width: "90%",
                  margin: "10px auto",
                }}
              >
                You currently don't have any purchased rare items. Place a bid
                to acquire your favorite rarities and explore more.
              </p>
            ) : (
              <div className="showcase_details" style={{ maxWidth: "100vw" }}>
                {renderSelectedCard()}
                {/* <div
                  style={{
                    width: "100%",
                    height: "100px",
                    border: "1px solid red",
                  }}
                ></div> */}
                <br />
                <div
                  className="chnages_btn"
                  style={{ marginTop: windowWidth > 786 ? "60px" : "40px" }}
                >
                  {products && products.length > 0
                    ? products.map((item, index) => {
                        return (
                          <img
                            src={item.bond_img_video[0].img_video}
                            className={
                              setindex == index
                                ? "chnages_img_btn"
                                : "chnages_img_btn_border"
                            }
                            alt=""
                            onClick={() => {
                              setdata(item);
                              console.log(item.theme_index);
                              setIsCollection(1);
                              setSetIndex(index);
                              setSetImage(
                                item.bond_img_video[
                                  item.bond_img_video.length - 1
                                ]?.img_video
                              );
                              setSetImage2(item.bond_img_video[0].img_video);
                              setOwnerName(item.owner_name);
                              setIsNfb(item.is_nfb);
                              setUniqCode(item.uniq_code);
                              setBondId(item.product_id);
                              setIsClub(item.is_club);
                              setGetDate(item.created_at);
                              setReleaseBondId(item.release_bond_id);
                              setGetQR(item.qr_code);
                              setAcceptBondBidId(item.accept_bond_bid_id);
                              setamount(item.bid_amount);
                              setTime(item.time);
                              setThemeIndex(item.theme_index);
                              setIsNfbType(item.is_nfb_type);
                              setGetQRString(item.qr_string);
                              handleCardChange(1);
                            }}
                          />
                        );
                      })
                    : null}

                  {/* <img
          src={images.media2}
          className="chnages_img_btn"
          alt=""
          onClick={() => handleCardChange(2)}
        />
        <img
          src={images.media3}
          className="chnages_img_btn"
          alt=""
          onClick={() => handleCardChange(3)}
        /> */}
                </div>
              </div>
            )}
          </>
        </>
      )}
      {/* <Footer></Footer> */}
      <NewFooter />

      {ismodalopen && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={getmsg}
            setShowModal={setIsModalOpen}
          /> */}
          {/* <NewModal
            title=""
            msg={getmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getmsg}
            setShowModal={setIsModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default Purchasednfbs;
