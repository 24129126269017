import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import AlertModal from "../components/AlertModal";

const SocialSignUp = () => {
  const [isWorkEmail, setIsWorkEmail] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://otpless.com/auth.js";
    script.id = "SZAF3OECKLEAZJUYC4G5D8TMXAZLOKKQ";
    document.body.appendChild(script);
    window.otpless = (data) => {
      // alert(JSON.stringify(data));
      console.log("data", data);
      // Your code here
      // navigate("/dashboard");
      let message = "";
      if (data === null || data === null) {
        message = data.errorMessage;
        console.log("====================================");
        console.log("me", message);
        console.log("====================================");
      } else {
        message = "token: ${data.token}";

        const email = data.email?.email;
        const emailName = data.email?.name;

        const mobileNumber = data.mobile.number;
        const mobileName = data.mobile.name;
        console.log("data--->", data);
        console.log("email me", email);
        console.log("emailName me", emailName);
        console.log("mobileNumber me", mobileNumber);
        console.log("mobileName me", mobileName);

        const param = {
          email_name: emailName,
          email: email,
          phone: mobileNumber.substring(2),
          otpless_token: data.token,
        };

        console.log("====================================");
        console.log("form", param);
        console.log("====================================");
        axios
          .post("https://www.ravi.host/api/user_otpless", qs.stringify(param))
          .then((Response) => {
            console.log("====================================");
            console.log("Response", Response.data);
            console.log("status", Response.data.status);
            console.log("====================================");
            if (Response.data.status == 1) {
              //  this.props.navigation.navigate("Register", {
              //    email: email,
              //    emailName: emailName,
              //    mobileNumber: mobileNumber,
              //    mobileName: mobileName,
              //    user_id: Response.data.user_id,
              //  });
              navigate("/signup", {
                state: {
                  email: email,
                  emailName: emailName,
                  mobileNumber: mobileNumber,
                  mobileName: mobileName,
                  user_id: Response.data.user_id,
                },
              });
            } else if (Response.data.status == 2) {
              //  this.props.navigation.navigate("Register", {
              //    email: email,
              //    emailName: emailName,
              //    mobileNumber: mobileNumber,
              //    mobileName: mobileName,
              //    user_id: Response.data.user_id,
              //  });
              navigate("/signup", {
                state: {
                  email: email,
                  emailName: emailName,
                  mobileNumber: mobileNumber,
                  mobileName: mobileName,
                  user_id: Response.data.user_id,
                },
              });
            } else if (Response.data.status == 3) {
              //  this.props.navigation.navigate("AdharCard", {
              //    user_id: Response.data.user_id,
              //  });aadharcard
              navigate("/aadharcard", {
                state: {
                  user_id: Response.data.user_id,
                },
              });
            } else if (Response.data.status == 4) {
              // this.props.navigation.replace("/AutherVideo", {
              //   user_id: Response.data.user_id,
              // });
              navigate("/AutherVideo", {
                state: {
                  user_id: Response.data.user_id,
                },
              });
            } else if (Response.data.status == 5) {
              // this.props.navigation.replace("/Intrests", {
              //   user_id: Response.data.user_id,
              // });
              navigate("/Intrests", {
                state: {
                  user_id: Response.data.user_id,
                },
              });
            } else if (Response.data.status == 6) {
              //  this.setState({
              //    isLoading: true,
              //  });
              setIsLoading(true);
              const param1 = {
                user_id: Response.data.user_id,
              };
              let demo = Response.data.user_id;
              console.log("====================================");
              console.log("param", param1);
              console.log("====================================");

              axios
                .post(
                  "https://www.ravi.host/api/edit_profile",
                  qs.stringify(param1)
                )
                .then((Response) => {
                  //  this.setState({
                  //    isLoading: false,
                  //  });
                  setIsLoading(false);

                  console.log(
                    "----",
                    JSON.stringify(Response.data.result, null, 2)
                  );

                  if (Response.data.status == 1) {
                    // this.setState({
                    //   name: Response.data.result.firstname,
                    //   username: Response.data.result.username,
                    //   profile: Response.data.result.profile,
                    //   bio: Response.data.result.bio,
                    //   number: Response.data.result.phone,
                    //   email: Response.data.result.email,
                    //   managerdata: Response.data.result.manager_data,
                    //   uploaded_video: Response.data.result.user_videos,
                    //   user_story: Response.data.result.user_story,
                    //   cover: Response.data.result.user_cover_photo,
                    // });
                    // console.log('----', JSON.stringify(Response.data.result, null, 2));

                    //  this.props.addUserId(demo);
                    //  this.props.addEmail(Response.data.email);
                    //  this.props.addPhoneNumber(demo);

                    //  this.props.addUserName(Response.data.username);
                    //  this.props.addProfile(Response.data.profile);

                    //  this.props.navigation.replace("BottomTabNavigator", {
                    //    user_id: demo,
                    //  });

                    localStorage.setItem("is_login", JSON.stringify(true));
                    localStorage.setItem("user_id", demo);
                    localStorage.setItem("email", Response.data.email);
                    localStorage.setItem("username", Response.data.username);
                    localStorage.setItem("profile", Response.data.username);
                    console.log("Response.data", Response.data);
                    navigate("/homescreen2", {
                      state: {
                        user_id: demo,
                      },
                    });
                  } else {
                    // this.setState({
                    //   isModalVisible: true,
                    //   cusmsg: Response.data.message,
                    // });
                    setCusmsg(Response.data.message);
                    setIsModalVisible(true);
                  }
                });
            }
          });

        // todo here
      }
    };
    return () => {
      const divid = document.getElementById("SZAF3OECKLEAZJUYC4G5D8TMXAZLOKKQ");
      // const divid = document.getElementById("otplessIdScript");
      document.body.removeChild(divid);
    };
  }, []);

  const openModal = () => {
    window.otplessInit();
    const modalContainer = document.getElementById("modalContainer");
    const modal = document.getElementById("otpless-login-page");
    modalContainer.style.display = "flex";
    modal.style.display = modal.style.display === "block" ? "none" : "block";
  };

  const closeModal = (e) => {
    const modalContainer = document.getElementById("modalContainer");
    if (e.target === modalContainer) {
      modalContainer.style.display = "none";
    }
  };

  return (
    <>
      {isloading ? (
        <div className="loader"></div>
      ) : (
        <div>
          <div class="modal-container" id="modalContainer" onClick={closeModal}>
            <div class="modal">
              <div id="otpless-login-page"></div>
            </div>
          </div>
          <button id="loginBtn" onClick={openModal}>
            Login
          </button>
          {isModalVisible && (
            <AlertModal
              title=""
              msg={cusmsg}
              setShowModal={setIsModalVisible}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SocialSignUp;
