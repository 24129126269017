import React, { useState, useRef, useEffect } from "react";
import images from "../../constants/images";
import "./ClubOtp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  club_email_verify_and_active_user,
  club_resend_email_otp,
  club_resend_otp,
  club_reset_email_phone_number,
  club_reset_phone_number,
  email_verify_and_active_user,
  user_otp,
  user_resend_email_otp,
  user_resend_otp,
  user_reset_email_phone_number,
  user_reset_phone_number,
  verify_and_active_club,
} from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import ReactModal from "react-modal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const ClubOtp = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmail, setIsEmail] = useState(
    location.state && location.state.email
  );
  const [isEmailedit, setIsEmailEdit] = useState(
    location.state && location.state.email
  );
  const [getphone, setPhone] = useState(location.state.phone);
  const [getphoneedit, setPhoneEdit] = useState(location.state.phone);
  const [getoldOtp, setIsOldOtp] = useState(
    location.state && location.state.user_otp
  );
  const [isLoading, setIsLoading] = useState(false);
  const emailmpin = [useRef(), useRef(), useRef(), useRef()];
  const [getcusmsg, setcusmsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [getnumbervalidation, setNumberValidation] = useState(
    location.state && location.state.status
  );
  const navigate = useNavigate();
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(30);
  };
  const [resendTimer, setResendTimer] = useState(30);
  const [isResendTimerRunning, setIsResendTimerRunning] = useState(false);

  const [showsuccessModal, showSuccessModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditModalemail, setShowEditModalemail] = useState(false);
  const [getcon, setcon] = useState(false);

  const startResendTimer = () => {
    setIsResendTimerRunning(true);
    setResendTimer(30);
  };

  const ResendMobileOtp1 = () => {
    // Place your logic here to initiate OTP resend
    startTimer();
  };

  useEffect(() => {
    if (isResendTimerRunning && resendTimer > 0) {
      const countdown = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isResendTimerRunning && resendTimer === 0) {
      setIsResendTimerRunning(false);
    }
  }, [isResendTimerRunning, resendTimer]);

  useEffect(() => {
    // console.log("location", location.state);

    if (isTimerRunning && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [isTimerRunning, timer]);

  // mobileotp value start
  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setPhoneEdit(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setPhoneEdit(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  useEffect(() => {
    console.log(
      "storeitem",
      localStorage.getItem("is_login"),
      localStorage.getItem("user_id"),
      localStorage.getItem("email"),
      localStorage.getItem("username"),
      localStorage.getItem("profile")
    );
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const otpValue = mobileotp.join("");
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  // mobileotp value end

  // mobileotp api start
  const VerifyOtp = async () => {
    console.log("dcss", otpValue);
    if (!otpValue) {
      // setcusmsg("Please enter your otp");
      // setShowModal(true);
      toast.error("Please enter your otp", { theme: "colored" });
    } else if (otpValue.length < 4) {
      // setcusmsg("Please enter 4 digit otp");
      // setShowModal(true);
      toast.error("Please enter 4 digit otp", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        // mobile_no: getphone,
        user_otp: otpValue,
        club_id: location.state.club_id,
      };
      await axios
        .post(verify_and_active_club, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response);
            setIsMobileVerified(true);
            setIsEmail(Response.data.email);
          } else {
            // alert(Response.data.message);
            // setcusmsg(Response.data.message);
            // setShowModal(true);
            toast.error(Response.data.message, { theme: "colored" });
          }
          // setIsModalOpen2(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("verify_and_active_club err", err);
          setcusmsg(err);
          setShowModal(true);
          // alert("OTP is incrrect please try again");
        });
    }
  };
  // mobileotp api end

  // resend mobileotp api start
  const ResendMobileOtp = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
      club_id: location.state.club_id,
    };
    console.log("success");
    await axios
      .post(club_resend_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.success(Response.data.message, { theme: "colored" });
        } else {
          // alert(Response.data.message);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.error(Response.data.message, { theme: "colored" });
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("club_resend_otp err", err);
        setcusmsg(err);
        setShowModal(true);
      });
  };
  // resend mobileotp api end
  const [toastDisplayed, setToastDisplayed] = useState(false);

  // resend mobileotp api start
  const EditMobileNo = async () => {
    if (getphoneedit == "") {
      toast.error("Please enter a mobile number", { theme: "colored" });
    } else if (getphoneedit.length != 10) {
      toast.error("Please enter a valid mobile number", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        club_id: location.state.club_id,
        phone_number: getphoneedit,
      };
      console.log("user_otp param", param);

      await axios
        .post(club_reset_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Respons1111e", Response.data);
            // alert(Response.data.message);
            handleModalClose();
            setPhone(Response.data.results);
          } else {
            console.log("club_reset_phone_number err", Response.data);
            toast.error(`${Response.data.message}`, { theme: "colored" });
            if (!toastDisplayed) {
              setToastDisplayed(true);
            }
            // alert(Response.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("club_reset_phone_number err", err);
          toast.error(`${err}`, { theme: "colored" });
          // setcusmsg(err);
          // setShowModal(true);
        });
    }
  };
  // resend mobileotp api end

  // ==================== email section ===================== //

  // emailotp api start
  const VerifyEmailOtp = async () => {
    if (!otpValueEmail) {
      // setcusmsg("Please enter your otp");
      // setShowModal(true);
      toast.error("Please enter your otp", { theme: "colored" });
    } else if (otpValueEmail.length < 4) {
      // setcusmsg("Please enter 4 digit otp");
      // setShowModal(true);
      toast.error("Please enter 4 digit otp", { theme: "colored" });
    } else {
      console.log("ascsac", "adcasfasevsf");
      setIsLoading(true);
      const param = {
        // email_otp: otpValueEmail,
        mobile_no: isEmail,
        email_otp: otpValueEmail,
        club_id: location.state.club_id,
      };
      console.log("success");
      await axios
        .post(club_email_verify_and_active_user, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            setcusmsg(Response.data.message);
            showSuccessModal(true);
            console.log(
              "club_email_verify_and_active_user Response",
              Response.data
            );
            //   navigate("/Intrests", {
            //     state: {
            //       user_id: Response.data.user_id,
            //     },
            //   });
            // setIsLoading(false);
            // setIsMobileVerified(true);
          } else {
            console.log("email_verify_and_active_user err", Response.data);
            // alert(Response.data.message);
            // setcusmsg(Response.data.message);
            // setShowModal(true);
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
          // setIsModalOpen2(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("email_verify_and_active_user err", err);
        });
    }
  };
  // emailotp api end

  // resend Emailotp api start
  const ResendEmailOtp = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
      club_id: location.state.club_id,
    };
    console.log("success");
    await axios
      .post(club_resend_email_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startResendTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.success(Response.data.message, { theme: "colored" });
        } else {
          // alert(Response.data.message);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.error(Response.data.message, { theme: "colored" });
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("club_resend_email_otp err", err);
        setcusmsg(err);
        setShowModal(true);
      });
  };
  // resend emailotp api end

  // Edit email api start
  const EditEmail = async () => {
    if (isEmailedit == "") {
      toast.error("Please enter a email address", { theme: "colored" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(isEmailedit)) {
      setIsEmailEdit(null);
      toast.error("Please enter a valid email address", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        email: isEmailedit,
        club_id: location.state.club_id,
      };
      await axios
        .post(club_reset_email_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response.data);
            // alert(Response.data.message);
            setIsEmail(Response.data.results);
            // setcusmsg(Response.data.message);
            // setShowModal(true);
            toast.success(`${Response.data.message}`, { theme: "colored" });

            handleModalClose2();
          } else {
            // alert(Response.data.message);
            // setcusmsg(Response.data.message);
            // setShowModal(true);
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("club_reset_email_phone_number err", err);
          setcusmsg(err);
          setShowModal(true);
        });
    }
  };
  // edit email api end

  // Emailotp value start
  const [emailotp, setEmailOtp] = React.useState(["", "", "", ""]);
  const refsEmail = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeEmail = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...emailotp];
      newOtp[index] = value;
      setEmailOtp(newOtp);

      if (index < refsEmail.length - 1 && value !== "") {
        refsEmail[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownEmail = (index, event) => {
    if (event.key === "Backspace" && index > 0 && emailotp[index] === "") {
      const newOtp = [...emailotp];
      newOtp[index - 1] = "";
      setEmailOtp(newOtp);
      refsEmail[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (refsEmail[0].current) {
      refsEmail[0].current.focus();
    }
  }, []);
  const otpValueEmail = emailotp.join("");
  // Emailotp value end

  const handleMobileVerification = () => {
    setIsMobileVerified(true);
  };

  // modal mobile
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };
  // email modal
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleModalOpen2 = () => {
    setIsModalOpen2(true);
  };

  const handleModalClose2 = () => {
    setShowEditModalemail(false);
  };

  const closemodal1 = () => {
    showSuccessModal(false);
  };

  const closemodal = () => {
    setShowModal(false);
  };

  const openmodal = () => {
    setShowModal(true);
  };

  const formatPhoneNumber = (inputValue) => {
    // Remove any existing spaces
    let formattedText = inputValue.replace(/ /g, "");

    // console.log("formattedText", formattedText);

    if (formattedText.length > 10) {
      formattedText = formattedText.slice(0, 10); // Limit the input to 12 characters
    }

    formattedText = formattedText.replace(/\s+/g, " ");
    setPhoneEdit(formattedText);
    const tdata = formattedText.replace(/ /g, "");

    // console.log("formattedText", tdata);
    return formattedText;
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="Verifyscreen_1"></div>

      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isTimerRunning ? null : (
        <button
          style={{ marginTop: "20px", marginLeft: "20px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaAngleLeft color="var(--color-white)" size={20} />
        </button>
      )}
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={images.new_logo}
              className="play_screen_banner"
              style={{
                height: "64px",
                maxWidth: "200px",
              }}
              alt=""
            />
          </div>
          {getnumbervalidation == 3 ? null : (
            <>
              {/* <div className="otp_demo">
                <h1 className="otp_demo_title">Enter OTP</h1>
                <p style={{ marginTop: "20px" }} className="otp_demo_p">
                  We have sent an OTP to your mobile number
                </p>

                <p
                  className="mobile_verify_number"
                  onClick={isMobileVerified == true ? null : handleModalOpen}
                >
                  +91 {getphone}{" "}
                  <img
                    className="verify_edit"
                    src={images.editing_verify}
                    alt=""
                  />
                </p>
              </div> */}
              <div
                className="otp_screen_text_container"
                style={{ margin: "0px", marginTop: "50px" }}
              >
                <h4>Verify OTP</h4>
                <p>
                  We have sent an OTP to your number <br />
                  <span
                    style={{
                      color: "var(--color-main)",
                      fontFamily: "var(--font-600)",
                    }}
                  >
                    +91 {getphone}
                  </span>
                  , please check.
                </p>
              </div>

              {isModalOpen && (
                <div className="modal_overlay">
                  <div className="modal_content_mobile">
                    <input
                      type="number"
                      placeholder="Enter Mobile number"
                      className="mobile_input_modal"
                      // onChange={(e) => setPhone(e.target.value)}
                      // value={getphone}
                      onChange={(e) => handlePhoneNumberChange(e)}
                      value={getphoneedit}
                    />
                    <div className="mobile_modal_btn">
                      <button
                        className="mobile_verify_btn"
                        onClick={handleModalClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="mobile_verify_btn"
                        onClick={() => EditMobileNo()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="new_verify_otp_wrapp ">
                {mobileotp.map((digit, index) => (
                  <input
                    style={{
                      borderWidth: 0.5,
                      // borderColor: isMobileVerified && "green",
                    }}
                    key={index}
                    // disabled={isMobileVerified}
                    type="number"
                    maxLength={1}
                    className="new_verify_otp_inputs"
                    ref={refsMobile[index]}
                    value={digit}
                    onChange={(event) => handleInputChangeMobile(index, event)}
                    onKeyDown={(event) => handleKeyDownMobile(index, event)}
                  />
                ))}
              </div>
              {!isMobileVerified && (
                <div className="monile_div">
                  <button
                    onClick={ResendMobileOtp}
                    className="demo_resend_otp"
                    disabled={isTimerRunning}
                    style={{
                      color: isTimerRunning ? "#fff" : "var(--color-main)",
                      fontFamily: isTimerRunning
                        ? "var(--font-400)"
                        : "var(--font-600)",
                    }}
                  >
                    {isTimerRunning ? `Resent in ${timer}s` : "RESENT OTP"}
                  </button>
                  <br />
                  <button
                    style={{ width: "40%", height: "50px" }}
                    className="new_btn_wrapp mx-1"
                    onClick={VerifyOtp}
                  >
                    <p>Verify</p>
                  </button>
                </div>
              )}
              {isMobileVerified == true || getnumbervalidation == 3 ? null : (
                <div
                  style={{
                    justifyContent: "flex-start",
                    width: "90%",
                    margin: "0px auto",
                  }}
                  className="new_verify_otp_down_sec"
                >
                  <div>
                    <p>
                      Want to{" "}
                      <button
                        onClick={() => {
                          setShowEditModal(true);
                        }}
                      >
                        edit mobile
                      </button>{" "}
                      number?
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
          {isMobileVerified == true || getnumbervalidation == 3 ? (
            <div className="monile_div_club">
              {/* <div className="otp_demo">
                <p className="otp_demo_p">
                 
                  We have sent an OTP to your email address (please check your
                  spam folder)
                </p>
                <p className="mobile_verify_number" onClick={handleModalOpen2}>
                  {isEmail}{" "}
                  <img
                    className="verify_edit"
                    src={images.editing_verify}
                    alt=""
                  />
                </p>
                {isModalOpen2 && (
                  <div className="modal_overlay">
                    <div className="modal_content_mobile">
                      <input
                        type="email"
                        // readOnly={isModalOpen2}
                        placeholder="Enter Your email address"
                        className="email_input_modal"
                        value={isEmailedit}
                        onChange={(e) => setIsEmailEdit(e.target.value)}
                      />
                      <div className="mobile_modal_btn">
                        <button
                          className="mobile_verify_btn"
                          onClick={handleModalClose2}
                        >
                          Cancel
                        </button>
                        <button
                          className="mobile_verify_btn"
                          onClick={() => EditEmail()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
              <div
                className="otp_screen_text_container"
                style={{ margin: "0px" }}
              >
                <p>
                  Please enter the verification code sent to{" "}
                  <span
                    style={{
                      color: "var(--color-main)",
                      fontFamily: "var(--font-600)",
                    }}
                  >
                    {isEmail}{" "}
                  </span>
                  email address (please check your spam folder)
                </p>
              </div>

              {/* <div className="input_otp_new">
                {emailotp.map((digit, index) => (
                  <input
                    key={index}
                    type="number"
                    maxLength={1}
                    className="in_de_otp"
                    ref={refsEmail[index]}
                    value={digit}
                    onChange={(event) => handleInputChangeEmail(index, event)}
                    onKeyDown={(event) => handleKeyDownEmail(index, event)}
                  />
                ))}
              </div> */}
              <div className="new_verify_otp_wrapp ">
                {emailotp.map((digit, index) => (
                  <input
                    style={{
                      borderWidth: 0.5,
                      // borderColor: isMobileVerified && "green",
                    }}
                    key={index}
                    // disabled={isMobileVerified}
                    type="number"
                    maxLength={1}
                    className="new_verify_otp_inputs"
                    ref={refsEmail[index]}
                    value={digit}
                    onChange={(event) => handleInputChangeEmail(index, event)}
                    onKeyDown={(event) => handleKeyDownEmail(index, event)}
                  />
                ))}
              </div>

              {/* <p>{otpValueEmail}</p> */}
              <div className="monile_div">
                <button
                  onClick={ResendEmailOtp}
                  className="demo_resend_otp"
                  disabled={isResendTimerRunning}
                  style={{
                    color: isResendTimerRunning ? "#fff" : "var(--color-main)",
                    fontFamily: isResendTimerRunning
                      ? "var(--font-400)"
                      : "var(--font-600)",
                  }}
                >
                  {isResendTimerRunning
                    ? `Resent in ${resendTimer}s`
                    : "RESENT OTP"}
                </button>
                <br />
                <button
                  style={{
                    width: "40%",
                    height: "50px",
                  }}
                  className="new_btn_wrapp mx-1"
                  onClick={VerifyEmailOtp}
                >
                  <p>Verify</p>
                </button>
              </div>
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "90%",
                  margin: "0px auto",
                }}
                className="new_verify_otp_down_sec"
              >
                <div>
                  <p>
                    Want to{" "}
                    <button
                      onClick={() => {
                        setShowEditModalemail(true);
                      }}
                    >
                      edit email
                    </button>{" "}
                    address?
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {/* custom alert modal start vishal */}
      {showModal && (
        <>
          {/* <AlertModal title="" msg={getcusmsg} setShowModal={setShowModal} /> */}
          {/* <NewModal
            title=""
            msg={getcusmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setShowModal}
            okfunction={closemodal}
          />
        </>
      )}
      {showsuccessModal && (
        // <div className="modal_login" style={{ zIndex: 111 }}>
        //   <div className="modal-content_login" style={{ maxHeight: "60vh" }}>
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="alert_modal_div">
        //       <b
        //         style={{
        //           color: "#fff",
        //           fontFamily: "var(--font-600)",
        //           fontSize: "14px",
        //         }}
        //       >
        //         Success
        //       </b>
        //       <br />
        //       <p
        //         className="alert-modal-message"
        //         style={{
        //           height: "80%",
        //           // border: "1px solid red",
        //           overflowY: "scroll",
        //         }}
        //       >
        //         {" "}
        //         {getcusmsg ? getcusmsg : ""}
        //       </p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           showSuccessModal(false);
        //           navigate("/clubBankaccount", {
        //             state: {
        //               club_id: location.state.club_id,
        //             },
        //           });
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={showSuccessModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel">Success</p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{getcusmsg ? getcusmsg : ""}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         showSuccessModal(false);
        //         navigate("/clubBankaccount", {
        //           state: {
        //             club_id: location.state.club_id,
        //           },
        //         });
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={getcusmsg}
          setShowModal={showSuccessModal}
          okfunction={() => {
            showSuccessModal(false);
            navigate("/clubBankaccount", {
              state: {
                club_id: location.state.club_id,
              },
            });
          }}
        />
      )}
      {showEditModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            {/* {title && <h4 className="v2modal_fixed_heading">{title}</h4>} */}
            <div className="v2modal_fixed_text_wrapp">
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_user_icon} alt="" />
                  <p>Enter Mobile Number</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="number"
                    placeholder="Type here"
                    value={getphoneedit}
                    onChange={(e) => formatPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn2"
                onClick={() => {
                  EditMobileNo();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditModalemail && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowEditModalemail(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            {/* {title && <h4 className="v2modal_fixed_heading">{title}</h4>} */}
            <div className="v2modal_fixed_text_wrapp">
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_email_logo} alt="" />
                  <p>Enter Email Address</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="email"
                    placeholder="Type here"
                    value={isEmailedit}
                    // onChange={(e) => formatPhoneNumber(e.target.value)}
                    onChange={(e) => {
                      const text = e.target.value;
                      if (email_validation.test(text) === false) {
                        setcon(false);
                      } else if (text.length == 0) {
                        setcon(false);
                      } else {
                        setcon(true);
                      }
                      setIsEmailEdit(text);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn2"
                onClick={EditEmail}
                disabled={!getcon}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ClubOtp;
