import React, { useEffect, useRef } from "react";
import "./NewAddharCardOtpScreen.css";
import images from "../../constants/images";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { adhar_submit_otp_v2 } from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewAddharCardOtpScreen = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const navigate = useNavigate();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Array to store OTP digits
  const otpInputs = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [con, setCon] = useState(false);
  const [getUserId, setUserId] = useState("");

  const handleInputChange = (index, event) => {
    const value = event.target.value.replace(/\D/g, "");

    // Check if the entered value is a digit and not empty
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field automatically if the current field is not empty
      if (value !== "" && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && otp[index] === "") {
      // Move back to the previous input field when backspace is pressed on an empty field
      otpInputs.current[index - 1].focus();
    }
  };

  const otpValue = otp.join("");

  const modalFunctions = () => {
    setIsModalVisible(false);
  };
  const updateOtp = () => {
    if (otpValue == "") {
      //   setIsModalVisible(true);
      //   setCusmsg("Enter aadhaar otp");
      toast.error("Enter aadhaar otp", { theme: "colored" });
    } else if (otpValue.length < 6) {
      toast.error("Please enter valid aadhaar otp", { theme: "colored" });
    } else {
      setIsLoading(true);

      const param = {
        adhar_no: location.state.adhar_no,
        transaction_id: location.state.transaction_id,
        otp: otpValue,
        user_id: location.state.user_id,
      };
      console.log("Transaction", param);

      axios
        .post(adhar_submit_otp_v2, qs.stringify(param))
        .then((response) => {
          setIsLoading(false);
          console.log("API Response:", response.data);

          if (response.data.status === 1) {
            // setCon(true);
            // setIsModalVisible(true);
            // setCusmsg(response.data.message);
            toast.success(`${response.data.message}`, { theme: "colored" });
            navigate("/NewAddhharSuccess", {
              state: {
                user_id: response.data.user_id,
                all_data: response.data,
              },
            });
          } else {
            setCon(false);
            toast.error(`${response.data.message}`, { theme: "colored" });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          toast.error(`${error}`, { theme: "colored" });
        });
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "20vh", minHeight: "180px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4>Verify Addhaar OTP</h4>
              <p>
                An OTP sent to your mobile number linked to your
                Aadhaar Card. Please enter the same to verify your profile.
                <p
                  style={{
                    color: "var(--color-main)",
                    fontFamily: "var(--font-600)",
                  }}
                >
                  Please note that we don't save your Aadhaar details.{" "}
                </p>
              </p>
            </div>

            <div className="new_verify_otp_wrapp ">
              {otp.map((digit, index) => (
                <input
                  style={{
                    borderWidth: 0.5,
                    // borderColor: isMobileVerified && "green",
                  }}
                  key={index}
                  // disabled={isMobileVerified}
                  type="number"
                  maxLength={1}
                  className="new_verify_otp_inputs"
                  ref={(input) => (otpInputs.current[index] = input)}
                  value={digit}
                  onChange={(event) => handleInputChange(index, event)}
                  onKeyDown={(event) => handleBackspace(index, event)}
                />
              ))}
            </div>

            <div
              className="new_verify_otp_down_sec"
              style={{ padding: "0px 5%" }}
            >
              {/* btn */}
              <button
                disabled={!otpValue}
                onClick={updateOtp}
                className="new_btn_wrapp mx-1"
              >
                <p>Next</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}

      {/* modal start */}
      {isModalVisible ? (
        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={modalFunctions}
        />
      ) : null}
      {/* modal end */}

      <ToastContainer />
    </div>
  );
};

export default NewAddharCardOtpScreen;
