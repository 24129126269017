import {
  CLUB_JOIN_BEGIN,
  CLUB_JOIN_FAIL,
  CLUB_JOIN_SUCCESS,
  CREATE_CLUB_BEGIN,
  CREATE_CLUB_FAIL,
  CREATE_CLUB_SUCCESS,
  GET_ALL_CLUB_BEGIN,
  GET_ALL_CLUB_DETAIL_BEGIN,
  GET_ALL_CLUB_DETAIL_FAIL,
  GET_ALL_CLUB_DETAIL_SUCCESS,
  GET_ALL_CLUB_FAIL,
  GET_ALL_CLUB_SUCCESS,
  GET_ALL_USER_CLUB_BEGIN,
  GET_ALL_USER_CLUB_FAIL,
  GET_ALL_USER_CLUB_SUCCESS,
} from "../Action";

const nfb_block_club_reducer = (state, action) => {
  //get all club
  if (action.type === GET_ALL_CLUB_BEGIN) {
    return { ...state, get_allclub_loading: true };
  }

  if (action.type === GET_ALL_CLUB_SUCCESS) {
    return {
      ...state,
      get_allclub_loading: false,
      get_allclub_data: action.payload.result,
    };
  }

  if (action.type === GET_ALL_CLUB_FAIL) {
    return { ...state, get_allclub_loading: false };
  }

  // get all club detail

  if (action.type === GET_ALL_CLUB_DETAIL_BEGIN) {
    return { ...state, get_allclub_detail_loading: true };
  }

  if (action.type === GET_ALL_CLUB_DETAIL_SUCCESS) {
    return {
      ...state,
      get_allclub_detail_loading: false,
      get_allclub_detail_data: action.payload.result,
    };
  }

  if (action.type === GET_ALL_CLUB_DETAIL_FAIL) {
    return { ...state, get_allclub_detail_loading: false };
  }

  // get_all_user_club

  if (action.type === GET_ALL_USER_CLUB_BEGIN) {
    return { ...state, get_all_user_club_loading: true };
  }

  if (action.type === GET_ALL_USER_CLUB_SUCCESS) {
    return {
      ...state,
      get_all_user_club_loading: false,
      get_all_user_club_data: action.payload.result,
    };
  }

  if (action.type === GET_ALL_USER_CLUB_FAIL) {
    return { ...state, get_all_user_club_loading: false };
  }

  // create_club

  if (action.type === CREATE_CLUB_BEGIN) {
    return { ...state, create_club_loading: true };
  }

  if (action.type === CREATE_CLUB_SUCCESS) {
    return {
      ...state,
      create_club_loading: false,
      create_club_data: action.payload.result,
    };
  }

  if (action.type === CREATE_CLUB_FAIL) {
    return { ...state, create_club_loading: false };
  }

  // club_join

  if (action.type === CLUB_JOIN_BEGIN) {
    return { ...state, club_join_loading: true };
  }

  if (action.type === CLUB_JOIN_SUCCESS) {
    return {
      ...state,
      club_join_loading: false,
    };
  }

  if (action.type === CLUB_JOIN_FAIL) {
    return { ...state, club_join_loading: false };
  }
};

export default nfb_block_club_reducer;
