import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./wishlist.css";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { delete_whishlist, get_user_whishlist } from "../../utils/Constant";
import axios from "axios";
import NavbarSec from "../../common/NavbarSec";
import { FaAngleLeft } from "react-icons/fa";
import NewLoader from "../../common/newloder/NewLoader";

const Wishlist = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const removeItemFromWishlist = (item) => {
    // Implement your logic to remove the item from the wishlist.
    // You can update your wishlist state or perform an API call here.
    console.log("Removing item:", item);
  };
  const wishlistData = [
    // Replace this with your actual wishlist data
    {
      name: "Henti Matisse",
      club: "Rarerez Club",
      value: 350000,
      image: images.media1,
    },
    {
      name: "Henti Matisse",
      club: "Rarerez Club",
      value: 350000,
      image: images.media1,
    },
    // Add more wishlist items as needed
  ];

  const [getdata, setdata] = useState([]);
  const [getloading, setloading] = useState(false);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    getReleasebond();
  }, []);

  const getReleasebond = () => {
    setloading(true);
    const param = {
      user_id: userId,
    };
    console.log(param);
    axios
      .post(get_user_whishlist, param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          setloading(false);
          setdata(Response.data.result);
        } else {
          setloading(false);
          setdata([]);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const _deletewishlist = (whishlist_id) => {
    setloading(true);
    const param = {
      whishlist_id: whishlist_id,
    };
    console.log("removeparam", param);
    axios
      .post(delete_whishlist, param)
      .then((Response) => {
        // this.setState({
        //   isLoading: false,
        // });

        console.log("====================================");
        console.log("Respons", JSON.stringify(Response.data, null, 2));
        console.log("====================================");
        if (Response.data.status == 1) {
          setloading(false);

          getReleasebond();
          // this.props.navigation.navigate('Wishlist')
        } else {
          getReleasebond();
        }
      })
      .catch((err) => {
        setloading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 100, behavior: "smooth" });
  }, []);

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {getloading ? (
        <NewLoader />
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <div
              style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
            >
              {/* <NavbarSec name="Wishlist" /> */}
              <div
                className="legacy_dashbord_nav_sec"
                style={{ margin: "10px auto" }}
              >
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Wishlist</p>
                <div></div>
              </div>
            </div>

            {/* <div className="wishlist_club_user">
              {getdata && getdata.length > 0 ? (
                getdata.map((item, index) => (
                  <Link
                    to={"/productview"}
                    state={{
                      release_bond_id: item.release_bond_id,
                      product_id: item.product_id,
                      is_nfb: item.is_nfb,
                      uniq_code: item.uniq_code,
                      theme_index: item.theme_index,
                      share_by_user: item.share_by_user,
                      club_id: item.club_id,
                    }}
                    className="wishlist_list"
                    key={index}
                  >
                    <div className="wishlist_list2">
                      <div className="wishlist_list_first">
                        <img
                          src={item.bond_img_video[0].img_video}
                          className="wishlist_1_img"
                          alt=""
                        />
                      </div>
                      <div className="wishlist_list_second">
                        <h1 className="h2_wishlist"> {item.product_name}</h1>
                        <h1 className="h2_wishlist">{item.owner_name}</h1>
                        <hr />
                        <p className="wish_value1">Asset Value:</p>
                        <p className="wish_value">{item.product_value}</p>
                        <img
                          src={images.delete2}
                          className="wishlist_2_img"
                          alt=""
                          onClick={() => {
                            _deletewishlist(item.whishlist_id);
                          }}
                        />
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      fontFamily: "var(--font-600)",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    No Items found
                  </p>
                </div>
              )}
            </div> */}
            {getdata && getdata.length == 0 ? (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "60px",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                No item found.
              </p>
            ) : null}
            <div className="resellnfb_content">
              {getdata && getdata.length > 0
                ? getdata.map((item, index) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            navigate("/productview", {
                              state: {
                                release_bond_id: item.release_bond_id,
                                product_id: item.product_id,
                                is_nfb: item.is_nfb,
                                uniq_code: item.uniq_code,
                                theme_index: item.theme_index,
                                share_by_user: item.share_by_user,
                                club_id: item.club_id,
                              },
                            });
                          }}
                          className="resellnfb_btn_details"
                        >
                          <div className="resellnfb_btn_details_main">
                            <img
                              src={
                                item.bond_img_video.length == 0
                                  ? images.logo
                                  : item.bond_img_video[0].img_video
                              }
                              className="details_main_img"
                              alt=""
                            />
                            <div className="details_of_resell">
                              <p className="details_of_resell_h1">
                                {item.product_name}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <p className="details_of_resell_p">
                                  {item.owner_name}
                                </p>
                              </div>
                              <p className="details_of_resell_p">
                                Asset Value: {item.product_value}
                              </p>
                              <div
                                style={{
                                  width: "100%",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  display: "flex",
                                }}
                              >
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    alignSelf: "flex-end",
                                    display: "flex",
                                  }}
                                  src={images.delete2}
                                  // className="wishlist_2_img"
                                  alt=""
                                  onClick={() => {
                                    _deletewishlist(item.whishlist_id);
                                  }}
                                />
                              </div>
                              {/* <div className="reles_details_benefits_wrapp">
                                      {item.product_bond_benifit_detail.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={
                                                i.reward_img_video[0].img_video
                                              }
                                              className="reles_details_single_benefit"
                                              alt=""
                                              style={{ marginRight: "10px" }}
                                            />
                                          );
                                        }
                                      )}
                                      {item.partner_bond_benifit_data.map(
                                        (i, index) => {
                                          return (
                                            <img
                                              key={index}
                                              src={i.benifit_icon}
                                              className="reles_details_single_benefit"
                                              alt=""
                                              style={{ marginRight: "10px" }}
                                            />
                                          );
                                        }
                                      )}
                                    </div>

                                    {item.status == 4 ? (
                                      <>
                                        <div className="action_of_resell">
                                          <Link
                                            to="/Resellbidslist"
                                            state={{
                                              resale_release_id:
                                                item.resale_release_id,
                                              uniq_code: item.uniq_code,
                                            }}
                                            className="action_of_resell_btn"
                                          >
                                            Manage Bids
                                          </Link>
                                        </div>
                                      </>
                                    ) : null}
                                    {item.status != 4 ? (
                                      <button
                                        onClick={() => {
                                          item.status == 1
                                            ? statusadd()
                                            : navigate("/ResellActionneed", {
                                                state: {
                                                  product_name:
                                                    item.product_name,
                                                  owner_name: item.owner_name,
                                                  product_id: item.product_id,
                                                  remaining_bond:
                                                    item.remaining_bond,
                                                },
                                              });
                                        }}
                                        className="action_of_resell_btn"
                                      >
                                        {item.status == 1
                                          ? "In review"
                                          : item.status == 2 || item.status == 3
                                          ? "Action Needed"
                                          : item.status == 3
                                          ? "Reject"
                                          : null}
                                      </button>
                                    ) : null} */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Wishlist;
