import React, { useState, useRef, useEffect } from "react";
import "./NewAadhaarCard.css";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { adhar_init, get_terms_condition } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";
import { useLocation, useNavigate } from "react-router-dom";
import NewTandC from "../../components/newtandc/NewTandC";

const NewAadhaarCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aadhaarNumber, setAadhaarNumber] = useState(Array(12).fill(""));
  const [aadhaarNumber2, setAadhaarNumber2] = useState("");
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getcapchaImg, setCapchaImg] = useState("");
  const [getcapchaCode, setCapchaCode] = useState("");
  const [gettranzactionId, setTranzactionId] = useState("");
  const [gettermAccept, setTermAccept] = useState(false);
  const [gettermModel, setTermModel] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);

  // terms api start

  useEffect(() => {
    // Make the API call to get the terms and conditions HTML
    axios
      .get(get_terms_condition)
      .then((response) => {
        console.log(response.data.result);
        // Assuming the API returns HTML content in the 'response.data' field
        setHtmlContent(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // terms api end

  // const handleChange = (e, index) => {
  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  //   inputValue = inputValue.slice(0, 1); // Limit input to 1 character
  //   const updatedAadhaarNumber = [...aadhaarNumber];
  //   const updatedAadhaarNumber2 = updatedAadhaarNumber.join("");
  //   console.log("updatedAadhaarNumber", updatedAadhaarNumber2);
  //   updatedAadhaarNumber[index] = inputValue;
  //   setAadhaarNumber(updatedAadhaarNumber);
  //   setAadhaarNumber2(updatedAadhaarNumber2);
  //   const nextInput = inputRefs.current[index + 1];
  //   if (nextInput && inputValue !== "") {
  //     nextInput.focus();
  //   }
  // };

  const handleChange = (e, index) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 1); // Limit input to 1 character
    const updatedAadhaarNumber = [...aadhaarNumber];
    updatedAadhaarNumber[index] = inputValue;
    setAadhaarNumber(updatedAadhaarNumber);
    const nextInput = inputRefs.current[index + 1];
    if (nextInput && inputValue !== "") {
      nextInput.focus();
    }

    // Join the array elements into a single string
    const fullAadhaarNumber = updatedAadhaarNumber.join("");
    console.log(fullAadhaarNumber);
    setAadhaarNumber2(fullAadhaarNumber);

    // Use fullAadhaarNumber as needed...
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && aadhaarNumber[index] === "") {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  // genrate capcha api start
  const GetCapcha = () => {
    setIsLoading(true);

    // Make the API request
    axios
      .get(adhar_init)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === 1) {
          console.log("response.data", response.data);
          setCapchaImg(response.data.result.file_path);
          setTranzactionId(response.data.result.transaction_id);
          // toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error, { theme: "colored" });
      });
  };
  // genrate capcha api end

  // set addhaar number api start

  const SetAddhaarNumber = () => {
    console.log("aadhaarNumber2", aadhaarNumber2);
    if (aadhaarNumber2.length <= 0) {
      toast.error("Please Enter aadhaar card number", { theme: "colored" });
      return;
    } else if (aadhaarNumber2.length != 12) {
      toast.error(
        "Invalid Input \n \n Aadhaar card number should be exactly 12 digits",
        { theme: "colored" }
      );
    } else if (getcapchaCode == "") {
      toast.error("Please enter capcha code", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        transaction_id: gettranzactionId,
        adhar_no: aadhaarNumber2,
        captcha_code: getcapchaCode,
      };
      // Make the API request
      axios
        .post(
          "https://www.ravi.host/api/adhar_generate_otp",
          qs.stringify(param)
        )
        .then((response) => {
          if (response.data.status === 1) {
            console.log("response.data", response.data);
            setIsLoading(false);
            toast.error(response.data.message, {
              theme: "colored",
            });
            navigate("/NewAddharCardOtpScreen", {
              state: {
                adhar_no: response.data.result.adhar_no,
                transaction_id: response.data.result.transaction_id,
                user_id: location.state.user_id,
              },
            });
          } else {
            toast.error(response.data.message, {
              theme: "colored",
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error, { theme: "colored" });
          setIsLoading(false);
        });
    }
  };

  // term accept function start

  const handleTermAccept = () => {
    console.log("asd");
    setTermModel(false);
    setTermAccept(true);
  };

  const handleTermChange = () => {
    console.log("asd");
    setTermModel(true);
  };

  // term accept function end

  // set addhaar number api end

  useEffect(() => {
    GetCapcha();
  }, []);

  return (
    <div className="new_wrapp_container relative_position">
      {/* <img src={images.new_bg_circule} alt="" className="back_circule_shadow" /> */}
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="set_otp_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "20vh", minHeight: "180px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4 style={{ margin: "0px" }}>Confirm your Identity</h4>
              <p style={{ color: "var(--color-main)" }}>
                Your card details remain confidential. This helps us to process
                further.{" "}
              </p>
            </div>
            <div className="new_aadhaar_input_wrapp">
              <p>Enter AADHAAR CARD number</p>
              <div className="aadhaar-input-container">
                {aadhaarNumber.map((digit, index) => (
                  <>
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="aadhaar-input"
                      value={digit}
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      maxLength={1}
                      inputMode="numeric"
                    />
                    {(index + 1) % 4 === 0 &&
                      index < aadhaarNumber.length - 1 && (
                        <span className="space">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      )}
                  </>
                ))}
              </div>
            </div>

            <div
              className="new_aadhaar_input_wrapp"
              style={{ padding: "0px 5%" }}
            >
              <p style={{ marginBottom: "10px" }}>
                Enter the character you see
              </p>
              <div className="new_aadhaar_cpatcha_wrapp">
                <img src={getcapchaImg} alt="" />
                <button onClick={GetCapcha}>
                  <img src={images.new_capcha_rephrase} alt="" />
                </button>
                <input
                  type="text"
                  value={getcapchaCode}
                  onChange={(e) => {
                    setCapchaCode(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="new_aadhaar_btn_wrapp">
              <div>
                {gettermAccept ? (
                  <img
                    src={images.new_accepted_terms}
                    onClick={() => setTermAccept(false)}
                    alt=""
                  />
                ) : (
                  <img
                    src={images.new_notaccepted_terms}
                    alt=""
                    onClick={handleTermChange}
                  />
                )}
                <p>I agree to the terms & conditions</p>
              </div>
              {/* btn */}
              <button
                onClick={SetAddhaarNumber}
                disabled={!gettermAccept}
                className="new_btn_wrapp mx-1"
              >
                <p>Next</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      {gettermModel ? (
        <>
          <NewTandC
            title="Terms & Condition"
            msg={htmlContent}
            setIsOpen={setTermModel}
            accept={handleTermAccept}
          />
        </>
      ) : null}
    </div>
  );
};

export default NewAadhaarCard;
