import React from "react";
import "./NewFounderVideoScreen.css";
import images from "../../constants/images";
import { useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NewFounderVideoScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="new_wrapp_container">
      <div className="new_container">
        <div className="new_founder_video_main_wrapp">
          <p className="new_founder_video_heading mt-3">
            Message from our <br /> Co-Founder
          </p>
          <div className="new_founder_sample_video_wrapp mt-3">
            <video
              ref={videoRef}
              controls={false}
              // controls
              className="new_founder_sample_video"
            >
              <source src={images.smaple_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div
              className="new_founder_sample_video_play_pause_btn"
              onClick={togglePlayPause}
            >
              {isPlaying ? (
                <img
                  src={images.pause_white}
                  alt=""
                  style={{ opacity: "0.4" }}
                />
              ) : (
                <img src={images.play_white} alt="" />
              )}
            </div>
          </div>

          <div className="new_gst_btns_wrapp">
            {/* btn */}
            <button
              onClick={() => {
                navigate("/NewUploadAutherVideoScreen", {
                  state: {
                    user_id: location.state.user_id,
                  },
                });
              }}
              className="new_btn_wrapp mx-1"
            >
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFounderVideoScreen;
