import React, { useState } from "react";
import "./NewEmailVerification.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { user_add_email_v2 } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";

const NewEmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [getemail, setEmail] = useState("");
  const [getcon, setcon] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  // add email api start

  const AddEmailAddress = async () => {
    if (getemail === "") {
      toast.error("Please enter email address", { theme: "colored" });
    } else {
      setIsLoading(true);
      const params = {
        email: getemail,
        user_id: location.state.user_id,
      };
      await axios
        .post(user_add_email_v2, params)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status == 1) {
            navigate("/NewEmailOtpVerify", {
              state: {
                user_id: res.data.user_id,
                email: res.data.email,
              },
            });
            toast.success(`${res.data.message}`, { theme: "colored" });
          } else {
            toast.error(`${res.data.message}`, { theme: "colored" });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(`${error}`, { theme: "colored" });
        });
    }
  };

  // add email api end

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />

      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "45vh" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div className="otp_screen_text_container">
              <h4>OTP Verification</h4>
              <p>Enter email address to get the verification code.</p>
            </div>

            <div className="new_otp_input_container">
              <div className="new_otp_input_1st_sec" style={{ width: "30%" }}>
                <div className="verify_email_logo_wrapp">
                  <img
                    src={images.new_email_logo}
                    className="verify_email_logo"
                    alt=""
                  />
                  <p>Email:</p>
                </div>
              </div>
              <div className="new_otp_input_2nd_sec" style={{ width: "65%" }}>
                {/* <input type="email" value={getemail} className="" /> */}
                <input
                  type="email"
                  name=""
                  id=""
                  className="verify_email_input"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (email_validation.test(text) === false) {
                      setcon(false);
                    } else if (text.length == 0) {
                      setcon(false);
                    } else {
                      setcon(true);
                    }
                    setEmail(text);
                  }}
                />
              </div>
            </div>

            {/* btn */}
            <button
              disabled={!getcon}
              onClick={AddEmailAddress}
              className="new_btn_wrapp mx-1 mt-3"
            >
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewEmailVerification;
