import React, { useState, useRef, useEffect } from "react";
import images from "../../constants/images";
import "./verifyscreen.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {
  email_verify_and_active_user,
  user_otp,
  user_resend_email_otp,
  user_resend_otp,
  user_reset_email_phone_number,
  user_reset_phone_number,
} from "../../utils/Constant";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const Verifyscreen = () => {
  const location = useLocation();
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmail, setIsEmail] = useState(
    location.state && location.state.email
  );
  const [isEmailedit, setIsEmailEdit] = useState(
    location.state && location.state.email
  );
  const [getphone, setPhone] = useState(location.state.phone);
  const [getphoneedit, setPhoneEdit] = useState(location.state.phone);
  const [getoldOtp, setIsOldOtp] = useState(
    location.state && location.state.user_otp
  );
  const [isLoading, setIsLoading] = useState(false);
  const emailmpin = [useRef(), useRef(), useRef(), useRef()];
  const [getcusmsg, setcusmsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [getnumbervalidation, setNumberValidation] = useState(
    location.state && location.state.status
  );
  const navigate = useNavigate();
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(60);
  };
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendTimerRunning, setIsResendTimerRunning] = useState(false);

  const startResendTimer = () => {
    setIsResendTimerRunning(true);
    setResendTimer(60);
  };

  const ResendMobileOtp1 = () => {
    // Place your logic here to initiate OTP resend
    startTimer();
  };

  useEffect(() => {
    if (isResendTimerRunning && resendTimer > 0) {
      const countdown = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isResendTimerRunning && resendTimer === 0) {
      setIsResendTimerRunning(false);
    }
  }, [isResendTimerRunning, resendTimer]);

  useEffect(() => {
    // console.log("location", location.state);

    if (isTimerRunning && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [isTimerRunning, timer]);

  // mobileotp value start
  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  useEffect(() => {
    console.log(
      "storeitem",
      localStorage.getItem("is_login"),
      localStorage.getItem("user_id"),
      localStorage.getItem("email"),
      localStorage.getItem("username"),
      localStorage.getItem("profile")
    );
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const otpValue = mobileotp.join("");

  // mobileotp value end

  // mobileotp api start
  const VerifyOtp = async () => {
    console.log("dcss", otpValue);
    if (!otpValue) {
      setcusmsg("Please enter your otp");
      setShowModal(true);
    } else if (otpValue.length < 4) {
      setcusmsg("Please enter 4 digit otp");
      setShowModal(true);
    } else {
      setIsLoading(true);
      const param = {
        mobile_no: getphone,
        user_otp: otpValue,
      };
      await axios
        .post(user_otp, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response);
            setIsMobileVerified(true);
            setIsEmail(Response.data.email);
          } else {
            // alert(Response.data.message);
            setcusmsg(Response.data.message);
            setShowModal(true);
          }
          // setIsModalOpen2(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          // alert("OTP is incrrect please try again");
        });
    }
  };
  // mobileotp api end

  // resend mobileotp api start
  const ResendMobileOtp = async () => {
    setMobileOtp(["", "", "", ""]);
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
    };
    console.log("success");
    await axios
      .post(user_resend_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        } else {
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
      });
  };
  // resend mobileotp api end

  // resend mobileotp api start
  const EditMobileNo = async () => {
    if (getphoneedit == "") {
      setcusmsg("Please enter a mobile number");
      setShowModal(true);
    } else if (getphoneedit.length != 10) {
      setcusmsg("Please enter a valid mobile number");
      setShowModal(true);
    } else {
      setIsLoading(true);
      const param = {
        user_id: location.state.user_id,
        phone_number: getphoneedit,
      };
      console.log("user_otp param", param);

      await axios
        .post(user_reset_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response.data);
            // alert(Response.data.message);
            setPhone(Response.data.results);
            handleModalClose();
          } else {
            console.log("user_otp err", Response.data);
            setcusmsg(Response.data.message);
            setShowModal(true);
            // alert(Response.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
        });
    }
  };
  // resend mobileotp api end

  // ==================== email section ===================== //

  // emailotp api start
  const VerifyEmailOtp = async () => {
    console.log("ascsac", "adcasfasevsf");
    setIsLoading(true);
    const param = {
      mobile_no: isEmail,
      email_otp: otpValueEmail,
    };
    console.log("success");
    await axios
      .post(email_verify_and_active_user, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          // localStorage.setItem("is_login", JSON.stringify(true));
          // localStorage.setItem("user_id", Response.data.user_id);
          // localStorage.setItem("email", Response.data.email);
          // localStorage.setItem("username", Response.data.username);
          // localStorage.setItem("profile", Response.data.profile);
          // console.log("email_verify_and_active_user Response", Response.data);
          // navigate("/aadharcard", {
          //   state: {
          //     user_id: Response.data.user_id,
          //   },
          // });
          if (Response.data.countries == "India") {
            navigate("/aadharcard", {
              state: {
                user_id: Response.data.user_id,
              },
            });
          } else {
            navigate("/AutherVideo", {
              // navigate("/aadharcard", {
              state: {
                user_id: Response.data.user_id,
              },
            });
          }
          // setIsLoading(false);
          // setIsMobileVerified(true);
        } else {
          console.log("email_verify_and_active_user err", Response.data);
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        }
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("email_verify_and_active_user err", err);
      });
  };
  // emailotp api end

  // resend Emailotp api start
  const ResendEmailOtp = async () => {
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
    };
    console.log("success");
    await axios
      .post(user_resend_email_otp, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startResendTimer();
          console.log("user_otp Response", Response.data);
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        } else {
          // alert(Response.data.message);
          setcusmsg(Response.data.message);
          setShowModal(true);
        }
        // setIsOldOtp(Response.data);
        // setIsMobileVerified(true);
        // setIsEmail(Response.data.email);
        // setIsModalOpen2(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
      });
  };
  // resend emailotp api end

  // Edit email api start
  const EditEmail = async () => {
    if (isEmailedit == "") {
      setcusmsg("Please enter a email address");
      setShowModal(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(isEmailedit)) {
      setIsEmailEdit(null);
      setcusmsg("Please enter a valid email address");
      setShowModal(true);
    } else {
      setIsLoading(true);
      const param = {
        email: isEmailedit,
        user_id: location.state.user_id,
      };
      await axios
        .post(user_reset_email_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            console.log("user_otp Response", Response.data);
            // alert(Response.data.message);
            setIsEmail(Response.data.results);
            setcusmsg(Response.data.message);
            setShowModal(true);
            handleModalClose2();
          } else {
            console.log(Response.data);
            setcusmsg(Response.data.message);
            setShowModal(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
        });
    }
  };
  // edit email api end

  // Emailotp value start
  const [emailotp, setEmailOtp] = React.useState(["", "", "", ""]);
  const refsEmail = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeEmail = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...emailotp];
      newOtp[index] = value;
      setEmailOtp(newOtp);

      if (index < refsEmail.length - 1 && value !== "") {
        refsEmail[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownEmail = (index, event) => {
    if (event.key === "Backspace" && index > 0 && emailotp[index] === "") {
      const newOtp = [...emailotp];
      newOtp[index - 1] = "";
      setEmailOtp(newOtp);
      refsEmail[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (refsEmail[0].current) {
      refsEmail[0].current.focus();
    }
  }, []);
  const otpValueEmail = emailotp.join("");
  // Emailotp value end

  const handleMobileVerification = () => {
    setIsMobileVerified(true);
  };

  // modal mobile
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  // email modal
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleModalOpen2 = () => {
    setIsModalOpen2(true);
  };

  const handleModalClose2 = () => {
    setIsModalOpen2(false);
  };
  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setPhoneEdit(numericValue);
      // setErrorMessage("");
    } else {
      // If more than 10 digits, trim to the first 10 digits
      const trimmedValue = numericValue.slice(0, 10);
      setPhoneEdit(trimmedValue);
      console.log("trimmedValue", trimmedValue);
      // setphonedisable()
      // alert("Please enter up to 10 digits.");
      // setcusmsg("Please valid phone number.");
      // setShowModal(true);
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };
  return (
    <div
      className="Verifyscreen-container"
      style={{ justifyContent: "center" }}
    >
      <div className="Verifyscreen_1"></div>
      <div
        // className="Verifyscreen_2"
        style={{
          width: "90%",
          margin: "10px auto",
          maxWidth: "var(--max-width-sm)",
        }}
      >
        {isLoading ? (
          <div className="loader"></div>
        ) : (
          <div
            // className="web_user_menu"
            style={{
              width: "100%",
            }}
          >
            {/* <div className="Verifyscreen_header"> */}
            <img src={images.new_logo} className="signup-logo" alt="" />
            {/* </div> */}
            {getnumbervalidation == 3 ? null : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="otp_demo1">
                  <h1
                    className="otp_demo_title"
                    style={{ margin: "10px auto" }}
                  >
                    Enter OTP
                  </h1>
                  <p className="otp_demo_p">
                    We have sent an OTP to your mobile number
                  </p>

                  <p
                    className="mobile_verify_number"
                    onClick={isMobileVerified == true ? null : handleModalOpen}
                  >
                    +91 {getphone}{" "}
                    <img
                      className="verify_edit"
                      src={images.editing_verify}
                      alt=""
                    />
                  </p>
                </div>
                {isModalOpen && (
                  <div className="modal_overlay">
                    <div className="modal_content_mobile">
                      <div
                        style={{
                          width: "90%",
                          maxWidth: "var(--max-width-sm)",
                          margin: "0px auto",
                        }}
                      >
                        <input
                          maxLength={10}
                          type="number"
                          placeholder="Enter Mobile number"
                          className="mobile_input_modal"
                          onChange={(e) => handlePhoneNumberChange(e)}
                          value={getphoneedit}
                        />
                        <div className="mobile_modal_btn">
                          <button
                            className="verify_btn_otp"
                            onClick={handleModalClose}
                          >
                            Cancel
                          </button>
                          <button
                            className="verify_btn_otp"
                            onClick={() => EditMobileNo()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div style={{}} className="input_otp_new1">
                  {mobileotp.map((digit, index) => (
                    <input
                      style={{
                        borderWidth: 0.5,
                        borderColor: isMobileVerified && "green",
                      }}
                      key={index}
                      disabled={isMobileVerified}
                      type="number"
                      maxLength={1}
                      className="in_de_otp"
                      ref={refsMobile[index]}
                      value={digit}
                      onChange={(event) =>
                        handleInputChangeMobile(index, event)
                      }
                      onKeyDown={(event) => handleKeyDownMobile(index, event)}
                    />
                  ))}
                </div>
                {!isMobileVerified && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "16px",
                      margin: "16px auto",
                    }}
                  >
                    <button
                      onClick={ResendMobileOtp}
                      // className="resend_mobile_otp"
                      disabled={isTimerRunning}
                      style={{
                        color: "var(--color-main)",
                        fontSize: "14px",
                        fontFamily: "var(--font-600)",
                      }}
                    >
                      {isTimerRunning ? `Resend in ${timer}s` : "RESEND OTP"}
                    </button>
                    <button className="verify_btn_otp" onClick={VerifyOtp}>
                      Verify
                    </button>
                  </div>
                )}
              </div>
            )}
            {isMobileVerified == true || getnumbervalidation == 3 ? (
              <div className="monile_div" style={{ marginTop: "20px" }}>
                <div className="mobile_verify">
                  {/* <p className="mobile_verify_p">
                    We have sent an OTP to your email address please check your
                    spam folder too
                  </p> */}
                  <p className="otp_demo_p">
                    We have sent an OTP to your email address please check your
                    spam folder too
                  </p>
                  <p
                    className="mobile_verify_number"
                    onClick={handleModalOpen2}
                  >
                    {isEmail}{" "}
                    <img
                      className="verify_edit"
                      src={images.editing_verify}
                      alt=""
                    />
                  </p>
                  {isModalOpen2 && (
                    <div className="modal_overlay">
                      <div
                        className="modal_content_mobile"
                        style={{
                          width: "90%",
                          maxWidth: "var(--max-width-sm)",
                          margin: "0px auto",
                        }}
                      >
                        <input
                          type="email"
                          // readOnly={isModalOpen2}
                          placeholder="Enter Your email address"
                          className="email_input_modal"
                          value={isEmailedit}
                          onChange={(e) => setIsEmailEdit(e.target.value)}
                        />
                        <div className="mobile_modal_btn">
                          <button
                            className="verify_btn_otp"
                            onClick={handleModalClose2}
                          >
                            Cancel
                          </button>
                          <button
                            className="verify_btn_otp"
                            onClick={() => EditEmail()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="input_otp_new1">
                    {emailotp.map((digit, index) => (
                      <input
                        key={index}
                        // style={{
                        //   borderWidth: 0.5,
                        //   borderColor: isMobileVerified && "green",
                        // }}
                        type="number"
                        maxLength={1}
                        className="in_de_otp"
                        ref={refsEmail[index]}
                        value={digit}
                        onChange={(event) =>
                          handleInputChangeEmail(index, event)
                        }
                        onKeyDown={(event) => handleKeyDownEmail(index, event)}
                      />
                    ))}
                  </div>
                </div>
                {/* <p>{otpValueEmail}</p> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "16px",
                    margin: "16px auto",
                  }}
                >
                  <button
                    onClick={ResendEmailOtp}
                    style={{
                      color: "var(--color-main)",
                      fontSize: "14px",
                      fontFamily: "var(--font-600)",
                    }}
                    disabled={isResendTimerRunning}
                  >
                    {isResendTimerRunning
                      ? `Resend in ${resendTimer}s`
                      : "RESEND OTP"}
                  </button>
                  <button className="verify_btn_otp" onClick={VerifyEmailOtp}>
                    Verify
                  </button>
                </div>
              </div>
            ) : null}{" "}
          </div>
        )}
      </div>
      {/* custom alert modal start vishal */}
      {showModal && (
        <>
          {/* <AlertModal title="" msg={getcusmsg} setShowModal={setShowModal} /> */}
          {/* <NewModal
            title=""
            msg={getcusmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setShowModal}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default Verifyscreen;
