import React from "react";
import ContentLoader from "react-content-loader";

const CardSkeletonLoader = () => {
  return (
    <div
      style={{
        maxWidth: "400px",
        height: "630px",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ContentLoader
        speed={2}
        width={400}
        height={600}
        viewBox="0 0 400 600"
        backgroundColor={"#333"}
        foregroundColor={"#999"}
        // style={{maxWidth:"420px"}}
      >
        <circle cx="37" cy="26" r="24" />
        <rect x="171" y="108" rx="0" ry="0" width="31" height="1" />
        <rect x="22" y="58" rx="0" ry="0" width="12" height="0" />
        <rect x="71" y="53" rx="0" ry="0" width="0" height="23" />
        <rect x="71" y="19" rx="0" ry="0" width="116" height="14" />
        <rect x="42" y="54" rx="13" ry="13" width="300" height="500" />
        <rect x="45" y="571" rx="0" ry="0" width="299" height="23" />
      </ContentLoader>
    </div>
  );
};

export default CardSkeletonLoader;
