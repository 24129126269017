import React, { useEffect, useState } from "react";
import "./selectmanagers.css";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import {
  add_user_manager,
  get_all_user2,
  getall_user,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const Selectmanagers = () => {
  const [searchValue, setSearchValue] = useState("");
  const [getdata, setdata] = useState([]);
  const [getsearchData, setsearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const managers = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Michael Johnson" },
    { id: 4, name: "Emily Williams" },
    { id: 5, name: "Robert Brown" },
    { id: 6, name: "Olivia Davis" },
    { id: 7, name: "William Taylor" },
    { id: 8, name: "Sophia Martinez" },
    { id: 9, name: "David Anderson" },
    { id: 10, name: "Isabella Wilson" },
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const filteredManagers = managers.filter((manager) =>
    manager.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();

  useEffect(() => {
    _getData1();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = getsearchData.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toLowerCase();
      const number = String(item.mobile_number)
        ? String(item.mobile_number).toUpperCase()
        : "".toUpperCase();
      const stock = String(item.status)
        ? String(item.status).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        stock.indexOf(textData) > -1
      );
    });
    setdata(newData);
    console.log("asvsvsds", newData);
  };

  const _getData1 = () => {
    const param1 = {
      user_id: userId,
    };
    axios.post(get_all_user2, param1).then((Response) => {
      // console.log(
      //   'Response.data.result',
      //   JSON.stringify(Response.data.result, null, 2),
      // );
      setdata(Response.data.result);
      setsearchData(Response.data.result);
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onItemPress = (itemId) => {
    let updatedSelectedItems;

    if (selectedItems.includes(itemId)) {
      updatedSelectedItems = selectedItems.filter((label) => label !== itemId);
    } else {
      updatedSelectedItems = [...selectedItems, itemId];
    }

    setSelectedItems(updatedSelectedItems);
    console.log("selectedItems:", updatedSelectedItems);
  };

  const onItemid = (itemId) => {
    let updatedSelectedItems1;

    if (selectedId.includes(itemId)) {
      updatedSelectedItems1 = selectedId.filter((id) => id !== itemId);
    } else {
      updatedSelectedItems1 = [...selectedId, itemId];
    }

    setSelectedId(updatedSelectedItems1);
    console.log("selectedId:", updatedSelectedItems1);
  };

  const _addmanager = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("manager_id", selectedId.join(", "));
    console.log(formData);
    axios.post(add_user_manager, formData).then((response) => {
      console.log(response.data);
      setIsLoading(false);
      if (response.data.status === 1) {
        navigate("/viewmanagers", {});
      } else {
        setErrorMessage(response.data.message);
        setShowModal(true);
      }
    });
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Add Managers" /> */}
        <div className="user_benefit_bond_modal_heding_1">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Add Managers</p>
        </div>
        {isLoading ? (
          <NewLoader />
        ) : (
          <>
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  onChange={(e) => searchFilter_branch(e.target.value)}
                />
              </div>
            </div>
            <br />
            <div style={{ marginBottom: "110px" }}>
              {getdata &&
                getdata.map((item) => {
                  const isSelected = selectedItems.includes(item.label);
                  return (
                    // <div
                    //   onClick={() => {
                    //     onItemPress(item.label);
                    //     onItemid(item.id);
                    //   }}
                    //   className="selectmanagers_details"
                    // >
                    //   <img className="select_managers" src={item.profile} alt="" />
                    //   <b
                    //     key={item.id}
                    //     className={
                    //       isSelected
                    //         ? "selected_nm_managers_green"
                    //         : "selected_nm_managers"
                    //     }
                    //   >
                    //     <p className={isSelected ? "lablegreen" : "lablewhite"}>
                    //       {item.label}
                    //     </p>
                    //   </b>
                    // </div>
                    <div
                      className="user_benefit_modal_single_bond_1"
                      style={{
                        marginTop: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        borderRadius: "5px",
                      }}
                      // key={item.product_id}
                    >
                      <div className="user_benefit_modal_single_bond_inner_wrapp">
                        <div
                          onClick={() => {
                            navigate("/userProfile", {
                              state: { user_id: item.user_id },
                            });
                          }}
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "45px",
                            backgroundImage:
                              "linear-gradient(to right, rgba(255, 0, 76, 1), rgba(202, 172, 255, 1))",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            zIndex: "111",
                          }}
                        >
                          <img
                            style={{
                              width: "42px",
                              height: "42px",
                              borderRadius: "40px",
                            }}
                            src={item.profile}
                            alt=""
                          />
                        </div>
                        <p
                          onClick={() => {
                            navigate("/userProfile", {
                              state: { user_id: item.user_id },
                            });
                          }}
                        >
                          {item.label}
                        </p>
                        <img
                          onClick={() => {
                            onItemPress(item.label);
                            onItemid(item.id);
                          }}
                          //   className="attach_partner_select"
                          style={{
                            width: "25px",
                            height: "25px",
                            zIndex: "111",
                            // borderRadius: "10px",
                          }}
                          src={
                            isSelected
                              ? images.tickcommunity
                              : images.untickcommunity
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {selectedItems == 0 ? null : (
          <button
            onClick={() => _addmanager()}
            className="new_btn_wrapp"
            //   style={{ backgroundColor: "#000" }}
            style={{
              position: "fixed",
              bottom: "10%",
              width: "90%",
              //   left: "10%",
              transform: "translate(0%, 50%)",
              maxWidth: "var(--max-width-sm)",
              background: "#000",
              zIndex: "113",
            }}
          >
            <p>Submit</p>
          </button>
        )}
      </div>
      <div className=""></div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />

        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Selectmanagers;
