import React, { useEffect, useState } from "react";
import "./NewLoginScreen.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserContext } from "../../context/user_context";
import { edit_profile } from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";
import { genarateToken } from "../../firebase";

const NewLoginScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUserLogin } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [getemailPhone, setEmailPhone] = useState(location.state.phone);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [getIp, setIp] = useState("");
  const [gettoken, setToken] = useState("");

  useEffect(() => {
    FetchIpAddress();
  }, []);

  // login validations start
  const handleLogin = () => {
    if (getemailPhone === "") {
      toast.error("Please enter Email or Phone number", { theme: "colored" });
    } else if (password === "") {
      toast.error("Please enter password", { theme: "colored" });
    } else {
      console.log("getemailPhone:", getemailPhone);
      console.log("Password:", password);
      UserLoginApi();
      // Perform the actual login logic here
    }
  };
  // login validations end

  // fetch ip start
  const FetchIpAddress = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    console.log("data", data);
    setIp(data.ip);
    console.log("ipAddress", getIp);
  };
  // fetch ip end

  const fetchData = async () => {
    const token = await genarateToken();
    // console.log("Token:", token);
    return token;
  };

  fetchData()
    .then((token) => {
      console.log("Token: 1", token); // This will log the token value
      setToken(token);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  // login api start
  const UserLoginApi = async () => {
    setIsLoading(true);
    const params = {
      email: getemailPhone,
      password: password,
      user_ip_address: getIp,
      player_id: gettoken,
      token: "asdf",
    };
    console.log("login param", params);

    const data = await setUserLogin(params);
    console.log("data 112", data);

    if (data.status == 0) {
      setIsLoading(false);
      toast.error(data.message, { theme: "colored" });
    } else if (data.status == 1) {
      // steps start
      if (data.step == 5) {
        navigate("/NewAadhaarCard", {
          state: {
            user_id: data.user_id,
          },
        });
      } else if (data.step == 6) {
        navigate("/NewPancardScreen", {
          state: {
            user_id: data.user_id,
            pan_no: data.pan_no,
          },
        });
      } else if (data.step == 7) {
        navigate("/NewGstScreen", {
          state: {
            user_id: data.user_id,
          },
        });
      } else if (data.step == 8) {
        navigate("/NewFounderVideoScreen", {
          state: {
            user_id: data.user_id,
          },
        });
      } else if (data.step == 9) {
        navigate("/NewInterests", {
          state: {
            user_id: data.user_id,
          },
        });
      } else if (data.step == 10) {
        toast.success(data.message, { theme: "colored" });
        const param1 = {
          user_id: data.user_id,
        };
        let demo = data.user_id;
        console.log("====================================");
        console.log("param", param1);
        console.log("====================================");

        axios
          .post(edit_profile, qs.stringify(param1))
          .then((Response) => {
            setIsLoading(false);

            console.log("----", JSON.stringify(Response.data.result, null, 2));

            if (Response.data.status == 1) {
              localStorage.setItem("is_login", JSON.stringify(true));
              localStorage.setItem("user_id", demo);
              localStorage.setItem("email", Response.data.result.email);
              localStorage.setItem("username", Response.data.result.username);
              localStorage.setItem("profile", Response.data.result.username);
              console.log("Response.data", Response.data.result);
              navigate("/homescreen2", {
                state: {
                  user_id: demo,
                },
              });
            } else {
              toast.error(Response.data.message, { theme: "colored" });
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      // steps end
    } else {
      setIsLoading(false);
    }
  };
  // login api end

  const handleEmailPhoneChange = (e) => {
    setEmailPhone(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="new_wrapp_container relative_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="new_login_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "40vh", minHeight: "180px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className="new_login_screen_text_container">
                <h4 style={{ margin: "0px" }}>Login</h4>
                <p>Unlock Possibilities</p>
              </div>
              <div className="new_login_inputs_wrapp">
                {/* single input */}
                {/* <div className="new_set_password_single_input_wrapp">
                  <div className="new_set_password_single_labal_wrapp">
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={images.new_email_logo}
                      alt=""
                    />
                    <p style={{ fontSize: "12px" }}>Add your phone or email</p>
                  </div>
                  <div className="new_set_password_single_border_wrapp">
                    <input
                      type="text"
                      placeholder="Type here"
                      value={getemailPhone}
                      onChange={(e) => handleEmailPhoneChange(e)}
                    />
                  </div>
                </div> */}
                {/* single input */}
                <div className="new_set_password_single_input_wrapp">
                  <div className="new_set_password_single_labal_wrapp">
                    <img src={images.new_lock_logo} alt="" />
                    <p>Password :</p>
                  </div>
                  <div className="new_set_password_single_border_wrapp">
                    <input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => handlePasswordChange(e)}
                      value={password}
                      placeholder="Type here"
                    />
                    <img
                      onClick={toggleShowPassword}
                      src={images.new_eye_icon}
                      alt=""
                    />
                  </div>
                </div>
                {/* forgate password */}
                <div className="new_login_forgate_btn">
                  <button
                    onClick={() => {
                      navigate("/NewForgotPassword");
                    }}
                  >
                    Forgot Password ?
                  </button>
                </div>
              </div>
            </div>
            {/* btn */}
            <button onClick={handleLogin} className="new_btn_wrapp mt-3">
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewLoginScreen;
