export const REGISTER_BEGIN = "REGISTER_BEGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOGIN_BEGIN = "USER_LOGIN_BEGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

// otp
export const USER_OTP_BEGIN = "USER_OTP_BEGIN";
export const USER_OTP_SUCCESS = "USER_OTP_SUCCESS";
export const USER_OTP_FAIL = "USER_OTP_FAIL";

// forgate password
export const FORGATE_PASSWORD_BEGIN = "FORGATE_PASSWORD_BEGIN";
export const FORGATE_PASSWORD_SUCCESS = "FORGATE_PASSWORD_SUCCESS";
export const FORGATE_PASSWORD_FAIL = "FORGATE_PASSWORD_FAIL";

// get_intersst
export const GET_INTERESTS_BEGIN = "GET_INTERESTS_BEGIN";
export const GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS";
export const GET_INTERESTS_FAIL = "GET_INTERESTS_FAIL";

// list_of_release_bond
export const GET_HOME_BEGIN = "GET_HOME_BEGIN";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_FAIL = "GET_HOME_FAIL";

// release_bond_like
export const GET_LIKE_BEGIN = "GET_LIKE_BEGIN";
export const GET_LIKE_SUCCESS = "GET_LIKE_SUCCESS";
export const GET_LIKE_FAIL = "GET_LIKE_FAIL";

// add_release_bond_comment
export const GET_HOME_COMMENT_BEGIN = "GET_HOME_COMMENT_BEGIN";
export const GET_HOME_COMMENT_SUCCESS = "GET_HOME_COMMENT_SUCCESS";
export const GET_HOME_COMMENT_FAIL = "GET_HOME_COMMENT_FAIL";

//get_user_profile
export const GET_USER_PROFILE_BEGIN = "GET_USER_PROFILE_BEGIN";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

//add_followers_following
export const GET_FOLLOW_BEGIN = "GET_FOLLOW_BEGIN";
export const GET_FOLLOW_SUCCESS = "GET_FOLLOW_SUCCESS";
export const GET_FOLLOW_FAIL = "GET_FOLLOW_FAIL";

//change_profile
export const GET_CHANGE_PROFILE_BEGIN = "GET_CHANGE_PROFILE_BEGIN";
export const GET_CHANGE_PROFILE_SUCCESS = "GET_CHANGE_PROFILE_SUCCESS";
export const GET_CHANGE_PROFILE_FAIL = "GET_CHANGE_PROFILE_FAIL";

//create_bond
export const GET_CREATE_BOND_BEGIN = "GET_CREATE_BOND_BEGIN";
export const GET_CREATE_BOND_SUCCESS = "GET_CREATE_BOND_SUCCESS";
export const GET_CREATE_BOND_FAIL = "GET_CREATE_BOND_FAIL";

// --> club section start

// get_all_club
export const GET_ALL_CLUB_BEGIN = "GET_ALL_CLUB_BEGIN";
export const GET_ALL_CLUB_SUCCESS = "GET_ALL_CLUB_SUCCESS";
export const GET_ALL_CLUB_FAIL = "GET_ALL_CLUB_FAIL";

// get_all_club_detail
export const GET_ALL_CLUB_DETAIL_BEGIN = "GET_ALL_CLUB_DETAIL_BEGIN";
export const GET_ALL_CLUB_DETAIL_SUCCESS = "GET_ALL_CLUB_DETAIL_SUCCESS";
export const GET_ALL_CLUB_DETAIL_FAIL = "GET_ALL_CLUB_DETAIL_FAIL";

// get_all_user_club
export const GET_ALL_USER_CLUB_BEGIN = "GET_ALL_USER_CLUB_BEGIN";
export const GET_ALL_USER_CLUB_SUCCESS = "GET_ALL_USER_CLUB_SUCCESS";
export const GET_ALL_USER_CLUB_FAIL = "GET_ALL_USER_CLUB_FAIL";

//create_club
export const CREATE_CLUB_BEGIN = "CREATE_CLUB_BEGIN";
export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const CREATE_CLUB_FAIL = "CREATE_CLUB_FAIL";

//club_join
export const CLUB_JOIN_BEGIN = "CLUB_JOIN_BEGIN";
export const CLUB_JOIN_SUCCESS = "CLUB_JOIN_SUCCESS";
export const CLUB_JOIN_FAIL = "CLUB_JOIN_FAIL";

// --> bid section start

export const GET_ALL_NFBBLOCKBID_BEGIN = "GET_ALL_NFBBLOCKBID_BEGIN";
export const GET_ALL_NFBBLOCKBID_SUCCESS = "GET_ALL_NFBBLOCKBID_SUCCESS";
export const GET_ALL_NFBBLOCKBID_FAIL = "GET_ALL_NFBBLOCKBID_FAIL";

/// create ablockbid
export const CREATE_NFBBLOCKBID_BEGIN = "CREATE_NFBBLOCKBID_BEGIN";
export const CREATE_NFBBLOCKBID_SUCCESS = "CREATE_NFBBLOCKBID_SUCCESS";
export const CREATE_NFBBLOCKBID_FAIL = "CREATE_NFBBLOCKBID_FAIL";

//// platform fees
export const GET_PLATFORM_FEES_BEGIN = "GET_PLATFORM_FEES_BEGIN";
export const GET_PLATFORM_FEES_SUCCESS = "GET_PLATFORM_FEES_SUCCESS";
export const GET_PLATFORM_FEES_FAIL = "GET_PLATFORM_FEES_FAIL";

// get release bond highest bid
export const GET_HIGHEST_BID_BEGIN = "GET_HIGHEST_BID_BEGIN";
export const GET_HIGHEST_BID_SUCCESS = "GET_HIGHEST_BID_SUCCESS";
export const GET_HIGHEST_BID_FAIL = "GET_HIGHEST_BID_FAIL";

/// accept_bond_bid
export const ACCEPT_BOND_BID_BEGIN = "ACCEPT_BOND_BID_BEGIN";
export const ACCEPT_BOND_BID_SUCCESS = "ACCEPT_BOND_BID_SUCCESS";
export const ACCEPT_BOND_BID_FAIL = "ACCEPT_BOND_BID_FAIL";

// get_all_accept_bond_bid
export const GET_ALL_ACCEPT_BOND_BID_BEGIN = "GET_ALL_ACCEPT_BOND_BID_BEGIN";
export const GET_ALL_ACCEPT_BOND_BID_SUCCESS =
  "GET_ALL_ACCEPT_BOND_BID_SUCCESS";
export const GET_ALL_ACCEPT_BOND_BID_FAIL = "GET_ALL_ACCEPT_BOND_BID_FAIL";

// get_all_release_bond_bids
export const GET_ALL_RELEASE_BOND_BIDS_BEGIN =
  "GET_ALL_RELEASE_BOND_BIDS_BEGIN";
export const GET_ALL_RELEASE_BOND_BIDS_SUCCESS =
  "GET_ALL_RELEASE_BOND_BIDS_SUCCESS";
export const GET_ALL_RELEASE_BOND_BIDS_FAIL = "GET_ALL_RELEASE_BOND_BIDS_FAIL";

// demo_1
export const GET_DEMO_1_BEGIN = "GET_DEMO_1_BEGIN";
export const GET_DEMO_1_SUCCESS = "GET_DEMO_1_SUCCESS";
export const GET_DEMO_1_FAIL = "GET_DEMO_1_FAIL";

// reject_accepted_bid
export const REJECT_ACCEPTED_BID_BEGIN = "REJECT_ACCEPTED_BID_BEGIN";
export const REJECT_ACCEPTED_BID_SUCCESS = "REJECT_ACCEPTED_BID_SUCCESS";
export const REJECT_ACCEPTED_BID_FAIL = "REJECT_ACCEPTED_BID_FAIL";

// reject_bid
export const REJECT_BID_BEGIN = "REJECT_BID_BEGIN";
export const REJECT_BID_SUCCESS = "REJECT_BID_SUCCESS";
export const REJECT_BID_FAIL = "REJECT_BID_FAIL";

// list_of_accepted_user_benifit
export const GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN =
  "GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN";
export const GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS =
  "GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS";
export const GET_ALL_ACCEPTED_USER_BENIFITS_FAIL =
  "GET_ALL_ACCEPTED_USER_BENIFITS_FAIL";

// get_user_accepted_bond
export const GET_USER_ACCEPTED_BOND_BEGIN = "GET_USER_ACCEPTED_BOND_BEGIN";
export const GET_USER_ACCEPTED_BOND_SUCCESS = "GET_USER_ACCEPTED_BOND_SUCCESS";
export const GET_USER_ACCEPTED_BOND_FAIL = "GET_USER_ACCEPTED_BOND_FAIL";

// get_single_accepted_user_bond
export const GET_SINGLE_ACCEPTED_USER_BOND_BEGIN =
  "GET_SINGLE_ACCEPTED_USER_BOND_BEGIN";
export const GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS =
  "GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS";
export const GET_SINGLE_ACCEPTED_USER_BOND_FAIL =
  "GET_SINGLE_ACCEPTED_USER_BOND_FAIL";

// get_latest_5_bond_3_club
export const GET_LATEST5_BEGIN = "GET_LATEST5_BEGIN";
export const GET_LATEST5_SUCCESS = "GET_LATEST5_SUCCESS";
export const GET_LATEST5_FAIL = "GET_LATEST5_FAIL";

// get all intrests
export const GET_ALL_INTEREST_BEGIN = "GET_ALL_INTEREST_BEGIN";
export const GET_ALL_INTEREST_SUCCESS = "GET_ALL_INTEREST_SUCCESS";
export const GET_ALL_INTEREST_FAIL = "GET_ALL_INTEREST_FAIL";

// user wallet
export const GET_USER_WALLET_BEGIN = "GET_USER_WALLET_BEGIN";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";
export const GET_USER_WALLET_FAIL = "GET_USER_WALLET_FAIL";

// user wallet recharge
export const GET_USER_WALLET_RECHARGE_BEGIN = "GET_USER_WALLET_RECHARGE_BEGIN";
export const GET_USER_WALLET_RECHARGE_SUCCESS =
  "GET_USER_WALLET_RECHARGE_SUCCESS";
export const GET_USER_WALLET_RECHARGE_FAIL = "GET_USER_WALLET_RECHARGE_FAIL";

// user wallet withdrew
export const GET_USER_WALLET_WITHDREW_BEGIN = "GET_USER_WALLET_WITHDREW_BEGIN";
export const GET_USER_WALLET_WITHDREW_SUCCESS =
  "GET_USER_WALLET_WITHDREW_SUCCESS";
export const GET_USER_WALLET_WITHDREW_FAIL = "GET_USER_WALLET_WITHDREW_FAIL";

// get_all_advertisement
export const GET_ALL_ADVERTISEMENT_BEGIN = "GET_ALL_ADVERTISEMENT_BEGIN";
export const GET_ALL_ADVERTISEMENT_SUCCESS = "GET_ALL_ADVERTISEMENT_SUCCESS";
export const GET_ALL_ADVERTISEMENT_FAIL = "GET_ALL_ADVERTISEMENT_FAIL";

// get_all_advertisement
export const GET_EDIT_PROFILE_BEGIN = "GET_EDIT_PROFILE_BEGIN";
export const GET_EDIT_PROFILE_SUCCESS = "GET_EDIT_PROFILE_SUCCESS";
export const GET_EDIT_PROFILE_FAIL = "GET_EDIT_PROFILE_FAIL";

// get_all_notifications
export const GET_ALL_NOTIFICATION_BEGIN = "GET_ALL_NOTIFICATION_BEGIN";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_FAIL = "GET_ALL_NOTIFICATION_FAIL";

// list_of_release_bond_2
export const GET_FEED_BEGIN = "GET_FEED_BEGIN";
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const GET_FEED_FAIL = "GET_FEED_FAIL";


// 12-09-2023
// vishal
// my_collection_get_user_accepted_bond
export const MY_COLLECTION_BEGIN = "MY_COLLECTION_BEGIN";
export const MY_COLLECTION_SUCCESS = "MY_COLLECTION_SUCCESS";
export const MY_COLLECTION_FAIL = "MY_COLLECTION_FAIL";