import React from "react";
import "./Auction2.css";
import { useState } from "react";
import { get_all_auction_bond_check_bid } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import { useEffect } from "react";
import NavbarSec from "../../common/NavbarSec";
import { Link } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const Auction2 = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();

  const [getauction2Data, setAuction2Data] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ismodalopen, setIsModalOpen] = useState(false);
  const [geterrmsg, setIsErrMsg] = useState("");
  const userId = localStorage.getItem("user_id");

  const GetAuction = () => {
    setIsLoading(true);

    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_all_auction_bond_check_bid, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);

        console.log("====================================");
        console.log("Respon------------------------>", Response.data);
        console.log("====================================");
        if (Response.data.status == 1) {
          setAuction2Data(Response.data.result);
        } else {
          setIsModalOpen(true);
          setIsErrMsg(
            "Currently there are no active auctions that you can participate in."
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetAuction();
  }, []);
  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (ismodalopen) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [ismodalopen, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* <div className="page_navbar_wrapp">
          <div className="nav_back_wrapp">
            <img src={images.back} className="nav_back_btn" alt="" />
          </div>
          <p className="nav_screen_name">Auction</p>
        </div> */}
        {/* <NavbarSec name="Auction" /> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Auction</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>

        <div className="auction_products_wrapp">
          {/* auction single button start */}

          {getauction2Data && getauction2Data.length > 0 ? (
            getauction2Data.map((item, index) => {
              return (
                <div
                  className="auction_single_product_wrapp"
                  key={item.auction_id}
                >
                  <Link
                    to={`${
                      item.is_club_id == "" ? "/UserProfile" : "/Clubprofile"
                    }`}
                    state={{
                      user_id: item.is_club_id == "" ? item.user_id : "",
                      club_id: item.is_club_id !== "" ? item.is_club_id : "",
                    }}
                    className="auction_product_username_wrapp"
                  >
                    <img src={item.profile} alt="" />
                    <p>{item.username}</p>
                  </Link>

                  <Link className="auction_product_image_wrapp">
                    <img
                      src={
                        item.product_image_video &&
                        item.product_image_video[0].img_video
                      }
                      alt=""
                      className="auction_product_img"
                    />
                    <div className="auction_product_blur_view">
                      <p className="auction_product_name">
                        {item.product_name}
                      </p>
                      <p className="auction_product_des">{item.description}</p>
                      <div className="auction_bid_btn_wrapp">
                        <div className="auction_bid_price_wrapp">
                          <p>current bid</p>
                          <span>₹{item.current_highest_bid}</span>
                        </div>
                        {item.is_user_owner == 0 ? (
                          <button className="auction_bid_btn">Bid now</button>
                        ) : null}
                      </div>
                    </div>

                    <div className="auction_stock_cube_wrapp">
                      <p>{item.total_available_bond_bid}</p>
                      <p>Stock</p>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : (
            <div className="auction_nodata_text">
              Currently ther are no active auctions
            </div>
          )}

          {/* auction single button end */}
        </div>
      </div>

      {ismodalopen && (
        <>
          {/* <div className="modal_login">
            <div className="modal-content_login">
              <div className="modal_div1">
                <img className="forgot_main" src={images.logo} alt="" />
              </div>
              <div className="modal_div">
                <b>{""}</b>
                <br />
                <p className="modal-message"> {geterrmsg ? geterrmsg : ""}</p>
                <br />
              </div>
              <hr className="hr_forgot" />
              <br />
              <div className="modal_div">
                <button
                  className="close_forgot"
                  onClick={() => {
                    setIsModalOpen(false);
                    // window.location.goback();
                    navigate(-1);
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div> */}

          {/* <ReactModal
            isOpen={setIsModalOpen}
            onRequestClose={closemodal}
            style={customStyles}
          >
            <div className="NewModal_main_wrapp">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img className="newmodal_logo" src={images.logo} alt="" />
                <p className="newmodal_titel"></p>
              </div>

              <p className="newmodal_des">{geterrmsg ? geterrmsg : ""}</p>

              <button
                className="newmodal_btn"
                onClick={() => {
                  setIsModalOpen(false);

                  navigate(-1);
                }}
              >
                Okay
              </button>
            </div>
          </ReactModal> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={geterrmsg}
            setShowModal={setIsModalOpen}
            okfunction={() => {
              setIsModalOpen(false);
              // window.location.goback();
              navigate(-1);
            }}
          />
        </>
      )}
    </div>
  );
};

export default Auction2;
