import React, { useState } from "react";
import "./NewOtpScreen.css";
import images from "../../constants/images";
import { FaSortDown } from "react-icons/fa";
import axios from "axios";
import {
  get_all_country2,
  user_add_phone_v2,
  user_ph_em_login,
} from "../../utils/Constant";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewLoader from "../../common/newloder/NewLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { genarateToken, messaging } from "../../firebase";
import { onMessage } from "firebase/messaging";
// import NewLoginVerifyOTP from "./pages/newverifyotp/NewLoginVerifyOTP";
import Urls from "../../utils/Urls";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

const NewOtpScreen = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [getphoneNumber, setPhoneNumber] = useState("");
  const [getallCountry, setAllCountry] = useState([]);
  const [getcountryCode, setCountryCode] = useState("+91");
  const [getcountryimg, setCountryimg] = useState(
    "https://flagcdn.com/w320/in.png"
  );
  const [searchContry, setSearchContry] = useState("");
  const [getloading, setLoading] = useState(false);
  const [getloginModal, setLoginModal] = useState(false);
  const [getemail, setEmail] = useState(false);

  const [gettoken, setToken] = useState("");

  const navigate = useNavigate();

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchContry(event.target.value);
  };

  // Filtered list of countries based on search term
  const filteredCountries = getallCountry.filter((option) =>
    option.Country_code.toLowerCase().includes(searchContry.toLowerCase())
  );

  const handleSearchClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    GetAllContry();
  }, []);

  // all contry api start

  const GetAllContry = async () => {
    setLoading(true);
    await axios
      .get("https://www.ravi.host/api/get_country_flag")
      .then((res) => {
        console.log(JSON.stringify(res.data.result, null, 2));
        setLoading(false);
        setAllCountry(res.data.result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // all contry api end

  // add phone api start

  const AddPhoneNumber = async () => {
    if (getphoneNumber == "") {
      toast.error("Please enter a mobile number", { theme: "colored" });
    } else if (getphoneNumber.length != 10) {
      toast.error("Please enter a valid mobile number", { theme: "colored" });
    } else {
      setLoading(true);
      const params = {
        phone: getphoneNumber,
        // country_code: getcountryCode,
        country_code: "+91",
        player_id: gettoken,
      };
      await axios
        .post(user_add_phone_v2, params)
        .then((res) => {
          setLoading(false);
          if (res.data.status == 1) {
            toast.success(res.data.massage, { theme: "colored" });
            setEmail(res.data.email);
            if (res.data.step == 1) {
              navigate("/NewVerifyOtp", {
                state: {
                  user_id: res.data.user_id,
                  phone: res.data.phone,
                },
              });
            } else if (res.data.step == 2) {
              navigate("/NewEmailVerification", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 3) {
              navigate("/NewEmailOtpVerify", {
                state: {
                  user_id: res.data.user_id,
                  email: res.data.email,
                },
              });
            } else if (res.data.step == 35) {
              navigate("/UserChargeScreen", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 4) {
              navigate("/NewSetPassword", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 5) {
              navigate("/NewAadhaarCard", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 6) {
              navigate("/NewPancardScreen", {
                state: {
                  user_id: res.data.user_id,
                  pan_no: res.data.pan_no,
                },
              });
            } else if (res.data.step == 7) {
              navigate("/NewGstScreen", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 8) {
              navigate("/NewFounderVideoScreen", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 9) {
              navigate("/NewInterests", {
                state: {
                  user_id: res.data.user_id,
                },
              });
            } else if (res.data.step == 10) {
              // navigate("/login");
              setLoginModal(true);
            }
          } else {
            toast.error(res.data.message, { theme: "colored" });
          }
          // localStorage.setItem("profile", Response.data.result.username);
        })
        .catch((error) => {
          toast.error(error, { theme: "colored" });

          setLoading(false);
        });
    }
  };

  // add phone api end

  const formatPhoneNumber = (inputValue) => {
    // Remove any existing spaces
    let formattedText = inputValue.replace(/ /g, "");

    // console.log("formattedText", formattedText);

    if (formattedText.length > 10) {
      formattedText = formattedText.slice(0, 10); // Limit the input to 12 characters
    }

    formattedText = formattedText.replace(/\s+/g, " ");
    setPhoneNumber(formattedText);
    const tdata = formattedText.replace(/ /g, "");

    // console.log("formattedText", tdata);
    return formattedText;
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is a digit or a space
    if (!(e.key.match(/[0-9]/) || e.key === " " || e.key === "Backspace")) {
      e.preventDefault();
    }
  };

  const handleOptionClick = (option) => {
    if (option.Country_code == "+91") {
      setCountryCode(option.Country_code);
      setCountryimg(option.uri);
      setSelectedOption(option);
    } else {
      toast.error("This is intended solely for residents of India.", {
        theme: "colored",
      });
    }

    setIsOpen(false);
    // onSelect(option);
  };

  const fetchData = async () => {
    const token = await genarateToken();
    // console.log("Token:", token);
    return token;
  };

  fetchData()
    .then((token) => {
      console.log("Token: 1", token); // This will log the token value
      setToken(token);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  const handleOTPLogin = async (istype, phone) => {
    setLoading(true);
    const param = {
      mo_no: phone,
      is_type: istype,
      user_ip_address: "103.105.235.242",
      player_id: "123",
      token: "",
    };
    await axios
      .post(user_ph_em_login, param, {})
      .then((res) => {
        if (res.data.status == 1) {
          navigate("/NewLoginVerifyOTP", {
            state: {
              phone: istype == 1 ? res.data.phone : res.data.email,
              is_type: istype,
              user_id: res.data.user_id,
              countries_code: res.data.countries_code,
            },
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [run, setRun] = useState(false);

  const steps = [
    {
      target: ".play_screen_login_btn", // CSS selector of the target element
      content: (
        <h2>
          Let's begin our journey! <br /> Tap next to start : ){" "}
        </h2>
      ),
      color: "red",
    },
    {
      target: ".step2",
      content: "This is step 2",
    },
    {
      target: ".step3",
      content: "This is step 3",
    },
  ];

  const handleTourEnd = (data) => {
    const { status } = data;

    if (status === STATUS.FINISHED) {
      console.log("Tour ended");
      setRun(false);
    }
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {/* <Joyride
        steps={steps}
        run={run}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        disableOverlayClose={true}
        disableScrollParentFix={true}
        disableCloseOnEsc={true}
        disableScrolling={true}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#007bff",
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#fff",
            textColor: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        callback={handleTourEnd}
      /> */}
      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "45vh", minHeight: "200px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div className="otp_screen_text_container">
              <h4>Get Started</h4>
              <p>Enter mobile number to get the verification code.</p>
            </div>

            <div className="new_otp_input_container">
              <div className="new_otp_input_1st_sec">
                <div>
                  <div
                    className="new_otp_custom_dropdown"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <div className="new_otp_selected_option">
                      <img
                        src={getcountryimg}
                        alt="Country Logo"
                        className="country-logo"
                      />
                      {/* {selectedOption ? selectedOption.name : "Select a country"} */}
                    </div>
                    {/* <FaSortDown color="#fff" size={14} /> */}
                    <img
                      src={images.new_down_arrow}
                      className="new_otp_down_aroow"
                      alt=""
                    />
                    <div className="new_otp_vr" />
                    <p className="new_otp_mobile_code">{getcountryCode}</p>
                    {/* {isOpen && (
                      <div className="new_otp_options">
                        {getallCountry.map((option) => (
                          <div
                            key={option.name}
                            className="new_otp_contry_option"
                            onClick={() => handleOptionClick(option)}
                          >
                            <img
                              src={option.image.uri}
                              alt="Country Logo"
                              className="country-logo"
                            />
                            <p>{option.Country_code}</p>
                          </div>
                        ))}
                      </div>
                    )} */}

                    {isOpen && (
                      <div className="new_otp_options">
                        <input
                          type="text"
                          placeholder="Search country..."
                          value={searchContry}
                          onChange={handleSearchChange}
                          onClick={handleSearchClick}
                          className="contry_search_input"
                        />
                        {filteredCountries.map((option) => (
                          <div
                            key={option.Country_code}
                            className="new_otp_contry_option"
                            onClick={() => handleOptionClick(option)}
                          >
                            <img
                              src={option.uri}
                              alt="Country Logo"
                              className="country-logo"
                            />
                            <p>{option.Country_code}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="new_otp_input_2nd_sec">
                <input
                  // type="number"
                  name="mobile"
                  onKeyPress={handleKeyPress}
                  onChange={(e) => formatPhoneNumber(e.target.value)}
                  value={getphoneNumber}
                  className=""
                  inputMode="numeric"
                />
              </div>
            </div>

            {/* btn */}
            <button
              disabled={
                getphoneNumber == "" || getphoneNumber.length != 10
                  ? true
                  : false
              }
              onClick={AddPhoneNumber}
              className="new_btn_wrapp mx-1 mt-3"
            >
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      )}
      <ToastContainer />

      {/* login modal start */}

      {getloginModal && (
        <>
          <div className="play_screen_login_modal_wrapp">
            {/* <button onClick={() => setRun(true)}>Start Tour</button> */}

            <div className="play_screen_login_inner_wrapp">
              <div className="play_screen_login_inner_2">
                <p className="play_screen_login_heading">
                  Do you want to <br />
                  login with?
                </p>
                <div className="play_screen_login_hr"></div>
                <button
                  className="play_screen_login_btn"
                  onClick={() => handleOTPLogin(1, getphoneNumber)}
                >
                  Mobile OTP
                </button>
                <div className="play_screen_login_hr2">
                  <p>or</p>
                </div>
                <button
                  className="play_screen_login_btn"
                  onClick={() => handleOTPLogin(2, getemail)}
                >
                  Email OTP
                </button>
                <div className="play_screen_login_hr2">
                  <p>or</p>
                </div>
                <button
                  className="play_screen_login_btn"
                  onClick={() => {
                    navigate("/login", {
                      state: {
                        phone: getphoneNumber,
                      },
                    });
                  }}
                >
                  Password
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* login modal end */}
    </div>
  );
};

export default NewOtpScreen;
