import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./homesearch.css";
import BackButton from "../../components/BackButton";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useHomeContext } from "../../context/home_context";
import NavbarSec from "../../common/NavbarSec";
import { FaAngleLeft } from "react-icons/fa";

const Homesearch = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const { getDashboardlatest5, is_loading, home_latest5_data } =
    useHomeContext();
  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tab_id, setTabId] = useState(1);
  const [searchUserData, setSearchUserData] = useState([]);
  const [searchClubData, setSearchClubData] = useState([]);
  const [searchBondData, setSearchBondData] = useState([]);
  const [sData, setSData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");

  useEffect(() => {
    _getData();
  }, []);

  const _getData = () => {
    const params = {
      user_id: userId,
    };

    console.log(params);

    axios
      .post(
        "https://www.ravi.host/api/get_user_search_history",
        qs.stringify(params)
      )
      .then((res) => {
        console.log(
          "SearchData=---=-==-=-=-=-===-=------>",
          JSON.stringify(res.data.result, null, 2)
        );
        setSearchData(res.data.result);
      });
  };

  // const renderSearchItem2 = ({ item }) => (
  //   <div
  //     onClick={() => {
  //       setSearchQuery(item.search_data);
  //       handleInputChange(item.search_data);
  //     }}
  //     style={{
  //       flexDirection: "row",
  //       padding: "14px",
  //       borderRadius: "10px",
  //       marginBottom: "10px",
  //       alignItems: "flex-start",
  //       justifyContent: "flex-start",
  //       width: "100%",
  //       backgroundColor: "#2b2b2b",
  //       boxShadow: "0px 12px 16px 0px rgba(0,0,0,0.58)",
  //       borderRadius: "24px",
  //       overflow: "hidden",
  //       display: "flex",
  //       alignItems: "center",
  //     }}
  //   >
  //     <div
  //       style={{
  //         height: "50px",
  //         width: "50px",
  //         borderRadius: "50px",
  //         padding: "10px",
  //         backgroundColor: "#898989",
  //         marginRight: "10px",
  //         overflow: "hidden",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <img
  //         src={require("../img/search.png")} // Adjust the path to your image
  //         style={{ height: "80%", width: "80%" }}
  //         alt="Search Icon"
  //       />
  //     </div>
  //     <div style={{ width: "60%" }}>
  //       <span
  //         style={{ color: "#fff", fontSize: "15px", fontFamily: "Gr_bold" }}
  //       >
  //         {item.search_data}
  //       </span>
  //       <span style={{ color: "#fff", fontSize: "12px" }}>
  //         {moment(item.created_at).format("Do MMMM YYYY")}
  //       </span>
  //     </div>
  //   </div>
  // );

  const handleInputChange = (query) => {
    setSearchQuery(query);

    const param = {
      key: query,
      user_id: userId,
    };

    console.log("param", param);
    axios
      .post("https://www.ravi.host/api/search_detail", qs.stringify(param))
      .then((response) => {
        console.log("====================================,", response.data);
        if (response.data.status === 1) {
          setSearchUserData(response.data.result.user_array);
          setSearchClubData(response.data.result.club_array);
          setSearchBondData(response.data.result.bond_array);
        } else {
          setIsModalVisible(true);
          setCusmsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        // Handle error appropriately
      });
  };

  useEffect(() => {
    console.log("searchUserData", searchUserData);
  }, []);

  return (
    <div className="homesearch-container">
      <div className="homesearch_1"></div>
      <div className="homesearch_2">
        <div className="web_user_menu">
          <div className="homesearch_header">
            <div className="homesearch_main_header_notifi">
              {/* <NavbarSec name="Search" /> */}
              <div
                className="legacy_dashbord_nav_sec"
                style={{ margin: "10px auto" }}
              >
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Search</p>
                <div></div>
                {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
              </div>
              {/* <div className="div_main1">
                <button
                  onClick={() => navigate("/homescreen2")}
                  className="back_img_all"
                >
                  <img src={images.back} alt="" className="all_back" />
                </button>
              </div> */}
              <br />
              {/* <input
                type="text"
                placeholder="Search"
                className="user_homesearch_search"
                onChange={(e) => {
                  handleInputChange(e.target.value);
                }}
              /> */}
              <div style={{}} className="bid13_search">
                <div
                  className="bids13_user_bid_main"
                  style={{ paddingTop: "5px" }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    className="bids13_user_bid_search"
                    // value={searchTerm}
                    onChange={(e) => {
                      handleInputChange(e.target.value);
                    }}
                  />
                  {/* <img src={images.search} alt="" className="search_ic" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="general_club_user">
            {/* <div className="search_home_main">
              <img src={images.logo} className="homesearch_icon" alt="" />
              <div>
                <h1 className="search_home_h1"> Ravi Bhai </h1>
                <p className="search_home_p">12th august 2023</p>
              </div>
            </div> */}
            {searchQuery == "" ? (
              <div
                style={{
                  width: "100%",
                  // margin: "0 3%",
                  marginTop: "20px",
                }}
              >
                {/* Render the search results  start*/}
                <>
                  {searchData &&
                    searchData.map((item, index) => (
                      <div key={index} className="club_manager_gradinat_wrapp">
                        <div
                          onClick={() => {
                            setSearchQuery(item.search_data);
                            handleInputChange(item.search_data);
                          }}
                          className="main_manager_club_set"
                          // style={{
                          //   flexDirection: "row",
                          //   padding: "14px",
                          //   borderRadius: "10px",
                          //   marginBottom: "10px",
                          //   justifyContent: "flex-start",
                          //   width: "100%",
                          //   backgroundColor: "#2b2b2b",
                          //   boxShadow: "0px 12px 16px 0px rgba(0,0,0,0.58)",
                          //   overflow: "hidden",
                          //   display: "flex",
                          //   alignItems: "center",
                          // }}
                        >
                          <div
                            style={{
                              height: "45px",
                              width: "45px",
                              borderRadius: "45px",
                              padding: "10px",
                              backgroundColor: "#898989",
                              marginRight: "10px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={images.search} // Adjust the path to your image
                              style={{ height: "90%", width: "90%" }}
                              alt="Search Icon"
                            />
                          </div>
                          <div
                            style={{
                              width: "60%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "16px",
                                fontFamily: "var(--font-600)",
                              }}
                            >
                              {item.search_data}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                fontFamily: "var(--font-300)",
                              }}
                            >
                              {moment(item.created_at).format("Do MMMM YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
                {/* Render the search results  end*/}

                {/* Render top creator's start*/}
                <>
                  <div className="search_creator_name_wrapp">
                    <p className="search_creator_name">Top Creators</p>
                  </div>
                  {home_latest5_data.user_array &&
                  home_latest5_data.user_array.length > 0
                    ? home_latest5_data.user_array.map((item, index) => (
                        <div className="club_manager_gradinat_wrapp">
                          <div
                            onClick={() => {
                              navigate("/UserProfile", {
                                state: {
                                  user_id: item.user_id,
                                },
                              });
                            }}
                            className="main_manager_club_set"
                            key={index}
                          >
                            <div
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "45px",
                                // padding: "10px",
                                backgroundColor: "#898989",
                                // marginRight: "10px",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ width: "45px", height: "45px" }}>
                                <img
                                  src={item.profile} // Adjust the path to your image
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                  alt="Search Icon"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "87%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                  fontFamily: "var(--font-600)",
                                  alignSelf: "start",
                                }}
                              >
                                {item.username}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  fontFamily: "var(--font-300)",
                                  alignSelf: "start",
                                }}
                              >
                                {item.firstname}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </>
                {/* Render top creator's end*/}
              </div>
            ) : (
              <>
                <div className="main_home_search">
                  <div class="box">
                    <input
                      type="radio"
                      class="tab-toggle"
                      name="tab-toggle"
                      id="tab1"
                      defaultChecked
                      onChange={() => {
                        setTabId(1);
                      }}
                    />
                    <input
                      type="radio"
                      class="tab-toggle"
                      name="tab-toggle"
                      id="tab2"
                      onChange={() => {
                        setTabId(2);
                      }}
                    />
                    <input
                      type="radio"
                      class="tab-toggle"
                      name="tab-toggle"
                      id="tab3"
                      onChange={() => {
                        setTabId(3);
                      }}
                    />
                    <ul class="tab-list">
                      {/* <li class="tab-item">
                        <label class="tab-trigger" for="tab1">
                          Users
                        </label>
                      </li>
                      <li class="tab-item">
                        <label class="tab-trigger" for="tab2">
                          Clubs
                        </label>
                      </li>
                      <li class="tab-item">
                        <label class="tab-trigger" for="tab3">
                          Rarerez
                        </label>
                      </li> */}
                    </ul>

                    {/* tab buttons wrapp start */}

                    <div className="tab_btns_wrapp">
                      <button
                        onClick={() => {
                          setTabId(1);
                        }}
                        className="tab_single_btn"
                        style={{
                          backgroundColor:
                            tab_id == 1 ? "var(--color-main)" : "transparent",
                          flex: tab_id == 1 ? 0.4 : 0.3,
                        }}
                      >
                        {tab_id == 1 ? (
                          <img src={images.Users_tab} alt="" />
                        ) : null}
                        <p>Users</p>
                      </button>
                      <button
                        onClick={() => {
                          setTabId(2);
                        }}
                        className="tab_single_btn"
                        style={{
                          backgroundColor:
                            tab_id == 2 ? "var(--color-main)" : "transparent",
                          flex: tab_id == 2 ? 0.4 : 0.3,
                        }}
                      >
                        {tab_id == 2 ? (
                          <img src={images.Clubs_tab} alt="" />
                        ) : null}
                        <p>Clubs</p>
                      </button>
                      <button
                        onClick={() => {
                          setTabId(3);
                        }}
                        className="tab_single_btn"
                        style={{
                          backgroundColor:
                            tab_id == 3 ? "var(--color-main)" : "transparent",
                          flex: tab_id == 3 ? 0.4 : 0.3,
                        }}
                      >
                        {tab_id == 3 ? (
                          <img src={images.logo_white} alt="" />
                        ) : null}
                        <p>Rarerez</p>
                      </button>
                    </div>

                    {/* tab buttons wrapp end */}

                    <div class="tab-container">
                      {tab_id == 1 ? (
                        <>
                          <div class="tab-content">
                            <div className="all_bond">
                              {searchUserData.length > 0 ? (
                                searchUserData.map((item, index) => (
                                  <div className="club_manager_gradinat_wrapp">
                                    <div
                                      onClick={() => {
                                        navigate("/UserProfile", {
                                          state: {
                                            user_id: item.user_id,
                                          },
                                        });
                                      }}
                                      className="main_manager_club_set"
                                      key={index}
                                      // style={{ marginBottom: "10px" }}
                                    >
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      >
                                        <img
                                          src={item.profile}
                                          className="homesearch_icon"
                                          alt=""
                                        />
                                      </div>
                                      <div style={{ width: "80%" }}>
                                        <h1 className="search_home_h1">
                                          {item.username}
                                        </h1>
                                        <p className="search_home_p">
                                          {item.firstname}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p>No data available</p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : tab_id == 2 ? (
                        <>
                          {/* <div class="tab-content">
                          <div className="all_bond"> */}
                          {searchClubData.length > 0 ? (
                            searchClubData.map((item, index) => {
                              // console.log(item);
                              return (
                                <div className="club_manager_gradinat_wrapp">
                                  <div
                                    onClick={() => {
                                      navigate("/Clubprofile", {
                                        state: {
                                          club_id: item.club_id,
                                        },
                                      });
                                    }}
                                    className="main_manager_club_set"
                                    key={index}
                                    // style={{ marginBottom: "10px" }}
                                  >
                                    {/* <button
                                  className="search_home_main"
                                  style={{
                                    marginBottom: "10px",
                                    alignItems: "center",
                                  }}
                                > */}
                                    <img
                                      src={item.profile}
                                      className="homesearch_icon"
                                      alt=""
                                    />
                                    <div>
                                      <h1 className="search_home_h1">
                                        {" "}
                                        {item.club_name}{" "}
                                      </h1>
                                      {/* <p className="search_home_p">
                                      {item.firstname}{" "}
                                    </p> */}
                                    </div>
                                    {/* </button> */}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p>No data available</p>
                          )}
                          {/* </div>
                        </div> */}
                        </>
                      ) : tab_id == 3 ? (
                        <>
                          {searchBondData &&
                            searchBondData.map((item, index) => {
                              return (
                                <div className="club_manager_gradinat_wrapp">
                                  <div
                                    onClick={() => {
                                      navigate("/productView", {
                                        state: {
                                          release_bond_id: item.release_bond_id,
                                          product_id: item.product_id,
                                          is_nfb: item.is_nfb,
                                          uniq_code: item.uniq_code,
                                          theme_index: item.theme_index,
                                          share_by_user: item.share_by_user,
                                          club_id: item.club_id,
                                        },
                                      });
                                    }}
                                    className="main_manager_club_set"
                                    key={index}
                                    style={{ alignItems: "center" }}
                                  >
                                    <img
                                      src={item.bond_img_video[0].img_video}
                                      className="homesearch_icon"
                                      alt=""
                                    />
                                    <div style={{}}>
                                      <h1 className="search_home_h1">
                                        {" "}
                                        {item.product_name}
                                      </h1>
                                      <p className="search_home_p">
                                        {item.owner_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homesearch;
