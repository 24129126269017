// export const BASEURL = "https://rarerez.club/blockchain_2/api/";
// export const BASEURL = "https://gokulnair.com/blockchain_2/api/";
export const BASEURL = "https://www.ravi.host/api/";
export const AssetLink = "https://www.ravi.host/public/assets/interest/";
// export const AssetLink =
//   "https://www.ravi.host/blockchain_2/public/assets/interest/";
// export const AssetLink =
//   "https://gokulnair.com/blockchain_2/public/assets/interest/";

export const user_register = BASEURL + "user_register";

export const user_login = BASEURL + "login";
export const user_otp = BASEURL + "verify_and_active_user";
export const email_verify_and_active_user =
  BASEURL + "email_verify_and_active_user";
export const user_resend_otp = BASEURL + "user_resend_otp";

export const user_resend_email_otp = BASEURL + "user_resend_email_otp";
export const user_reset_phone_number = BASEURL + "user_reset_phone_number";
export const user_reset_email_phone_number =
  BASEURL + "user_reset_email_phone_number";
export const forgotPassword = BASEURL + "forgotPassword";

export const get_all_interest = BASEURL + "get_all_interest";
export const add_user_seleted_interest = BASEURL + "add_user_seleted_interest";
export const get_all_country = BASEURL + "getallcountry";
export const getstatebycountry = BASEURL + "getstatebycountry";
export const getcitybystate = BASEURL + "getcitybystate";
export const get_user_emailcheck = BASEURL + "check_is_user_email";
export const get_user_usercheck = BASEURL + "check_is_user";
export const get_user_number = BASEURL + "check_is_user_phone_no";
export const list_of_release_bond = BASEURL + "list_of_release_bond";
export const release_bond_like = BASEURL + "release_bond_like";
export const add_release_bond_comment = BASEURL + "add_release_bond_comment";
export const get_user_profile = BASEURL + "get_user_profile";
export const add_followers_following = BASEURL + "add_followers_following";
export const change_profile = BASEURL + "change_profile";

// bond
export const create_bond = BASEURL + "create_bond";

// bid
export const get_all_bid = BASEURL + "get_all_bid";
export const create_block_bid = BASEURL + "create_bid";
export const get_platform_fees = BASEURL + "platform_fees";
export const get_release_bond_highest_bid =
  BASEURL + "get_release_bond_highest_bid";
export const accept_bond_bid = BASEURL + "accept_bond_bid";
export const get_all_accept_bond = BASEURL + "get_all_accept_bond_bid";
export const get_all_release_bond_bid = BASEURL + "get_all_release_bond_bids";
export const demo_1 = BASEURL + "demo_1";
export const reject_accepted_bid = BASEURL + "reject_accepted_bid";
export const reject_bid = BASEURL + "reject_bid";
export const get_all_accepted_user_bids =
  BASEURL + "list_of_accepted_user_benifit";
export const get_user_accepted_bond = BASEURL + "get_user_accepted_bond";
export const get_single_accepted_user_bond =
  BASEURL + "get_single_accepted_user_bond";

// club
export const get_block_allclub = BASEURL + "get_block_allclub";
export const get_all_club_detail = BASEURL + "get_all_club_detail";
export const get_all_user_club = BASEURL + "get_all_user_club";
export const create_club = BASEURL + "create_club";
export const club_join = BASEURL + "club_join";

// dashboard
export const get_latest_5_bond_3_club = BASEURL + "get_latest_5_bond_3_club";

// 24-08

export const get_all_user_product = BASEURL + "get_all_user_product";
export const get_all_club_product_assign_bond =
  BASEURL + "get_all_club_product_assign_bond";
export const get_all_user_after_acution_product =
  BASEURL + "get_all_user_after_acution_product";

/// user_wallet GET
export const user_wallet = BASEURL + "user_wallet";

// user_wallet_recharge

export const recharge_user_wallet = BASEURL + "recharge_user_wallet";

// user_wallet_withdrew
export const user_withdrawal_request = BASEURL + "user_withdrawal_request";

// clubs
export const get_all_advertisement = BASEURL + "get_all_advertisement";
export const get_all_club_2 = BASEURL + "get_all_club_2";

// auction
export const get_all_auction_bond_2 = BASEURL + "get_all_auction_bond_2";

// edit_profile
export const edit_profile = BASEURL + "edit_profile";
export const get_all_user2 = BASEURL + "get_all_user2";

// get_user_general_notification
export const get_user_general_notification =
  BASEURL + "get_user_general_notification_2";

// list_of_release_bond_2
export const list_of_release_bond_2 = BASEURL + "list_of_release_bond_2";

//list_of_release_bond_3
export const list_of_release_bond_3 = BASEURL + "list_of_release_bond_3";

// --------

// get_user_pending_verify_product
export const get_user_pending_verify_product =
  BASEURL + "get_user_pending_verify_product";

// get_all_user_selected_interest
export const get_all_user_selected_interest =
  BASEURL + "get_all_user_selected_interest";

// get_user_benifit_reward_claim
export const get_user_benifit_reward_claim =
  BASEURL + "get_user_benifit_reward_claim";

// reward_claim_user_product_accept
export const reward_claim_user_product_accept =
  BASEURL + "reward_claim_user_product_accept";

// get_single_product_bond_detail
export const get_single_product_bond_detail =
  BASEURL + "get_single_product_bond_detail";

/// get_single_product_bond_detail_3
export const get_single_product_bond_detail_3 =
  BASEURL + "get_single_product_bond_detail_3";

// get_single_product_bond_detail
export const get_single_product_bond_detail_user =
  BASEURL + "get_single_product_bond_detail_user";

// ----vishal ------------------------

// edit_profile

/// getall_user for create product creator name

export const getall_user = BASEURL + "getall_user";

// get_all_release_bond

export const get_all_release_bond = BASEURL + "get_all_release_bond";

// add_showcase_my_collection
export const add_showcase_my_collection =
  BASEURL + "add_showcase_my_collection";

// get_all_user_Resale_product

export const get_all_user_Resale_product =
  BASEURL + "get_all_user_Resale_product";

// add_showcase_my_collection

export const add_showcase_my_collections =
  BASEURL + "add_showcase_my_collection";

// get_user_requested_bond_list

export const get_user_requested_bond_list =
  BASEURL + "get_user_requested_bond_list";

// user_accept_reject_bond

export const user_accept_reject_bond = BASEURL + "user_accept_reject_bond";

// get_user_whishlist

export const get_user_whishlist = BASEURL + "get_user_whishlist";

// delete_whishlist

export const delete_whishlist = BASEURL + "delete_whishlist";

// user_update
export const user_update = BASEURL + "user_update";
// delete_user_manager
export const delete_user_manager = BASEURL + "delete_user_manager";

// add_user_manager
export const add_user_manager = BASEURL + "add_user_manager";

// user_Reset_new_email_with_old_email

export const user_Reset_new_email_with_old_email =
  BASEURL + "user_Reset_new_email_with_old_email";
// update_user_mobile_number

export const update_user_mobile_number = BASEURL + "update_user_mobile_number";

/// verify_and_active_New_Mobile_Number

export const verify_and_active_New_Mobile_Number =
  BASEURL + "verify_and_active_New_Mobile_Number";

// user_resend_otp_in_new_mobile

export const user_resend_otp_in_new_mobile =
  BASEURL + "user_resend_otp_in_new_mobile";

// user_resend_otp_in_new_mobile
export const user_resend_email_otp_with_new_email =
  BASEURL + "user_resend_email_otp_with_new_email";
// Reset_email_verify_and_active_user
export const Reset_email_verify_and_active_user =
  BASEURL + "Reset_email_verify_and_active_user";

// 02-09-2023 vishal
// user_Send_otp_for_bond
export const user_Send_otp_for_bond = BASEURL + "user_Send_otp_for_bond";
export const user_Set_m_pin = BASEURL + "user_Set_m_pin";

// vishal 02-09-2023
// get_all_benifit_category

export const get_all_benifit_category = BASEURL + "get_all_benifit_category";
export const get_all_user_manager = BASEURL + "get_all_user_manager";
export const product_bond_benifit = BASEURL + "product_bond_benifit";

export const bid_withdraw_amount_status =
  BASEURL + "bid_withdraw_amount_status";
export const get_user_pending_bid = BASEURL + "get_user_pending_bid";

// 04-09-2023
// get_user_pending_bid
// export const get_user_pending_bid = BASEURL + "get_user_pending_bid";

// bid_withdraw_amount_status
// export const bid_withdraw_amount_status =
// BASEURL + "bid_withdraw_amount_status";

// user_Send_otp_for_bond
// export const user_Send_otp_for_bond = BASEURL + "user_Send_otp_for_bond";
// export const user_Set_m_pin = BASEURL + "user_Set_m_pin";

// get_all_benifit_category

// export const get_all_benifit_category = BASEURL + "get_all_benifit_category";
// export const get_all_user_manager = BASEURL + "get_all_user_manager";
// export const product_bond_benifit = BASEURL + "product_bond_benifit";

// platform_fees
export const platform_fees = BASEURL + "platform_fees_2";

// get_user_balance
export const get_user_balance = BASEURL + "get_user_balance";

// user_Check_m_pin
export const user_Check_m_pin = BASEURL + "user_Check_m_pin";
// update_bid
export const update_bid = BASEURL + "update_bid";

// club_product_history

export const product_history = BASEURL + "product_history";

// 05-09-2023

export const platform_feesbid = BASEURL + "platform_fees";

export const create_bid = BASEURL + "create_bid";

// vishla
export const is_club_id = BASEURL + "is_club_id";
// preview_product
export const preview_product = BASEURL + "preview_product";

// 08-09-2023

export const draft_bond_api_part_1 = BASEURL + "draft_bond_api_part_1";
// 09-09-2023
export const create_bond_v2 = BASEURL + "create_bond_v2";

// 12-09-2023
// jalpesh
export const get_user_club_member_detail =
  BASEURL + "get_user_club_member_detail";

export const bond_img_video_delete = BASEURL + "bond_img_video_delete";
export const get_club_join_fees = BASEURL + "get_club_join_fees";
export const club_check_is_user_email = BASEURL + "club_check_is_user_email";
export const club_check_is_user_phone_no =
  BASEURL + "club_check_is_user_phone_no";
export const verify_and_active_club = BASEURL + "verify_and_active_club";
export const club_resend_otp = BASEURL + "club_resend_otp";
// club_resend_email_otp
export const club_resend_email_otp = BASEURL + "club_resend_email_otp";

// club_reset_email_phone_number
export const club_reset_email_phone_number =
  BASEURL + "club_reset_email_phone_number";

// club_resend_otp
export const club_email_verify_and_active_user =
  BASEURL + "club_email_verify_and_active_user";
// club_reset_phone_number
export const club_reset_phone_number = BASEURL + "club_reset_phone_number";

// 12-09-2023
// vishal
// my_collection_get_user_accepted_bond

export const my_collection_get_user_accepted_bond =
  BASEURL + "my_collection_get_user_accepted_bond";

// get_all_Resale_interest_product

export const get_all_Resale_interest_product =
  BASEURL + "get_all_Resale_interest_product";

// resale_user_get_product

export const resale_user_get_product = BASEURL + "resale_user_get_product";

// 13-09-2023

export const club_get_all_release_bond = BASEURL + "club_get_all_release_bond";
export const get_club_follow = BASEURL + "get_club_follow";
export const club_follow = BASEURL + "club_follow";
export const free_club_join = BASEURL + "free_club_join";
export const create_club_product = BASEURL + "create_club_product";

// 15-09-2023

export const get_single_partner_benifit_detail =
  BASEURL + "get_single_partner_benifit_detail";
export const single_product_bond_benifit_detail =
  BASEURL + "single_product_bond_benifit_detail";

// 18-09-2023
export const add_release_bond_whishlist =
  BASEURL + "add_release_bond_whishlist";
// 19-09-2023
export const user_adhar_is_verify = BASEURL + "user_adhar_is_verify";
export const get_all_auction_bond_check_bid =
  BASEURL + "get_all_auction_bond_check_bid";
export const club_product_history = BASEURL + "club_product_history";

// 20-09-2023

export const club_preview_product = BASEURL + "club_preview_product";
export const club_draft_bond_api_part_1 =
  BASEURL + "club_draft_bond_api_part_1";
export const resale_product_benifit = BASEURL + "resale_product_benifit";
export const Resale_product_history = BASEURL + "Resale_product_history";

// 26-09-2023
// 26-09-2023
export const club_wallet = BASEURL + "club_wallet";
export const club_withdrawal_request = BASEURL + "club_withdrawal_request";
export const get_user_profile_video = BASEURL + "get_user_profile_video";
export const get_manager_all_user = BASEURL + "get_manager_all_user";
export const get_club_requested_bond_list =
  BASEURL + "get_club_requested_bond_list";
export const club_accept_reject_bond = BASEURL + "club_accept_reject_bond";
export const check_is_user = BASEURL + "check_is_user";
export const user_forgot_password = BASEURL + "user_forgot_password";
export const check_user_club_is_member = BASEURL + "check_user_club_is_member";
export const get_single_product_bond_detail_all_buyer =
  BASEURL + "get_single_product_bond_detail_all_buyer";
export const contact_us_user = BASEURL + "contact_us_user";
export const getPincodes = BASEURL + "getPincodes";
export const get_all_title = BASEURL + "get_all_title";
export const get_club_profile_video = BASEURL + "get_club_profile_video";
export const get_user_place_order_data = BASEURL + "get_user_place_order_data";
export const get_ecommerce_product = BASEURL + "get_ecommerce_product";
export const product_cart = BASEURL + "product_cart";
export const get_product_ditails = BASEURL + "get_product_ditails";
export const get_user_cart_product_data =
  BASEURL + "get_user_cart_product_data";
export const update_bond_img = BASEURL + "update_bond_img";
export const get_total_available_bond_and_value =
  BASEURL + "get_total_available_bond_and_value";
export const add_original_file_asset = BASEURL + "add_original_file_asset";
export const get_original_file_asset = BASEURL + "get_original_file_asset";
export const update_club_bond_img = BASEURL + "update_club_bond_img";

///
export const show_request = BASEURL + "show_request";
export const get_indian_user = BASEURL + "get_indian_user";
export const free_partner_bond_benifit_1 =
  BASEURL + "free_partner_bond_benifit_1";

export const get_terms_condition = BASEURL + "get_terms_condition";
export const user_change_password = BASEURL + "user_change_password";
// new apis

export const get_all_country2 = BASEURL + "get_all_country";
export const user_add_phone_v2 = BASEURL + "user_add_phone_v2";
export const user_resend_otp_v2 = BASEURL + "user_resend_otp_v2";
export const verify_mobile_otp_v2 = BASEURL + "verify_mobile_otp_v2";
export const user_add_email_v2 = BASEURL + "user_add_email_v2";
export const verify_email_otp_v2 = BASEURL + "verify_email_otp_v2";
export const user_resend_email_otp_v2 = BASEURL + "user_resend_email_otp_v2";
export const user_add_password_v2 = BASEURL + "user_add_password_v2";
export const adhar_init = BASEURL + "adhar_init";
export const adhar_submit_otp_v2 = BASEURL + "adhar_submit_otp_v2";
export const add_user_pan_card_v2 = BASEURL + "add_user_pan_card_v2";
export const user_add_bank_detail_v2 = BASEURL + "user_add_bank_detail_v2";
export const add_user_gst_v2 = BASEURL + "add_user_gst_v2";
export const user_gst_skip_v2 = BASEURL + "user_gst_skip_v2";
export const user_add_video_v2 = BASEURL + "user_add_video_v2";
export const user_add_interest_v2 = BASEURL + "user_add_interest_v2";
export const user_forgot_password_v2 = BASEURL + "user_forgot_password_v2";
export const add_new_inter_user_v2 = BASEURL + "add_new_inter_user_v2";
export const get_release_bond = BASEURL + "get_release_bond";
export const conform_benifits = BASEURL + "conform_benifits";
export const delete_user_pending_bond = BASEURL + "delete_user_pending_bond";
export const get_user_legacy = BASEURL + "get_user_legacy";
export const get_legacy_milestone = BASEURL + "get_legacy_milestone_profile";
export const add_user_legacy = BASEURL + "add_user_legacy";
export const delete_user_family = BASEURL + "delete_user_family";
export const delete_user_community = BASEURL + "delete_user_community";
export const get_milestone_details = BASEURL + "get_milestone_details";
export const get_user_year_list = BASEURL + "get_user_year_list";
export const get_mileston_bond_detail = BASEURL + "get_mileston_bond_detail";

// ---------------------- create product version 2 ----------------------

export const add_user_pending_bond_step_1 =
  BASEURL + "add_user_pending_bond_step_1";
export const add_user_pending_bond_step_2 =
  BASEURL + "add_user_pending_bond_step_2";
export const add_user_pending_bond_step_3 =
  BASEURL + "add_user_pending_bond_step_3";
export const add_user_pending_bond_step_4 =
  BASEURL + "add_user_pending_bond_step_4";
export const add_user_pending_bond_step_5 =
  BASEURL + "add_user_pending_bond_step_5";
export const add_user_pending_bond_step_6 =
  BASEURL + "add_user_pending_bond_step_6";
export const add_user_pending_bond_step_7 =
  BASEURL + "add_user_pending_bond_step_7";
export const add_user_pending_bond_step_8 =
  BASEURL + "add_user_pending_bond_step_8";
export const add_user_pending_bond_step_9 =
  BASEURL + "add_user_pending_bond_step_9";
export const add_user_pending_bond_step_10 =
  BASEURL + "add_user_pending_bond_step_10";
export const add_user_pending_bond_step_11 =
  BASEURL + "add_user_pending_bond_step_11";
export const add_user_pending_bond_step_12 =
  BASEURL + "add_user_pending_bond_step_12";
export const add_user_pending_bond_step_13 =
  BASEURL + "add_user_pending_bond_step_13";

export const get_user_pending_bond = BASEURL + "get_user_pending_bond";
export const delete_user_bond_media_link =
  BASEURL + "delete_user_bond_media_link";
export const add_user_grup_benifit = BASEURL + "add_user_grup_benifit";

// ---------------------- create product version 2 ----------------------

// ---------------------- international user apis start ---------------------
export const get_inter_user_v2 = BASEURL + "get_inter_user_v2";

export const inter_user_email_verify_v2 =
  BASEURL + "inter_user_email_verify_v2";

export const verify_inter_user_email_otp_v2 =
  BASEURL + "verify_inter_user_email_otp_v2";
export const inter_user_resend_email_otp_v2 =
  BASEURL + "inter_user_resend_email_otp_v2";
export const inter_user_reset_email_phone_number =
  BASEURL + "inter_user_reset_email_phone_number";
export const inert_user_add_password_v2 =
  BASEURL + "inert_user_add_password_v2";
export const inert_user_add_identity_v2 =
  BASEURL + "inert_user_add_identity_v2";
export const inter_user_pay_con = BASEURL + "inter_user_pay_con";
export const ind_user_pay_con = BASEURL + "ind_user_pay_con";
// ---------------------- international user apis end -----------------------

// club create product v2 start ---------------------

export const add_club_pending_bond_step_1 =
  BASEURL + "add_club_pending_bond_step_1";
export const add_club_pending_bond_step_2 =
  BASEURL + "add_club_pending_bond_step_2";
export const add_club_pending_bond_step_3 =
  BASEURL + "add_club_pending_bond_step_3";
export const add_club_pending_bond_step_4 =
  BASEURL + "add_club_pending_bond_step_4";
export const add_club_pending_bond_step_5 =
  BASEURL + "add_club_pending_bond_step_5";
export const add_club_pending_bond_step_6 =
  BASEURL + "add_club_pending_bond_step_6";
export const add_club_pending_bond_step_7 =
  BASEURL + "add_club_pending_bond_step_7";
export const add_club_pending_bond_step_8 =
  BASEURL + "add_club_pending_bond_step_8";
export const add_club_pending_bond_step_9 =
  BASEURL + "add_club_pending_bond_step_9";
export const add_club_pending_bond_step_10 =
  BASEURL + "add_club_pending_bond_step_10";
export const add_club_pending_bond_step_11 =
  BASEURL + "add_club_pending_bond_step_11";
export const add_club_pending_bond_step_12 =
  BASEURL + "add_club_pending_bond_step_12";
export const add_club_pending_bond_step_13 =
  BASEURL + "add_club_pending_bond_step_13";

// club create product v2 end -----------------------

// c & W start -------------------

export const get_user_pending_c_and_w = BASEURL + "get_user_pending_c_and_w";
export const add_user_pending_c_and_w = BASEURL + "add_user_pending_c_and_w";
export const add_c_and_w_bond_request = BASEURL + "add_c_and_w_bond_request";

// c & W end ---------------------

export const user_ph_em_login = BASEURL + "user_ph_em_login";
export const user_login_otp_atch = BASEURL + "user_login_otp_atch";

// joyride api's
export const add_joyride = BASEURL + "add_joyride";
export const get_user_joyride = BASEURL + "get_user_joyride";

export const get_year_bond = BASEURL + "get_year_bond";
