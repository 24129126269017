import React, { useEffect, useState } from "react";
import "./bnefits.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Slider from "react-slick";
import {
  get_single_partner_benifit_detail,
  single_product_bond_benifit_detail,
} from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const ViewBenefits = () => {
  const location = useLocation();
  const [is_loading, setIsLoading] = useState(false);
  const [getmodalOpen, setModalOpen] = useState(false);
  const [getpartnerData, setPartnerData] = useState({});

  // -----------

  const [benifit_price, setbenifit_price] = useState(0);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [benifit_icon, setbenifit_icon] = useState([]);
  const [timeRemaining, settimeRemaining] = useState(1);
  const [terms, setterms] = useState("");
  const [manager_data, setmanager_data] = useState([]);
  const [benifit_galary, setbenifit_galary] = useState([]);

  // -----------

  useEffect(() => {
    // GetProductBenefit();
    // console.log("location", location.state);
    if (location.state.partner == 1) {
      GetPartnerBenefit();
    } else {
      GetProductBenefit();
    }
  }, []);

  const GetPartnerBenefit = () => {
    setIsLoading(true);
    const param = {
      is_nfb: location.state.is_nfb,
      partner_benifit_id: location.state.partner_benifit_id,
    };
    axios
      .post(get_single_partner_benifit_detail, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log(
          "get_single_partner_benifit_detail Res",
          JSON.stringify(Response.data, null, 2)
        );
        console.log("====================================");

        if (Response.data.status == 1) {
          // this.setState({
          setbenifit_price(Response.data.result.benifit_price);
          setname(
            Response.data.result.benifit_name +
              " " +
              Response.data.result.partner_name
          );
          setdescription(Response.data.result.benifit_desc);
          setbenifit_icon(Response.data.result?.benifit_image);
          setterms(Response.data.result.benifit_terms_condition);
          settimeRemaining(Response.data.result.valid_time);
          setmanager_data(Response.data.result.manager_data);
          setbenifit_galary(Response.data.result.benifit_galary);
          setPartnerData(Response.data.result);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("get_single_partner_benifit_detail errr", error);
      });
  };

  const GetProductBenefit = () => {
    setIsLoading(true);
    const param = {
      is_nfb: location.state.is_nfb,
      pro_bo_ben_id: location.state.pro_bo_ben_id,
    };
    console.log("param--->", param);
    axios
      .post(single_product_bond_benifit_detail, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log(
          "single_product_bond_benifit_detail Res",
          JSON.stringify(Response.data, null, 2)
        );
        console.log("====================================");
        if (Response.data.status == 1) {
          setbenifit_price(Response.data.result.benifit_price);
          setname(Response.data.result.reward_name);
          setdescription(Response.data.result.description);
          setPartnerData(Response.data.result);
          setbenifit_icon(Response.data.result?.reward_img_video);
          setterms(Response.data.result.terms_condition);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("get_single_partner_benifit_detail errr", error);
      });
  };

  const sliderSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per interaction
  };
  const navigate = useNavigate();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    console.log(
      "bid_benifit_last_date: item.bid_benifit_last_date,",
      location.state.message
    );
  }, []);

  useEffect(() => {
    const countdownDate = new Date(
      location.state.bid_benifit_last_date
    ).getTime();

    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      } else {
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [location.state.bid_benifit_last_date]);
  const [descriptionMsg, setDescriptionMsg] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [con, setCon] = useState(false);

  const handleClaim = () => {
    setIsLoading(true);

    const param = {
      reward_id: location.state.reward_id,
      user_history_id: location.state.user_history_id,
      user_id: location.state.user_id,
      message: descriptionMsg,
    };

    console.log("====================================");
    console.log("param", param);
    console.log("====================================");

    axios
      .post(
        "https://www.ravi.host/api/user_request_reward_claim",
        qs.stringify(param)
      )
      .then((Response) => {
        if (Response.data.status == 1) {
          setCon(true);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        } else {
          setCon(false);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }
      });
  };

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (getmodalOpen || isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [getmodalOpen, isModalVisible, bodyScrollEnabled]);

  const closemodal = () => {
    setModalOpen(false);
  };
  const openmodal = () => {
    setModalOpen(true);
  };

  return (
    <div className="page_newmain_wrapp">
      <div className="page_newbase_wrapp">
        {/* <div className="page_newnavbar_wrapp">
          <div className="nav_back_wrapp">
            <img
              src={images.back}
              className="nav_back_btn"
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <p className="nav_screen_name">Benefits</p>
        </div> */}
        {/* navbar start */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Benefits</p>
          <div></div>
        </div>
        {/* navbar end */}
        <div>
          <div className="benefits_club_user">
            <h1 style={{}} className="benefits_name1">
              {name}
            </h1>
            <p
              style={{
                marginTop: "10px",
                textAlign: "start",
                fontSize: "12px",
                fontFamily: "var(--main-200)",
              }}
              className="benefits_name2"
            >
              {description}
            </p>
            <div style={{ marginTop: "10px" }}>
              <Slider {...sliderSettings}>
                {benifit_icon && benifit_icon.length > 0
                  ? benifit_icon.map((item, index) => {
                      console.log("item: ", item);
                      return (
                        <div style={{ width: "100px" }}>
                          <img
                            src={item.img_video}
                            className="Collaboratenfb_img1"
                            alt=""
                          />
                        </div>
                      );
                    })
                  : null}
              </Slider>
            </div>
          </div>
          <center>
            {benifit_price && (
              <h1 className="benefit_price">₹{benifit_price}</h1>
            )}
          </center>
        </div>
        {/* <center> */}

        <div
          style={{ position: "absolute", marginTop: "10px", bottom: "-10px" }}
          className="details_of_benefit"
        >
          <div
            style={{
              backgroundColor: "#302d2d",
              display: "flex",
              flexDirection: "column",
              gap: "-11px",
              padding: "10px",
              borderRadius: "4px",
            }}
            className="sub_benefit"
          >
            <button
              // style={{  backgroundColor: '#c2c2c257',
              //   height: 'fit-content',
              //   padding: '20px',
              //   display: 'flex',
              //   flexDirection: 'row',
              //   justify-content: center,
              //   align-items: center,
              //   border-radius: 25px,
              //   color: white,
              //   font-family: var(--font-800),
              //   font-weight: 300,
              //   letter-spacing: 2px,
              //   font-size: 20px,
              //   width: 100%,
              //   position: relative,}}
              onClick={() => setModalOpen(true)}
              className="benefits_btn_details"
            >
              <img src={images.terms_white} className="img_benefits" alt="" />
              <p
                style={{
                  textAlign: "start",
                  fontSize: "15px",
                  fontFamily: "var(--main-200)",
                }}
                className="benefits_name2"
              >
                Terms & condition{" "}
              </p>
            </button>
            {/* <Link to="/ReportPage" className="benefits_btn_details">
              <img src={images.need} className="img_benefits" alt="" />
              Need help?
            </Link> */}
          </div>
        </div>
      </div>

      {getmodalOpen && (
        <>
          {/* <CusModal msg={terms} setShowModal={setModalOpen} /> */}
          {/* <NewModal
            title=""
            msg={terms}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={terms}
            setShowModal={setModalOpen}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default ViewBenefits;
