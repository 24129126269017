import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import "./termsandcondition.css";
import BackButton from "../../components/BackButton";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Termsandcondition = () => {
  const [htmlContent, setHtmlContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Make the API call to get the terms and conditions HTML
    axios
      .get("https://www.ravi.host/api/get_terms_condition")
      .then((response) => {
        console.log(response.data.result);
        // Assuming the API returns HTML content in the 'response.data' field
        setHtmlContent(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Termsandcondition-container">
      <div className="Termsandcondition_2">
        <div className="web_user_menu">
          <div
            style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
          >
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Terms & Conditions</p>
              <div></div>
            </div>
          </div>
          <div
            style={{
              color: "var(--color-white)",
              fontFamily: "var(--font-400)",
              fontSize: "14px",
              // fontWeight: 200,
            }}
            className="Termsandcondition_details"
            dangerouslySetInnerHTML={{ __html: htmlContent[0]?.details }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default Termsandcondition;
