import React, { useEffect, useState } from "react";
import "./benefitshow.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
const Benefitshow = () => {
  const navigate = useNavigate();
  const [partnerData, setPartnerData] = useState([]);
  const [partnerBidValue, setPartnerBidValue] = useState(0);
  const location = useLocation();
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const param = {
      uniq_code: location.state.uniq_code,
      is_nfb: location.state.is_nfb,
      product_id: location.state.product_id,
      user_id: userId,
    };

    try {
      const response = await axios.post(
        "https://www.ravi.host/api/get_release_bond_partner_benifit",
        qs.stringify(param)
      );

      console.log("Res---", response.data);

      setPartnerData(response.data.result);
      if (response.data.result.length > 0) {
        setPartnerBidValue(response.data.result[0].benifit_price);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [amount, setAmount] = useState(location.state.amount);

  const validationWith = () => {
    const selectedItems = partnerData.filter((item) => item.is_selected === 1);
    const partner_id = selectedItems.map((item) => item.partner_id);
    const benifit_price = selectedItems.map((item) => item.benifit_price);
    const partner_benifit_id = selectedItems.map(
      (item) => item.partner_benifit_id
    );

    const partner_idsString = partner_id.join(", ");
    const benifit_price_string = benifit_price.join(", ");
    const partner_benifit_id_sString = partner_benifit_id.join(", ");

    if (amount === 0) {
      setIsModalVisible(true);
      setCusmsg("Enter amount");
    } else if (parseInt(amount) < parseInt(location.state.minimum_bid_amount)) {
      setIsModalVisible(true);
      setCusmsg("Enter big value bid from minimum");
    } else if (partnerBidValue === 0) {
      setIsModalVisible(true);
      setCusmsg("Enter partner bid value");
    } else if (
      parseInt(partnerBidValue) < parseInt(partnerData[0].benifit_price)
    ) {
      setIsModalVisible(true);
      setCusmsg("Enter amount above bid value");
    } else {
      navigate("/bidscreate", {
        state: {
          share_by_user: location.state.share_by_user,
          accept_bond_bid_id: location.state.accept_bond_bid_id,
          is_globle: location.state.is_globle,
          is_club: location.state.is_club,
          release_bond_id: location.state.release_bond_id,
          amount: location.state.amount,
          uniq_code: location.state.uniq_code,
          is_nfb: location.state.is_nfb,
          product_id: location.state.product_id,
          partned_id: partnerData[0].partner_id,
          partner_benifit_id: partnerData[0].partner_benifit_id,
          benifit_price:
            parseInt(partnerData[0].benifit_price) +
            parseInt((partnerData[0].benifit_price * 1) / 100),
          product_assign_id: partnerData[0].product_assign_id,
          original_partner_benifit_price: partnerData[0].benifit_price,
          product_name: location.state.product_name,
        },
      });
      // props.navigation.navigate("PayoutBid", {
      //   accept_bond_bid_id: location.state.accept_bond_bid_id,
      //   is_globle: location.state.is_globle,
      //   is_club: location.state.is_club,
      //   release_bond_id: location.state.release_bond_id,
      //   amount,
      //   uniq_code: location.state.uniq_code,
      //   is_nfb: location.state.is_nfb,
      //   product_id: location.state.product_id,
      //   partned_id: props.partnerData[0].partner_id,
      //   partner_benifit_id: props.partnerData[0].partner_benifit_id,
      //   benifit_price: parseInt(props.partnerData[0].benifit_price) + parseInt((props.partnerData[0].benifit_price * 1) / 100),
      //   product_assign_id: props.partnerData[0].product_assign_id,
      // });
    }
  };

  const nnn = () => {
    navigate("/bidscreate", {
      state: {
        share_by_user: location.state.share_by_user,
        accept_bond_bid_id: location.state.accept_bond_bid_id,
        is_globle: location.state.is_globle,
        is_club: location.state.is_club,
        release_bond_id: location.state.release_bond_id,
        amount: location.state.amount,
        uniq_code: location.state.uniq_code,
        is_nfb: location.state.is_nfb,
        product_id: location.state.product_id,
        partned_id: "",
        partner_benifit_id: "",
        benifit_price: 0,
        product_assign_id: "",
        product_name: location.state.product_name,
      },
    });
  };

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="benefitsmain_wrapp">
      <div className="benefitsbase_wrapp">
        <div className="benefitsnavbar_wrapp">
          <div className="benefits_navs">
            <img
              src={images.back}
              className="nav_back_btns"
              alt=""
              onClick={() => navigate(-1)}
            />
          </div>
          <p className="nav_screen_names">Benefits</p>
        </div>
        <div className="benefitshow_main">
          <p className="nm_start_benefit">BENEFITS FOR YOU</p>
          <img src={images.star} className="benefit_star" alt="" />
          <div className="line_start"></div>
        </div>
        <br />
        {partnerData &&
          partnerData.map((item, index) => {
            return (
              <center>
                <div className="benefitshow_1">
                  <b>Benefits</b>
                  <br />
                  <p>{item.partner_name}</p>
                  <p> {item.benifit_name}</p>
                  <img
                    src={item.partner_profile}
                    className="benefitshow_images"
                    alt=""
                  />
                </div>
                <div className="benefitshow_second">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="price_nm_box">Price</p>
                    <p className="price_nm_box">
                      ₹{item.benifit_price + (item.benifit_price * 1) / 100}
                    </p>
                  </div>
                  <p className="price_nm_box">(1% platform fee incl)</p>
                </div>
              </center>
            );
          })}
        <div className="btn_with_benefit">
          <button
            onClick={() => {
              validationWith();
            }}
            className="contiune_with"
          >
            Continue with benefit
          </button>
          <button onClick={nnn} className="contiune_with">
            Continue without benefit
          </button>
        </div>
      </div>
      {isModalVisible && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>{""}</b>
        //       <br />
        //       <p className="modal-message"> {cusmsg}</p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           setIsModalVisible(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <NewModal
        //   title=""
        //   msg={cusmsg}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Benefitshow;
