import React, { useEffect, useState } from "react";
import "../../src/pages/userbankaccount/userbankaccount.css";
import images from "../../src/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../src/common/NavbarSec";
import axios from "axios";
import ReactModal from "react-modal";
import V2Modal from "../common/v2modal/V2Modal";

const Newbankaccountadd = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [userid, setUserid] = useState(location.state.user_id); // Initialize with your default user_id or set it using some other mechanism
  const [getacno, setGetacno] = useState("");
  const [getconformacno, setGetconformacno] = useState("");
  const [backholdername, setBackholdername] = useState("");
  const [bankname, setBankname] = useState("");
  const [ifscno, setIfscno] = useState("");
  const [con, setcon] = useState(false);

  const [cusmsg, setCusmsg] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    // Fetch user's bank details when the component mounts
    // get_bankdetail();
  }, []);

  const get_bankdetail = () => {
    const formdata = new FormData();
    formdata.append("user_id", userid);
    setLoading(true);

    axios
      .post("https://www.ravi.host/api/get_user_bank_detail", formdata)
      .then((Response) => {
        if (Response.data.status === 1) {
          setGetacno(Response.data.result[0].bank_account_number);
          setGetconformacno(Response.data.result[0].bank_account_number);
          setBackholdername(Response.data.result[0].bank_holder_name);
          setBankname(Response.data.result[0].bank_name);
          setIfscno(Response.data.result[0].ifsc_code);
        }
        setLoading(false);
        console.log("Response", Response.data);
      });
  };
  const handleBankNameChange = (e) => {
    setBankname(e.target.value);
  };

  const handleBankHolderNameChange = (e) => {
    setBackholdername(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setGetacno(e.target.value);
  };

  const handleConfirmAccountNumberChange = (e) => {
    setGetconformacno(e.target.value);
  };

  const handleIFSCCodeChange = (e) => {
    setIfscno(e.target.value);
  };

  const create_bankdetail = () => {
    if (getacno === "") {
      setIsModalVisible(true);
      setCusmsg("Please enter Account Number");
      setcon(false);
    } else if (getacno != getconformacno) {
      setIsModalVisible(true);
      setCusmsg(
        "The two account numbers you entered do not match each other. Please ensure that both account numbers are the same before proceeding"
      );
      setcon(false);
    } else if (ifscno === "") {
      setIsModalVisible(true);
      setCusmsg("Please enter IFSC code");
      setcon(false);
    } else {
      const formdata = new FormData();
      formdata.append("user_id", userid);
      // formdata.append("bank_name", bankname);
      // formdata.append("bank_holder_name", backholdername);
      formdata.append("bank_account_number", getacno);
      formdata.append("ifsc_code", ifscno);
      setLoading(true);
      console.log(formdata);
      axios
        .post("https://www.ravi.host/api/user_bank_detail", formdata)
        .then((Response) => {
          console.log("asccdzsvdsavds", Response.data);
          setLoading(false);
          if (Response.data.status === 1) {
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
            setcon(true);

            // SimpleToast.show(Response.data.message);
            // Do something after success, e.g., navigate to a different screen
            // this.props.navigation.goBack('');
          } else {
            setIsModalVisible(true);
            setCusmsg(Response.data.message);
            setcon(false);
          }
          console.log("Response", Response.data);
        });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Fill Bank Details" />
        {/* <div className="club_bank_set">
          <b>Bank Name</b>
          <input type="text" value={bankname} onChange={handleBankNameChange} />
        </div>
        <div className="club_bank_set">
          <b>Bank Holder Name</b>
          <input
            type="text"
            value={backholdername}
            onChange={handleBankHolderNameChange}
          />
        </div> */}
        <div className="club_bank_set mt-3">
          <b>Account Number</b>
          <input
            type="number"
            value={getacno}
            onChange={handleAccountNumberChange}
          />
        </div>
        <div className="club_bank_set">
          <b>Confirm Account Number</b>
          <input
            type="number"
            value={getconformacno}
            onChange={handleConfirmAccountNumberChange}
          />
        </div>
        <div className="club_bank_set">
          <b>IFSC Code</b>
          <input type="text" value={ifscno} onChange={handleIFSCCodeChange} />
        </div>

        <center>
          <button
            onClick={() => {
              create_bankdetail();
            }}
            className="bank_btn_set"
          >
            Submit
          </button>
        </center>
      </div>
      {/* {isModalVisible && (
        <div className="modal_login">
          <div className="modal-content_login">
            <div className="modal_div1">
              <img className="forgot_main" src={images.logo} alt="" />
            </div>
            <div className="modal_div">
              <b>{""}</b>
              <br />
              <p className="modal-message"> {cusmsg}</p>
              <br />
            </div>
            <hr className="hr_forgot" />
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  if (con == true) {
                    navigate("/AutherVideo", {
                      state: {
                        user_id: location.state.user_id,
                      },
                    });
                  }

                  setIsModalVisible(false);
                  // console.log(con);
                  // con == 2
                  //   ? navigate("/homescreen2")
                  //   : con == 1
                  //   ? navigate("/wallet")
                  //   : setcusmodal1(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )} */}

      {/*       <ReactModal
        isOpen={isModalVisible}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closemodal}
        style={customStyles}
      >
        <div className="NewModal_main_wrapp">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="newmodal_logo" src={images.logo} alt="" />
            <p className="newmodal_titel"></p>
          </div>

          <p className="newmodal_des">{cusmsg}</p>

          <button
            className="newmodal_btn"
            onClick={() => {
              if (con == true) {
                navigate("/AutherVideo", {
                  state: {
                    user_id: location.state.user_id,
                  },
                });
                setIsModalVisible(false);
              } else {
                setIsModalVisible(false);
              }
            }}
          >
            Okay
          </button>
        </div>
      </ReactModal> */}

      <V2Modal
        canclebtn={false}
        title=""
        msg={cusmsg}
        setShowModal={setIsModalVisible}
        okfunction={() => {
          if (con == true) {
            navigate("/AutherVideo", {
              state: {
                user_id: location.state.user_id,
              },
            });
            setIsModalVisible(false);
          } else {
            setIsModalVisible(false);
          }
        }}
      />
    </div>
  );
};

export default Newbankaccountadd;
