import React, { useState } from "react";
import "./addcontact.css";
import NavbarSec from "../../common/NavbarSec";

const Addcontact = () => {
  const [searchValue, setSearchValue] = useState("");

  const contactNames = [
    "Vishal",
    "John",
    "Emily",
    "Michael",
    "Sarah",
    "David",
    "Emma",
    "Daniel",
    "Olivia",
    "Sophia"
  ];

  const filteredContactNames = contactNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp">
        <NavbarSec name="Add contact Person" />
        <br />
        <div>
          <input
            type="text"
            placeholder="Search"
            className="viewmanagers_search"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        <div>
          {filteredContactNames.map((name, index) => (
            <p className="add_contact_name" key={index}>
              {name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Addcontact;
