import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  GET_ALL_NFBBLOCKBID_FAIL,
  GET_ALL_NFBBLOCKBID_BEGIN,
  GET_ALL_NFBBLOCKBID_SUCCESS,
  CREATE_NFBBLOCKBID_BEGIN,
  CREATE_NFBBLOCKBID_SUCCESS,
  CREATE_NFBBLOCKBID_FAIL,
  GET_PLATFORM_FEES_BEGIN,
  GET_PLATFORM_FEES_SUCCESS,
  GET_PLATFORM_FEES_FAIL,
  GET_HIGHEST_BID_BEGIN,
  GET_HIGHEST_BID_SUCCESS,
  GET_HIGHEST_BID_FAIL,
  ACCEPT_BOND_BID_BEGIN,
  ACCEPT_BOND_BID_SUCCESS,
  ACCEPT_BOND_BID_FAIL,
  GET_ALL_ACCEPT_BOND_BID_BEGIN,
  GET_ALL_ACCEPT_BOND_BID_SUCCESS,
  GET_ALL_ACCEPT_BOND_BID_FAIL,
  GET_ALL_RELEASE_BOND_BIDS_BEGIN,
  GET_ALL_RELEASE_BOND_BIDS_SUCCESS,
  GET_ALL_RELEASE_BOND_BIDS_FAIL,
  GET_DEMO_1_BEGIN,
  GET_DEMO_1_SUCCESS,
  GET_DEMO_1_FAIL,
  REJECT_ACCEPTED_BID_BEGIN,
  REJECT_ACCEPTED_BID_SUCCESS,
  REJECT_ACCEPTED_BID_FAIL,
  REJECT_BID_BEGIN,
  REJECT_BID_SUCCESS,
  REJECT_BID_FAIL,
  GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN,
  GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS,
  GET_ALL_ACCEPTED_USER_BENIFITS_FAIL,
  GET_USER_ACCEPTED_BOND_BEGIN,
  GET_USER_ACCEPTED_BOND_SUCCESS,
  GET_USER_ACCEPTED_BOND_FAIL,
  GET_SINGLE_ACCEPTED_USER_BOND_BEGIN,
  GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS,
  GET_SINGLE_ACCEPTED_USER_BOND_FAIL,
} from "../Action";
import {
  get_all_bid,
  create_block_bid,
  get_platform_fees,
  get_release_bond_highest_bid,
  accept_bond_bid,
  get_all_accept_bond,
  get_all_release_bond_bid,
  demo_1,
  reject_accepted_bid,
  reject_bid,
  get_all_accepted_user_bids,
  get_user_accepted_bond,
  get_single_accepted_user_bond,
} from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import user_blockbid from "../reducer/nfb_blockbid_reducer";

const initialState = {
  get_allclubbid_loading: false,
  create_blockbid_loading: false,
  get_allclubbid_data: [],
  create_blockbid_data: [],
  fees_loading: false,
  fees_data: [],
  highest_bid_loading: false,
  highest_bid_data: [],
  accept_bond_bid_loading: false,
  accept_bond_bid_data: [],
  accept_all_bond_bid_loading: false,
  accept_all_bond_bid_data: [],
  release_bond_bids_loading: false,
  release_bond_bids_data: [],
  demo_1_loading: false,
  demo_1_data: [],
  reject_accepted_bid_loading: false,
  reject_accepted_bid_data: [],
  reject_bid_loading: false,
  reject_bid_data: [],
  list_of_accepted_user_benifits_loading: false,
  list_of_accepted_user_benifits_data: [],
  get_user_accepted_bond_loading: false,
  get_user_accepted_bond_data: [],
  get_single_accepted_user_bond_loading: false,
  get_single_accepted_user_bond_data: [],
};

const Userblockbidcontext = React.createContext();
export const UserBlockbidProvider = ({ children }) => {
  const [state, dispatch] = useReducer(user_blockbid, initialState);
  // register

  const get_all_bids = async () => {
    dispatch({ type: GET_ALL_NFBBLOCKBID_BEGIN });
    try {
      const response = await axios.get(get_all_bid);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_ALL_NFBBLOCKBID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_NFBBLOCKBID_FAIL });
    }
  };
  //// create bid
  const Create_bid = async (params) => {
    dispatch({ type: CREATE_NFBBLOCKBID_BEGIN });
    try {
      const response = await axios.post(create_block_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: CREATE_NFBBLOCKBID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: CREATE_NFBBLOCKBID_FAIL });
    }
  };

  //// platform fees
  const get_platform_fees = async () => {
    dispatch({ type: GET_PLATFORM_FEES_BEGIN });
    try {
      const response = await axios.get(get_platform_fees);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_PLATFORM_FEES_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: GET_PLATFORM_FEES_FAIL });
    }
  };

  // get_release_bond_highest_bid
  const get_highest_bid = async (params) => {
    dispatch({ type: GET_HIGHEST_BID_BEGIN });
    try {
      const response = await axios.post(get_release_bond_highest_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_HIGHEST_BID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: GET_HIGHEST_BID_FAIL });
    }
  };

  /// Accept bold bid
  const Accept_bond_bid = async (params) => {
    dispatch({ type: ACCEPT_BOND_BID_BEGIN });
    try {
      const response = await axios.post(accept_bond_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: ACCEPT_BOND_BID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: ACCEPT_BOND_BID_FAIL });
    }
  };

  // get_all_accept_bond_bid
  const get_all_accept_bond_bid = async (params) => {
    dispatch({ type: GET_ALL_ACCEPT_BOND_BID_BEGIN });
    try {
      const response = await axios.post(get_all_accept_bond, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({
          type: GET_ALL_ACCEPT_BOND_BID_SUCCESS,
          payload: registerdata,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_ACCEPT_BOND_BID_FAIL });
    }
  };

  // get_all_release_bond_bids
  const get_all_release_bond_bids = async (params) => {
    dispatch({ type: GET_ALL_RELEASE_BOND_BIDS_BEGIN });
    try {
      const response = await axios.post(get_all_release_bond_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({
          type: GET_ALL_RELEASE_BOND_BIDS_SUCCESS,
          payload: registerdata,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_RELEASE_BOND_BIDS_FAIL });
    }
  };

  //GET_DEMO_1_BEGIN

  const get_demo_1 = async (params) => {
    dispatch({ type: GET_DEMO_1_BEGIN });
    try {
      const response = await axios.post(demo_1, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_DEMO_1_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: GET_DEMO_1_FAIL });
    }
  };

  // Reject_accepted_bid

  const Reject_accepted_bid = async (params) => {
    dispatch({ type: REJECT_ACCEPTED_BID_BEGIN });
    try {
      const response = await axios.post(reject_accepted_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: REJECT_ACCEPTED_BID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: REJECT_ACCEPTED_BID_FAIL });
    }
  };

  // Reject_bid

  const Reject_bid = async (params) => {
    dispatch({ type: REJECT_BID_BEGIN });
    try {
      const response = await axios.post(reject_bid, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: REJECT_BID_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: REJECT_BID_FAIL });
    }
  };

  // ALL_ACCEPTED_USER_BENIFITS

  const Get_all_accepted_user_bids = async (params) => {
    dispatch({ type: GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN });
    try {
      const response = await axios.post(get_all_accepted_user_bids, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({
          type: GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS,
          payload: registerdata,
        });
      }
    } catch (error) {
      dispatch({ type: GET_ALL_ACCEPTED_USER_BENIFITS_FAIL });
    }
  };

  // Get_user_accepted_bond

  const Get_user_accepted_bond = async (params) => {
    dispatch({ type: GET_USER_ACCEPTED_BOND_BEGIN });
    try {
      const response = await axios.post(get_user_accepted_bond, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({
          type: GET_USER_ACCEPTED_BOND_SUCCESS,
          payload: registerdata,
        });
      }
    } catch (error) {
      dispatch({ type: GET_USER_ACCEPTED_BOND_FAIL });
    }
  };

  // get_single_accepted_user_bond
  const Get_single_accepted_user_bond = async (params) => {
    dispatch({ type: GET_SINGLE_ACCEPTED_USER_BOND_BEGIN });
    try {
      const response = await axios.post(get_single_accepted_user_bond, params);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({
          type: GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS,
          payload: registerdata,
        });
      }
    } catch (error) {
      dispatch({ type: GET_SINGLE_ACCEPTED_USER_BOND_FAIL });
    }
  };

  return (
    <Userblockbidcontext.Provider
      value={{
        ...state,
        get_all_bids,
        Create_bid,
        get_platform_fees,
        get_highest_bid,
        Accept_bond_bid,
        get_all_accept_bond_bid,
        get_all_release_bond_bids,
        get_demo_1,
        Reject_accepted_bid,
        Reject_bid,
        Get_all_accepted_user_bids,
        Get_user_accepted_bond,
        Get_single_accepted_user_bond,
      }}
    >
      {children}
    </Userblockbidcontext.Provider>
  );
};

export const useBlockBidContext = () => {
  return useContext(Userblockbidcontext);
};
