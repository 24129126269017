import React, { useState } from "react";
import "./NewInternationalStripePage.css";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { log } from "debug/src/browser";
import { loadStripe } from "@stripe/stripe-js";
import Urls from "../../utils/Urls";

const NewInternationalStripePage = () => {
  const nav = useNavigate();
  const location = useLocation();
  const [response, setResponsesession] = useState(null);
  const [getdata, setData] = useState();
  //   const [redirectToInvitation, setRedirectToInvitation] = useState(false);

  // Storing data in sessionStorage
  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };

  // Retrieving data from sessionStorage
  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || [];
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        "https://www.ravi.host/api/get_inter_user_v2"
      );
      if (response.status === 200) {
        // setRedirectToInvitation(true);
        console.log("api call successfull", response.data);
        nav("/Recievedinvitaion_2");
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const stripePromise = loadStripe(
    "pk_live_51ODMf4SIZS16AN382v1WLRYA0m5T0xqKIjkWwJCYo2eLmEVR6JlSxQM1MIgvQNl6NJewKCQBR2F6JqMqhKrhm5O7000ONaUVem"
  );

  const makePayment = async () => {
    try {
      const apiUrl = "https://www.ravi.host/api/add_inter_user_pay_web";
      const datas = {
        user_id: location.state.user_id,
        amount: 1, // Replace with the desired amount
        // amount: getplatform_fees_data.stripe_total, // Replace with the desired amount
        success_url: ` ${Urls.main_url}NewInternationalStripeSuccess`, // Replace with your success URL
        fail_url: `${Urls.main_url}Fail`, // Replace with your fail URL
      };
      console.log("dddd", datas);
      const response = await axios.post(apiUrl, datas);
      console.log("Response: " + JSON.stringify(response.data.success));
      // Handle the response data as needed
      setResponsesession(response.data.session_id);
      if (response.data.success === true) {
        saveDataToSessionStorage("inter_payment", response.data.user_id);
        saveDataToSessionStorage("inter_user_id", response.data.user_id);
        // saveDataToSessionStorage("total_ammount", total_amount_bid);
        // localStorage.setItem("allres", JSON.stringify(response.data));

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      } else {
        alert("something went wrong ");
        sessionStorage.clear();
      }
    } catch (error) {
      // Handle errors
      console.error("Error making payment:", error);
    }
  };

  return (
    <div>
      <div className="recr_new_wrapp_container recr_reletive_position">
        <img
          src={images.new_bg_circule}
          alt=""
          className="recr_back_circule_shadow"
        />
        <div className="recr_new_container">
          {/* <div className="recr_back_arrow_wrapp">
            <button>
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div> */}
          <div className="recr_1_new_intanationa_invite_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "35vh", minHeight: "150px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>
            <div
              className="otp_screen_text_container"
              style={{ margin: "0px", padding: "1rem 0px" }}
            >
              <h4>Attention for International Users</h4>
              <p>
                A small $10 fee is required. Your support allows us to maintain
                and grow our services worldwide. We're grateful for your
                contribution!
              </p>
            </div>
            <button className="new_btn_wrapp mt-3" onClick={makePayment}>
              <p>Pay</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInternationalStripePage;
