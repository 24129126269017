import React, { useEffect, useState } from "react";
import "./editemail.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import CusModal from "../../common/cusmodal/CusModal";
import { user_Reset_new_email_with_old_email } from "../../utils/Constant";
import axios from "axios";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";

const Editemail = () => {
  const location = useLocation();
  const [new_email, setNewEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("user_id");
  const [getoldmail, setoldmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state.email) {
      console.log(" location.state", location.state.email);
    }
  }, []);

  const handleEmailChange = () => {
    if (new_email == "") {
      toast.error("Enter New Email Address", {
        theme: "colored",
      });
    } else if (location.state.email === new_email) {
      toast.error("The new Email must be different from the old Email.", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("old_email", location.state.email);
      formData.append("new_email", new_email);

      axios
        .post(user_Reset_new_email_with_old_email, formData)
        .then((response) => {
          setIsLoading(false);

          if (response.data.status === 1) {
            navigate("/editmailotp", {
              state: {
                email: new_email,
              },
            });
          } else {
            toast.error(response.data.message, {
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          // Handle error
        });
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        {isLoading ? (
          <NewLoader />
        ) : (
          <>
            <div
              className="play_screen_main_wrapp"
              style={{ padding: "10px 5%" }}
            >
              <div className="legacy_dashbord_nav_sec">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
                <p>Update Email Address</p>
                <div></div>
              </div>
              <div style={{ height: "70vh", width: "100%" }}>
                <div
                  style={{ marginTop: "30px" }}
                  className="ep_main_wrapp_txt_username"
                >
                  <input
                    readOnly
                    value={location.state.email}
                    placeholder="Current email address"
                    className="ep_text_input_username"
                  />
                  <div className="ep_absolue_txt_username">
                    <p className="ep_position_two_p">Current email address</p>
                  </div>
                </div>
                <div
                  style={{ marginTop: "30px" }}
                  className="ep_main_wrapp_txt_username"
                >
                  <input
                    type="phone"
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                    }}
                    placeholder="New Email Address"
                    className="ep_text_input_username"
                  />
                  <div className="ep_absolue_txt_username">
                    <p className="ep_position_two_p">New Email Address</p>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => handleEmailChange()}
              className="new_btn_wrapp"
              style={{
                bottom: "10%",
                width: "100%",
                //   left: "10%",
                transform: "translate(0%, 50%)",
                background: "#000",
                maxWidth: "var(--max-width-sm)",
                marginTop: "20px",
                marginBottom: "50px",
              }}
            >
              <p className="ep_p_tag_preview">Submit</p>
            </button>
            {showModal && (
              // <CusModal
              //   msg={errorMessage}
              //   setShowModal={setShowModal}
              //   showModal={showModal}
              // />
              // <NewModal
              //   title=""
              //   msg={errorMessage}
              //   setShowModal={openmodal}
              //   closemodal={closemodal}
              // />

              <V2Modal
                canclebtn={false}
                title=""
                msg={errorMessage}
                setShowModal={setShowModal}
                okfunction={closemodal}
              />
            )}
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Editemail;
