import React from "react";
import "./CreateCandW.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import axios from "axios";
import qs from "qs";
import {
  add_user_grup_benifit,
  add_user_pending_c_and_w,
  get_all_benifit_category,
  get_all_user_manager,
  get_release_bond,
  product_bond_benifit,
} from "../../utils/Constant";
import { useEffect } from "react";
import { useState } from "react";
import V2Modal from "../../common/v2modal/V2Modal";
import images from "../../constants/images";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";
import { IoSearch } from "react-icons/io5";

const CreateCandW = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getallBonds, setAllBonds] = useState([]);
  const [getsearchBonds, setSearchBonds] = useState([]);
  const [getselectedProIds, setSelectedProIds] = useState([]);
  const [getselectedBondId, setSelectedBondId] = useState([]);
  const [getselectedBondIsNfb, setSelectedBondIsNfb] = useState([]);

  const [getselectedProIdsString, setSelectedProIdsString] = useState("");
  const [getselectedBondIdString, setSelectedBondIdString] = useState("");
  const [getselectedBondIsNfbString, setSelectedBondIsNfbString] = useState("");

  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [getdata, setdata] = useState([]);
  const [getbanifit, setbenifit] = useState("");
  const [getdes, setdes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showOtherSelect, setShowOtherSelect] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [catname, setcatname] = useState("");
  const [catid, setcatid] = useState("");
  const [getmanagerdata, setmanagerdata] = useState([]);
  const [getmanagersearch, setmanagersearch] = useState([]);
  const [contectname, setcontectname] = useState("");
  const [contectid, setcontectid] = useState("");
  const [getcontect, setgetcontect] = useState("me");
  const [getcon, setgetcon] = useState(false);
  const [getme, setMe] = useState("me");
  const location = useLocation();
  const [whatsapptick, setWhatsappTick] = useState(1);
  const [whatsappvalue, setWhatsappValue] = useState("");
  const handleRadioChange = (event) => {
    setcontectname("");
    const value = event.target.value;
    setMe(value);
    setgetcontect(value);
    if (value === "other") {
      setShowOtherSelect(true);
      // setWhatsappTick(0);
      // whatsappGetApi();
      // setWhatsappValue("");
    } else {
      setWhatsappTick(0);
      whatsappGetApi();
      setShowOtherSelect(false);
    }
  };

  const handleRarerezSearch = (event) => {
    const query = event.target.value;
    setSearchBonds(query);
    filterData(query);
  };

  const filterData = (query) => {
    const filtered = getallBonds.filter((item) =>
      item.product_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchBonds(filtered);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedFiles = [];

    for (let i = 0; i < files.length; i++) {
      newSelectedFiles.push(files[i]);
    }

    // setSelectedFiles(newSelectedFiles);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newSelectedFiles,
    ]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };
  const handlePlayVideo = (index) => {
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      videoElement.play();
    }
  };

  // Function to open the modal

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };

  // modal
  const [searchValue, setSearchValue] = useState("");

  const contactNames = [
    "Vishal",
    "John",
    "Emily",
    "Michael",
    "Sarah",
    "David",
    "Emma",
    "Daniel",
    "Olivia",
    "Sophia",
  ];

  const handleWhatsappToggle = () => {
    if (whatsapptick === 1) {
      // Perform action if ticked
      // For example, call this._whatsappgetapi()
      whatsappGetApi();
    }

    // Toggle the tick state
    setWhatsappTick((prevTick) => (prevTick === 0 ? 1 : 0));
    setWhatsappValue("");
  };

  const searchFilter_branch = (text) => {
    const newData = getmanagersearch.filter(function (item) {
      console.log("item", item.label);
      const employee = item.label ? item.label.toUpperCase() : "".toLowerCase();
      const number = String(item.mobile_number)
        ? String(item.mobile_number).toUpperCase()
        : "".toUpperCase();
      const status = String(item.status)
        ? String(item.status).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        status.indexOf(textData) > -1
      );
    });
    setmanagerdata(newData);
    console.log("asvsvsds", newData);
  };

  const handleWhatsappChange = (data) => {
    setWhatsappValue(data);
  };

  const handleCheckboxChange = (proids, productId, isNfb) => {
    // Create a copy of the existing state arrays
    const selectedProIdsCopy = [...getselectedProIds];
    const selectedBondIdsCopy = [...getselectedBondId];
    const selectedBondIsNfbCopy = [...getselectedBondIsNfb];

    // Check if the product_id is already in the array
    const index = selectedBondIdsCopy.indexOf(productId);

    // If the product_id is not in the array, add it
    if (index === -1) {
      selectedProIdsCopy.push(proids);
      selectedBondIdsCopy.push(productId);
      selectedBondIsNfbCopy.push(isNfb);
    } else {
      // If the product_id is already in the array, remove it
      selectedProIdsCopy.splice(index, 1);
      selectedBondIdsCopy.splice(index, 1);
      selectedBondIsNfbCopy.splice(index, 1);
    }

    // Update the state with the modified arrays
    setSelectedProIds(selectedProIdsCopy);
    setSelectedBondId(selectedBondIdsCopy);
    setSelectedBondIsNfb(selectedBondIsNfbCopy);

    const selectedProIdsString = selectedProIdsCopy.join(",");
    const selectedBondIdsString = selectedBondIdsCopy.join(",");
    const selectedBondIsNfbString = selectedBondIsNfbCopy.join(",");
    setSelectedProIdsString(selectedProIdsString);
    setSelectedBondIdString(selectedBondIdsString);
    setSelectedBondIsNfbString(selectedBondIsNfbString);
  };

  const whatsappGetApi = (userIds) => {
    setIsLoading(true);
    const param = {
      user_id: userIds ? userIds : userId,
    };
    console.log("setting", param);

    axios
      .post("https://www.ravi.host/api/get_user_whatsapp_number", param)
      .then((response) => {
        console.log("whatsappGetApi --->", response.data);
        setIsLoading(false);
        setWhatsappValue(response.data.whatsapp_number);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Error in whatsappGetApi:", error);
      });
  };

  const getReleasebond = () => {
    setIsLoading(true);
    axios
      .get(get_all_benifit_category)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setIsLoading(false);
        if (Response.data.status == 1) {
          setdata(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const Getallusermanager = () => {
    const param = {
      user_id: userId,
    };
    setIsLoading(true);
    axios
      .post(get_all_user_manager, qs.stringify(param))
      .then((Response) => {
        console.log("acdascs--->", Response.data);
        setIsLoading(false);
        if (Response.data.status == 1) {
          // setmanagerdata(Response.data.result)
          setmanagersearch(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createnfb = () => {
    // if (getselectedBondId.length < 1) {
    //   toast.error("Please select at least one rarerez", {
    //     theme: "colored",
    //   });
    // } else
    if (getbanifit == "") {
      toast.error("Enter reward name", {
        theme: "colored",
      });
    } else if (catid == "") {
      toast.error("Select category", {
        theme: "colored",
      });
    } else if (getdes == "") {
      toast.error("Enter description", {
        theme: "colored",
      });
    } else if (getcontect == "") {
      toast.error("Please Select contact", {
        theme: "colored",
      });
    } else if (getcontect === "other" && contectid === "") {
      toast.error("Please Select contact person name", {
        theme: "colored",
      });
    } else if (selectedFiles == 0) {
      toast.error("Upload atlist one image", {
        theme: "colored",
      });
    } else {
      let formData = new FormData();
      {
        getselectedProIds.map((ProIds) =>
          formData.append("pro_as_ids[]", ProIds)
        );
      }
      {
        getselectedBondIsNfb.map((IsNfb) => formData.append("is_nfb[]", IsNfb));
      }
      // formData.append("is_nfb[]", getselectedBondIsNfbString);
      // formData.append("product_id", getselectedBondIdString);
      formData.append("user_id", userId);
      formData.append("reward_name", getbanifit);
      formData.append("free_paid", 0);
      formData.append("Free", "Free");
      formData.append("is_contact", getcontect == "other" ? 1 : 0);
      formData.append("contact_user_id", contectid);

      formData.append("for_other_user_paid", "0");

      formData.append("ctegory_id", catid);
      formData.append("free_paid_user_option", "0");
      formData.append("valid_time", "0");
      formData.append("description", getdes);
      formData.append(
        "for_other_user_price",
        getcontect !== "other" ? "0" : getcontect
      );

      // this.state.images_array.map((i) =>
      //   i == "uri"
      //     ? null
      //     : formData.append("img_video[]", {
      //         uri: Platform.OS === "android" ? i : i.replace("file://", ""),
      //         name: "name",
      //         type: "image/jpeg",
      //       })
      // );
      selectedFiles.forEach((image, index) => {
        formData.append("img_video[]", image);
      });
      formData.append("whatsapp_number", whatsappvalue);
      console.log("param", formData);

      setIsLoading(true);
      axios
        // .post(
        //   "https://gokulnair.com/blockchain_2/api/add_user_grup_benifit",
        //   formData
        // )
        .post(add_user_pending_c_and_w, formData)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setIsLoading(false);
          if (Response.data.status == 1) {
            setgetcon(true);
            toast.success(Response.data.message, {
              theme: "colored",
            });
            navigate("/FreeBenefit");
            // window.location.reload();
          } else {
            toast.error(Response.data.message, {
              theme: "colored",
            });
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    // AllBonds();
    getReleasebond();
    Getallusermanager();
  }, []);
  return (
    <div className="new_wrapp_container reletive_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="back_arrow_wrapp">
            <button
              onClick={() => {
                navigate("/sidemenu");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div>
          <div className="npasset_main_wrapp" style={{ padding: "10px 5%" }}>
            <h2 className="npasset_heading">Collect & Win</h2>
            <div className="npasset_heading_border"></div>

            <div className="npasset_inner_page_wrapp">
              {/* price name start */}

              <div
                className="new_show_user_single_input_wrapp"
                style={{ padding: "0px" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p style={{ fontSize: "14px" }}>Price Name</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Enter Price Name"
                    style={{ padding: "8px 0px" }}
                    onChange={(e) => {
                      setbenifit(e.target.value);
                    }}
                  />
                </div>
              </div>

              {/* price name end */}

              {/* description box start */}

              <>
                <div className="new_set_password_single_labal_wrapp">
                  <p style={{ fontSize: "14px" }}>
                    Provide a brief description of your Price
                  </p>
                </div>
                <div
                  className="des_textarea_wrapp"
                  style={{ marginTop: "10px", marginBottom: "1rem" }}
                >
                  <textarea
                    name="product_des"
                    rows="6"
                    className="form_input_box"
                    placeholder="Type Here"
                    onChange={(e) => {
                      setdes(e.target.value);
                    }}
                    style={{ fontSize: "12px" }}
                  ></textarea>
                </div>
              </>
              {/* description box end */}

              {/* category start */}
              <>
                <div className="new_set_password_single_labal_wrapp">
                  <p style={{ fontSize: "14px" }}>Category</p>
                </div>
                <div className="product_interests_wrapp mx-1 mb-2">
                  {getdata &&
                    getdata.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            setcatid(item.cat_id);
                          }}
                          // className={
                          //   catid == item.cat_id
                          //     ? "benefit_category_sub_validation"
                          //     : "benefit_category_sub"
                          // }
                          style={{
                            border: "1px solid",
                            borderColor:
                              catid == item.cat_id
                                ? "var(--color-main)"
                                : "var(--color-gray)",
                          }}
                          className="interest_sigle_btn"
                        >
                          <img
                            src={
                              "https://www.ravi.host/public/assets/benifit_category/" +
                              item.cat_icon
                            }
                            className="interest_sigle_icon"
                            alt=""
                          />
                          <p className="interest_sigle_icon_name">
                            {item.cat_name && item.cat_name.length > 5
                              ? item.cat_name.substring(0, 5) + "..."
                              : item.cat_name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </>
              {/* category end */}

              {/* image upload start */}

              <>
                <div>
                  <div className="new_set_password_single_labal_wrapp">
                    <p style={{ fontSize: "14px" }}>Please upload images.</p>
                  </div>
                  <div
                    className="new_creator_video_wrapp"
                    style={{ margin: "14px auto" }}
                  >
                    <label
                      // onClick={() => {
                      //   setIsModalVisiblevideo(true);
                      //   setRecordedChunks([]);
                      //   setrecordedvideo(false);
                      // }}
                      htmlFor="min_1_img"
                      // className="upload-button"
                      className="new_creator_video_upload_button"
                    >
                      <img src={images.new_upload_logo} alt="" />
                    </label>
                    <input
                      id="min_1_img"
                      type="file"
                      accept="image/*"
                      // capture="camera"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                  {selectedFiles && selectedFiles.length > 0 && (
                    <div className="new_min1_img_preview_wrapp">
                      {selectedFiles.map((image, index) => (
                        <div
                          className="new_min1_img_preview_single"
                          key={index}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                          />

                          <button
                            className="delete-button"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <img src={images.close_red} alt="" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* select at least one image end */}
                </div>
              </>

              {/* image upload end */}

              {/* 2nd name start */}
              <div
                className="new_show_user_single_input_wrapp"
                style={{ padding: "0px" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p style={{ fontSize: "14px" }}>Collection Name</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Enter Collection Name"
                    style={{ padding: "8px 0px" }}
                    // onChange={(e) => {
                    //   setbenifit(e.target.value);
                    // }}
                  />
                </div>
              </div>

              {/* 2nd name end */}

              {/* add manager start */}
              <div style={{ width: "100%", margin: "10px auto" }}>
                <Link to={"/editprofile"} className="cb_add_manager_btn">
                  Add Managers
                </Link>
              </div>
              {/* add manager end */}

              {/* contact person start */}
              <div>
                <div className="new_set_password_single_labal_wrapp">
                  <p style={{ fontSize: "14px" }}>Contact person</p>
                </div>
                <div className="benefit_contact" style={{ marginTop: "10px" }}>
                  <div className="benefit_contact_sub">
                    <input
                      type="radio"
                      name="contactType"
                      value="me"
                      onChange={handleRadioChange}
                      checked={getme === "me"}
                      style={{ marginTop: "4px" }}
                    />
                    <p>me</p>
                  </div>
                  {getmanagerdata && getmanagerdata.length > 0 ? (
                    <div className="benefit_contact_sub">
                      <input
                        type="radio"
                        name="contactType"
                        value="other"
                        onChange={handleRadioChange}
                        // onClick={handleWhatsappToggle}
                      />
                      <p>Other</p>
                    </div>
                  ) : null}
                </div>
                {showOtherSelect && (
                  <div className="other_select">
                    <br />
                    {/* <div to={"/addcontact"}> */}
                    <div onClick={openModal}>
                      <input
                        type="text"
                        readOnly
                        placeholder="Select Contact Person"
                        value={contectname}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* contact person end */}

              {/* whatsapp number start */}

              <div
                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  className="new_show_user_single_input_wrapp"
                  style={{ padding: "0px" }}
                >
                  <div className="new_set_password_single_labal_wrapp">
                    <p style={{ fontSize: "14px" }}>WhatsApp number</p>
                  </div>
                  <div className="new_set_password_single_border_wrapp">
                    <input
                      type="text"
                      placeholder="Enter WhatsApp number"
                      style={{ padding: "8px 0px" }}
                      readOnly={whatsapptick === 1 ? false : true}
                      onChange={(e) => handleWhatsappChange(e.target.value)}
                      value={whatsappvalue}
                    />
                  </div>
                </div>

                <div
                  className="new_aadhaar_btn_wrapp"
                  style={{ marginTop: "-10px", padding: "0px" }}
                >
                  <div>
                    {whatsapptick === 0 ? (
                      <img
                        src={images.new_accepted_terms}
                        onClick={handleWhatsappToggle}
                        alt=""
                      />
                    ) : (
                      <img
                        src={images.new_notaccepted_terms}
                        alt=""
                        onClick={handleWhatsappToggle}
                      />
                    )}
                    <p>is this your WhatsApp number ?</p>
                  </div>
                </div>
              </div>

              {/* whatsapp number end */}

              <div className="createbenefit mt-2">
                <div>
                  <center>
                    <button
                      onClick={() => {
                        // ccc();
                        createnfb();
                      }}
                      className="new_btn_wrapp  mt-3"
                    >
                      <p>Create</p>
                    </button>
                  </center>
                </div>
                {isModalOpen && (
                  <div className="modal_p-overlay">
                    <div className="modal_p-content">
                      <div className="modal_p-header">
                        <img
                          src={images.back}
                          alt=""
                          className="modal-close_p"
                          onClick={closeModal}
                        />
                        <p>Add contact Person</p>
                        <button className="modal-close">&times;</button>
                      </div>
                      <div className="modal_p-body">
                        <div>
                          <input
                            type="text"
                            placeholder="Search"
                            className="viewmanagers_search"
                            onChange={(e) => {
                              e.target.value.length == 0
                                ? setmanagerdata([])
                                : searchFilter_branch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="list_of_contact_nm">
                          {getmanagerdata.map((item, index) => (
                            <p
                              onClick={() => {
                                setIsModalOpen(false);
                                setcontectid(item.id);
                                setcontectname(item.label);
                                whatsappGetApi(item.id);
                              }}
                              className="add_contact_name"
                              key={index}
                            >
                              {item.label}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />

      {showModal1 && (
        <>
          <div className="user_benefit_allbonds_modal_wrapp">
            <div className="user_benefit_bond_modal_heding">
              <button
                onClick={() => {
                  setShowModal1(false);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Select rarerez</p>
            </div>

            {/* search bar start */}
            <div className="myproduct_13_search">
              <div className="myproduct_13_user_bid_main">
                <input
                  type="text"
                  placeholder="Search rarerez"
                  className="myproduct_13_user_bid_search"
                  // value={searchTerm}
                  // onChange={(e) => handleSearch(e.target.value)}
                  onChange={handleRarerezSearch}
                />
                {/* <IoSearch
                  size={24}
                  color="#fff"
                  className="myproduct_13_search_ic"
                /> */}
              </div>
            </div>

            {/* search bar end */}

            <div className="user_benefit_modal_allbonds_wrapp">
              {getsearchBonds && getsearchBonds.length > 0
                ? getsearchBonds.map((item, index) => {
                    return (
                      <div
                        className="user_benefit_modal_single_bond"
                        // key={item.product_id}
                      >
                        <div className="user_benefit_modal_single_bond_inner_wrapp">
                          <img src={item.img_video} alt="" />
                          <p>{item.product_name}</p>
                          <input
                            type="checkbox"
                            name="bond-check"
                            id={`check_bond_${index}`}
                            // className="attach_partner_select"
                            value={item.product_id}
                            onChange={() => {
                              handleCheckboxChange(
                                item.pro_as_id,
                                item.product_id,
                                item.is_nfb
                              );
                            }}
                            checked={getselectedBondId.includes(
                              item.product_id
                            )}
                          />
                        </div>
                        <div className="user_benefit_modal_bond_hr"></div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateCandW;
