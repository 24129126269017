import React, { useEffect, useState } from "react";
import "../../src/pages/viewmanagers/viewmanagers.css";
import images from "../../src/constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../src/common/NavbarSec";
import Cardthree from "../../src/components/feedcard/cardthree";
import {
  edit_profile,
  delete_user_manager,
  show_request,
} from "../../src/utils/Constant";
import axios from "axios";

const Internationaluserlist = () => {
  const location = useLocation();

  const [loading, setloading] = useState(false);
  const [request_co, setrequest_co] = useState(0);

  useEffect(() => {
    _getdata();
  }, []);
  const userId = localStorage.getItem("user_id");
  const [managerdata, setManagerData] = useState([]);

  const _getdata = () => {
    setloading(true);

    const param = {
      sender_id: location.state.user_id,
    };

    axios.post(show_request, param).then((Response) => {
      setloading(false);
      console.log("----", JSON.stringify(Response.data, null, 2));
      if (Response.data.status === 1) {
        const result = Response.data;
        setManagerData(result.receiver_data_list);
        setrequest_co(result.request_co);
      } else {
        // setIsModalVisible(true);
        // setCusMsg(Response.data.message);
      }
    });
  };

  return (
    <div className="page_main_wrapp">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            {/* <NavbarSec name="International user request list" /> */}
            <div
              style={{
                color: "#fff",
                fontfamily: "var(--font-600)",
                fontsize: "16px",
                textAlign: "center",
                // marginLeft: 70,
                margin: "10px auto",
                width: "90%",
              }}
            >
              International user request list
            </div>
            {/* <Link to={"/selectmanagers"} className="div">
              <input
                type="text"
                style={{ marginTop: "20px" }}
                readOnly
                placeholder="Search"
                className="viewmanagers_search"
              />
            </Link> */}

            {managerdata.length == 0 ? (
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  fontFamily: "var(--font-400)",
                  alignSelf: "center",
                  width: "100%",
                  marginTop: 50,
                }}
              >
                You must obtain approval from two Indian artists.
              </p>
            ) : null}

            {managerdata &&
              managerdata.map((item, index) => {
                console.log("item", item);
                return (
                  <div className="viewmanagers_details" key={item.id}>
                    <img
                      // className="viewmanagers_details_img"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "30px",
                        objectFit: "cover",
                      }}
                      src={item.profile}
                      alt=""
                    />
                    <b className="name_of_viewmanagers">{item.username}</b>
                    <b
                      style={{
                        color:
                          item.request_status == 0
                            ? "yellow"
                            : item.request_status == 1
                            ? "green"
                            : item.request_status == 2
                            ? "red"
                            : "#fff",
                      }}
                    >
                      {" "}
                      {item.request_status == 0
                        ? "Panding"
                        : item.request_status == 1
                        ? "Accepted"
                        : item.request_status == 2
                        ? "Rejected"
                        : null}
                    </b>
                  </div>
                );
              })}

            {request_co == 2 || request_co == 5 || request_co == 6 ? (
              <>
                {/* <Link
            to="/"
            className="account_btn_wrapp"
            style={{
              backgroundColor: "var(--color-main)",
              borderRadius: "8px",
              padding: "6px 10px",
            }}
          > */}
                {/* <img src={images.plus} alt="" /> */}
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "var(--font-400)",
                    fontSize: "14px",
                    margin: "2rem auto",
                    textAlign: "center",
                  }}
                >
                  Your request is panding. Please wait till the creators approve
                  you.
                </p>
                {/* </Link> */}
              </>
            ) : (
              <Link
                to="/Internationaluserrequest"
                state={{ user_id: location.state.user_id }}
                className="account_btn_wrapp"
              >
                <img src={images.plus} alt="" />
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Internationaluserlist;
