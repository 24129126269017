import {
  GET_ALL_NFBBLOCKBID_FAIL,
  GET_ALL_NFBBLOCKBID_BEGIN,
  GET_ALL_NFBBLOCKBID_SUCCESS,
  CREATE_NFBBLOCKBID_BEGIN,
  CREATE_NFBBLOCKBID_SUCCESS,
  CREATE_NFBBLOCKBID_FAIL,
  GET_PLATFORM_FEES_BEGIN,
  GET_PLATFORM_FEES_SUCCESS,
  GET_PLATFORM_FEES_FAIL,
  GET_HIGHEST_BID_BEGIN,
  GET_HIGHEST_BID_SUCCESS,
  GET_HIGHEST_BID_FAIL,
  ACCEPT_BOND_BID_BEGIN,
  ACCEPT_BOND_BID_SUCCESS,
  ACCEPT_BOND_BID_FAIL,
  GET_ALL_ACCEPT_BOND_BID_BEGIN,
  GET_ALL_ACCEPT_BOND_BID_SUCCESS,
  GET_ALL_ACCEPT_BOND_BID_FAIL,
  GET_ALL_RELEASE_BOND_BIDS_BEGIN,
  GET_ALL_RELEASE_BOND_BIDS_SUCCESS,
  GET_ALL_RELEASE_BOND_BIDS_FAIL,
  GET_DEMO_1_SUCCESS,
  GET_DEMO_1_BEGIN,
  GET_DEMO_1_FAIL,
  REJECT_ACCEPTED_BID_BEGIN,
  REJECT_ACCEPTED_BID_SUCCESS,
  REJECT_ACCEPTED_BID_FAIL,
  REJECT_BID_BEGIN,
  REJECT_BID_SUCCESS,
  REJECT_BID_FAIL,
  GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS,
  GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN,
  GET_ALL_ACCEPTED_USER_BENIFITS_FAIL,
  GET_USER_ACCEPTED_BOND_BEGIN,
  GET_USER_ACCEPTED_BOND_SUCCESS,
  GET_USER_ACCEPTED_BOND_FAIL,
  GET_SINGLE_ACCEPTED_USER_BOND_BEGIN,
  GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS,
  GET_SINGLE_ACCEPTED_USER_BOND_FAIL,
} from "../Action";

const user_blockbid = (state, action) => {
  // register

  if (action.type === GET_ALL_NFBBLOCKBID_BEGIN) {
    return { ...state, get_allclubbid_loading: true };
  }

  if (action.type === GET_ALL_NFBBLOCKBID_SUCCESS) {
    return {
      ...state,
      get_allclubbid_loading: false,
      get_allclubbid_data: action.payload.result,
    };
  }

  if (action.type === GET_ALL_NFBBLOCKBID_FAIL) {
    return { ...state, get_allclubbid_loading: false };
  }
  //// create bid
  if (action.type === CREATE_NFBBLOCKBID_BEGIN) {
    return {
      ...state,
      create_blockbid_loading: true,
    };
  }
  if (action.type === CREATE_NFBBLOCKBID_SUCCESS) {
    return {
      ...state,
      create_blockbid_loading: false,
      create_blockbid_data: action.payload.result,
    };
  }
  if (action.type === CREATE_NFBBLOCKBID_FAIL) {
    return {
      ...state,
      create_blockbid_loading: false,
    };
  }
  //// get fees

  if (action.type === GET_PLATFORM_FEES_BEGIN) {
    return {
      ...state,
      fees_loading: true,
    };
  }

  if (action.type === GET_PLATFORM_FEES_SUCCESS) {
    return {
      ...state,
      fees_loading: false,
      fees_data: action.payload.result,
    };
  }
  if (action.type === GET_PLATFORM_FEES_FAIL) {
    return {
      ...state,
      fees_loading: false,
    };
  }
  // get_release_bond_highest_bid

  if (action.type === GET_HIGHEST_BID_BEGIN) {
    return {
      ...state,
      highest_bid_loading: true,
    };
  }
  if (action.type === GET_HIGHEST_BID_SUCCESS) {
    return {
      ...state,
      highest_bid_loading: false,
      highest_bid_data: action.payload.result,
    };
  }
  if (action.type === GET_HIGHEST_BID_FAIL) {
    return {
      ...state,
      highest_bid_loading: false,
    };
  }
  /// accept bond bid
  if (action.type === ACCEPT_BOND_BID_BEGIN) {
    return {
      ...state,
      accept_bond_bid_loading: true,
    };
  }
  if (action.type === ACCEPT_BOND_BID_SUCCESS) {
    return {
      ...state,
      accept_bond_bid_loading: false,
      accept_bond_bid_data: action.payload.result,
    };
  }
  if (action.type === ACCEPT_BOND_BID_FAIL) {
    return {
      ...state,
      accept_bond_bid_loading: false,
    };
  }
  // accept all bond bid
  if (action.type === GET_ALL_ACCEPT_BOND_BID_BEGIN) {
    return {
      ...state,
      accept_all_bond_bid_loading: true,
    };
  }
  if (action.type === GET_ALL_ACCEPT_BOND_BID_SUCCESS) {
    return {
      ...state,
      accept_all_bond_bid_loading: false,
      accept_all_bond_bid_data: action.payload.result,
    };
  }
  if (action.type === GET_ALL_ACCEPT_BOND_BID_FAIL) {
    return {
      ...state,
      accept_all_bond_bid_loading: false,
    };
  }
  // get_all_release_bond_bids
  if (action.type === GET_ALL_RELEASE_BOND_BIDS_BEGIN) {
    return {
      ...state,
      release_bond_bids_loading: true,
    };
  }
  if (action.type === GET_ALL_RELEASE_BOND_BIDS_SUCCESS) {
    return {
      ...state,
      release_bond_bids_loading: false,
      release_bond_bids_data: action.payload.result,
    };
  }
  if (action.type === GET_ALL_RELEASE_BOND_BIDS_FAIL) {
    return {
      ...state,
      release_bond_bids_loading: false,
    };
  }
  // demo
  if (action.type === GET_DEMO_1_BEGIN) {
    return {
      ...state,
      demo_1_loading: true,
    };
  }
  if (action.type === GET_DEMO_1_SUCCESS) {
    return {
      ...state,
      demo_1_loading: false,
      demo_1_data: action.payload.result,
    };
  }
  if (action.type === GET_DEMO_1_FAIL) {
    return {
      ...state,
      demo_1_loading: false,
    };
  }
  //// reject_accepted_bid
  if (action.type === REJECT_ACCEPTED_BID_BEGIN) {
    return {
      ...state,
      reject_accepted_bid_loading: true,
    };
  }
  if (action.type === REJECT_ACCEPTED_BID_SUCCESS) {
    return {
      ...state,
      reject_accepted_bid_loading: false,
      reject_accepted_bid_data: action.payload.result,
    };
  }
  if (action.type === REJECT_ACCEPTED_BID_FAIL) {
    return {
      ...state,
      reject_accepted_bid_loading: false,
    };
  }
  // reject_bid
  if (action.type === REJECT_BID_BEGIN) {
    return {
      ...state,
      reject_bid_loading: true,
    };
  }
  if (action.type === REJECT_BID_SUCCESS) {
    return {
      ...state,
      reject_bid_loading: false,
      reject_bid_data: action.payload.result,
    };
  }
  if (action.type === REJECT_BID_FAIL) {
    return {
      ...state,
      reject_bid_loading: false,
    };
  }
  // list_of_accepted_user_benifit
  if (action.type === GET_ALL_ACCEPTED_USER_BENIFITS_BEGIN) {
    return {
      ...state,
      list_of_accepted_user_benifits_loading: true,
    };
  }
  if (action.type === GET_ALL_ACCEPTED_USER_BENIFITS_SUCCESS) {
    return {
      ...state,
      list_of_accepted_user_benifits_loading: false,
      list_of_accepted_user_benifits_data: action.payload.result,
    };
  }
  if (action.type === GET_ALL_ACCEPTED_USER_BENIFITS_FAIL) {
    return {
      ...state,
      list_of_accepted_user_benifits_loading: false,
    };
  }
  // get_user_accepted_bond
  if (action.type === GET_USER_ACCEPTED_BOND_BEGIN) {
    return {
      ...state,
      get_user_accepted_bond_loading: true,
    };
  }
  if (action.type === GET_USER_ACCEPTED_BOND_SUCCESS) {
    return {
      ...state,
      get_user_accepted_bond_loading: false,
      get_user_accepted_bond_data: action.payload.result,
    };
  }
  if (action.type === GET_USER_ACCEPTED_BOND_FAIL) {
    return {
      ...state,
      get_user_accepted_bond_loading: false,
    };
  }
  // get_single_accepted_user_bond
  if (action.type === GET_SINGLE_ACCEPTED_USER_BOND_BEGIN) {
    return {
      ...state,
      get_single_accepted_user_bond_loading: true,
    };
  }
  if (action.type === GET_SINGLE_ACCEPTED_USER_BOND_SUCCESS) {
    return {
      ...state,
      get_single_accepted_user_bond_loading: false,
      get_single_accepted_user_bond_data: action.payload.result,
    };
  }
  if (action.type === GET_SINGLE_ACCEPTED_USER_BOND_FAIL) {
    return {
      ...state,
      get_single_accepted_user_bond_loading: false,
    };
  }
};

export default user_blockbid;
