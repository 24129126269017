import React, { useEffect, useState } from "react";
import "./addowners.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import { add_user_manager, getall_user } from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const Addowners = () => {
  const [searchValue, setSearchValue] = useState("");
  const [getdata, setdata] = useState([]);
  const [getsearchData, setsearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const managers = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Michael Johnson" },
    { id: 4, name: "Emily Williams" },
    { id: 5, name: "Robert Brown" },
    { id: 6, name: "Olivia Davis" },
    { id: 7, name: "William Taylor" },
    { id: 8, name: "Sophia Martinez" },
    { id: 9, name: "David Anderson" },
    { id: 10, name: "Isabella Wilson" },
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const filteredManagers = managers.filter((manager) =>
    manager.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    _getData1();
  }, []);

  const searchFilter_branch = (text) => {
    const newData = getsearchData.filter(function (item) {
      const employee = item.label ? item.label.toUpperCase() : "".toLowerCase();
      const firstname = item.firstname
        ? item.firstname.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      console.log("employee", employee);
      return (
        employee.indexOf(textData) > -1 || firstname.indexOf(textData) > -1
      );
    });
    setdata(newData);
    console.log("asvsvsds", getsearchData);
  };

  const _getData1 = () => {
    let formData = new FormData();
    formData.append("user_id", userId);
    // formData.append('manager_id', this.state.selectedId.join(', '));
    console.log("formData", formData);
    axios
      .post("https://www.ravi.host/api/getall_user_club", formData)
      .then((Response) => {
        // console.log(
        //   'Response.data.result',
        //   JSON.stringify(Response.data.result, null, 2),
        // );
        setdata(Response.data.result);
        setsearchData(Response.data.result);
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onItemPress = (itemId) => {
    let updatedSelectedItems;

    if (selectedItems.includes(itemId)) {
      updatedSelectedItems = selectedItems.filter((label) => label !== itemId);
    } else {
      updatedSelectedItems = [...selectedItems, itemId];
    }

    setSelectedItems(updatedSelectedItems);
    console.log("selectedItems:", updatedSelectedItems);
  };

  const onItemid = (itemId) => {
    let updatedSelectedItems1;

    if (selectedId.includes(itemId)) {
      updatedSelectedItems1 = selectedId.filter((id) => id !== itemId);
    } else {
      updatedSelectedItems1 = [...selectedId, itemId];
    }

    setSelectedId(updatedSelectedItems1);
    console.log("selectedId:", updatedSelectedItems1);
  };

  const _addmanager = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("club_id", location.state.club_id);
    formData.append("manager_id", selectedId.join(", "));
    console.log("formData", formData);
    axios
      .post("https://www.ravi.host/api/add_club_manager", formData)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        if (response.data.status === 1) {
          navigate("/clubmanager", {
            state: {
              club_id: location.state.club_id,
            },
          });
        } else {
          setErrorMessage(response.data.message);
          setShowModal(true);
        }
      });
  };
  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="Add Managers" /> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Add Managers</p>
          <div></div>
        </div>
        {selectedItems == 0 ? null : (
          <button
            onClick={() => {
              _addmanager();
            }}
            style={{
              position: "absolute",
              top: "20px",
              right: "10px",
              backgroundColor: "var(--color-main)",
              padding: "5px",
              marginBottom: "5px",
              borderRadius: "5px",
              zIndex: 11,
            }}
          >
            <p style={{ fontFamily: "var(--font-600)", fontSize: "12px" }}>
              Submit
            </p>
          </button>
        )}
        {/* <div className="selectmanagers">
          <input
            type="text"
            placeholder="Search"
            className="viewmanagers_search"
            // value={searchValue}
            onChange={(e) => searchFilter_branch(e.target.value)}
          />
        </div> */}

        <div
          style={{ marginBottom: "0px", width: "100%" }}
          className="bid13_search"
        >
          <div className="bids13_user_bid_main" style={{ paddingTop: "5px" }}>
            <input
              type="text"
              placeholder="Search"
              className="bids13_user_bid_search"
              // value={searchTerm}
              onChange={(e) => {
                searchFilter_branch(e.target.value);
              }}
            />
            {/* <img src={images.search} alt="" className="search_ic" /> */}
          </div>
        </div>
        <br />
        {getdata &&
          getdata.map((item) => {
            const isSelected = selectedItems.includes(item.label);
            return (
              <div
                onClick={() => {
                  onItemPress(item.label);
                  onItemid(item.id);
                }}
                // className="selectmanagers_details"
                style={{
                  // border: "1px solid red",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* <img className="select_managers" src={item.profile} alt="" /> */}
                <div className="nav13_profile_wrapp">
                  <div>
                    <img
                      src={item.profile}
                      className="nav13_img_of_usermenu"
                      alt=""
                    />
                  </div>
                </div>
                <b
                  key={item.id}
                  className={
                    isSelected
                      ? "selected_nm_managers_green"
                      : "selected_nm_managers"
                  }
                  style={{ width: "76%" }}
                >
                  <p className={isSelected ? "lablegreen" : "lablewhite"}>
                    {item.label}
                  </p>
                </b>
              </div>
            );
          })}
      </div>
      <div className=""></div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Addowners;
