import React, { useEffect } from "react";
import "./NewInternationalPassword.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  check_is_user,
  inert_user_add_password_v2,
  user_add_password_v2,
} from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";

const NewInternationalPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [ispasswordShown, setIsPasswordShown] = useState(false);
  const [isconfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

  const [getdisplayName, setDisplayName] = useState("");
  const [getfirstName, setFirstName] = useState("");
  const [gender, setGender] = useState("");
  const [getpassword, setPassword] = useState("");
  const [getconfirmPassword, setconfirmPassword] = useState("");
  const [is_username, setIsUserName] = useState(null);

  // password validation start
  const [valid, setvalid] = useState(false);
  const [isInputValid, setInputValid] = useState(false);
  const [isInputValidConfirmPassword, setIsInputValidConfirmPassword] =
    useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [isSpecialCharacterMissing, setIsSpecialCharacterMissing] =
    useState(false);
  const [isMinLengthInvalid, setIsMinLengthInvalid] = useState(false);
  const [isNumericCharacterMissing, setIsNumericCharacterMissing] =
    useState(false);
  const [isUppercaseMissing, setIsUppercaseMissing] = useState(false);
  const [isLowercaseMissing, setIsLowercaseMissing] = useState(false);

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const newPassword = e.target.value;
    console.log("event", newPassword.length);
    if (newPassword.length == 0) {
      setInputValid(false);
    } else {
      console.log("111");
      validateInput();
    }
    setPassword(newPassword);
  };

  const validateInput = () => {
    setInputValid(true);
    const isSpecialCharacterMissing = !/[!@#$%^&]/.test(getpassword);
    const isMinLengthInvalid = getpassword.length < 8;
    const isNumericCharacterMissing = !/\d/.test(getpassword);
    const isUppercaseMissing = !/[A-Z]/.test(getpassword);
    const isLowercaseMissing = !/[a-z]/.test(getpassword);

    const isInputValid =
      isInputFocused ||
      (!isSpecialCharacterMissing &&
        !isMinLengthInvalid &&
        !isNumericCharacterMissing &&
        !isUppercaseMissing &&
        !isLowercaseMissing);

    const isInputValid1 =
      !isSpecialCharacterMissing &&
      !isMinLengthInvalid &&
      !isNumericCharacterMissing &&
      !isUppercaseMissing &&
      !isLowercaseMissing;
    setvalid(isInputValid1);

    setInputValid(isInputValid);
    setIsSpecialCharacterMissing(isSpecialCharacterMissing);
    setIsMinLengthInvalid(isMinLengthInvalid);
    setIsNumericCharacterMissing(isNumericCharacterMissing);
    setIsUppercaseMissing(isUppercaseMissing);
    setIsLowercaseMissing(isLowercaseMissing);
  };

  useEffect(() => {
    validateInput();
    console.log("valid", valid);
    console.log("isInputValid", isInputValid);
  }, [getpassword, isInputFocused]);

  // password validation end

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent entering space
    }
  };

  // set password api start
  const SetPassword = async () => {
    if (getdisplayName === "") {
      toast.error("Please enter a display name", { theme: "colored" });
    } else if (getfirstName === "") {
      toast.error("Please enter a first name", { theme: "colored" });
    } else if (getpassword === "") {
      toast.error("Please enter a password", { theme: "colored" });
    } else if (getconfirmPassword === "") {
      toast.error("Please enter a confirm password", { theme: "colored" });
    } else if (getconfirmPassword !== getpassword) {
      toast.error("Password and Confirm password is not match", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      const param = {
        new_password: getpassword,
        confirm_password: getconfirmPassword,
        username: getdisplayName,
        firstname: getfirstName,
        user_id: location.state.user_id,
        gender:
          gender == "male"
            ? 0
            : gender == "female"
            ? 1
            : gender == "other"
            ? 2
            : 0,
      };
      console.log("user_otp param", param);

      await axios
        .post(inert_user_add_password_v2, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status === 1) {
            toast.success(`${Response.data.message}`, { theme: "colored" });
            navigate("/NewInterNationalUserDetails", {
              state: {
                user_id: Response.data.user_id,
              },
            });
          } else {
            console.log("user_otp err", Response.data);
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };
  // set password api end

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="set_otp_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "24vh", minHeight: "150px" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4 style={{ margin: "0px" }}>Set your password</h4>
              <p>Secure Your Space</p>
            </div>

            <div className="new_set_password_inputs_wrapp">
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_user_icon} alt="" />
                  <p>Display Name : </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Type here"
                    style={{ width: "100%" }}
                    value={getdisplayName}
                    onChange={(e) => {
                      setDisplayName(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* first name start */}
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_user_icon} alt="" />
                  <p>First Name : </p>
                </div>
                <div
                  className="new_set_password_single_border_wrapp"
                  style={{
                    backgroundImage:
                      is_username == 1
                        ? "linear-gradient(to right, #21ce0380, #65855537)"
                        : "linear-gradient(to right, #ff004c80, #cbacff37)",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Type here"
                    style={{ width: "100%" }}
                    value={getfirstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    onBlur={() => {
                      if (getfirstName != "") {
                        setIsLoading(true);
                        const param = {
                          firstname: getfirstName,
                        };
                        console.log("param", param);
                        axios
                          .post(check_is_user, qs.stringify(param))
                          .then((Response) => {
                            setIsLoading(false);
                            console.log("param", Response.data);
                            if (Response.data.status === 0) {
                              setIsUserName(0);
                              toast.error(Response.data.message, {
                                theme: "colored",
                              });
                            } else {
                              setIsUserName(1);
                              toast.success(Response.data.message, {
                                theme: "colored",
                              });
                            }
                          })
                          .catch((err) => {
                            console.log("check_is_user err", err);
                          });
                      }
                    }}
                    // onKeyPress={handleKeyPress}
                  />
                </div>
              </div>
              {/* first name end */}

              {/* gender start */}

              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_user_icon} alt="" />
                  <p>Gender: </p>{" "}
                  <div className="all_gender_radio_wrapp">
                    <div className="gender_single_wrapp">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={gender === "male"}
                        onChange={handleGenderChange}
                        className="radio_btn1"
                        style={{ margin: "0px" }}
                      />
                      <p className="radio_btn_name">Male</p>
                    </div>
                    <div className="gender_single_wrapp">
                      <input
                        className="radio_btn2"
                        type="radio"
                        name="gender"
                        value="female"
                        checked={gender === "female"}
                        onChange={handleGenderChange}
                        style={{ margin: "0px" }}
                      />
                      <p className="radio_btn_name">Female</p>
                    </div>
                    <div className="gender_single_wrapp">
                      <input
                        type="radio"
                        name="gender"
                        value="other"
                        checked={gender === "other"}
                        onChange={handleGenderChange}
                        className="radio_btn3"
                        style={{ margin: "0px" }}
                      />
                      <p className="radio_btn_name">Other</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* gender end */}

              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_lock_logo} alt="" />
                  <p>Password : </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type={ispasswordShown ? "text" : "password"}
                    placeholder="Type here"
                    value={getpassword}
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                    onFocus={() => {
                      setIsInputFocused(true);
                    }}
                  />
                  <img
                    onClick={() => {
                      setIsPasswordShown(!ispasswordShown);
                    }}
                    src={
                      ispasswordShown
                        ? images.new_eye_close
                        : images.new_eye_icon
                    }
                    alt=""
                  />
                </div>
              </div>

              {/* password validation start */}
              {valid == false ? (
                <>
                  {isInputValid && (
                    <div className="password_validation_main_wrapp">
                      <p
                        style={{
                          color: isMinLengthInvalid
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must be at least 8 characters long
                      </p>
                      <p
                        style={{
                          color: isSpecialCharacterMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one special character
                      </p>
                      <p
                        style={{
                          color: isNumericCharacterMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one numeric character
                      </p>
                      <p
                        style={{
                          color: isUppercaseMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one uppercase character
                      </p>
                      <p
                        style={{
                          color: isLowercaseMissing
                            ? "white"
                            : "var(--color-main)",
                        }}
                      >
                        Password must include at least one lowercase character
                      </p>
                    </div>
                  )}
                </>
              ) : null}
              {/* password validation end */}

              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_lock_logo} alt="" />
                  <p>Confirm Password : </p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type={isconfirmPasswordShown ? "text" : "password"}
                    placeholder="Type here"
                    value={getconfirmPassword}
                    onChange={(e) => {
                      setconfirmPassword(e.target.value);
                    }}
                  />
                  <img
                    src={
                      isconfirmPasswordShown
                        ? images.new_eye_close
                        : images.new_eye_icon
                    }
                    alt=""
                    onClick={() => {
                      setIsConfirmPasswordShown(!isconfirmPasswordShown);
                    }}
                  />
                </div>
              </div>

              {/* btn */}
              <button onClick={SetPassword} className="new_btn_wrapp mx-3">
                <p>Next</p>
              </button>
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default NewInternationalPassword;
