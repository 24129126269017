import React, { useCallback, useEffect, useRef } from "react";
import "./neweditprofile.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { edit_profile, user_update } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FaAngleLeft } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import NewLoader from "../../common/newloder/NewLoader";

const NewUserRecordedVideo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getuserVid2, setUserVideo2] = useState("");

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="play_screen_main_wrapp" style={{ padding: "10px 5%" }}>
        <div className="legacy_dashbord_nav_sec">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>User Uploaded Videos</p>
          <div></div>
        </div>
        <div className="ep_record_authentication_video_wrapp">
          {location.state?.getvideoArray &&
          location.state?.getvideoArray.length > 0
            ? location.state?.getvideoArray.map((item, index) => {
                return (
                  <button className="ep_record_authentication_video_div">
                    {item.user_thumbnail != "" ? (
                      <div className="ep_record_authentication_thumb_div">
                        <img
                          className="ep_record_authentication_thumb"
                          src={item.user_thumbnail}
                        />
                        <div
                          onClick={() => {
                            setPreviewVideoModal2(true);
                            setUserVideo2(item.user_videos);
                          }}
                          className="ep_play_thumb_div"
                        >
                          <img
                            className="ep_video_play_btn"
                            src={images.d_play}
                          />
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setPreviewVideoModal2(true);
                          setUserVideo2(item.user_videos);
                        }}
                        className="ep_preview_btn_main_wrapp"
                      >
                        <p className="ep_p_tag_preview">Preview</p>
                      </button>
                      // "Preview"
                    )}
                  </button>
                );
              })
            : null}
        </div>
      </div>
      {getpreviewVideoModal2 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0px",
            left: "0px",
            zIndex: 112,
          }}
        >
          <div className="page_main_wrapp">
            <div className="page_base_wrapp">
              <div className="page_navbar_wrapp">
                <div className="nav_back_wrapp">
                  <div
                    onClick={() => {
                      setPreviewVideoModal2(false);
                    }}
                  >
                    <FaAngleLeft color="var(--color-white)" size={20} />
                  </div>
                </div>
                <p className="nav_screen_name">Video</p>
              </div>
              <video controls style={{ width: "100%", height: "90%" }}>
                <source src={getuserVid2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewUserRecordedVideo;
