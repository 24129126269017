import React, { useEffect, useState } from "react";
import "./Auction.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get_all_auction_bond_2 } from "../../utils/Constant";
import qs from "qs";
import axios from "axios";
import { useProfileContext } from "../../context/profile_context";
import NavbarSec from "../../common/NavbarSec";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const auctiondata = [
  {
    id: 1,
    name: "Asset Name",
    image: images.media1,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 2,
    name: "Asset Name",
    image: images.media2,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
  {
    id: 3,
    name: "Asset Name",
    image: images.media3,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus delectus magnam nemo quas ipsum laudantium maiores repudiandae enim doloribus illo?",
    price: "1.37 lac",
    username: "User Name",
    userimg: images.mask_profile,
  },
];
const Auction = () => {
  const navigate = useNavigate();

  const [getauctionData, setAuctionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("user_id");

  const GetAuction = () => {
    setIsLoading(true);

    const param = {
      user_id: userId,
    };
    console.log("param", param);
    axios
      .post(get_all_auction_bond_2, qs.stringify(param))
      .then((Response) => {
        console.log("====================================");
        console.log("Respon------------------------>", Response.data);
        console.log("====================================");
        if (Response.data.status == 1) {
          // this.setState({
          //   feed_list: Response.data.result,
          //   isLoading: false,
          //   isok: 0,
          // });
          setAuctionData(Response.data.result);
          setIsLoading(false);
        } else {
          //  alert(Response.data.message);
          console.log("auction err", Response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetAuction();
    console.log("getauctionData", getauctionData);
  }, []);

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Auction</p>
            <div></div>
            {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
          </div>
          {/* <div className="page_navbar_wrapp">
          <div className="nav_back_wrapp">
            <img src={images.back} className="nav_back_btn" alt="" />
          </div>
          <p className="nav_screen_name">Auction</p>
        </div> */}
          {/* <NavbarSec name="Auction" /> */}

          <div className="auction_products_wrapp">
            {/* auction single button start */}

            {getauctionData && getauctionData.length > 0 ? (
              getauctionData.map((item, index) => {
                return (
                  <div
                    className="auction_single_product_wrapp"
                    key={item.auction_id}
                  >
                    <Link
                      to={`${
                        item.is_club_id == "" ? "/UserProfile" : "/Clubprofile"
                      }`}
                      state={{
                        user_id: item.is_club_id == "" ? item.user_id : "",
                        club_id: item.is_club_id !== "" ? item.is_club_id : "",
                      }}
                      className="auction_product_username_wrapp"
                    >
                      <img src={item.profile} alt="" />
                      <p>{item.username}</p>
                    </Link>

                    <div className="auction_product_image_wrapp">
                      <img
                        src={
                          item.product_image_video &&
                          item.product_image_video[0].img_video
                        }
                        alt=""
                        className="auction_product_img"
                        onClick={() => {
                          // navigate('/')
                          navigate("/ProductviewAuction", {
                            state: {
                              release_bond_id: item.release_bond_id,
                              product_id: item.product_id,
                              is_nfb: item.is_nfb,
                              uniq_code: item.uniq_code,
                              theme_index: item.theme_index,
                              share_by_user: item.share_by_user,
                              club_id: item.club_id,
                              auction_item: item,
                            },
                          });
                        }}
                      />
                      <div className="auction_product_blur_view">
                        <p className="auction_product_name">
                          {item.product_name}
                        </p>
                        <p className="auction_product_des">
                          {item.description}
                        </p>
                        <div className="auction_bid_btn_wrapp">
                          <div className="auction_bid_price_wrapp">
                            <p>current bid</p>
                            <span>₹{item.current_highest_bid}</span>
                          </div>
                          {item.is_user_owner == 0 ? (
                            <button
                              onClick={() => {
                                navigate("/placebid", {
                                  state: {
                                    is_club: item.is_club,
                                    release_bond_id: item.release_bond_id,
                                    current_highest_bid:
                                      item.current_highest_bid,
                                    uniq_code: item.uniq_code,
                                    is_nfb: item.is_nfb,
                                    currenbt_stock:
                                      item.total_available_bond_bid,
                                    minimum_bid_amount: item.minimum_bid_amount,
                                    product_id: item.product_id,
                                    partner_bond_benifit_data:
                                      item.partner_bond_benifit_data.length,
                                    product_name: item.product_name,
                                    is_club_member: item.is_club_member,
                                    is_club_id: item.is_club_id,
                                    is_auction: 1,
                                    is_place_bid: item.is_place_bid,
                                    place_bid_amount: item.place_bid_amount,
                                    join_club_product: item.join_club_product,
                                    is_globle: item.is_globle,
                                  },
                                });

                                // console.log('item', item)
                              }}
                              className="auction_bid_btn"
                            >
                              Bid now
                            </button>
                          ) : null}
                        </div>
                      </div>

                      <div className="auction_stock_cube_wrapp">
                        <p>{item.total_available_bond_bid}</p>
                        <p>Stock</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="auction_nodata_text">
                Currently ther are no active auctions that you can participate
              </div>
            )}

            {/* auction single button end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Auction;
