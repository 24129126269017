import React, { useEffect, useState } from "react";
import "../../src/pages/mybidslist/mybidslist.css";
import images from "../../src/constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../src/common/NavbarSec";
import Cardthree from "../../src/components/feedcard/cardthree";
import {
  edit_profile,
  delete_user_manager,
  show_request,
} from "../../src/utils/Constant";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import NewModal from "../common/newmodal/NewModal";
import V2Modal from "../common/v2modal/V2Modal";
import NewLoader from "../common/newloder/NewLoader";

const Acceptinternationaluser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState([]);
  const [isok, setIsOk] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const _getData = () => {
    const param = {
      receiver_id: userId,
    };
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_international_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", JSON.stringify(response.data, null, 2));
        setIsLoading(false);

        if (response.data.status === 1) {
          setData(response.data.receiver_data_list);
          setSearch(response.data.receiver_data_list);
          setIsOk(1);
        } else {
          setIsOk(0);
        }
      });
  };

  useEffect(() => {
    _getData();
  }, []); // This will run _getData when the component mounts
  const userId = localStorage.getItem("user_id");
  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg1, setcusmsg1] = useState("");

  const closemodal = () => {
    setcusmodal1(false);
  };
  const openmodal = () => {
    setcusmodal1(true);
  };
  const handleAcceptBondBid = (item, status) => {
    setIsLoading(true);

    const param = {
      international_id: item.international_id,
      receiver_id: item.receiver_id,
      req_status: status,
    };

    console.log("param", param);

    axios
      .post(
        "https://www.ravi.host/api/inter_user_req_accept",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("param", Response.data);
        setIsLoading(false);

        if (Response.data.status === 1) {
          setcusmodal1(true);
          setcusmsg1(Response.data.message);
          _getData();
        } else {
          setcusmodal1(true);
          setcusmsg1(Response.data.message);
        }
      });
  };

  const searchFilterBranch = (text) => {
    const newData = search.filter((item) => {
      console.log("item", item.firstname);
      const employee = item.firstname
        ? item.firstname.toUpperCase()
        : "".toLowerCase();
      const number = String(item.bid_amount)
        ? String(item.bid_amount).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return employee.indexOf(textData) > -1 || number.indexOf(textData) > -1;
    });
    setData(newData);
    console.log("asvsvsds", newData);
  };

  return (
    <div className="page_main_wrapp">
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            <NavbarSec name="" />
            <div>
              <input
                style={{ marginTop: "10px" }}
                placeholder="Search"
                onChange={(e) => {
                  searchFilterBranch(e.target.value);
                }}
                type="search"
              />
            </div>
            {data.length == 0 ? (
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  fontFamily: "var(--font-400)",
                  alignSelf: "center",
                  width: "100%",
                  marginTop: 50,
                }}
              >
                No data found.
              </p>
            ) : null}
            <div className="mybidslist_details" style={{ marginTop: "10px" }}>
              {data &&
                data.map((item, index) => {
                  return (
                    <div className="mybidslist_list">
                      {/* <center>
                          <b>₹{item.bid_amount}</b>
                          <div className="b_list_hr"></div>
                        </center> */}
                      <center>
                        <p
                          style={{
                            color: "white",
                            fontSize: "12px",
                            marginTop: "10px",
                          }}
                        >
                          {moment(item.created_at).format("MMM Do YYYY")}
                        </p>
                      </center>
                      <div className="mybidslist_sub">
                        <img
                          src={item.profile}
                          className="my_list_bis_img"
                          alt=""
                          onClick={() => {
                            navigate("/UserProfile", {
                              state: {
                                user_id: item.sender_id,
                              },
                            });
                          }}
                        />
                        <p
                          className="firstname_mybidslist"
                          onClick={() => {
                            navigate("/UserProfile", {
                              state: {
                                user_id: item.sender_id,
                              },
                            });
                          }}
                        >
                          {item.username}
                        </p>
                        {item.receiver_status == 0 ? (
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "30%",
                            }}
                          >
                            <button
                              onClick={() => handleAcceptBondBid(item, 1)}
                              className="btn_sub_list_my"
                            >
                              <img
                                src={images.right_green}
                                className="btn_sub_list_my_img"
                                alt=""
                              />
                              <span>Accept</span>
                            </button>
                            <button
                              style={{ borderColor: "red" }}
                              onClick={() => handleAcceptBondBid(item, 2)}
                              className="btn_sub_list_my"
                            >
                              <img
                                style={{ borderColor: "red" }}
                                src={images.close_red}
                                className="btn_sub_list_my_img"
                                alt=""
                              />
                              <span style={{ color: "red" }}>Reject</span>
                            </button>
                          </div>
                        ) : item.receiver_status == 1 ? (
                          <p>Accepted</p>
                        ) : item.receiver_status == 2 ? (
                          <p>Rejected</p>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {getcusmodal1 && (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       <b>{""}</b>
            //       <br />
            //       <p className="modal-message"> {getcusmsg1}</p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_div">
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           setcusmodal1(false);
            //         }}
            //       >
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </div>
            // ------
            // <NewModal
            //   title=""
            //   msg={getcusmsg1}
            //   setShowModal={openmodal}
            //   closemodal={closemodal}
            // />

            <V2Modal
              canclebtn={false}
              title=""
              msg={getcusmsg1}
              setShowModal={setcusmodal1}
              okfunction={closemodal}
            />
          )}
        </>
      )}
    </div>
  );
};
export default Acceptinternationaluser;
