import React, { useState } from "react";
import "./MyOrders.css";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { get_user_place_order_data } from "../../utils/Constant";
import { useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";

const MyOrders = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [loading, setloading] = useState(false);
  const [getdata, setdata] = useState([]);

  useEffect(() => {
    allOrders();
  }, []);

  const allOrders = async () => {
    setloading(true);
    const params = {
      user_id: userId,
    };
    axios
      .post(get_user_place_order_data, qs.stringify(params))
      .then((res) => {
        setloading(false);

        console.log(
          "GetData of place order data_-------------+_->",
          JSON.stringify(res.data, null, 2)
        );

        if (res.data.status == 1) {
          console.log("datas--->", res.data);
          setdata(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <div className="my_order_main_wrapp">
      <div className="my_order_base_wrapp">
        <div className="mo_nav_wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>My Orders</p>
        </div>

        {/* products start */}
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="mo_products_wrap">
            {getdata && getdata.length == 0 ? (
              <p style={{ color: "white", marginTop: "50px" }}>
                No items found
              </p>
            ) : null}
            {getdata && getdata.length > 0
              ? getdata.map((item, index) => {
                  return (
                    <div className="mo_single_product">
                      <div className="mo_sp_part1">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="mo_sp_part2">
                        <p className="mo_sp_part2_heading">
                          {item.product_name}
                        </p>
                        {item.size && item.size.length > 0 ? (
                          <div className="mo_sp_part2_size_wrapp">
                            <p>Selectd Size : </p>
                            {item.size.map((itm, ind) => {
                              return <b>{itm}</b>;
                            })}
                          </div>
                        ) : null}
                        <div className="mo_sp_part2_size_wrapp">
                          <p style={{ color: "white" }}>Quantity : &nbsp;</p>
                          <b style={{ color: "white" }}>{item.quantity}</b>
                        </div>
                        <div className="mo_sp_part2_hr" />
                        <p className="mo_sp_part2_final_price">
                          ₹{item.total}/-
                        </p>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
        {/* products end */}
      </div>
    </div>
  );
};

export default MyOrders;
