import React, { useEffect, useState } from "react";
import "./Feed2.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardview from "../../components/feedcard/Cardview";
import Cardviewsecond from "../../components/feedcard/CardViewsecond";
import Cardthree from "../../components/feedcard/cardthree";
import Footer from "../../common/footer/Footer";

import InfiniteScroll from "react-infinite-scroll-component";

import axios from "axios";
import {
  add_joyride,
  get_all_user_selected_interest,
  get_user_joyride,
} from "../../utils/Constant";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import { BiSearch } from "react-icons/bi";
import Cardfour from "../../components/feedcard/Cardfour";
import Cardfive from "../../components/feedcard/Cardfive";
import Cardsix from "../../components/feedcard/Cardsix";
import Cardseven from "../../components/feedcard/Cardseven";
import Cardeight from "../../components/feedcard/Cardeight";
import { debounce } from "lodash";
import Cardfeed13 from "../../components/feedcard/Card13";
import FeedCard14 from "../../components/feedcard/FeedCard14";
import FeedCard15 from "../../components/feedcard/FeedCard15";
import Redcard from "../../components/redcard/Redcard";
import Yellowcard from "../../components/yellowcard/Yellowcard";
import NewModal from "../../common/newmodal/NewModal";
import CardSkeletonLoader from "../../components/CardSkeletonLoader";
import NewFrame1 from "../../frames/feedframes/FeedNewFrame1";
import NewFrame2 from "../../frames/feedframes/FeedNewFrame2";
import NewFrame3 from "../../frames/feedframes/FeedNewFrame3";
import NewFrame4 from "../../frames/feedframes/FeedNewFrame4";
import NewFrame5 from "../../frames/feedframes/FeedNewFrame5";
import NewFrame6 from "../../frames/feedframes/FeedNewFrame6";
import NewFrame7 from "../../frames/feedframes/FeedNewFrame7";
import NewFrame8 from "../../frames/feedframes/FeedNewFrame8";
import NewFrame9 from "../../frames/feedframes/FeedNewFrame9";
import NewFrame10 from "../../frames/feedframes/FeedNewFrame10";
import NewFooter from "../../common/newfooter/NewFooter";
import V2Modal from "../../common/v2modal/V2Modal";
import CustomJoyride from "../../utils/CustomJoyride";

const steps = [
  {
    target: ".feed_joy_1",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".feed_joy_2",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".feed_joy_3",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
];

const Feed3 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userId = localStorage.getItem("user_id");

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastpage, setLastPage] = useState(4);
  const [ismodalopen, setIsModalOpen] = useState(false);
  const [getmsg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");
  const [loading, setloading] = useState(false);

  const [isSkeletonLoad, setIsSkeletonLoad] = useState(true); //for skeleton

  const [allIntrests, setAllIntrests] = useState([]);
  const [getintrestId, setIntrestId] = useState(0);
  const [getintrestShow, setIntrestShow] = useState(false);
  const [feed_list, setFeedList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [run, setRun] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setloading(false), 2000); // Replace with actual loading logic
    return () => clearTimeout(timer);
  }, []);

  const fetchData = async () => {
    if (loading || nextPage > lastpage) {
      return;
    }
    setloading(true);
    const formdata = new FormData();
    formdata.append("user_id", userId);
    formdata.append("currentPage", nextPage);

    try {
      const response = await axios.post(
        "https://www.ravi.host/api/list_of_release_bond_3",
        formdata
      );
      setloading(false);
      // setIsSkeletonLoad(false);

      if (
        response.data.result &&
        typeof response.data.result[Symbol.iterator] === "function"
      ) {
        setData((prevData) => [...prevData, ...response.data.result]);
        setFeedList((prevData) => [...prevData, ...response.data.result]);
        setFilteredData((prevData) => [...prevData, ...response.data.result]);
      } else {
        // Handle the case where response.data.result is not iterable
      }

      setLastPage(response.data.totalPages);
      setNextPage(nextPage + 1);

      //  window.scrollTo(0, document.documentElement.scrollHeight);
      setIsSkeletonLoad(false);
    } catch (error) {
      setloading(false);
      console.error("Error fetching data:", error);
    }
  };

  const GetAllIntrest = () => {
    setloading(true);
    setIsSkeletonLoad(true);

    const param = {
      user_id: userId,
    };

    axios
      .post(get_all_user_selected_interest, qs.stringify(param))
      .then((Response) => {
        setloading(false);

        if (Response.data.status === 1) {
          setAllIntrests(Response.data.result);
        } else {
          setIsModalOpen(true);
          setMsg(Response.data.message);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleScroll = () => {
    const scrolledToBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.offsetHeight;

    if (scrolledToBottom && lastpage > page) {
      setPage((prevPage) => prevPage + 1);
    } else if (scrolledToBottom && lastpage == page) {
      setPage(lastpage);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    // restoreScrollPosition();
    GetAllIntrest();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [page, lastpage]);
  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight;

      if (scrolledToBottom) {
        fetchData();
      }
      // console.log("111", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, lastpage, loading]);

  const fetchMoreData = () => {
    if (loading || nextPage > lastpage) {
      return;
    }

    fetchData();
  };

  const filterData = (name) => {
    if (name) {
      const filteredData = feed_list.filter((item) =>
        item.bond_interest.some((interest) => interest.name === name)
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(feed_list);
    }
  };

  const closemodal = () => {
    setIsModalOpen(false);
  };
  const openmodal = () => {
    setIsModalOpen(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["feed_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            setRun(false);
          } else {
            setRun(true);
          }
        } else {
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "feed_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end

  return (
    <InfiniteScroll
      dataLength={data.length} // This is important to prevent infinite loop when you reach the end
      next={fetchMoreData}
      hasMore={nextPage <= lastpage}
      endMessage={
        <div
          style={{
            textAlign: "center",
            width: "240px",
            height: "100px",
            // border: "1px solid red",
            margin: "10px auto",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#fff",
              fontSize: "16px",
              fontFamily: "var(--font-600)",
            }}
          >
            {" "}
            Yay! You have seen it all
          </p>
        </div>
      }
    >
      <div className="page_main_wrapp">
        {/* <CustomJoyride
          steps={steps}
          run={run}
          showProgress={false}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 100000,
              // primaryColor: "var(--color-main)",
            },

            spotlight: {
              backgroundColor: "transparent",
            },
          }}
        /> */}
        <div className="page_base_wrapp_2" style={{ position: "relative" }}>
          {/* <NavbarSec name=""  /> */}
          <div className="feed_nav_main_wrapp">
            <div className="feed_nav_base_wrapp">
              <button
                onClick={() => {
                  navigate("/homescreen2");
                  setIntrestId(0);
                }}
                className="feed_nav_name"
              >
                rarerez
              </button>

              <div className="feed_nav_search_filter_btn_wrapp">
                <button
                  onClick={() => {
                    navigate("/homesearch");
                    setIntrestId(0);
                  }}
                  className="feed_nav_search_btn feed_joy_1"
                >
                  {/* <img src={images.search} alt="" /> */}
                  <BiSearch size={25} color="#fff" />
                </button>
                <button
                  className="feed_nav_search_btn feed_joy_2"
                  onClick={() => setIntrestShow(!getintrestShow)}
                >
                  <img src={images.feed_filter} alt="" />
                  {/* <BiSearch size={25} color="#fff" /> */}
                </button>
              </div>
            </div>

            {getintrestShow && (
              <div className="feed_catagory_wrapp">
                <button
                  style={{
                    border:
                      getintrestId === 0
                        ? "1px solid var(--color-main)"
                        : "1px solid var(--color-white)",
                  }}
                  className="feed_explore_btn"
                  onClick={() => {
                    setFilteredData(data);
                    setIntrestId(0);
                  }}
                >
                  <img src={images.explore} alt="" />
                  <p>Explore</p>
                </button>

                {/* all catagory btns start */}
                <div
                  className="product_interests_wrapp"
                  style={{ marginLeft: "10px" }}
                >
                  {allIntrests &&
                    allIntrests.length > 0 &&
                    allIntrests.map((item, index) => {
                      return (
                        <>
                          <button
                            className="feed_explore_btn"
                            style={{
                              minWidth: "80px",
                              maxWidth: "80px",
                              border:
                                item.id === getintrestId
                                  ? "1px solid var(--color-main)"
                                  : "1px solid var(--color-white)",
                            }}
                            onClick={() => {
                              filterData(item.interest_name);
                              setIntrestId(item.id);
                            }}
                          >
                            <img src={item.interest_icon} alt="" />
                            <p>{item.interest_name}</p>
                          </button>
                        </>
                      );
                    })}
                </div>
                {/* all catagory btns end */}
              </div>
            )}
          </div>
          <div style={{ width: "100%" }} className="feed_joy_3">
            {isSkeletonLoad && (
              <>
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
              </>
            )}

            {filteredData && filteredData.length == 0 ? (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "60px",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                No item found.
              </p>
            ) : null}
            {filteredData && filteredData.length > 0
              ? filteredData.map((item, index) => {
                  console.log(item.product_name);

                  return (
                    <div
                      className="feedmain"
                      style={{ marginTop: "10px", marginBottom: "70px" }}
                    >
                      <Link
                        to={
                          item.is_club_id == ""
                            ? "/UserProfile"
                            : "/Clubprofile"
                        }
                        state={
                          item.is_club_id == ""
                            ? { user_id: item.user_id }
                            : { club_id: item.is_club_id }
                        }
                        className="feed_details"
                      >
                        <div className="feed_user_icon_Wrapp">
                          <img src={item.profile} className="" alt="" />
                        </div>
                        <p className="user_feed_nm" style={{ width: "56%" }}>
                          {item.username}
                        </p>
                      </Link>

                      <div className="mt-2" style={{ width: "100%" }}>
                        <div className="">
                          {
                            // item.theme_index == 6 ? (
                            //   <Yellowcard
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     like_btn={true}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 7 ? (
                            //   <Redcard
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 8 ? (
                            //   <Cardfour
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 9 ? (
                            //   <Cardfive
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 10 ? (
                            //   <Cardsix
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 11 ? (
                            //   <Cardseven
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 12 ? (
                            //   <Cardeight
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 13 ? (
                            //   <Cardfeed13
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) : item.theme_index == 14 ? (
                            //   <FeedCard14
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) :
                            // item.theme_index == 15 ? (
                            //   <FeedCard15
                            //     item={item}
                            //     setIsModalOpen={setIsModalOpen}
                            //     setMsg={setMsg}
                            //     setModalName={setModalName}
                            //     // windowWidth={windowWidth}
                            //   />
                            // ) :
                            item.theme_index == 16 ? (
                              <NewFrame1
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 17 ? (
                              <NewFrame2
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 18 ? (
                              <NewFrame3
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 19 ? (
                              <NewFrame4
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 20 ? (
                              <NewFrame5
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 21 ? (
                              <NewFrame6
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 22 ? (
                              <NewFrame7
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 23 ? (
                              <NewFrame8
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 24 ? (
                              <NewFrame9
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : item.theme_index == 25 ? (
                              <NewFrame10
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                            ) : (
                              <NewFrame1
                                item={item}
                                setIsModalOpen={setIsModalOpen}
                                setMsg={setMsg}
                                setModalName={setModalName}
                                like_btn={true}
                                windowWidth={windowWidth}
                              />
                              // <Cardviewsecond
                              //   item={item}
                              //   setIsModalOpen={setIsModalOpen}
                              //   setMsg={setMsg}
                              //   setModalName={setModalName}
                              //   // fetchData={fetchData}
                              // />
                              // <Cardsix
                              //   item={item}
                              //   setIsModalOpen={setIsModalOpen}
                              //   setMsg={setMsg}
                              //   setModalName={setModalName}
                              //   fetchData={fetchData}
                              // />
                            )
                          }
                        </div>
                      </div>
                      <br />
                    </div>
                  );
                })
              : null}
          </div>
          {loading && (
            <div className="loader" style={{ marginTop: "10px" }}></div>
          )}

          <br />
          <br />
        </div>

        {/* <Footer getlocation={location.pathname} /> */}
        <NewFooter getlocation={location.pathname} />

        {ismodalopen && (
          <>
            {/* <NewModal
              title={getmodalName}
              msg={getmsg}
              setShowModal={openmodal}
              closemodal={closemodal}
              
            /> */}

            <V2Modal
              canclebtn={false}
              title={getmodalName}
              msg={getmsg}
              setShowModal={setIsModalOpen}
              okfunction={closemodal}
            />
          </>
        )}
      </div>
    </InfiniteScroll>
  );
};

export default Feed3;
