import React, { useEffect, useRef, useState } from "react";
import "./createclubbenifit.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import {
  get_all_benifit_category,
  get_all_user_manager,
  product_bond_benifit,
} from "../../utils/Constant";
import axios from "axios";
import CusModal from "../../common/cusmodal/CusModal";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Createclubbenefit = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showOtherSelect, setShowOtherSelect] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [catname, setcatname] = useState("");
  const [catid, setcatid] = useState("");
  const [getmanagerdata, setmanagerdata] = useState([]);
  const [getmanagersearch, setmanagersearch] = useState([]);
  const [contectname, setcontectname] = useState("");
  const [contectid, setcontectid] = useState("");
  const [getcontect, setgetcontect] = useState("me");
  const [getcon, setgetcon] = useState(false);
  const handleRadioChange = (event) => {
    setcontectname("");
    const value = event.target.value;
    setgetcontect(value);
    if (value === "other") {
      setShowOtherSelect(true);
    } else {
      setShowOtherSelect(false);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   // setImageFiles(e.target.files[0]);
  //   setSelectedFile((prevSelectedImages) => [...prevSelectedImages, ...files]);
  // };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedFiles = [];

    for (let i = 0; i < files.length; i++) {
      newSelectedFiles.push(files[i]);
    }

    // setSelectedFiles(newSelectedFiles);
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newSelectedFiles,
    ]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };
  const handlePlayVideo = (index) => {
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      videoElement.play();
    }
  };

  // Function to open the modal

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // modal
  const [searchValue, setSearchValue] = useState("");

  const contactNames = [
    "Vishal",
    "John",
    "Emily",
    "Michael",
    "Sarah",
    "David",
    "Emma",
    "Daniel",
    "Olivia",
    "Sophia",
  ];

  const filteredContactNames = contactNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const [loading, setloading] = useState(false);

  useEffect(() => {
    getReleasebond();
    // Getallusermanager();
  }, []);

  const [getdata, setdata] = useState([]);

  const getReleasebond = () => {
    setloading(true);
    axios
      .get(get_all_benifit_category)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          setdata(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const userId = localStorage.getItem("user_id");

  const Getallusermanager = () => {
    const param = {
      user_id: userId,
    };
    setloading(true);
    axios
      .post(get_all_user_manager, param)
      .then((Response) => {
        console.log("acdascs--->", Response.data.result);
        setloading(false);
        if (Response.data.status == 1) {
          // setmanagerdata(Response.data.result)
          setmanagersearch(Response.data.result);
        } else {
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const searchFilter_branch = (text) => {
    const newData = getmanagersearch.filter(function (item) {
      console.log("item", item.label);
      const employee = item.label ? item.label.toUpperCase() : "".toLowerCase();
      const number = String(item.mobile_number)
        ? String(item.mobile_number).toUpperCase()
        : "".toUpperCase();
      const status = String(item.status)
        ? String(item.status).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        status.indexOf(textData) > -1
      );
    });
    setmanagerdata(newData);
    console.log("asvsvsds", newData);
  };

  const [getbanifit, setbenifit] = useState("");
  const [getdes, setdes] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // const ccc = () => {
  //   alert(getcontect !== "other" ? "0" : getcontect);
  // };

  const createnfb = () => {
    if (getbanifit == "") {
      setShowModal(true);
      setErrorMessage("Enter reward name");
    } else if (catid == "") {
      setShowModal(true);
      setErrorMessage("Select category");
    } else if (getdes == "") {
      setShowModal(true);
      setErrorMessage("Enter description");
    } else if (selectedFiles == 0) {
      setShowModal(true);
      setErrorMessage("Upload atlist one image");
    } else {
      let formData = new FormData();
      formData.append("club_product_id", location.state.club_product_id);
      formData.append("user_id", userId);
      formData.append("reward_name", getbanifit);
      formData.append("free_paid", 0);
      //   formData.append("is_contact", getcontect == "other" ? 1 : 0);
      //   formData.append("contact_user_id", contectid);

      formData.append("for_other_user_paid", "0");

      formData.append("ctegory_id", catid);
      formData.append("free_paid_user_option", "0");
      formData.append("valid_time", "0");
      formData.append("description", getdes);
      formData.append(
        "for_other_user_price",
        getcontect !== "other" ? "0" : getcontect
      );

      // this.state.images_array.map((i) =>
      //   i == "uri"
      //     ? null
      //     : formData.append("img_video[]", {
      //         uri: Platform.OS === "android" ? i : i.replace("file://", ""),
      //         name: "name",
      //         type: "image/jpeg",
      //       })
      // );
      selectedFiles.forEach((image, index) => {
        formData.append("img_video[]", image);
      });
      console.log("param", formData);

      setloading(true);
      axios
        .post("https://www.ravi.host/api/add_club_bond_benifit", formData)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            setShowModal(true);
            setErrorMessage(Response.data.message);
            setgetcon(true);
          } else {
            setShowModal(true);
            setErrorMessage(Response.data.message);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const closemodal = () => {
    setShowModal(false);
  };

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Create Rewards" />
        <div className="createbenefit">
          <div>
            <b>Reward Name</b>
            <input
              type="text"
              className="benefit_nm_new"
              placeholder="Enter Reward Name"
              onChange={(e) => {
                setbenifit(e.target.value);
              }}
            />
          </div>
          <br />
          <b>Category</b>
          <div style={{ position: "relative", width: "100%" }}>
            <button
              className="nav-button left"
              onClick={scrollLeft}
              style={{ padding: "10px 0px", width: "24px" }}
            >
              <FaAngleLeft color="var(--color-main)" size={22} />
            </button>
            <div
              ref={sliderRef}
              style={{ padding: "6px 1.5rem" }}
              className="benefit_category_club"
            >
              {getdata &&
                getdata.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setcatid(item.cat_id);
                      }}
                      className={
                        catid == item.cat_id
                          ? "benefit_category_sub_club_validation"
                          : "benefit_category_sub_club"
                      }
                    >
                      <img
                        src={
                          "https://www.ravi.host/public/assets/benifit_category/" +
                          item.cat_icon
                        }
                        className="benefit_category_sub_img"
                        alt=""
                      />
                      <p className="nm_category_new">{item.cat_name}</p>
                    </div>
                  );
                })}
            </div>
            <button
              className="nav-button right"
              onClick={scrollRight}
              style={{
                justifyContent: "flex-end",
                padding: "10px 0px",
                width: "24px",
              }}
            >
              <FaAngleRight color="var(--color-main)" size={22} />
            </button>
          </div>
          <br />
          <div>
            <b>Description</b>
            <textarea
              name=""
              className="benefit_nm_new"
              id=""
              cols="30"
              rows="7"
              onChange={(e) => {
                setdes(e.target.value);
              }}
            ></textarea>
          </div>
          {/* <b>Contact</b>
          <div>
            <div className="benefit_contact">
              <div className="benefit_contact_sub">
                <input
                  type="radio"
                  name="contactType"
                  value="me"
                  onChange={handleRadioChange}
                />
                <p>me</p>
              </div>
              <div className="benefit_contact_sub">
                <input
                  type="radio"
                  name="contactType"
                  value="other"
                  onChange={handleRadioChange}
                />
                <p>Other</p>
              </div>
            </div>
            {showOtherSelect && (
              <div className="other_select">
                <br />
                <div onClick={openModal}>
                  <input
                    type="text"
                    readOnly
                    placeholder="Select Contact Person"
                    value={contectname}
                  />
                </div>
              </div>
            )}
          </div> */}
          <p className="file_benefit_details_titel">Upload Images</p>
          <div className="file_benefit_details">
            <label className="custom-file-input">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                multiple
                className="benefit_input_2"
              />
              <div className="upload_tag_benefit">
                <span className="add_img_nm">+ add images</span>
              </div>
            </label>
            {selectedFiles.length > 0 && (
              <div className="file-preview">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="preview-item">
                    <span
                      className="close-icon1"
                      onClick={() => handleRemoveFile(index)}
                    >
                      ✖
                    </span>
                    {file.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(file)}
                        className="benefit_preview"
                        alt={`Preview ${index}`}
                      />
                    ) : (
                      <video
                        controls
                        className="benefit_preview2"
                        onClick={() => handlePlayVideo(index)}
                      >
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <center>
              <button
                onClick={() => {
                  // ccc();
                  createnfb();
                }}
                className="new_btn_wrapp mx-1 mt-3"
              >
                <p>Proceed</p>
              </button>
            </center>
          </div>
          {isModalOpen && (
            <div className="modal_p-overlay">
              <div className="modal_p-content">
                <div className="modal_p-header">
                  <img
                    src={images.back}
                    alt=""
                    className="modal-close_p"
                    onClick={closeModal}
                  />
                  <h2>Add contact Person</h2>
                  <button className="modal-close">&times;</button>
                </div>
                <div className="modal_p-body">
                  <div>
                    <input
                      type="text"
                      placeholder="Search"
                      className="viewmanagers_search"
                      onChange={(e) => {
                        e.target.value.length == 0
                          ? setmanagerdata([])
                          : searchFilter_branch(e.target.value);
                      }}
                    />
                  </div>
                  <div className="list_of_contact_nm">
                    {getmanagerdata.map((item, index) => (
                      <p
                        onClick={() => {
                          setIsModalOpen(false);
                          setcontectid(item.id);
                          setcontectname(item.label);
                        }}
                        className="add_contact_name"
                        key={index}
                      >
                        {item.label}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{errorMessage}</p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           getcon == true
        //             ? // ? navigate("/homescreen2")
        //               navigate(-1)
        //             : setShowModal(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setShowModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{errorMessage ? errorMessage : ""}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         getcon == true
        //           ? // ? navigate("/homescreen2")
        //             navigate(-1)
        //           : setShowModal(false);
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage ? errorMessage : ""}
          setShowModal={setShowModal}
          okfunction={() => {
            getcon == true
              ? // ? navigate("/homescreen2")
                navigate(-1)
              : setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Createclubbenefit;
