import React, { useState, useEffect } from "react";
import "./clubsetting.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import NavbarSec from "../../common/NavbarSec";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";

const Clubsetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cover, setCover] = useState(null);
  const userId = localStorage.getItem("user_id");
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const param = {
        club_id: location.state.club_id,
        user_id: userId,
      };
      const response = await axios.post(
        "https://www.ravi.host/api/get_all_club_detail",
        qs.stringify(param)
      );
      console.log(
        "Responseclubsettings----->",
        JSON.stringify(response.data.result.is_on_off, null, 2)
      );
      if (response.data.status === 1) {
        setStatus(response.data.result.is_on_off);
        setCover(response.data.result.cover_photo);
        setProfile(response.data.result.profile);
        setName(response.data.result.club_name);
      } else {
        // Handle error here
        console.log("Error:", response.data.message);
      }
    } catch (error) {
      // Handle any other errors that occurred during the request
      console.error(error);
    }
  };
  const Clubonoff = (newStatus) => {
    console.log(newStatus);
    setIsLoading(true);

    const param = {
      user_id: userId,
      club_id: location.state.club_id,
      status: newStatus,
    };

    console.log(param);

    axios
      .post(
        "https://www.ravi.host/api/owner_manager_can_stop_onboarding_club",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(
          "Responseclubsettings----->",
          JSON.stringify(Response.data, null, 2)
        );
        if (Response.data.status == 1) {
          fetchData();
        } else {
          fetchData();
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle any other errors that occurred during the request
        console.error(error);
      });
  };
  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isModalVisible, bodyScrollEnabled]);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };
  return (
    <div className="club_set_main_wrapp">
      <div className="club_set_base_wrapp">
        <div className="club_set_navbar_wrapp">
          <div className="club_set_wrapp">
            {/* <div className="club_set_1">
              <button
                onClick={() =>
                  navigate(-1, {
                    state: {
                      club_id: location.state.club_id,
                    },
                  })
                }
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
            </div>
            <div className="club_set_2">
              <p className="nav_screen_name_set">Club Profile</p>
            </div> */}

            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate("/Clubprofile", {
                    state: {
                      club_id: location.state.club_id,
                    },
                  });
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Club Profile</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>
          </div>

          <img src={cover} className="club_set_profile" alt="" />
          <div className="footer_club_set">
            <div className="footer_club_set_1">
              <img src={profile} className="set_club_pr" alt="" />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "90px" }} className="footer_club_set_2">
          <p className="set_nm_club">{name}</p>
        </div>
        <br />

        <div className="details_ofset">
          <div
            onClick={() => {
              if (status === 0) {
                setIsModalVisible(true);
                setCusmsg("Please on the club.");
              } else {
                navigate("/editclub", {
                  state: {
                    club_id: location.state.club_id,
                  },
                });
              }
            }}
            className="details_ofset_1"
          >
            <img src={images.editProfile} className="set_club_sub" alt="" />{" "}
            <p className="set_club_sub_nm"> Edit club basic details</p>
          </div>
          <div
            onClick={() => {
              if (status === 0) {
                setIsModalVisible(true);
                setCusmsg("Please on the club.");
              } else {
                navigate("/clubmanager", {
                  state: {
                    club_id: location.state.club_id,
                  },
                });
              }
            }}
            className="details_ofset_1"
          >
            <img src={images.usernew} className="set_club_sub" alt="" />{" "}
            <p className="set_club_sub_nm">Club managers/owners</p>
          </div>
          <div
            onClick={() => {
              navigate("/clubRequest", {
                state: {
                  club_id: location.state.club_id,
                },
              });
            }}
            className="details_ofset_1"
          >
            <img src={images.usernew} className="set_club_sub" alt="" />{" "}
            <p className="set_club_sub_nm">Join Request</p>
          </div>
          <div className="details_ofset_1">
            <img src={images.notifications} className="set_club_sub" alt="" />{" "}
            <p className="set_club_sub_nm">Club active/inactive</p>
            <input
              className="set_chek"
              type="checkbox"
              id="checkbox2"
              checked={status == 1}
              onChange={() => {
                Clubonoff(status == 1 ? 0 : 1);
              }}
            />
            <label
              // style={{ border: status == 1 ? "red" : "gray" }}
              className={status == 1 ? "setlabel" : "setlabel1"}
              for="checkbox2"
            ></label>
          </div>
        </div>
      </div>
      {isModalVisible && (
        // <NewModal
        //   title=""
        //   msg={cusmsg}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default Clubsetting;
