import React, { useState } from "react";

import "./FlipCard.css";

const FlipCard = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [rotation, setRotation] = useState(0);

  const getInitialX = (e) => {
    if (e.clientX) {
      return e.clientX;
    } else if (e.touches && e.touches[0]) {
      return e.touches[0].clientX;
    }
    return 0;
  };

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(getInitialX(e));
  };

  const handleDrag = (e) => {
    if (!isDragging) return;

    const diffX = (e.clientX || (e.touches && e.touches[0].clientX)) - startX;
    const rotationIncrement = (diffX / 4) % 360; // Adjust speed by changing divisor

    setRotation((prevRotation) => prevRotation + rotationIncrement);
    setStartX(getInitialX(e));
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="book-container"
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDrag}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="book-cover"
        style={{ transform: `rotateY(${rotation}deg)` }}
      >
        <div className="cover-front" />
        <div className="cover-back" />
      </div>
    </div>
  );
};

export default FlipCard;
