import React, { useEffect, useState } from "react";
import "./NewCommunityRequest.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FiCheck } from "react-icons/fi";

const NewCommunityRequest = () => {
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [getseletedTab, setSelectedTab] = useState(1);
  const [getseletedMainTab, setSelectedMainTab] = useState(1);

  const _getDatacommunity = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_user_community_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setData(response.data.result);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const [getlegacy_family_data, setlegacy_family_data] = useState([]);
  const _Getlegacycommunity = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_legacy_community",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setlegacy_family_data(response.data.legacy_community_data);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _setAcceptReject_community = (id, status) => {
    const param = {
      user_community_id: id,
      user_id: userId,
      status: status,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/accept_reject_community_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          //   setData(response.data.result);
          _getDatacommunity();
          setSelectedMainTab(2);
          setSelectedTab(1);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // alert(location.state.con);
    _getDatacommunity();
    _Getlegacycommunity();

    //
    _getData_family();
    _Getlegacyfamily_data();
  }, []); // This will run _getData when the component mounts

  // vishal code start
  const [getdatafamily, setdatafamily] = useState([]);
  const _getData_family = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_user_family_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("get_user_family_request_responcw", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setdatafamily(response.data.result);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _setAcceptReject_family = (id, status) => {
    const param = {
      user_family_id: id,
      user_id: userId,
      status: status,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/accept_reject_family_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          _getData_family();
          setSelectedMainTab(1);
          setSelectedTabfamily(1);
        } else {
          _getData_family();
          setSelectedMainTab(1);
          setSelectedTabfamily(1);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const [getlegacy_family_data_show, setlegacy_family_data_show] = useState([]);

  const _Getlegacyfamily_data = () => {
    const param = {
      user_id: userId,
    };
    console.log(param);
    setIsLoading(true);

    axios
      .post("https://www.ravi.host/api/get_legacy_family", qs.stringify(param))
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setlegacy_family_data_show(response.data.legacy_family_data);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const [getseletedTabfamily, setSelectedTabfamily] = useState(1);

  return (
    <div className="new_wrapp_container reletive_position">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_circule_shadow_left"
      />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  location.state && location.state.con == true
                    ? navigate("/Sidemenu")
                    : navigate("/LegacyDashbord", {
                        state: {
                          first_time_popup: 0,
                          first_time_home: 0,
                        },
                      });
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>{getseletedMainTab == 1 ? "Family" : "Community"} Requests</p>
              <div></div>
            </div>

            {/* mian tabs start */}

            <div className="req_main_tabs_wrapp">
              <button
                className="new_btn_wrapp"
                style={{
                  height: "40px",
                  width: "48%",
                  opacity: getseletedMainTab == 1 ? "1" : "0.6",
                }}
                onClick={() => {
                  _getData_family();
                  setSelectedMainTab(1);
                }}
              >
                <p style={{ fontSize: "14px" }}>Family</p>
              </button>
              <button
                className="new_btn_wrapp"
                style={{
                  height: "40px",
                  width: "48%",
                  opacity: getseletedMainTab == 2 ? "1" : "0.6",
                }}
                onClick={() => {
                  _getDatacommunity();
                  setSelectedMainTab(2);
                }}
              >
                <p style={{ fontSize: "14px" }}>Community</p>
              </button>
            </div>

            {/* mian tabs end */}

            {/* request tabs section start */}

            {/* request tabs section end */}

            {/* recevied list start */}
            {getseletedMainTab == 1 ? (
              <>
                <div className="family_req_tabs_sec">
                  <div className="family_req_tabs_sec_details">
                    <button
                      onClick={() => {
                        setSelectedTabfamily(1);
                      }}
                      style={{ opacity: getseletedTabfamily === 1 ? 1 : 0.5 }}
                    >
                      Received
                    </button>
                    <div></div>
                    <button
                      onClick={() => {
                        setSelectedTabfamily(2);
                      }}
                      style={{ opacity: getseletedTabfamily === 2 ? 1 : 0.5 }}
                    >
                      Sent
                    </button>
                  </div>
                  <div className="family_req_tabs_sec_hr"></div>
                </div>

                {/* request tabs section end */}

                {/* recevied list start */}
                <div className="family_req_receved_list">
                  {getseletedTabfamily == 1 && (
                    <>
                      {getdatafamily && getdatafamily.length == 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>No requests found</p>
                        </div>
                      ) : (
                        <>
                          {getdatafamily && getdatafamily.length > 0
                            ? getdatafamily.map((item, index) => {
                                return (
                                  <>
                                    <div className="family_req_receved_single_card">
                                      <img
                                        onClick={() => {
                                          navigate("/UserProfile", {
                                            state: { user_id: item.user_id },
                                          });
                                          // console.log("AXA/S", item.user_id);
                                        }}
                                        src={item.profile}
                                        alt=""
                                      />
                                      <div className="family_req_receved_single_card_name">
                                        <h4 style={{ fontSize: "12px" }}>
                                          {item.firstname}
                                        </h4>
                                        {/* <p>Request Content</p> */}
                                        <p>{item.date_time}</p>
                                      </div>
                                      <div className="family_req_receved_single_card_btn_wrapp">
                                        <button
                                          onClick={() => {
                                            _setAcceptReject_family(
                                              item.user_family_id,
                                              1
                                            );
                                          }}
                                          className="family_req_receved_single_card_btn"
                                        >
                                          <FiCheck
                                            size={20}
                                            color="var(--color-white)"
                                          />
                                        </button>
                                        <button
                                          onClick={() => {
                                            _setAcceptReject_family(
                                              item.user_family_id,
                                              2
                                            );
                                          }}
                                          className="family_req_receved_single_card_btn"
                                        >
                                          <IoClose
                                            size={20}
                                            color="var(--color-white)"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </>
                      )}
                    </>
                  )}
                  {getseletedTabfamily == 2 && (
                    <>
                      {getlegacy_family_data_show &&
                      getlegacy_family_data_show.length == 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>No requests found</p>
                        </div>
                      ) : (
                        <>
                          {getlegacy_family_data_show &&
                          getlegacy_family_data_show.length > 0
                            ? getlegacy_family_data_show.map((item, index) => {
                                return (
                                  <>
                                    <div className="family_req_receved_single_card">
                                      <img
                                        onClick={() => {
                                          navigate("/UserProfile", {
                                            state: { user_id: item.user_id },
                                          });
                                          // console.log("AXA/S", item.user_id);
                                        }}
                                        src={item.profile}
                                        alt=""
                                      />
                                      <div className="family_req_receved_single_card_name">
                                        <h4 style={{ fontSize: "12px" }}>
                                          {item.firstname}
                                        </h4>
                                        <p>{item.date_time}</p>
                                      </div>
                                      <div className="family_req_receved_single_card_lrg_btn">
                                        <p>
                                          {item.status == 0
                                            ? "Panding"
                                            : "Accepted"}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="family_req_tabs_sec">
                  <div className="family_req_tabs_sec_details">
                    <button
                      onClick={() => {
                        setSelectedTab(1);
                      }}
                      style={{ opacity: getseletedTab === 1 ? 1 : 0.5 }}
                    >
                      Received
                    </button>
                    <div></div>
                    <button
                      onClick={() => {
                        setSelectedTab(2);
                      }}
                      style={{ opacity: getseletedTab === 2 ? 1 : 0.5 }}
                    >
                      Sent
                    </button>
                  </div>
                  <div className="family_req_tabs_sec_hr"></div>
                </div>
                <div className="family_req_receved_list">
                  {getseletedTab == 1 && (
                    <>
                      {data && data.length == 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>No requests found</p>
                        </div>
                      ) : (
                        <>
                          {data && data.length > 0
                            ? data.map((item, index) => {
                                return (
                                  <div className="family_req_receved_single_card">
                                    <img
                                      onClick={() => {
                                        navigate("/UserProfile", {
                                          state: { user_id: item.user_id },
                                        });
                                        // console.log("AXA/S", item.user_id);
                                      }}
                                      src={item.profile}
                                      alt=""
                                    />
                                    <div className="family_req_receved_single_card_name">
                                      <h4 style={{ fontSize: "12px" }}>
                                        {item.firstname}
                                      </h4>
                                      {/* <p>Request Content</p> */}
                                      <p>{item.date_time}</p>
                                    </div>
                                    <div className="family_req_receved_single_card_btn_wrapp">
                                      <button
                                        onClick={() => {
                                          _setAcceptReject_community(
                                            item.user_community_id,
                                            1
                                          );
                                        }}
                                        className="family_req_receved_single_card_btn"
                                      >
                                        <FiCheck
                                          size={20}
                                          color="var(--color-white)"
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          _setAcceptReject_community(
                                            item.user_community_id,
                                            2
                                          );
                                        }}
                                        className="family_req_receved_single_card_btn"
                                      >
                                        <IoClose
                                          size={20}
                                          color="var(--color-white)"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      )}
                    </>
                  )}
                  {getseletedTab == 2 && (
                    <>
                      {getlegacy_family_data &&
                      getlegacy_family_data.length == 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>No requests found</p>
                        </div>
                      ) : (
                        <>
                          {getlegacy_family_data &&
                          getlegacy_family_data.length > 0
                            ? getlegacy_family_data.map((item, index) => {
                                return (
                                  <div className="family_req_receved_single_card">
                                    <img
                                      onClick={() => {
                                        navigate("/UserProfile", {
                                          state: { user_id: item.user_id },
                                        });
                                        // console.log("AXA/S", item.user_id);
                                      }}
                                      src={item.profile}
                                      alt=""
                                    />
                                    <div className="family_req_receved_single_card_name">
                                      <h4 style={{ fontSize: "12px" }}>
                                        {item.firstname}
                                      </h4>
                                      <p>{item.date_time}</p>
                                    </div>
                                    <div className="family_req_receved_single_card_lrg_btn">
                                      <p>
                                        {item.status == 0
                                          ? "Panding"
                                          : "Accepted"}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {/* recevied list end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCommunityRequest;
