import React from "react";
import "./AutherVideo.css";
import NavbarSec from "../../common/NavbarSec";
import { useState } from "react";
import images from "../../constants/images";
import samplevideo from "../../assets/video/smaple_video.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import CusModal from "../../common/cusmodal/CusModal";
import RecordRTC from "recordrtc";
import Webcam from "react-webcam";
import NewModal from "../../common/newmodal/NewModal";
import ReactModal from "react-modal";

const AutherVideo = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const navigate = useNavigate();
  const location = useLocation();

  // video start

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [getrecordedvideo, setrecordedvideo] = useState(false);
  const [recordingTime, setRecordingTime] = useState(180);
  const [isRecordingstatus, setRecordingstatus] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);
  const recorderRef = useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getpreviewVideoModal2, setPreviewVideoModal2] = useState(false);
  const [getvideo, setVideo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");

  // const handleVideoChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setSelectedVideos([...selectedVideos, ...files]);
  // };

  // const handleVideoChange = async (e) => {
  //   const files = Array.from(e.target.files);

  //   // Assuming you have a minimum and maximum duration in seconds
  //   const minDuration = 30; // Minimum duration in seconds
  //   const maxDuration = 180; // Maximum duration in seconds

  //   // Validate each video file
  //   for (const file of files) {
  //     const video = document.createElement("video");
  //     video.src = URL.createObjectURL(file);

  //     // Wait for the video metadata to load
  //     await new Promise((resolve) => {
  //       video.onloadedmetadata = resolve;
  //     });

  //     const duration = video.duration;

  //     if (duration < minDuration || duration > maxDuration) {
  //       // Video duration is outside the allowed range
  //       alert(
  //         `Video duration should be between ${minDuration}s and ${maxDuration}s.`
  //       );
  //     } else {
  //       // Video is within the allowed range, add it to the state
  //       setSelectedVideos([...selectedVideos, file]);
  //     }

  //     // Clean up the video element
  //     video.remove();
  //   }
  // };

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);

    // Assuming you have a minimum and maximum duration in seconds
    const minDuration = 20; // Minimum duration in seconds
    const maxDuration = 60; // Maximum duration in seconds

    // Validate each video file
    for (const file of files) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait for the video metadata to load
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      const duration = video.duration;

      if (duration < minDuration || duration > maxDuration) {
        // Video duration is outside the allowed range
        alert(
          `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        );
      } else {
        // Video is within the allowed range, add it to the state
        setSelectedVideos([...files]);
      }

      // Clean up the video element
      video.remove();
    }
  };

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(180);
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            // mimeType: "video/mp4",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
        });
    }
  };
  const stopRecording = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 180;
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();

        console.log("blob---->", blob.length);
        // Use the current value of recordingTime from the useEffect
        if (180 - recordingTime < minDuration) {
          // Display an alert or take other actions for insufficient duration
          alert(
            `Video duration should be between ${minDuration}s and ${maxDuration}s.`
          );
          setRecordedChunks([]);
          setSelectedVideos([]);
        } else {
          // Continue with handling the recorded video
          setRecordedChunks([blob]);
          setSelectedVideos([blob]);
          setRecordingTime(180);
          setIsModalVisiblevideo(false);

          // Stop the media stream to release the camera
          const stream = videoRef.current.srcObject;
          if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        }
      });
    }
  };

  const stopRecording1 = () => {
    setRecording(false);
    const minDuration = 30;
    const maxDuration = 180;

    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob---->", blob.length);
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        // if (recordingTime < minDuration) {
        //   // console.error(
        //   //   `Recording time should be at least ${minDuration} seconds.`
        //   // );
        //   alert(
        //     `Video duration should be between ${minDuration}s and ${maxDuration}s.`
        //   );
        //   setRecordedChunks([]);
        //   setSelectedVideos([]);
        // } else {
        setRecordedChunks([blob]);
        setSelectedVideos([blob]);
        setRecordingTime(180);
        setIsModalVisiblevideo(false);
        // }
      });
    }
  };

  useEffect(() => {
    let timerInterval;
    const minDuration = 30;

    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime - 1;

          // Check if the recording time is less than the minimum duration
          if (newTime < minDuration) {
            // Display an error or take other actions
            console.error(
              `Recording time should be at least ${minDuration} seconds.`
            );
          }

          // Check if the recording time reaches 1 second
          if (newTime <= 1) {
            stopRecording1(); // Stop recording or take other actions
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setIsModalVisiblevideo(false);
    setRecordingTime(180);
  };

  const showimage = () => {
    stopRecording();
    setRecordedChunks([]);
    setSelectedVideos([]);
    resetCountdown();
    console.log(videoRef.current);
    // Stop the media stream to release the camera
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const opendeleteModal = () => {
    setShowModal2(true);
  };
  // const handleVideoDelete = (index) => {
  //   const newVideos = [...selectedVideos];
  //   newVideos.splice(index, 1);
  //   setSelectedVideos(newVideos);
  //   setRecordedChunks(newVideos);
  // };

  const handleVideoDelete = () => {
    // const newVideos = [...selectedVideos];
    // newVideos.splice(index, 1);

    setSelectedVideos([]);
    // setSelectedVideos(newVideos);
    setRecordedChunks([]);
    setPreviewVideoModal2(false);
    setShowModal2(false);
  };
  const closedeleteModal = () => {
    setShowModal2(false);
  };

  useEffect(() => {
    console.log("setSelectedVideos", setSelectedVideos.length);
    console.log("setVideo", setVideo);
    console.log(" location.state.user_id", location.state.user_id);
  }, []);

  // const UploadVideo = () => {
  //   if (selectedVideos && selectedVideos.length <= 0) {
  //     setShowModal(true);
  //     setErrorMessage("Please Upload video");
  //   } else {
  //     // setIsLoading(true);
  //     let formData = new FormData();
  //     formData.append("user_id", location.state.user_id);

  //     //   if (setSelectedVideos == []) {
  //     //   } else {
  //     selectedVideos.forEach((image, index) => {
  //       formData.append("user_videos", image);
  //     });
  //     //   }
  //     console.log("formData", JSON.stringify(formData));

  //     axios
  //       .post(
  //         "https://www.ravi.host/api/user_add_video",
  //         formData,
  //         {
  //           onUploadProgress: (progressEvent) => {
  //             const percentage = Math.round(
  //               (progressEvent.loaded * 100) / progressEvent.total
  //             );
  //             console.log("Upload Percentage:", percentage);
  //             // Update the state to show the progress
  //             setUploadPercentage(percentage);
  //           },
  //         }
  //       )

  //       .then((Response) => {
  //         setIsLoading(false);
  //         console.log("param", Response.data);
  //         if (Response.data.status == 1) {
  //           //this.props.navigation.goBack();
  //           // this.props.navigation.replace("Interest", {
  //           //   user_id: this.props.route.params.user_id,
  //           // });
  //           navigate("/Intrests", {
  //             state: {
  //               user_id: location.state.user_id,
  //             },
  //           });
  //         } else {
  //           // this.setState({
  //           //   isModalVisible: true,
  //           //   cusmsg: Response.data.message,
  //           // });
  //           setShowModal(true);
  //           setErrorMessage(Response.data.message);
  //         }
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  const UploadVideo = () => {
    if (selectedVideos && selectedVideos.length <= 0) {
      setShowModal(true);
      setErrorMessage("Please Upload video");
    } else {
      // setIsLoading(true);
      let formData = new FormData();
      formData.append("user_id", location.state.user_id);

      selectedVideos.forEach((image, index) => {
        formData.append("user_videos", image);
      });

      axios
        .post("https://www.ravi.host/api/user_add_video", formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("Upload Percentage:", percentage);
            // Update the state to show the progress
            setUploadPercentage(percentage);
          },
        })
        .then((response) => {
          console.log("Response:", response.data);
          if (response.data.status === 1) {
            // Navigate to the "/Intrests" screen
            navigate("/Intrests", {
              state: {
                user_id: location.state.user_id,
              },
            });
          } else {
            setShowModal(true);
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setShowModal(true);
          setErrorMessage("An error occurred while uploading the video.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const openmodal = () => {
    setShowModal(true);
  };

  const closemodal = () => {
    setShowModal(false);
  };

  const openmodal1 = () => {
    setShowModal1(true);
  };

  const closemodal1 = () => {
    setShowModal1(false);
  };

  return (
    <div className="page_main_wrapp" style={{}}>
      {isloading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_base_wrapp_2" style={{ position: "relative" }}>
          {/* <NavbarSec name="Video" /> */}
          {/* navbar start */}
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "30px",
              // background: "#1b1919",
              zIndex: 11,
            }}
          >
            <div className="navsec_back_arrow">
              <img src={images.back} alt="" onClick={() => navigate("/")} />
            </div>

            <b
              style={{
                color: "#fff",
                fontfamily: "var(--font-600)",
                fontsize: "14px",
              }}
            >
              Authentication video
            </b>
          </div>
          {/* navbar end */}

          {/* video upload input start */}
          <div
            className="auth-video-contents-div"
            style={{
              // flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "flex-start",
              margin: "20px auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="image-upload"
                style={{
                  // alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  // width:"60%",
                  flexDirection: "column",
                  marginTop: "0",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    color: "white",
                    fontSize: "12px",
                    textAlign: "center",
                    height: "42px",
                  }}
                >
                  Upload Authentication Video{" "}
                  {/* <span
                    className="details_home_icon"
                    onClick={() => {
                      setShowModal1(true);
                      setErrorMessage1(
                        "Bring your Rarerez to life! Record a captivating video showcasing your asset in all its glory. Show or speak about its unique features, craftsmanship, or the story behind its creation. Remember to keep it between 30 seconds to 3 minute for the best impact."
                      );
                    }}
                  >
                    {String.fromCharCode(9432)}
                  </span> */}
                </p>
                {selectedVideos && selectedVideos.length <= 0 ? (
                  <>
                    <label
                      onClick={() => {
                        setIsModalVisiblevideo(true);
                        setRecordedChunks([]);
                        setrecordedvideo(false);
                      }}
                      htmlFor="creator_vid"
                      className="upload-button"
                      style={{ width: "130px", height: "200px", opacity: 0.8 }}
                    >
                      <img
                        src={images.new_plus_icon}
                        alt=""
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                        }}
                      />
                    </label>
                    <input
                      disabled
                      id="creator_vid"
                      type="file"
                      accept="video/*"
                      // multiple
                      capture="camera"
                      onChange={(e) => {
                        handleVideoChange(e);
                        // setIsModalVisiblevideo(true);
                      }}
                    />
                  </>
                ) : (
                  <div
                    className="image-previews"
                    style={{
                      width: "77%",
                      justifyContent: "center",
                    }}
                  >
                    {selectedVideos.map((image, index) => (
                      <div
                        className="image-preview"
                        // style={{ width: "140px", height: "100px", opacity: 0.8 }}
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed gray",
                          borderRadius: "8px",
                          width: "70vw",
                          height: "200px",
                          maxWidth: "130px",
                        }}
                      >
                        {/* <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                  /> */}
                        <button
                          onClick={() => {
                            setPreviewVideoModal2(true);
                            setVideo(URL.createObjectURL(image));
                            console.log("getvideo", getvideo);
                            console.log(
                              "getvideo 111",
                              URL.createObjectURL(image)
                            );
                          }}
                          style={{
                            color: "var(--color-main)",
                            fontSize: "12px",
                            fontFamily: "var(--font-600)",
                          }}
                        >
                          Preview
                        </button>
                        {/* <button
                      className="delete-button"
                      onClick={() => handleVideoDelete(index)}
                    >
                      <img src={images.close_red} alt="" />
                    </button> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* sample video start  */}
              <div
                className="sample_vid_main_wrapp"
                style={{
                  // width:"50%",
                  gap: "10px",
                  margin: "0",
                }}
              >
                <p
                  style={{
                    fontFamily: "var(--font-400)",
                    color: "white",
                    fontSize: "12px",
                    textAlign: "center",
                    height: "42px",
                  }}
                >
                  Sample Video
                </p>
                <video
                  ref={videoRef}
                  controls={false}
                  // controls
                  style={{ width: "80%", height: "200px" }}
                >
                  <source src={images.smaple_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <div
                  style={{
                    position: "absolute",
                    top: "60%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={togglePlayPause}
                >
                  {isPlaying ? (
                    <img
                      src={images.pause_white}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={images.play_white}
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            <p className="auth-video-text">
              Bring your Rarerez to life! Record a captivating video showcasing
              your asset in all its glory. Show or speak about its unique
              features, craftsmanship, or the story behind its creation.
              Remember to keep it between 30 seconds to 3 minute for the best
              impact.
            </p>
          </div>
          {/* video upload input end */}

          {/* upload button start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px auto",
              marginTop: "50px",
            }}
          >
            <button
              onClick={UploadVideo}
              style={{
                display:
                  uploadPercentage === 0 || uploadPercentage === 100
                    ? "block"
                    : "none",
                padding: "10px 20px",
                borderRadius: "28px",
                backgroundColor: "var(--color-main)",
                color: "var(--color-white)",
                fontFamily: "var(--font-600)",
                fontSize: "17px",
                width: "100%",
                border: "none",
                cursor: "pointer",
              }}
            >
              Upload Video
            </button>
          </div>
          {/* upload button end */}

          {uploadPercentage > 0 && uploadPercentage < 100 && (
            <div className="progress-wrapper">
              <div className="progress-bar-container">
                <progress
                  value={uploadPercentage}
                  max="100"
                  className="progress"
                />
                <div className="progress-text">{`${uploadPercentage}%`}</div>
              </div>
            </div>
          )}

          {/* modal start */}
          {getpreviewVideoModal2 && (
            <div
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: "0px",
                left: "0px",
                zIndex: 11,
              }}
            >
              <div className="page_main_wrapp">
                <div className="page_base_wrapp" style={{ maxWidth: "450px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px auto",
                      maxWidth: "450px",
                    }}
                  >
                    <div className="nav_back_wrapp">
                      <img
                        src={images.back}
                        className="nav_back_btn"
                        alt=""
                        onClick={() => setPreviewVideoModal2(false)}
                      />
                    </div>
                    <p className="nav_screen_name" style={{ flex: 0.5 }}>
                      Preview
                    </p>
                    <button
                      // className="delete-button"
                      style={{
                        width: "23px",
                        height: "23px",
                      }}
                      onClick={() => opendeleteModal()}
                    >
                      <img
                        style={{
                          width: "96%",
                          height: "96%",
                          objectFit: "contain",
                        }}
                        src={images.delete2}
                        alt=""
                      />
                    </button>
                  </div>
                  <video controls style={{ width: "100%", height: "90%" }}>
                    <source src={getvideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          )}
          {/* modal end */}

          {showModal && (
            // <AlertModal
            //   msg={errorMessage}
            //   setShowModal={setShowModal}
            //   // title="Information"
            // />

            <NewModal
              title=""
              msg={errorMessage}
              setShowModal={openmodal}
              closemodal={closemodal}
            />
          )}
        </div>
      )}
      {showModal1 && (
        // <AlertModal
        //   msg={errorMessage1}
        //   setShowModal={setShowModal1}
        //   // title="Information"
        // />
        <NewModal
          title=""
          msg={errorMessage1}
          setShowModal={openmodal1}
          closemodal={closemodal1}
        />
      )}

      {showModal2 && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     {/* <p className="cm_heading">Information</p> */}
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des mb-1">
        //         Are you sure want to delete this video?
        //       </p>
        //       <div className="mpin_btns_wrapp">
        //         <button className="mpin_yn_btn" onClick={handleVideoDelete}>
        //           Yes
        //         </button>
        //         <button className="mpin_yn_btn" onClick={closedeleteModal}>
        //           No
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        <ReactModal
          isOpen={setShowModal2}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closedeleteModal}
          style={customStyles}
        >
          <div className="NewModal_main_wrapp">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img className="newmodal_logo" src={images.logo} alt="" />
              <p className="newmodal_titel"></p>
            </div>

            <p className="newmodal_des">
              Are you sure want to delete this video?
            </p>

            <div className="modal_two_buton" style={{ width: "100%" }}>
              <button className="close_forgot" onClick={handleVideoDelete}>
                Yes
              </button>
              <button className="close_forgot" onClick={closedeleteModal}>
                No
              </button>
            </div>
          </div>
        </ReactModal>
      )}

      {isModalVisiblevideo && (
        <div
          className="modal_login"
          style={{ zIndex: 111, width: "100%", height: "95vh", bottom: "-5px" }}
        >
          <div
            className="select_nw_modal"
            style={{ height: "100%", background: "#333" }}
          >
            <div className="modal_div1">
              <img
                className="forgot_main"
                src="/static/media/logo.c9b7a18fb586e344723a.png"
                alt=""
              />
            </div>
            {getrecordedvideo ? (
              <div>
                {recordedChunks.length > 0 && (
                  <div>
                    <p>Recorded Video:</p>
                    <video controls width="400">
                      <source
                        src={URL.createObjectURL(recordedChunks[0])}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    marginBottom: "14px",
                    width: "100%",
                    borderRadius: "18px",
                    overflow: "hidden",
                    height: "60vh",
                  }}
                >
                  <Webcam
                    // audio={true}
                    // audio={{
                    //   echoCancellation: true,
                    //   noiseSuppression: true,
                    //   autoGainControl: true,
                    // }}
                    // width={"500"}
                    // height={"400"}
                    width={"100%"}
                    height={"100%"}
                    videoConstraints={{ facingMode: "user" }}
                    ref={videoRef}
                    mirrored={true}
                    // videoConstraints={videoConstraints}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {recording ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          height: "60px",
                          borderRadius: "28px",
                          backgroundColor: "#69696971",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "20px",
                            fontFamily: "var(--font-600)",
                            color: "white",
                            fontSize: "14px",
                          }}
                        >
                          {recordingTime} seconds
                        </p>
                        {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={stopRecording}
                      >
                        Stop Recording
                      </button> */}
                        <img
                          onClick={stopRecording}
                          src={images.stop_circle}
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "60px",
                        borderRadius: "28px",
                        backgroundColor: "#69696971",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          alignSelf: "center",
                        }}
                        onClick={startRecording}
                      >
                        Start Recording
                      </button> */}
                      <img
                        onClick={startRecording}
                        src={images.start_circle}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <button
              onClick={() => {
                showimage();
              }}
            ></button>
            {/* <hr className="hr_forgot" /> */}
            <br />
            <div className="modal_div">
              <button
                className="close_forgot"
                onClick={() => {
                  showimage();
                  setBodyScrollEnabled(true);
                }}
              >
                Cancel
              </button>
              {/* <button
                style={{ marginLeft: 40 }}
                className="close_forgot"
                onClick={() => {
                  console.log("recordedChunks", recordedChunks.length);
                  // handleVideoChange()
                  setIsModalVisiblevideo(false);
                  // setRecordedChunks([]);
                }}
              >
                Submit
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutherVideo;
