import React, { useState, useRef, mpinInputRefs, useEffect } from "react";
import images from "../../constants/images";
import "./mpin.css";
import BackButton from "../../components/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import CusModal from "../../common/cusmodal/CusModal";

const Mpin = () => {
  const [otpDigits, setOtpDigits] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Create an array to hold references to each input element
  const mpinInputRefs = [useRef(), useRef(), useRef(), useRef()];
  const enterMpinRefs = useRef([]); // Refs to store references to the Enter Mpin input fields

  const [enterMpin, setEnterMpin] = useState(["", "", "", ""]);

  const handleMpinChange = (index, value, isEnterMpin) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      if (isEnterMpin) {
        const newEnterMpin = [...enterMpin];
        newEnterMpin[index] = value;
        setEnterMpin(newEnterMpin);
        if (value !== "" && index < enterMpin.length - 1) {
          enterMpinRefs.current[index + 1].focus();
        }
      }
    }
  };

  // Function to handle the input change and move focus
  const handleInputChange = (index, event) => {
    const inputValue = event.target.value;
    const inputLength = inputValue.length;

    // Clear the last input field if the user enters a second value
    if (inputLength === 2) {
      mpinInputRefs[index].current.value = inputValue[0];
    }

    if (inputLength === 1 && index < mpinInputRefs.length - 1) {
      // Move focus to the next input field
      mpinInputRefs[index + 1].current.focus();
    }

    // Prevent auto-back behavior when the input is cleared
    if (inputLength === 0) {
      event.preventDefault();
    }
    // const otpValueEmail = inputValue.join("");
    setOtpDigits(inputValue);
  };

  const location = useLocation();
  const [getotp, setotp] = useState("");
  useEffect(() => {
    console.log("Checking", location.state.otp);
    setotp(location.state.otp);
  }, [location.state.otp]);

  const navigate = useNavigate();

  useEffect(() => {
    if (refsMobile[0].current) {
      refsMobile[0].current.focus();
    }
  }, []);

  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };
  const otpValue = mobileotp.join("");

  const checkmpin = (e) => {
    const otpValueEmail = otpValue;
    const locationOtp = location.state.otp.toString(); // Ensure both are strings
    console.log("otpValueEmail", otpValueEmail, location.state.otp);
    e.preventDefault(); // Prevent the form submission
    if (otpValueEmail === locationOtp) {
      // Assuming navigate is a function to redirect to another page
      navigate("/setmpin");
    } else {
      setErrorMessage("Enter valid otp");
      setShowModal(true);
    }
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <center>
          <b className="mpin-title">Set Mpin</b>
        </center>
        <br />
        <center>
          <p className="otp-head">Enter otp</p>
          <form action="#">
            <div
              style={{
                alignSelf: "center",
                width: "100%",
                alignItems: "center",
                justifyContent:"space-between",
                marginTop:"10%",
              }}
              className="otp_input_container"
            >
              {mobileotp.map((digit, index) => (
                <input
                  style={{
                    borderWidth: 0.5,
                  }}
                  key={index}
                  type="number"
                  maxLength={1}
                  className="in_de_otp"
                  ref={refsMobile[index]}
                  value={digit}
                  onChange={(event) => handleInputChangeMobile(index, event)}
                  onKeyDown={(event) => handleKeyDownMobile(index, event)}
                />
              ))}
            </div>
            <br />
            <div className="opt_details_nm">
              <p className="otp_details_">
                Otp has been sent to your <br /> registered mobile number
              </p>
              <br />
              <button onClick={checkmpin} className="mobile_submit_new_btn">
                Submit
              </button>
            </div>
          </form>
          {showModal && (
            <div className="cusModal_main_wrapp">
              <div className="cusmodal_inner_wrapp">
                <img src={images.logo} alt="" className="cm_logo" />
                <p className="cm_heading"></p>
                <div className="cm_des_wrapp">
                  <p className="cm_des">{errorMessage}</p>
                  <div className="cm_hr"></div>
                 
                  <button
                    className="cm_btn"
                    onClick={() => setShowModal(false)}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          )}
        </center>
      </div>
    </div>
  );
};
export default Mpin;
