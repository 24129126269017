import React, { useState } from "react";
import images from "../../constants/images";
import "./productview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  add_joyride,
  add_release_bond_whishlist,
  delete_whishlist,
  get_single_product_bond_detail,
  get_single_product_bond_detail_3,
  get_user_joyride,
  release_bond_like,
  user_adhar_is_verify,
} from "../../utils/Constant";
import qs from "qs";
import ProductCardViewsecond from "../../components/productcard/ProductCardViewsecond";
import ProductCardview from "../../components/productcard/ProductCardview";
import Productcardthree from "../../components/productcard/Productcardthree";
import AlertModal from "../../components/AlertModal";
import Productcardeight from "../../components/productcard/Productcardeight";
import Productcardseven from "../../components/productcard/Productcardseven";
import Productcardsix from "../../components/productcard/Productcardsix";
import Productcardfive from "../../components/productcard/Productcardfive";
import Productcardfour from "../../components/productcard/Productcardfour";
import { loadStripe } from "@stripe/stripe-js";
import Urls from "../../utils/Urls";
import Productcard13 from "../../components/productcard/Productcard13";
import Productcard14 from "../../components/productcard/Productcard14";
import Productcard15 from "../../components/productcard/Productcard15";
import NewModal from "../../common/newmodal/NewModal";
import ProductNewFrame1 from "../../frames/productframes/ProductNewFrame1";
import ProductNewFrame2 from "../../frames/productframes/ProductNewFrame2";
import ProductNewFrame3 from "../../frames/productframes/ProductNewFrame3";
import ProductNewFrame4 from "../../frames/productframes/ProductNewFrame4";
import ProductNewFrame5 from "../../frames/productframes/ProductNewFrame5";
import ProductNewFrame6 from "../../frames/productframes/ProductNewFrame6";
import ProductNewFrame7 from "../../frames/productframes/ProductNewFrame7";
import ProductNewFrame8 from "../../frames/productframes/ProductNewframe8";
import ProductNewFrame9 from "../../frames/productframes/ProductNewFrame9";
import ProductNewFrame10 from "../../frames/productframes/ProductNewFrame10";
import V2Modal from "../../common/v2modal/V2Modal";
import { FaAngleLeft } from "react-icons/fa";
import CustomJoyride from "../../utils/CustomJoyride";
import NewLoader from "../../common/newloder/NewLoader";

const steps = [
  {
    target: ".product_view_joy_1",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".product_view_joy_2",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  // {
  //   target: ".product_view_joy_3",
  //   content: "it's show platform total assets and total assets value",
  //   // disableBeacon: true,

  //   spotlightPadding: 20,
  // },
  // {
  //   target: ".product_view_joy_4",
  //   content: "it's show platform total assets and total assets value",
  //   // disableBeacon: true,

  //   spotlightPadding: 20,
  // },
];

const dddddd = [
  {
    id: 1,
    total_bond: 10,
  },
  {
    id: 2,
    total_bond: 5,
  },
  {
    id: 3,
    total_bond: 20,
  },
  {
    id: 4,
    total_bond: 30,
  },
];

const ProductView = () => {
  const [showButton, setShowButton] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [run, setRun] = useState(false);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
      console.log("first", parseInt(savedScrollPosition, 10));
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("location.state", location.state);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      const scrollThreshold = viewportHeight / 1; // Half of the viewport height

      if (scrollPosition >= scrollThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const navigate = useNavigate();
  const location = useLocation();

  // video
  const videoRef = useRef(null);
  const userId = localStorage.getItem("user_id");
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");
  const [data, setData] = useState({});
  const [user_product_history, setUser_product_history] = useState("");
  const [accept_bond_bid_id, setaccept_bond_bid_id] = useState("");
  const [release_bond_id, setrelease_bond_id] = useState(
    location.state.release_bond_id
  );
  const [current_highest_bid, setcurrent_highest_bid] = useState("");
  const [uniq_code, setuniq_code] = useState(
    location.state && location.state.uniq_code
  );
  const [is_nfb, setis_nfb] = useState("");
  const [total_available_bond_bid, settotal_available_bond_bid] = useState("");
  const [minimum_bid_amount, setminimum_bid_amount] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [partner_bond_benifit_data, setpartner_bond_benifit_data] =
    useState("");
  const [product_name, setproduct_name] = useState("");
  const [is_club_member, setis_club_member] = useState("");
  const [is_club_id, setis_club_id] = useState("");
  const [is_globle, setis_globle] = useState("");
  const [is_user_owner, setis_user_owner] = useState("");
  const [getproducthashtag, setProductHashtag] = useState([]);
  const [getuserLike, setUserLike] = useState(0);
  const [is_adhar, setIs_adhar] = useState(null);
  const [getaddharModal, setaddharModal] = useState(false);
  const [getwishlist, setwishlist] = useState("");
  const [getuserid, setuserid] = useState("");
  const [getclubid, setclubid] = useState("");
  const [is_club, setIsClub] = useState("");
  const [join_club_product, setjoin_club_product] = useState("");
  const [share_by_user, setshare_by_user] = useState("");
  const [getmediaLinks, setMediaLinks] = useState([]);
  const [getqr_code_data, setqr_code_data] = useState("");
  const [getdata2, setdata2] = useState([]);
  const [getuniqIndex, setUniqIndex] = useState(0);
  const [bond_thumbnail, setbond_thumbnail] = useState("");
  const [proof_type, setproof_type] = useState("");

  const [response, setResponsesession] = useState(null);
  const [gettheam_index, setTheam_index] = useState(location.state.theme_index);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("getproducthashtag", getproducthashtag);
  }, []);

  const [stock, setstock] = useState([]);

  const GetProductDetail = async (uniq_code1) => {
    setIsLoading(true);

    const param = {
      user_id: userId,
      release_bond_id: release_bond_id,
      product_id: location.state.product_id,
      // uniq_code: location.state.uniq_code,
      uniq_code:
        uniq_code1 == "" || uniq_code1 == null || uniq_code1 == undefined
          ? uniq_code
          : uniq_code1,
      is_nfb: location.state.is_nfb,
      share_by_user: location.state.share_by_user,
      club_id: location.state.club_id,
    };

    console.log("param --==-->", param);

    await axios
      .post(get_single_product_bond_detail_3, qs.stringify(param))
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          console.log("res.data--->11", res.data.result[0]);
          setstock(res.data.result);
          // setuniq_code(res.data.result.uniq_code);

          const index_new = res.data.result.findIndex(
            (item) => item.uniq_code == location.state.uniq_code
            // const ff = item
          );

          const data = res.data.result[index_new];
          const productHastags = (data && data.product_hashtag) || "";
          setwishlist(data.is_wishlist);
          setUser_product_history(data.user_product_history);
          setData(data);
          setaccept_bond_bid_id(data.accept_bond_bid_id);
          setrelease_bond_id(data.release_bond_id);
          setcurrent_highest_bid(data.current_highest_bid);
          // const index_new = res.data.result.findIndex(
          //   (item) => item.uniq_code == location.state.uniq_code
          //   // const ff = item
          // );
          console.log("index_new", res.data.result[index_new]);
          console.log("index_new2", index_new);
          // setuniq_code(index_new);
          setuniq_code(res.data.result[index_new].uniq_code);
          setUniqIndex(index_new);
          setis_nfb(data.is_nfb);
          setUserLike(data.user_like);
          settotal_available_bond_bid(data.total_available_bond_bid);
          setminimum_bid_amount(data.minimum_bid_amount);
          setproduct_id(data.product_id);
          // setpartner_bond_benifit_data(data.product_id);
          setpartner_bond_benifit_data(data.partner_bond_benifit_data);
          setproduct_name(data.product_name);
          setis_club_member(data.is_club_member);
          setis_club_id(data.is_club_id);
          setis_globle(data.globle_exclusive);
          setis_user_owner(data.is_user_owner);
          setProductHashtag(productHastags.split(" "));
          setuserid(data.owner_id);
          setclubid(data.is_club_id);
          setIsClub(data.is_club);
          setjoin_club_product(data.join_club_product);
          setshare_by_user(data.share_by_user);
          setMediaLinks(data.social_media_link);
          setqr_code_data(data.qr_code_data);
          setTheam_index(data.theme_index);
          setbond_thumbnail(data.bond_thumbnail);
          setproof_type(data.proof_type);
          // data1 &&
          //   data1.map((data, index) => {
          //     const productHastags = (data && data.product_hashtag) || "";
          //     console.log(
          //       "Asset",
          //       JSON.stringify(data.product_hashtag, null, 2)
          //     );
          //     setwishlist(data.is_wishlist);
          //     setUser_product_history(data.user_product_history);
          //     setData(data);
          //     setaccept_bond_bid_id(data.accept_bond_bid_id);
          //     setrelease_bond_id(data.release_bond_id);
          //     setcurrent_highest_bid(data.current_highest_bid);
          //     setuniq_code(data.uniq_code);
          //     setis_nfb(data.is_nfb);
          //     setUserLike(data.user_like);
          //     settotal_available_bond_bid(data.total_available_bond_bid);
          //     setminimum_bid_amount(data.minimum_bid_amount);
          //     setproduct_id(data.product_id);
          //     // setpartner_bond_benifit_data(data.product_id);
          //     setpartner_bond_benifit_data(data.partner_bond_benifit_data);
          //     setproduct_name(data.product_name);
          //     setis_club_member(data.is_club_member);
          //     setis_club_id(data.is_club_id);
          //     setis_globle(data.globle_exclusive);
          //     setis_user_owner(data.is_user_owner);
          //     setProductHashtag(productHastags.split(" "));
          //     setuserid(data.owner_id);
          //     setclubid(data.is_club_id);
          //     setIsClub(data.is_club);
          //     setjoin_club_product(data.join_club_product);
          //     setshare_by_user(data.share_by_user);
          //     setMediaLinks(data.social_media_link);
          //     setqr_code_data(data.qr_code_data);

          //     // console.log("product view response ->", data.owner_list_data);
          //   });
          // window.location.reload();
        } else {
          console.log("product view err ->", res.data);
          setIsModalVisible(true);
          setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // adhar verify api start

  const isAdharVerified = async () => {
    const param = {
      user_id: userId,
    };

    setIsLoading(true);
    await axios
      .post(user_adhar_is_verify, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          setIs_adhar(1);
        } else {
          setIs_adhar(0);
        }
      })
      .catch((error) => {
        console.log("user_adhar_is_verify errr", error);
        setIs_adhar(0);
        setIsLoading(false);
      });
  };

  // adhar verify api end

  const BondLike = async (release_bond_id, user_id, is_nfb) => {
    const param = {
      release_bond_id: release_bond_id,
      user_id: user_id,
      is_nfb: is_nfb,
    };

    try {
      const response = await axios.post(release_bond_like, qs.stringify(param));
      console.log("response.data", response.data);
      GetProductDetail();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleImageClick = () => {
    setShowVideo(true);
  };

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    videoRef.current.currentTime = 0;
  };

  // video
  // api
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const code = urlParts.slice(-2)[0];

  // images slider end

  // wishlist start

  const AddWishlist = () => {
    setIsLoading(true);
    const param = {
      release_bond_id: release_bond_id,
      product_id: location.state.product_id,
      is_club: data.is_club,
      user_id: userId,
      is_nfb: location.state.is_nfb,
      uniq_code: location.state.uniq_code,
    };
    console.log("addparam--->", param);
    axios
      .post(add_release_bond_whishlist, param)
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log("Respons", JSON.stringify(Response.data, null, 2));
        console.log("====================================");
        if (Response.data.status == 1) {
          GetProductDetail();
        } else {
          console.log("add_release_bond_whishlist err", Response.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const DeleteWishlist = () => {
    setIsLoading(true);
    const param = {
      whishlist_id: data.wishlist_id,
    };
    console.log("removeparam", param);
    axios
      .post(delete_whishlist, param)
      .then((Response) => {
        setIsLoading(false);
        console.log("====================================");
        console.log("Respons", JSON.stringify(Response.data, null, 2));
        console.log("====================================");
        if (Response.data.status == 1) {
          GetProductDetail();
        } else {
          console.log("delete_whishlist err", Response.data);
        }
      })
      .catch((err) => {
        console.error("delete_whishlist errr", err);
        setIsLoading(false);
      });
  };

  // wishlist end

  useEffect(() => {
    GetProductDetail();
    isAdharVerified();
    // console.log("--> uniq_code", location.state.uniq_code);
  }, []);
  // api

  const navigate = useNavigate();

  // copy link functionality start

  const handleCopyLink = (link) => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link copied to clipboard!");
  };
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "rarerez.club",
          text: getqr_code_data,
          url: getqr_code_data,
        });
      } else {
        // Fallback for browsers that do not support Web Share API
        // You can implement a custom sharing solution here
        alert("Web Share API not supported on this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };

  // copy link functionality end

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["product_view_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            setRun(false);
          } else {
            setRun(true);
          }
        } else {
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "product_view_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end

  return (
    <div className="productview_main" style={{ position: "relative" }}>
      {/* <CustomJoyride
        steps={steps}
        run={run}
        showProgress={false}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 100000,
            // primaryColor: "var(--color-main)",
          },

          spotlight: {
            backgroundColor: "transparent",
          },
        }}
      /> */}
      {isLoading ? (
        <NewLoader />
      ) : (
        <div
          className="productview_2"
          style={{ margin: "0px auto", width: "100%" }}
        >
          <div className="productview_header">
            <div className="productview_main_header_notifi">
              <div className="productviewmain1">
                {/* <BackButton /> */}
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>
              </div>
              <div className="productviewmain2"></div>
            </div>
          </div>
          <div className="productview_club_user">
            <div
              onClick={() => {
                if (getclubid == "" || getclubid == 0 || getclubid == null) {
                  // this.props.navigation.navigate('UserProfile', {
                  //   user_id: this.state.owner_id,
                  // });
                  navigate("/UserProfile", {
                    state: {
                      user_id: data.user_id,
                    },
                  });
                } else {
                  navigate("/Clubprofile", {
                    state: {
                      club_id: getclubid,
                    },
                  });
                  // this.props.navigation.navigate('ClubProfile', {
                  //   club_id: this.state.is_club_id,
                  // });
                }
              }}
              className="product_user_nm"
            >
              <img src={data.profile} className="product_user_nm_img" alt="" />
              <p className="product_user_nm_p" style={{ width: "56%" }}>
                {data.username}
              </p>
            </div>

            {/* <div className="card_new_sub_main">
            {location.state.theme_index == 6 ? (
              <ProductCardview
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 7 ? (
              <ProductCardViewsecond
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 8 ? (
              <Productcardthree
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 9 ? (
              <Productcardfour
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 10 ? (
              <Productcardfive
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 11 ? (
              <Productcardsix
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : location.state.theme_index == 12 ? (
              <Productcardseven
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            ) : (
              // <Productcardeight
              //   item={data}
              //   setIsModalOpen={setIsModalVisible}
              //   setMsg={setMsg}
              //   setModalName={setModalName}
              // />
              <Productcardfour
                item={data}
                setIsModalOpen={setIsModalVisible}
                setMsg={setMsg}
                setModalName={setModalName}
              />
            )}
          </div> */}
            <div className="card_new_sub_main">
              {
                // gettheam_index == 6 ? (
                //   <ProductCardview
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 7 ? (
                //   <ProductCardViewsecond
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 8 ? (
                //   <Productcardfour
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 9 ? (
                //   <Productcardfive
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 10 ? (
                //   <ProductNewFrame1
                //     // <Productcardsix
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 11 ? (
                //   <Productcardseven
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 12 ? (
                //   <Productcardeight
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 13 ? (
                //   <Productcard13
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 14 ? (
                //   <Productcard14
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) : gettheam_index == 15 ? (
                //   <Productcard15
                //     item={data}
                //     setIsModalOpen={setIsModalVisible}
                //     setMsg={setMsg}
                //     setModalName={setModalName}
                //   />
                // ) :
                gettheam_index == 16 ? (
                  <ProductNewFrame1
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 17 ? (
                  <ProductNewFrame2
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 18 ? (
                  <ProductNewFrame3
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 19 ? (
                  <ProductNewFrame4
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 20 ? (
                  <ProductNewFrame5
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 21 ? (
                  <ProductNewFrame6
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 22 ? (
                  <ProductNewFrame7
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 23 ? (
                  <ProductNewFrame8
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 24 ? (
                  <ProductNewFrame9
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : gettheam_index == 25 ? (
                  <ProductNewFrame10
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                ) : (
                  <ProductNewFrame1
                    item={data}
                    setIsModalOpen={setIsModalVisible}
                    setMsg={setMsg}
                    setModalName={setModalName}
                    windowWidth={windowWidth}
                  />
                )
              }
            </div>
            {/* <Cardview item={data} /> */}
            {/* <Cardviewsecond start /> */}
            <div className="detsil_product_nm">
              <div className="sub_product1">
                <p className="sub_h_1">{data.product_name}</p>
                <p className="sub_h_p">{data.created_at}</p>
              </div>
              <div className="sub_product2">
                <p className="product-nfb_nm">Asset Value</p>
                <p className="product-nfb_price">₹{data.product_value}</p>
              </div>
            </div>
            {/* <Cardviewsecond end/> */}
            {/* <Cardviewsecond start /> */}
            <div className="pd_share">
              <button
                onClick={() => {
                  // BondLike(data.release_bond_id, data.user_id, data.is_nfb)
                  setIsLoading(true);
                  const param = {
                    release_bond_id: release_bond_id,
                    user_id: userId,
                    is_nfb: is_nfb,
                    product_name: product_name,
                  };
                  console.log("param", param);
                  axios
                    .post("https://www.ravi.host/api/release_bond_like", param)
                    .then((Response) => {
                      setIsLoading(false);
                      //  this._getData();
                      console.log("Re", Response.data);
                      //  this._getsingledata();
                      GetProductDetail();
                      console.log("====================================");
                      console.log("====================================");
                    })
                    .catch((err) => {
                      setIsLoading(false);
                    });
                }}
                style={{
                  color: getuserLike == 1 ? "#e51a4b" : "gray",
                }}
                className="pd_share_1"
              >
                <img
                  src={getuserLike == 1 ? images.like_true : images.like}
                  className="pd_share_img"
                  alt=""
                />
                <p
                  style={{
                    color: getuserLike == 1 ? "#e51a4b" : "gray",
                  }}
                  className="pd_nm"
                >
                  Like - {data.bond_like}
                </p>
              </button>
              <button
                onClick={() => {
                  handleShare();
                }}
                className="pd_share_2"
              >
                <img src={images.sharenfb} className="pd_share_img" alt="" />
                <p className="pd_nm" style={{ color: "gray" }}>
                  Share
                </p>
              </button>
              <div
                className="pd_share_3"
                onClick={() => {
                  if (getwishlist == 0) {
                    AddWishlist();
                  } else {
                    DeleteWishlist();
                  }
                }}
              >
                <img
                  src={
                    getwishlist === 0 ? images.favorite_gray : images.favorite
                  }
                  className="pd_share_img"
                  alt=""
                />
                <p
                  className="pd_nm"
                  style={{
                    color: getwishlist === 0 ? "gray" : "var(--color-main)",
                  }}
                >
                  Wishlist
                </p>
              </div>
              {is_user_owner == 0 ? (
                <>
                  {is_adhar == 1 ? (
                    <div className="pd_share_4 product_view_joy_1">
                      <Link
                        to={"/placebid"}
                        state={{
                          is_globle: is_globle,
                          accept_bond_bid_id: accept_bond_bid_id,
                          release_bond_id: release_bond_id,
                          current_highest_bid: current_highest_bid,
                          uniq_code: uniq_code,
                          is_nfb: is_nfb,
                          currenbt_stock: total_available_bond_bid,
                          minimum_bid_amount: minimum_bid_amount,
                          product_id: product_id,
                          partner_bond_benifit_data:
                            partner_bond_benifit_data.length,
                          product_name: product_name,
                          is_club_member: is_club_member,
                          is_club_id: is_club_id,
                          is_auction: 0,
                          is_place_bid: 0,
                          join_club_product: join_club_product,
                          share_by_user: share_by_user,
                          is_club: is_club,
                          notify_value: data.notify_value,
                        }}
                        style={{ width: "100%", height: "50px" }}
                        className="new_btn_wrapp"
                        // className="bid_d"
                        onClick={() => {
                          console.log("accept_bond_bid_id", accept_bond_bid_id);
                        }}
                      >
                        <p className="bid_nm">Bid</p>
                        <img
                          src={images.giphy}
                          className="bid_d_img"
                          alt=""
                          style={{
                            transform: "rotate(90deg)",
                            left: "10%",
                            position: "absolute",
                          }}
                        />
                      </Link>
                    </div>
                  ) : (
                    <button
                      className="bid_d"
                      onClick={() => {
                        // setIsModalVisible(true);
                        // setMsg("Please complete your KYC");
                      }}
                    >
                      <p className="bid_nm">Bid</p>
                      <img
                        src={images.giphy}
                        className="bid_d_img"
                        alt=""
                        style={{ transform: "rotate(90deg)" }}
                      />
                    </button>
                  )}
                </>
              ) : null}
            </div>
            <>
              <p
                style={{
                  width: "100%",
                  marginTop: "14px",
                  marginBottom: "-10px",
                  fontSize: "14px",
                  color: "var(--color-main)",
                  fontFamily: "var(--font-400)",
                }}
              >
                Available Rarerez
              </p>
              <div class="box_main_con">
                {stock &&
                  stock.map((item, index) => {
                    // console.log("stock_uniq_code", item.release_bond_id);
                    return (
                      <button
                        onClick={async () => {
                          setIsLoading(true);
                          setuniq_code(item.uniq_code);
                          const param = {
                            user_id: userId,
                            release_bond_id: item.release_bond_id,
                            product_id: location.state.product_id,
                            uniq_code: item.uniq_code,
                            is_nfb: location.state.is_nfb,
                            share_by_user: location.state.share_by_user,
                            club_id: location.state.club_id,
                          };

                          console.log("param --==-->", param);

                          await axios
                            .post(
                              get_single_product_bond_detail_3,
                              qs.stringify(param)
                            )
                            .then((res) => {
                              setIsLoading(false);
                              if (res.data.status === 1) {
                                console.log(
                                  "res.data--->11",
                                  res.data.result[index]
                                );

                                const data = res.data.result[index];
                                const productHastags =
                                  (data && data.product_hashtag) || "";
                                setData(data);
                                setwishlist(data.is_wishlist);
                                setUser_product_history(
                                  data.user_product_history
                                );
                                setUniqIndex(index);

                                setaccept_bond_bid_id(data.accept_bond_bid_id);
                                setrelease_bond_id(data.release_bond_id);
                                setcurrent_highest_bid(
                                  data.current_highest_bid
                                );
                                setuniq_code(data.uniq_code);
                                setis_nfb(data.is_nfb);
                                setUserLike(data.user_like);
                                settotal_available_bond_bid(
                                  data.total_available_bond_bid
                                );
                                setminimum_bid_amount(data.minimum_bid_amount);
                                setproduct_id(data.product_id);
                                // setpartner_bond_benifit_data(data.product_id);
                                setpartner_bond_benifit_data(
                                  data.partner_bond_benifit_data
                                );
                                setproduct_name(data.product_name);
                                setis_club_member(data.is_club_member);
                                setis_club_id(data.is_club_id);
                                setis_globle(data.globle_exclusive);
                                setis_user_owner(data.is_user_owner);
                                setProductHashtag(productHastags.split(" "));
                                setuserid(data.owner_id);
                                setclubid(data.is_club_id);
                                setIsClub(data.is_club);
                                setjoin_club_product(data.join_club_product);
                                setshare_by_user(data.share_by_user);
                                setMediaLinks(data.social_media_link);
                                setqr_code_data(data.qr_code_data);
                                setTheam_index(data.theme_index);
                                setbond_thumbnail(data.bond_thumbnail);
                                setproof_type(data.proof_type);
                              } else {
                                console.log("product view err ->", res.data);
                                setIsModalVisible(true);
                                setMsg(res.data.message);
                              }
                            })
                            .catch((err) => {
                              setIsLoading(false);
                            });
                        }}
                        // onClick={async () => {
                        //   setuniq_code(item.uniq_code);
                        //   await GetProductDetail(item.uniq_code);
                        //   window.location.reload();
                        // }}
                        class="box_details"
                        key={index}
                        style={{
                          border:
                            index == getuniqIndex ? "1px solid green" : null,
                          borderRadius: "8px",
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          minWidth: "100px",
                        }}
                      >
                        {/* <div class="box_details"> */}
                        {/* {item.card_index == 1 ? ( */}
                        <>
                          <div class="box_02 product_view_joy_2">
                            <img
                              src={
                                item.product_image_video
                                  ? item.product_image_video[0].img_video
                                  : item.bond_media_upload &&
                                    item.bond_media_upload[0].img_video
                              }
                              class="box_img2"
                              alt
                            />
                          </div>
                          <p class="box_p">stock {item.total_bond}</p>
                        </>

                        {/* </div> */}
                        {/* <div className={"box_02"}>
                     
                      <img
                        src={
                          item.product_image_video
                            ? item.product_image_video[0].img_video
                            : item.bond_media_upload &&
                              item.bond_media_upload[0].img_video
                        }
                        className="box_img2"
                      />
                    </div>
                    <p class="box_p">{`stock ${item.total_bond}`}</p> */}
                      </button>
                    );
                  })}

                {/* <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img2" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div> */}
                {/* <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img3" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div>
            <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img4" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div>
            <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img5" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div> */}
              </div>
            </>
            {/* <Cardviewsecond end/> */}
            <div className="detsil_product_nm2">
              <div className="product_del_part1">
                {data.commitment_date !== "" && (
                  <>
                    <p className="product_del_part_sub1">Next Release Date</p>
                    <h1 className="product_del_part_sub2">
                      {data.commitment_date}
                    </h1>
                  </>
                )}
              </div>
              <div className="product_del_part2">
                <p className="product_del_part_sub1">Released Rarerez</p>
                <h1 className="product_del_part_sub2">
                  {" "}
                  {data.total_available_bond_bid} out of {data.bond_value}
                </h1>
              </div>
            </div>
            <div className="detsil_product_nm2">
              <div className="product_del_part1">
                <p className="product_del_part_sub1">Rarerez Base Value</p>
                <h1 className="product_del_part_sub2">
                  ₹{data.product_base_value}
                </h1>
              </div>
              <div className="product_del_part2">
                <p className="product_del_part_sub1">Minimum bid</p>
                <h1 className="product_del_part_sub2">
                  ₹{data.minimum_bid_amount}
                </h1>
              </div>
            </div>

            {/* trade history start */}
            {/* {data.owner_list_data && data.owner_list_data.length > 0 && ( */}
            <>
              <div className="product_story1">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">
                  Trade <br />
                  History
                </h1>
              </div>
              {data.owner_list_data && data.owner_list_data.length > 0 ? (
                data.owner_list_data.map((item, index) => {
                  // console.log("trade_history_data", data.owner_list_data);
                  return (
                    <div
                      className="trad_hist_siglecard"
                      style={{ border: index == 0 ? "1px solid gold" : "" }}
                      onClick={() => {
                        navigate("/UserProfile", {
                          state: {
                            user_id: item.buyer_id,
                          },
                        });
                      }}
                      key={index}
                    >
                      <div className="th_img_wrapp">
                        <span className="list_number_pv">{index + 1}</span>
                        <img
                          src={item.buyer_profile}
                          className="list_img_of_resell"
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h1 className="th_buyer_name">{item.buyer}</h1>
                        <p
                          style={{
                            fontSize: "11px",
                            fontFamily: "var(--font-300)",
                            textAlign: "center",
                          }}
                        >
                          {item.date_time}
                        </p>
                      </div>

                      <p className="th_price">
                        ₹ {item.get_amount}/-{"  "}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {is_user_owner == 0 ? (
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "var(--font-400)",
                        textAlign: "left",
                      }}
                    >
                      Place your bid now to become the proud first owner!
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "var(--font-400)",
                        textAlign: "left",
                      }}
                    >
                      No trade history available.
                    </p>
                  )}
                </div>
              )}
            </>
            {/* )} */}

            {data.owner_list_data && data.owner_list_data.length > 4 && (
              <Link
                style={{
                  padding: "10px 20px",
                  background: "var(--color-main)",
                  color: "#fff",
                  fontFamily: "var(--font-600)",
                  fontSize: "14px",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                  borderRadius: "28px",
                }}
                onClick={() => {
                  console.log("release_bond_id", release_bond_id);
                  console.log("product_id", location.state.product_id);
                  console.log("uniq_code", location.state.uniq_code);
                  console.log("user_id", userId);
                  console.log("is_nfb", location.state.is_nfb);
                }}
                to={"/Tradehistory"}
                state={{
                  release_bond_id: release_bond_id,
                  product_id: location.state.product_id,
                  uniq_code: location.state.uniq_code,
                  // uniq_code: uniq_code,
                  is_nfb: location.state.is_nfb,
                  userId: userId,
                }}
              >
                View All
              </Link>
            )}
            {/* trade history end */}

            {/* Highlights start */}
            {data.product_highlights == "" ||
            data.product_highlights === null ||
            data.product_highlights === undefined ? null : (
              <>
                <div className="product_story">
                  <h1
                    className="pv_titel"
                    style={{ fontFamily: "var(--font-600)" }}
                  >
                    Highlights
                  </h1>
                  <div className="product_view_hr"></div>
                </div>
                <p className="ser_p_produuct">{data.product_highlights}</p>
              </>
            )}
            {/* Highlights end */}

            {/* phisical asset section start */}

            <div className="product_view_phisical_sec_wrapp">
              <p className="product_view_gradinat_text_heading">
                {data.product_name?.slice(0, 24)}
                {data.product_name?.length > 24 ? "..." : null}
              </p>
              <div className="phisical_asset_box_gradient_wrapp">
                <div className="phisical_asset_box_inner_wrapp">
                  {/* image */}

                  {data.product_image_video &&
                  data.product_image_video.length > 0 ? (
                    <img
                      src={data.product_image_video[0].img_video}
                      alt="product img"
                      onClick={() => {
                        navigate("/ProductViewImg", {
                          state: {
                            img: data.product_image_video
                              ? data.product_image_video
                              : "",
                          },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      src={
                        data.product_image_video
                          ? data.product_image_video
                              .filter((item) => item.image_type == "new")
                              .map((item) => item.img_video)[0] || null
                          : (data.bond_media_upload &&
                              data.bond_media_upload
                                .filter((item) => item.image_type == "new")
                                .map((item) => item.img_video)[0]) ||
                            null
                      }
                      alt="product img"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/ProductViewImg", {
                          state: {
                            img: data.product_image_video
                              ? data.product_image_video
                              : "",
                          },
                        });
                      }}
                    />
                  )}

                  {/* phisical story */}

                  {data.user_product_history == "" ||
                  data.user_product_history === null ||
                  data.user_product_history === undefined ? null : (
                    <div className="phisical_asset_story_wrapp">
                      <p className="phisical_asset_story_heading">
                        Story Statement
                      </p>

                      <p className="p_v_details">{data.user_product_history}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* phisical asset section end */}

            {/* product story start */}
            {/* {data.user_product_history == "" ||
          data.user_product_history === null ||
          data.user_product_history === undefined ? null : (
            <>
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">
                  Story / <br /> Statement
                </h1>
              </div>
              <div className="product_story_del">
                <p className="p_v_details">{data.user_product_history}</p>
              </div>
            </>
          )} */}
            {/* product story end */}

            {/* video start */}
            <>
              <div className="product_story">
                <h1 className="pv_titel">
                  Authentication <br /> Video
                </h1>
                <div className="product_view_hr"></div>
              </div>
              <div className="pv_video_shows">
                {!showVideo ? (
                  <>
                    <img
                      src={
                        bond_thumbnail == "" ? images.thumbnail : bond_thumbnail
                      }
                      className="video_bg_pv"
                      alt="Video Thumbnail"
                    />
                    <img
                      src={images.video}
                      className="video_plays"
                      onClick={handleImageClick}
                      alt=""
                    />
                  </>
                ) : (
                  <video
                    poster={bond_thumbnail}
                    ref={videoRef}
                    className="video_player_pv"
                    autoPlay
                    controls
                    onClick={handleVideoClick}
                    onEnded={handleVideoEnded}
                    src={data.bond_video}
                  >
                    {/* <source
                src={data.bond_video}
                type="video/mp4" className="video_link"
              /> */}
                    {/* Add additional source tags for other video formats if necessary */}
                  </video>
                )}
                {showVideo && (
                  <img
                    src={images.video}
                    className="video_play1_pv"
                    onClick={handleVideoClick}
                    alt=""
                  />
                )}
              </div>
            </>
            {/* video end */}
            {/* cirtificate start */}
            {data.owner_proof ==
              "https://www.ravi.host/public/assets/club_owner_proof/" ||
            data.owner_proof ==
              "https://www.ravi.host/public/assets/owner_proof/" ||
            data.owner_proof == "" ||
            data.owner_proof == null ? null : (
              <>
                <div className="product_story">
                  <div className="product_view_hr"></div>
                  <h1 className="pv_titel">Certificate</h1>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {proof_type === "document" ? (
                    <div>
                      <a
                        href={data.owner_proof}
                        target="_blank"
                        rel="noopener noreferrer"
                        download="document.pdf"
                      >
                        <img
                          src={images.pdf_icon}
                          style={{
                            width: "100%",
                            minWidth: "200px",
                            height: "200px",
                          }}
                          alt=""
                        />
                      </a>
                    </div>
                  ) : (
                    <img
                      src={data.owner_proof}
                      style={{
                        width: "100%",
                        minWidth: "200px",
                        height: "200px",
                      }}
                      alt=""
                      onClick={() => {
                        navigate("/CirtificateViewImg", {
                          state: {
                            img: data.owner_proof ? data.owner_proof : "",
                          },
                        });
                      }}
                    />
                  )}
                </div>
              </>
            )}
            {/* cirtificate end */}

            {/* physical start */}

            {(data.physical_owner_name !== "" ||
              data.physical_asset_address !== "") && (
              <div style={{ width: "100%" }}>
                <div className="product_story">
                  <h1 className="pv_titel">Physical Details</h1>
                  <div className="product_view_hr"></div>
                </div>
                {/* owner name */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    margin: "10px auto",
                  }}
                >
                  {data.physical_owner_name &&
                  data.physical_owner_name !== "" ? (
                    <div className="phisical_asset_owner_name_wrapp">
                      <div>
                        <img src={images.new_profile_icon} alt="" />
                      </div>
                      <p>{data.physical_owner_name}</p>
                    </div>
                  ) : null}

                  {/* location */}
                  {data.physical_asset_address &&
                  data.physical_asset_address !== "" ? (
                    <div className="phisical_asset_owner_name_wrapp">
                      <div>
                        <img src={images.new_location_icon} alt="" />
                      </div>
                      <p>{data.physical_asset_address}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            {/* physical start */}

            {/* milestone start */}
            {data.user_milestone_id !== "" &&
              data.user_milestone_id !== null &&
              data.user_milestone_id !== undefined && (
                <>
                  <div className="product_story">
                    <div className="product_view_hr"></div>
                    <h1 className="pv_titel">Milestone</h1>
                  </div>

                  <Link
                    className="productview_milestone_wrapp"
                    to="/MilestoneDashboard"
                    state={{
                      user_id: data.user_id,
                      user_milestone_id: data.user_milestone_id,
                    }}
                  >
                    <img
                      src={
                        data.mile_image_link &&
                        data.mile_image_link[0]?.imageLink
                      }
                      alt=""
                    />
                    <div>
                      <div>
                        <p>{data.milestone_name}</p>
                      </div>
                    </div>
                  </Link>
                </>
              )}
            {/* milestone end */}

            {/* benefits start */}
            {/* {data.partner_bond_benifit_data?.length !== 0 ||
            (data.product_bond_benifit_detail?.length !== 0 && ( */}
            <>
              {data.partner_bond_benifit_data?.length > 0 ||
                (data.product_bond_benifit_detail?.length > 0 && (
                  <div className="product_story">
                    <h1 className="pv_titel">Benefits</h1>
                    <div className="product_view_hr"></div>
                  </div>
                ))}
              {data.partner_bond_benifit_data &&
                data.partner_bond_benifit_data.length > 0 && (
                  <>
                    {/* <br /> */}
                    <p
                      className="set_p_product"
                      style={{
                        fontFamily: "var(--font-600)",
                        fontSize: "18px",
                      }}
                    >
                      Partner Benefits
                    </p>
                    <div className="partner_b_set">
                      <img
                        src={data.partner_bond_benifit_data[0].benifit_icon}
                        className="partner_b_set_img"
                        alt=""
                      />
                      <div className="partner_b_set_details">
                        <p className="nm_pr_set1">
                          {data.partner_bond_benifit_data[0].benifit_name}
                        </p>
                        <p className="nm_pr_set2">
                          {data.partner_bond_benifit_data[0].partner_name}
                        </p>
                      </div>
                      <Link
                        className="partner_b_set_view"
                        to={"/ViewBenefits"}
                        state={{
                          partner: 1,
                          is_nfb: data.is_nfb,
                          partner_benifit_id:
                            data.partner_bond_benifit_data[0]
                              .partner_benifit_id,
                        }}
                      >
                        View
                      </Link>
                    </div>{" "}
                  </>
                )}
              {data.partner_bond_benifit_data &&
                data.partner_bond_benifit_data.length > 0 && <br />}
              {data.product_bond_benifit_detail &&
                data.product_bond_benifit_detail.length > 0 && (
                  <>
                    <p
                      className="set_p_product"
                      style={{
                        fontFamily: "var(--font-600)",
                        fontSize: "18px",
                        width: "100%",
                        marginTop: "-26px",
                      }}
                    >
                      User Benefits
                    </p>
                    {data.product_bond_benifit_detail.map((item, index) => {
                      return (
                        <div className="user_benefit_new">
                          <div className="user_benefit_new_sub1">
                            <div className="user_benefit_img">
                              <img
                                src={item.reward_img_video[0]?.img_video}
                                className="user_benefit_img_sub"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="user_benefit_new_sub2">
                            <b> {item.banifit_name}</b>
                            <b> {item.reward_name}</b>
                            <div className="benefit_hr_set"></div>
                            <Link
                              className="view_of_pr_benefit"
                              to={"/ViewBenefits"}
                              state={{
                                partner: 0,
                                is_nfb: data.is_nfb,
                                pro_bo_ben_id: item.pro_bo_ben_id,
                              }}
                            >
                              {" "}
                              View
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
            </>
            {/* ))} */}

            {/* benefits end */}
            {/* media start */}
            {data.bond_media_upload && data.bond_media_upload.length > 0 && (
              <>
                <div className="product_story">
                  <div className="product_view_hr"></div>
                  <h1 className="pv_titel">Media</h1>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {data.bond_media_upload.map((item, index) => {
                    return (
                      <img
                        src={item.img_video}
                        style={{ width: "100px", height: "100px" }}
                        alt=""
                        onClick={() => {
                          navigate("/ProductViewImg3", {
                            state: {
                              img: data.bond_media_upload
                                ? data.bond_media_upload
                                : "",
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {/* media end */}

            {/* media links start */}
            {getmediaLinks && getmediaLinks.length > 0 ? (
              <>
                <div className="product_story">
                  <h1 className="pv_titel">Media Link</h1>
                  <div className="product_view_hr"></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  {getmediaLinks && getmediaLinks.length > 0
                    ? getmediaLinks.map((item, index) => {
                        console.log("item", item);
                        return (
                          <div key={index} className="ml_wrapp">
                            <a
                              href={item.media_link}
                              target="_blank"
                              className="ml_link_text"
                            >
                              <p>{item.media_link}</p>
                            </a>
                            <button
                              className="ml_copy_btn"
                              onClick={() => handleCopyLink(item.media_link)}
                            >
                              Copy
                            </button>
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            ) : null}
            {/* media links end */}

            {/* <div className="hasttagnew_pro" > */}

            {/* Hashtags start */}
            {data.product_hashtag && data.product_hashtag !== null
              ? getproducthashtag.length > 0 && (
                  <>
                    <div className="product_story">
                      <div className="product_view_hr"></div>
                      <h1 className="pv_titel">Hashtags</h1>
                    </div>
                    <div className="hashtags_list">
                      {getproducthashtag.map((item, index) => {
                        return <p key={index}>{item}</p>;
                      })}
                    </div>
                  </>
                )
              : null}
            {/* </div> */}
            {/* Hashtags end */}

            <div className="product_story">
              <h1 className="pv_titel">Creator</h1>
              <div className="product_view_hr"></div>
            </div>
          </div>
          <div
            onClick={() => {
              if (
                data.owner_id !== "" ||
                data.owner_id !== 0 ||
                data.owner_id !== null
              ) {
                // this.props.navigation.navigate('UserProfile', {
                //   user_id: this.state.owner_id,
                // });
                navigate("/UserProfile", {
                  state: {
                    user_id: data.owner_id,
                  },
                });
              } else {
              }
            }}
            className="creator_details"
          >
            <div
              className="creator_details_details"
              style={{ width: "90%", margin: "10px auto" }}
            >
              <p style={{ color: "#000" }}>
                {data.owner_bio == "null" || data.owner_bio == null
                  ? ""
                  : data.owner_bio}
              </p>
            </div>
            <img
              src={data.owner_profile}
              className="creator_details_img"
              alt=""
            />
            <div className="creator_details_sub">
              <h1 className="creator_details_sub_h1">{data.owner_name}</h1>
              <p className="creator_details_sub_p">Asset Creator</p>
            </div>
            {/* <div className="instgarm">
            <div>
              <img src={images.insta} className="instagram_img_ins" alt="" />
            </div>
            <img src={images.media1} className="instagram_img" alt="" />
            <img src={images.media2} className="instagram_img" alt="" />
            <img src={images.media3} className="instagram_img" alt="" />
            <img src={images.media4} className="instagram_img" alt="" />
            <img src={images.media5} className="instagram_img" alt="" />
            <img src={images.media6} className="instagram_img" alt="" />
          </div> */}
          </div>
          <div className="productview_club_user">
            <button
              onClick={() => {
                navigate("/Report", {
                  state: {
                    release_bond_id: release_bond_id,
                  },
                });
              }}
              className="need_pv_help"
            >
              <div className="need_pv_help_1">
                <div className="need_pvpart1">
                  <img
                    src={images.need_help}
                    className="need_pv_helpimg"
                    alt=""
                  />
                </div>
                <div className="need_pvpart2">
                  <h1>Need help?</h1>
                </div>
                <div className="need_pvpart3">
                  <img src={images.next} className="need_pv_helpimg2" alt="" />
                </div>
              </div>
            </button>
          </div>
          <br />
          <br />
          <br />{" "}
          {is_user_owner == 0 ? (
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                width: "100%",
                padding: "10px",
                zIndex: 111,
                maxWidth: "var(--max-width-sm)",
              }}
            >
              {/* <div className="pd_share_4" style={{ width: "100%" }}>
            <Link
              to={"/placebid"}
              state={{
                is_globle: is_globle,
                accept_bond_bid_id: accept_bond_bid_id,
                release_bond_id: release_bond_id,
                current_highest_bid: current_highest_bid,
                uniq_code: uniq_code,
                is_nfb: is_nfb,
                currenbt_stock: total_available_bond_bid,
                minimum_bid_amount: minimum_bid_amount,
                product_id: product_id,
                partner_bond_benifit_data: partner_bond_benifit_data.length,
                product_name: product_name,
                is_club_member: is_club_member,
                is_club_id: is_club_id,
                is_auction: 0,
                is_place_bid: 0,
                join_club_product: join_club_product,
                share_by_user: share_by_user,
                is_club: is_club,
              }}
              className="bid_d2"
            >
              <p className="bid_nm">Bid Now</p>
              <img src={images.next} className="bid_d_img" alt="" />
            </Link>
          </div> */}

              {/* <div className="pd_share_4" style={{ width: "100%" }}>
              <button
                onClick={() => {
                  // is_adhar == 1
                  // ?
                  navigate("/placebid", {
                    state: {
                      is_globle: is_globle,
                      accept_bond_bid_id: accept_bond_bid_id,
                      release_bond_id: release_bond_id,
                      current_highest_bid: current_highest_bid,
                      uniq_code: uniq_code,
                      is_nfb: is_nfb,
                      currenbt_stock: total_available_bond_bid,
                      minimum_bid_amount: minimum_bid_amount,
                      product_id: product_id,
                      partner_bond_benifit_data:
                        partner_bond_benifit_data.length,
                      product_name: product_name,
                      is_club_member: is_club_member,
                      is_club_id: is_club_id,
                      is_auction: 0,
                      is_place_bid: 0,
                      join_club_product: join_club_product,
                      share_by_user: share_by_user,
                      is_club: is_club,
                      notify_value: data.notify_value,
                    },
                  });
                  // : null
                  //  setIsModalVisible(true);
                  // setMsg("Please complete your KYC");
                }}
                className="new_btn_wrapp"
                // className="bid_d2"
                style={{ backgroundColor: "black" }}
              >
                <p className="bid_nm">Bid</p>
                <img
                  src={images.giphy}
                  className="bid_d_img"
                  alt=""
                  style={{ transform: "rotate(90deg)", right: "30%" }}
                />
              </button>
            </div> */}
              <div style={{ width: "100%" }}>
                {showButton && (
                  <div className="pd_share_4" style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        navigate("/placebid", {
                          state: {
                            is_globle: is_globle,
                            accept_bond_bid_id: accept_bond_bid_id,
                            release_bond_id: release_bond_id,
                            current_highest_bid: current_highest_bid,
                            uniq_code: uniq_code,
                            is_nfb: is_nfb,
                            currenbt_stock: total_available_bond_bid,
                            minimum_bid_amount: minimum_bid_amount,
                            product_id: product_id,
                            partner_bond_benifit_data:
                              partner_bond_benifit_data.length,
                            product_name: product_name,
                            is_club_member: is_club_member,
                            is_club_id: is_club_id,
                            is_auction: 0,
                            is_place_bid: 0,
                            join_club_product: join_club_product,
                            share_by_user: share_by_user,
                            is_club: is_club,
                            notify_value: data.notify_value,
                          },
                        });
                      }}
                      className="new_btn_wrapp"
                      style={{ backgroundColor: "black" }}
                    >
                      <p className="bid_nm">Bid</p>
                      <img
                        src={images.giphy}
                        className="bid_d_img"
                        alt=""
                        style={{ transform: "rotate(90deg)", right: "30%" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      )}

      {isModalVisible && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={msg}
            setShowModal={setIsModalVisible}
          /> */}
          {/* <NewModal
            title=""
            msg={msg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={msg}
            setShowModal={setIsModalVisible}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default ProductView;
