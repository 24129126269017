import React from "react";
import "./NewMobileOtpSuccess.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const NewAddhharSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("location", location.state);
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="mobile_success_main_wrapp">
          <p className="success_heading">Success!!!</p>
          <img src={images.new_success_img} className="success_img" alt="" />
          <p className="success_des_text">
            Congratulation! Your Aadhar <br />
            Card is Verified!
          </p>

          {/* btn */}
          <button
            style={{ marginTop: "40px" }}
            onClick={() => {
              navigate("/NewShowUserDetail", {
                state: {
                  user_id: location.state.user_id,
                  all_data: location.state.all_data,
                },
              });
            }}
            className="new_btn_wrapp mx-1"
          >
            <p>Next</p>
          </button>
          {/* btn */}
        </div>
      </div>
    </div>
  );
};

export default NewAddhharSuccess;
