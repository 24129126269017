import React, { useState } from "react";
import "./NewInterNationalUserDetails.css";
import { FaAngleLeft } from "react-icons/fa6";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  getPincodes,
  get_all_country,
  getcitybystate,
  getstatebycountry,
  inert_user_add_identity_v2,
} from "../../utils/Constant";
import { useEffect } from "react";
import NewTandC from "../../components/newtandc/NewTandC";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewLoader from "../../common/newloder/NewLoader";

const NewInterNationalUserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [gettermAccept, setTermAccept] = useState(false);
  const [gettermModel, setTermModel] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);

  //   contry states start
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [states, setStates] = useState([]);
  const [statesName, setStatesName] = useState("");
  const [stateId, setStateId] = useState(null);
  const [city, setCity] = useState([]);
  const [cityName, setCityName] = useState("");
  const [cityId, setCityId] = useState(null);
  const [getpincode, setPincode] = useState([]);
  const [getpincodeName, setPincodeName] = useState("");

  const [getaddress1, setAddress1] = useState("");
  const [getaddress2, setAddress2] = useState("");

  const [opencontryModal, setOpencontryModal] = useState(false);
  const [inputText, setInputText] = useState("");

  const [openstateModal, setOpenstateModal] = useState(false);
  const [stateInputText, setStateInputText] = useState("");

  const [opencityModal, setOpencityModal] = useState(false);
  const [openpincodeModal, setOpenpincodeModal] = useState(false);
  const [cityInputText, setCityInputText] = useState("");
  // contry states end

  // Function to handle input text change
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  // Function to handle input text change
  const handleStateInputChange = (e) => {
    setStateInputText(e.target.value);
  };

  // Function to handle input text change
  const handlecitysInputChange = (e) => {
    setCityInputText(e.target.value);
  };

  // Function to handle picode text change
  const handlePincodeInputChange = (e) => {
    setPincodeName(e.target.value);
  };

  // Filter city options based on the input text
  const filteredContrys = country.filter((data) =>
    data.Name.toLowerCase().includes(inputText.toLowerCase())
  );

  // Filter city options based on the input text
  const filteredStates = states.filter((data) =>
    data.Name.toLowerCase().includes(stateInputText.toLowerCase())
  );

  // Filter city options based on the input text
  const filteredcity = city.filter((data) =>
    data.Name.toLowerCase().includes(cityInputText.toLowerCase())
  );

  // Filter pincode options based on the input text

  const filteredPincode = getpincode.filter((pin) =>
    pin.includes(getpincodeName.toLowerCase())
  );

  // contry apis start

  useEffect(() => {
    Countryget();
  }, []);

  const Countryget = () => {
    axios.get(get_all_country).then((Response) => {
      if (Response.data.status === 1) {
        setCountry(Response.data.data);
        console.log("get_all_country Response", Response.data);
      } else {
        console.log("get_all_country err", Response.data);
      }
      console.log("param", Response.data.data);
      console.log("====================================");
    });
  };

  const StatesGet = (Id) => {
    const params = {
      country_id: Id,
    };
    axios
      .post(getstatebycountry, qs.stringify(params))
      .then((Response) => {
        if (Response.data.status === 1) {
          setStates(Response.data.data);
        }
        console.log("getstatebycountry Response", Response.data.data);
      })
      .catch((err) => {
        console.log("getstatebycountry err", err);
      });
  };

  const CitysGet = (cId, sId) => {
    const params = {
      country_id: cId,
      state_id: sId,
    };
    axios
      .post(getcitybystate, qs.stringify(params))
      .then((Response) => {
        if (Response.data.status === 1) {
          setCity(Response.data.data);
        }
        console.log("getcitybystate Response", Response.data.data);
      })
      .catch((err) => {
        console.log("getcitybystate err", err);
      });
  };

  const PincodeGet = (city) => {
    const params = {
      city: city,
    };
    axios
      .post(getPincodes, qs.stringify(params))
      .then((Response) => {
        console.log("ascsac", Response.data);
        // setPincodeName(Response.data.pincodes);
        if (Response.data.pincodes !== "") {
          setPincode(Response.data.pincodes);
        }
        console.log("getPincodes_Response", Response.data);
      })
      .catch((err) => {
        console.log("getPincodes err", err);
      });
  };

  // contry apis end

  // Function to handle city selection
  const handleContrySelect = (contry) => {
    setCountryId(contry.Id);
    StatesGet(contry.Id);
    setCountryName(contry.Name);
    setCountryCode(contry.phonecode);
    setOpencontryModal(false);
    console.log("contry", contry);

    // setStateId(null);
    setStatesName("");

    // setCityId(null);
    setCityName("");

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle state selection
  const handleStateSelect = (state) => {
    setStateId(state.Id);
    setStatesName(state.Name);
    CitysGet(countryId, state.Id);
    setOpenstateModal(false);

    setCityId(null);
    setCityName("");

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle city selection
  const handleCitysSelect = (city) => {
    setCityId(city.Id);
    setCityName(city.Name);
    PincodeGet(city.Name);

    setOpencityModal(false);
    setOpenpincodeModal(false);

    setPincode([]);
    setPincodeName("");
  };

  // Function to handle pincode selection
  const handlePincodeSelect = (city) => {
    setPincodeName(city);

    setOpenpincodeModal(false);
  };

  const handleTermChange = () => {
    console.log("asd");
    setTermModel(true);
  };

  // term accept function start

  const handleTermAccept = () => {
    console.log("asd");
    setTermModel(false);
    setTermAccept(true);
  };

  // set password api start
  const SetDetails = async () => {
    if (countryName === "") {
      toast.error("Please select country", { theme: "colored" });
    } else if (statesName === "") {
      toast.error("Please select state", { theme: "colored" });
    } else if (cityName === "") {
      toast.error("Please select city", { theme: "colored" });
    } else if (getaddress1 === "") {
      toast.error("Please enter address line 1", { theme: "colored" });
    } else if (getaddress2 === "") {
      toast.error("Please enter address line 2", {
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      const param = {
        user_id: location.state.user_id,
        country: countryName,
        state: statesName,
        city: cityName,
        pincode: getpincodeName,
        address_line_1: getaddress1,
        address_line_2: getaddress2,
      };
      console.log("user_otp param", param);

      await axios
        .post(inert_user_add_identity_v2, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status === 1) {
            toast.success(`${Response.data.message}`, { theme: "colored" });
            navigate("/NewFounderVideoScreen", {
              state: {
                user_id: Response.data.user_id,
              },
            });
          } else {
            console.log("user_otp err", Response.data);
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };
  // set password api end

  return (
    <div className="new_wrapp_container reletive_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div className="back_arrow_wrapp_bank">
            <button>
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          </div>
          <div className="new_intanationa_invite_form_main_wrapp">
            <div className="new_intanationa_invite_form_inner_wrapp">
              <div
                className="otp_screen_text_container_bank"
                style={{ margin: "1rem 0px", padding: "0px" }}
              >
                <h4 style={{ margin: "0px" }}>Confirm your Identity</h4>
                <p>
                  Let's personalize your experience! Please provide your
                  location details below.{" "}
                </p>
              </div>

              {/* select contry start */}

              <div
                className="new_set_password_single_input_wrapp"
                style={{ position: "relative", margin: "1rem auto" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p>Select your country</p>
                </div>
                <div
                  className="new_set_password_single_border_wrapp"
                  onClick={() => {
                    setOpencontryModal(!opencontryModal);
                    setOpenstateModal(false);
                    setOpencityModal(false);
                    setOpenpincodeModal(false);
                  }}
                >
                  <input
                    type="text"
                    // placeholder="Type here"
                    value={countryName}
                    readOnly
                    placeholder="Select Country"
                  />
                  <img
                    style={{
                      transform: opencontryModal
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    src={images.new_down_logo}
                    alt=""
                  />
                </div>

                {opencontryModal && (
                  <div className="contry_select_modal">
                    <input
                      type="text"
                      className="city_nm_select"
                      placeholder={"Search Country"}
                      onChange={(e) => handleInputChange(e)}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <div
                      className="contry_modal_option_wrapp"
                      style={{
                        zIndex: 111,
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      {filteredContrys.map((contry) => {
                        return (
                          <>
                            <button
                              key={contry}
                              // className="city_option_nm_set"
                              style={{
                                color: "white",
                                fontSize: "12px",
                                fontFamily: "var(--font-300)",
                              }}
                              onClick={() => {
                                handleContrySelect(contry);
                                // console.log("first", contry);
                                // setCountryName(contry.Name);
                              }}
                            >
                              {contry.Name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {/* select contry end */}

              {/* select states start */}

              <div
                className="new_set_password_single_input_wrapp"
                style={{ position: "relative", margin: "1rem auto" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p>Select your state</p>
                </div>
                <div
                  className="new_set_password_single_border_wrapp"
                  onClick={() => {
                    setOpenstateModal(!openstateModal);
                    setOpencontryModal(false);
                    setOpencityModal(false);
                    setOpenpincodeModal(false);
                  }}
                >
                  <input
                    type="text"
                    // placeholder="Type here"
                    value={statesName}
                    readOnly
                    placeholder="Select Country"
                  />
                  <img
                    style={{
                      transform: openstateModal
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    src={images.new_down_logo}
                    alt=""
                  />
                </div>

                {openstateModal && (
                  <div className="contry_select_modal">
                    <input
                      type="text"
                      className="city_nm_select"
                      placeholder={"Search state"}
                      onChange={(e) => handleStateInputChange(e)}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <div
                      className="contry_modal_option_wrapp"
                      style={{
                        zIndex: 111,
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      {filteredStates.map((state) => {
                        return (
                          <>
                            <button
                              key={state.Id}
                              // className="city_option_nm_set"
                              style={{
                                color: "white",
                                fontSize: "12px",
                                fontFamily: "var(--font-300)",
                              }}
                              onClick={() => handleStateSelect(state)}
                            >
                              {state.Name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              {/* select states end */}

              {/* select city start */}

              <div
                className="new_set_password_single_input_wrapp"
                style={{ position: "relative", margin: "1rem auto" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p>Select your state</p>
                </div>
                <div
                  className="new_set_password_single_border_wrapp"
                  onClick={() => {
                    setOpencityModal(!opencityModal);
                    setOpenstateModal(false);
                    setOpencontryModal(false);
                    setOpenpincodeModal(false);
                  }}
                >
                  <input
                    type="text"
                    // placeholder="Type here"
                    value={cityName}
                    readOnly
                    placeholder={cityName == "" ? "Select City" : cityName}
                  />
                  <img
                    style={{
                      transform: opencityModal
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    src={images.new_down_logo}
                    alt=""
                  />
                </div>

                {opencityModal && (
                  <div className="contry_select_modal">
                    <input
                      type="text"
                      className="city_nm_select"
                      placeholder={"Search City"}
                      onChange={(e) => handlecitysInputChange(e)}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <div
                      className="contry_modal_option_wrapp"
                      style={{
                        zIndex: 111,
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      {filteredcity.map((city) => {
                        return (
                          <>
                            <button
                              key={city.Id}
                              // className="city_option_nm_set"
                              style={{
                                color: "white",
                                fontSize: "12px",
                                fontFamily: "var(--font-300)",
                              }}
                              onClick={() => handleCitysSelect(city)}
                            >
                              {city.Name}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              {/* select city end */}

              {/* select zipcode start */}
              <div
                className="new_set_password_single_input_wrapp"
                style={{ position: "relative", margin: "1rem auto" }}
              >
                <div className="new_set_password_single_labal_wrapp">
                  <p>Select your zipcode</p>
                </div>
                <div
                  className="new_set_password_single_border_wrapp"
                  onClick={() => {
                    setOpenpincodeModal(!openpincodeModal);
                    setOpencityModal(false);
                    setOpenstateModal(false);
                    setOpencontryModal(false);
                  }}
                >
                  <input
                    type="text"
                    // placeholder="Type here"
                    value={getpincodeName}
                    readOnly
                    placeholder={
                      getpincodeName == "" ? "Select zipcode" : getpincodeName
                    }
                  />
                  <img
                    style={{
                      transform: openpincodeModal
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    src={images.new_down_logo}
                    alt=""
                  />
                </div>

                {openpincodeModal && (
                  <div className="contry_select_modal">
                    <input
                      type="text"
                      className="city_nm_select"
                      placeholder={"Search City"}
                      onChange={(e) => handlePincodeInputChange(e)}
                      // onBlur={() => setOpencontryModal(false)}
                    />
                    <div
                      className="contry_modal_option_wrapp"
                      style={{
                        zIndex: 111,
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      {getpincode.map((pin) => {
                        return (
                          <>
                            <button
                              key={pin}
                              // className="city_option_nm_set"
                              style={{
                                color: "white",
                                fontSize: "12px",
                                fontFamily: "var(--font-300)",
                              }}
                              onClick={() => handlePincodeSelect(pin)}
                            >
                              {pin}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {/* select zip end */}

              {/* address line 1 start */}
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_location_logo} alt="" />
                  <p>Address Line 1</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Type here"
                    style={{ width: "100%" }}
                    value={getaddress1}
                    onChange={(e) => {
                      setAddress1(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* address line 1 end */}

              {/* address line 2 start */}
              <div className="new_set_password_single_input_wrapp mt-1">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_location_logo} alt="" />
                  <p>Address Line 2</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="text"
                    placeholder="Type here"
                    style={{ width: "100%" }}
                    value={getaddress2}
                    onChange={(e) => {
                      setAddress2(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* address line 2 end */}
            </div>

            <div className="new_aadhaar_btn_wrapp" style={{ padding: "0px" }}>
              <div>
                {gettermAccept ? (
                  <img
                    src={images.new_accepted_terms}
                    onClick={() => setTermAccept(false)}
                    alt=""
                  />
                ) : (
                  <img
                    src={images.new_notaccepted_terms}
                    alt=""
                    onClick={handleTermChange}
                  />
                )}
                <p>I agree to the terms & conditions</p>
              </div>
              {/* btn */}
              <button
                onClick={SetDetails}
                disabled={!gettermAccept}
                className="new_btn_wrapp mx-1"
              >
                <p>Next</p>
              </button>
              {/* btn */}
            </div>

            {/* btn */}
          </div>
        </div>
      )}

      {gettermModel ? (
        <>
          <NewTandC
            title="Terms & Condition"
            msg={htmlContent}
            setIsOpen={setTermModel}
            accept={handleTermAccept}
          />
        </>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default NewInterNationalUserDetails;
