import React, { useState } from "react";
import "./CWLeaderBord.css";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import images from "../../constants/images";

const List = [
  {
    id: 1,
    name: "user name 1",
    place: "1st",
  },
  {
    id: 2,
    name: "user name 2",
    place: "2nd",
  },
  {
    id: 3,
    name: "user name 3",
    place: "3rd",
  },
  {
    id: 4,
    name: "user name 4",
    place: "4th",
  },
];

const CWLeaderBord = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(List);

  return (
    <div className="new_wrapp_container reletive_position">
      <div className="new_container">
        <div className="play_screen_main_wrapp" style={{ padding: "10px 5%" }}>
          <div className="legacy_dashbord_nav_sec">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p style={{ fontsize: "18px", width: "60%", textAlign: "center" }}>
              Leaderboard
            </p>
            <div></div>
          </div>
        </div>

        {/* main section start */}

        <div className="cw_leaderbord_main_wrapp">
          {/* header section start */}
          <div className="cw_leaderbord_head_wrapp">
            <div className="cw_leaderbord_star_wrapp1">
              <img src={images.pink_stars} alt="" />
            </div>
            <div className="cw_leaderbord_price_wrapp">
              <img src={images.leaderbord_boxes} alt="" />
              <div className="cw_leaderbord_price_ineer">
                <p>Price</p>
                <h4>₹5.00L</h4>
              </div>
            </div>
            <div className="cw_leaderbord_star_wrapp2">
              <img src={images.pink_stars2} alt="" />
            </div>
          </div>
          {/* header section end */}

          {/* my section start */}

          <div className="cw_leaderbord_my_card">
            <div>
              <img src={images.hr_img} alt="" />
              <div className="cw_leaderbord_my_card_name_wrapp">
                <p>You</p>
                <h5>User Name</h5>
              </div>
            </div>
            <p>8th</p>
          </div>

          {/* my section end */}

          {/* all list start */}
          {data && data.length > 0
            ? data.map((item, index) => {
                return (
                  <div key={item.id} className="cw_leaderbord_simple_card">
                    <div>
                      <img src={images.hr_img} alt="" />
                      <p>{item.name}</p>
                    </div>
                    <p>{item.place}</p>
                  </div>
                );
              })
            : null}

          {/* all list end */}

          {/*  */}
        </div>

        {/* main section end */}
      </div>
    </div>
  );
};

export default CWLeaderBord;
