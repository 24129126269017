import React from "react";
import "./SelectMerchent.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { get_ecommerce_product } from "../../utils/Constant";
import { useEffect } from "react";

const SelectMerchent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [loading, setloading] = useState(false);
  const [getdata, setdata] = useState([]);
  const [getecomerse_id, setecomerse_id] = useState("");

  useEffect(() => {
    allMerchets();
  }, []);

  const allMerchets = async () => {
    setloading(true);
    const params = {
      //    user_id: userId,
      product_id: location.state.product_id,
      is_nfb: location.state.is_nfb,
      release_bond_id: location.state.release_bond_id,
      is_club: location.state.is_club,
    };
    axios
      .post(get_ecommerce_product, qs.stringify(params))
      .then((res) => {
        setloading(false);

        console.log(
          "GetData of place order data_-------------+_->",
          JSON.stringify(res.data, null, 2)
        );

        if (res.data.status == 1) {
          console.log("datas--->", res.data);
          setdata(res.data.result);
          setecomerse_id(res.data.result[0].ecommerce_product_id);
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <div className="my_order_main_wrapp">
      <div className="my_order_base_wrapp">
        <div className="sm_nav_wrap">
          <img
            src={images.back_black}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <p>Select Merchandise</p>
          <img
            src={images.shopping_cart}
            alt=""
            onClick={() => {
              // navigate(-1);
              navigate("/OrderConfirmation", {
                state: {
                  ecommerce_product_id: getecomerse_id,
                },
              });
            }}
          />
        </div>

        <div className="sm_products_wrapp">
          {getdata && getdata.length > 0
            ? getdata.map((item, index) => {
                return (
                  <Link
                    to="/BuyProduct"
                    state={{ ecommerce_product_id: item.ecommerce_product_id }}
                    className="sm_single_product"
                  >
                    <img src={item.image} alt="" />
                  </Link>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default SelectMerchent;
