import React, { useEffect, useState, useRef } from "react";
import "./NewFooter.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

const NewFooter = ({ getlocation }) => {
  const userId = localStorage.getItem("user_id");
  const [isFooterExpanded, setFooterExpanded] = useState(
    JSON.parse(localStorage.getItem("isFooterExpanded")) || false
  );
  const footerRef = useRef(null);

  useEffect(() => {
    console.log("Location:", getlocation);
    // Save state to local storage whenever it changes
    localStorage.setItem("isFooterExpanded", JSON.stringify(isFooterExpanded));
  }, [isFooterExpanded]);

  const toggleFooter = (e) => {
    e.stopPropagation(); // Prevent this click from affecting parent handlers
    setFooterExpanded(!isFooterExpanded);
  };

  return (
    <div
      ref={footerRef}
      className={`footer13_main_wrapp ${isFooterExpanded ? "expanded" : ""}`}
      style={{ zIndex: 111 }}
    >
      <div
        className={`footer13_base_wrapp ${isFooterExpanded ? "expanded" : ""}`}
      >
        {!isFooterExpanded && (
          <button onClick={toggleFooter} className="footer-toggle-button">
            <img
              src={images.footerlogo2_r}
              alt="Toggle Footer"
              className="footer-toggle-icon"
            />
          </button>
        )}

        {isFooterExpanded && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="footer-links"
              style={{ position: "relative", marginLeft: "10px" }}
            >
              <button>
                <Link to={"/homescreen2"}>
                  <img
                    src={
                      getlocation === "/homescreen2"
                        ? images.footerlogo1_r
                        : images.footerlogo1_w
                    }
                    alt=""
                    className={
                      getlocation === "/homescreen2"
                        ? "footer13_btn_main1_h"
                        : "footer13_btn_main1"
                    }
                  />
                </Link>
              </button>
              <button>
                <Link to={"/feed"} className="home_joy_5">
                  <img
                    src={
                      getlocation === "/feed"
                        ? images.footerlogo2_r
                        : images.footerlogo2_w
                    }
                    alt=""
                    className={
                      getlocation === "/feed"
                        ? "footer13_btn_main1_h"
                        : "footer13_btn_main1"
                    }
                  />
                </Link>
              </button>
              <button>
                <Link
                  to={"/showcase"}
                  state={{ user_id: userId }}
                  className="home_joy_6"
                >
                  <img
                    src={
                      getlocation === "/showcase"
                        ? images.footerlogo3_r
                        : images.footerlogo3_w
                    }
                    alt=""
                    className={
                      getlocation === "/showcase"
                        ? "footer13_btn_main1_h"
                        : "footer13_btn_main1"
                    }
                  />
                </Link>
              </button>
              <button>
                <Link to={"/bids"} className="home_joy_7">
                  <img
                    src={
                      getlocation === "/bids"
                        ? images.footerlogo4_r
                        : images.footerlogo4_w
                    }
                    alt=""
                    className={
                      getlocation === "/bids"
                        ? "footer13_btn_main1_h"
                        : "footer13_btn_main1"
                    }
                  />
                </Link>
              </button>
              <button>
                <Link to={"/specialBenefits"} className="home_joy_8">
                  <img
                    src={
                      getlocation === "/specialBenefits"
                        ? images.footerlogo5_r
                        : images.footerlogo5_w
                    }
                    alt=""
                    className={
                      getlocation === "/specialBenefits"
                        ? "footer13_btn_main1_h"
                        : "footer13_btn_main1"
                    }
                  />
                </Link>
              </button>
              <button
                onClick={toggleFooter}
                // className="footer_close_icon"
                style={{ position: "absolute", right: "-13%" }}
              >
                <img
                  src={images.dobble_back}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    objectFit: "contain",
                  }}
                />
                {/* <IoChevronBackOutline size={16} color="white" /> */}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewFooter;
