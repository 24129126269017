import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../common/NavbarSec";
import images from "../constants/images";
import axios from "axios";
import { create_bid, update_bid } from "../utils/Constant";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";

const SuccessUpdatebid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const userId = localStorage.getItem("user_id");

  const getDataFromSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key)) || {};
  };

  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };

  const storedItems = getDataFromSessionStorage("myItems");
  const locatioData = getDataFromSessionStorage("location_data");
  const allRes = JSON.parse(localStorage.getItem("allres"));
  const totals = JSON.parse(localStorage.getItem("updatetotal"));

  useEffect(() => {
    console.log("store data", storedItems);
    console.log("store data", totals);
    // console.log("location data", locatioData[0]);

    if (
      storedItems !== null ||
      storedItems !== undefined ||
      storedItems.length > 0
    ) {
      bidplace();
    }
  }, []);

  const bidplace = () => {
    setloading(true);
    const param = {
      user_id: userId,
      bid_id: locatioData[0]?.bid_id,
      bid_amount: locatioData[0]?.amount,
      total: totals,
    };
    console.log("assacacacasavsedav", param);
    axios
      .post(update_bid, param)
      .then((Response) => {
        setloading(false);
        if (Response.data.status == 1) {
          console.log(Response.data);
          sessionStorage.clear();
        } else {
          console.log(Response.data.message);
        }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <div className="page_main_wrapp" style={{}}>
      <div
        className="page_base_wrapp"
        style={{ overflowY: "hidden", margin: "0px auto" }}
      >
        {/* success modal start */}
        <div
          className="actionneed_2main"
          style={{
            minHeight: "95vh",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              background: "linear-gradient(180deg, #ff004c, #caacffc2",
              display: "flex",
              alignItems: "center",
              padding: "2px",
              minHeight: "200px",
              borderRadius: "18px",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "var(--color-black)",
                height: "100%",
                borderRadius: "18px",
                padding: "10px",
                // margin: "10px auto",
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-start",
              }}
            >
              <IoIosCheckmarkCircle color="#fff" size="30" />

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-700)",
                  fontSize: "24px",
                  marginTop: "12px",
                  lineHeight: "28px",
                }}
              >
                Payment of ₹{allRes.ammount} is successful!
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-400)",
                  fontSize: "12px",
                  marginTop: "4px",
                }}
              >
                {/* 18 Des,2023 | 03:46 PM */}
                {moment(allRes.date_time).format("MMM D, YYYY")} |{" "}
                {moment(allRes.date_time).format("h:mm a")}
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-300)",
                  fontSize: "12px",
                  marginTop: "12px",
                }}
              >
                {allRes.message}
              </p>

              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-600)",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Transction number
              </p>
              <p
                style={{
                  color: "var(--color-white)",
                  fontFamily: "var(--font-300)",
                  fontSize: "12px",
                  // marginTop: "8px",
                }}
              >
                {allRes.tra_id}
              </p>
            </div>
          </div>
          {/* <Link
            onClick={() => {
              localStorage.removeItem("allres");
            }}
            to={"/homescreen2"}
            className="actionneed_2btn"
          >
            Home
          </Link> */}
          <button
            onClick={() => {
              localStorage.removeItem("allres");
              navigate("/homescreen2");
            }}
            className="new_btn_wrapp mt-3"
          >
            <p>Home</p>
          </button>
        </div>
        {/* success modal end */}
      </div>
    </div>
  );
};

export default SuccessUpdatebid;
