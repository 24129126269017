import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import images from "../constants/images";
import { useHomeContext } from "../context/home_context";
import { useEffect } from "react";
import axios from "axios";
import NewLoader from "../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const TopCreators = () => {
  const { getDashboardlatest5, is_loading, home_latest5_data } =
    useHomeContext();
  const [getsearch, setSearch] = useState(false);
  const [getsearchData, setSearchData] = useState([]);
  const [getroyaltyFirst, setRoyaltyFirst] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [isLoading, setIsLoading] = useState(is_loading);

  const Latest5Api = async () => {
    const params = {
      user_id: JSON.parse(userId),
    };
    console.log("login param", params);

    const data = await getDashboardlatest5(params);
    if (data) {
      // setAllIntrest(data.user_interest);
      setRoyaltyFirst(data.result.user_array);
      setSearchData(data.result.user_array);
    }
    console.log("user_interest", getroyaltyFirst);
  };

  const AllCreator = async () => {
    setIsLoading(true);
    await axios
      .get("https://www.ravi.host/api/get_creator_list")
      .then((Response) => {
        setIsLoading(false);
        console.log(
          "Respon============---------~~~~~~~~~~~```>",
          Response.data
        );
        if (Response.data.status == 1) {
          setRoyaltyFirst(Response.data.result);
          setSearchData(Response.data.result);
        } else {
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Latest5Api();
    AllCreator();
    console.log("getroyaltyFirst", getroyaltyFirst);
  }, []);

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.username
        ? item.username.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setRoyaltyFirst(newData);
    console.log("newData", newData);
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="page_base_wrapp_2">
          {/* navbar start */}
          <div className="rnfb_nav_wrapp">
            {/* <img
              src={images.back}
              onClick={() => navigate(-1)}
              alt=""
              className="rnfb_nav_back_btn"
            /> */}
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </div>
            <p className="rnfb_nav_screen_name">Top Creators</p>
            <FiSearch
              color="var(--color-red)"
              size={24}
              // className="rnfb_nav_search_btn"
              style={{ cursor: "pointer" }}
              onClick={() => setSearch(!getsearch)}
            />
          </div>
          {/* navbar end */}

          {/* search start */}
          {getsearch && (
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>
          )}
          {/* search end */}

          <div className="rnfb_products_wrapp">
            {getroyaltyFirst && getroyaltyFirst.length == 0 ? (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "60px",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                }}
              >
                No item found.
              </p>
            ) : null}
            {getroyaltyFirst && getroyaltyFirst.length > 0
              ? getroyaltyFirst.map((item, index) => {
                  return (
                    <>
                      <Link
                        to={"/UserProfile"}
                        state={{
                          user_id: item.user_id,
                        }}
                        className="home_sec_slide_card"
                        key={item.id}
                      >
                        <img src={item.profile} alt="" />
                        <div className="home_sec_card_blur_view">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <p
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                fontFamily: "var(--font-800)",
                                marginLeft: "2%",
                              }}
                            >
                              {item.username}
                              {/* User Name */}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopCreators;
