import React from "react";

// import { useNavigate } from 'react-router-dom';
import images from "../../constants/images";
// import Modal from "react-modal";
import "./launchednfb.css";
import BackButton from "../../components/BackButton";

const Launchednfb = () => {
  return (
    <div className="launche_nfb">
      <div className="launche_nfb_header">
        <div className="launche_nfb_header1">
          <BackButton />
        </div>
        <div className="launche_nfb_header2">
          <h1 className="nfb_h1">Launched Rarerezs</h1>
        </div>
      </div>
      <div className="nfb_details">
        <img src={images.bidsimg} className="nfb_img" alt="" />
        <div className="nfb_details_sub">
          <h1>Test</h1>
          <p>Applied on -2023-07-04 13:00:22</p>
          <br />
          <button className="launche_btn">Action Needed</button>
        </div>
      </div>
    </div>
  );
};

export default Launchednfb;
