import React from "react";
import images from "../constants/images";

const AlertModal = ({ title, msg, setShowModal }) => {
  return (
    <>
      <div className="modal_login" style={{ zIndex: 111 }}>
        <div className="modal-content_login" style={{ maxHeight: "95vh" }}>
          <div className="modal_div1">
            <img className="forgot_main" src={images.logo} alt="" />
          </div>
          <div className="alert_modal_div">
            <b>{title ? title : ""}</b>
            <br />
            <p
              className="alert-modal-message"
              style={{
                height: "80%",
                // border: "1px solid red",
                overflowY: "scroll",
              }}
            >
              {" "}
              {msg ? msg : ""}
            </p>
            <br />
          </div>
          <hr className="hr_forgot" />
          <br />
          <div className="modal_div">
            <button
              className="close_forgot"
              onClick={() => setShowModal(false)}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
