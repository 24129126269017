import React, { useState } from "react";
import images from "../../constants/images";
import "./productview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  add_release_bond_whishlist,
  delete_whishlist,
  get_mileston_bond_detail,
  get_single_product_bond_detail,
  get_single_product_bond_detail_3,
  product_history,
  release_bond_like,
  user_adhar_is_verify,
} from "../../utils/Constant";
import qs from "qs";
import V2Modal from "../../common/v2modal/V2Modal";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";

const MilestoneProductView = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  // video
  const videoRef = useRef(null);
  const userId = localStorage.getItem("user_id");
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");
  const [getmodalName, setModalName] = useState("");
  const [data, setData] = useState({});
  const [user_product_history, setUser_product_history] = useState("");
  const [accept_bond_bid_id, setaccept_bond_bid_id] = useState("");

  const [current_highest_bid, setcurrent_highest_bid] = useState("");

  const [is_nfb, setis_nfb] = useState("");
  const [total_available_bond_bid, settotal_available_bond_bid] = useState("");
  const [minimum_bid_amount, setminimum_bid_amount] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [partner_bond_benifit_data, setpartner_bond_benifit_data] =
    useState("");
  const [product_name, setproduct_name] = useState("");
  const [is_club_member, setis_club_member] = useState("");
  const [is_club_id, setis_club_id] = useState("");
  const [is_globle, setis_globle] = useState("");
  const [is_user_owner, setis_user_owner] = useState("");
  const [getproducthashtag, setProductHashtag] = useState([]);
  const [getuserid, setuserid] = useState("");
  const [getclubid, setclubid] = useState("");
  const [is_club, setIsClub] = useState("");
  const [join_club_product, setjoin_club_product] = useState("");
  const [share_by_user, setshare_by_user] = useState("");
  const [getmediaLinks, setMediaLinks] = useState([]);
  const [getstatuscode, setstatuscode] = useState("");

  const [stock, setstock] = useState([]);
  const [getuniqIndex, setUniqIndex] = useState(0);
  const [getqr_code_data, setqr_code_data] = useState("");
  const [proof_type, setproof_type] = useState("");
  const [getproductPrice, setProductPrice] = useState("");

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("getproducthashtag", getproducthashtag);
  }, []);

  const GetProductDetail = async (uniq_code1) => {
    setIsLoading(true);

    const param = {
      user_id: location.state.user_id,
      bond_id: location.state.bond_id,
      user_milestone_id: location.state.user_milestone_id,
    };

    console.log("param --==-->", param);

    await axios
      .post(get_mileston_bond_detail, qs.stringify(param))
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          console.log("res.data--->11", res.data.result[0]);
          // setuniq_code(res.data.result.uniq_code);
          const data = res.data.result[0];
          const productHastags = (data && data.product_hashtag) || "";
          setUser_product_history(data.user_product_history);
          setData(data);
          setaccept_bond_bid_id(data.accept_bond_bid_id);
          setcurrent_highest_bid(data.current_highest_bid);
          setis_nfb(data.is_nfb);
          settotal_available_bond_bid(data.total_available_bond_bid);
          setminimum_bid_amount(data.minimum_bid_amount);
          setproduct_id(data.product_id);
          // setpartner_bond_benifit_data(data.product_id);
          setpartner_bond_benifit_data(data.partner_bond_benifit_data);
          setproduct_name(data.product_name);
          setis_club_member(data.is_club_member);
          setis_club_id(data.is_club_id);
          setis_globle(data.globle_exclusive);
          setis_user_owner(data.is_user_owner);
          setProductHashtag(productHastags.split(" "));
          setuserid(data.owner_id);
          setclubid(data.is_club_id);
          setIsClub(data.is_club);
          setjoin_club_product(data.join_club_product);
          setshare_by_user(data.share_by_user);
          setMediaLinks(data.social_media_link);
          setstock(data.user_release_bond);
          setproof_type(data.proof_type);
          setStatus(data.is_status);
        } else {
          console.log("product view err ->", res.data);
          setIsModalVisible(true);
          setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleImageClick = () => {
    setShowVideo(true);
  };

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    videoRef.current.currentTime = 0;
  };

  const handleCopyLink = (link) => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Link copied to clipboard!");
  };

  // video
  // api
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const code = urlParts.slice(-2)[0];

  useEffect(() => {
    GetProductDetail();
    // console.log("--> uniq_code", location.state.uniq_code);
  }, []);
  // api

  const navigate = useNavigate();

  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => setSlideIndex(currentSlide),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          margin: "10px 5px",
          borderRadius: "50%",
          backgroundColor: slideIndex === i ? "#da0e41" : "white",
          opacity: slideIndex === i ? 1 : 0.6,
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [status, setStatus] = useState(null);

  const Milestoneonof = (newStatus) => {
    console.log(newStatus);
    setIsLoading(true);

    const param = {
      user_id: location.state.user_id,
      bond_id: location.state.bond_id,
      user_milestone_id: location.state.user_milestone_id,
      is_status: newStatus,
    };

    console.log(param);

    axios
      .post(
        "https://www.ravi.host/api/user_hide_unhide_user_ml_bond",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log(
          "Responseclubsettings----->",
          JSON.stringify(Response.data, null, 2)
        );
        if (Response.data.status == 1) {
          GetProductDetail();
        } else {
          GetProductDetail();
          toast.error(Response.data.message, {
            theme: "colored",
          });
          // setIsModalVisible(true);
          // setCusmsg(Response.data.message);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle any other errors that occurred during the request
        console.error(error);
      });
  };
  const [is_step, setis_step] = useState(0);

  useEffect(() => {
    History();
  }, []);

  // history api start
  const History = () => {
    const param = {
      product_id: location.state.bond_id,
      user_id: userId,
    };
    console.log("parammpin", param);
    axios
      .post(product_history, param)
      .then((Response) => {
        console.log("acdascs", Response.data.result);
        const data = Response.data.result;
        // setUserName(data.user_name);
        if (data.status == 2) {
          setProductPrice(Response.data.result.product_price);

          // if (data.extra_message != null) {
          //   setoffer_price(data.offer_price);
          //   setis_step(data.is_step);
          //   setfull_msg(data.message);
          //   setextra_msg(data.extra_message);
          // } else {

          setis_step(data.is_step);
          // setcommitDate(data.commitment_date);

          // alert(Response.data.message);
          // }
        } else if (data.status == 3) {
          setstatuscode(3);
          // setcommitDate(data.commitment_date);
        }
        setIsLoading(false);
        // if (Response.data.status == 1) {
        //   setis_step(data.is_step);
        //   setoffer_price(data.offer_price)
        // } else {
        //   setErrorMessage(Response.data.message);
        //   setShowModal(true);
        // }
        // this.setState({
        //   // data: Response.data.result,
        //   task_arrayholder: Response.data.result,
        // });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  // history api end

  return (
    <div className="productview_main" style={{ position: "relative" }}>
      <div
        className="productview_2"
        style={{ margin: "0px auto", width: "100%" }}
      >
        <div className="productview_header">
          <div className="productview_main_header_notifi">
            <div className="productviewmain1">
              <BackButton />
            </div>
            <div className="productviewmain2"></div>
          </div>
        </div>
        {location.state.user_id == userId ? (
          <div
            style={{
              width: "95%",
              paddingLeft: "20px",
              marginBottom: "0px",
              marginTop: "10px",
            }}
            className="details_ofset_1"
          >
            <p className="set_club_sub_nm">Asset active/inactive</p>
            <input
              className="set_chek"
              type="checkbox"
              id="checkbox2"
              checked={status == 1}
              onChange={() => {
                Milestoneonof(status == 1 ? 0 : 1);
              }}
            />
            <label
              // style={{ border: status == 1 ? "red" : "gray" }}
              className={status == 1 ? "setlabel" : "setlabel1"}
              for="checkbox2"
            ></label>
          </div>
        ) : null}
        <div className="productview_club_user">
          <div
            onClick={() => {
              if (getclubid == "" || getclubid == 0 || getclubid == null) {
                navigate("/UserProfile", {
                  state: {
                    user_id: getuserid,
                  },
                });
              } else {
                navigate("/Clubprofile", {
                  state: {
                    club_id: getclubid,
                  },
                });
              }
            }}
            className="product_user_nm"
          >
            <img src={data.profile} className="product_user_nm_img" alt="" />
            <p className="product_user_nm_p">{data.owner_name}</p>
          </div>

          <div
            className="pv_imgs_wrapp"
            style={{ width: "100%", margin: "10px auto" }}
          >
            <Slider {...settings}>
              {data && data.bond_img_video
                ? data.bond_img_video.map((item, index) => {
                    console.log("item", item);
                    return (
                      <img
                        onClick={() => {
                          navigate("/ActionViewImg", {
                            state: {
                              img: data.bond_img_video
                                ? data.bond_img_video
                                : "",
                            },
                          });
                        }}
                        src={item.img_video}
                        alt=""
                        className="pv_img_single_img"
                      />
                    );
                  })
                : null}
            </Slider>
          </div>
          {/* <Cardview item={data} /> */}
          {/* <Cardviewsecond start /> */}
          <div className="detsil_product_nm">
            <div className="sub_product1">
              <p className="sub_h_1">{data.product_name}</p>
              <p className="sub_h_p">{data.created_at}</p>
            </div>
            <div className="sub_product2">
              <p className="product-nfb_nm">Asset Value</p>
              <p className="product-nfb_price">₹{data.product_value}</p>
            </div>
          </div>
          {/* <Cardviewsecond end/> */}

          {/* {getstatuscode == 3 ? null : (
            <>
              {is_step == 0 ? (
                <>
                  <Link
                    to={"/bondissue"}
                    state={{
                      product_id: location.state.bond_id,
                      remaining_bond: location.state.remaining_bond,
                      product_price: getproductPrice,
                      is_globle: location.state.is_globle,
                      is_owner: location.state.is_owner,
                      commitment_date_new: location.state.commitment_date,
                    }}
                    className="new_btn_wrapp mt-3"
                  >
                    <p>Create Rarerez</p>
                  </Link>
                </>
              ) : is_step == 1 ? (
                <Link
                  to={"/Collaboratenfb"}
                  state={{
                    product_id: location.state.product_id,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  <p> Create Rarerez</p>
                </Link>
              ) : is_step == 5 ? (
                <Link
                  to={"/bondissue"}
                  state={{
                    product_id: location.state.product_id,
                    remaining_bond: location.state.remaining_bond,
                    product_price: getproductPrice,
                    is_globle: location.state.is_globle,
                    is_owner: location.state.is_owner,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  <p>Create Rarerez</p>
                </Link>
              ) : is_step == 6 ? (
                <Link
                  to={"/Collaboratenfb"}
                  state={{
                    product_id: location.state.product_id,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  <p>Create Rarerez</p>
                </Link>
              ) : is_step == 7 ? (
                <Link
                  to={"/Createnfbs"}
                  state={{
                    product_id: location.state.product_id,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  <p>Create Rarerez</p>
                </Link>
              ) : (
                <Link
                  to={"/Createnfbs"}
                  state={{
                    product_id: location.state.product_id,
                    commitment_date_new: location.state.commitment_date,
                  }}
                  className="new_btn_wrapp mt-3"
                >
                  <p>Create Rarerez</p>
                </Link>
              )}
            </>
          )} */}

          {stock && stock.length > 0 && (
            <>
              <p
                style={{
                  width: "100%",
                  marginTop: "14px",
                  marginBottom: "-10px",
                  fontSize: "14px",
                  color: "var(--color-main)",
                  fontFamily: "var(--font-400)",
                }}
              >
                Available Rarerez
              </p>
              <div class="box_main_con">
                {stock.map((item, index) => {
                  console.log("stock_uniq_code", item.release_bond_id);
                  return (
                    <button
                      key={item.product_id}
                      onClick={() => {
                        navigate("/productview", {
                          state: {
                            release_bond_id: item.release_bond_id,
                            product_id: item.product_id,
                            is_nfb: item.is_nfb,
                            uniq_code: item.uniq_code,
                            theme_index: item.theme_index,
                            share_by_user: item.share_by_user,
                            club_id: item.club_id,
                          },
                        });
                      }}
                      class="box_details"
                      style={{
                        border:
                          index == getuniqIndex ? "1px solid green" : null,
                        borderRadius: "8px",
                        padding: "8px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        minWidth: "100px",
                      }}
                    >
                      {/* <div class="box_details"> */}
                      {/* {item.card_index == 1 ? ( */}
                      <>
                        <div class="box_02">
                          <img
                            src={
                              item.product_image_video
                                ? item.product_image_video[0].img_video
                                : item.bond_img_video &&
                                  item.bond_img_video[0].img_video
                            }
                            class="box_img2"
                            alt
                          />
                        </div>
                        <p class="box_p">stock {item.total_bond}</p>
                      </>

                      {/* </div> */}
                      {/* <div className={"box_02"}>
                     
                      <img
                        src={
                          item.product_image_video
                            ? item.product_image_video[0].img_video
                            : item.bond_media_upload &&
                              item.bond_media_upload[0].img_video
                        }
                        className="box_img2"
                      />
                    </div>
                    <p class="box_p">{`stock ${item.total_bond}`}</p> */}
                    </button>
                  );
                })}

                {/* <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img2" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div> */}
                {/* <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img3" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div>
            <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img4" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div>
            <div class="box_details">
              <div class="box_02">
                <img src={images.vk_img} class="box_img5" alt />
              </div>
              <p class="box_p">stock 5</p>
            </div> */}
              </div>
            </>
          )}

          {/* product story start */}
          {/* {data.product_history == "" ||
          data.product_history === null ||
          data.product_history === undefined ? null : (
            <>
              <div className="product_story">
                <h1 className="pv_titel">
                  Asset <br /> Story
                </h1>
                <div className="product_view_hr"></div>
              </div>
              <div className="product_story_del">
                <p className="p_v_details">{data.product_history}</p>
              </div>
            </>
          )} */}
          {/* product story end */}

          {/* Highlights start */}
          {data.product_highlights == "" ||
          data.product_highlights === null ||
          data.product_highlights === undefined ? null : (
            <>
              <div className="product_story">
                <div className="product_view_hr"></div>

                <h1
                  className="pv_titel"
                  style={{ fontFamily: "var(--font-600)" }}
                >
                  Highlights
                </h1>
              </div>
              <p className="ser_p_produuct">{data.product_highlights}</p>
            </>
          )}
          {/* Highlights end */}

          {/* phisical asset section start */}

          <div className="product_view_phisical_sec_wrapp">
            <p className="product_view_gradinat_text_heading">
              {data.product_name?.slice(0, 24)}
              {data.product_name?.length > 24 ? "..." : null}
            </p>
            <div className="phisical_asset_box_gradient_wrapp">
              <div className="phisical_asset_box_inner_wrapp">
                {/* image */}
                {data && data.bond_img_video
                  ? data.bond_img_video.slice(0, 1).map((item, index) => {
                      console.log("item", item);
                      return (
                        <img
                          onClick={() => {
                            navigate("/ActionViewImg", {
                              state: {
                                img: data.bond_img_video
                                  ? data.bond_img_video
                                  : "",
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                          src={item.img_video}
                          alt=""
                          // className="pv_img_single_img"
                        />
                      );
                    })
                  : null}

                {/* phisical story */}

                {data.product_history == "" ||
                data.product_history === null ||
                data.product_history === undefined ? null : (
                  <div className="phisical_asset_story_wrapp">
                    <p className="phisical_asset_story_heading">
                      Story Statement
                    </p>

                    <p className="p_v_details">{data.product_history}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* phisical asset section end */}

          {/* video start */}
          <>
            <div className="product_story">
              <h1 className="pv_titel">
                Authentication <br /> Video
              </h1>
              <div className="product_view_hr"></div>
            </div>
            <div className="pv_video_shows">
              {!showVideo ? (
                <>
                  <img
                    // src={images.thumbnail}
                    src={data.bond_thumbnail}
                    className="video_bg_pv"
                    alt="Video Thumbnail"
                  />
                  <img
                    src={images.video}
                    className="video_plays"
                    onClick={handleImageClick}
                    alt=""
                  />
                </>
              ) : (
                <video
                  ref={videoRef}
                  className="video_player_pv"
                  autoPlay
                  controls
                  onClick={handleVideoClick}
                  onEnded={handleVideoEnded}
                  src={data.bond_video}
                  poster={data.bond_thumbnail}
                >
                  {/* <source
                src={data.bond_video}
                type="video/mp4" className="video_link"
              /> */}
                  {/* Add additional source tags for other video formats if necessary */}
                </video>
              )}
              {showVideo && (
                <img
                  src={images.video}
                  className="video_play1_pv"
                  onClick={handleVideoClick}
                  alt=""
                />
              )}
            </div>
          </>
          {/* video end */}
          {/* cirtificate start */}
          {data.owner_proof ==
            "https://www.ravi.host/public/assets/club_owner_proof/" ||
          data.owner_proof ==
            "https://www.ravi.host/public/assets/owner_proof/" ||
          data.owner_proof == "" ||
          data.owner_proof == null ? null : (
            <>
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">Certificate</h1>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {proof_type === "document" ? (
                  <div>
                    <a
                      href={data.owner_proof}
                      target="_blank"
                      rel="noopener noreferrer"
                      download="document.pdf"
                    >
                      <img
                        src={images.pdf_icon}
                        style={{
                          width: "100%",
                          minWidth: "200px",
                          height: "200px",
                        }}
                        alt=""
                      />
                    </a>
                  </div>
                ) : (
                  <img
                    src={data.owner_proof}
                    style={{
                      width: "100%",
                      minWidth: "200px",
                      height: "200px",
                    }}
                    alt=""
                    onClick={() => {
                      navigate("/CirtificateViewImg", {
                        state: {
                          img: data.owner_proof ? data.owner_proof : "",
                        },
                      });
                    }}
                  />
                )}
              </div>
            </>
          )}
          {/* cirtificate end */}

          {/* physical start */}

          {(data.physical_owner_name !== "" ||
            data.physical_asset_address !== "") && (
            <div style={{ width: "100%" }}>
              <div className="product_story">
                <h1 className="pv_titel">Physical Details</h1>
                <div className="product_view_hr"></div>
              </div>
              {/* owner name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  margin: "10px auto",
                }}
              >
                {data.physical_owner_name && data.physical_owner_name !== "" ? (
                  <div className="phisical_asset_owner_name_wrapp">
                    <div>
                      <img src={images.new_profile_icon} alt="" />
                    </div>
                    <p>{data.physical_owner_name}</p>
                  </div>
                ) : null}

                {/* location */}
                {data.physical_asset_address &&
                data.physical_asset_address !== "" ? (
                  <div className="phisical_asset_owner_name_wrapp">
                    <div>
                      <img src={images.new_location_icon} alt="" />
                    </div>
                    <p>{data.physical_asset_address}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {/* physical end */}

          {/* milestone start */}

          <>
            <div className="product_story">
              <div className="product_view_hr"></div>
              <h1 className="pv_titel">Milestone</h1>
            </div>

            <Link
              className="productview_milestone_wrapp"
              to="/MilestoneDashboard"
              state={{
                user_id: data.user_id,
                user_milestone_id: data.user_milestone_id,
              }}
            >
              <img
                src={data.mile_image_link && data.mile_image_link[0]?.imageLink}
                alt=""
              />
              <div>
                <div>
                  <p>{data.milestone_name}</p>
                </div>
              </div>
            </Link>
          </>

          {/* milestone end */}

          {/* benefits start */}
          {/* {data.partner_bond_benifit_data?.length !== 0 ||
            (data.product_bond_benifit_detail?.length !== 0 && ( */}
          <>
            {data.partner_bond_benifit_data?.length > 0 ||
              (data.product_bond_benifit_detail?.length > 0 && (
                <div className="product_story">
                  <h1 className="pv_titel">Benefits</h1>
                  <div className="product_view_hr"></div>
                </div>
              ))}
            {data.partner_bond_benifit_data &&
              data.partner_bond_benifit_data.length > 0 && (
                <>
                  {/* <br /> */}
                  <p
                    className="set_p_product"
                    style={{ fontFamily: "var(--font-600)", fontSize: "18px" }}
                  >
                    Partner Benefits
                  </p>
                  <div className="partner_b_set">
                    <img
                      src={data.partner_bond_benifit_data[0].benifit_icon}
                      className="partner_b_set_img"
                      alt=""
                    />
                    <div className="partner_b_set_details">
                      <p className="nm_pr_set1">
                        {data.partner_bond_benifit_data[0].benifit_name}
                      </p>
                      <p className="nm_pr_set2">
                        {data.partner_bond_benifit_data[0].partner_name}
                      </p>
                    </div>
                    <Link
                      className="partner_b_set_view"
                      to={"/ViewBenefits"}
                      state={{
                        partner: 1,
                        is_nfb: data.is_nfb,
                        partner_benifit_id:
                          data.partner_bond_benifit_data[0].partner_benifit_id,
                      }}
                    >
                      View
                    </Link>
                  </div>{" "}
                </>
              )}
            <br />
            {data.product_bond_benifit_detail &&
              data.product_bond_benifit_detail.length > 0 && (
                <>
                  <p
                    className="set_p_product"
                    style={{
                      fontFamily: "var(--font-600)",
                      fontSize: "18px",
                      width: "100%",
                      marginTop: "-26px",
                    }}
                  >
                    User Benefits
                  </p>
                  {data.product_bond_benifit_detail.map((item, index) => {
                    return (
                      <div className="user_benefit_new">
                        <div className="user_benefit_new_sub1">
                          <div className="user_benefit_img">
                            <img
                              src={item.reward_img_video[0].img_video}
                              className="user_benefit_img_sub"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="user_benefit_new_sub2">
                          <b> {item.banifit_name}</b>
                          <b> {item.reward_name}</b>
                          <div className="benefit_hr_set"></div>
                          <Link
                            className="view_of_pr_benefit"
                            to={"/ViewBenefits"}
                            state={{
                              partner: 0,
                              is_nfb: data.is_nfb,
                              pro_bo_ben_id: item.pro_bo_ben_id,
                            }}
                          >
                            {" "}
                            View
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
          </>
          {/* ))} */}

          {/* benefits end */}
          {/* media start */}
          {data.bond_media_upload && data.bond_media_upload.length > 0 && (
            <>
              <div className="product_story">
                <div className="product_view_hr"></div>
                <h1 className="pv_titel">Media</h1>
              </div>
              <div style={{ width: "100%" }}>
                {data.bond_media_upload.map((item, index) => {
                  console.log("media", item);
                  return (
                    <img
                      src={item.img_video}
                      style={{ width: "100px", height: "100px" }}
                      alt=""
                      onClick={() => {
                        navigate("/ProductViewImg2", {
                          state: {
                            img: item.img_video ? item.img_video : "",
                          },
                        });
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
          {/* media end */}

          {/* media links start */}
          {getmediaLinks && getmediaLinks.length > 0 ? (
            <>
              <div className="product_story">
                <h1 className="pv_titel">Media Link</h1>
                <div className="product_view_hr"></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {getmediaLinks && getmediaLinks.length > 0
                  ? getmediaLinks.map((item, index) => {
                      console.log("item", item);
                      return (
                        <div key={index} className="ml_wrapp">
                          <a
                            href={item.media_link}
                            target="_blank"
                            className="ml_link_text"
                          >
                            <p>{item.media_link}</p>
                          </a>
                          <button
                            className="ml_copy_btn"
                            onClick={() => handleCopyLink(item.media_link)}
                          >
                            Copy
                          </button>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          ) : null}
          {/* media links end */}

          {/* <div className="hasttagnew_pro" > */}

          {/* Hashtags start */}
          {data.product_hashtag && data.product_hashtag !== null
            ? getproducthashtag.length > 0 && (
                <>
                  <div className="product_story">
                    <div className="product_view_hr"></div>
                    <h1 className="pv_titel">Hashtags</h1>
                  </div>
                  <div className="hashtags_list">
                    {getproducthashtag.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                </>
              )
            : null}
          {/* </div> */}
          {/* Hashtags end */}

          <div className="product_story">
            <h1 className="pv_titel">Creator</h1>
            <div className="product_view_hr"></div>
          </div>
        </div>
        <div
          className="creator_details"
          onClick={() => {
            if (
              data.owner_id !== "" ||
              data.owner_id !== 0 ||
              data.owner_id !== null
            ) {
              // this.props.navigation.navigate('UserProfile', {
              //   user_id: this.state.owner_id,
              // });
              navigate("/UserProfile", {
                state: {
                  user_id: data.owner_id,
                },
              });
            } else {
            }
          }}
        >
          <div
            className="creator_details_details"
            style={{ width: "90%", margin: "10px auto" }}
          >
            <p style={{ color: "#000" }}>{data.description}</p>
          </div>
          <img src={data.profile} className="creator_details_img" alt="" />
          <div className="creator_details_sub">
            <h1 className="creator_details_sub_h1">{data.owner_name}</h1>
            <p className="creator_details_sub_p">Asset Creator</p>
          </div>
          {/* <div className="instgarm">
            <div>
              <img src={images.insta} className="instagram_img_ins" alt="" />
            </div>
            <img src={images.media1} className="instagram_img" alt="" />
            <img src={images.media2} className="instagram_img" alt="" />
            <img src={images.media3} className="instagram_img" alt="" />
            <img src={images.media4} className="instagram_img" alt="" />
            <img src={images.media5} className="instagram_img" alt="" />
            <img src={images.media6} className="instagram_img" alt="" />
          </div> */}
        </div>

        <br />
        <br />
      </div>

      {isModalVisible && (
        <>
          {/* <AlertModal
            title={getmodalName}
            msg={msg}
            setShowModal={setIsModalVisible}
          /> */}
          {/* <NewModal
            title=""
            msg={msg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title={getmodalName}
            msg={msg}
            setShowModal={setIsModalVisible}
            okfunction={closemodal}
          />
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default MilestoneProductView;
