import React, { useState } from "react";
import images from "../../constants/images";
import "./userqr.css";
import BackButton from "../../components/BackButton";
import { useLocation } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";

const Userqr = () => {
  const location = useLocation();
  const copyUrlToClipboard = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard.writeText(location.state.link).then(
      () => {
        // The URL has been copied successfully
        console.log("URL copied to clipboard:", location.state.link);
      },
      (err) => {
        // Unable to copy URL
        console.error("Failed to copy URL:", err);
      }
    );
  };
  const handleShare = () => {
    const urlToShare = window.location.href;
    if (navigator.share) {
      navigator
        .share({
          title: "Share Profile",
          url: location.state.link,
        })
        .then(
          () => {
            // The link has been shared successfully
            console.log("Link shared successfully:", location.state.link);
          },
          (err) => {
            // Sharing failed
            console.error("Failed to share link:", err);
          }
        );
    } else {
      // Fallback to copy URL to clipboard if the Web Share API is not supported
      copyUrlToClipboard();
    }
  };
  return (
    <div className="qr-container">
      {/* <div className="qr_1"></div> */}
      {/* <NavbarSec name="Scan QR Code" /> */}

      <div className="qr_2">
        <div className="qr_user_">
          <div className="qr_header">
            <BackButton />
          </div>
          <div className="user_qr_center_wrapp">
            <div className="qr_details">
              <p className="qr_details_scan_heading">Scan QR Code</p>
              <div style={{ width: "100%" }}>
                <div className="qr_details1">
                  <img
                    className="qr_details1_img"
                    src={location.state.qr_code}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="qr_details_scan_un">{location.state.username}</p>
          </div>
        </div>
      </div>
      <div className="footer_qr_user">
        <div className="footer_qr_user1">
          <button className="footer_qr_user_bottom" onClick={handleShare}>
            <img src={images.share} alt="" />
            &nbsp; &nbsp;
            <p> Share Profile</p>
          </button>
        </div>
        <div className="footer_qr_user2">
          <button
            className="footer_qr_user_bottom"
            onClick={copyUrlToClipboard}
          >
            <img src={images.copylink} alt="" />
            &nbsp; &nbsp;
            <p>Copy link</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Userqr;
