import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import qs from "qs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { club_preview_product, preview_product } from "../utils/Constant";
import NavbarSec from "../common/NavbarSec";
import CusModal from "../common/cusmodal/CusModal";
import NewModal from "../common/newmodal/NewModal";
import V2Modal from "../common/v2modal/V2Modal";

const ClubBondissue = () => {
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const naviagte = useNavigate();
  const bondissueSettings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll per interaction
  };

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [created_at, setcreated_at] = useState("");
  const [product_price, setproduct_price] = useState("");
  const [product_value, setproduct_value] = useState("");
  const [product_img_video, setproduct_img_video] = useState("");
  const [min_bid_amount, setmin_bid_amount] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [minus_bond_data, setminus_bond_data] = useState(
    location.state.is_owner == 4 ? 0 : location.state.is_globle == 1 ? 0 : 0
  );
  const [number_of_bod, setNumberOfBod] = useState("");

  // date start

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    if (date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return { day, month, year };
    }
    return { day: "", month: "", year: "" };
  };

  const { day, month, year } = formatDate(selectedDate);

  //  date end

  const handleTextInputChange = (data) => {
    const inputValue = data;
    const isValid = /^\d+$/.test(inputValue) && parseInt(inputValue, 10) >= 0;

    if (!isValid && inputValue.length !== 0) {
      setNumberOfBod("");
      setErrorMessage("Please enter a valid bid amount");
      setShowModal(true);
    } else {
      const remaining_bond = location.state.remaining_bond - minus_bond_data;
      if (parseInt(data) <= remaining_bond || data === "") {
        setNumberOfBod(data);
      } else {
        setNumberOfBod("");
        alert(`Please enter below ${remaining_bond} values of Rarerez`);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const param = {
          club_product_id: location.state.club_product_id,
          user_id: location.state.club_user_id,
          club_id: location.state.club_id,
          share_by_user: location.state.share_by_user,
        };

        const response = await axios.post(
          club_preview_product,
          qs.stringify(param)
        );

        if (response.data.status === 1) {
          const result = response.data.result;
          console.log("club_preview_product result", result);

          setproduct_name(result.product_name);
          setdescription(result.description);
          setcreated_at(result.created_at);
          setproduct_price(result.product_value);
          setproduct_value(result.product_value_data);
          setproduct_img_video(
            result.product_img_video.map((item) => item.product_icon)
          );

          // var demo = [];
          // for (
          //   let index = 0;
          //   index < response.data.result.product_img_video.length;
          //   index++
          // ) {
          //   demo = [
          //     ...product_img_video,
          //     Response.data.result.product_img_video[index].product_icon,
          //   ];
          //   setproduct_img_video(demo);
          // }

          console.log("====================================");
          console.log("product_img_video", product_img_video);
          console.log("====================================");
          setIsLoading(false);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location.state.product_id]);

  const handleKeyPress = (e) => {
    // Check if the pressed key is a dot
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
      // alert("Dots are not allowed in the bid amount");
    }
  };

  // submit btn code start
  const handleSubmit = () => {
    if (number_of_bod == 90) {
      if (number_of_bod == 0) {
        // alert("You have to enter minimum 1 bond");
        setErrorMessage("You have to enter minimum 1 bond");
        setShowModal(true);
      } else if (min_bid_amount == "") {
        // alert("Enter minimum bid amount");
        setErrorMessage(
          "Kindly input your minimum expected amount. You will be notified of bids exceeding your expectations."
        );
        setShowModal(true);
      } else if (min_bid_amount >= product_price) {
        naviagte("/ClubBondbox", {
          state: {
            club_product_id: location.state.club_product_id,
            is_globle: location.state.is_globle,
            club_user_id: location.state.club_user_id,
            number_of_bod: number_of_bod,
            product_image: location.state.product_image,
            product_name: location.state.product_name,
            no_of_release_bond: parseInt(number_of_bod) + minus_bond_data,
            min_bid_amount: min_bid_amount,
            remaining_bond_release_date: "",
            commitment_date: moment(selectedDate).format("YYYY-MM-DD"),
            // is_owner: location.state.is_owner,
            // remaining_bond: parseInt(
            //   location.state.remaining_bond - minus_bond_data
            // ),
            // user_id: userId,
            share_by_user: location.state.share_by_user,
            user_id: location.state.club_user_id,
            club_id: location.state.club_id,
            commitment_date_new: location.state.commitment_date_new,
          },
        });
      } else {
        // alert("The minimum bid amount should be above minimum bond value.");
        setErrorMessage(
          "The minimum bid amount should be above minimum bond value"
        );
        setShowModal(true);
      }
    } else {
      if (number_of_bod == 0) {
        // alert("You have to enter minimum 1 bond");
        setErrorMessage("You have to enter minimum 1 bond");
        setShowModal(true);
      } else if (min_bid_amount == "") {
        // alert("Enter minimum bid amount");
        setErrorMessage(
          "Kindly input your minimum expected amount. You will be notified of bids exceeding your expectations."
        );
        setShowModal(true);
      } else if (min_bid_amount >= product_price) {
        naviagte("/ClubBondbox", {
          state: {
            club_product_id: location.state.club_product_id,
            is_globle: location.state.is_globle,
            number_of_bod: number_of_bod,
            product_image: location.state.product_image,
            product_name: location.state.product_name,
            no_of_release_bond: parseInt(number_of_bod) + minus_bond_data,

            // // is_owner: location.state.is_owner,
            club_user_id: location.state.club_user_id,
            remaining_bond: parseInt(
              location.state.remaining_bond - minus_bond_data
            ),
            min_bid_amount: min_bid_amount,
            remaining_bond_release_date:
              moment(selectedDate).format("YYYY-MM-DD"),
            commitment_date: moment(selectedDate).format("YYYY-MM-DD"),
            is_club_assign: location.state.is_club_assign,

            share_by_user: location.state.share_by_user,
            user_id: location.state.club_user_id,
            club_id: location.state.club_id,

            // product_id: location.state.product_id,
            // user_id: userId,
            commitment_date_new: location.state.commitment_date_new,
          },
        });
      } else {
        // alert(
        //   `Please enter minimum bid amount above Rarerez value - ${product_price}`
        // );
        setErrorMessage(
          `Please enter minimum bid amount above Rarerez value - ${product_price}`
        );
        setShowModal(true);
      }
    }
  };
  // submit btn code end

  //disable body scrolling

  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, bodyScrollEnabled]);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Create Rarerez" />
        {/* <b className="cre_titel">
          Congratulations! Now, it's time to add some enticing benefits to make
          your Rarerez even more valuable. Attach exclusive perks, experiences,
          or rewards that align with your asset. Your added benefits will make
          your Rarerez more desirable. Happy attaching!
        </b> */}
        <br />
        <br />
        <Slider {...bondissueSettings}>
          {/* Use the Slider component with your settings */}
          {product_img_video && product_img_video.length > 0
            ? product_img_video.map((item, index) => {
                console.log("item jjj", item);
                return (
                  <div>
                    <img src={item} className="Collaboratenfb_img" alt="" />
                  </div>
                );
              })
            : null}
          {/* Add additional slides here */}
                 
        </Slider>
        <br />
        <b className="nm_bondise">{product_name}</b>
        <p className="bondise_price">₹{product_value}</p>
        <p style={{ marginTop: "10px" }} className="bondise_price">
          Minimum Rarerez value: ₹{product_price}
        </p>
        <br />
        <div className="bondissue_details">
          <div className="bondissue_details1">
            <p className="bondise_price">
              How many Rarerez do <br />
              you want to issue ?{" "}
              {/* <span
                className="details_home_icon"
                onClick={() => {
                  setShowModal(true);
                  setErrorMessage(
                    "Verify the authenticity of your asset by uploading the authentication certificate file. Ensure it is a valid document that confirms the originality of your Rarerez. Protect your investment and contribute to a trustworthy community."
                  );
                }}
              >
                {" "}
                {String.fromCharCode(9432)}
              </span> */}
            </p>
          </div>
          <div className="bondissue_details2">
            <input
              type="number"
              className="input_bondissue"
              placeholder="0"
              onInput={(e) => {
                if (e.target.value.length > 2) {
                  e.target.value = e.target.value.slice(0, 2);
                }
              }}
              onKeyPress={handleKeyPress}
              onChange={(e) => handleTextInputChange(e.target.value)}
              value={number_of_bod}
              maxLength="2"
            />
            <span className="bondissue_arrow">/</span>
            <input
              type="number"
              className="input_bondissue"
              placeholder={
                location.state.is_owner == 4
                  ? location.state.remaining_bond
                  : parseInt(location.state.remaining_bond - minus_bond_data)
              }
              readOnly
            />
          </div>
        </div>
        {parseInt(location.state.remaining_bond - minus_bond_data) ==
        number_of_bod ? null : (
          // <div
          //   style={{ flexDirection: "row", marginTop: 10, marginRight: -10 }}
          // >
          //   <div style={{ flex: 1 }}>
          //     <p style={{ fontSize: 16, fontFamily: "Gr_bold", color: "#fff" }}>
          //       Commitment Date&nbsp;
          //       <span
          //         onClick={() => {
          //           // Handle your modal visibility here
          //         }}
          //         style={{
          //           color: "#e51a4b",
          //           fontSize: 16,
          //           marginLeft: 10,
          //           cursor: "pointer",
          //         }}
          //       >
          //         &#9432;
          //       </span>
          //     </p>
          //   </div>

          //   <div style={{ flex: 1.4, flexDirection: "row" }}>
          //     <DatePicker
          //       selected={selectedDate}
          //       onChange={handleDateChange}
          //       placeholderText="dd/MM/yyyy"
          //       dateFormat="dd/MM/yyyy"
          //       minDate={new Date()}
          //       showYearDropdown
          //       showMonthDropdown
          //       scrollableYearDropdown
          //       yearDropdownItemNumber={10}
          //       dropdownMode="select"
          //       isClearable
          //     />
          //   </div>
          // </div>

          // ===

          <div className="bondissue_date">
            <div className="bondissue_date1">
              <p style={{ fontFamily: "var(--font-600)" }}>Reminder date</p>
              <span
                className="details_home_icon"
                onClick={() => {
                  setShowModal(true);
                  setErrorMessage(
                    "Take control of your Rarerez releases by setting a Reminder Date. This is the date before which you commit not to release any new Rarerez for this particular asset. It helps you plan and manage your Rarerez creation schedule effectively."
                  );
                }}
              >
                {" "}
                {String.fromCharCode(9432)}
              </span>
            </div>
            <div className="bondissue_date2">
              <div style={{ flex: 1.4, flexDirection: "row" }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  minDate={tomorrow}
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={10}
                  dropdownMode="select"
                  isClearable
                  disabledKeyboardNavigation
                />
              </div>
            </div>
          </div>
        )}

        <div className="bondissue_minimum">
          <div className="bondissue_minimum1">
            <p className="bondise_price">Notify me when the bid crosses:</p>
          </div>
          <div className="bondissue_minimum2">
            ₹{" "}
            <input
              type="number"
              className="minimum_bin_input"
              value={min_bid_amount}
              onChange={(e) => {
                const inputValue = e.target.value;
                const isValid =
                  /^\d+$/.test(inputValue) && parseInt(inputValue, 10) >= 0;

                if (!isValid) {
                  setmin_bid_amount("");
                  setErrorMessage("Please enter a valid bid amount");
                  setShowModal(true);
                } else {
                  setmin_bid_amount(parseInt(inputValue, 10));
                  console.log("isValid", inputValue);
                }
              }}
            />
          </div>
        </div>
        <div className="bond_issue_btn">
          <button className="new_btn_wrapp mt-3" onClick={handleSubmit}>
            <p>Save and promote</p>
          </button>
        </div>
      </div>
      {showModal && (
        // <CusModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   showModal={showModal}
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
    </div>
  );
};

export default ClubBondissue;
