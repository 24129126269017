import React, { useEffect, useState } from "react";
import "./ClubMamberRequsts.css";
import NavbarSec from "../../common/NavbarSec";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import NewLoader from "../../common/newloder/NewLoader";

const ClubMamberRequsts = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const _getData = () => {
    const param = {
      user_id: userId,
    };

    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/get_club_manager_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          setData(response.data.result);
          //   setSearch(response.data.result);
          //   setIsOk(1);
        } else {
          setData([]);
          //   setIsOk(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const _setAcceptReject = (id, club_id, status) => {
    const param = {
      club_manager_id: id,
      club_id: club_id,
      status: status,
    };

    setIsLoading(true);

    axios
      .post(
        "https://www.ravi.host/api/accept_reject_manager_request",
        qs.stringify(param)
      )
      .then((response) => {
        console.log("res", response.data);
        setIsLoading(false);

        if (response.data.status == 1) {
          //   setData(response.data.result);
          _getData();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    _getData();
  }, []); // This will run _getData when the component mounts
  return (
    <div className="page_main_wrapp_nw reletive_position">
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NewLoader />
        </div>
      ) : (
        <div className="page_base_wrapp_2">
          {/* <NavbarSec name="Club Requests" /> */}
          <div
            style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}
          >
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Club Requests</p>
              <div></div>
            </div>
          </div>

          <div className="cmr_list_wrapp">
            {data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <div className="cmr_single_item">
                    <img
                      src={item.cover_photo}
                      alt=""
                      className="cmr_single_item_img"
                    />
                    <p className="cmr_single_item_name">{item.club_name}</p>
                    <div>
                      <button
                        onClick={() => {
                          _setAcceptReject(
                            item.club_manager_id,
                            item.club_id,
                            1
                          );
                        }}
                      >
                        <IoIosCheckmarkCircleOutline size={30} color="green" />
                      </button>
                      <button
                        onClick={() => {
                          _setAcceptReject(
                            item.club_manager_id,
                            item.club_id,
                            2
                          );
                        }}
                      >
                        <IoIosCloseCircleOutline size={30} color="red" />
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="cmr_no_data_text">No Requests Found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClubMamberRequsts;
