import React, { useState } from "react";
import images from "../../constants/images";
// import Modal from "react-modal";
import "./Bidsall.css";
import BackButton from "../../components/BackButton";

const Bidsall = () => {
  const [showButtons, setShowButtons] = useState(true);
  const [status, setStatus] = useState("");

  const handleBidAction = (action) => {
    setShowButtons(false);
    setStatus(action === "accept" ? "Accepted" : "Rejected");
  };
  return (
    <div className="bidsall_screen">
      {/* start header */}
      <div className="bidsallheader">
        <div className="bidsallheader1">
          <BackButton />
        </div>
        <div className="bidsallheader2">
          <h1 className="bidsall_h1">Bids</h1>
        </div>
      </div>
      {/* end  header */}
      <div className="alllist">
        <div className="alllist_part1">
          <img src={images.vk_img} className="alllist_part1_img" alt="" />
        </div>
        <div className="alllist_part2">
          <p>VISHALAHIR</p>
          <p>₹21000</p>
        </div>
        <div className="alllist_part3">
          {showButtons && (
            <div className="bidsbtn_all">
              <div className="bidsbtn_all1">
                <button
                  className="button_all1"
                  onClick={() => handleBidAction("accept")}
                >
                  <p className="button_img" alt=""></p>
                </button>
                <h1 className="button_allp">Accept</h1>
              </div>
              <div className="bidsbtn_all2">
                <button
                  className="button_all2"
                  onClick={() => handleBidAction("reject")}
                >
                  <p className="button_img2" alt="">
                    x
                  </p>
                </button>
                <h1 className="button_allp">Reject</h1>
              </div>
            </div>
          )}

          {status && <div className="msg_bids">{status}</div>}
        </div>
      </div>
      
    </div>
  );
};

export default Bidsall;
