import React, { useEffect } from "react";
import "./OrderConfirmation.css";
import { useState } from "react";
import { get_user_cart_product_data } from "../../utils/Constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import images from "../../constants/images";
import { FaPlus } from "react-icons/fa6";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getproducts, setProducts] = useState([]);
  const [getallAddresses, setAddresses] = useState([]);
  const [getshippingcharges, setShippingCharges] = useState("");
  const [gettotalProice, setTotalPrice] = useState("");
  const [loading, setloading] = useState(false);
  const [get_ship_id, set_ship_id] = useState("");
  const [getusername, setUserName] = useState("");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    getSingleProduct();
    ShippingChare();
    GetAllAddress();
  }, []);

  const getSingleProduct = async () => {
    const sizee = "";
    setloading(true);
    const params = {
      user_id: userId,
      ecommerce_product_id: location.state.ecommerce_product_id,
    };
    axios
      .post(get_user_cart_product_data, qs.stringify(params))
      .then((res) => {
        setloading(false);

        console.log("111-------------+_->", JSON.stringify(res.data, null, 2));

        if (res.data.status == 1) {
          console.log("get_user_cart_product_data data--->", res.data);
          setProducts(res.data.result);
          setUserName(res.data.username);
          //   setAllQty(res.data.result.quantity);
          //   setPrice(res.data.result.price);
          //   setSizes(
          //     res.data.result.size !== "" ? res.data.result.size.split(",    ") : []
          //   );
          const totalSum = res.data.result.reduce(
            (sum, item) => sum + item.total,
            0
          );
          setTotalPrice(totalSum);
        } else {
          setProducts([]);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const ShippingChare = () => {
    setloading(true);

    const params = {
      user_id: userId,
      ecommerce_product_id: location.state.ecommerce_product_id,
    };
    axios
      .post("https://www.ravi.host/api/shipping_charges", qs.stringify(params))
      .then((res) => {
        setloading(false);
        if (res.data.status == 1) {
          console.log("ShippingDetails", JSON.stringify(res.data, null, 2));

          setShippingCharges(res.data.shipping_charges);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const GetAllAddress = () => {
    setloading(true);

    const params = {
      user_id: userId,
    };
    axios
      .post("https://www.ravi.host/api/getall_address", qs.stringify(params))
      .then((res) => {
        setloading(false);
        if (res.data.status == 1) {
          console.log("ShippingDetails", JSON.stringify(res.data, null, 2));

          setAddresses(res.data.result);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const productDelete = (cartId) => {
    setloading(true);

    const params = {
      cart_id: cartId,
    };

    axios
      .post(
        "https://www.ravi.host/api/delete_user_cart_product",
        qs.stringify(params)
      )
      .then((res) => {
        console.log("deleteAPI----->", JSON.stringify(res.data, null, 2));
        if (res.data.status == 1) {
          getSingleProduct();
        }
      })
      .then(() => {
        setloading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setloading(false);
      });
  };

  const placeOrder = (addressId_A) => {
    var demo = getproducts.length;
    console.log("====================================");
    console.log("tesst", demo);
    console.log("====================================");

    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("address_id", get_ship_id);
    formData.append("amount", gettotalProice);
    formData.append("total_amount", gettotalProice + getshippingcharges);
    formData.append("shipping_charges", getshippingcharges);
    // formData.append("ecommerce_product_id", this.props.route.params.id);
    let cartIds = "";
    let productIds = "";
    let product_price = "";

    for (let i = 0; i < getproducts.length; i++) {
      if (i !== 0) {
        cartIds += ",";
        productIds += ",";
        product_price += ",";
      }
      cartIds += getproducts[i].cart_id;
      productIds += getproducts[i].ecommerce_product_id;
      product_price += getproducts[i].total;
    }

    formData.append("cart_id", cartIds);
    formData.append("ecommerce_product_id", productIds);
    formData.append("product_amount", product_price);
    console.log(
      "FormData==============>()()",
      JSON.stringify(formData, null, 2)
    );

    axios
      .post("https://www.ravi.host/api/place_order", formData)
      .then((res) => {
        if (res.data.status === 1) {
          // this.props.navigation.replace("SuccessProductBuy");
          console.log("FormDataAppended--->", res.data);
        } else {
          alert(Response.data.message);

          // this.setState({
          //   isModalVisible: true,
          //   cusmsg: res.data.message,
          // });

          //   Alert.alert('Alert', 'Please recharge your wallet now', [
          //     {
          //       text: 'Yes',
          //       onPress: () => {
          //         this.props.navigation.navigate('Wallet');
          //       },
          //     },
          //     {
          //       text: 'No',
          //       onPress: () => console.log('Cancel Pressed'),
          //       style: 'cancel',
          //     },
          //   ]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="my_order_main_wrapp">
      <div className="my_order_base_wrapp">
        <div className="mo_nav_wrap">
          <img
            src={images.back_black}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <p>Confirmation</p>
        </div>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <>
            <div className="oc_first_wrapp"></div>
            <div className="oc_secound_wrapp">
              <img src={images.shopping_bag} alt="" />
              <p>
                Hey , {getusername} <br />
                your cart datails
              </p>
            </div>
            {getproducts && getproducts.length > 0 ? (
              <>
                {/* products start */}
                {getproducts.map((item, index) => {
                  return (
                    <div className="mo_single_product" key={item.cart_id}>
                      <div className="mo_sp_part1">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="mo_sp_part2">
                        <p className="mo_sp_part2_heading">
                          {item.product_name}
                        </p>
                        {item.size && item.size.length > 0 ? (
                          <div className="mo_sp_part2_size_wrapp">
                            <p>Selectd Size : &nbsp;</p>
                            {item.size.map((itm, ind) => {
                              return <b>{itm}</b>;
                            })}
                          </div>
                        ) : null}
                        <div className="mo_sp_part2_size_wrapp">
                          <p>Quantity : &nbsp;</p>
                          <b>{item.quantity}</b>
                        </div>

                        <div className="mo_sp_part2_hr" />
                        <div className="mo_sp_part3_size_wrapp">
                          <p className="mo_sp_part3_final_price">
                            Product price ₹{item.total}/-
                          </p>
                          <img
                            src={images.deleteicon}
                            alt=""
                            onClick={() => {
                              productDelete(item.cart_id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* products end */}

                {/* view products start */}

                <div className="oc_view_product_wrapp">
                  <div className=""></div>
                  <button
                    onClick={() => {
                      navigate(-2);
                    }}
                  >
                    View Product
                  </button>
                </div>

                {/* view products end */}

                {/* all address start */}

                <div className="oc_addresses_wrapp">
                  {getallAddresses && getallAddresses.length > 0
                    ? getallAddresses.map((item, index) => {
                        console.log("item", item);
                        return (
                          <button
                            className="oc_sigle_add"
                            key={item.address_id}
                          >
                            <input
                              type="radio"
                              id={item.address_id}
                              name="radioGroup"
                              onChange={(event) => {
                                console.log("event", event.target.id);
                                set_ship_id(event.target.id);
                              }}
                            />
                            <div className="oc_sigle_add_inner">
                              <label htmlFor={item.address_id}>
                                {item.user_address == 0
                                  ? "Home"
                                  : item.user_address == 1
                                  ? "Work"
                                  : item.user_address == 2 &&
                                    item.other_address !== ""
                                  ? item.other_address
                                  : null}
                              </label>
                              <p>{item.address}</p>
                            </div>
                          </button>
                        );
                      })
                    : null}
                </div>

                {/* all address end */}

                {/* add address btn start */}

                <button
                  onClick={() => {
                    navigate("/AddressForProductBuy", {
                      state: {
                        totalamount: gettotalProice,
                        getshippingcharges: getshippingcharges,
                      },
                    });
                  }}
                  className="oc_add_address_btn"
                >
                  <FaPlus size={20} color="#fff" />
                  <p>Add Address</p>
                </button>

                {/* add address btn end */}

                {/* oc total start */}

                <div className="oc_total_main_wrapp">
                  <div className="pac_wrapp">
                    <p>Product actual cost</p>
                    <p>₹ {gettotalProice}/-</p>
                  </div>
                  <div className="pac_wrapp">
                    <p>Shipping charges</p>
                    <p>₹ {getshippingcharges}/-</p>
                  </div>
                  <div className="mo_sp_part2_hr"></div>
                  <div className="pac__total_wrapp">
                    <p>Total</p>
                    <p>₹ {gettotalProice + getshippingcharges}/-</p>
                  </div>
                </div>

                {/* oc total end */}

                {/* confirm order btn start */}

                <div className="bp_total_wrapp" style={{ minHeight: "80px" }}>
                  <div className="bp_total_wrapp_inner">
                    <button
                      className="oc_co_btn"
                      onClick={() => {
                        placeOrder();
                      }}
                    >
                      Confirm Order
                    </button>
                  </div>
                </div>
                {/* confirm order btn end */}
              </>
            ) : (
              <div className="empty_cart_btn_view">
                <button
                  className="empty_cart_btn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  + Add Products
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderConfirmation;
