import React, { useEffect, useState } from "react";
import "../FeedNewFrame1.css";
import { useSwipeable } from "react-swipeable";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { release_bond_like } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";

const HistoryNewFrame1 = ({
  msg,
  setShowModal,
  showModal,
  setIsModalOpen,
  setMsg,
  setModalName,
  data,
  icon,
  handleData,
  windowWidth,
}) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  useEffect(() => {
    console.log("scdc", data);
  }, []);

  return (
    <div className="blue-card-main-wrapp">
      <div
        className={`flip-container ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        {windowWidth > 786 && (
          <div
            onClick={() => setIsFlipped(!isFlipped)}
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={images.flip_icon}
              style={{ width: "30px", height: "30px", alignSelf: "flex-end" }}
            />
          </div>
        )}
        <div className="flipper">
          <div
            className="front"
            onClick={() => {
              navigate("/ProductView2", {
                state: {
                  uniq_code: data.uniq_code,
                  is_nfb: data.is_nfb,
                  product_id: data.product_id,
                  release_bond_id: data.release_bond_id,
                  theme_index: data.theme_index,
                  accept_bond_bid_id: data.accept_bond_bid_id,
                  share_by_user: data.share_by_user,
                },
              });
            }}
          >
            <img src={images.new_frame_1_f} className="frame-img" alt="" />
            <img src={icon} className="inner-img" alt="product img" />
            <div className="front-card-del-qr">
              <div className="front-card-del-div">
                <div className="front-card-del">
                  <p>
                    {data.product_name?.slice(0, 24)}
                    {data.product_name?.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {data.username?.slice(0, 20)}{" "}
                    {data.username?.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{data.product_value}</p>
                </div>
              </div>
              <div className="front-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="back">
            <img src={images.new_frame_1_b} className="frame-img" alt="" />
            {/* <img
              src={icon}
              className="inner-img"
              alt=""
              onClick={() => {
                navigate("/ProductViewImg", {
                  state: {
                    img: data.product_image_video
                      ? data.product_image_video
                      : "",
                  },
                });
              }}
            /> */}
            <div className="back-card-del-div">
              {data.desc && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product Description</p>
                  <p className="des-content">
                    {data.desc.slice(0, 78)} {data.desc.length > 78 && "..."}
                  </p>
                  {data.desc.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.desc);
                        setModalName("Product Description");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_history && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product story</p>
                  <p className="des-content">
                    {data.product_history.slice(0, 78)}{" "}
                    {data.product_history.length > 78 && "..."}
                  </p>
                  {data.product_history.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_history);
                        setModalName("Product Story");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {data.product_highlights && (
                <>
                  <p className="des-name">Product Highlight</p>
                  <p className="des-content">
                    {data.product_highlights.slice(0, 78)}{" "}
                    {data.product_highlights.length > 78 && "..."}
                  </p>
                  {data.product_highlights.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(data.product_highlights);
                        setModalName("Product Highlight");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="owner-name-div">
              <p className="owned-by">Owned by</p>
              <p className="owner-name">{data.owner_name}</p>
            </div>
            <div className="back-card-del-qr">
              <div className="back-card-del-div2">
                <div className="back-card-del">
                  <p>{data.product_name}</p>
                  <p>
                    By : {data.username?.slice(0, 20)}{" "}
                    {data.username?.length > 20 ? "..." : null}
                  </p>
                  <p>rarerez Value : ₹{data.minimum_bid_amount}</p>
                </div>
              </div>
              <div className="back-card-qr">
                <div className="qr-circle">
                  <img src={data.qr_code} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryNewFrame1;
