import React, { useState, useRef } from "react";
import "./partnerbenefits.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import { useEffect } from "react";
import AlertModal from "../../components/AlertModal";
import { ReactMediaRecorder } from "react-media-recorder";
import Webcam from "react-webcam";
import RecordRTC from "recordrtc";
import ReactModal from "react-modal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";
import { ToastContainer, toast } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa";

const Partnerbenefits = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const videoConstraints = {
    width: 310,
    height: 10,
  };
  const [searchInput, setSearchInput] = useState(""); // for search input
  const [getisOk, setIsOk] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [getproducts, setProducts] = useState(null);
  const [getsearchProducts, setSearchProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");

  const [new_reward_id, setNew_reward_id] = useState("");
  const [getmessage, setmessage] = useState("");
  const [new_partner_id, setNew_partner_id] = useState("");
  const [new_partner_benifit_id, setNew_partner_benifit_id] = useState("");
  const [new_user_id, setNew_user_id] = useState("");
  const [requstedmodal, setRequstedmodal] = useState(false);
  const [requstedmodal_1, setRequstedmodal_1] = useState(false);
  const [getvideoMassage, setVideoMassage] = useState("");
  const [getuploadModal, setUploadModal] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    GetBenefitData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // video sec start

  // const webcamRef = useRef(null);
  // const [facingMode, setFacingMode] = useState("user");
  // const [isCameraOn, setIsCameraOn] = useState(false);

  // const switchCamera = () => {
  //   setFacingMode((prevFacingMode) =>
  //     prevFacingMode === "user" ? "environment" : "user"
  //   );
  // };

  // const startCamera = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //     });
  //     webcamRef.current.video.srcObject = stream;
  //     setIsCameraOn(true);
  //   } catch (error) {
  //     console.error("Error starting the camera:", error);
  //   }
  // };

  // const stopCamera = () => {
  //   const stream = webcamRef.current.video.srcObject;
  //   const tracks = stream.getTracks();

  //   tracks.forEach((track) => track.stop());
  //   webcamRef.current.video.srcObject = null;

  //   setIsCameraOn(false);
  // };

  // const pauseCamera = async () => {
  //   stopCamera();
  //   setIsCameraOn(false);
  // };

  // const resumeCamera = async () => {
  //   startCamera();
  // };

  // useEffect(() => {
  //   return () => {
  //     // Ensure to stop the camera when the component is unmounted
  //     if (isCameraOn) {
  //       stopCamera();
  //     }
  //   };
  // }, [isCameraOn]);

  // useEffect(() => {
  //   const updateCamera = async () => {
  //     const videoConstraints = {
  //       facingMode: facingMode,
  //     };

  //     const mediaStream = await navigator.mediaDevices.getUserMedia({
  //       video: videoConstraints,
  //     });

  //     webcamRef.current.video.srcObject = mediaStream;
  //   };

  //   updateCamera();
  // }, [facingMode]);

  // video sec end

  const GetBenefitData = async () => {
    setIsLoading(true);
    const param = {
      manager_id: userId,
    };

    await axios
      .post(
        "https://www.ravi.host/api/get_manager_partner_benifit_reward_claim",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("====================================");
        console.log(
          "get_manager_partner_benifit_reward_claim res",
          Response.data
        );
        console.log("====================================");
        setIsLoading(false);

        if (Response.data.status == 1) {
          setProducts(Response.data.result);
          setSearchProducts(Response.data.result);
          setIsOk(0);
        } else {
          setIsOk(1);
          //alert(Response.data.message);
        }
        console.log("Respnose", Response.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("get_manager_partner_benifit_reward_claim err", err);
      });
  };

  const PartnerRewardAccept = async () => {
    setIsLoading(true);
    const param = {
      reward_id: partnerbenefitsid,
      status: 2,
    };
    console.log("param", param);
    await axios
      .post(
        "https://www.ravi.host/api/reward_claim_partner_accept",
        qs.stringify(param)
      )
      .then((Response) => {
        console.log("====================================");
        console.log("reward_claim_partner_accept res", Response.data);
        console.log("====================================");
        setIsLoading(false);

        if (Response.data.status == 1) {
          GetBenefitData();
          setconformationmodel(false);
          setIsModalVisible(true);
          setMsg(Response.data.message);
        } else {
          setIsModalVisible(true);
          setMsg(Response.data.message);
        }
        console.log("Respnose", Response.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("get_manager_partner_benifit_reward_claim err", err);
      });
  };

  const [partnerbenefitsid, setpartnerbenefitsid] = useState("");
  const [getconformationmodel, setconformationmodel] = useState(false);

  // search start

  const searchFilter_branch = (text) => {
    const newData = getsearchProducts.filter(function (item) {
      console.log("item", item.partner_name);
      const employee = item.partner_name
        ? item.partner_name.toUpperCase()
        : "".toLowerCase();
      const number = String(item.username)
        ? String(item.username).toUpperCase()
        : "".toUpperCase();
      const claim_time = String(item.claim_time)
        ? String(item.claim_time).toUpperCase()
        : "".toUpperCase();

      const textData = text.toUpperCase();
      return (
        employee.indexOf(textData) > -1 ||
        number.indexOf(textData) > -1 ||
        claim_time.indexOf(textData) > -1
      );
    });
    setProducts(newData);
    console.log("asvsvsds", newData);
  };
  const openModal = () => {
    setIsModalVisible1(true);
  };
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const [inputValuedd1, setInputValuedd1] = useState("");
  const [inputValuemm1, setInputValuemm1] = useState("");
  const [inputValueyear1, setInputValueyear1] = useState("");

  /// secound
  const [inputValuedd2, setInputValuedd2] = useState("");
  const [inputValuemm2, setInputValuemm2] = useState("");
  const [inputValueyear2, setInputValueyear2] = useState("");

  /// third
  const [inputValuedd3, setInputValuedd3] = useState("");
  const [inputValuemm3, setInputValuemm3] = useState("");
  const [inputValueyear3, setInputValueyear3] = useState("");

  /// first

  const handleInputChangedate1 = (e) => {
    const value = e.target.value;
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuedd1(value);
    }
    if (value.length == 2) {
      if (value <= 31 && value > 0) {
      } else {
        setInputValuedd1("");
        setIsModalVisible(true);
        setMsg("Enter valid day");
      }
    }

    // Limit the input to two characters
  };
  const handleInputChangedatemm1 = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuemm1(value);
    }
    if (value.length == 2) {
      if (value <= 12 && value > 0) {
      } else {
        setInputValuemm1("");
        setIsModalVisible(true);
        setMsg("Enter valid month");
      }
    }
  };
  // const handleInputChangedateyear1 = (e) => {
  //   const value = e.target.value;

  //   // Limit the input to two characters
  //   if (value.length <= 4 && /^\d*$/.test(value)) {
  //     setInputValueyear1(value);
  //   }
  //   const currentYear = new Date().getFullYear().toString();

  //   if (value.length == 4) {
  //     if (value === currentYear) {
  //     } else {
  //       setInputValueyear1("");
  //       setIsModalVisible(true);
  //       setMsg("Enter valid year");
  //     }
  //   }
  // };

  const handleInputChangedateyear1 = (e) => {
    const value = e.target.value;

    // Limit the input to four characters
    if (value.length <= 4 && /^\d*$/.test(value)) {
      setInputValueyear1(value);
    }

    const currentYear = new Date().getFullYear();

    if (value.length === 4) {
      const enteredYear = parseInt(value, 10);

      if (enteredYear >= currentYear) {
        // Entered year is current year or a future year
        // Proceed with your logic here
      } else {
        setInputValueyear1("");
        setIsModalVisible(true);
        setMsg("Enter valid year");
      }
    }
  };

  // secound

  const handleInputChangedate2 = (e) => {
    const value = e.target.value;
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuedd2(value);
    }
    if (value.length == 2) {
      if (value <= 31 && value > 0) {
      } else {
        setInputValuedd2("");
        setIsModalVisible(true);
        setMsg("Enter valid day");
      }
    }

    // Limit the input to two characters
  };
  const handleInputChangedatemm2 = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuemm2(value);
    }
    if (value.length == 2) {
      if (value <= 12 && value > 0) {
      } else {
        setInputValuemm2("");
        setIsModalVisible(true);
        setMsg("Enter valid month");
      }
    }
  };
  const handleInputChangedateyear2 = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 4 && /^\d*$/.test(value)) {
      setInputValueyear2(value);
    }
    const currentYear = new Date().getFullYear().toString();

    if (value.length == 4) {
      const enteredYear = parseInt(value, 10);

      if (enteredYear >= currentYear) {
      } else {
        setInputValueyear2("");
        setIsModalVisible(true);
        setMsg("Enter valid year");
      }
    }
  };
  // third

  const handleInputChangedate3 = (e) => {
    const value = e.target.value;
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuedd3(value);
    }
    if (value.length == 2) {
      if (value <= 31 && value > 0) {
      } else {
        setInputValuedd3("");
        setIsModalVisible(true);
        setMsg("Enter valid day");
      }
    }

    // Limit the input to two characters
  };
  const handleInputChangedatemm3 = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setInputValuemm3(value);
    }
    if (value.length == 2) {
      if (value <= 12 && value > 0) {
      } else {
        setInputValuemm3("");
        setIsModalVisible(true);
        setMsg("Enter valid month");
      }
    }
  };
  const handleInputChangedateyear3 = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 4 && /^\d*$/.test(value)) {
      setInputValueyear3(value);
    }
    const currentYear = new Date().getFullYear().toString();

    if (value.length == 4) {
      const enteredYear = parseInt(value, 10);

      if (enteredYear >= currentYear) {
      } else {
        setInputValueyear3("");
        setIsModalVisible(true);
        setMsg("Enter valid year");
      }
    }
  };

  const handleFormSubmit = () => {
    if (
      inputValuedd1 === "" &&
      inputValuemm1 === "" &&
      inputValueyear1 === ""
    ) {
      setIsModalVisible(true);
      setMsg("Enter 1st date");
    } else if (
      inputValuedd2 === "" &&
      inputValuemm2 === "" &&
      inputValueyear2 === ""
    ) {
      setIsModalVisible(true);
      setMsg("Enter 2nd date");
    } else if (
      inputValuedd3 === "" &&
      inputValuemm3 === "" &&
      inputValueyear3 === ""
    ) {
      setIsModalVisible(true);
      setMsg("Enter 3rd date");
    } else {
      const date1 = `${inputValueyear1}-${inputValuemm1}-${inputValuedd1}`;
      const date2 = `${inputValueyear2}-${inputValuemm2}-${inputValuedd2}`;
      const date3 = `${inputValueyear3}-${inputValuemm3}-${inputValuedd3}`;

      const param = {
        partner_benifit_id: new_partner_benifit_id,
        user_id: new_user_id,
        partner_id: new_partner_id,
        reward_id: new_reward_id,
        date_1: date1,
        date_2: date2,
        date_3: date3,
      };

      console.log(
        "====================================",
        JSON.stringify(param, null, 2)
      );

      axios
        .post(
          "https://www.ravi.host/api/user_benifit_reward_request_date",
          qs.stringify(param)
        )
        .then((Response) => {
          console.log("Res", Response.data);
          console.log("====================================");
          setInputValuedd1("");
          setInputValuemm1("");
          setInputValueyear1("");
          setInputValuedd2("");
          setInputValuemm2("");
          setInputValueyear2("");
          setInputValuedd3("");
          setInputValuemm3("");
          setInputValueyear3("");
          GetBenefitData();
          setRequstedmodal(false);
        });
    }
  };

  const openModal2 = () => {
    setIsModalVisible2(true);
  };
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisiblevideo, setIsModalVisiblevideo] = useState(false);

  const [time1, settime1] = useState("");
  const [minit1, setminit1] = useState("");

  const handletime = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 2 && /^\d*$/.test(value)) {
      settime1(value);
    }
    if (value.length == 2) {
      if (value <= 24) {
      } else {
        settime1("");
        setIsModalVisible(true);
        setMsg("Please enter below 24");
      }
    }
  };
  const handleminit = (e) => {
    const value = e.target.value;

    // Limit the input to two characters
    if (value.length <= 2 && /^\d*$/.test(value)) {
      setminit1(value);
    }
    if (value.length == 2) {
      if (value <= 60) {
      } else {
        setminit1("");
        setIsModalVisible(true);
        setMsg("Please enter below 60");
      }
    }
  };

  const timesubmit = () => {
    if (time1 === "" && minit1 === "") {
      setIsModalVisible(true);
      setMsg("Enter time for video call");
    } else if (meetlink === "") {
      setIsModalVisible(true);
      setMsg("Please enter a meeting link");
    } else {
      const param = {
        user_id: new_user_id,
        reward_id: new_reward_id,
        time: `${time1}:${minit1}`,
        google_meeting_link: meetlink,
      };
      console.log(
        "====================================",
        JSON.stringify(param, null, 2)
      );
      axios
        .post(
          "https://www.ravi.host/api/user_partner_video_time",
          qs.stringify(param)
        )
        .then((Response) => {
          console.log("Res", Response.data);
          console.log("====================================");
          GetBenefitData();
          setIsModalVisible2(false);
          settime1("");
          setminit1("");
        });
    }
  };
  const [meetlink, setMeetLink] = useState("");

  const handleMeetLinkChange = (data) => {
    setMeetLink(data);
  };

  const videoRef = useRef(null);
  const videoRef2 = useRef(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const recorderRef = useRef(null);

  const startRecording = () => {
    setRecordingstatus(true);
    setRecordingTime(0);
    // if (videoRef.current) {
    //   const recorder = RecordRTC(videoRef.current.stream, {
    //     type: "video",
    //     mimeType: "video/webm",
    //   });
    //   recorder.startRecording();
    //   recorderRef.current = recorder;
    //   setRecording(true);
    //   setrecordedvideo(false);
    // }
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
        })
        .then((stream) => {
          const recorder = RecordRTC(stream, {
            type: "video",
            mimeType: "video/webm",
          });
          recorder.startRecording();
          recorderRef.current = recorder;
          setRecording(true);
          setrecordedvideo(false);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
          // Handle errors related to accessing media devices
          if (error.name === "NotAllowedError") {
            // Alert the user that they need to allow camera and microphone permissions
            // alert(
            //   "Please allow camera and microphone access to record a video."
            // );
            toast.error(
              "Please allow camera and microphone access to record a video.",
              { theme: "colored" }
            );
          } else if (error.name === "NotFoundError") {
            // Alert the user that no camera or microphone was found
            // alert("No camera or microphone found.");
            toast.error("No camera or microphone found.", { theme: "colored" });
          } else {
            // Alert for generic errors
            // alert("Error accessing media devices. Please try again later.");
            toast.error(
              "Error accessing media devices. Please try again later.",
              { theme: "colored" }
            );
          }
        });
    }
  };

  const togglePlayPause = () => {
    if (videoRef2.current.paused) {
      videoRef2.current.play();
    } else {
      videoRef2.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  // const startRecording = () => {
  //   if (videoRef.current) {
  //     const recorder = RecordRTC(videoRef.current.stream, {
  //       type: "video",
  //       mimeType: "video/webm",
  //     });

  //     recorder.startRecording();
  //     setIsRecording(true);
  //   }
  // };

  const [getrecordedvideo, setrecordedvideo] = useState(false);

  // const stopRecording = () => {
  //   if (videoRef.current) {
  //     const recorder = RecordRTC(videoRef.current.stream, {
  //       type: "video",
  //       mimeType: "video/webm",
  //     });
  //     const blob = recorder.getBlob();
  //     console.log("blob", [blob]);
  //     setRecordedChunks([blob]);
  //     setRecording(false);
  //     // recorder.stopRecording(() => {
  //     //   const blob = recorder.getBlob();
  //     //   setRecordedChunks([blob]);
  //     //   setIsRecording(false);

  //     //   recorder.clearRecordedData();
  //     // });
  //   }
  // };

  const stopRecording = () => {
    setRecordingstatus(false);
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        console.log("blob", blob);
        setRecordedChunks([blob]);
        setRecording(false);
        setrecordedvideo(true);
        // Clear recorded data if needed
        recorderRef.current.clearRecordedData();
      });
    }
  };

  // useEffect(() => {
  //   const startCamera = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       });

  //       if (videoRef.current) {
  //         // Check if videoRef.current is not null or undefined
  //         videoRef.current.srcObject = stream;
  //       }
  //     } catch (error) {
  //       console.error("Error accessing camera:", error);
  //     }
  //   };

  //   startCamera();

  //   return () => {
  //     if (videoRef.current) {
  //       const tracks = videoRef.current.srcObject.getTracks();
  //       tracks.forEach((track) => track.stop());
  //     }
  //   };
  // }, []);

  const showimage = () => {
    console.log(videoRef.current);
  };
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecordingstatus, setRecordingstatus] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (isRecordingstatus) {
      timerInterval = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isRecordingstatus]);

  const resetCountdown = () => {
    setRecordingTime(0);
  };

  // useEffect(() => {
  //   if (recordingTime === 0) {
  //     stopRecording();
  //   }
  // }, [recordingTime]);

  const closemodal = () => {
    setIsModalVisible(false);
  };
  const openmodal = () => {
    setIsModalVisible(true);
  };
  const closemodal1 = () => {
    setconformationmodel(false);
  };
  const openmodal1 = () => {
    setconformationmodel(true);
  };
  const closemodal2 = () => {
    setconformationmodel(false);
  };
  const openmodal2 = () => {
    setconformationmodel(true);
  };
  return (
    <>
      {" "}
      {isloading ? (
        <div className="loader"></div>
      ) : (
        <div className="page_main_wrapp_nw reletive_position">
          <div className="page_base_wrapp_2">
            {/* <NavbarSec name="Request" /> */}
            <div
              className="legacy_dashbord_nav_sec"
              style={{ margin: "10px auto" }}
            >
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>Request</p>
              <div></div>
              {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
            </div>
            <div style={{}} className="bid13_search">
              <div
                className="bids13_user_bid_main"
                style={{ paddingTop: "10px" }}
              >
                <input
                  type="text"
                  placeholder="Search by name"
                  className="bids13_user_bid_search"
                  // value={searchTerm}
                  onChange={(e) => searchFilter_branch(e.target.value)}
                />
                {/* <img src={images.search} alt="" className="search_ic" /> */}
              </div>
            </div>

            <div className="part_set_details">
              {/* Step 3: Use the filtered data with map */}
              {/* {getproducts.length > 0 ? (
            <p className="partner_data_re">No data found</p>
          ) : ( */}
              {getproducts && getproducts.length > 0 ? (
                getproducts.map((item, index) => {
                  return (
                    <div className="part_set_main" key={index}>
                      <img
                        src={item.partner_profile}
                        className="part_set_img"
                        alt=""
                      />
                      <div className="part_set_details_sub">
                        <b className="part_bene_list">{item.partner_name}</b>
                        <p className="part_bene_list">
                          Requested by - {item.username}
                        </p>
                        <p className="part_bene_list">
                          Requested on - {item.claim_time}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {item.is_claim == 3 && item.cat_name != "Video" ? (
                            <button className="part_set_details_sub_btn">
                              Claimed
                            </button>
                          ) : item.cat_name == "Video Message" ||
                            (item.cat_name == "Video" && item.is_claim == 0) ||
                            (item.cat_name == "Video" && item.is_claim == 3) ||
                            (item.cat_name == "Video" &&
                              item.is_claim == 6) ? null : (
                            <button
                              onClick={() => {
                                if (item.is_claim == 1) {
                                  setconformationmodel(true);
                                  setpartnerbenefitsid(item.reward_id);
                                  // PartnerRewardAccept(item.reward_id)
                                }
                              }}
                              className="part_set_details_sub_btn"
                            >
                              {item.is_claim == 1 ? "Confirm" : "Confirm By Us"}
                            </button>
                          )}

                          {item.cat_name ==
                          "Video Message" ? null : item.cat_name == "Video" &&
                            item.is_claim == 0 ? (
                            <button className="part_set_details_sub_btn">
                              Waiting for request
                            </button>
                          ) : item.cat_name == "Video" && item.is_claim == 1 ? (
                            <button
                              onClick={() => {
                                setNew_reward_id(item.reward_id);
                                setNew_partner_id(item.partner_id);
                                setNew_partner_benifit_id(
                                  item.partner_benifit_id
                                );
                                setNew_user_id(item.user_id);
                                setRequstedmodal(true);
                              }}
                              className="part_set_details_sub_btn"
                            >
                              Requested
                            </button>
                          ) : item.cat_name == "Video" && item.is_claim == 2 ? (
                            <button
                              onClick={() => {
                                setNew_reward_id(item.reward_id);
                                setNew_partner_id(item.partner_id);
                                setNew_partner_benifit_id(
                                  item.partner_benifit_id
                                );
                                setNew_user_id(item.user_id);
                                setRequstedmodal(true);
                              }}
                              className="part_set_details_sub_btn"
                            >
                              Date Seted
                            </button>
                          ) : item.cat_name == "Video" && item.is_claim == 3 ? (
                            <button
                              onClick={() => {
                                setNew_reward_id(item.reward_id);
                                setNew_partner_id(item.partner_id);
                                setNew_partner_benifit_id(
                                  item.partner_benifit_id
                                );
                                setNew_user_id(item.user_id);
                                setIsModalVisible2(true);
                              }}
                              className="part_set_details_sub_btn"
                            >
                              Set Time
                            </button>
                          ) : item.cat_name == "Video" && item.is_claim == 6 ? (
                            <button
                              onClick={() => {
                                setNew_reward_id(item.reward_id);
                                setNew_partner_id(item.partner_id);
                                setNew_partner_benifit_id(
                                  item.partner_benifit_id
                                );
                                setNew_user_id(item.user_id);
                                setRequstedmodal_1(true);
                              }}
                              className="part_set_details_sub_btn"
                            >
                              Call Now
                            </button>
                          ) : null}
                          {(item.cat_name == "Video Message" &&
                            item.is_claim == 0) ||
                          (item.cat_name == "Video Message" &&
                            item.is_claim == 1) ? (
                            <button
                              onClick={() => {
                                setNew_reward_id(item.reward_id);
                                setmessage(item.message);
                                setNew_partner_id(item.partner_id);
                                setNew_partner_benifit_id(
                                  item.partner_benifit_id
                                );
                                setNew_user_id(item.user_id);
                                setVideoMassage(item.message);
                                setIsModalVisiblevideo(true);
                              }}
                              className="part_set_details_sub_btn"
                            >
                              Record Video
                            </button>
                          ) : null}
                          {/* <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        style={{ width: "100%" }}
                      /> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="partner_data_re">No data found</p>
              )}
            </div>
            {requstedmodal && (
              <>
                <div className="modal_login" style={{ zIndex: 111 }}>
                  <div className="select_nw_modal">
                    <div className="modal_div1">
                      <img
                        className="forgot_main"
                        src="/static/media/logo.c9b7a18fb586e344723a.png"
                        alt=""
                      />
                    </div>

                    <div className="alert_modal_div">
                      <b>Set date for video call</b>
                      <br />
                      {/* <p className="alert-modal-message">{msg}</p> */}
                      <div className="date_select_new_prt1">
                        <div className="date_select_new_prt">
                          <p className="p_date_inof">Date 1</p>
                          <input
                            type="number"
                            className="select_date_part_in"
                            inputMode="numeric"
                            pattern="[1-9]*"
                            maxLength="2"
                            value={inputValuedd1}
                            placeholder="DD"
                            onChange={(e) => {
                              handleInputChangedate1(e);
                            }}
                          />
                          <span className="p_date_inof1">/</span>
                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="mm"
                            value={inputValuemm1}
                            onChange={(e) => {
                              handleInputChangedatemm1(e);
                            }}
                          />
                          <span className="p_date_inof1">/</span>

                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="yyyy"
                            value={inputValueyear1}
                            onChange={(e) => {
                              handleInputChangedateyear1(e);
                            }}
                          />
                        </div>
                        <div className="date_select_new_prt">
                          <p className="p_date_inof">Date 2</p>
                          <input
                            type="number"
                            className="select_date_part_in"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="2"
                            value={inputValuedd2}
                            placeholder="DD"
                            onChange={(e) => {
                              handleInputChangedate2(e);
                            }}
                          />
                          <span className="p_date_inof1">/</span>
                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="mm"
                            value={inputValuemm2}
                            onChange={(e) => {
                              handleInputChangedatemm2(e);
                            }}
                          />
                          <span className="p_date_inof1">/</span>

                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="yyyy"
                            value={inputValueyear2}
                            onChange={(e) => {
                              handleInputChangedateyear2(e);
                            }}
                          />
                        </div>
                        <div className="date_select_new_prt">
                          <p className="p_date_inof">Date 3</p>
                          <input
                            type="number"
                            className="select_date_part_in"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength="2"
                            value={inputValuedd3}
                            placeholder="DD"
                            onChange={(e) => {
                              handleInputChangedate3(e);
                            }}
                          />
                          <span className="p_date_inof1">/</span>
                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="mm"
                            value={inputValuemm3}
                            onChange={(e) => {
                              handleInputChangedatemm3(e);
                            }}
                          />
                          <span className=" p_date_inof1">/</span>

                          <input
                            type="number"
                            maxLength={2}
                            className="select_date_part_in"
                            placeholder="yyyy"
                            value={inputValueyear3}
                            onChange={(e) => {
                              handleInputChangedateyear3(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <hr className="hr_forgot" /> */}
                    <br />
                    <div className="modal_div">
                      <button
                        className="close_forgot"
                        onClick={() => setRequstedmodal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginLeft: 60 }}
                        className="close_forgot"
                        onClick={() => handleFormSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isModalVisible2 && (
              <div className="modal_login" style={{ zIndex: 111 }}>
                <div className="select_nw_modal">
                  <div className="modal_div1">
                    <img
                      className="forgot_main"
                      src="/static/media/logo.c9b7a18fb586e344723a.png"
                      alt=""
                    />
                  </div>
                  <div className="alert_modal_div">
                    <b>Set Time for video call</b>
                    <br />
                    {/* <p className="alert-modal-message">{msg}</p> */}
                    <div className="date_select_new_prt1">
                      <div className="date_select_new_prt3">
                        <p className="p_date_inof">Time</p>
                        <input
                          type="number"
                          className="select_date_part_in"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength="2"
                          value={time1}
                          placeholder="hh"
                          onChange={(e) => {
                            handletime(e);
                          }}
                        />
                        <span className="p_date_inof1">/</span>
                        <input
                          type="number"
                          className="select_date_part_in"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength="2"
                          value={minit1}
                          placeholder="mm"
                          onChange={(e) => {
                            handleminit(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{}}>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: "var(--font-600) !important",
                        color: "#fff",
                      }}
                    >
                      Meeting Link
                    </p>
                    <div style={{ width: "100%" }}>
                      <input
                        onChange={(e) => handleMeetLinkChange(e.target.value)}
                        placeholder="Enter a meeting link"
                        style={{
                          borderRadius: 45,
                          height: 45,
                          marginTop: 10,
                          paddingLeft: 15,
                          fontSize: 13,
                          fontFamily: "var(--font-600) !important",
                          paddingTop: 10,
                          color: "#fff",
                          borderColor: "#929396",
                          borderWidth: 0.5,
                        }}
                      />
                    </div>
                  </div>
                  {/* <hr className="hr_forgot" /> */}
                  <br />
                  <div className="modal_div">
                    <button
                      className="close_forgot"
                      onClick={() => setIsModalVisible2(false)}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginLeft: 40 }}
                      className="close_forgot"
                      onClick={() => timesubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isModalVisible && (
            <>
              {/* <AlertModal title="" msg={msg} setShowModal={setIsModalVisible} /> */}
              {/* <NewModal
                title=""
                msg={msg}
                setShowModal={openmodal}
                closemodal={closemodal}
              /> */}

              <V2Modal
                canclebtn={false}
                title=""
                msg={msg}
                setShowModal={setIsModalVisible}
                okfunction={closemodal}
              />
            </>
          )}
          {getconformationmodel && (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       {/* <b>Information</b> */}
            //       <br />
            //       <p className="modal-message">
            //         {" "}
            //         Are you sure you want to confirm benefit ?
            //       </p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_two_buton">
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           setconformationmodel(false);
            //         }}
            //       >
            //         Cancel
            //       </button>
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           PartnerRewardAccept();
            //         }}
            //       >
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </div>
            // <ReactModal
            //   isOpen={setconformationmodel}
            //   // onAfterOpen={afterOpenModal}
            //   onRequestClose={closemodal1}
            //   style={customStyles}
            // >
            //   <div className="NewModal_main_wrapp">
            //     <div
            //       style={{
            //         display: "flex",
            //         flexDirection: "column",
            //         alignItems: "center",
            //         justifyContent: "center",
            //       }}
            //     >
            //       <img className="newmodal_logo" src={images.logo} alt="" />
            //       <p className="newmodal_titel"></p>
            //       {/* <p className="newmodal_titel">Alert</p> */}
            //     </div>

            //     {/* <div className="newmodal_des_wrapp"> */}
            //     <p className="newmodal_des">
            //       Are you sure you want to confirm benefit ?
            //     </p>

            //     <div className="modal_two_buton" style={{ width: "100%" }}>
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           setconformationmodel(false);
            //         }}
            //       >
            //         Cancel
            //       </button>
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           PartnerRewardAccept();
            //         }}
            //       >
            //         Okay
            //       </button>
            //     </div>
            //   </div>
            // </ReactModal>

            <V2Modal
              canclebtn={true}
              title=""
              msg="Are you sure you want to confirm benefit ?"
              setShowModal={setconformationmodel}
              okfunction={PartnerRewardAccept}
            />
          )}
          {getuploadModal && (
            // <div className="modal_login">
            //   <div className="modal-content_login">
            //     <div className="modal_div1">
            //       <img className="forgot_main" src={images.logo} alt="" />
            //     </div>
            //     <div className="modal_div">
            //       {/* <b>Information</b> */}
            //       <br />
            //       <p className="modal-message">
            //         Are You sure want to upload this video ?
            //       </p>
            //       <br />
            //     </div>
            //     <hr className="hr_forgot" />
            //     <br />
            //     <div className="modal_two_buton">
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           console.log("new_user_id", new_user_id);
            //           console.log("new_reward_id", new_reward_id);
            //           console.log(
            //             "new_partner_benifit_id",
            //             new_partner_benifit_id
            //           );
            //           console.log("recordedChunks", recordedChunks[0]);
            //           let formData = new FormData();
            //           formData.append("user_id", new_user_id);
            //           formData.append("reward_id", new_reward_id);
            //           formData.append(
            //             "partner_benifit_id",
            //             new_partner_benifit_id
            //           );

            //           formData.append("video", recordedChunks[0]);

            //           setIsLoading(true);

            //           axios
            //             .post(
            //               "https://www.ravi.host/api/update_partner_video_message_reward_claim",
            //               formData
            //             )
            //             .then((Response) => {
            //               setIsLoading(false);
            //               if (Response.data.status == 1) {
            //                 setRecordedChunks([]);
            //                 setrecordedvideo(false);
            //                 setIsModalVisiblevideo(false);
            //                 setUploadModal(false);
            //                 setIsModalVisible(true);
            //                 setMsg(Response.data.message);
            //                 GetBenefitData();
            //               } else {
            //                 setUploadModal(false);
            //                 setIsModalVisible(true);
            //                 setMsg(Response.data.message);
            //                 GetBenefitData();
            //               }
            //             })
            //             .catch((err) => {
            //               setIsLoading(false);
            //               setUploadModal(false);
            //             });
            //         }}
            //       >
            //         Yes
            //       </button>
            //       <button
            //         className="close_forgot"
            //         onClick={() => {
            //           setRecordedChunks([]);
            //           setUploadModal(false);
            //           setIsModalVisiblevideo(false);
            //           setrecordedvideo(false);
            //           // PartnerRewardAccept();
            //         }}
            //       >
            //         No
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <ReactModal
              isOpen={setconformationmodel}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closemodal2}
              style={customStyles}
            >
              <div className="NewModal_main_wrapp">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="newmodal_logo" src={images.logo} alt="" />
                  <p className="newmodal_titel"></p>
                  {/* <p className="newmodal_titel">Alert</p> */}
                </div>

                {/* <div className="newmodal_des_wrapp"> */}
                <p className="newmodal_des">
                  Are You sure want to upload this video ?
                </p>

                <div className="modal_two_buton" style={{ width: "100%" }}>
                  <button
                    className="close_forgot"
                    onClick={() => {
                      console.log("new_user_id", new_user_id);
                      console.log("new_reward_id", new_reward_id);
                      console.log(
                        "new_partner_benifit_id",
                        new_partner_benifit_id
                      );
                      console.log("recordedChunks", recordedChunks[0]);
                      let formData = new FormData();
                      formData.append("user_id", new_user_id);
                      formData.append("reward_id", new_reward_id);
                      formData.append(
                        "partner_benifit_id",
                        new_partner_benifit_id
                      );

                      formData.append("video", recordedChunks[0]);

                      setIsLoading(true);

                      axios
                        .post(
                          "https://www.ravi.host/api/update_partner_video_message_reward_claim",
                          formData
                        )
                        .then((Response) => {
                          setIsLoading(false);
                          if (Response.data.status == 1) {
                            setRecordedChunks([]);
                            setrecordedvideo(false);
                            setIsModalVisiblevideo(false);
                            setUploadModal(false);
                            setIsModalVisible(true);
                            setMsg(Response.data.message);
                            GetBenefitData();
                          } else {
                            setUploadModal(false);
                            setIsModalVisible(true);
                            setMsg(Response.data.message);
                            GetBenefitData();
                          }
                        })
                        .catch((err) => {
                          setIsLoading(false);
                          setUploadModal(false);
                        });
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="close_forgot"
                    onClick={() => {
                      setRecordedChunks([]);
                      setUploadModal(false);
                      setIsModalVisiblevideo(false);
                      setrecordedvideo(false);
                      // PartnerRewardAccept();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </ReactModal>
          )}

          {isModalVisiblevideo && (
            <div className="modal_login benefit_videomodal_wrapp">
              <div className="benefit_video_back_wrapp">
                <img
                  src={images.back}
                  // className="nav_back_btn"
                  alt=""
                  onClick={() => {
                    setRecordedChunks([]);
                    setrecordedvideo(false);
                    setIsModalVisiblevideo(false);
                  }}
                  className="benfit_video_back_btn"
                />
                {recordedChunks.length > 0 && (
                  <button
                    onClick={() => {
                      setUploadModal(true);
                    }}
                    style={{
                      color: "var(--color-main)",
                      fontSize: "16px",
                      fontFamily: "var(--font-600)",
                    }}
                  >
                    Upload
                  </button>
                )}
              </div>

              {getrecordedvideo ? (
                <div>
                  {recordedChunks.length > 0 && (
                    <div className="recourd_video_wrapp">
                      <div>
                        {/* <video
                      ref={videoRef2}
                      style={{ width: "300px", height: "500px" }}
                      // controls
                      // onClick={handleVideoClick}
                      // onEnded={handleVideoEnded}
                      src={URL.createObjectURL(recordedChunks[0])}
                    ></video> */}
                        <video
                          ref={videoRef2}
                          controls={false}
                          // controls
                          style={{ width: "300px", height: "500px" }}
                        >
                          <source
                            src={URL.createObjectURL(recordedChunks[0])}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <div></div>
                      <div className="recourd_video_btns">
                        {" "}
                        <>
                          <button
                            style={{
                              fontFamily: "var(--font-600)",
                              color: "var(--color-main)",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              setRecordedChunks([]);
                              // setIsModalVisiblevideo(false);
                              setrecordedvideo(false);
                            }}
                          >
                            Retake
                          </button>
                          {/* <button onClick={stopRecording}>Stop Recording</button> */}
                          <img
                            src={images.play_white}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "contain",
                              marginLeft: "46px",
                            }}
                            onClick={togglePlayPause}
                          />
                        </>
                      </div>
                      <div className="recourd_text_wrapping">
                        <p>{getvideoMassage}</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="recourd_warning_text_wrapping">
                    <p>
                      Hello Partner, kindly read the following lines and record
                      a video to complete the video recording journey with the
                      buyer.
                    </p>
                  </div>
                  <div className="recourd_video_wrapp">
                    <div style={{ height: "50vh" }}>
                      <Webcam
                        // videoConstraints={videoConstraints}
                        videoConstraints={{ facingMode: "user" }}
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: "500px",
                        }}
                        height={500}
                        // audio={true}
                        ref={videoRef}
                        mirrored={true}
                      />
                    </div>
                    <div></div>
                    <div className="recourd_video_btns">
                      {recording ? (
                        <>
                          <p
                            style={{
                              fontFamily: "var(--font-400)",
                              color: "white",
                              fontSize: "12px",
                            }}
                          >
                            {recordingTime} sec
                          </p>
                          {/* <button onClick={stopRecording}>Stop Recording</button> */}
                          <img
                            src={images.pause_white}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "contain",
                              marginLeft: "60px",
                            }}
                            onClick={stopRecording}
                          />
                        </>
                      ) : (
                        <>
                          {/* <img
                        src={images.rotate_camera}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "contain",
                        }}
                        onClick={switchCamera}
                      /> */}
                          <img
                            src={images.camera_new}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "contain",
                              marginLeft: "105px",
                            }}
                            onClick={startRecording}
                          />
                        </>
                      )}
                    </div>

                    <div className="recourd_text_wrapping">
                      <p>{getvideoMassage}</p>
                      {/* <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Eum atque eius provident doloremque at quae inventore
                        ratione optio quas nesciunt impedit, quo animi,
                        aspernatur facilis, expedita assumenda aliquam nulla
                        praesentium consequatur quasi odio quaerat voluptatum.
                        Rerum perspiciatis, iusto nulla magni itaque, sint porro
                        reiciendis enim praesentium hic, esse officia?
                      </p> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Partnerbenefits;
