import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  GET_INTERESTS_FAIL,
  GET_INTERESTS_BEGIN,
  GET_INTERESTS_SUCCESS,
} from "../Action";
import { get_all_interest } from "../utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import user_interest from "../reducer/nfb_interests_reducer";

const initialState = {
  get_interest_loading: false,
  get_interest_data: [],
};

const UserInterestcontext = React.createContext();
export const UserInterestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(user_interest, initialState);
  // register

  const get_interest = async () => {
    dispatch({ type: GET_INTERESTS_BEGIN });
    try {
      const response = await axios.get(get_all_interest);
      const registerdata = response.data;
      console.log("====iii", response.data);
      if (registerdata.status == 1) {
        dispatch({ type: GET_INTERESTS_SUCCESS, payload: registerdata });
      }
    } catch (error) {
      dispatch({ type: GET_INTERESTS_FAIL });
    }
  };

  return (
    <UserInterestcontext.Provider
      value={{
        ...state,
        get_interest,
      }}
    >
      {children}
    </UserInterestcontext.Provider>
  );
};

export const useInterestContext = () => {
  return useContext(UserInterestcontext);
};
