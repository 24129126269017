import React, { useEffect, useState } from "react";
import "./ClubListOfBankAccounts.css";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import images from "../../constants/images";
import { Link, useLocation } from "react-router-dom";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const ClubListOfBankAccounts = () => {
  const location = useLocation();
  const userId = localStorage.getItem("user_id");
  const [getcusmodal, setcusmodal] = useState(false);
  const [getcusmsg, setcusmsg] = useState("");
  const [getallAccounts, setAllAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setAllAccounts([]);
    setIsLoading(false);
    const param = {
      user_id: userId,
      club_id: location.state.club_id,
    };

    await axios
      .post(
        "https://www.ravi.host/api/get_club_bank_account",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(true);

        if (Response.data.status == 1) {
          setAllAccounts(Response.data.result);
        } else {
        }
      })
      .catch((err) => {
        console.log("errr", err);
        setIsLoading(false);
      });
  };

  const setPrimary = async (user_bank_detail_id) => {
    setIsLoading(true);

    const param = {
      user_bank_detail_id: user_bank_detail_id,
      user_id: userId,
      club_id: location.state.club_id,
    };
    // console.log('param', param);

    await axios
      .post(
        "https://www.ravi.host/api/user_select_bank_account",
        qs.stringify(param)
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          getData();
        } else {
          setcusmsg(Response.data.message);
          getcusmodal(true);
        }
      })
      .catch((err) => {
        console.log("errr", err);
        setIsLoading(false);
      });
  };

  const closemodal = () => {
    setcusmodal(false);
  };
  const openmodal = () => {
    setcusmodal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2" style={{ position: "relative" }}>
        <NavbarSec name=" Bank Accounts" />

        <div className="accounts_main_wrapp">
          {getallAccounts && getallAccounts.length > 0 ? (
            getallAccounts.map((item, index) => {
              return (
                <div className="accounts_single_card">
                  <p className="account_bank_heading"> {item.bank_name}</p>
                  <p className="account_bank_des">
                    Bank Holder name :- {item.bank_holder_name}
                  </p>
                  <p className="account_bank_des">
                    Account number :- {item.bank_account_number}
                  </p>
                  <p className="account_bank_des">
                    IIFC code :- {item.ifsc_code}
                  </p>
                  <div className="account_primary_btn_wrapp">
                    <div
                      className="bondbox_checkbox"
                      style={{ width: "100%", marginTop: "4px" }}
                    >
                      <input
                        type="checkbox"
                        className="check_box_new"
                        style={{
                          margin: "0px",
                          borderColor: "var(--color-main)",
                        }}
                        //   value={item.is_primary}
                        //   selected={item.is_primary == 1}
                        onClick={() => {
                          setPrimary(item.club_bank_detail_id);
                        }}
                        id="myCheckbox"
                        checked={item.is_primary == 1}
                        //   onChange={handleCheckboxChange}
                      />
                      <span
                        className="radio_btn_name"
                        onClick={() => {
                          // settermsmodal(true);
                        }}
                        style={{ fontFamily: "var(--font-600)" }}
                      >
                        Primary
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: "90%",
                margin: "40px auto",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "var(--font-600)",
                  color: "white",
                  marginBottom: "10px",
                }}
              >
                No bank account found{" "}
              </p>

              <p
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontFamily: "var(--font-300)",
                }}
              >
                Please click on "+" and add a new account
              </p>
            </div>
          )}
        </div>
        <Link
          style={{}}
          to="/clubBankaccount"
          state={{ club_id: location.state.club_id }}
          className="account_btn_wrapp"
        >
          <img src={images.plus} alt="" />
        </Link>
        {getcusmodal && (
          // <AlertModal
          //   setShowModal={setcusmodal}
          //   // title={"Success"}
          //   msg={getcusmsg}
          // />

          // <NewModal
          //   title=""
          //   msg={getcusmsg}
          //   setShowModal={openmodal}
          //   closemodal={closemodal}
          // />

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setcusmodal}
            okfunction={closemodal}
          />
        )}
        {/* <button className="account_btn_wrapp">
          <img src={images.plus} alt="" />
        </button> */}
      </div>
    </div>
  );
};

export default ClubListOfBankAccounts;
