import React from "react";
import "./ShowAllFamilyMember.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa6";
import images from "../../constants/images";
import { useEffect } from "react";
import { useProfileContext } from "../../context/profile_context";
import axios from "axios";
import { delete_user_family } from "../../utils/Constant";

const ShowAllFamilyMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getalldata, setAllData] = useState([]);
  const [getloading, setLoading] = useState(false);
  const { getProfile } = useProfileContext();

  const GetProfile = async () => {
    setLoading(true);
    const param = {
      target_user_id: location.state.target_user_id,
      user_id: location.state.user_id,
    };
    console.log("param -->", param);

    const data = await getProfile(param);
    console.log("profile data ->", data.result);

    setAllData(data.result.user_family);
    setLoading(false);
  };

  const DeleteFamilyMamber = async (id) => {
    setLoading(true);
    const param = {
      user_family_id: id,
    };

    await axios
      .post(delete_user_family, param)
      .then((res) => {
        setLoading(false);
        if (res.data.status == 1) {
          GetProfile();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetProfile();
  }, []);

  return (
    <div className="new_wrapp_container reletive_position">
      {getloading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          <div
            className="play_screen_main_wrapp"
            style={{ padding: "10px 5%" }}
          >
            <div className="legacy_dashbord_nav_sec">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p
                style={{ fontsize: "18px", width: "60%", textAlign: "center" }}
              >
                You have {getalldata && getalldata.length} family members added
              </p>
              <div></div>
            </div>

            {/* all member start */}

            <div className="all_family_member_wrapp">
              {getalldata && getalldata.length > 0
                ? getalldata.map((item, index) => {
                    return (
                      <div className="single_family_member_card">
                        <img src={item.profile} alt="" />
                        <p>{item.firstname}</p>
                        <span>Added on {item.date}</span>
                        {location.state.target_user_id ==
                          location.state.user_id && (
                          <button
                            onClick={() => {
                              DeleteFamilyMamber(item.user_family_id);
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    );
                  })
                : null}
            </div>

            {/* all member end */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowAllFamilyMember;
