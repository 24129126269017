import React, { useState } from "react";
import "../FeedNewFrame1.css";
import { useSwipeable } from "react-swipeable";
import images from "../../constants/images";
import axios from "axios";
import qs from "qs";
import { release_bond_like } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";

const NewFrame9 = ({
  item,
  setIsModalOpen,
  setMsg,
  setModalName,
  like_btn,
  windowWidth,
}) => {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = useState(false);
  const [getLiked, setLiked] = useState(item.bond_like == 0 ? false : true);

  // const handleSwipe = (eventData) => {
  //   const numFlips = 155; // Number of times to flip the card
  //   let flipped = isFlipped;
  //   for (let i = 0; i < numFlips; i++) {
  //     flipped = !flipped;
  //     setIsFlipped(flipped);
  //   }
  //   if (eventData.dir === "Left") {
  //     setIsFlipped(false); // Flip to the front side
  //   } else if (eventData.dir === "Right") {
  //     setIsFlipped(true); // Flip to the back side
  //   }
  // };

  const handleSwipe = () => {
    setIsFlipped(!isFlipped);
  };
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  // like api start
  const userId = localStorage.getItem("user_id");

  const BondLike = async (release_bond_id, user_id, is_nfb) => {
    const param = {
      release_bond_id: release_bond_id,
      user_id: userId,
      is_nfb: is_nfb,
      product_name: item.product_name,
    };

    try {
      const response = await axios.post(release_bond_like, qs.stringify(param));
      console.log("response.data", response.data);
      // fetchData();
      // window.location.reload();
      setLiked(!getLiked);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // like api end

  const getImageSrc = (mediaList) => {
    if (!mediaList) return null;

    // Filter for 'new' images first
    let filtered = mediaList.filter((media) => media.image_type === "new");
    if (filtered.length > 0) {
      return filtered.map((media) => media.img_video)[0] || null;
    }

    // Fallback to 'old' images if no 'new' images are found
    filtered = mediaList.filter((media) => media.image_type === "old");
    if (filtered.length > 0) {
      return filtered.map((media) => media.img_video)[0] || null;
    }

    // Further fallback to images with 'type' as 'image'
    filtered = mediaList.filter((media) => media.type === "image");
    return filtered.map((media) => media.img_video)[0] || null;
  };

  return (
    <div className="blue-card-main-wrapp">
      <div
        className={`flip-container ${isFlipped ? "flipped" : ""}`}
        {...swipeHandlers}
      >
        {windowWidth > 786 && (
          <div
            onClick={() => setIsFlipped(!isFlipped)}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              top: "-9.5%",
              position: "absolute",
              right: "13px",
            }}
          >
            <img
              src={images.flip_icon}
              style={{ width: "30px", height: "30px", alignSelf: "flex-end" }}
            />
          </div>
        )}
        <div className="flipper">
          <div className="front">
            <img
              src={images.new_frame_9_f}
              className="frame-img"
              alt=""
              onClick={() => {
                navigate("/productview", {
                  state: {
                    release_bond_id: item.release_bond_id,
                    product_id: item.product_id,
                    is_nfb: item.is_nfb,
                    uniq_code: item.uniq_code,
                    theme_index: item.theme_index,
                    share_by_user: item.share_by_user,
                    club_id: item.club_id,
                  },
                });
              }}
            />
            <img
              src={
                item.product_image_video
                  ? getImageSrc(item.product_image_video)
                  : getImageSrc(item.bond_media_upload)
              }
              className="inner-img"
              alt="product img"
            />
            <div className="front-card-del-qr">
              <div className="front-card-del-div">
                <div className="front-card-del">
                  <p>
                    {item.product_name.slice(0, 24)}
                    {item.product_name.length > 24 ? "..." : null}
                  </p>
                  <p>
                    By : {item.owner_name.slice(0, 20)}{" "}
                    {item.owner_name.length > 20 ? "..." : null}
                  </p>
                  <p>Product Value : ₹{item.product_value}</p>
                </div>
              </div>
              <div className="front-card-qr">
                <div className="qr-circle">
                  <img src={item.qr_code} alt="" />
                </div>
              </div>
            </div>
            {/* like and share buttons start */}
            {like_btn && (
              <div
                className="feed_btn_div"
                style={{ position: "absolute", bottom: "-10%" }}
              >
                <button
                  className="feed_btn_div_like"
                  onClick={() =>
                    BondLike(item.release_bond_id, item.user_id, item.is_nfb)
                  }
                  style={{
                    color: getLiked ? "#e51a4b" : "gray",
                  }}
                >
                  <img
                    src={getLiked ? images.like_true : images.like}
                    className="images_feed_btn"
                    alt=""
                  />
                  Like
                </button>

                {item.user_profile_video == "" ? null : (
                  <button
                    className="feed_btn_div_like"
                    onClick={() =>
                      navigate("/FeedVideo", {
                        state: {
                          videoplay: item.user_profile_video,
                        },
                      })
                    }
                    style={{
                      color: "gray",
                    }}
                  >
                    <img
                      src={images.video_user}
                      className="images_feed_btn"
                      alt=""
                      style={{
                        width: "35px",
                        height: "35px",
                        objectFit: "contain",
                      }}
                    />
                    Creator
                  </button>
                )}

                {item.is_user_owner == 0 ? (
                  <button
                    onClick={() => {
                      navigate("/placebid", {
                        state: {
                          is_club: item.is_club,
                          is_globle: item.is_globle,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                          accept_bond_bid_id: item.accept_bond_bid_id,
                          release_bond_id: item.release_bond_id,
                          current_highest_bid: item.current_highest_bid,
                          uniq_code: item.uniq_code,
                          is_nfb: item.is_nfb,
                          currenbt_stock: item.total_available_bond_bid,
                          minimum_bid_amount: item.minimum_bid_amount,
                          product_id: item.product_id,
                          partner_bond_benifit_data:
                            item.partner_bond_benifit_data.length,
                          product_name: item.product_name,
                          is_club_member: item.is_club_member,
                          is_club_id: item.is_club_id,
                          join_club_product: item.join_club_product,
                          is_auction: 0,
                          is_place_bid: 0,
                        },
                        //location.state.partner_bond_benifit_data
                      });
                    }}
                    className="bid_feed_btn"
                  >
                    <img src={images.Bids} className="images_feed_btn" alt="" />
                    Bid
                  </button>
                ) : null}
              </div>
            )}
            {/* like and share buttons end */}
          </div>
          <div className="back">
            <img src={images.new_frame_9_b} className="frame-img" alt="" />
            {/* <img
              src={
                item.product_image_video
                  ? item.product_image_video[
                      item.product_image_video.length - 1
                    ].img_video
                  : item.bond_media_upload &&
                    item.bond_media_upload[item.bond_media_upload.length - 1]
                      .img_video
              }
              className="inner-img"
              alt=""
            /> */}
            <div className="back-card-del-div">
              {item.description && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product Description</p>
                  <p className="des-content">
                    {item.description.slice(0, 78)}{" "}
                    {item.description.length > 78 && "..."}
                  </p>
                  {item.description.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.description);
                        setModalName("Product Description");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {item.product_history && (
                <div style={{ width: "100%", marginBottom: "10px" }}>
                  <p className="des-name">Product story</p>
                  <p className="des-content">
                    {item.product_history.slice(0, 78)}{" "}
                    {item.product_history.length > 78 && "..."}
                  </p>
                  {item.product_history.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.product_history);
                        setModalName("Product Story");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </div>
              )}

              {item.product_highlights && (
                <>
                  <p className="des-name">Product Highlight</p>
                  <p className="des-content">
                    {item.product_highlights.slice(0, 78)}{" "}
                    {item.product_highlights.length > 78 && "..."}
                  </p>
                  {item.product_highlights.length > 78 && (
                    <button
                      onClick={() => {
                        setIsModalOpen(true);
                        setMsg(item.product_highlights);
                        setModalName("Product Highlight");
                      }}
                      className="fram_read_btn"
                    >
                      Read more
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="owner-name-div">
              <p className="owned-by">Owned by</p>
              <p className="owner-name">{item.username}</p>
            </div>
            <div className="back-card-del-qr">
              <div className="back-card-del-div2">
                <div className="back-card-del">
                  <p>{item.product_name}</p>
                  <p>By : {item.owner_name}</p>
                  <p>rarerez Value : ₹{item.minimum_bid_amount}</p>
                </div>
              </div>
              <div className="back-card-qr">
                <div className="qr-circle">
                  <img src={item.qr_code} alt="" />
                </div>
              </div>
            </div>

            {/* like and share buttons start */}
            {like_btn && (
              <div
                className="feed_btn_div"
                style={{ position: "absolute", bottom: "-10%" }}
              >
                <button
                  className="feed_btn_div_like"
                  onClick={() =>
                    BondLike(item.release_bond_id, item.user_id, item.is_nfb)
                  }
                  style={{
                    color: getLiked ? "#e51a4b" : "gray",
                  }}
                >
                  <img
                    src={getLiked ? images.like_true : images.like}
                    className="images_feed_btn"
                    alt=""
                  />
                  Like
                </button>

                {item.bond_video == "" ? null : (
                  <button
                    className="feed_btn_div_like"
                    onClick={() =>
                      navigate("/FeedVideo", {
                        state: {
                          videoplay: item.bond_video,
                        },
                      })
                    }
                    style={{
                      color: "gray",
                    }}
                  >
                    <img
                      src={images.video_product}
                      className="images_feed_btn"
                      alt=""
                      style={{
                        width: "35px",
                        height: "35px",
                        objectFit: "contain",
                      }}
                    />
                    Asset
                  </button>
                )}

                {item.is_user_owner == 0 ? (
                  <button
                    onClick={() => {
                      navigate("/placebid", {
                        state: {
                          is_club: item.is_club,
                          is_globle: item.is_globle,
                          share_by_user: item.share_by_user,
                          club_id: item.club_id,
                          accept_bond_bid_id: item.accept_bond_bid_id,
                          release_bond_id: item.release_bond_id,
                          current_highest_bid: item.current_highest_bid,
                          uniq_code: item.uniq_code,
                          is_nfb: item.is_nfb,
                          currenbt_stock: item.total_available_bond_bid,
                          minimum_bid_amount: item.minimum_bid_amount,
                          product_id: item.product_id,
                          partner_bond_benifit_data:
                            item.partner_bond_benifit_data.length,
                          product_name: item.product_name,
                          is_club_member: item.is_club_member,
                          is_club_id: item.is_club_id,
                          join_club_product: item.join_club_product,
                          is_auction: 0,
                          is_place_bid: 0,
                        },
                        //location.state.partner_bond_benifit_data
                      });
                    }}
                    className="bid_feed_btn"
                  >
                    <img src={images.Bids} className="images_feed_btn" alt="" />
                    Bid
                  </button>
                ) : null}
              </div>
            )}
            {/* like and share buttons end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFrame9;
