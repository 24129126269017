import React, { useEffect, useRef } from "react";
import "./LagecyDashbord.css";
import images from "../../constants/images";
import { useState } from "react";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";
import qs from "qs";
import {
  add_joyride,
  get_legacy_milestone,
  get_user_joyride,
  get_user_legacy,
  get_user_year_list,
  get_year_bond,
} from "../../utils/Constant";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import "react-horizontal-scrolling-menu/dist/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { useProfileContext } from "../../context/profile_context";
import V2Modal from "../../common/v2modal/V2Modal";
import { RiHome2Line } from "react-icons/ri";
import CustomJoyride from "../../utils/CustomJoyride";
import ReactJoyride from "react-joyride";

const steps = [
  {
    target: ".legacy_joy_1",
    content: "it's show platform total assets and total assets value",
    disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".legacy_joy_2",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".legacy_joy_3",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
  {
    target: ".legacy_joy_4",
    content: "it's show platform total assets and total assets value",
    // disableBeacon: true,

    spotlightPadding: 20,
  },
];

const LagecyDashbord = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const [getloading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [getlegacyId, setLegacyId] = useState(null);
  const sliderRef = useRef(null);
  const centerBoxRef = useRef(null);
  const { edit_profile_data, EditProfile } = useProfileContext();
  const [getallLegacyYear, setAllLegacyYear] = useState([]);
  const [getcusmodal, setcusmodal] = useState(
    location.state &&
      location.state.first_time_popup &&
      location.state.first_time_popup == 1
      ? true
      : false
  );
  const [gethomepopup, sethomepopup] = useState(
    location.state &&
      location.state.first_time_home &&
      location.state.first_time_home == 1
      ? true
      : false
  );
  const [run, setRun] = useState(false);

  const GetProfile = async () => {
    setLoading(true);
    const param = {
      user_id: userId,
    };

    const data = await EditProfile(param);
    console.log("data 111", data.result);
  };
  const closemodal = () => {
    setcusmodal(false);
  };
  const GetYears = async () => {
    setLoading(true);
    const parama = {
      user_id: userId,
    };
    await axios
      .post(get_user_year_list, parama)
      .then((res) => {
        setLoading(false);
        if (res.data.status == 1) {
          // const storedYear = localStorage.getItem("selectedYear");
          // const initialYear = storedYear || res.data.legacy_year[0];
          // setSelectedYear(initialYear);
          setAllLegacyYear(res.data.legacy_year);
          setSelectedYear(res.data.legacy_year[0]);
          setLegacyId(res.data.legacy_id);
          GetMilestones(res.data.legacy_year[0]);
          GetRarerez(res.data.legacy_year[0]);
          // if (sliderRef.current) {
          //   const yearElement = document.getElementById(`year-${initialYear}`);
          //   if (yearElement) {
          //     yearElement.scrollIntoView({
          //       behavior: "smooth",
          //       inline: "center",
          //     });
          //   }
          // }
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedYear && sliderRef.current) {
      const yearElement = document.getElementById(`year-${selectedYear}`);
      if (yearElement) {
        yearElement.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [selectedYear]);

  useEffect(() => {
    GetProfile();
    GetYears();
  }, []);

  const handleScroll2 = () => {
    const slider = sliderRef.current;
    const centerBox = centerBoxRef.current;
    if (!slider || !centerBox) return;

    const sliderRect = slider.getBoundingClientRect();
    const centerBoxRect = centerBox.getBoundingClientRect();

    const sliderCenterX = sliderRect.left + sliderRect.width / 2;
    let closestYear = null;
    let minDistance = Infinity;

    let selectedYearData = null;

    getallLegacyYear.forEach((yearData) => {
      const year = yearData.legacy_year;
      const id = yearData.legacy_id;
      const yearElement = document.getElementById(`year-${id}`);
      if (yearElement) {
        const yearRect = yearElement.getBoundingClientRect();
        const yearCenterX = yearRect.left + yearRect.width / 2;
        const distance = Math.abs(sliderCenterX - yearCenterX);
        if (distance < minDistance) {
          minDistance = distance;
          closestYear = year;
          selectedYearData = { year, id };
        }
      }
    });

    const selectedYear = selectedYearData?.year || null;
    const selectedId = selectedYearData?.id || null;
    setSelectedYear(selectedYear);
    console.log("Selected Year:", selectedYear);
    console.log("Selected Id:", selectedId);
  };

  const handleScroll = () => {
    const slider = sliderRef.current;
    if (!slider) return;

    const sliderRect = slider.getBoundingClientRect();
    const sliderCenterX = sliderRect.left + sliderRect.width / 2;
    let closestYear = null;
    let minDistance = Infinity;

    const years = slider.querySelectorAll(".year");
    years.forEach((year) => {
      const yearRect = year.getBoundingClientRect();
      const yearCenterX = yearRect.left + yearRect.width / 2;
      const distance = Math.abs(sliderCenterX - yearCenterX);
      if (distance < minDistance) {
        minDistance = distance;
        closestYear = year;
      }
    });

    const selectedYear = closestYear?.textContent || null;
    setSelectedYear(selectedYear);
    GetRarerez(selectedYear);
    GetMilestones(selectedYear); // Automatically fetch milestones for the selected year
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("scroll", handleScroll);
    }
    handleScroll(); // Initial selection
    return () => {
      if (slider) {
        slider.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const Years = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
  ];

  const GetLegacy = async () => {
    setLoading(true);
    const parama = {
      user_id: userId,
    };
    await axios
      .post(get_user_legacy, parama)
      .then((res) => {
        setLoading(false);
        if (res.data.status == 1) {
          setAllLegacyYear(res.data.legacy_data);
          res.data.legacy_data &&
            res.data.legacy_data.slice(0, 1).map((item, index) => {
              GetMilestones(item.legacy_id);
              GetRarerez(item.legacy_id);
              setLegacyId(item.legacy_id);
              setSelectedYear(item.legacy_year);
              // console.log(item.legacy_id);
            });
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const [milestonedata, setmilestonedata] = useState([]);

  const GetMilestones = async (year) => {
    // setLoading(true);
    const parama = {
      legacy_year: year,
      user_id: userId,
      is_login: 1,
    };
    await axios
      .post(get_legacy_milestone, parama)
      .then((res) => {
        setLoading(false);
        console.log("result", res.data.milestone_data);
        if (res.data.status == 1) {
          setmilestonedata(res.data.milestone_data);
        } else {
          toast.error(res.data.message, {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const [getyeararray, setyeararray] = useState([]);
  const GetRarerez = async (year) => {
    // setLoading(true);
    const param = {
      year: year,
      target_user_id: userId,
    };

    console.log("param --==-->", param);
    await axios
      .post(get_year_bond, qs.stringify(param))
      .then((res) => {
        // setLoading(false);
        if (res.data.status === 1) {
          console.log("res.data-getallyeae-->11", res.data);
          setyeararray(res.data.result);
        } else {
          console.log("product view err ->", res.data);
          // setIsModalVisible(true);
          // setMsg(res.data.message);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  // useEffect(() => {
  //   GetLegacy();
  // }, []);

  const [scrollIndex, setScrollIndex] = useState(0);

  useEffect(() => {
    const selectedIndex = Years.findIndex((year) => year === selectedYear);
    if (selectedIndex !== -1) {
      setScrollIndex(selectedIndex);
    }
  }, [selectedYear, Years]);

  const handleSelect = (year) => {
    setSelectedYear(year);
  };

  const handleUpdate = ({ translate }) => {
    const index = Math.round(Math.abs(translate) / 100); // Adjust 100 based on your item width
    setScrollIndex(index);
  };

  const yearItems = Years.map((year) => (
    <div
      key={year}
      className={`year ${year === selectedYear ? "selected" : ""}`}
      onClick={() => handleSelect(year)}
    >
      {year}
    </div>
  ));

  // JOYRIDE function start
  useEffect(() => {
    // const hasSeenTour = localStorage.getItem("hasSeenTour");
    // if (!hasSeenTour) {
    //   setRun(true);
    // }

    const param = { user_id: userId };
    const existingNames = ["legacy_joyride"];

    axios
      .post(get_user_joyride, qs.stringify(param))
      .then((response) => {
        const data = response.data;

        if (data && data.status === 1 && Array.isArray(data.joyride)) {
          const hasExistingNames = data.joyride.some((item) => {
            console.log("Item received from API:", item);
            return existingNames.includes(item);
          });

          if (hasExistingNames) {
            setRun(false);
          } else {
            setRun(true);
          }
        } else {
          setRun(true); // Default to true if no data or incorrect format
        }
      })
      .catch((error) => {
        setRun(false); // Optionally set run to false in case of an error
      });
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // localStorage.setItem("hasSeenTour", "true");
      // setRun(false);

      const param = {
        user_id: userId,
        joyride_name: "legacy_joyride",
      };

      axios.post(add_joyride, qs.stringify(param)).then((Response) => {
        const data = Response.data;
        if (data.status == 1) {
          setRun(false);
        }
      });
    }
  };
  // JOYRIDE function end

  return (
    <>
      {/* {!getloading && (
        <ReactJoyride
          steps={steps}
          run={run}
          continuous={true}
          showProgress={false}
          callback={handleJoyrideCallback}
          disableScrolling={true}
          disableOverlayClose={true}
          styles={{
            options: {
              zIndex: 10000,
            },
            spotlight: {
              // backgroundColor: "transparent",
            },
          }}
        />
      )} */}
      <div
        className="new_wrapp_container reletive_position"
        style={{ maxWidth: "var(--max-width-sm)", margin: "0px auto" }}
      >
        <img
          src={images.new_bg_circule}
          alt=""
          className="back_circule_shadow_left"
        />
        {getloading ? (
          <NewLoader />
        ) : (
          <div className="new_container">
            {/* <CustomJoyride
            steps={steps}
            run={run}
            showProgress={false}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                zIndex: 100000,
                // primaryColor: "var(--color-main)",
              },

              spotlight: {
                backgroundColor: "transparent",
              },
            }}
          /> */}
            <div className="play_screen_main_wrapp">
              <div className="legacy_dashbord_nav_sec">
                <button
                  onClick={() => {
                    navigate("/Usermenu");
                  }}
                >
                  <FaAngleLeft color="var(--color-white)" size={20} />
                </button>

                <p>Legacy</p>
                <img
                  onClick={() => {
                    navigate("/NewCommunityRequest");
                  }}
                  src={images.new_notification_fill}
                  alt=""
                />
              </div>
            </div>

            <div className="legacy_dashbord_sec1">
              <button
                onClick={() => {
                  navigate("/MyCommunity", {
                    state: {
                      legacy_id: getlegacyId,
                    },
                  });
                }}
                className="legacy_dashbord_sec1_create_cumunity legacy_joy_1"
              >
                <img src={images.round_plus} alt="" />
                <p>Community</p>
              </button>
              <div className="legacy_dashbord_sec1_user_wrapp">
                <Link to="/UserProfile" state={{ user_id: userId }}>
                  <img src={edit_profile_data.profile} alt="" />
                </Link>
              </div>
              <button
                onClick={() => {
                  navigate("/MyFamily", {
                    state: {
                      legacy_id: getlegacyId,
                    },
                  });
                }}
                className="legacy_dashbord_sec1_create_cumunity legacy_joy_2"
              >
                <img src={images.round_plus} alt="" />
                <p>Family</p>
              </button>
            </div>

            <div className="legacy_artist_name_wrapp">
              <h2>{edit_profile_data.username}</h2>
              <p>Artist</p>
            </div>

            {/* year slider start */}
            <div className="legacy_years_list_wrapp legacy_joy_3">
              <button className="nav-button left" onClick={scrollLeft}>
                <FaAngleLeft color="var(--color-main)" size={22} />
              </button>
              <div
                className="year-slider"
                ref={sliderRef}
                // style={{ alignItems: "center", justifyContent: "center" }}
              >
                {getallLegacyYear.map((year, yearIndex) => {
                  return (
                    <button
                      className={`year
                  `}
                      id={`year-${year}`}
                      key={year}
                      onClick={() => {
                        // localStorage.setItem("selectedYear", year);
                        GetMilestones(year);
                        GetRarerez(year);
                        setSelectedYear(year);
                        console.log(
                          "year",
                          `year ${selectedYear == year ? "selected" : ""}`
                        );
                      }}
                      style={{
                        textShadow:
                          selectedYear == year
                            ? "0px -1px #eb0546, -1px -1px 6px #ff004c,0 3px #ff004c,  0 -3px #130c0e, 1px 1px #130c0e, -1px -1px #130c0e, 1px -1px #130c0e, -1px 1px #130c0e"
                            : "0px 0px #000000",
                        color:
                          selectedYear == year ? "var(--color-white)" : "#555",
                        fontSize: selectedYear == year ? "1.6rem" : "1rem",
                      }}
                    >
                      {year}
                    </button>
                  );
                })}
              </div>
              <button
                className="nav-button right"
                onClick={scrollRight}
                style={{ justifyContent: "flex-end" }}
              >
                <FaAngleRight color="var(--color-main)" size={22} />
              </button>
            </div>
            {/* year slider end */}

            {/* milestone buttons start */}

            <div className="legacy_milstone_btn_wrapp">
              <div
                onClick={() => {
                  navigate("/CreateMilestone", {
                    state: {
                      legacy_year: selectedYear,
                    },
                  });
                }}
                className="legacy_milstone_btn1"
              >
                Milestones
              </div>
              <button
                className="legacy_milstone_btn1 legacy_joy_4"
                style={{ padding: "10px", width: "50px" }}
                onClick={() => {
                  navigate("/CreateMilestone", {
                    state: {
                      legacy_year: selectedYear,
                    },
                  });
                }}
              >
                <FaPlus color="#fff" size="20" />
              </button>
            </div>

            {/* milestone buttons end */}

            {/* miestone cards start */}

            {milestonedata && milestonedata.length == 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontFamily: "var(--font-600)",
                    color: "var(--color-white)",
                  }}
                >
                  No Milestone found
                </p>
                <button
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "var(--font-600)",
                    color: "var(--color-main)",
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    navigate("/CreateMilestone", {
                      state: {
                        legacy_year: selectedYear,
                      },
                    });
                  }}
                >
                  Create New
                </button>

                {gethomepopup && (
                  <div
                    onClick={() => {
                      sethomepopup(false);
                      navigate("/homescreen2");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#3c3c3ca3",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      marginTop: "1rem",
                    }}
                  >
                    <button
                      // onClick={() => {
                      //   sethomepopup(false);
                      //   navigate("/homescreen2");
                      // }}
                      style={{ width: "18px", height: "18px" }}
                    >
                      <RiHome2Line color="var(--color-white)" size={18} />
                    </button>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "var(--font-600)",
                        marginLeft: "5px",
                        fontSize: "14px",
                        fontWeight: 350,
                      }}
                    >
                      Home
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="legacy_milestone_cards_wrapp">
                  {milestonedata && milestonedata.length > 0
                    ? milestonedata.map((item, index) => {
                        console.log("item", item);
                        return (
                          <div
                            onClick={() => {
                              navigate("/MilestoneDashboard", {
                                state: {
                                  user_id: item.user_id,
                                  user_milestone_id: item.user_milestone_id,
                                },
                              });
                            }}
                            className="legacy_milestone_single_card"
                          >
                            <img
                              src={item.imageLink[0].imageLink}
                              alt=""
                              className="legacy_milestone_single_card_img"
                            />
                            <div className="legacy_milestone_single_card_blur_wrapp">
                              <p>{item.milestone_name}</p>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            )}
            {/* miestone cards end */}

            {/* rarerez start */}

            {getyeararray && getyeararray.length > 0 && (
              <>
                <p
                  style={{
                    marginTop: "1rem",
                    marginBottom: "10px",
                    color: "var(--color-white)",
                    fontSize: "14px",
                    fontFamily: "var(--font-600)",
                    fontWeight: 350,
                  }}
                >
                  My rarerez
                </p>
                <div className="legacy_milestone_cards_wrapp">
                  {getyeararray && getyeararray.length > 0
                    ? getyeararray.map((item, index) => {
                        console.log("item", item);
                        return (
                          <div
                            onClick={() => {
                              navigate("/ProductView", {
                                state: {
                                  release_bond_id: item.release_bond_id,
                                  product_id: item.product_id,
                                  is_nfb: item.is_nfb,
                                  uniq_code: item.uniq_code,
                                  theme_index: item.theme_index,
                                  share_by_user: item.share_by_user,
                                  club_id: item.club_id,
                                },
                              });
                            }}
                            className="legacy_milestone_single_card"
                          >
                            <img
                              src={item.product_img_video[0]?.img_video}
                              alt=""
                              className="legacy_milestone_single_card_img"
                            />
                            <div className="legacy_milestone_single_card_blur_wrapp">
                              <p>{item.product_name}</p>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            )}
            {/* rarerez end */}
          </div>
        )}
        <ToastContainer />
        {getcusmodal && (
          <>
            <V2Modal
              canclebtn={false}
              title=""
              msg="From your earliest memories to your latest achievements, Rarerez offers a secure platform to chronicle your legacy. Create digital milestones, document milestones, and safeguard your story for generations to come."
              setShowModal={setcusmodal}
              okfunction={closemodal}
            />
          </>
        )}
      </div>
    </>
  );
};

export default LagecyDashbord;
