import React from "react";
import "./NewEmailOtpVerify.css";
import { useRef } from "react";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import {
  user_resend_email_otp_v2,
  user_reset_email_phone_number,
  verify_email_otp_v2,
} from "../../utils/Constant";
import NewLoader from "../../common/newloder/NewLoader";
import { FaAngleLeft } from "react-icons/fa";

const NewEmailOtpVerify = () => {
  const email_validation =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [emailotp, setEmailOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(30);
  const [showEditModal, setShowEditModal] = useState(false);

  const [getemail, setEmail] = useState(location.state.email);
  const [getemailedit, setEmailEdit] = useState(location.state.email);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [getcon, setcon] = useState(false);

  // timer functions start
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(30);
  };

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (startTimer && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [setIsTimerRunning, timer]);

  useEffect(() => {
    startTimer();
  }, []);
  // timer functions end

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...emailotp];
      newOtp[index] = value;
      setEmailOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && emailotp[index] === "") {
      const newOtp = [...emailotp];
      newOtp[index - 1] = "";
      setEmailOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  const otpValue = emailotp.join("");

  // resend emailotp api start
  const ResendEmailOtp = async () => {
    setEmailOtp(["", "", "", ""]);
    setIsLoading(true);
    const param = {
      user_id: location.state.user_id,
    };
    console.log("success");
    await axios
      .post(user_resend_email_otp_v2, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          startTimer();
          console.log("user_otp Response", Response.data);
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.success(`${Response.data.message}`, { theme: "colored" });
        } else {
          // setcusmsg(Response.data.message);
          // setShowModal(true);
          toast.error(`${Response.data.message}`, { theme: "colored" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
        toast.error(`${err}`, { theme: "colored" });
      });
  };
  // resend emailotp api end

  // edit emailotp api start
  const EditEmail = async () => {
    console.log("asdas");
    if (getemailedit === "") {
      toast.error("Please enter a email address", { theme: "colored" });
    } else if (!getcon) {
      toast.error("Please enter a valid email address", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        user_id: location.state.user_id,
        email: getemailedit,
      };
      console.log("user_otp param", param);

      await axios
        .post(user_reset_email_phone_number, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status === 1) {
            console.log("user_otp Response", Response.data);
            setShowEditModal(false);
            setEmail(Response.data.results);
            toast.success(`${Response.data.message}`, { theme: "colored" });
          } else {
            console.log("user_otp err", Response.data);
            // setError(Response.data.message);
            toast.error(`${Response.data.message}`, { theme: "colored" });

            if (!toastDisplayed) {
              setToastDisplayed(true);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };
  // edit mobileotp api end

  // verify mobileotp api start
  const verifyEmailOtp = async () => {
    if (!otpValue) {
      toast.error("Please enter your otp", { theme: "colored" });
    } else if (otpValue.length < 4) {
      toast.error("Please enter 4 digit otp", { theme: "colored" });
    } else {
      setIsLoading(true);
      const param = {
        email: getemail,
        email_otp: otpValue,
      };
      await axios
        .post(verify_email_otp_v2, qs.stringify(param))
        .then((Response) => {
          setIsLoading(false);
          if (Response.data.status == 1) {
            toast.success(`${Response.data.message}`, { theme: "colored" });
            navigate("/NewEmailOtpSuccess", {
              state: {
                user_id: Response.data.user_id,
                email: Response.data.email,
              },
            });
            console.log("user_otp Response", Response);
          } else {
            toast.error(`${Response.data.message}`, { theme: "colored" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("user_otp err", err);
          toast.error(`${err}`, { theme: "colored" });
        });
    }
  };
  // verify mobileotp api end

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="new_container">
          {isTimerRunning ? null : (
            <button
              style={{ marginTop: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
          )}
          <div className="play_screen_main_wrapp">
            <div
              className="play_screen_banner_container"
              style={{ height: "45vh" }}
            >
              <img
                src={images.new_logo}
                className="play_screen_banner"
                style={{ height: "64px", maxWidth: "200px" }}
                alt=""
              />
            </div>

            <div
              className="otp_screen_text_container"
              style={{ margin: "0px" }}
            >
              <h4>Verify OTP</h4>
              <p>
                Please enter the verification code sent to{" "}
                <span
                  style={{
                    color: "var(--color-main)",
                    fontFamily: "var(--font-600)",
                  }}
                >
                  {getemail}{" "}
                </span>
                email address
              </p>
            </div>

            <div className="new_verify_otp_wrapp ">
              {emailotp.map((digit, index) => (
                <input
                  style={{
                    borderWidth: 0.5,
                    // borderColor: isMobileVerified && "green",
                  }}
                  key={index}
                  // disabled={isMobileVerified}
                  type="number"
                  maxLength={1}
                  className="new_verify_otp_inputs"
                  ref={refsMobile[index]}
                  value={digit}
                  onChange={(event) => handleInputChangeMobile(index, event)}
                  onKeyDown={(event) => handleKeyDownMobile(index, event)}
                />
              ))}
            </div>

            <div className="new_verify_otp_down_sec">
              <div>
                <p>Request another OTP in</p>
                {/* <span>00 : 30</span> */}
                <button disabled={isTimerRunning} onClick={ResendEmailOtp}>
                  {isTimerRunning ? `00 : ${timer}` : "RESEND OTP"}
                </button>
              </div>
              {/* btn */}
              <button
                disabled={!otpValue}
                onClick={verifyEmailOtp}
                className="new_btn_wrapp mx-1"
              >
                <p>Next</p>
              </button>
              {/* btn */}
              <div>
                <p>
                  Want to{" "}
                  <button
                    onClick={() => {
                      setShowEditModal(true);
                    }}
                  >
                    {" "}
                    edit email
                  </button>{" "}
                  ?
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* edit mobile number modal start */}
      {showEditModal && (
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            {/* {title && <h4 className="v2modal_fixed_heading">{title}</h4>} */}
            <div className="v2modal_fixed_text_wrapp">
              <div className="new_set_password_single_input_wrapp">
                <div className="new_set_password_single_labal_wrapp">
                  <img src={images.new_email_logo} alt="" />
                  <p>Enter Email Address</p>
                </div>
                <div className="new_set_password_single_border_wrapp">
                  <input
                    type="email"
                    placeholder="Type here"
                    value={getemailedit}
                    // onChange={(e) => formatPhoneNumber(e.target.value)}
                    onChange={(e) => {
                      const text = e.target.value;
                      if (email_validation.test(text) === false) {
                        setcon(false);
                      } else if (text.length == 0) {
                        setcon(false);
                      } else {
                        setcon(true);
                      }
                      setEmailEdit(text);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              <button
                className="v2modal_btn2"
                onClick={EditEmail}
                disabled={!getcon}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {/* edit mobile number modal end */}
      <ToastContainer />
    </div>
  );
};

export default NewEmailOtpVerify;
