import React, { useEffect } from "react";
import "./NewForgotOtpScreen.css";
import { useRef } from "react";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { user_resend_otp_in_new_mobile } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewForgotOtpScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [mobileotp, setMobileOtp] = React.useState(["", "", "", ""]);
  const refsMobile = [useRef(), useRef(), useRef(), useRef()];
  const [getuserOtp, setUserOtp] = useState(
    location.state.user_otp ? location.state.user_otp : ""
  );
  // timer states
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState(null);
  const [toastDisplayed, setToastDisplayed] = useState(false);

  // timer functions start
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(30);
  };

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (startTimer && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (isTimerRunning && timer === 0) {
      setIsTimerRunning(false);
    }
  }, [setIsTimerRunning, timer]);

  const handleInputChangeMobile = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...mobileotp];
      newOtp[index] = value;
      setMobileOtp(newOtp);

      if (index < refsMobile.length - 1 && value !== "") {
        refsMobile[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownMobile = (index, event) => {
    if (event.key === "Backspace" && index > 0 && mobileotp[index] === "") {
      const newOtp = [...mobileotp];
      newOtp[index - 1] = "";
      setMobileOtp(newOtp);
      refsMobile[index - 1].current.focus();
    }
  };

  // apis start

  const ResendOtp = () => {
    setIsLoading(true);

    const param = {
      user_id: location.state.user_id,
      phone: location.state.mobile_no,
    };
    console.log("Transaction", param);

    axios
      .post(user_resend_otp_in_new_mobile, qs.stringify(param))
      .then((response) => {
        setIsLoading(false);
        console.log("API Response:", response.data);

        if (response.data.status === 1) {
          setUserOtp(response.data.user_otp);
          startTimer();
          toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    // }
  };

  const VerifyOtp = () => {
    const otpValue = mobileotp.join("");
    if (otpValue == "") {
      toast.error("Enter OTP", { theme: "colored" });
    } else if (Number(getuserOtp) !== Number(otpValue)) {
      toast.error("Wrong OTP", { theme: "colored" });
    } else {
      navigate("/NewChangePasswordScreen", {
        state: {
          user_id: location.state.user_id,
        },
      });
    }
  };

  // apis end

  return (
    <div className="new_wrapp_container reletive_position">
      <img src={images.new_bg_circule} alt="" className="back_circule_shadow" />
      <div className="new_container">
        <div className="play_screen_main_wrapp">
          <div
            className="play_screen_banner_container"
            style={{ height: "45vh" }}
          >
            <img
              src={images.new_logo}
              className="play_screen_banner"
              style={{ height: "64px", maxWidth: "200px" }}
              alt=""
            />
          </div>

          <div className="otp_screen_text_container" style={{ margin: "0px" }}>
            <h4>Verify OTP</h4>
            <p>
              OTP has been sent on
              <b className="new_verify_otp_show_email_text">
                {" "}
                +91 {location.state.mobile_no}
              </b>
              <br />
              Please enter OTP to change your Password.
            </p>
          </div>

          <div className="new_verify_otp_wrapp ">
            {mobileotp.map((digit, index) => (
              <input
                style={{
                  borderWidth: 0.5,
                  // borderColor: isMobileVerified && "green",
                }}
                key={index}
                // disabled={isMobileVerified}
                type="number"
                maxLength={1}
                className="new_verify_otp_inputs"
                ref={refsMobile[index]}
                value={digit}
                onChange={(event) => handleInputChangeMobile(index, event)}
                onKeyDown={(event) => handleKeyDownMobile(index, event)}
              />
            ))}
          </div>

          <div className="new_verify_otp_down_sec">
            <div>
              <p>Request another OTP in</p>
              {/* <span>00 : 30</span> */}
              <button onClick={ResendOtp} disabled={isTimerRunning}>
                {isTimerRunning ? `00 : ${timer}` : "RESEND OTP"}
              </button>
            </div>
            {/* btn */}
            <button onClick={VerifyOtp} className="new_btn_wrapp mx-1">
              <p>Next</p>
            </button>
            {/* btn */}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default NewForgotOtpScreen;
