import React, { useState, useEffect } from "react";
import "./clubmembership.css";
import images from "../../constants/images";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import axios from "axios";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import { useRef } from "react";
import { user_Check_m_pin } from "../../utils/Constant";
import { loadStripe } from "@stripe/stripe-js";
import Urls from "../../utils/Urls";
import ReactModal from "react-modal";
import V2Modal from "../../common/v2modal/V2Modal";

const Clubmembership = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const location = useLocation();
  const [myBalance, setMyBalance] = useState(0);
  const [isModalOpen, setisisModalOpen] = useState(false);

  const userId = localStorage.getItem("user_id");

  const [mpin, setMpin] = useState(["", "", "", ""]);
  const mpinRefs = useRef([]);

  const handleMpinChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newMpin = [...mpin];
      newMpin[index] = value;
      setMpin(newMpin);
      if (value !== "" && index < mpin.length - 1) {
        mpinRefs.current[index + 1].focus();
      }
    }
  };

  // payment start
  const saveDataToSessionStorage = (key, data) => {
    const storedData = JSON.parse(sessionStorage.getItem(key)) || [];
    storedData.push(data);
    sessionStorage.setItem(key, JSON.stringify(storedData));
  };

  const stripePromise = loadStripe(
    "pk_live_51ODMf4SIZS16AN382v1WLRYA0m5T0xqKIjkWwJCYo2eLmEVR6JlSxQM1MIgvQNl6NJewKCQBR2F6JqMqhKrhm5O7000ONaUVem"
  );

  const makePayment = async () => {
    try {
      const apiUrl = "https://www.ravi.host/api/stripe_payment";
      const datas = {
        user_id: userId,
        club_id: location.state.club_id,
        amount: 1, // Replace with the desired amount
        // amount: getplatform_fees_data.stripe_total, // Replace with the desired amount
        success_url: ` ${Urls.main_url}ClubSuccess`, // Replace with your success URL
        fail_url: `${Urls.main_url}ClubFail`, // Replace with your fail URL
      };
      console.log("dddd", datas);
      const response = await axios.post(apiUrl, datas);
      console.log("Response: " + JSON.stringify(response.data.success));
      // Handle the response data as needed
      // setResponsesession(response.data.session_id);
      if (response.data.success === true) {
        // saveDataToSessionStorage("clubPackageId", clubPackageId);
        // saveDataToSessionStorage("club_id", location.state.club_id);
        sessionStorage.setItem("club_id", location.state.club_id);
        sessionStorage.setItem("clubPackageId", clubPackageId);
        // saveDataToSessionStorage("total_ammount", total_amount_bid);
        localStorage.setItem("allres", JSON.stringify(response.data));

        const stripe = await stripePromise;
        await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });
      } else {
        alert("something went wrong ");
        sessionStorage.clear();
      }
    } catch (error) {
      // Handle errors
      console.error("Error making payment:", error);
    }
  };

  // payment end

  useEffect(() => {
    getbalance();
    getclub();
  }, []);

  const getbalance = () => {
    const param1 = {
      user_id: userId,
    };

    console.log("====================================");
    console.log("param1", param1);
    console.log("====================================");

    axios
      .post("https://www.ravi.host/api/get_user_balance", qs.stringify(param1))
      .then((Response) => {
        console.log("====================================");
        console.log("Res", Response.data);
        console.log("====================================");

        if (Response.data.status === 1) {
          setMyBalance(Response.data.total);
        } else {
          setMyBalance(0);
        }
      });
  };

  const getclub = () => {
    const param = {
      club_id: location.state.club_id,
    };

    axios
      .post("https://www.ravi.host/api/get_club_package", qs.stringify(param))
      .then((Response) => {
        if (Response.data.status === 1) {
          console.log("skjfvndsf", Response.data.result[0].free_paid);
          setManagerDetail(Response.data.result);
          setFreePaid(Response.data.result[0].free_paid);
        } else {
          setCon(0);
          setIsModalVisible(true);
          setCusmsg(Response.data.message);
        }
      });
  };

  const [managerDetail, setManagerDetail] = useState([]);
  const [freePaid, setFreePaid] = useState(0);
  const [con, setCon] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [isloading, setloading] = useState(false);
  const [clubPackageId, setClubPackageId] = useState(0);
  const [localClubPackagePrice, setLocalClubPackagePrice] = useState(null);

  const handlePress = (item) => {
    const updatedArray = managerDetail.map((packageItem) => {
      if (packageItem.club_package_id === item.club_package_id) {
        console.log(packageItem.club_package_id + "=" + item.club_package_id);
        setClubPackageId(item.club_package_id);
        setLocalClubPackagePrice(item.club_package_price);
        return { ...packageItem, is_status: 1 };
      } else {
        return { ...packageItem, is_status: 0 };
      }
    });

    setManagerDetail(updatedArray);
    console.log("manager_detail", updatedArray);
  };

  const [getcusmodal1, setcusmodal1] = useState(false);
  const [getcusmsg1, setcusmsg1] = useState("");
  const handleJoinClub = () => {
    console.log("clubPackageId", clubPackageId, freePaid);
    if (freePaid == 1) {
      if (clubPackageId === 0) {
        setcusmodal1(true);
        setcusmsg1("Select package");
        setCon(0);
      }
      // else if (myBalance <= localClubPackagePrice) {
      //   setcusmodal1(true);
      //   setcusmsg1("Please recharge your wallet now");
      //   setCon(1);
      // }
      else {
        // const param = {
        //   club_id: location.state.club_id,
        //   user_id: userId,
        //   club_package_id: clubPackageId,
        // };

        // console.log("param---->", param);

        // axios
        //   .post(
        //     "https://www.ravi.host/api/club_join",
        //     qs.stringify(param)
        //   )
        //   .then((Response) => {
        //     if (Response.data.status === 1) {
        //       setcusmodal1(true);
        //       setcusmsg1(Response.data.message);
        //       setCon(2);
        //     } else {
        //       setcusmodal1(true);
        //       setcusmsg1(Response.data.message);
        //       setCon(0);
        //     }
        //   });

        // setisisModalOpen(true);
        makePayment();
      }
    } else {
      if (clubPackageId === 0) {
        setcusmodal1(true);
        setcusmsg1("Select package");
        setCon(0);
      } else if (myBalance <= localClubPackagePrice) {
        setcusmodal1(true);
        setcusmsg1("Please recharge your wallet now");
        setCon(1);
      } else {
        const param = {
          club_id: location.state.club_id,
          user_id: userId,
          club_package_id: "",
        };
        console.log("param---->", param);

        axios
          .post("https://www.ravi.host/api/club_join", qs.stringify(param))
          .then((Response) => {
            if (Response.data.status === 1) {
              setcusmodal1(true);
              setcusmsg1(Response.data.message);
              setCon(2);
            } else {
              setcusmodal1(true);
              setcusmsg1(Response.data.message);
              setCon(0);
            }
          });
      }
    }
  };

  const navigate = useNavigate();

  const SetMapin = () => {
    const otpValueEmail = mpin.join("");
    console.log("otpValueEmail", otpValueEmail);
    if (otpValueEmail == "") {
      setcusmodal1(true);
      setcusmsg1("Please enter mpin");
    } else {
      const param = {
        user_id: userId,
        m_pin: otpValueEmail,
      };
      console.log("parammpin", param);
      axios
        .post(user_Check_m_pin, param)
        .then((Response) => {
          console.log("acdascs", Response.data);
          setloading(false);
          if (Response.data.status == 1) {
            // bidplace();
            const param = {
              club_id: location.state.club_id,
              user_id: userId,
              club_package_id: clubPackageId,
            };
            console.log("param---->", param);
            axios
              .post("https://www.ravi.host/api/club_join", qs.stringify(param))
              .then((Response) => {
                if (Response.data.status === 1) {
                  setcusmodal1(true);
                  setcusmsg1(Response.data.message);
                  setCon(2);
                } else {
                  setcusmodal1(true);
                  setcusmsg1(Response.data.message);
                  setCon(0);
                }
              });
          } else {
            setcusmsg1(Response.data.message);
            setcusmodal1(true);
          }
          // this.setState({
          //   // data: Response.data.result,
          //   task_arrayholder: Response.data.result,
          // });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
  };

  const closemodal = () => {
    setcusmodal1(false);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="" />
        <br />
        <div className="club_m_list">
          <center>
            <img
              src={location.state.profile}
              className="club_m_list_img2"
              alt=""
            />
            <p className="club_m2_list_nm">{location.state.name}</p>
          </center>
        </div>
        <div className="club_menu_list">
          <div className="club_menu_list_1">
            <div className="club_menu_img">
              <img src={images.star_new} className="club_menu_img1" alt="" />
            </div>
            <p className="club_menu_nm">
              Priority Bond <br />
              Access
            </p>
            {/* <p className="club_menu_nm">Access</p> */}
          </div>
          <div className="club_menu_list_1">
            <div className="club_menu_img">
              <img src={images.discussion} className="club_menu_img1" alt="" />
            </div>
            <p className="club_menu_nm">
              Community <br />
              Connections
            </p>
            {/* <p className="club_menu_nm">Connections</p> */}
          </div>
          <div className="club_menu_list_1">
            <div className="club_menu_img">
              <img src={images.community} className="club_menu_img1" alt="" />
            </div>
            <p className="club_menu_nm">
              Exclusive Member <br />
              Networking
            </p>
            {/* <p className="club_menu_nm">Networking</p> */}
          </div>
        </div>
        {freePaid == 1 ? (
          <>
            {managerDetail &&
              managerDetail.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      handlePress(item);
                    }}
                    className="club_view_membership"
                  >
                    <div className="club_view_membership1">
                      <input
                        checked={item.is_status == 1}
                        type="radio"
                        className="club_member_select"
                        name="membershipDuration" // Give the same name for all radio buttons
                      />
                      <p className="clun_price_m">₹{item.club_package_price}</p>
                      <p className="club_month">
                        {item.club_package_time} Month
                      </p>
                    </div>
                  </div>
                );
              })}
          </>
        ) : null}
        <br />
        <br />
        <br />
        <div className="club_member_btn_div">
          <button
            onClick={() => {
              handleJoinClub();
            }}
            className="new_btn_wrapp"
            style={{
              fontSize: "18px",
              fontFamily: "var(--font-600)",
              width: "90%",
            }}
          >
            <p>Become Member</p>
          </button>
        </div>
      </div>
      {getcusmodal1 && (
        // <div className="modal_login">
        //   <div className="modal-content_login">
        //     <div className="modal_div1">
        //       <img className="forgot_main" src={images.logo} alt="" />
        //     </div>
        //     <div className="modal_div">
        //       <b>{""}</b>
        //       <br />
        //       <p className="modal-message"> {getcusmsg1}</p>
        //       <br />
        //     </div>
        //     <hr className="hr_forgot" />
        //     <br />
        //     <div className="modal_div">
        //       <button
        //         className="close_forgot"
        //         onClick={() => {
        //           console.log(con);
        //           con == 2
        //             ? navigate("/homescreen2")
        //             : con == 1
        //             ? navigate("/wallet")
        //             : setcusmodal1(false);
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>

        // <ReactModal
        //   isOpen={setcusmodal1}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{getcusmsg1}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         console.log(con);
        //         con == 2
        //           ? navigate("/homescreen2")
        //           : con == 1
        //           ? navigate("/wallet")
        //           : setcusmodal1(false);
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={getcusmsg1}
          setShowModal={setcusmodal1}
          okfunction={() => {
            console.log(con);
            con == 2
              ? navigate("/homescreen2")
              : con == 1
              ? navigate("/wallet")
              : setcusmodal1(false);
          }}
        />
      )}

      {isModalOpen && (
        // Apply CSS styles for the bottom modal
        <div className="modal_bottom">
          <div className="modal-content1">
            <center>
              <img src={images.logo} className="bidlist_img2" alt="" />
            </center>
            <p className="bidslist_new_nm">Enter m-Pin</p>
            <div>
              <div className="otp_input_container">
                {mpin.map((digit, index) => {
                  return (
                    <input
                      key={index}
                      type="password"
                      value={digit}
                      className="mobile_int_otp2"
                      onChange={(e) => handleMpinChange(index, e.target.value)}
                      maxLength={1}
                      ref={(input) => (mpinRefs.current[index] = input)}
                      inputMode="numeric"
                    />
                  );
                })}
              </div>
            </div>
            <div className="list_bid_btn2">
              <button
                className="list_bid_btn3"
                onClick={() => {
                  setisisModalOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  SetMapin();
                  // const param = {
                  //   club_id: location.state.club_id,
                  //   user_id: userId,
                  //   club_package_id: clubPackageId,
                  // };
                  // console.log("param---->", param);
                  // axios
                  //   .post(
                  //     "https://www.ravi.host/api/club_join",
                  //     qs.stringify(param)
                  //   )
                  //   .then((Response) => {
                  //     if (Response.data.status === 1) {
                  //       setcusmodal1(true);
                  //       setcusmsg1(Response.data.message);
                  //       setCon(2);
                  //     } else {
                  //       setcusmodal1(true);
                  //       setcusmsg1(Response.data.message);
                  //       setCon(0);
                  //     }
                  //   });
                }}
                className="list_bid_btn1"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Clubmembership;
