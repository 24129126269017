import React, { useState, useEffect } from "react";
import axios from "axios";
import "../pages/report/report.css";
import { useLocation, useNavigate } from "react-router-dom";
import AlertModal from "../components/AlertModal";
import images from "../constants/images";
import BackButton from "../components/BackButton";
import NewModal from "../common/newmodal/NewModal";
import ReactModal from "react-modal";
import V2Modal from "../common/v2modal/V2Modal";

const ReportPage = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const userId = localStorage.getItem("user_id");
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(0); // State to track the selected option
  const [getindex, setindex] = useState(null); // State to track the selected option
  const [getmsg, setmsg] = useState("");
  const handleOptionChange = (event) => {
    console.log(event);
    setSelectedOption(event.target.value); // Update the selected option when a radio button is clicked
    console.log(selectedOption);
  };
  const [isLoading, setIsLoading] = useState(false);

  const [idString, setIdString] = useState("");
  const [idString1, setIdString1] = useState(0);

  const [report, setReport] = useState([]);
  const [extraMessage, setExtraMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cusmsg, setCusmsg] = useState("");
  const [con, setCon] = useState(false);
  const url = "https://www.ravi.host/api/report";
  console.log("nitin 17", report);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fetchReportData = async () => {
    // get request with the report
    return await axios
      .get(url)
      .then((res) => setReport(res.data.data))
      .catch((error) => {
        // Handle errors
      });
  };
  useEffect(() => {
    fetchReportData();
  }, []);

  const performAddReason = async () => {
    if (selectedOption == 0) {
      setErrorMessage("Please select report reason");
      setShowModal(true);
    } else if (getindex == 5 && getmsg == "") {
      setErrorMessage("Please enter reason");
      setShowModal(true);
    } else {
      setIsLoading(true);
      const param = {
        user_id: userId,
        release_bond_id: "0",
        report_id: idString,
        message_text: getmsg,
      };
      console.log("pr+", param);
      try {
        const response = await axios.post(
          "https://www.ravi.host/api/report_bond",
          param
        );

        setIsLoading(false);
        if (response.data.status === 1) {
          setCon(true);
          setIsModalVisible(true);
          setCusmsg(response.data.message);
        } else {
          setCon(false);
          setIsModalVisible(true);
          setCusmsg(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        // Handle the error
      }
    }
  };

  //disable body scrolling
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(true);

  useEffect(() => {
    if (showModal || isModalVisible) {
      // Disable body scrolling
      setBodyScrollEnabled(false);
    } else {
      // Enable body scrolling
      setBodyScrollEnabled(true);
    }
    if (bodyScrollEnabled) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [showModal, isModalVisible, bodyScrollEnabled]);

  const closemodal1 = () => {
    setShowModal(false);
  };

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        {/* <NavbarSec name="" /> */}
        {/* <div className="productview_header"> */}
        <div className="productview_main_header_notifi">
          <div className="productviewmain1">
            <BackButton />
          </div>
          <div className="productviewmain2"></div>
        </div>
        {/* </div> */}
        <div className="report_div">
          <b className="report_title">Report</b>
        </div>
        <br />
        {report.map((item, index) => (
          <div className="report_details" key={index}>
            <input
              type="radio"
              value={item.report_id}
              checked={selectedOption == item.report_id}
              onChange={(e) => {
                handleOptionChange(e);
                setindex(index);
                setIdString(item.report_id);
              }}
            />{" "}
            <span className="report_details_span">{item.report_name}</span>
          </div>
        ))}
        {/* <div className="report_details">
          <input
            type="radio"
            value="otherViolations"
            checked={selectedOption === "otherViolations"}
            onChange={handleOptionChange}
          />{" "}
          <span className="report_details_span">Other violations</span>
        </div> */}
        <div
          className="show_text_report"
          style={{
            display: getindex === 5 ? "block" : "none",
          }}
        >
          <textarea
            onChange={(e) => {
              setmsg(e.target.value);
            }}
            placeholder="Please provide a detailed description of the violation you have observed in the Rarerez"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <center>
          <button
            onClick={() => {
              performAddReason();
            }}
            className="report_btn_new"
          >
            Report
          </button>
        </center>
      </div>
      {showModal && (
        // <AlertModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   title="Information"
        // />
        // <NewModal
        //   title=""
        //   msg={errorMessage}
        //   setShowModal={openmodal}
        //   closemodal={closemodal}
        // />

        <V2Modal
          canclebtn={false}
          title=""
          msg={errorMessage}
          setShowModal={setShowModal}
          okfunction={closemodal}
        />
      )}
      {isModalVisible && (
        // <div className="cusModal_main_wrapp">
        //   <div className="cusmodal_inner_wrapp">
        //     <img src={images.logo} alt="" className="cm_logo" />
        //     <p className="cm_heading"></p>
        //     <div className="cm_des_wrapp">
        //       <p className="cm_des">{cusmsg}</p>
        //       <div className="cm_hr"></div>
        //       <button
        //         className="cm_btn"
        //         onClick={() => {
        //           setIsModalVisible(false);
        //           if (con == true) {
        //             navigate(-1);
        //           }
        //         }}
        //       >
        //         Okay
        //       </button>
        //     </div>
        //   </div>
        // </div>
        // <ReactModal
        //   isOpen={setShowModal}
        //   // onAfterOpen={afterOpenModal}
        //   onRequestClose={closemodal1}
        //   style={customStyles}
        // >
        //   <div className="NewModal_main_wrapp">
        //     <div
        //       style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <img className="newmodal_logo" src={images.logo} alt="" />
        //       <p className="newmodal_titel"></p>
        //       {/* <p className="newmodal_titel">Alert</p> */}
        //     </div>

        //     {/* <div className="newmodal_des_wrapp"> */}
        //     <p className="newmodal_des">{cusmsg}</p>
        //     {/* </div> */}

        //     <button
        //       className="newmodal_btn"
        //       onClick={() => {
        //         setIsModalVisible(false);
        //         if (con == true) {
        //           navigate(-1);
        //         }
        //       }}
        //     >
        //       Okay
        //     </button>
        //   </div>
        // </ReactModal>

        <V2Modal
          canclebtn={false}
          title=""
          msg={cusmsg}
          setShowModal={setIsModalVisible}
          okfunction={() => {
            setIsModalVisible(false);
            if (con == true) {
              navigate(-1);
            }
          }}
        />
      )}
    </div>
  );
};

export default ReportPage;
