import React, { useEffect, useState } from "react";
import "./SpecialBenefits.css";
import images from "../../constants/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../../common/footer/Footer";
import axios from "axios";
import AlertModal from "../../components/AlertModal";
import NavbarSec from "../../common/NavbarSec";
import qs from "qs";
import NewFooter from "../../common/newfooter/NewFooter";
import { FaAngleLeft } from "react-icons/fa";

const SpecialBenefits = () => {
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [getchangeBenefit, setChangeBenefit] = useState(1);
  const Navigate = useNavigate();
  const location = useLocation();

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [benifites, setBenifites] = useState([]);
  const [product_benifit, setproduct_benifit] = useState([]);

  const url = "https://www.ravi.host/api/get_user_benifit_history";

  const fetchBenifitesData = async () => {
    // FormData object
    const formData = new FormData();

    formData.append("user_id", userId);

    // Send the POST request with the FormData
    return await axios
      .post(url, formData)
      .then((res) => {
        setBenifites(res.data.result);
        setproduct_benifit(res.data.result);
      })
      .catch((error) => {
        // Handle errors
      });
  };
  useEffect(() => {
    fetchBenifitesData();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const Setbanifit = (item) => {
    console.log("item", item);
    if (
      item.is_claim === 3 ||
      (item.is_claim === 2 && item.partner_category_type === "Video Message")
    ) {
      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          benifit_category: item.partner_category_type,
          message: item.message,
          partner: 1,
          partner_benifit_id: item.partner_benifit_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          bid_amount_price: item.bid_partner_benifit_price,
          reward_message_video: item.reward_message_video,
          is_claim: item.is_claim,
          user_selected_video_time: item.user_selected_video_time,
        },
      });
    } else if (item.is_claim == 3 || item.partner_category_type == "Video") {
      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          partner: 1,
          partner_benifit_id: item.partner_benifit_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          bid_amount_price: item.bid_partner_benifit_price,
          is_claim: item.is_claim,
          benifit_category: item.partner_category_type,
          reward_id: item.reward_id,
          user_history_id: item.user_history_id,
          date_1: item.date_1,
          date_2: item.date_2,
          date_3: item.date_3,
          user_selected_video_date: item.user_selected_video_date,
          message: item.message,
          user_selected_video_time: item.user_selected_video_time,
        },
      });
    } else if (item.is_claim == 1) {
      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          benifit_category: item.partner_category_type,
          message: item.message,
          partner: 1,
          partner_benifit_id: item.partner_benifit_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          bid_amount_price: item.bid_partner_benifit_price,
          is_claim: item.is_claim,
          user_selected_video_time: item.user_selected_video_time,
        },
      });
    } else if (item.is_claim == 2) {
      if (item.partner_category_type == "Video") {
        Navigate("/benefits", {
          state: {
            is_nfb: item.is_nfb,
            partner: 1,
            partner_benifit_id: item.partner_benifit_id,
            product_assign_id: item.product_assign_id,
            release_bond_id: item.release_bond_id,
            accept_bond_bid_id: item.accept_bond_bid_id,
            user_id: userId,
            bid_benifit_last_date: item.bid_benifit_last_date,
            bid_amount_price: item.bid_partner_benifit_price,
            is_claim: item.is_claim,
            benifit_category: item.partner_category_type,
            reward_id: item.reward_id,
            user_history_id: item.user_history_id,
            date_1: item.date_1,
            date_2: item.date_2,
            date_3: item.date_3,
            message: item.message,
            user_selected_video_time: item.user_selected_video_time,
          },
        });
      }
    } else if (
      item.partner_category_type === "Video Message" &&
      item.is_claim == 0
    ) {
      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          partner: 1,
          partner_benifit_id: item.partner_benifit_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
          bid_amount_price: item.bid_partner_benifit_price,
          is_claim: item.is_claim,
          benifit_category: item.partner_category_type,
          reward_id: item.reward_id,
          user_history_id: item.user_history_id,
          date_1: item.date_1,
          date_2: item.date_2,
          date_3: item.date_3,
          message: item.message,
          user_selected_video_time: item.user_selected_video_time,
        },
      });
    } else {
    }
  };

  const handleClaim = (item) => {
    if (item.is_claim === 1) {
      // setIsModalVisible(true);
      // setCusmsg('Already applied');
      setErrorMessage("Already applied");
      setShowModal(true);
    } else if (item.is_claim === 2) {
      const param = {
        reward_id: item.reward_id,
        status:
          item.is_claim === 2
            ? 3
            : item.is_claim === 5
            ? 6
            : item.is_claim === 6
            ? 7
            : 3,
      };

      axios
        .post("https://www.ravi.host/api/reward_claim_partner_accept", param)
        .then((response) => {
          if (response.data.status === 1) {
            fetchBenifitesData();
            // setIsModalVisible(true);
            // setCusmsg(response.data.message);
            setErrorMessage(response.data.message);
            setShowModal(true);
          } else {
            setErrorMessage(response.data.message);
            setShowModal(true);
          }
        });
    } else {
      // props.navigation.navigate("OnwRewardView", {
      //   is_nfb: item.is_nfb,
      //   partner: 1,
      //   partner_benifit_id: item.partner_benifit_id,
      //   product_assign_id: item.product_assign_id,
      //   release_bond_id: item.release_bond_id,
      //   accept_bond_bid_id: item.accept_bond_bid_id,
      //   user_id: props.user_id,
      // });
      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          partner: 1,
          partner_benifit_id: item.partner_benifit_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          bid_amount_price: item.bid_partner_benifit_price,
          bid_benifit_last_date: item.bid_benifit_last_date,
          user_id: userId,
          message: item.message,
        },
      });
    }
  };

  // const handleClaim = (item) => {
  //   if (item.is_claim === 1) {
  //     // setIsModalVisible(true);
  //     // setCusmsg('Already applied');
  //     setErrorMessage("Already applied");
  //     setShowModal(true);
  //   } else if (item.is_claim === 2) {
  //     const param = {
  //       reward_id: item.reward_id,
  //       status: 3,
  //     };

  //     axios
  //       .post(
  //         "https://www.ravi.host/api/reward_claim_partner_accept",
  //         param
  //       )
  //       .then((response) => {
  //         if (response.data.status === 1) {
  //           fetchBenifitesData();
  //           // setIsModalVisible(true);
  //           // setCusmsg(response.data.message);
  //           setErrorMessage(response.data.message);
  //           setShowModal(true);
  //         } else {
  //           setErrorMessage(response.data.message);
  //           setShowModal(true);
  //         }
  //       });
  //   } else {
  //     // props.navigation.navigate("OnwRewardView", {
  //     //   is_nfb: item.is_nfb,
  //     //   partner: 1,
  //     //   partner_benifit_id: item.partner_benifit_id,
  //     //   product_assign_id: item.product_assign_id,
  //     //   release_bond_id: item.release_bond_id,
  //     //   accept_bond_bid_id: item.accept_bond_bid_id,
  //     //   user_id: props.user_id,
  //     // });
  //     Navigate("/benefits", {
  //       state: {
  //         is_nfb: item.is_nfb,
  //         partner: 1,
  //         partner_benifit_id: item.partner_benifit_id,
  //         product_assign_id: item.product_assign_id,
  //         release_bond_id: item.release_bond_id,
  //         accept_bond_bid_id: item.accept_bond_bid_id,
  //         bid_amount_price: item.bid_partner_benifit_price,
  //         bid_benifit_last_date: item.bid_benifit_last_date,
  //         user_id: userId,
  //         message: item.message,
  //       },
  //     });
  //   }
  // };

  const handleClaimReward = (item) => {
    if (item.is_claim == 1) {
      // Handle case where is_claim is 1

      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          partner: 0,
          pro_bo_ben_id: item.pro_bo_ben_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          accept_bond_bid_id: item.accept_bond_bid_id,
          user_id: userId,
          bid_benifit_last_date: item.bid_benifit_last_date,
        },
      });
    } else if (item.is_claim === 2) {
      const param = {
        reward_id: item.reward_id,
        status: 3,
      };

      axios
        .post(
          "https://www.ravi.host/api/reward_claim_user_product_accept",
          qs.stringify(param)
        )
        .then((response) => {
          if (response.data.status === 1) {
            setErrorMessage(response.data.message);
            setShowModal(true);
            fetchBenifitesData(); // You should define _getData() to update your data
          } else {
            setErrorMessage(response.data.message);
            setShowModal(true);
          }
        });
    } else {
      // Handle the default case

      Navigate("/benefits", {
        state: {
          is_nfb: item.is_nfb,
          partner: 0,
          pro_bo_ben_id: item.pro_bo_ben_id,
          product_assign_id: item.product_assign_id,
          release_bond_id: item.release_bond_id,
          bid_benifit_last_date: item.bid_benifit_last_date,
          accept_bond_bid_id: item.accept_bond_bid_id,
          // bid_amount_price: item.bid_amount_price,
          user_id: userId,
        },
      });
    }
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* navbar start */}
        {/* <NavbarSec name="Benefits" /> */}
        <div
          className="legacy_dashbord_nav_sec"
          style={{ margin: "10px auto" }}
        >
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaAngleLeft color="var(--color-white)" size={20} />
          </button>
          <p>Benefits</p>
          <div></div>
          {/* <img
                onClick={() => {
                  navigate("/CreateLegacy");
                }}
                src={images.round_plus}
                alt=""
              /> */}
        </div>
        {/* navbar end */}
        <br />
        <b>Special Benefits</b>
        {/* select benefit btns wrapp start */}
        <div className="benefits_btns_wrapp">
          <button
            onClick={() => {
              setChangeBenefit(1);
            }}
            className={`benefit_btn ${
              getchangeBenefit === 1 ? "active-button" : ""
            }`}
          >
            Special Benefits
          </button>
          <button
            onClick={() => {
              setChangeBenefit(2);
            }}
            className={`benefit_btn ${
              getchangeBenefit === 2 ? "active-button" : ""
            }`}
          >
            Seller Benefits
          </button>
        </div>
        {/* select benefit btns wrapp end */}

        {/* user benefits start  */}
        {getchangeBenefit === 2 && (
          <div className="user_benefit_main_wrapp">
            <h4 className="user_benefit_heading">Seller Benefits</h4>

            {/* <div className="user_benefits_cards_wrapp">
              <div className="user_benefits_sigle_card">
                <img
                  src={images.mask_profile}
                  alt=""
                  className="user_benefits_card_img"
                />
                <p className="user_benefits_card_name">Benefit Name</p>
                <button className="user_benefits_card_btn">Explore</button>
              </div>
              <div className="user_benefits_sigle_card">
                <img
                  src={images.mask_profile}
                  alt=""
                  className="user_benefits_card_img"
                />
                <p className="user_benefits_card_name">Benefit Name</p>
                <button className="user_benefits_card_btn">Explore</button>
              </div>
              <div className="user_benefits_sigle_card">
                <img
                  src={images.mask_profile}
                  alt=""
                  className="user_benefits_card_img"
                />
                <p className="user_benefits_card_name">Benefit Name</p>
                <button className="user_benefits_card_btn">Explore</button>
              </div>
            </div> */}
            {benifites && benifites.length > 0 ? (
              <>
                {getchangeBenefit === 2 && (
                  // <div className="user_benefit_main_wrapp">
                  // <h4 className="user_benefit_heading">Creator Benefits</h4>
                  <>
                    {benifites.map((outerItem, outerIndex) => {
                      console.log("outerItem", outerItem);
                      return (
                        <div
                          className="user_benefits_cards_wrapp"
                          key={outerIndex}
                        >
                          {outerItem.product_benifit &&
                          outerItem.product_benifit.length > 0 ? (
                            outerItem.product_benifit.map(
                              (innerItem, innerIndex) => (
                                <div
                                  className="user_benefits_sigle_card"
                                  key={innerIndex}
                                >
                                  {/* <img
                                  src={
                                    outerItem.reward_img_video &&
                                    outerItem.reward_img_video.img_video
                                  }
                                  alt=""
                                  className="user_benefits_card_img"
                                  key={innerIndex}
                                /> */}
                                  {innerItem.reward_img_video
                                    .slice(0, 1)
                                    .map((item, itemIndex) => (
                                      <img
                                        src={item.img_video}
                                        alt=""
                                        className="user_benefits_card_img"
                                        key={itemIndex}
                                      />
                                    ))}
                                  <p className="user_benefits_card_name">
                                    {innerItem.reward_name}
                                  </p>

                                  <button
                                    onClick={() => {
                                      handleClaimReward(innerItem);
                                    }}
                                    style={{ zIndex: "11" }}
                                    className="user_benefits_card_btn"
                                  >
                                    {innerItem.is_claim == 1
                                      ? "Explore"
                                      : innerItem.is_claim == 2
                                      ? "Confirm"
                                      : innerItem.is_claim == 3
                                      ? "Approved"
                                      : "Add"}
                                  </button>
                                  {/* {innerItem.is_claim == 2 ? (
                                    <button
                                      onClick={() => {
                                        handleClaimReward(innerItem);
                                      }}
                                      className="user_benefits_card_btn"
                                    >
                                      {"Confirm"}
                                    </button>
                                  ) : null} */}
                                  {/* </div> */}
                                </div>
                              )
                            )
                          ) : (
                            <p
                              style={{
                                color: "white",
                                fontsize: "14px",
                                fontfamily: "var(--font-400)",
                              }}
                            >
                              No Benefits
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <p
                style={{
                  color: "white",
                  fontsize: "14px",
                  fontfamily: "var(--font-400)",
                }}
              >
                No Benefits
              </p>
            )}
          </div>
        )}
        {/* user benefits end  */}

        {/* partner benefit start */}

        <>
          {getchangeBenefit === 1 && (
            <>
              {/* {product_benifit && product_benifit?.partner_benifit ? ( */}
              <div className="partner_benefit_main_wrapp">
                <div className="sub_part_1">
                  <img
                    src={images.object}
                    alt=""
                    className="partner_benefit_side1_img"
                  />
                </div>
                <div className="partner_sub_slider">
                  {/* <p>No Benefit</p> */}
                  <center>
                    {/* Add your slider content here */}
                    {product_benifit ? (
                      product_benifit.map((item, index) => {
                        return (
                          <>
                            <Slider {...sliderSettings}>
                              {item.partner_benifit &&
                              item.partner_benifit.length > 0
                                ? item.partner_benifit.map((i, index) => {
                                    console.log("iii", i);
                                    return (
                                      <div className="benefit_slider">
                                        <div className="benefit_slider1">
                                          <img
                                            src={
                                              i.benifit_image[0].benifit_image
                                            }
                                            alt="Slider Image 1"
                                            className="slider-image_benefits"
                                          />
                                          <b className="benefit_font_nm">
                                            {i.partner_name}
                                          </b>
                                          <b className="benefit_font_nm">
                                            {i.bid_partner_benifit_price}
                                          </b>
                                          {/* {i.is_claim == 3 ? (
                                            <p
                                              onClick={() => {
                                                Setbanifit(i);
                                              }}
                                              style={{
                                                fontFamily: "var(--font-600)",
                                              }}
                                            >
                                              Completed
                                            </p>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  width: "130px",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <button
                                                  style={{ width: "60px" }}
                                                  onClick={() => {
                                                    Setbanifit(i);
                                                  }}
                                                  className="benefit_new_btn"
                                                >
                                                  <p
                                                    style={{ fontSize: "10px" }}
                                                  >
                                                    {i.is_claim == 3 ||
                                                    (i.is_claim == 2 &&
                                                      i.partner_category_type ==
                                                        "Video Message")
                                                      ? "Explore"
                                                      : i.is_claim == 1
                                                      ? "Explore"
                                                      : i.is_claim == 2
                                                      ? i.partner_category_type ==
                                                        "Video"
                                                        ? "Select Date"
                                                        : "Confirm"
                                                      : i.is_claim == 0
                                                      ? "Claim"
                                                      : i.partner_category_type ==
                                                          "Video" &&
                                                        i.is_claim == 4
                                                      ? "Check "
                                                      : i.partner_category_type ==
                                                          "Video" &&
                                                        i.is_claim == 5
                                                      ? "Confirm"
                                                      : i.partner_category_type ==
                                                          "Video" &&
                                                        i.is_claim == 6
                                                      ? "Confirm"
                                                      : null}
                                                  </p>
                                                </button>
                                                {i.is_claim == 2 ? (
                                                  <button
                                                    style={{ width: "60px" }}
                                                    onClick={() => {
                                                      handleClaim(i);
                                                    }}
                                                    className="benefit_new_btn"
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      {"Confirm"}
                                                    </p>
                                                  </button>
                                                ) : null}
                                              </div>
                                            </>
                                            // <Link
                                            //   to={"/benefits"}
                                            //   state={{
                                            //     product_id: i.user_history_id,
                                            //     number_of_bond: i.no_of_bond,
                                            //     uniuq_code: i.uniq_code,
                                            //     partner_benifit_id:
                                            //       i.partner_benifit_id,
                                            //     is_nfb: i.is_nfb,
                                            //     partner: 1,
                                            //   }}
                                            //   className="benefit_new_btn"
                                            //   onClick={() => {
                                            //     console.log("i", i);
                                            //   }}
                                            // >
                                            //   {i.is_claim == 1
                                            //     ? "Applied"
                                            //     : i.is_claim == 2
                                            //     ? "Confirm"
                                            //     : "Claim"}
                                            // </Link>
                                          )} */}

                                          <>
                                            {i.is_claim === 3 ||
                                            (i.is_claim === 2 &&
                                              i.partner_category_type ===
                                                "Video Message") ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <button
                                                  onClick={() => {
                                                    Navigate("/benefits", {
                                                      state: {
                                                        is_nfb: i.is_nfb,
                                                        benifit_category:
                                                          i.partner_category_type,
                                                        message: i.message,
                                                        partner: 1,
                                                        partner_benifit_id:
                                                          i.partner_benifit_id,
                                                        product_assign_id:
                                                          i.product_assign_id,
                                                        release_bond_id:
                                                          i.release_bond_id,
                                                        accept_bond_bid_id:
                                                          i.accept_bond_bid_id,
                                                        user_id: userId,
                                                        bid_benifit_last_date:
                                                          i.bid_benifit_last_date,
                                                        bid_amount_price:
                                                          i.bid_partner_benifit_price,
                                                        reward_message_video:
                                                          i.reward_message_video,
                                                        is_claim: i.is_claim,
                                                        user_selected_video_time:
                                                          i.user_selected_video_time,
                                                      },
                                                    });
                                                  }}
                                                  style={{
                                                    color: "#000",
                                                    backgroundColor: "#ffcb5b",
                                                    fontSize: "12px",
                                                    fontFamily:
                                                      "var(--font-400)",
                                                    padding: "6px 12px",
                                                    borderRadius: "6px",
                                                    marginTop: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {i.is_claim == 2
                                                    ? "Explore"
                                                    : "Claimed"}
                                                </button>
                                                {i.is_claim == 2 ? (
                                                  <button
                                                    onClick={() => {
                                                      const param = {
                                                        reward_id: i.reward_id,
                                                        status: 3,
                                                      };

                                                      axios
                                                        .post(
                                                          "https://www.ravi.host/api/reward_claim_partner_accept",
                                                          param
                                                        )
                                                        .then((response) => {
                                                          if (
                                                            response.data
                                                              .status === 1
                                                          ) {
                                                            fetchBenifitesData();
                                                            // setIsModalVisible(true);
                                                            // setCusmsg(response.data.message);
                                                            setErrorMessage(
                                                              response.data
                                                                .message
                                                            );
                                                            setShowModal(true);
                                                          } else {
                                                            setErrorMessage(
                                                              response.data
                                                                .message
                                                            );
                                                            setShowModal(true);
                                                          }
                                                        });
                                                    }}
                                                    style={{
                                                      color: "#000",
                                                      backgroundColor:
                                                        "#ffcb5b",
                                                      fontSize: "12px",
                                                      fontFamily:
                                                        "var(--font-400)",
                                                      padding: "6px 12px",
                                                      borderRadius: "6px",
                                                      marginTop: "4px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    Confirm
                                                  </button>
                                                ) : null}
                                              </div>
                                            ) : i.is_claim == 3 ? (
                                              i.partner_category_type ==
                                              "Video" ? (
                                                <button
                                                  onClick={() => {
                                                    Navigate("/benefits", {
                                                      state: {
                                                        is_nfb: i.is_nfb,
                                                        partner: 1,
                                                        partner_benifit_id:
                                                          i.partner_benifit_id,
                                                        product_assign_id:
                                                          i.product_assign_id,
                                                        release_bond_id:
                                                          i.release_bond_id,
                                                        accept_bond_bid_id:
                                                          i.accept_bond_bid_id,
                                                        user_id: userId,
                                                        bid_benifit_last_date:
                                                          i.bid_benifit_last_date,
                                                        bid_amount_price:
                                                          i.bid_partner_benifit_price,
                                                        is_claim: i.is_claim,
                                                        benifit_category:
                                                          i.partner_category_type,
                                                        reward_id: i.reward_id,
                                                        user_history_id:
                                                          i.user_history_id,
                                                        date_1: i.date_1,
                                                        date_2: i.date_2,
                                                        date_3: i.date_3,
                                                        user_selected_video_date:
                                                          i.user_selected_video_date,
                                                        message: i.message,
                                                        user_selected_video_time:
                                                          i.user_selected_video_time,
                                                      },
                                                    });
                                                  }}
                                                  style={{
                                                    color: "#000",
                                                    backgroundColor: "#ffcb5b",
                                                    fontSize: "12px",
                                                    fontFamily:
                                                      "var(--font-400)",
                                                    padding: "6px 12px",
                                                    borderRadius: "6px",
                                                    marginTop: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Date Selected
                                                </button>
                                              ) : (
                                                <p> Claimed</p>
                                              )
                                            ) : i.is_claim == 7 ? (
                                              <p
                                                style={{
                                                  color: "#000",
                                                  backgroundColor: "#ffcb5b",
                                                  fontSize: "12px",
                                                  fontFamily: "var(--font-400)",
                                                  padding: "6px 12px",
                                                  borderRadius: "6px",
                                                  marginTop: "4px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {" "}
                                                Claimed
                                              </p>
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  if (i.is_claim == 1) {
                                                    Navigate("/benefits", {
                                                      state: {
                                                        is_nfb: i.is_nfb,
                                                        benifit_category:
                                                          i.partner_category_type,
                                                        message: i.message,
                                                        partner: 1,
                                                        partner_benifit_id:
                                                          i.partner_benifit_id,
                                                        product_assign_id:
                                                          i.product_assign_id,
                                                        release_bond_id:
                                                          i.release_bond_id,
                                                        accept_bond_bid_id:
                                                          i.accept_bond_bid_id,
                                                        user_id: userId,
                                                        bid_benifit_last_date:
                                                          i.bid_benifit_last_date,
                                                        bid_amount_price:
                                                          i.bid_partner_benifit_price,
                                                        is_claim: i.is_claim,
                                                        user_selected_video_time:
                                                          i.user_selected_video_time,
                                                      },
                                                    });
                                                  } else if (i.is_claim == 2) {
                                                    if (
                                                      i.partner_category_type ==
                                                      "Video"
                                                    ) {
                                                      Navigate("/benefits", {
                                                        state: {
                                                          is_nfb: i.is_nfb,
                                                          partner: 1,
                                                          partner_benifit_id:
                                                            i.partner_benifit_id,
                                                          product_assign_id:
                                                            i.product_assign_id,
                                                          release_bond_id:
                                                            i.release_bond_id,
                                                          accept_bond_bid_id:
                                                            i.accept_bond_bid_id,
                                                          user_id: userId,
                                                          bid_benifit_last_date:
                                                            i.bid_benifit_last_date,
                                                          bid_amount_price:
                                                            i.bid_partner_benifit_price,
                                                          is_claim: i.is_claim,
                                                          benifit_category:
                                                            i.partner_category_type,
                                                          reward_id:
                                                            i.reward_id,
                                                          user_history_id:
                                                            i.user_history_id,
                                                          date_1: i.date_1,
                                                          date_2: i.date_2,
                                                          date_3: i.date_3,
                                                          message: i.message,
                                                          user_selected_video_time:
                                                            i.user_selected_video_time,
                                                        },
                                                      });
                                                    } else {
                                                      const param = {
                                                        reward_id: i.reward_id,
                                                        status: 3,
                                                      };

                                                      axios
                                                        .post(
                                                          "https://www.ravi.host/api/reward_claim_partner_accept",
                                                          param
                                                        )
                                                        .then((response) => {
                                                          if (
                                                            response.data
                                                              .status === 1
                                                          ) {
                                                            fetchBenifitesData();
                                                            // setIsModalVisible(true);
                                                            // setCusmsg(response.data.message);
                                                            setErrorMessage(
                                                              response.data
                                                                .message
                                                            );
                                                            setShowModal(true);
                                                          } else {
                                                            setErrorMessage(
                                                              response.data
                                                                .message
                                                            );
                                                            setShowModal(true);
                                                          }
                                                        });
                                                    }
                                                  } else if (
                                                    item.is_claim == 4
                                                  ) {
                                                    Navigate("/benefits", {
                                                      state: {
                                                        is_nfb: i.is_nfb,
                                                        partner: 1,
                                                        partner_benifit_id:
                                                          i.partner_benifit_id,
                                                        product_assign_id:
                                                          i.product_assign_id,
                                                        release_bond_id:
                                                          i.release_bond_id,
                                                        accept_bond_bid_id:
                                                          i.accept_bond_bid_id,
                                                        user_id: userId,
                                                        bid_benifit_last_date:
                                                          i.bid_benifit_last_date,
                                                        bid_amount_price:
                                                          i.bid_partner_benifit_price,
                                                        is_claim: i.is_claim,
                                                        benifit_category:
                                                          i.partner_category_type,
                                                        reward_id: i.reward_id,
                                                        user_history_id:
                                                          i.user_history_id,
                                                        date_1: i.date_1,
                                                        date_2: i.date_2,
                                                        date_3: i.date_3,
                                                        user_selected_video_date:
                                                          i.user_selected_video_date,
                                                        user_selected_video_time:
                                                          i.user_selected_video_time,
                                                      },
                                                    });
                                                  } else if (
                                                    item.is_claim == 5
                                                  ) {
                                                    const param = {
                                                      reward_id: i.reward_id,
                                                      status: 6,
                                                    };

                                                    axios
                                                      .post(
                                                        "https://www.ravi.host/api/reward_claim_partner_accept",
                                                        param
                                                      )
                                                      .then((response) => {
                                                        if (
                                                          response.data
                                                            .status === 1
                                                        ) {
                                                          // setIsModalVisible(true);
                                                          // setCusmsg(response.data.message);
                                                          setErrorMessage(
                                                            response.data
                                                              .message
                                                          );
                                                          setShowModal(true);
                                                          fetchBenifitesData();
                                                        } else {
                                                          setErrorMessage(
                                                            response.data
                                                              .message
                                                          );
                                                          setShowModal(true);
                                                        }
                                                      });
                                                  } else if (
                                                    item.is_claim == 6
                                                  ) {
                                                    const param = {
                                                      reward_id: i.reward_id,
                                                      status: 7,
                                                    };

                                                    axios
                                                      .post(
                                                        "https://www.ravi.host/api/reward_claim_partner_accept",
                                                        param
                                                      )
                                                      .then((response) => {
                                                        if (
                                                          response.data
                                                            .status === 1
                                                        ) {
                                                          // setIsModalVisible(true);
                                                          // setCusmsg(response.data.message);
                                                          setErrorMessage(
                                                            response.data
                                                              .message
                                                          );
                                                          setShowModal(true);
                                                          fetchBenifitesData();
                                                        } else {
                                                          setErrorMessage(
                                                            response.data
                                                              .message
                                                          );
                                                          setShowModal(true);
                                                        }
                                                      });
                                                  } else {
                                                    Navigate("/benefits", {
                                                      state: {
                                                        is_nfb: i.is_nfb,
                                                        partner: 1,
                                                        partner_benifit_id:
                                                          i.partner_benifit_id,
                                                        product_assign_id:
                                                          i.product_assign_id,
                                                        release_bond_id:
                                                          i.release_bond_id,
                                                        accept_bond_bid_id:
                                                          i.accept_bond_bid_id,
                                                        user_id: userId,
                                                        bid_benifit_last_date:
                                                          i.bid_benifit_last_date,
                                                        bid_amount_price:
                                                          i.bid_partner_benifit_price,
                                                        is_claim: i.is_claim,
                                                        benifit_category:
                                                          i.partner_category_type,
                                                        reward_id: i.reward_id,
                                                        user_history_id:
                                                          i.user_history_id,
                                                        user_selected_video_time:
                                                          i.user_selected_video_time,
                                                      },
                                                    });
                                                  }
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "#fff",
                                                    backgroundColor: "#a4c8b2",
                                                    fontSize: "12px",
                                                    fontFamily:
                                                      "var(--font-400)",
                                                    padding: "6px 12px",
                                                    borderRadius: "6px",
                                                    marginTop: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {i.is_claim == 1
                                                    ? "Explore"
                                                    : i.is_claim == 2
                                                    ? i.partner_category_type ==
                                                      "Video"
                                                      ? "Select Date"
                                                      : "Confirm"
                                                    : i.is_claim == 0
                                                    ? "Claim"
                                                    : i.partner_category_type ==
                                                        "Video" &&
                                                      i.is_claim == 4
                                                    ? "Check "
                                                    : i.partner_category_type ==
                                                        "Video" &&
                                                      i.is_claim == 5
                                                    ? "Confirm"
                                                    : i.partner_category_type ==
                                                        "Video" &&
                                                      i.is_claim == 6
                                                    ? "Confirm"
                                                    : null}
                                                </p>
                                              </button>
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </Slider>
                          </>
                        );
                      })
                    ) : (
                      <p
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        No Benefits
                      </p>
                    )}

                    {/* Add more slider items as needed */}
                  </center>
                </div>
                <br />
                <div className="sub_part_2">
                  <img
                    src={images.object}
                    alt=""
                    className="partner_benefit_side2_img"
                  />
                </div>
              </div>
              {/* ) : (
                <p
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  No Special Benefits found
                </p>
              )} */}
            </>
          )}
        </>

        {/* partner benefit end */}
      </div>
      {showModal && (
        // <AlertModal
        //   msg={errorMessage}
        //   setShowModal={setShowModal}
        //   title="Information"
        // />
        <div className="v2modal_main_wrapp">
          <div className="v2modal_inner_wrapp">
            <div className="v2modal_fixed_logo_wrapp">
              <img src={images.logo} alt="" />
            </div>
            <button
              className="v2modal_close_img"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img
                src={images.new_close_logo}
                className="v2modal_close_img"
                alt=""
              />
            </button>
            <div className="v2modal_fixed_text_wrapp">
              <p>{errorMessage}</p>
            </div>

            <div className="v2modal_fixed_btns_wrapp">
              {/* <button
              className="v2modal_btn1"
              onClick={() => {
                setconformationmodel(false);
              }}
            >
              Cancel
            </button> */}
              <button
                className="v2modal_btn2"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <Footer></Footer> */}
      {/* <Footer getlocation={location.pathname} /> */}
      <NewFooter getlocation={location.pathname} />
    </div>
  );
};

export default SpecialBenefits;
