import React from "react";
import "./Intrests.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  add_user_seleted_interest,
  get_all_interest,
} from "../../utils/Constant";
import qs from "qs";
import AlertModal from "../../components/AlertModal";
import NewModal from "../../common/newmodal/NewModal";
import V2Modal from "../../common/v2modal/V2Modal";

const Intrests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [allIntrests, setAllInterests] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedIds, setSelectedIds] = useState("");
  const [getcusmsg, setcusmsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  const closemodal = () => {
    setShowModal(false);
  };
  const openmodal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    fetchInterests();

    if (location.state.user_id) {
      console.log(" location.state.user_id", location.state.user_id);
    }
  }, []);

  const fetchInterests = () => {
    setLoading(true);
    axios
      .get(get_all_interest)
      .then((response) => {
        setLoading(false);
        if (response.data.status === 1) {
          setAllInterests(response.data.result);
        } else {
          // alert(response.data.message);
          setcusmsg(response.data.message);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleInterestSelection = (item) => {
    const updatedSelectedInterests = [...selectedInterests];
    let updatedSelectedIds = selectedIds;

    if (isInterestSelected(item)) {
      const index = updatedSelectedInterests.findIndex(
        (interest) => interest.id === item.id
      );
      updatedSelectedInterests.splice(index, 1);
    } else {
      if (updatedSelectedInterests.length >= 5) {
        // alert("You can only select five interests");
        setcusmsg("You can only select five interests");
        setShowModal(true);
        return;
      }
      updatedSelectedInterests.push(item);
    }

    updatedSelectedIds = updatedSelectedInterests
      .map((interest) => interest.id)
      .join(", ");

    setSelectedInterests(updatedSelectedInterests);
    setSelectedIds(updatedSelectedIds);
  };

  const isInterestSelected = (item) => {
    return selectedInterests.some((interest) => interest.id === item.id);
  };

  const handleValidation = () => {
    if (selectedInterests.length >= 5) {
      setLoading(true);
      const param = {
        user_id: location.state.user_id,
        interest_id: selectedIds,
      };

      axios
        .post(add_user_seleted_interest, qs.stringify(param))
        .then((response) => {
          setLoading(false);
          if (response.data.status === 1) {
            setLoading(true);
            const param1 = {
              user_id: location.state.user_id,
            };
            console.log("====================================");
            console.log("param", param1);
            console.log("====================================");

            axios
              .post(
                "https://www.ravi.host/api/edit_profile",
                qs.stringify(param1)
              )
              .then((Response) => {
                setLoading(false);

                console.log("-qwwwq", Response.data.result);

                if (Response.data.status == 1) {
                  localStorage.setItem("is_login", JSON.stringify(true));
                  localStorage.setItem("user_id", location.state.user_id);
                  localStorage.setItem("email", Response.data.result.email);
                  localStorage.setItem(
                    "username",
                    Response.data.result.username
                  );
                  localStorage.setItem(
                    "profile",
                    Response.data.result.username
                  );
                  console.log("Response.data", Response.data.result);
                  if (Response.data.result.countries == "India") {
                    navigate("/homescreen2", {
                      state: {
                        user_id: location.state.user_id,
                      },
                    });
                  } else {
                    navigate("/Internationaluserlist", {
                      state: {
                        user_id: location.state.user_id,
                      },
                    });
                  }
                } else {
                  setcusmsg(response.data.message);
                  setShowModal(true);
                }
              })
              .catch((err) => {
                // alert(err);
                setcusmsg(err);
                setShowModal(true);
                setLoading(false);
              });
          } else {
            // alert("Please select five interests");
            setcusmsg("Please select five interests");
            setShowModal(true);
          }
        })
        .catch((err) => {});
    } else {
      setcusmsg("Please select  five interests");
      setShowModal(true);
    }
  };

  return (
    <div className="Intrests_main_wrapp">
      <div className="intrests_base_wrapp">
        <img src={images.new_logo} className="intrests_nfb_logo" alt="" />

        <h2 className="intrests_heading">
          Choose <br /> any five <br /> interests that <br /> you connect
          <br /> with.
        </h2>

        <div className="intrests_btn_main_wrapp">
          {allIntrests && allIntrests.length > 0
            ? allIntrests.map((item, index) => {
                return (
                  <button
                    key={item.id}
                    // className="intrests_single_btn"
                    className={`intrests_single_btn ${
                      isInterestSelected(item) ? "selected_intrest" : ""
                    }`}
                    onClick={() => handleInterestSelection(item)}
                    style={{}}
                  >
                    {item.name}
                  </button>
                );
              })
            : null}
        </div>

        <button
          className="intrests_get_started_btn"
          onClick={() => handleValidation()}
        >
          Get Started
        </button>
      </div>
      {showModal && (
        <>
          {/* <AlertModal title="" msg={getcusmsg} setShowModal={setShowModal} /> */}
          {/* <NewModal
            title=""
            msg={getcusmsg}
            setShowModal={openmodal}
            closemodal={closemodal}
          /> */}

          <V2Modal
            canclebtn={false}
            title=""
            msg={getcusmsg}
            setShowModal={setShowModal}
            okfunction={closemodal}
          />
        </>
      )}
    </div>
  );
};

export default Intrests;
