import React, { useState } from "react";
import "./Sponsors.css";
import images from "../../constants/images";
import Slider from "react-slick";
import { FaArrowRightLong } from "react-icons/fa6";
import NavbarSec from "../../common/NavbarSec";
import { get_all_advertisement } from "../../utils/Constant";
import axios from "axios";
import qs from "qs";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

const Sponsors = () => {
  const navigate = useNavigate();
  const [slideIndex, setSlideIndex] = useState(0);
  const [getads, setAds] = useState([]);
  const [getclubs, setClubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getsearchData, setSearchData] = useState([]);
  const [getisOk, setIsOk] = useState(false);
  const userId = localStorage.getItem("user_id");

  const GetAdds = async () => {
    setIsLoading(true);
    await axios
      .get(get_all_advertisement)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          setAds(res.data.result);
        } else {
          console.log("ads err ->", res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchFilter = (text) => {
    const newData = getsearchData.filter(function (item) {
      console.log("text", text);
      const sdata = item.club_name
        ? item.club_name.toUpperCase()
        : "".toLowerCase();

      const textData = text.toUpperCase();
      return sdata.indexOf(textData) > -1;
    });
    setClubs(newData);
    console.log("newData", newData);
  };

  const GetClubsData = async () => {
    setIsLoading(true);
    const param = {
      user_id: userId,
    };

    await axios
      .post("https://www.ravi.host/api/get_all_user_club", qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);

        if (Response.data.status == 1) {
          setClubs(Response.data.result);
          setSearchData(Response.data.result);
          setIsOk(0);
        } else {
          setIsOk(1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetAdds();
    GetClubsData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="page_main_wrapp_nw reletive_position">
      <div className="page_base_wrapp_2">
        {/* navbar start */}
        {/* <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "30px",
            background: "black",
            zIndex: 11,
            marginTop: "20px",
          }}
        >
          <div className="navsec_back_arrow">
            <img
              src={images.back}
              // className="nav_back_btn"
              alt=""
              onClick={() => {
                navigate("/Sidemenu");
              }}
            />
          </div>

          <b
            style={{
              color: "#fff",
              fontfamily: "var(--font-600)",
              fontsize: "16px",
            }}
          >
            Sponsors
          </b>
        </div> */}
        <div style={{ width: "100%", margin: "10px auto", maxWidth: "920px" }}>
          {/* <NavbarSec name="Wishlist" /> */}
          <div
            className="legacy_dashbord_nav_sec"
            style={{ margin: "10px auto" }}
          >
            <button
              onClick={() => {
                navigate("/Sidemenu");
              }}
            >
              <FaAngleLeft color="var(--color-white)" size={20} />
            </button>
            <p>Sponsors</p>
            <div></div>
          </div>
        </div>
        {/* <NavbarSec name="Sponsors" /> */}
        {/* navbar end */}

        {/* banners start */}
        <div className="sponsors_carosel_wrapp">
          <Slider {...settings}>
            {getads && getads.length > 0
              ? getads.map((item, index) => {
                  return (
                    <div className="sponsor_single_banner">
                      <img src={item.banner} alt="" />
                    </div>
                  );
                })
              : null}
          </Slider>
        </div>
        {/* banner end */}

        {/* search start */}
        {getclubs && getclubs.length == 0 ? null : (
          // <div className="search">
          //   <input
          //     type="text"
          //     placeholder="Search"
          //     // value={search}
          //     onChange={(e) => searchFilter(e.target.value)}
          //   />
          //   <img className="search-img" src={images.search} alt="" />
          // </div>
          <div style={{}} className="bid13_search">
            <div
              className="bids13_user_bid_main"
              style={{ paddingTop: "10px" }}
            >
              <input
                type="text"
                placeholder="Search"
                className="bids13_user_bid_search"
                // value={searchTerm}
                onChange={(e) => searchFilter(e.target.value)}
              />
              {/* <img src={images.search} alt="" className="search_ic" /> */}
            </div>
          </div>
        )}
        {/* search end */}

        {/* sponsors products start */}
        <div className="sponsors_products_wrapp">
          {getclubs && getclubs.length > 0
            ? getclubs.map((item, index) => {
                // console.log("item", item);
                return (
                  <div className="sponsors_single_products">
                    <img src={item.cover_photo} alt="" />
                    <div>
                      <p className="sponsors_product_name">{item.club_name}</p>
                      <button
                        className="sponsors_product_view_btn"
                        onClick={() => {
                          console.log("item", item);
                          item.phone_is_status == 0 && item.email_is_status == 0
                            ? navigate("/ClubOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.phone_is_status == 0
                            ? navigate("/ClubOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.email_is_status == 0
                            ? navigate("/ClubEmailOtp", {
                                state: {
                                  email: item.email,
                                  phone: item.phone_number,
                                  user_id: item.user_id,
                                  club_id: item.club_id,
                                },
                              })
                            : item.is_bank_status == 0
                            ? navigate("/clubBankaccount", {
                                state: {
                                  club_id: item.club_id,
                                },
                              })
                            : navigate("/Clubprofile", {
                                state: {
                                  club_id: item.club_id,
                                },
                              });
                        }}
                      >
                        View{" "}
                        {/* <FaArrowRightLong color="var(--color-main)" size={20} /> */}
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {getclubs && getclubs.length <= 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <p
              style={{
                color: "var(--color-white)",
                fontSize: "14px",
                fontFamily: "var(--font-600)",
                margin: "10px auto",
                textAlign: "center",
              }}
            >
              You don't have any clubs as of now.
              <br /> Create Club.
            </p>
            <button
              className="new_btn_wrapp mx-1"
              style={{
                width: "60%",
              }}
              onClick={() => {
                navigate("/Createclub");
              }}
            >
              <p>Create Club</p>
            </button>
          </div>
        )}
        {/* sponsors products end */}
      </div>
    </div>
  );
};

export default Sponsors;
