import React, { useEffect, useState } from "react";
import "./viewmanagers.css";
import images from "../../constants/images";
import { Link, useNavigate } from "react-router-dom";
import NavbarSec from "../../common/NavbarSec";
import Cardthree from "../../components/feedcard/cardthree";
import { edit_profile, delete_user_manager } from "../../utils/Constant";
import axios from "axios";
import ReactModal from "react-modal";
import { FaAngleLeft } from "react-icons/fa";

const Viewmanagers = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "none",
      border: "none",
      borderRadius: "12px",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };
  const [managers, setManagers] = useState([
    { id: 1, name: "Manager 1" },
    { id: 2, name: "Manager 2" },
    { id: 3, name: "Manager 3" },
    { id: 4, name: "Manager 4" },
    { id: 5, name: "Manager 5" },
    { id: 6, name: "Manager 6" },
    { id: 7, name: "Manager 7" },
    { id: 8, name: "Manager 8" },
    { id: 9, name: "Manager 9" },
    { id: 10, name: "Manager 10" },
  ]);
  const [selectedManagerId, setSelectedManagerId] = useState(null);

  const [deletemodal, setdeletemodal] = useState(false);
  const handleDeleteManager = (managerId) => {
    const updatedManagers = managers.filter(
      (manager) => manager.id !== managerId
    );
    setManagers(updatedManagers);
  };

  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    _getdata();
  }, []);
  const userId = localStorage.getItem("user_id");
  const [managerdata, setManagerData] = useState([]);

  const _getdata = () => {
    setloading(true);

    const param = {
      user_id: userId,
    };

    axios.post(edit_profile, param).then((Response) => {
      setloading(false);
      console.log(
        "----",
        JSON.stringify(Response.data.result.manager_data, null, 2)
      );
      if (Response.data.status === 1) {
        const result = Response.data.result;
        setManagerData(result.manager_data);
      } else {
        // setIsModalVisible(true);
        // setCusMsg(Response.data.message);
      }
    });
  };
  const deletemanager = (manager_id) => {
    setloading(true);

    const param = {
      user_id: userId,
      manager_id: manager_id,
    };

    axios.post(delete_user_manager, param).then((Response) => {
      setloading(false);
      console.log("----", JSON.stringify(Response.data, null, 2));
      if (Response.data.status === 1) {
        _getdata();
        setdeletemodal(false);
      } else {
        // setIsModalVisible(true);
        // setCusMsg(Response.data.message);
      }
    });
    // setdeletemodal(false)
  };
  const closemodal = () => {
    setdeletemodal(false);
  };
  const openmodal = () => {
    setdeletemodal(true);
  };

  return (
    <div className="page_main_wrapp">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="page_base_wrapp_2">
            {/* <NavbarSec name="Viewmanagers" /> */}
            <div className="user_benefit_bond_modal_heding_1">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
              <p>View Managers</p>
            </div>
            <Link to={"/selectmanagers"} className="div">
              {/* <input
                type="text"
                style={{ marginTop: "20px" }}
                readOnly
                placeholder="Search"
                className="viewmanagers_search"
              /> */}
              <div style={{}} className="bid13_search">
                <div
                  className="bids13_user_bid_main"
                  style={{ paddingTop: "10px" }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    className="bids13_user_bid_search"
                    // value={searchTerm}
                    // onChange={(e) => searchFilter(e.target.value)}
                  />
                  {/* <img src={images.search} alt="" className="search_ic" /> */}
                </div>
              </div>
            </Link>
            <div>
              <p
                style={{
                  fontSize: "12px",
                  color: "white",
                  fontFamily: "var(--font-600)",
                  margin: "10px auto",
                }}
              >
                Selected Managers
              </p>
            </div>
            <div
              className="user_benefit_modal_allbonds_wrapp_mycommunity"
              style={{ paddingBottom: "10px" }}
            >
              {managerdata &&
                managerdata.map((item, index) => {
                  console.log("item", item);
                  return (
                    <div
                      className="user_benefit_modal_single_bond_1"
                      key={item.id}
                      style={{
                        marginTop: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        borderRadius: "5px",
                        display: "flex",
                      }}
                    >
                      <div className="user_benefit_modal_single_bond_inner_wrapp">
                        <img
                          onClick={() => {
                            // alert(item.manager_id);
                            navigate("/userProfile", {
                              state: { user_id: item.manager_id },
                            });
                          }}
                          // className="viewmanagers_details_img"
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "30px",
                            objectFit: "cover",
                            zIndex: "111",
                          }}
                          src={item.manager_profile}
                          alt=""
                        />
                        <b className="name_of_viewmanagers">
                          {item.manager_name}
                        </b>
                        <img
                          style={{
                            // alignItems: "flex-end",
                            // display: "flex",
                            // justifyContent: "flex-end",
                            zIndex: "111",
                            width: "25px",
                            height: "25px",
                          }}
                          className="viewmanagers_details_img"
                          src={images.new_delete_icon}
                          alt=""
                          // onClick={() => deletemanager(item.manager_id)}

                          onClick={() => {
                            setSelectedManagerId(item.manager_id);
                            setdeletemodal(true);
                          }}
                        />
                      </div>

                      {/* modal */}
                      {deletemodal && (
                        // <ReactModal
                        //   isOpen={setdeletemodal}
                        //   // onAfterOpen={afterOpenModal}
                        //   onRequestClose={closemodal}
                        //   style={customStyles}
                        // >
                        //   <div className="NewModal_main_wrapp">
                        //     <div
                        //       style={{
                        //         display: "flex",
                        //         flexDirection: "column",
                        //         alignItems: "center",
                        //         justifyContent: "center",
                        //       }}
                        //     >
                        //       <img
                        //         className="newmodal_logo"
                        //         src={images.logo}
                        //         alt=""
                        //       />
                        //       <p className="newmodal_titel"></p>
                        //       {/* <p className="newmodal_titel">Alert</p> */}
                        //     </div>

                        //     {/* <div className="newmodal_des_wrapp"> */}
                        //     <p className="newmodal_des">
                        //       Are you sure you want to delete?
                        //     </p>

                        //     <div
                        //       className="modal_two_buton"
                        //       style={{ width: "100%" }}
                        //     >
                        //       <button
                        //         className="close_forgot"
                        //         onClick={() => deletemanager(selectedManagerId)}
                        //       >
                        //         Yes
                        //       </button>
                        //       <button
                        //         className="close_forgot"
                        //         onClick={closemodal}
                        //       >
                        //         No
                        //       </button>
                        //     </div>
                        //   </div>
                        // </ReactModal>

                        <div className="v2modal_main_wrapp">
                          <div className="v2modal_inner_wrapp">
                            <div className="v2modal_fixed_logo_wrapp">
                              <img src={images.logo} alt="" />
                            </div>
                            <button
                              className="v2modal_close_img"
                              onClick={() => {
                                setdeletemodal(false);
                              }}
                            >
                              <img
                                src={images.new_close_logo}
                                className="v2modal_close_img"
                                alt=""
                              />
                            </button>
                            <div className="v2modal_fixed_text_wrapp">
                              <p>Are you sure you want to delete?</p>
                            </div>

                            <div className="v2modal_fixed_btns_wrapp">
                              <button
                                className="v2modal_btn1"
                                onClick={() => {
                                  setdeletemodal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="v2modal_btn2"
                                onClick={() => deletemanager(selectedManagerId)}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* modal end */}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Viewmanagers;
