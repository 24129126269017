import React, { useState } from "react";
import "./Recievedinvitaion_2.css";
import images from "../../constants/images";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { log } from "debug/src/browser";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import NewLoader from "../../common/newloder/NewLoader";
import {
  get_inter_user_v2,
  user_resend_email_otp_v2,
} from "../../utils/Constant";

const Recievedinvitaion1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [getemail, setEmail] = useState("");
  const [getuserId, setUserId] = useState("");
  const [getuserName, setUserName] = useState("");
  const [getstep, setStep] = useState("");
  const { user_id } = useParams();

  useEffect(() => {
    GetUser();
  }, []);

  const GetUser = async () => {
    setIsLoading(true);
    const param = {
      user_id: user_id,
    };
    console.log("success");
    await axios
      .post(get_inter_user_v2, qs.stringify(param))
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          console.log("user_otp Response", Response.data);
          setEmail(Response.data.email);
          setUserId(Response.data.user_id);
          setUserName(Response.data.username);
          setStep(Response.data.int_step);
          // toast.success(`${Response.data.message}`, { theme: "colored" });
        } else {
          toast.error(`${Response.data.message}`, { theme: "colored" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("user_otp err", err);
        toast.error(`${err}`, { theme: "colored" });
      });
  };

  return (
    <div>
      {isLoading ? (
        <NewLoader />
      ) : (
        <div className="recr_new_wrapp_container recr_reletive_position">
          <img
            src={images.new_bg_circule}
            alt=""
            className="recr_back_circule_shadow"
          />
          <div className="recr_new_container">
            <div className="recr_back_arrow_wrapp">
              <button>
                <FaAngleLeft color="var(--color-white)" size={20} />
              </button>
            </div>
            <div className="recr_1_new_intanationa_invite_main_wrapp">
              <img src={images.logo} alt="" />
              <h2>INVITATION</h2>
              <p>
                An invitation from <b>{getuserName}</b> has been received to
                your email at{" "}
                <b>
                  ****
                  {getemail && getemail.length > 4
                    ? getemail.slice(4)
                    : getemail}
                </b>
                .
              </p>
              {/* btn */}
              <button
                className="new_btn_wrapp mt-3"
                // onClick={handleConfirm}
                onClick={() => {
                  if (getstep == 0) {
                    navigate("/Recievedinvitaion2", {
                      state: {
                        user_id: getuserId,
                        email: getemail,
                        username: getuserName,
                      },
                    });
                  } else if (getstep == 1) {
                    navigate("/NewInternationalEmailOtp", {
                      state: {
                        user_id: getuserId,
                        email: getemail,
                        // username: getuserName,
                      },
                    });
                  } else if (getstep == 2) {
                    navigate("/NewInternationalPassword", {
                      state: {
                        user_id: getuserId,
                        email: getemail,
                        // username: getuserName,
                      },
                    });
                  } else if (getstep == 3) {
                    navigate("/NewInterNationalUserDetails", {
                      state: {
                        user_id: getuserId,
                        // email: getemail,
                        // username: getuserName,
                      },
                    });
                  } else if (getstep == 4) {
                    navigate("/NewFounderVideoScreen", {
                      state: {
                        user_id: getuserId,
                        // email: getemail,
                        // username: getuserName,
                      },
                    });
                  } else if (getstep == 5) {
                    navigate("/NewInterests", {
                      state: {
                        user_id: getuserId,
                        // email: getemail,
                        // username: getuserName,
                      },
                    });
                  } else if (getstep == 5) {
                    navigate("/login");
                  }
                }}
              >
                <p>Confirm</p>
              </button>
              {/* Redirect to the invitation page if redirectToInvitation is true */}
              {/* {redirectToInvitation && <Link to="/Recievedinvitaion_2" />} */}
              {/* btn */}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Recievedinvitaion1;
