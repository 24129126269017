import React, { useState, useEffect } from "react";
import axios from "axios";
import "./clubmenbers.css";
import { useLocation, useNavigate } from "react-router-dom";
import images from "../../constants/images";
import NavbarSec from "../../common/NavbarSec";

const Clubmembers = () => {
  const url = "https://www.ravi.host/api/get_all_club_detail";
  const [data, setData] = useState([]);

  console.log("nitin", data);
  const userId = localStorage.getItem("user_id");

  const fetchInfo = async () => {
    // FormData object
    const formData = new FormData();

    formData.append("club_id", location.state.club_id);
    formData.append("user_id", userId);

    // Send the POST request with the FormData
    return await axios
      .post(url, formData)
      .then((res) => setData(res.data.result.club_member))
      .catch((error) => {
        // Handle errors
      });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="page_main_wrapp">
      <div className="page_base_wrapp_2">
        <NavbarSec name="Members" />
        <br />
        <div className="club_m_list">
          {data.map(({ profile, firstname }) => (
            <div className="club_manager_gradinat_wrapp">
              <div className="main_manager_club_set">
                <img src={profile} className="club_m_list_img" alt="" />
                <p className="club_m_list_nm">{firstname}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clubmembers;
