import React from "react";
import "./BuyProduct.css";
import images from "../../constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { get_product_ditails, product_cart } from "../../utils/Constant";

const BuyProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userId = localStorage.getItem("user_id");
  const [loading, setloading] = useState(false);
  const [getdata, setdata] = useState([]);
  const [getsizes, setSizes] = useState([]);
  const [getallQty, setAllQty] = useState("");
  const [getprice, setPrice] = useState("");

  const [selectedSize, setSelectedSize] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isAddToCartDisabled, setAddToCartDisabled] = useState(true);

  useEffect(() => {
    // Enable or disable "Add to Cart" button based on whether a size is selected
    setAddToCartDisabled(!selectedSize);
  }, [selectedSize]);

  useEffect(() => {
    getSingleProduct();
    console.log("getsizes", getsizes);
  }, []);
  const handleSizeSelection = (size) => {
    setSelectedSize(size);
  };

  const handleQuantityDecrease = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  const handleQuantityIncrease = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const getTotalPrice = () => {
    return getprice * selectedQuantity;
  };

  const getSingleProduct = async () => {
    const sizee = "";
    setloading(true);
    const params = {
      //   user_id: userId,
      ecommerce_product_id: location.state.ecommerce_product_id,
    };
    axios
      .post(get_product_ditails, qs.stringify(params))
      .then((res) => {
        setloading(false);

        console.log("111-------------+_->", JSON.stringify(res.data, null, 2));

        if (res.data.status == 1) {
          console.log("datas--->", res.data);
          if (res.data.result.size == "") {
            setAddToCartDisabled(false);
          }
          setdata(res.data.result);
          setAllQty(res.data.result.quantity);
          setPrice(res.data.result.price);
          //   setSizes(
          //     res.data.result.size !== "" ? res.data.result.size.split(",") : []
          //   );
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const addSingleProduct = async () => {
    setloading(true);

    const params = {
      user_id: userId,
      ecommerce_product_id: location.state.ecommerce_product_id,
      quantity: selectedQuantity,
      total: getTotalPrice(),
      size: selectedSize,
    };

    axios
      .post(product_cart, qs.stringify(params))
      .then((res) => {
        setloading(false);

        console.log(
          "GetData of place order data_-------------+_->",
          JSON.stringify(res.data, null, 2)
        );

        if (res.data.status == 1) {
          console.log("datas--->", res.data);
          setdata(res.data.result);
          navigate("/OrderConfirmation", {
            state: {
              ecommerce_product_id: location.state.ecommerce_product_id,
            },
          });
        } else {
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <div className="my_order_main_wrapp">
      <div className="my_order_base_wrapp">
        <div className="mo_nav_wrap">
          <img
            src={images.back_black}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <p>Buy Product</p>
        </div>
        <div className="bp_img_wrapp">
          <img src={getdata.image} alt="" />
        </div>

        {/* product details start */}
        <div className="bp_details_wrapp">
          <h3>Product Details</h3>
          <p>{getdata.description}</p>
        </div>
        {/* product details end */}

        {/* select sizes start */}
        {getdata && getdata.size && getdata.size !== "" && (
          <div className="bp_details_wrapp">
            <h3>Select Size</h3>
            <div className="bp_sizes_wrapp">
              {getdata.size.split(",").map((ite, ind) => (
                <button
                  className="bp_single_size"
                  key={ind}
                  style={{
                    background: selectedSize === ite ? "#545454" : "#ddd",
                    color: selectedSize === ite ? "#fff" : "#000",
                  }}
                  onClick={() => handleSizeSelection(ite)}
                >
                  {ite}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* select sizes end */}

        {/* select quantity start */}
        <div className="bp_details_wrapp" style={{ marginBottom: "60px" }}>
          <h3>Select Quantity</h3>
          <div className="bp_sizes_wrapp">
            <button
              className="bp_single_size btn_qty_active"
              onClick={handleQuantityDecrease}
              style={{ fontSize: "18px", fontFamily: "var(--font-400)" }}
            >
              -
            </button>
            <p
              style={{
                fontSize: "16px",
                color: "#000",
                fontFamily: "var(--font-400)",
                alignSelf: "center",
                margin: "0px 10px",
              }}
            >
              {selectedQuantity}
            </p>
            <button
              className="bp_single_size btn_qty_active"
              onClick={handleQuantityIncrease}
              style={{ fontSize: "18px", fontFamily: "var(--font-400)" }}
            >
              +
            </button>
          </div>
        </div>
        {/* select quantity end */}

        {/* total start */}

        <div className="bp_total_wrapp">
          <div className="bp_total_wrapp_inner">
            <p>₹ {getTotalPrice()}</p>
            <button
              disabled={isAddToCartDisabled}
              onClick={() => {
                addSingleProduct();
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
        {/* total end */}
      </div>
    </div>
  );
};

export default BuyProduct;
